import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import ContractIcon from '../../assets/contract-icon'
import { handleScrollIntoView } from '../../Utils/utils'
import { trackPayoffQuote } from '../../Global/Analytics'

const StyledQuoteReadyBlock = styled.div`
  display: flex;
  padding: 40px 40px 70px;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  align-text: center;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding-right: 40px;

  svg {
    max-height: 90px;
    min-height: 90px;
  }

  .quote-ready-title {
    margin: 40px 0 24px 0;
    text-align: center;
    width: 100%;
  }

  .quote-ready-text {
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
  }

  .scroll {
    position: absolute;
    margin-top: -138px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    margin-top: 40px;
  }
`

const QuoteReadyBlock = ({
  generatingPDF,
  generatedDate,
  payoffQuoteUrl,
}) => {
  const scrollToRef = useRef(null)

  useEffect(() => {
    if (!generatingPDF)
      handleScrollIntoView(scrollToRef.current, 'smooth', 'start')
  }, [generatingPDF])

  return (
    <StyledQuoteReadyBlock className="cell">
      <div className="scroll" ref={scrollToRef} />
      <ContractIcon />
      <h2 role="alert" className="quote-ready-title heading-6">
        {generatingPDF
          ? 'We are generating your quote...'
          : 'Your Payoff Quote Is Ready!'}
      </h2>
      <p className="subtitle-2 quote-ready-text">
        {`Your payoff quote is based on a Good Through Date of
              ${dayjs(generatedDate).format('MM/DD/YYYY')}. ${
          generatingPDF
            ? ''
            : 'Click the button below to view and download for your records.'
        }`}
      </p>
      <Button
        text="Click To View Quote"
        UID="payoff_quote"
        disabled={generatingPDF}
        externalLink={payoffQuoteUrl}
        refreshPage
        onClick={() =>
          trackPayoffQuote.Downloaded(generatedDate.toString())
        }
      />
    </StyledQuoteReadyBlock>
  )
}

QuoteReadyBlock.propTypes = {
  generatingPDF: PropTypes.bool.isRequired,
  generatedDate: PropTypes.PropTypes.instanceOf(Date).isRequired,
  payoffQuoteUrl: PropTypes.string.isRequired,
}

export default QuoteReadyBlock
