import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import Styled from 'styled-components'

import AuthContainer from './AuthContainer'
import { getRandomLetters } from '../../services/auth'
import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'
import MfaErrorModal from '../../Common/MfaErrorModal'

const StyledConfirmSignIn = Styled.div`
  .error-message {
    color: ${({ theme }) => theme.colors.error};
    font-size: 20px;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledConfirmSignInModern = Styled.div`
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 20px;
  margin-left: 15px;
  margin-bottom: 40px;

  .label {
    color: var(--Black, #000);
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 137.5%;
    font-size: 16px;
    // margin-top: 82px;

    @media (max-width: 991px) {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .input {
    font-family: Lato, sans-serif;
    border-radius: 10px;
    border: 1px solid #a9a9a9;
    background-color: var(--White, #fff);
    // margin-top: 6px;
    justify-content: center;
    color: var(--Grey-2, #777);
    font-weight: 400;
    line-height: 137.5%;
    width: 100%;

    display: flex;
    padding: 10px 8px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 20px;

    @media (max-width: 991px) {
      max-width: 100%;
    }
  }

  .error-message {
    color: ${({ theme }) => theme.colors.error};
    font-size: 20px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const ConfirmSignIn = ({ authData, setAuthState, setAuthData, setUserInfo, setIsAuthenticated }) => {
  const [code, setCode] = useState('')
  const [rememberBrowser, setRememberBrowser] = useState(false)
  const [error, setError] = useState('')
  const [showMfaErrorModal, setShowMfaErrorModal] = useState(false)

  const [randomLetters, setRandomLetters] = useState('')

  const setErrorForAda = (error) => {
    setRandomLetters(getRandomLetters(4))
    setError(error)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {
      const loggedUser = await Auth.confirmSignIn(
        authData,
        code,
        'SMS_MFA',
      )

      if (rememberBrowser) {
        console.log('remembering device to supress MFA')
        loggedUser.setDeviceStatusRemembered(
          rememberDeviceCallback(loggedUser),
        )
        await Auth.rememberDevice();
      } else {
        console.log('Forgetting device. MFA re-stablished')
        loggedUser.setDeviceStatusNotRemembered(
          rememberDeviceCallback(loggedUser),
        )
        await Auth.forgetDevice();
      }

      setAuthData({ authData, redirect: true })
      setUserInfo(loggedUser)
      setIsAuthenticated(!!loggedUser)
    } catch (err) {
      setShowMfaErrorModal(true)
      setErrorForAda(err.message)
    }
  }

  const rememberDeviceCallback = (loggedUser) => ({
    onSuccess: function (result) {
      console.log('call result: ' + result)
      setAuthState('signedIn')
      setAuthData({ loggedUser, redirect: true })
    },

    onFailure: function (err) {
      setError(err.message)
    },
  })

  return (
      <StyledConfirmSignInModern>
        <AuthContainer header="2-Factor Authentication">
          <form onSubmit={handleSubmit}>
            <div className="Section__sectionBody___ihqqd">
              <div className="Form__formField___38Ikl">
                <div className="label">
                  <label htmlFor="code">
                    <strong>
                      Please enter the 6-digit code that was sent to
                      your mobile phone *
                    </strong>
                  </label>
                </div>
                <input
                  placeholder="Code"
                  name="code"
                  autoComplete="off"
                  data-testid={buildTestID(
                    'code',
                    '/home',
                    'confirm_sign_in',
                  )}
                  className="input"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  id="code"
                  aria-required="true"
                  type="text"
                  pattern="\d*"
                />
              </div>
            </div>
            <div>
              <p role="alert">
                <span
                  data-testid="confirm-sign-in-error"
                  className="error-message"
                >
                  {error}
                  <span
                    style={{ visibility: 'hidden' }}
                    dangerouslySetInnerHTML={{
                      __html: randomLetters,
                    }}
                  ></span>
                </span>
              </p>
            </div>
            <div className="Section__sectionFooterSecondaryContent___Nj41Q">
              <label htmlFor="remember-browser">
                Remember this browser?&nbsp;
              </label>
              <input
                type="checkbox"
                checked={rememberBrowser}
                onChange={(e) => setRememberBrowser(e.target.checked)}
                id="remember-browser"
                data-testid={buildTestID(
                  'remember_browser',
                  '/home',
                  'confirm_sign_in',
                )}
              ></input>
            </div>
            <div className="buttons">
              <Button
                onClick={() => setAuthState('signIn')}
                href="#"
                text="Back to Sign In"
                textButton
                UID="confirm_signin"
              />
              <Button
                type="submit"
                text="Confirm"
                UID="confirm_signin"
              />
            </div>
          </form>
          <MfaErrorModal onHide={() => { setShowMfaErrorModal(false) }} show={showMfaErrorModal} />
        </AuthContainer>
      </StyledConfirmSignInModern>
    )
}

export default ConfirmSignIn