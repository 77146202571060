import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const ThirdPartyAuthorizations = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.ThirdPartyAuthorizations, title)
  })

  return (
    <>
      <h3 ref={title} id="third-party-authorizations" tabIndex="-1">
        Representations and Powers of Attorney
      </h3>
      <h5>
        Third party Authorizations (3PA or A3P) & Attorney
        Representation
      </h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Who is authorized to receive personal information on my
              account?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Only borrowers, Attorneys-in-Fact (via an approved Power
              of Attorney) and third parties authorized in writing by
              a borrower are authorized to receive information on an
              account. If a borrower has a guardian or conservator,
              they are also an authorized party as typically this
              means there is a competency concern and they are
              appointed to manage the borrower’s financial affairs.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="how-can-i-authorize-third-party">
          <Card.Header>
            <CustomToggle eventKey="1">
              How can I authorize a third party to receive information
              on my account?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              A borrower may submit a signed{' '}
              <a href="./forms/2024/Third_Party_Authorization.pdf" target="_blank">
                Third Party Authorization Form
              </a>{' '}
              authorizing us to provide account- specific information
              on their reverse mortgage to their third-party
              designee(s) – they must list the specific names of the
              authorized persons. An Authorized Third Party (A3P) will
              only be permitted to receive information on the account.
              A3Ps may not make any changes or decisions on the
              account, including requesting and advance of LOC funds.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              What is the difference between an A3P and Authorized
              Agent?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              An A3P (authorized third party) is someone the borrower
              has given written permission to get information on the
              loan. This can be requested through the{' '}
              <a href="./forms/2024/Third_Party_Authorization.pdf" target="_blank">
                Third Party Authorization Form
              </a>{' '}
              found on{' '}
              <a href="https://www.reversedepartment.com/common-forms" target="_blank">
                reversedepartment.com/common-forms
              </a>
              .<br />
              <br />
              An Authorized Agent is someone who can act on the behalf
              of the borrower with legal authority such as an
              Attorney-in-Fact (via a Power of Attorney), Guardian,
              Conservator, Executor or Trustee. They can make changes
              and requests on the account as if they were the
              borrower.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <h5>Powers of Attorney / Guardianships / Conservatorships</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              I have Power of Attorney (POA) / Guardianship or
              Conservatorship for a borrower. What do I need to do?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              The POA, Guardianship or Conservatorship documents must be reviewed and approved before the account record can be updated. Please send all POA/guardianship/conservatorship documents and requests to {' '}
              <a href="mailto:DocReview@reversedepartment.com">
                DocReview@reversedepartment.com
              </a>. Please allow 10 business days from receipt for review. Additional documentation (doctor’s letters, notarized signatures, photo identification, etc.) may be required as part of the review process.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default ThirdPartyAuthorizations
