import React, { useState } from "react"
import { Col, Form, Row } from 'react-bootstrap'
import Countdown from 'react-countdown'
import styled from "styled-components"
import Button from '../../Common/button'
import { buildTestID, formatPhoneNumberNormalize, getCookie } from "../../Utils/utils";
import { DIDNT_RECEIVE_CODE_ERROR, DONE, VERIFY_PHONE_NUMBER_CODE_ERROR } from "."
import { Auth } from "aws-amplify"
import { track2FA } from "../../Global/Analytics"

const StyledVerifyPhoneNumber = styled.div`
h1, h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }
  
  p {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 20px;
    // background: #fafafa;
  }

  input.form-control, select.form-control {
    height: 56px;
  }

  #verify-code {
    width: 305px;
  }

  div.col:nth-child(even) {
    text-align: center;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
    flex-direction: column;
  
    .cancel {
      margin-right: 24px;
    }
  
    button {
      margin-bottom: 15px;
    }
  }

  .what_to_do {
    display: flex;
  }

  .what_to_do_text {
    margin-left: 15px;
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .verify-phone-number-code {
      background-color: #fff;
      padding: 15px;
      margin: 32px auto 0;
      width: 600px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      // text-align: center;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // padding: 24px;
      // background: #FAFAFA;
      font-size: 16px;
      // font-weight: 700;
      // margin: 25px 35px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
      flex-direction: row;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .button-confirm-identity  {
        width: 230px !important;
      }

 }
  `

const VerifyPhoneNumberCode = ({ setView, phoneNumber, mainRef }) => {
  const [enteredCode, setEnteredCode] = useState('')
  const [showError, setShowError] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [codeSentVisible, setCodeSentVisible] = useState(false)
  const [canResendCode, setCanResendCode] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const [resendAttempts, setResendAttempts] = useState(0)

  const setCodeSent = () => {
    setCanResendCode(false)
    setCodeSentVisible(true)
    setTimeout(() => setCodeSentVisible(false), 5000)
  }

  const verifyCode = async () => {
    try {
      const attempt = attempts + 1
      setAttempts(attempt)
      setIsVerifying(true)

      try {
        const user = await Auth.currentAuthenticatedUser()

        const response = await Auth.verifyCurrentUserAttributeSubmit(
          'phone_number',
          enteredCode,
        )
        console.log('verified phone response', response)

        // Select SMS as preferred
        Auth.setPreferredMFA(user, 'SMS')
          .then(() => {
            const finishYourAccount = getCookie('finish_your_account_mfa_enabled');
            if(finishYourAccount === 'true') 
              track2FA.FinishYourAccount()
            else
              track2FA.Completed()
            
            setView(DONE)
          })
          .catch(() => {
            setShowError(true)
            setIsVerifying(false)
          });
      } catch (err) {
        if (attempts >= 2)
          setView(VERIFY_PHONE_NUMBER_CODE_ERROR)

        setShowError(true)
        setIsVerifying(false)
      }
    } catch (e) {
      console.log('an error', e)
      setIsVerifying(false)
      setShowError(true)
    }
  }

  const handleClick = async (e) => {
    e.preventDefault()
    const attempt = resendAttempts + 1
    setResendAttempts(attempt)

    try {
      // To initiate the process of verifying the attribute like 'phone_number' or 'email'
      Auth.verifyCurrentUserAttribute('phone_number')
        .then(() => {
          console.log('a verification code is sent');
          setCodeSent()
        })
        .catch((e) => {
          console.log('failed with error', e);
          setCodeSent()
        });
    } catch (err) {
      setShowError(true)
    }
  }

  return (
    <StyledVerifyPhoneNumber>
      <div className="verify-phone-number-code"
        data-gridcolumnlayout={{
          xl: '1/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef} className="mb-3">A Text Message has been sent to {formatPhoneNumberNormalize(phoneNumber)}</h1>
        <p> It could be a few seconds to a few minutes before you receive the text.</p>
        <p>When you receive the text, enter the code in the field below, and then click Verify Code</p>
        <Form>
          <Row>
            <Col>
              {showError ? (
                <p className="error_text" role="alert">
                  Incorrect code. Please check to see that the code is correctly entered and try again.  If multiple codes were requested, please make sure you are entering the most recent one sent to you. You have {(3 - attempts)} attempts remaining.
                </p>
              ) : (
                ''
              )}
              <Form.Group className="mb-40" controlId="verify-code">
                <div className="verify_content">
                  <div className="input_group">
                    <Form.Label>Enter the code you received here</Form.Label>
                    <Form.Control type="text"
                      data-testid={buildTestID(
                        'verify_code',
                        'enable_mfa',
                        'enter_verify_code',
                      )}
                      // ref={inputRef}
                      pattern="\d*"
                      className={showError ? 'input_error' : ''}
                      name="verify-code"
                      min="111111"
                      max="999999"
                      value={enteredCode}
                      // onWheel={() => inputRef.current.blur()}
                      // onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        setShowError(false)
                        if (e.target.value.length <= 6) {
                          setEnteredCode(e.target.value.replace(/\D/g, ''))
                        }
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <hr />
        <div className="buttons">
          <Button
            loading={isVerifying}
            text={isVerifying ? 'Verifying' : 'Verify Code'}
            disabled={enteredCode.length !== 6}
            onClick={
              enteredCode.length === 6 && !isVerifying
                ? verifyCode
                : () => { }
            }
            UID="enter_verify_code"
          />
          {codeSentVisible ? (
            <>
              <p className="error_text resend_code_text" role="alert">
                Code Resent
              </p>
            </>
          ) : (
            <div className="what_to_do">
              {canResendCode ? (
                <>
                  <Button
                    onClick={handleClick}
                    text="Re-Send Code"
                    textButton
                    UID="enter_verify_code"
                  />
                </>
              ) : resendAttempts < 2 ? (
                <>
                  <span className="what_to_do_text">Wait </span>
                  <span className="what_to_do_text">
                    <Countdown
                      date={Date.now() + 20000}
                      onComplete={() => setCanResendCode(true)}
                      intervalDelay={1000}
                      precision={2}
                      renderer={(props) => <span>{props.seconds}</span>}
                    />{' '}
                  </span>
                  <span className="what_to_do_text">Seconds</span>
                </>
              ) : null}
              {resendAttempts < 2 ? (
                <span className="what_to_do_text">I Didn't Receive Code?</span>)
                :
                (
                  <Button
                    onClick={() => setView(DIDNT_RECEIVE_CODE_ERROR)}
                    text="I Didn't Receive Code?"
                    textButton
                    UID="i_didn't_receive_code"
                  />
                )
              }
            </div>
          )}
        </div>
      </div>
    </StyledVerifyPhoneNumber>
  )
}

export default VerifyPhoneNumberCode;