import React from 'react'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import Button from './button'
import { buildTestID } from '../Utils/utils'

const smallScreenStyles = `
  flex-direction: column;
  align-items: start;
  padding: 24px 20px;

  .title {
    margin-bottom: 20px;
  }

  .all-text {
    margin: 0;
  }

  svg {
    min-height: 57px;
    min-width: 57px;
    height: 57px;
    width: 57px;
    margin-bottom: 20px;
  }
`

const StyledInfoCard = styled.div`
  .info_card {
    display: flex;
    padding: 32px 40px;
    flex-direction: row;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    height: 100%;

    .all-text {
      margin-left: 32px;
      width: 100%;
    }

    .paragraph {
      margin: 0;

      .phone-web-link {
        text-decoration: none;
        font-weight: 700;
        text-transform: lowercase;
        white-space: nowrap;
      }
    }

    svg {
      min-height: 88px;
      min-width: 88px;
    }
  }

  .button {
    margin-top: 12px;

    a {
      text-align: start;
    }
  }

  .smallScreenStyles {
    ${smallScreenStyles}
    margin-top: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .info_card {
      ${smallScreenStyles}
    }
  }
`

const InfoCard = ({
  title = '',
  paragraph,
  SVG,
  smallScreenStyles = false,
  className = '',
  loading = false,
  buttonProps = { text: '', linkTo: '' },
  onClick
}) => {
  let { pathname } = useLocation()

  return !loading ? (
    <StyledInfoCard className={className}>
      <div
        className={`info_card ${
          smallScreenStyles ? 'smallScreenStyles' : ''
        }`}
        onClick={onClick}  
      >
        {SVG && <SVG />}
        <div className="all-text">
          {title ? <h2 className="subtitle bold">{title}</h2> : null}
          <p
            className="body-2 paragraph"
            data-testid={buildTestID(title || paragraph, pathname)}
          >
            {paragraph}
          </p>
        </div>
        {buttonProps.text ? (
          <Button
            className="button"
            textButton
            {...buttonProps}
            UID={buttonProps.linkTo}
          />
        ) : null}
      </div>
    </StyledInfoCard>
  ) : null
}

InfoCard.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  SVG: PropTypes.any,
  smallScreenStyles: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  buttonProps: PropTypes.object,
}

export default InfoCard
