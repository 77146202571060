import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`

const ChipLogoPlaceholder = ({ color, className }) => {
  return (
    <StyledSVG
      color={color}
      fill="none"
      className={className}
      role="presentation"
      viewBox="0 0 32 24"
      width="32"
      height="24"
    >
      <rect width="32" height="24" fill="#5A5A5A" rx="2"></rect>
      <path
        fill="#fff"
        d="M10.666 11.667v2.666c0 .554.447 1 1 1 .554 0 1-.446 1-1v-2.666c0-.554-.446-1-1-1-.553 0-1 .446-1 1zm4 0v2.666c0 .554.447 1 1 1 .554 0 1-.446 1-1v-2.666c0-.554-.446-1-1-1-.553 0-1 .446-1 1zm-4.333 7H21a.999.999 0 100-2H10.333c-.553 0-1 .446-1 1s.447 1 1 1zm8.333-7v2.666c0 .554.447 1 1 1 .554 0 1-.446 1-1v-2.666c0-.554-.446-1-1-1-.553 0-1 .446-1 1zm-3.62-6.674L9.78 7.767a.828.828 0 00-.447.733c0 .46.373.833.833.833h11.007A.832.832 0 0022 8.5a.828.828 0 00-.447-.733l-5.267-2.774a1.317 1.317 0 00-1.24 0z"
      ></path>
    </StyledSVG>
  )
}

export default ChipLogoPlaceholder
