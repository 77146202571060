import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import MoneyHandIcon from '../../assets/money-hand-icon.js'
import ViewIcon from '../../assets/view-icon.js'
import { useRetrieveLocEligibility } from '../../Hooks/useRetrieveLocEligibility'
import Button from '../../Common/button'
import { trackLOCRequest } from '../../Global/Analytics'
import useWindowSize from '../../Hooks/useWindowSize'
import { buildTestID } from '../../Utils/utils'
import { EQUITY_ELITE, HOMESAFE } from '../AdvanceRequest/index.js'

const LOCAdvanceTool = ({ loanType, setToolHeight, setAvailableTools }) => {
  const { isXSmall } = useWindowSize()

  const locAdvanceToolHeightRef = useRef()

  const {
    error: eligibilityError,
    loading: eligibilityLoading,
    data: eligibilityData,
  } = useRetrieveLocEligibility()

  useEffect(() => {
    if (eligibilityLoading || !locAdvanceToolHeightRef.current) return

    let { height } =
      locAdvanceToolHeightRef.current.getBoundingClientRect()

    setToolHeight(height)
  }, [locAdvanceToolHeightRef, setToolHeight, eligibilityLoading])

  const eligibility = {
    eligible: false,
    pending: false,
    history: false,
    maxRequestAmount: null,
  }

  if (!eligibilityLoading && !eligibilityError) {
    eligibility.eligible =
      eligibilityData.data.getLOCEligibility.info?.eligible
    eligibility.history =
      eligibilityData.data.getLOCEligibility.info?.history
    eligibility.pending =
      eligibilityData.data.getLOCEligibility.info?.pending
    eligibility.maxRequestAmount =
      eligibilityData.data.getLOCEligibility.info?.maxRequestAmount
  }

  const { eligible, pending, history, maxRequestAmount } = eligibility
  const hide = (loanType === HOMESAFE || loanType === EQUITY_ELITE) && maxRequestAmount < 500
  // const eligible = true
  // const pending = false
  // const history = true
  // const maxRequestAmount = null

  if (!hide && (eligible || history)) {
    setAvailableTools()
  }

  if (!isXSmall) {
    return !eligibilityLoading ? (
      Boolean(!hide && (eligible || history)) ? (
        <div className="tool" ref={locAdvanceToolHeightRef}>
          <div className="content">
            <MoneyHandIcon color="primaryDark" />
            <div className="button-and-text">
              <h3 className="body-1 bold">
                {maxRequestAmount && pending
                  ? 'Your loan has a Line of Credit Advance Pending.'
                  : null}
                {maxRequestAmount && eligible && !pending
                  ? 'Your loan is eligible for a Line of Credit advance.'
                  : null}
                {!maxRequestAmount || (!eligible && !pending)
                  ? 'Your loan is not eligible for a Line of Credit advance.'
                  : null}
              </h3>
              <div className="loc-advance-center-button">
                {maxRequestAmount && pending ? (
                  <Button
                    text="Check My Status"
                    linkTo="/my-advance-requests"
                    UID="loc_advance"
                  />
                ) : (
                  ''
                )}
                {maxRequestAmount && eligible && !pending ? (
                  <Button
                    UID="loc_advance"
                    text="Request Advance"
                    linkTo="/advance-request"
                    onClick={trackLOCRequest.Started}
                  />
                ) : (
                  ''
                )}
                {(!maxRequestAmount || (!eligible && !pending)) && (
                  <Button
                    UID="loc_advance_disabled"
                    text="Request Advance"
                    linkTo="#"
                    disabled
                  />
                )}
              </div>
              {history && !pending && (
                <Button
                  UID="loc_advance"
                  className="view-my-req-button"
                  text="View My Requests"
                  linkTo="/my-advance-requests"
                  textButton
                />
              )}
            </div>
          </div>
        </div>
      ) : null
    ) : (
      <div className="tool">
        <div className="loading">
          <div className="content">
            <div className="rectangle icon" />
            <div className="left-rec-group">
              <div className="rectangle long" />
              <div className="rectangle long" />
              <div className="rectangle large" />
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return Boolean(!hide && (eligible || history)) ? (
      <>
        {maxRequestAmount && eligible && !pending ? (
          <Link
            to="/advance-request"
            onClick={trackLOCRequest.Started}
            className="mobile_card"
            data-testid={buildTestID(
              'request_a_line_of_credit_advance',
              '_my_loan',
              'mobile',
            )}
          >
            <div className="icon_section">
              <MoneyHandIcon color="white" />
            </div>
            <p className="caption">
              Request a Line of Credit Advance
            </p>
          </Link>
        ) : null}
        {maxRequestAmount && pending ? (
          <Link
            to="/my-advance-requests"
            className="mobile_card"
            data-testid={buildTestID(
              'check_my_line_of_credit_status',
              '_my_loan',
              'mobile',
            )}
          >
            <div className="icon_section">
              <MoneyHandIcon color="white" />
            </div>
            <p className="caption">Check my Line of Credit Status</p>
          </Link>
        ) : null}
        {maxRequestAmount && eligible && !pending && history ? (
          <Link
            to="/my-advance-requests"
            className="mobile_card"
            data-testid={buildTestID(
              'view_my_line_of_credit_requests',
              '_my_loan',
              'mobile',
            )}
          >
            <div className="icon_section">
              <ViewIcon />
            </div>
            <p className="caption">View my Line of Credit Requests</p>
          </Link>
        ) : null}
      </>
    ) : null
  }
}

LOCAdvanceTool.propTypes = {
  loanType: PropTypes.string.isRequired,
  setToolHeight: PropTypes.func.isRequired,
  setAvailableTools: PropTypes.func.isRequired,
}

export default LOCAdvanceTool
