import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react';

import BankIcon from '../../assets/bank-icon.js'
import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import { checkIsUserSignedIn } from '../../services/auth'

import Stepper from './Stepper'
import BannerBar from '../../Common/BannerBar.js'
import CompleteForm from './CompleteForm'
import ReviewRequest from './ReviewRequest'
import RequestSubmitted from './RequestSubmitted'
import SubmitRequest from './SubmitRequest'
import VerifyAccount from './VerifyAccount'
import EnterVerifyCode from './EnterVerifyCode'
import InfoCard from '../../Common/InfoCard.js'
export const EQUITY_ELITE = 'EE'
export const HOMESAFE = 'HomeSafe'

const StyledIndexContainer = styled.div`
  min-height: 800px;
  margin-bottom: 80px;

  .banner {
    margin: 0 0 32px 0;
  }

  .background {
    height: 330px;
    width: 100%;
    position: absolute;
    z-index: -1;
    background: ${({ theme }) => theme.colors.primary4};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .background {
      display: none;
    }
  }
`

// All LOC Advance screens are represented below
// As they are ubiquitous they are stored here to ensure consistency
export const COMPLETE_FORM = 'COMPLETE_FORM'
export const REVIEW_REQUEST = 'REVIEW_REQUEST'
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT'
export const ENTER_VERIFY_CODE = 'ENTER_VERIFY_CODE'
export const SUBMIT_REQUEST = 'SUBMIT_REQUEST'
export const REQUEST_SUBMITTED = 'REQUEST_SUBMITTED'

const AdvanceRequest = ({ loan, payType, user }) => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const [currentScreen, setCurrentScreen] = useState(COMPLETE_FORM)
  const [verificationValue, setVerificationValue] = useState('')
  const [drawAmount, setDrawAmount] = useState('')
  const [submittedRequestData, setSubmittedRequestData] = useState({})
  const [verificationMethod, setVerificationMethod] =
    useState('email')
  const [userVerified, setUserVerified] = useState(false)

  useLayoutEffect(() => {
    const redirectOnInvalidUserSession = async () => {
      const isUserSignedIn = await checkIsUserSignedIn()
      if (!isUserSignedIn) {
        Auth.signOut()
      }
    }

    if (authStatus !== "signIn" && authStatus !== "signUp" && authStatus !== "loading")
      redirectOnInvalidUserSession()
  }, [currentScreen, authStatus])

  return (
    <StyleGuide>
      <StyledIndexContainer>
        <div className="background" />
        <Grid>
          <Stepper currentScreen={currentScreen} />
          <BannerBar
            show={currentScreen === SUBMIT_REQUEST && !userVerified}
            type="verified"
            className="banner"
            text="Your Identity is successfully verified."
            UID="loc_advance"
          />
          {currentScreen === COMPLETE_FORM && (
            <CompleteForm
              setCurrentScreen={setCurrentScreen}
              maxAmount={loan.adjustedRequestAmountDraw}
              setDrawAmount={setDrawAmount}
              drawAmount={drawAmount}
              pendingTransactionAmountDraw={loan.pendingTransactionAmountDraw}
              userVerified={userVerified}
              loanType={loan.productName}
            />
          )}
          {currentScreen === REVIEW_REQUEST && (
            <ReviewRequest
              payType={payType}
              setCurrentScreen={setCurrentScreen}
              drawAmount={drawAmount}
              loanType={loan.productName}
            />
          )}
          {currentScreen === VERIFY_ACCOUNT && (
            <VerifyAccount
              setCurrentScreen={setCurrentScreen}
              verificationMethod={verificationMethod}
              setVerificationMethod={setVerificationMethod}
              setVerificationValue={setVerificationValue}
              user={user}
            />
          )}
          {currentScreen === ENTER_VERIFY_CODE && (
            <EnterVerifyCode
              setCurrentScreen={setCurrentScreen}
              verificationMethod={verificationMethod}
              verificationValue={verificationValue}
            />
          )}
          {currentScreen === SUBMIT_REQUEST && (
            <SubmitRequest
              payType={payType}
              setCurrentScreen={setCurrentScreen}
              drawAmount={drawAmount}
              loan={loan}
              verificationMethod={verificationMethod}
              setSubmittedRequestData={setSubmittedRequestData}
              setUserVerified={setUserVerified}
              user={user}
            />
          )}
          {currentScreen === REQUEST_SUBMITTED && (
            <RequestSubmitted
              setUserVerified={setUserVerified}
              submittedRequestData={submittedRequestData}
              setSubmittedRequestData={setSubmittedRequestData}
            />
          )}
          <InfoCard
            SVG={BankIcon}
            title="When will my request be processed?"
            paragraph="A Line of Credit advance request submitted by 5pm ET
                on a business day will begin processing on the same
                day and will be disbursed within 5 business days in
                accordance with your established preferred method of
                payment. Requests that are submitted after 5pm ET, or
                on a weekend or federal holiday, will begin processing
                on the next business day."
          />
        </Grid>
      </StyledIndexContainer>
    </StyleGuide>
  )
}

AdvanceRequest.propTypes = {
  payType: PropTypes.string.isRequired,
  loan: PropTypes.exact({
    accountNumber: PropTypes.string.isRequired,
    maxAmountToDraw: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.exact({
    completeName: PropTypes.string.isRequired,
    phone: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
}

export default AdvanceRequest
