import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const DefaultMaturity = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.DefaultMaturity, title)
  })

  return (
    <>
      <h3 ref={title} id="default-maturity-due-payable" tabIndex="-1">
        Default, Maturity / Due and Payable
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              What is the difference between a loan in default and a
              loan that is matured or called due and payable?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              A loan in default has not necessarily been matured or
              called due and payable yet. For example, if you are
              behind on your property charges (taxes, insurance, HOA,
              ground rents, etc.) and we have advanced funds on your
              behalf to pay the charges, your loan goes into a default
              status and you will have an opportunity to cure that
              default before the loan is called due and payable. You
              will be notified of any default and provided with a
              period of time to cure the default before the loan is
              matured/called due and payable.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              What can cause a reverse mortgage to go into default?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              There are six (6) events of default as explained in your loan documents. If a default is not cured or cannot be cured (i.e., death) the loan will be called Due and Payable:
              <ol>
                <li>
                  The death of the last remaining borrower or eligible non-borrowing spouse*
                </li>
                <li>
                  The permanent move out of all borrowers or sale of the property.
                </li>
                <li>
                  Failure to certify annually that the property is the borrowers' primary residence.
                </li>
                <li>
                  Failure to pay property charges including but not limited to taxes, insurance, HOA dues, etc.
                </li>
                <li>
                  Failure to maintain the condition of the property (i.e., if the property is condemned or in disrepair)
                </li>
                <li>
                  Transferring title to another party(ies) and removing all borrowers from the title/ownership of the property. Note: Borrowers can ADD people to title after closing if they wish, but at least one borrower must remain on title to the property.
                </li>
              </ol>
              <br />
              <strong>
                *A Non-Borrowing Spouse is a spouse of a borrower but
                not a borrower themselves on the loan. For more
                information on this topic please contact us at
                866-446-0026.
              </strong>
              <br />
              <br />
              If the loan is in default, a deed-in-lieu of foreclosure
              or a short sale could help you avoid foreclosure. Please
              contact us at <strong>866-446-0026,</strong>{' '}
              Monday-Thursday 8 am – 6 pm EST, or on Friday 8 am -5 pm
              EST, to explore your options and/or use the{' '}
              <a href="./forms/2024/Deed-in-lieu_of_Foreclosure_Request.pdf" target="_blank">
                Deed-in-lieu of Foreclosure Request Form
              </a>{' '}
              or the{' '}
              <a href="./forms/2024/Short_Sale_Instruction_Package.pdf" target="_blank">
                Short Sale Instruction Package.
              </a>
              <br />
              <br />
              <strong>Benefits of a Deed-in-Lieu:</strong>
              <ul>
                <li>
                  Property is not vacant for a long period of time
                </li>
                <li>
                  Simple process for borrower or heirs to complete
                  provided title is clear
                </li>
                <li>
                  Borrower/heirs obligations are released more
                  expediently, without going through foreclosure
                </li>
              </ul>
              <br />
              <strong>
                Benefits of a Short Sale (Note: a Short Sale may not
                be available for all reverse mortgage loan types):
              </strong>
              <ul>
                <li>
                  If the loan is in a Called Due status (i.e., Due and
                  Payable) the home may be sold for 95% of the current
                  appraised value, regardless of the amount owed on
                  the loan.
                </li>
                <li>
                  If the loan has not been called Due and Payable, the
                  home may be sold for 100% of the current appraised
                  value, regardless of the amount owed on the loan.
                </li>
                <li>
                  If all borrowers are deceased, heirs can retain the
                  home and purchase it at 95% of the current appraised
                  value, regardless of the amount owed on the loan.
                </li>
                <li>
                  Fees will stop accruing to the loan upon completion
                  of the sale.
                </li>
                <li>Will avoid foreclosure</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              An individual visited my property stating they were completing an inspection. What is the purpose of this inspection?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              The Servicer, in compliance with HUD guidelines, investor requirements, and/or local regulations, is required to complete periodic property inspections.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              One or more locks have been changed at my property. What is the purpose of this action?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              The Servicer may be required per HUD, investor requirements and/or local regulations to take immediate action to protect a property we believe to be vacant, abandoned, or at risk.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default DefaultMaturity