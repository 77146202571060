import React from 'react'
import AdvanceRequest from '../Components/AdvanceRequest'

import BadRequestPage from '../Components/BadRequestPage'
import LoadingPage from '../Components/LoadingPage'
import Title from '../Common/Title'
import { useApiContext } from '../Hooks/useApiContext'
import { extractLoan } from '../Utils/apiExtractors'

import { useRetrieveLocEligibility } from '../Hooks/useRetrieveLocEligibility'
import { useLoggedInUser } from '../Hooks/useLoggedInUser'

const AdvanceRequestContainer = () => {
  const {
    error: eligibilityError,
    loading: eligibilityLoading,
    data: eligibilityData,
  } = useRetrieveLocEligibility()

  const apiContext = useApiContext()

  const {
    error: loanError,
    loading: loanLoading,
    data: loanData,
  } = apiContext.loan

  const {
    error: userError,
    loading: userLoading,
    data: userData,
  } = useLoggedInUser()

  if (loanLoading || eligibilityLoading || userLoading) {
    return <LoadingPage />
  }

  if (loanError || eligibilityError || userError) {
    return <div>{(loanError || eligibilityError).message}</div>
  }

  const { eligible, pending, maxRequestAmount, adjustedRequestAmount, pendingTransactionAmount, maxOnlineRequestAmount } = eligibilityData.data.getLOCEligibility.info

  const loanResponse = extractLoan(loanData)
  
  const accountId = loanResponse.advanceBankAccount.accountId

  const payType =
    loanResponse.advanceBankAccount.paymentType === 'ACH'
      ? `Direct deposit to acct. ending in ${accountId.substring(
          accountId.length - 4,
        )}`
      : `Check mailed via USPS`

  const loan = {
    accountNumber: loanResponse.loanId.toString(),
    maxAmountToDraw: maxOnlineRequestAmount || maxRequestAmount,
    adjustedRequestAmountDraw: maxOnlineRequestAmount || adjustedRequestAmount,
    pendingTransactionAmountDraw: pendingTransactionAmount,
    productName: loanResponse.product.productName,
  }

  const user = {
    completeName: `${userData.attributes.given_name} ${userData.attributes.family_name}`,
    phone: userData.attributes.phone_number
      ? userData.attributes.phone_number
      : null,
    email: userData.attributes.email,
  }

  const canUseLOCAdvanceFlow =
    maxRequestAmount && eligible && !pending

  return canUseLOCAdvanceFlow ? (
    <>
      <Title>Advance Request</Title>
      <AdvanceRequest loan={loan} payType={payType} user={user} />
    </>
  ) : (
    <BadRequestPage error="Unauthorized" />
  )
}

export default AdvanceRequestContainer
