import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import Styled from 'styled-components'
import CommonQuestionCategories from './CommonQuestionCategories'
import { buildTestID } from '../../Utils/utils'

const StrongStyled = Styled.strong`
text-align: center;
display: block
`

const RepairsInsuranceTaxes = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.RepairsInsuranceTaxes, title)
  })

  return (
    <>
      <h3
        ref={title}
        id="repairs-insurance-taxes-set-asides"
        tabIndex="-1"
      >
        Set Asides: Taxes, Insurance, and Repairs
      </h3>
      <h5>Set Asides</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              What is a Set Aside?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              A Set Aside on a reverse mortgage loan is a portion of
              the amount of money you qualified to borrow that is “set
              aside” for a specific use and purpose. There are four
              possible types of set asides: LESA, voluntary tax and
              insurance (not available for all loans); servicing fee;
              and repair. Not all loans have a Set Aside – refer to
              your loan documents signed at closing.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              What is the purpose of a LESA?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <strong>A LESA</strong> is a life expectancy set aside.
              The terms of your reverse mortgage loan may have
              required a LESA from which your property charges will
              paid when due, such as property taxes and hazard and/or
              flood insurance. (See your loan agreement signed at
              closing.)
              <br />
              <br />
              For each borrower who applies for a HECM loan, HUD
              requires the Lender to perform a Financial Assessment
              (FA) which evaluates criteria to assess your capacity
              and willingness to pay your future property taxes and
              required homeowner’s insurance (hazard and flood).
              Included in that assessment is the borrower’s credit
              score, past payment history and residual income, among
              other criteria. Depending on the results of the FA, you
              may have been required by HUD guidelines and the Lender
              to have either a partially-funded LESA or a fully-funded
              LESA to qualify for the HECM loan.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              What is the difference between a Fully-Funded LESA and a
              Partially-Funded LESA?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <strong>The differences are explained below:</strong>
              <ul>
                <li>
                  <strong>A Fully-Funded LESA</strong> is required
                  when, even after considering extenuating
                  circumstances, the borrower has not demonstrated a
                  satisfactory credit history and/or property charge
                  payment history, even if residual income is
                  sufficient.{' '}
                  <strong>
                    Property charges will be{' '}
                    <span className="underline">
                      paid by the servicer
                    </span>{' '}
                    out of the LESA Set-Aside.
                  </strong>{' '}
                </li>
                <li>
                  <strong> A Partially-Funded LESA</strong> is
                  required when the borrower has demonstrated a
                  satisfactory credit and property charge payment
                  history, but even after considering any compensating
                  factors, residual income is not sufficient. The
                  borrower will receive semi-annual payments designed
                  to bring residual income up to the standard.{' '}
                  <strong>
                    {' '}
                    The <span className="underline">Borrower</span> is
                    responsible for the payment of all property
                    charges.
                  </strong>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              If my loan has a fully-funded LESA, does the property
              tax bill get forwarded to the loan servicer
              automatically or is it up to me to forward the bill to
              the servicer to be paid?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              If your loan a fully-funded LESA, our tax vendor will
              track the taxes like an escrowed loan and the taxes will
              be paid prior to becoming delinquent. The homeowner or
              tax office can send the bill to us if they choose, but
              it is not necessary.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              Can a LESA be cancelled or changed?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              No, HUD does not permit changes to an established LESA,
              nor may it ever be cancelled. The funds are set aside
              for the specific intent of payment of property charges
              and a borrower may not access them for any other reason.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="5">
              Can I establish a voluntary Tax & Insurance Set Aside so
              you will pay my property taxes and/or insurance?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              No. If a Tax & Insurance Set Aside was not created at the time of closing, you are not able establish one after your loan has closed.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
              What is Servicing Fee Set Aside for?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              If your loan documents disclose a Servicing Fee, this
              set aside was established to cover administrative costs
              of servicing the loan, such as providing statements and
              a customer service call center. The monthly charge is
              capitalized (added to) the loan balance each month.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="7">
              Can I draw funds from my LESA for purposes other than payment of taxes and insurance?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              Unfortunately, no. Once a LESA is established, those funds are reserved for the sole purpose of paying your taxes and insurance and are not accessible for any other reason, in compliance with HUD guidelines.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="8">
              If I pay off my loan and there are unused funds in my LESA, will those funds be paid to me?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="8">
            <Card.Body>
              LESA funds are part of the overall principal limit of the loan (the amount you qualified to borrow); therefore, as LESA funds are used (i.e., borrowed) they are added to your loan balance and must be repaid when the loan is paid in full. Any LESA funds not used are not included in the amount to payoff the loan (as they were not borrowed), nor will they be paid to you. If you payoff the loan, those funds are simply unused credit.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <h5>Taxes and Insurance</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Who is responsible for paying the property taxes and
              insurance on my home?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Depending on how your reverse mortgage was originated,
              there may have been funds set aside to pay for your
              taxes and insurance or you may be responsible to pay for
              those obligations.
              <ul>
                <li>
                  If you received a “fully funded” lifetime expectancy
                  set aside (or LESA), then we will handle paying your
                  taxes and insurance from the Tax & Insurance Set
                  Aside established on your loan at closing. You can
                  see the remaining amount of funds in this set aside
                  in your monthly statement.{' '}
                  <strong>
                    However, once the LESA funds are exhausted, we
                    will no longer be able to make your tax and
                    insurance payments, and you must pay your property
                    taxes and insurance directly.
                  </strong>
                </li>
                <li>
                  If you received a “partially funded” LESA, then you
                  will be responsible for paying your own taxes and
                  insurance to ensure that they are kept in a current
                  status at all times. In addition, you will receive a
                  semi-annual disbursement from your Tax & Insurance
                  Set Aside/LESA that was established when your loan
                  was originated. You can see the remaining amount of
                  funds in this set aside in your monthly statement.{' '}
                </li>
                <li>
                  Please note that if you ever fail to pay your taxes
                  and insurance timely, it could jeopardize your right
                  to receive your semi-annual disbursement.
                </li>
                <li>
                  If you do not have a Tax & Insurance Set Aside/LESA
                  established with your loan, then you will be
                  responsible for paying your own taxes and insurance
                  to ensure that they are kept in a current status at
                  all times.
                </li>
              </ul>
              <br />
              Please note that you are responsible for ensuring that
              all other property charges are kept current at all
              times, including (if applicable), but not limited to,
              ground rents, condominium fees, planned unit development
              fees, homeowner association dues and any other special
              assessment that may be required by local or state law.{' '}
              <strong>
                Note: Property charges other than Taxes and Insurance
                are not included in any funds set aside in a LESA.
              </strong>
              <br />
              <br />
              If you are responsible for paying your own taxes and
              insurance and ever experience any difficulties in paying
              those obligations, please call our office at{' '}
              <strong>(866) 654-0020</strong> so that we can work
              together to solve the problem. If taxes or insurance go
              unpaid, it could result in your loan being placed into a
              default status and your loan could be called due and
              payable, which we want to help you avoid. Also, please
              know that you can always seek the advice, free of
              charge, from a reverse mortgage counselor by calling{' '}
              <strong>(800) 569-4287</strong>.
              <br />
              <br />
              Please ensure that your lender is always listed in the
              Mortgagee Clause section of your insurance policy(ies)
              as follows:
              <StrongStyled>
                {`<Your Lender’s Name>, Its Successors and/ or Assigns`}
              </StrongStyled>
              <StrongStyled>PO Box 39457</StrongStyled>
              <StrongStyled>Solon, OH 44139-0457</StrongStyled>
              To add or correct the Mortgagee Clause on your insurance
              policy(ies), contact your insurance agent(s).
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              Do I need to send you proof of payment of taxes and
              insurance?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              If you paid your taxes and insurance premiums, then the
              answer is NO. If we receive a delinquency notice from
              our Tax or Insurance Vendor, we will ask you for proof
              of payment.
              <br />
              <br />
              If you change insurance carriers, you must provide us
              with a copy of your new policy at renewal otherwise
              force-placed insurance may be obtained and the cost of
              the premium would be applied to your loan. You also must
              contact your insurance agent and ask them to update the
              Mortgagee Clause to the following:
              <StrongStyled>
                {`<Your Lender’s Name>, Its Successors and/ or Assigns`}
              </StrongStyled>
              <StrongStyled>PO Box 39457</StrongStyled>
              <StrongStyled>Solon, OH 44139-0457</StrongStyled>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              I have insurance and can provide proof of coverage with
              no gaps. Can I get a refund of the force- placed
              insurance premium and how long will that refund take?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              Yes, we will refund the cost of the policy if you can
              provide your own policy with no lapse in coverage. If
              there is a lapse in coverage, a pro-rated refund will be
              processed. Force-placed insurance refunds are typically
              issued to the loan within 15 days of receipt of
              acceptable evidence of insurance from the borrower.
              <br />
              <br />
              Mail or fax proof of insurance to:
              <br />
              <strong>Insurance Department</strong>
              <br />
              <strong>PO Box 39457</strong>
              <br />
              <strong>Solon, OH 44139-0457</strong>
              <br />
              <strong>Fax number: 440-505-2000</strong>
              <br />
              <br />
              Please allow 1-2 business days for the document to be
              processed. You may contact the insurance department at{' '}
              <strong>866-871-2412</strong> to verify your proof of
              insurance coverage has been received.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              Can I participate in a property tax deferral program?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              Tax deferrals are generally not allowed while you have a
              reverse mortgage on your property. Tax deferrals are not
              the same as tax exemptions (see below), as they defer
              the tax payments until a later date and a lien is placed
              against your property, which can be in violation of the
              terms of your reverse mortgage.
              <br />
              <br />
              <strong>
                <em>Property Tax Exemptions</em> <br />
              </strong>
              Please be advised that as a homeowner, you may be
              eligible for property tax reductions from your local tax
              authorities in the form of exemptions. Property tax
              exemptions are different from the deferral programs
              listed above, as they are permanent reductions in your
              taxable amount.
              <br />
              <br />
              Some examples of possible exemptions are:
              <ul>
                <li>
                  Homestead exemptions for occupying your home as your
                  primary residence,
                </li>
                <li>
                  Age exemptions, such as being over 65 (age limits
                  may vary by county),
                </li>
                <li>
                  Disability exemptions (if you are disabled), or{' '}
                </li>
                <li>
                  Mortgage exemptions for having a mortgage on your
                  property.
                </li>
              </ul>
              If you do not have any of these exemptions currently in
              place, we strongly encourage you to contact your city or
              county tax office to find out if you can take advantage
              of these potential savings on your annual property tax
              bill. Please note that there may be instances where you
              will need to re-apply for your property tax exemptions
              when a new mortgage is placed on your property, such as
              a refinance or a reverse mortgage. In order to avoid
              losing your tax exemption status, we urge you to contact
              your local tax authorities for more information.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              Why are payment plans with the tax offices not
              acceptable?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              Per the reverse mortgage contract, a borrower's taxes
              must always remain current. If a payment plan is
              established with a taxing authority, it is typically
              because the taxes are past due. In some counties, there
              is also a risk of a tax lien which could supersede the
              mortgage lien.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="7">
              When will I get my tax form?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              If you pay your reverse mortgage in full or have made qualifying payments of $600 or more to your loan during the past year, you will receive a 1098 mortgage interest statement, which will be mailed by January 31st.
              <br />
              <br />
              Because most reverse mortgage borrowers do not make any payments on their loan, 1098 mortgage interest statements are typically not sent until the loan is paid in full.
              <br />
              <br />
              Note: Please contact a tax professional with any questions you may have about tax implications of your reverse mortgage.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="5">
              What if I get behind on taxes?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              If we receive notice that property taxes are delinquent and you are unable to pay them, we will be required to advance payment for the taxes on your behalf. If you have line of credit available, we will pay the delinquent taxes from the line of credit. If you have a line of credit and receive monthly payments, a forced payment plan change will be completed to cover the tax payment which may result in a reduction in the amount of your future monthly payments. If there is no line of credit available, we will pay the delinquent taxes on your behalf, and you will be required to repay the default balance in full. If you are unable to pay the default balance in full, you may be able to request a review for Repayment Plan eligibility by completing and submitting a <a href="./forms/2024/Repayment_Plan_Review_Request.pdf" target="_blank">Repayment Plan Review Request Form</a>. If you are located in New York, you must provide the full <a href="./forms/2024/Loss_Mitigation_Application_NY_Only.pdf" target="_blank" data-testid={buildTestID( 'what_if_i_get_behind_on_taxes', '/common_questions', 'ny_loss', )}>NY Loss Mitigation Request Form</a>. Failure to pay any default balance in full could result in your loan being called due and payable.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="9">
              What if I get behind on insurance?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="9">
            <Card.Body>
              If we receive a Notice of Cancellation from your insurance company, we are required to buy lender-placed insurance on your property. The coverage will be minimal (it does not cover the contents of your home) and it is likely to be more expensive than a policy you could obtain. If you have a line of credit and receive monthly payments, a forced payment plan change will be completed to cover the cost of the lender-placed insurance which may result in a reduction in the amount of your future monthly payments. If there is no line of credit available, we will pay the insurance premium on your behalf, and you will be required to repay the default balance. If you are unable to pay the default balance in full, you may be able to request a review for Repayment Plan eligibility by completing and submitting a <a href="./forms/2024/Repayment_Plan_Review_Request.pdf" target="_blank">Repayment Plan Review Request Form</a>.  If you are located in New York, you must provide the full <a href="./forms/2024/Loss_Mitigation_Application_NY_Only.pdf" target="_blank" data-testid={buildTestID( 'what_if_i_get_behind_on_insurance', '/common_questions', 'ny_loss', )}>NY Loss Mitigation Request Form</a>. Failure to pay any default balance in full could result in your loan being called due and payable.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
              What is a Repayment Plan Arrangement?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              A Repayment Plan Arrangement (RPA) is an agreement between you and your servicer to repay funds that were advanced on your behalf to pay for delinquent taxes and/or lender-placed insurance. Eligibility for a Repayment Plan Arrangement is determined by submitting a <a href="./forms/2024/Repayment_Plan_Review_Request.pdf" target="_blank">Repayment Plan Review Request Form</a>. If you are located in New York, you must provide the full <a href="./forms/2024/Loss_Mitigation_Application_NY_Only.pdf" target="_blank" data-testid={buildTestID( 'what_is_a_repayment_arrangement', '/common_questions', 'ny_loss', )}>NY Loss Mitigation Request Form</a>. If you are approved for a Repayment Plan Arrangement, you will be required to make a monthly payment for the term identified in the Repayment Plan Arrangement or until the Repayment Plan Arrangement has been satisfied and the property charge default balance repaid. Failure to make your required monthly payment could result in your Repayment Plan Arrangement being terminated and may affect your eligibility for future Repayment Plan Arrangements.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="7">
              Do I need to pay at least a full payment every month to keep the Repayment Plan Arrangement valid?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              Yes
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="8">
              If there is a new advance for tax or insurance what will happen to my Repayment Plan Arrangement?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="8">
            <Card.Body>
              One of the following:
              <ul>
                <li>You will need to pay the new amount advanced in full; or </li>
                <li>Show proof that you paid the tax or insurance amount we advanced; or </li>
                <li>Send in an updated budget to be reviewed for a recalculated Repayment Plan Arrangement.</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="9">
              Can my loan be taken out of a default/called due/foreclosure status while I am on the Repayment Plan Arrangement?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="9">
            <Card.Body>
              No, the loan status will remain valid until the default balance is paid in full.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="10">
              If I have difficulty paying my full monthly repayment amount, can my Repayment Plan Arrangement be recalculated with a lower monthly payment?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="10">
            <Card.Body>
              Yes, you will need to submit a new budget for us to review and determine if you qualify for a new Repayment Plan Arrangement.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="11">
              Will I receive monthly payment notices?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="11">
            <Card.Body>
              Yes, reminder letters will be sent. Your repayment plan information will also be included in your monthly statement.
              <br /><br />
              If you miss a payment, you will be sent a letter notifying you of the missed payment.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default RepairsInsuranceTaxes
