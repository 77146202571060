import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { numberToMoney } from '../../Utils/utils'
import AccountBalanceIcon from '../../assets/account-balance-icon.js'
import ScrollToTop from '../../Global/ScrollToTop'
import Button from '../../Common/button'
import { trackLOCRequest } from '../../Global/Analytics'
import { COMPLETE_FORM, VERIFY_ACCOUNT } from './index.js'
import { buildTestID } from '../../Utils/utils'

const StyledReviewRequest = styled.div`
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0 40px;

  .review_content {
    margin: 0 auto;
    width: 416px;

    h6 {
      text-align: center;
    }

    .label {
      margin-bottom: 12px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 13px;
      margin-top: 3px;
    }

    .subtitle {
      font-weight: bold;
    }

    .content_block {
      margin-bottom: 35px;
    }

    .bank_details_block {
      display: flex;
      flex-direction: row;
    }
  }

  .break_line {
    width: 100%;
    border: 0.5px solid ${({ theme }) => theme.colors.black12};
    margin: 0;
  }

  .card_buttons {
    display: flex;
    justify-content: space-between;
    margin: 32px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 32px 16px;

    .screen_title {
      margin: 0;
    }

    .review_content {
      width: 100%;
    }

    .label {
      margin: 32px 0 12px 0;
    }

    .subtitle {
      margin: 0;
    }

    .break_line {
      width: 100%;
    }

    .card_buttons {
      margin: 32px 0 0 0;
    }
  }
`

const ReviewRequest = ({ setCurrentScreen, drawAmount, payType }) => {
  return (
    <>
      <ScrollToTop />
      <StyledReviewRequest>
        <div className="review_content">
          <h6 className="screen_title">
            Please review your Line of Credit Advance request
          </h6>
          <div className="content_block">
            <p className="label">Line of Credit Advance Amount</p>
            <p className="subtitle">{numberToMoney(drawAmount)}</p>
          </div>
          <div className="content_block">
            <p className="label">Date of Request</p>
            <p
              className="subtitle"
              data-testid={buildTestID(
                'date',
                'loc_advance',
                'review_request',
              )}
            >
              {' '}
              {dayjs().format('MM/DD/YYYY')}
            </p>
          </div>
          <div className="content_block">
            <p className="label">Disbursement Method</p>
            <div className="bank_details_block">
              <AccountBalanceIcon />
              <p className="subtitle">{payType}</p>
            </div>
            <p className="label">
              Changes to your preferred disbursement method or bank
              account information must be completed prior to
              submitting this request. Otherwise, funds will be
              disbursed according to your disbursement method
              currently on file.
            </p>
          </div>
        </div>
        <div className="break_line"></div>
        <div className="card_buttons">
          <Button
            outlined
            backArrow
            onClick={() => setCurrentScreen(COMPLETE_FORM)}
            text="Back"
            UID="review_request"
          />
          <Button
            onClick={() => {
              trackLOCRequest.StepThree()
              setCurrentScreen(VERIFY_ACCOUNT)
            }}
            text="Continue"
            UID="review_request"
          />
        </div>
      </StyledReviewRequest>
    </>
  )
}

ReviewRequest.propTypes = {
  setCurrentScreen: PropTypes.func.isRequired,
  drawAmount: PropTypes.string.isRequired,
  payType: PropTypes.string.isRequired,
}

export default ReviewRequest
