import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const LoanSales = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.LoanSales, title)
  })

  return (
    <>
      <h3
        ref={title}
        id="loan-sales-servicing-transfers"
        tabIndex="-1"
      >
        Loan Sales and Servicing Transfers
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              I received a letter saying my loan has been sold or is
              being serviced by a different company now. What does
              that mean?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              The sale of a loan from one lender to another, and/or
              the transfer of servicing from one servicer to another,
              is a{' '}
              <strong>
                very common, normal transaction in the mortgage
                industry.
              </strong>{' '}
              The terms of your loan do not change with a loan sale or
              servicing transfer. Please refer to the letter you
              received for the effective date of the sale or transfer
              and the new company’s contact information.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default LoanSales
