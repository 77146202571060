import React, { useState } from 'react'
import { buildTestID } from '../../Utils/utils'
import styled from 'styled-components'

const StyledShowHidePassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .show_hide {
    display: flex;
    align-items: center;

    .checkbox {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .body2 {
      margin: 0;
    }
  }
`

const ShowHidePassword = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <StyledShowHidePassword>
      <input
        {...props}
        className={props.className}
        type={showPassword ? 'text' : 'password'}
        data-testid={buildTestID(
          props.datatestid,
          '/home',
          'show_hide_input',
        )}
      />
      <div className="show_hide">
        <input
          className="checkbox"
          type="checkbox"
          id={`chk-show-password-${props.datatestid}`}
          name="chk-show-password"
          onClick={() => setShowPassword(!showPassword)}
          aria-label="Show your password"
          data-testid={buildTestID(
            props.datatestid,
            '/home',
            'show_hide_check',
          )}
        />
        <label className="body2">Show password</label>
      </div>
    </StyledShowHidePassword>
  )
}

export default ShowHidePassword
