import React, { useState } from "react"
import { Col, Form, FormGroup, Row } from 'react-bootstrap'
import styled from "styled-components"
import Button from '../../Common/button'
import useWindowSize from '../../Hooks/useWindowSize'
import { CONSENT, NUMBER_REPLACEMENT } from "."
import {
  formatPhoneNumberNormalize,
} from '../../Utils/utils'

const StyledSelectPhoneNumber = styled.div`
h1, h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }
  
  p {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 20px;
    // background: #fafafa;
  }

  input.form-control, select.form-control {
    height: 56px;
  }

  div.col:nth-child(even) {
    text-align: center;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  .phone-type {
    text-transform: capitalize;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .select_phone_number {
      background-color: #fff;
      padding: 15px;
      margin: 32px auto 0;
      width: 600px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      // text-align: center;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // padding: 24px;
      // background: #FAFAFA;
      font-size: 16px;
      // font-weight: 700;
      // margin: 25px 35px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .button-confirm-identity  {
        width: 230px !important;
      }
 }
  `

const SelectPhoneNumber = ({ borrower, primaryPhone, setView, setModalShow, phoneNumber, setPhoneNumber, otherNumberType, setOtherNumberType, mainRef }) => {
  const [loading, setLoading] = useState(false)
  const [phoneType, setPhoneType] = useState(otherNumberType ? 'other' : primaryPhone.phoneType)
  const [otherPhone, setOtherPhone] = useState(phoneNumber);
  const [otherPhoneType, setOtherPhoneType] = useState(otherNumberType || 'home');
  const [validOtherPhoneNumber, setValidOtherPhoneNumber] = useState(false)
  const [showOtherPhoneNumberValidation, setShowOtherPhoneNumberValidation] = useState(false)
  const { isSmall } = useWindowSize()

  setPhoneNumber(primaryPhone.phoneNumber)

  const validateOtherPhoneNumber = (e) => {
    if (phoneType !== 'other') 
     return;
    
    if (!otherPhone.length || otherPhone.length < 10) {
      setValidOtherPhoneNumber(false)
      setShowOtherPhoneNumberValidation(true)
      return
    }

    setValidOtherPhoneNumber(true)
    setShowOtherPhoneNumberValidation(false)
  }

  const handlePhoneOptionChange = e => {
    const phoneType = e.target.value
    setPhoneType(phoneType)
    if (phoneType !== 'other') {
      const phone = borrower.phone.find(phone => phone.phoneType === e.target.value)
      setPhoneNumber(phone.phoneNumber)
    }
  }

  const handleOtherPhoneChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "")
    setOtherPhone(value)
  }

  const handleClick = async (event) => {
    try {
      if (phoneType === 'other') {
        setPhoneNumber(otherPhone)
        setOtherNumberType(otherPhoneType)
        setView(NUMBER_REPLACEMENT)
      } else {
        setView(CONSENT)
      }
    } catch (err) {
      setLoading(false)
      event.target.blur();
    }
  }

  return (
    <StyledSelectPhoneNumber>
      <div className="select_phone_number"
        data-gridcolumnlayout={{
          xl: '1/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Great! Your identity has been confirmed.</h1>
        <p>Please select one of these numbers to use for authentication.</p>
        <p><b><u>You must select a number that is capable of receiving a text (i.e., a cell phone)</u></b>.</p>
        <p>This number will be used authenticate you every time you login.</p>
        <p>If the number you want to use is not in the list, please complete the “Other Number” information, and select it in the “Use this Number” column.</p>
        <Form>
          {borrower.phone.map((phone, index) => {
            if(phone.phoneNumber && phone.phoneNumber !== '') {
              return (
                <FormGroup index={index}>
                  <Row>
                    <Col>
                      <label><span className="phone-type">{phone.phoneType}</span> Phone</label>
                    </Col>
                    <Col>
                      <label>Use this Number</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {formatPhoneNumberNormalize(phone.phoneNumber)}
                    </Col>
                    <Col>
                      <Form.Check type="radio" aria-label={`${phone.phoneType.toLowerCase()}-type`} name="phone-type" value={phone.phoneType.toLowerCase()} checked={phoneType === phone.phoneType} onChange={handlePhoneOptionChange} />
                    </Col>
                  </Row>
                </FormGroup>
              )
            }
          })}

          <Row className="mt-3">
            <Col>
              <label>Other Phone</label>
            </Col>
            <Col>
              <label></label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control type="tel"
                name="otherPhone"
                placeholder="Enter Other phone"
                className={`${showOtherPhoneNumberValidation ? "is-invalid" : ""}`}
                onChange={handleOtherPhoneChange}
                minLength={10}
                maxLength={10}
                onBlur={validateOtherPhoneNumber}
                value={otherPhone}
                disabled={phoneType !== 'other'}
                aria-describedby="other-desc"
              />
              <span id="other-desc" className="sr-only">Please enter your phone number. Phone numbers must be 10 digits.</span>
              <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                Please enter your phone number. Phone numbers must be 10 digits.
              </Form.Control.Feedback>
              <Form.Group controlId="formSelectPhoneNumber.phoneType" className="mt-3">
                <Form.Label>Other Number Type</Form.Label>
                <Form.Control as="select"
                  disabled={phoneType !== 'other'}
                  onChange={(e) => setOtherPhoneType(e.target.value)}
                  value={otherPhoneType}>
                  <option value={"home"}>Home</option>
                  <option value={"work"}>Work</option>
                  <option value={"mobile"}>Mobile</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Check type="radio" aria-label="other-phone" name="phone-type" value="other" checked={phoneType === 'other'} onChange={handlePhoneOptionChange} />
            </Col>
          </Row>
        </Form>
        <hr />
        <div className="buttons">
          <Button
            className="button button-confirm-identity"
            text={isSmall ? "Save" : "Save My Choice"}
            type="submit"
            fullWidth
            UID="select_phone_number_submit"
            disabled={phoneType === 'other' && !otherPhone.length}
            loading={loading}
            onClick={handleClick}
          />
          <Button
            className="button cancel"
            text="Cancel"
            textButton
            fullWidth
            UID="select_phone_number_cancel"
            onClick={() => setModalShow(true)}
          />
        </div>
      </div>
    </StyledSelectPhoneNumber>
  )
}

export default SelectPhoneNumber;