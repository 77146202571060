import React from 'react'

import MyAdvanceRequests from '../Components/AdvanceRequest/MyAdvanceRequests'
import { useRetrieveLocStatus } from '../Hooks/useRetrieveLocStatus'
import { useRetrieveLocEligibility } from '../Hooks/useRetrieveLocEligibility'
import LoadingPage from '../Components/LoadingPage'
import Title from '../Common/Title'

const MyAdvanceRequestsContainer = () => {
  const {
    data: locStatusData,
    locStatusError,
    locStatusloading,
  } = useRetrieveLocStatus()

  const {
    error: eligibilityError,
    loading: eligibilityLoading,
    data: eligibilityData,
  } = useRetrieveLocEligibility()

  // TODO locStatusData should not need to be here for loading properly
  if (locStatusloading || eligibilityLoading || !locStatusData)
    return <LoadingPage />

  if (locStatusError || eligibilityError)
    return (
      <div>
        There was an error loading your Requests. Please reload the
        page and if this continues contact support.
      </div>
    )

  if (locStatusError) {
    console.log(
      'There was an error retrieving LOC status or LOC eligibility',
    )
    return (
      <div>
        There was an error, please refresh the page. If this continues
        contact support.
      </div>
    )
  }

  return (
    <>
      <Title>My Advance Requests</Title>
      <MyAdvanceRequests
        locRequests={locStatusData.data.getLOCStatus.info}
        eligibility={eligibilityData.data.getLOCEligibility.info}
      />
    </>
  )
}

export default MyAdvanceRequestsContainer
