import React, { useState } from 'react'
import Styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import StatementPreferenceModal from './modal//StatementPreference.js'
import StatementPreferenceLegalModal from './modal/StatementPreferenceLegal'
import useWindowSize from '../../Hooks/useWindowSize'
import { trackGoPaperless } from '../../Global/Analytics'
import LeafIcon from '../../assets/leaf-icon'
import {
  STATEMENT_PREFERENCE,
  GO_PAPERLESS,
  STATEMENT_PREFERENCE_LEGAL,
} from './index'
import dayjs from 'dayjs'

const StyledGoPaperless = Styled.div`
width: 100%;
padding: 32px;
margin-top: 32px;
box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
border-radius: 4px;
display: flex;
flex-direction: column;

.heading {
  margin: 0;
}

.sub_heading {
  margin: 16px 0 32px 0;
}

.group_title {
  margin: 0 0 12px 0;
}

.online_only_text {
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
}

.edit_group {
  display: flex;
  justify-content: space-between;
  margin: 0 0 32px 0;

  button {
    text-align: end;
    margin-left: 24px;
  }
}

p {
  margin: 0;
}

.note_container {
  p {
    margin: 0;
  }
  background: ${({ theme }) => theme.colors.primary4};
  padding: 16px;
  border-radius: 4px;
}

.change_delivery_button {
  >button {
    &:focus-visible {
      outline: 2px solid #1460AA;
    }
  }
}

@media (max-width: ${({ theme }) => theme.breakPoints.small}) {
  box-shadow: none;
  margin-top: 24px;
  padding: 0;

  .edit_group {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    
    .change_delivery_button {
      width: 100%;
      margin-top: 20px;
      
      button {
        text-align: center;
        margin-left: 0;
        line-height: 20px;
      }
    }
  }

  .note_container {
    margin-top: 20px;
  }
}
`

const StatementsOnlineNote = (
  statementsOnline,
  paperlessChangeDate,
) => {
  let hoursFromChangeDate = paperlessChangeDate
    ? dayjs().diff(dayjs(paperlessChangeDate.slice(0, 10)), 'hour')
    : 0

  let hoursToShowMessage =
    Number(
      process.env.REACT_APP_TIME_TO_SHOW_PAPERLESS_MESSAGE_IN_HOURS,
    ) || 720

  return hoursFromChangeDate >
    hoursToShowMessage ? null : statementsOnline ? (
    <div className="note_container">
      <p className="caption">
        Note: Thank you for choosing paperless correspondence! Please
        note that it could take a full statement cycle (one month) for
        your preference for paperless statements to take effect.
      </p>
    </div>
  ) : (
    <div className="note_container">
      <p className="caption">
        Note: Please note that it could take a full statement cycle
        (one month) for your preference for mailed statements to take
        effect.
      </p>
    </div>
  )
}

const GoPaperless = ({
  scrollRef,
  currentScreen,
  setCurrentScreen,
  setOpenBanner,
  paperlessIndicator,
  paperlessChangeDate,
  refreshLoanData,
}) => {
  const [deliveryOnline, setDeliveryOnline] = useState(
    paperlessIndicator,
  )
  const { isSmall } = useWindowSize()

  return (
    <>
      <StatementPreferenceModal
        refreshLoanData={refreshLoanData}
        show={currentScreen === STATEMENT_PREFERENCE}
        closeScreen={() => setCurrentScreen(GO_PAPERLESS)}
        deliveryOnline={deliveryOnline}
        setDeliveryOnline={setDeliveryOnline}
        setOpenBanner={setOpenBanner}
        openLegalScreen={() =>
          setCurrentScreen(STATEMENT_PREFERENCE_LEGAL)
        }
      />
      <StatementPreferenceLegalModal
        setDeliveryOnline={setDeliveryOnline}
        refreshLoanData={refreshLoanData}
        show={currentScreen === STATEMENT_PREFERENCE_LEGAL}
        closeScreen={() => setCurrentScreen(GO_PAPERLESS)}
        setOpenBanner={setOpenBanner}
      />
      {currentScreen === GO_PAPERLESS || !isSmall ? (
        <StyledGoPaperless id="go_paperless">
          <div ref={scrollRef} className="scroll" />
          <h2
            className="heading-6 heading"
            role={currentScreen === GO_PAPERLESS ? 'alert' : null}
          >
            Paperless Settings
          </h2>
          <p className="subtitle-2 sub_heading">
            Receive correspondence from us, including account
            statements,{' '}
            <b>
              <i>online only</i>
            </b>
            , unless required by law to send via mail.
          </p>
          <h3 className="subtitle-2 bold group_title">
            Delivery Preference:
          </h3>
          <div className="edit_group">
            {deliveryOnline ? (
              <div className="online_only_text">
                <LeafIcon />
                <p className="subtitle">Online only</p>
              </div>
            ) : (
              <p className="subtitle">Mail</p>
            )}
            {isSmall
              ? StatementsOnlineNote(
                  deliveryOnline,
                  paperlessChangeDate,
                )
              : null}
            <Button
              className="change_delivery_button"
              text="Change my delivery preference"
              onClick={() => {
                trackGoPaperless.Started()
                setCurrentScreen(STATEMENT_PREFERENCE)
              }}
              UID="go_paperless"
              textButton={isSmall ? false : true}
              fullWidth={isSmall ? true : false}
            />
          </div>
          {isSmall
            ? null
            : StatementsOnlineNote(
                deliveryOnline,
                paperlessChangeDate,
              )}
        </StyledGoPaperless>
      ) : null}
    </>
  )
}

GoPaperless.propTypes = {
  scrollRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  currentScreen: PropTypes.string.isRequired,
  setCurrentScreen: PropTypes.func.isRequired,
  setOpenBanner: PropTypes.func.isRequired,
  paperlessIndicator: PropTypes.bool.isRequired,
  refreshLoanData: PropTypes.func.isRequired,
}

export default GoPaperless
