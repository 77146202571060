import React, { useState, useEffect } from 'react'
import Styled from 'styled-components'
import { Container, useAccordionToggle, Col } from 'react-bootstrap'

import { HashLink as Link } from 'react-router-hash-link'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'

import Popular from './Popular'
import HowReverseMortgagesWork from './HowReverseMortgagesWork'
import RequestingFunds from './RequestingFunds'
import Statements from './Statements'
import IncomeTaxes1098Forms from './IncomeTaxes1098'
import RepairsInsuranceTaxes from './RepairsInsuranceTaxes'
import LossDraft from './LossDraft'
import Occupancy from './Occupancy'
import DefaultMaturity from './DefaultMaturity'
import WhatDoIDoWhenMyLoanIsDue from './WhatDoIDoWhenMyLoanIsDue'
import MailingAddress from './MailingAddress'
import PrepaymentsPayoffs from './PrepaymentsPayoffs'
import Refinancing from './Refinancing'
import LoanSales from './LoanSales'
import ThirdPartyAuthorizations from './ThirdPartyAuthorizations'
import GettingHelp from './GettingHelp'
import UsefulLinks from './UsefulLinks'
import Trusts from './Trusts'
import Spouses from './Spouses'

import { handleScrollIntoView } from '../../Utils/utils'
import ScrollUpButton from '../../Common/ScrollUpButton'
import { useFocuses } from '../../Hooks/useFocuses'
import { usePrevious } from '../../Hooks/usePrevious'
import Title from '../../Common/Title'
import H1 from '../../Common/H1'
import CommonQuestionCategories from './CommonQuestionCategories'
import { trackFAQViewed } from '../../Global/Analytics'
import MonthlyPayments from './MonthlyPayments'
import EftDirectDeposit from './EftDirectDeposit'
import PaymentPlanChanges from './PaymentPlanChanges'
import Search from './Search'
import Bankruptcy from './Bankruptcy'
import RepairsRequiredAtLoanOrigination from './RepairsRequiredAtLoanOrigination'

const StyledCommonQuestionsModern = Styled.div`
    font-size: 20px;
    margin-bottom: 100px;
    overflow-y: scroll;

    a {
        color: #007bff;
        font-weight: bold;
        text-decoration: underline;
    }

    .sidebar{ 
      a {
        color: #000;
      }
    }

    .under-line {
      text-decoration: underline;
    }

    .underline {
      text-decoration: underline;
    }

  .subheading {
    text-align: center;
    margin-top: -25px;
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    flex-direction: row;
    height: 100%;

    .sidebar {
      top: 0;
      position: -webkit-sticky;
      position: sticky;
      height: 50vh;
      font-size: 16px;
      margin-right: 20px;
      padding-right: 20px;

      ul {
        padding-left: 0;
        margin-top: 10px;
        list-style-type: none;

        li {
          display: flex;
          justify-content: space-between;
          color: #000;
          margin-bottom: 20px;
          color: var(--Black, #000);
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          /* 120% */
          text-transform: capitalize;
        }
      }

      ol {
        padding-left: 0;
        margin-bottom: 20px;

        li {
          margin-bottom: 10px;
          font-size: 20px;
          margin-top: 10px;
          margin-left: 30px;
        }
      }
    }

    .questions {
      width: 100%;
      word-break: break-word;
    }

    .accordion {
      margin-bottom: 40px;
    }
  }

h3 {
  color: var(--Black, #000);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 113.333% */
  margin-bottom: 24px;
}

h4 {
  color: var(--Black, #000);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 113.333% */
}

.title {
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
}

  .accordion>.card:not(:last-of-type) {
      border-bottom: unset;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
  }

  .accordion>.card:not(:first-of-type) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .card {
    margin-bottom: 0;
    font-size: 20px;
    border-radius: 20px;
    background: var(--White, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 8px;
  }

  .card-header {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
    padding-top: 28px;
    padding-bottom: 28px;

    svg {
      order: 1;
    }

    button {
      background: ${({ theme }) => theme.colors.white};
      color: #000;
      border: none;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  .pipe {
    border-left: 1px solid #E2F5FF;
    height: 60px;
    padding: 0 10px;
  }

    @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
      .content {
        flex-direction: column;
      }
      div#sidebar {
        height: 100%;
      }
    }
`

function CustomToggle({ children, eventKey }) {
  const [accordionOpen, setAccordionOpen] = useState(false)
  const { previous } = usePrevious(accordionOpen)

  useEffect(() => {
    previous && setAccordionOpen(false)
  }, [previous])

  const toggleAccordion = useAccordionToggle(eventKey, () =>
    setAccordionOpen(!accordionOpen),
  )

  const handleClick = (e) => {
    toggleAccordion()
    if (!accordionOpen) {
      const question =
        typeof children === 'string'
          ? children
          : children.join('').trim()

      trackFAQViewed(question)
    }
  }

  return (
    <button onClick={handleClick} aria-expanded={accordionOpen}>
      {' '}
      {accordionOpen ? (
        <FontAwesomeIcon icon={faChevronDown} />
      ) : (
        <FontAwesomeIcon icon={faChevronRight} />
      )}
      {children}
    </button>
  )
}

const CommonQuestions =() => {
  const [refs, addRefs] = useState([])
  const { focus } = useFocuses(refs, false)

  const addRef = (key, ref) => {
    if (!refs.some((x) => x.key.toLowerCase() === key.toLowerCase()))
      addRefs([...refs, { key, ref }])
  }

  useEffect(() => {
    const hash = window.location.hash;
    if (!hash) return;
    const anchor = document.getElementById(hash.slice(1));
    if (!anchor) return;
    anchor.scrollIntoView({ behavior: 'instant' });
  }, []);
  return (
    <main id="main" tabIndex="0">
      <Title>Common Questions</Title>
      <Container>
        <Search />
        <StyledCommonQuestionsModern>
          <H1 className="title">Common Questions*</H1>
          <div role="doc-subtitle" className="subheading">*Actual requirements for non-FHA insured reverse mortgages may vary.</div>
          <div className="content">
            <Col className="sidebar" sm="4" id="sidebar">
              <h4>Go To Topic</h4>
              <ul>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.Popular)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#popular"
                  >
                    Popular
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.UsefulLinks)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#useful-links"
                  >
                    Helpful Resources & Useful Links
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(
                        CommonQuestionCategories.HowReverseMortgagesWork,
                      )
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#how-do-reverse-mortgages-work"
                  >
                    How Do Reverse Mortgages Work?
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.RequestingFunds)
                    }}
                    to="#requesting-funds"
                  >
                    Requesting Funds
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.MonthlyPayments)
                    }}
                    to="#monthly-payments"
                  >
                    Monthly Payments
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.EftDirectDeposit)
                    }}
                    to="#eft-direct-deposit"
                  >
                    EFT/Direct Deposit
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.PaymentPlanChanges)
                    }}
                    to="#payment-plan-changes"
                  >
                    Payment Plan Changes
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.Statements)
                    }}
                    to="#statements-loan-documents-fees"
                  >
                    Statements, Loan Documents, and Fees
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    onClick={(e) =>
                      focus(CommonQuestionCategories.IncomeTaxes1098)
                    }
                    to="#income-taxes-1098-forms"
                  >
                    Income Taxes and 1098 Forms
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(
                        CommonQuestionCategories.RepairsInsuranceTaxes,
                      )
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#repairs-insurance-taxes-set-asides"
                  >
                    Set Asides: Taxes, Insurance, Repairs
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(
                        CommonQuestionCategories.RepairsRequiredAtLoanOrigination,
                      )
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#repairs-required-at-loan-origination"
                  >
                    Repairs Required at Loan Origination
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(
                        CommonQuestionCategories.LossDraft,
                      )
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#loss-draft-insurance-claims-property-damage"
                  >
                    Insurance Claims (Loss Draft)/Property Damage
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.Occupancy)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#occupancy"
                  >
                    Occupancy
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.DefaultMaturity)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#default-maturity-due-payable"
                  >
                    Default, Maturity / Due and Payable
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(
                        CommonQuestionCategories.WhatDoIDoWhenMyLoanIsDue,
                      )
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#what-do-i-do-when-my-loan-is-due"
                  >
                    What Do I Do When My Loan is Due?
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.MailingAddress)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#mailing-address-name-changes"
                  >
                    Mailing Address and Name Changes
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(
                        CommonQuestionCategories.PrepaymentsPayoffs,
                      )
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#prepayments-payoffs"
                  >
                    Prepayments and Payoffs
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.Refinancing)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#refinancing"
                  >
                    Refinancing
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.LoanSales)
                    }}
                    to="#loan-sales-servicing-transfers"
                  >
                    Loan Sales, and Servicing Transfers
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(
                        CommonQuestionCategories.ThirdPartyAuthorizations,
                      )
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#third-party-authorizations"
                  >
                    Representations and Powers of Attorney
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.Trusts)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#trusts"
                  >
                    Trusts
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.Spouses)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#spouses"
                  >
                    Spouses
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.Bankruptcy)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#bankruptcy"
                  >
                    Bankruptcy
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      e.preventDefault()
                      focus(CommonQuestionCategories.GettingHelp)
                    }}
                    scroll={(e) => handleScrollIntoView(e, 'smooth')}
                    to="#getting-help"
                  >
                    Getting Help
                  </Link>
                  <FontAwesomeIcon icon={faChevronRight} />
                </li>
              </ul>
            </Col>

            <div className="questions" sm="9" id="content">
              <Popular CustomToggle={CustomToggle} addRef={addRef} />
              <UsefulLinks
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <HowReverseMortgagesWork
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <RequestingFunds
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <MonthlyPayments
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <EftDirectDeposit
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <PaymentPlanChanges
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <Statements
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <IncomeTaxes1098Forms
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <RepairsInsuranceTaxes
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <RepairsRequiredAtLoanOrigination
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <LossDraft
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <Occupancy
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <DefaultMaturity
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <WhatDoIDoWhenMyLoanIsDue
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <MailingAddress
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <PrepaymentsPayoffs
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <Refinancing
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <LoanSales
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <ThirdPartyAuthorizations
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <Trusts CustomToggle={CustomToggle} addRef={addRef} />
              <Spouses CustomToggle={CustomToggle} addRef={addRef} />
              <Bankruptcy
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <GettingHelp
                CustomToggle={CustomToggle}
                addRef={addRef}
              />
              <ScrollUpButton />
            </div>
          </div>
        </StyledCommonQuestionsModern>
      </Container>
    </main>
  )
}

export default CommonQuestions
