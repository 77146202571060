import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Auth,Cache } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useHistory, useLocation } from 'react-router-dom'

import { storeCognitoId } from '../services/api'
import useLogoutSubscription from '../Hooks/useLogoutSubscription'
import SignIn from '../Components/auth/SignIn'
import SignUp from '../Components/auth/SignUp'
import ConfirmSignIn from '../Components/auth/ConfirmSignIn'
import ConfirmSignUp from '../Components/auth/ConfirmSignUp'
import ConfirmPhone from '../Components/auth/ConfirmPhone'
import AddPhone from '../Components/auth/AddPhone'
import ForgotPassword from '../Components/auth/ForgotPassword'
import OnStateChangeContext from '../Contexts/OnStateChangeContext'
import AuthLayout, { CONFIRM_SIGN_IN, CONFIRM_SIGN_UP } from '../Components/AuthLayout'
import SignUpLayout from '../Components/SignUpLayout'
import LoadingPage from '../Components/LoadingPage'
import { analyticsReset, trackRegistration } from '../Global/Analytics'
import { removeAllApiCacheEntries } from '../services/api'
import { checkIsUserSignedIn } from '../services/auth'
import MyLoanContainer from '../Containers/MyLoanContainer'
import FinishYourAccountContainer from '../Containers/FinishYourAccountContainer';
import { getCookie, setCookie } from '../Utils/utils';

const AuthRouter = ({
  // authState,
  // authData,
  children,
  // onStateChange,
  isAuthenticated,
  setUserInfo,
  setIsAuthenticated
}) => {
  const location = useLocation();

  const { user, authStatus } = useAuthenticator(context => [context.authStatus, context.user]);
  const [authState, setAuthState] = useState(isAuthenticated ? 'signedIn' : location.pathname.includes('register') ? 'signUp' : 'signIn');
  const [authData, setAuthData] = useState({ user, redirect: user ? true : false });
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [skip, setSkip] = useState(false)
  let history = useHistory()

  useEffect(() => {
    setAuthData({ user, redirect: true })
  }, [user])

  useLayoutEffect(() => {
    const checkIfUserIsAuthenticated = async () => {
      if (authStatus === 'authenticated') {
        try {
          const isUserSignedIn = await checkIsUserSignedIn()
          if (!isUserSignedIn) {
            Auth.signOut()
            history.push('/')
          }
        } catch (error) {
          Auth.signOut()
          history.push('/')
        }
      }

      if (authState !== 'signedIn' && authState !== 'loading') {
        if (isAuthorized) setIsAuthorized(false)
        removeAllApiCacheEntries()
      }

      if (authState !== "signIn" && authState !== "signUp" && authState !== "loading")
        checkIfUserIsAuthenticated()
    }
  }, [isAuthorized, authState, history, authStatus])

  useLogoutSubscription(() => {
    Auth.signOut()
    history.push('/')
  })

  const { pathname } = useLocation()

  const onValidRegisterPath =
    pathname.includes('paperless') || pathname.includes('register')

  useEffect(() => {
    if (!isAuthenticated) {
      // navigates back to home page at end of signIn flow
      if (onValidRegisterPath && authState === 'signIn') {
        history.push('/')
        // changes auth state to match /register endpoint
      } else if (
        onValidRegisterPath &&
        authState !== 'confirmSignUp'
      ) {
        setAuthState('signUp')
      }

      if (onValidRegisterPath && authState === 'signUp') {
        trackRegistration.Started()
      }
    }
  }, [
    authState,
    authData,
    onValidRegisterPath,
    history,
    isAuthenticated,
  ])

  return (
    <OnStateChangeContext.Provider value={authState}>
      {getPage(
        authState,
        setAuthState,
        authData,
        setAuthData,
        children,
        // onStateChange,
        isAuthorized,
        setIsAuthorized,
        history,
        setUserInfo,
        setIsAuthenticated,
        authStatus,
        setSkip,
        skip
      )}
    </OnStateChangeContext.Provider>
  )
}

const getPage = (
  authState,
  setAuthState,
  authData,
  setAuthData,
  children,
  // onStateChange,
  isAuthorized,
  setIsAuthorized,
  history,
  setUserInfo,
  setIsAuthenticated,
  authStatus,
  setSkip,
  skip
) => {
  const user = localStorage.getItem('ajs_user_traits');
  // console.log(user,"userrr",authState)
  if (authStatus === 'unauthenticated'){
    setIsAuthenticated(false)
    if (user) {
      localStorage.removeItem('ajs_user_traits');
      Cache.clear()
    }
    if (localStorage.getItem('aws-amplify-cacheCurSize')){
      Cache.clear()
    }
}

  switch (authState) {
    case 'setup':
    case 'signIn': {
      return (
        user ? <LoadingPage /> :
          <AuthLayout setAuthState={setAuthState} setAuthData={setAuthData} setUserInfo={setUserInfo} setIsAuthenticated={setIsAuthenticated}>
            <SignIn setAuthState={setAuthState} setAuthData={setAuthData} setUserInfo={setUserInfo} setIsAuthenticated={setIsAuthenticated} />
          </AuthLayout>
      )
    }

    case 'signUp': {
      return (
        <SignUpLayout>
          <SignUp setAuthState={setAuthState} setAuthData={setAuthData} />
        </SignUpLayout>
      )
    }

    case 'confirmSignIn': {
      return (
        <AuthLayout authData={authData} setAuthState={setAuthState} setAuthData={setAuthData} setUserInfo={setUserInfo} setIsAuthenticated={setIsAuthenticated} activeTab={CONFIRM_SIGN_IN}>
          <ConfirmSignIn authData={authData} setAuthState={setAuthState} setAuthData={setAuthData} setUserInfo={setUserInfo} setIsAuthenticated={setIsAuthenticated} />
        </AuthLayout>
      )
    }

    case 'confirmSignUp':
      return (
        <AuthLayout authData={authData} setAuthState={setAuthState} setAuthData={setAuthData} setUserInfo={setUserInfo} setIsAuthenticated={setIsAuthenticated} activeTab={CONFIRM_SIGN_UP}>
          <ConfirmSignUp setAuthState={setAuthState} authData={authData} />
        </AuthLayout>
      )

    case 'confirmPhoneAndMFA': {
      return <FinishYourAccountContainer setAuthState={setAuthState} setSkip={setSkip} />
    }

    case 'confirmPhone': {
      return (
        <AuthLayout>
          <ConfirmPhone authData={authData} setAuthState={setAuthState} />
        </AuthLayout>
      )
    }

    case 'addPhone': {
      return (
        <AuthLayout>
          <AddPhone authData={authData} setAuthState={setAuthState} />
        </AuthLayout>
      )
    }

    case 'forgotPassword': {
      return (
        <AuthLayout>
          <ForgotPassword setAuthState={setAuthState} />
        </AuthLayout>
      )
    }

    case 'publicPage': {
      return <>{authData}</>
    }

    case 'signedIn': {
      if (!authData.user)
        handleSignedIn(setAuthState, setIsAuthorized, history, skip)

      const views = ['esign', 'finish-your-account', 'account-settings', 'upload', 'payoff-quote', 'advance-request', 'enable-multifactor']

      if ((views.some(view => history.location.pathname.includes(view))) && authData)
        authData.redirect = false;

      if (authData?.redirect) {
        authData.redirect = false
        // Need to change this value before deployment
        if (new Date().getDate() <= 9) {
          history.push('/finish-your-account')
          return <FinishYourAccountContainer />
        }
        history.push('/my-loan')
        return <MyLoanContainer />
      }

      return authData ? <>{children}</> : <LoadingPage />
    }

    case 'loading':
    default: {
      return <React.Fragment />
    }
  }
}

const handleSignedIn = async (setAuthState, setIsAuthorized, history, skip) => {
  const user = await Auth.currentAuthenticatedUser()
  const confirmSignUpCookie = getCookie('confirm_sign_up')
  const storeCognitoIdCookie = getCookie('store_cognito_id')

  if(!storeCognitoIdCookie){
    setCookie('store_cognito_id', 'true', 1)
    storeCognitoId()
  }

  if (!user.attributes['email_verified']) {
    setAuthState('confirmSignUp')
    return
  }

  if (!user.attributes['phone_number_verified'] && !user.attributes['custom:phone_verif_opt_out'] && !skip  && confirmSignUpCookie === 'true') {
    setCookie('confirm_sign_up', 'false', -1)
    history.push('/finish-your-account')
    setAuthState('confirmPhoneAndMFA')
    return
  }

  setIsAuthorized(true)
}

export default AuthRouter