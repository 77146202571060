import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import UploadIcon from '../../assets/upload-icon'
import DocumentIcon from '../../assets/document-icon'
import { buildTestID } from '../../Utils/utils'
import useWindowSize from '../../Hooks/useWindowSize'

const StyledUploadSection = styled.div`
  margin: 40px 0 0 12px;

  .upload_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    text-align: center;
    border-radius: 4px;
    border: 1px dashed ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary4};

    h2 {
      color: ${({ theme }) => theme.colors.primaryDark};
      margin: 24px 0 0 0;
    }
  }

  .file_uploaded {
    cursor: not-allowed;
    border: 1px dashed ${({ theme }) => theme.colors.black60};
    background: ${({ theme }) => theme.colors.lightGrey};

    h2 {
      color: ${({ theme }) => theme.colors.black60};
    }

    svg {
      path {
        fill: ${({ theme }) => theme.colors.black60};
      }
    }
  }

  .error {
    border-color: ${({ theme }) => theme.colors.error};
  }

  h5 {
    margin: 26px 0 16px 0;
  }

  .uploaded_file {
    width: 100%;
    position: absolute;
    display: flex;
    box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06),
      0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    border-radius: 4px;
    padding: 12px;

    svg {
      min-width: 16px;
    }

    p {
      margin: 0 0 12px 12px;
      word-break: break-word;
    }
    .button {
      margin: 0 0 0 12px;
      justify-content: start;
    }
  }

  .error_text {
    color: ${({ theme }) => theme.colors.error};
    margin: 16px 0 0 0;
  }

  .textButton:focus {
    outline: 2px solid #1460AA;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    margin-left: 0;

    .uploaded_file {
      position: inherit;
    }
  }
`

const Upload = ({
  setFileToUpload,
  fileUploaded,
  fileToUpload,
  invalidFileError,
  getInputProps,
  isDragActive,
  dynamicUpload,
}) => {
  const { isSmall } = useWindowSize()

  return (
    <StyledUploadSection {...dynamicUpload()}>
      <div
        role="button"
        className={`upload_box 
        ${fileUploaded ? 'file_uploaded' : null}
        ${invalidFileError ? 'error' : null}`}
      >
        <input
          data-testid={buildTestID('drag_form_here', '/upload')}
          {...getInputProps()}
        />
        <UploadIcon />
        <h2 className="subtitle bold" aria-label={isSmall ? "Click here to select and upload your file" : "Drag and drop your completed file here or select it from your computer"}>
          {isDragActive ? (
            'Drag and drop your file here'
          ) : isSmall ? (
            <u>Click here to select and upload your file</u>
          ) : (
            <>
              Drag and drop your completed file here or{' '}
              <u>select it from your computer</u>
            </>
          )}
        </h2>
      </div>
      {invalidFileError ? (
        <h6 role="alert" className="caption error_text">
          {invalidFileError}
        </h6>
      ) : null}
      {fileUploaded ? (
        <>
          <h5 className="subtitle bold">File Uploaded:</h5>
          <div className="uploaded_file">
            <DocumentIcon />
            <div>
              <p
                role="alert"
                aria-label="file uploaded, please select a form type, then 
                click download at the bottom of the page"
                className="subtitle-2"
              >
                {fileToUpload[0].name}
              </p>
              <Button
                className="button"
                textButton
                text="Remove Upload"
                onClick={() => setFileToUpload([])}
              />
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </StyledUploadSection>
  )
}

Upload.propTypes = {
  setFileToUpload: PropTypes.func.isRequired,
  fileUploaded: PropTypes.bool.isRequired,
  fileToUpload: PropTypes.array.isRequired,
  invalidFileError: PropTypes.string.isRequired,
  getInputProps: PropTypes.func.isRequired,
  dynamicUpload: PropTypes.func.isRequired,

  isDragActive: PropTypes.bool.isRequired,
}

export default Upload
