import React from 'react'
import Multifactor from '../Components/EnableMultifactor'

import LoadingPage from '../Components/LoadingPage'
import Title from '../Common/Title'

import { useLoggedInUser } from '../Hooks/useLoggedInUser'
import { useApiContext } from '../Hooks/useApiContext'
import { extractBorrowerAndCoborrowers } from '../Utils/apiExtractors'
import { useLocation } from 'react-router-dom'
import FinishYourAccountEnableMultifactor from '../Components/EnableMultifactor/FinishYourAccountWrapper'

const EnableMultifactorContainer = ({ setCurrentScreen, completed, setCompleted }) => {
    const context = useApiContext()
    const location = useLocation()

    const {
        error: userError,
        loading: loanLoading,
        data: loanData,
    } = context.loan
    const { data, loading } = useLoggedInUser()

    if (loanLoading || loading) {
        return <LoadingPage />
    }

    if (userError) {
        return <div>{(userError).message}</div>
    }

    const [borrower] = loanData
        ? extractBorrowerAndCoborrowers(
            loanData,
            data?.attributes['custom:person_id'],
        )
        : [null]

    borrower.loanNumber = loanData.data.loan.loanData.loanId;

    return (
        <>
            <Title>Enable Multifactor Authentication</Title>
            {location.pathname === '/finish-your-account'
                ? <FinishYourAccountEnableMultifactor borrower={borrower} setCurrentScreen={setCurrentScreen} completed={completed} setCompleted={setCompleted} />
                : <Multifactor borrower={borrower} />}
        </>
    )
}

export default EnableMultifactorContainer
