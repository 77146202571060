import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }
`

const CheckGreenIcon = () => {
    return (
        <StyledSVG
            aria-hidden="true"
            role="img"
            width="18"
            height="13"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1315 2.23212L15.0102 0.110805L7.23204 7.88898L2.9894 3.64634L0.868083 5.76766L5.11072 10.0103L6.52494 11.4245L7.23204 12.1316L17.1315 2.23212Z" fill="#08D541" />
        </StyledSVG>
    )
}

export default CheckGreenIcon
