import React from 'react'

import AccountSettings from '../Components/AccountSettings'
import Title from '../Common/Title'
import {
  extractLoan,
  extractBorrowerAndCoborrowers,
} from '../Utils/apiExtractors'
import { useApiContext } from '../Hooks/useApiContext'
import LoadingPage from '../Components/LoadingPage'
import { useLoggedInUser } from '../Hooks/useLoggedInUser'
import { useESignConsentAgreement } from '../Hooks/useESignConsentAgreement'

const AccountSettingsContainer = () => {
  const { loan } = useApiContext()
  const {
    error: loanError,
    loading: loanLoading,
    data: loanData,
    refresh: refreshLoanData,
  } = loan

  const {
    error: userError,
    loading: userLoading,
    data: userData,
  } = useLoggedInUser()

  const {
    error: eSignAgreementError,
    loading: eSignAgreementLoading,
    data: eSignAgreementData
  } = useESignConsentAgreement()

  if (loanLoading || !loanData || userLoading || !userData || eSignAgreementLoading || !eSignAgreementData)
    return <LoadingPage />

  if (loanError || userError || eSignAgreementError) return <div>{loanError.message}</div>

  const { paperlessIndicator, paperlessChangeDate } =
    extractLoan(loanData).disclosures
  const [borrowerResponse, coborrowersResponse] =
    extractBorrowerAndCoborrowers(
      loanData,
      userData.attributes['custom:person_id']
    )

  const borrower = {
    fullName: `${borrowerResponse.firstName} ${borrowerResponse.lastName}`,
    birthDate: borrowerResponse.birthDate,
    houseNumberStreeName: borrowerResponse.address[0].address1,
    cityStateZip: `${borrowerResponse.address[0].city}, ${borrowerResponse.address[0].state} ${borrowerResponse.address[0].postalCode}`,
    phoneNumber: borrowerResponse.phone.find(
      ({ primaryIndicator }) => primaryIndicator === true,
    )?.phoneNumber,
    coborrowers: coborrowersResponse.map(
      (person) => person.firstName + ' ' + person.lastName,
    ),
    email: userData.attributes["email"],
    eSignExpiredDate: eSignAgreementData.data.agreementSignatureConsent.responseData.responseCode === 200
      ? eSignAgreementData.data.agreementSignatureConsent.agreementSignature[0].expirationDate
      : null,
    signatureId: eSignAgreementData.data.agreementSignatureConsent.responseData.responseCode === 200
      ? eSignAgreementData.data.agreementSignatureConsent.agreementSignature[0].signatureId
      : null
  }

  return (
    <>
      <Title>Account Settings</Title>
      <AccountSettings
        paperlessIndicator={Boolean(paperlessIndicator === 'OPTIN')}
        paperlessChangeDate={paperlessChangeDate}
        refreshLoanData={refreshLoanData}
        borrower={borrower}
      />
    </>
  )
}

export default AccountSettingsContainer
