import * as React from "react"
import styled from "styled-components"
import PayByPhoneIcon from "../../assets/pay-by-phone-icon"
import { buildTestID } from "../../Utils/utils"
import PaymentCardComputerIcon from "../../assets/payment-card-computer-icon"
import PaymentsCardMailIcon from '../../assets/payments-card-mail-icon.js'
const CardWrapper = styled.div`
  border-radius: 20px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
  background-color: #fff;
  display: flex;
  gap: 10px;
  padding: 20px 11px;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    max-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 0;
    background-color: unset;
    box-shadow: none;
    padding: 0;
    gap: unset;
 }

  @media (max-width: ${({theme})=>theme.breakPoints.medium}) and (min-width: ${({ theme }) => theme.breakPoints.xSmall}){
    width:33%;
    flex-direction:column;
    align-items:center;
    h3{
      text-align:center;
    }
  }

  @media (max-width: ${({theme})=>theme.breakPoints.xSmall}){
    svg{
      width:65px;
    }
  }
`;

const CardImage = styled.div`
  aspect-ratio: 1;
  object-fit: cover;
  margin: auto 0;

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    width: 136px;
    height: 136px;
    object-fit: cover;
    margin-bottom: -68px;
    z-index: 1;
  }
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;

    .div-wrapper {
      align-items: center;
      background-color: #475468;
      border: 1px solid;
      border-radius: 10px;
      display: inline-flex;
      flex: 0 0 auto;
      gap: 10px;
      justify-content: center;
      padding: 10px 20px;
      position: relative;
      margin: 20px 0;

      .text-wrapper {
        font-family: "Lato-Bold", Helvetica;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 24px;
        position: relative;
        width: fit-content;
        margin-bottom: 0;
      }

      a {
        color: #fff;
      }
    }

    .phone-link {
      color: var(--Orange, #C45835);
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 145.455% */
    }

    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 60px 20px 20px 20px;
      border-radius: 20px;
      background-color: #fff;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.13);
      max-height: 375px;
      min-height: 375px;
    }

    @media (max-width: ${({ theme }) => theme.breakPoints.medium}) and (min-width: ${({ theme }) => theme.breakPoints.xSmall}){
      .div-wrapper{
        width:50%;
        margin:20px auto;
      }
    }
`;

const CardTitle = styled.h3`
  margin: 25px 0 0;
  color: #000;
  font: 700 22px/1.09 Lato, sans-serif;
`;

const CardDescription = styled.p`
  margin: 10px 0 0;
  @media (max-width: ${({ theme }) => theme.breakPoints.medium}){
    text-align:center;
  }
  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    margin: 39px 0 0;
    color: #475468;
    font: 400 16px/1.25 Lato, sans-serif;
  }
`;

export const SEND_FROM_COMPUTER = 'SEND_FROM_COMPUTER';
export const SEND_VIA_FAX = 'SEND_VIA_FAX';
export const SEND_VIA_MAIL = 'SEND_FROM_MAIL';
export const SEND_VIA_EMAIL = 'SEND_VIA_EMAIL';

const PaymentCards = () => {
  return (
    <>
      <CardWrapper>
        <CardImage>
          <PaymentCardComputerIcon />
        </CardImage>
        <CardContent>
          <CardTitle>Online</CardTitle>
          <CardDescription>
            Click here to go to our bill pay website:
          </CardDescription>
          <div className="div-wrapper">
            <div className="text-wrapper-2"><a href="https://secure.directbiller.com/pbills/payer/welcomeDirect.do?h=1gzzt5f3tn" target="_blank" rel="noreferrer" data-testid={buildTestID('banner_repayment_link', '/', 'repayment_link')}>Pay Online</a></div>
          </div>
        </CardContent>
      </CardWrapper>
      <CardWrapper>
        <CardImage>
          <PayByPhoneIcon />
        </CardImage>
        <CardContent>
          <CardTitle>Pay by Phone</CardTitle>
          <CardDescription>
            Call our bill pay line directly at<br/><br/>
            <a href="tel:8773424480" className="phone-link">877-342-4480</a><br/><br/>
            or call the phone number shown on your monthly statement and follow the voice prompts to be transferred to the bill pay line.
          </CardDescription>
        </CardContent>
      </CardWrapper>
      <CardWrapper>
        <CardImage>
          <PaymentsCardMailIcon />
        </CardImage>
        <CardContent>
          <CardTitle>Pay by Mail</CardTitle>
          <CardDescription>
            Send a check or money order<br />
            (<i>please write your loan number on the payment</i>) to:<br /><br />
            <b>Reverse Mortgage Servicing, ATTN:</b><br />
            <b>Payment Processing PO Box 40761,</b><br />
            <b>Lansing, MI 48901-7961</b>
          </CardDescription>
        </CardContent>
      </CardWrapper>
    </>
  )
}

export default PaymentCards;