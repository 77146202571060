import { createContext } from 'react'

import { getLoan, getTransactions } from '../services/api'
import { useApi } from '../Hooks/useApi'

export const ApiContext = createContext({
  transactions: { loading: null, data: null, error: null },
  loan: { loading: null, data: null, error: null, refresh: () => {} },
})

export const ApiProvider = ({ isAuthenticated, children }) =>
  isAuthenticated ? (
    <ApiProviderAuthenticated>{children}</ApiProviderAuthenticated>
  ) : (
    <>{children}</>
  )

const ApiProviderAuthenticated = ({ children }) => {
  const { data: transactionsData } = useApi(getTransactions)
  const { data: loanData, refresh: loanRefresh } = useApi(getLoan)

  return (
    <ApiContext.Provider
      value={{
        transactions: transactionsData,
        loan: { ...loanData, refresh: loanRefresh },
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
