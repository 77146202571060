import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme }) => theme.colors.success};
  }
`
const VerifiedIcon = ({ dataTestid }) => {
  return (
    <StyledSVG
      data-testid={dataTestid}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      fill="none"
    >
      <path
        fill="#2E7D32"
        d="M9 0L0 4v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V4L9 0zM7 16l-4-4 1.41-1.41L7 13.17l6.59-6.59L15 8l-8 8z"
      ></path>
    </StyledSVG>
  )
}

export default VerifiedIcon
