import React, { useEffect, useState } from 'react'
import ReactScrollUpButton from 'react-scroll-up-button'

const ScrollUpButton = () => {
  const [scrollUpSelected, setScrollUpSelected] = useState(false)

  useEffect(() => {
    if (scrollUpSelected)
      setTimeout(() => setScrollUpSelected(false), 100)
  }, [scrollUpSelected])

  return (
    <div
      tabIndex="0"
      onClick={() => setScrollUpSelected(true)}
      onKeyPress={() => setScrollUpSelected(true)}
    >
      <ReactScrollUpButton />
      {scrollUpSelected ? (
        <div
          role="alert"
          aria-label="you have scrolled to the top of the page"
        />
      ) : null}
    </div>
  )
}

export default ScrollUpButton
