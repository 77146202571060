import React from 'react'

import MyLoan from '../Components/MyLoan/index'
import Title from '../Common/Title'
import { formatDate, FormatMoney } from '../Utils/utils'
import {
  extractLoan,
  extractBorrowerAndCoborrowers,
  extractMonetaryEventSummary,
  extractServicer,
} from '../Utils/apiExtractors'
import { useApiContext } from '../Hooks/useApiContext'
import LoadingPage from '../Components/LoadingPage'
import { useLoggedInUser } from '../Hooks/useLoggedInUser'
import { useRetrieveLocEligibility } from '../Hooks/useRetrieveLocEligibility'

const MyLoanContainer = () => {
  const { data: eligibilityData } = useRetrieveLocEligibility()
  const { transactions: apiTransactions, loan: apiLoan } = useApiContext()

  const {
    error: loanError,
    loading: loanLoading,
    data: loanData,
  } = apiLoan

  const {
    error: userError,
    loading: userLoading,
    data: userData,
  } = useLoggedInUser()

  const {
    error: transactionsError,
    loading: transactionsLoading,
    data: transactionsData,
  } = apiTransactions

  if (
    transactionsLoading ||
    loanLoading ||
    userLoading ||
    !loanData 
    ||
    !eligibilityData
  ) {
    return <LoadingPage />
  }

  if (transactionsError || loanError || userError) {
    return <div>{(transactionsError || loanError).message}</div>
  }

  const servicer = extractServicer(loanData)
  const loanResponse = extractLoan(loanData)
  const summaryResponse = extractMonetaryEventSummary(loanData)
  const [borrowerResponse] = extractBorrowerAndCoborrowers(
    loanData,
    userData.attributes['custom:person_id'],
  )

  const loan = {
    accountNumber: loanResponse.loanId.toString(),
    loanType: loanResponse.product.productName,
    enrolledInPaperless: Boolean(
      loanResponse.disclosures.paperlessIndicator === 'OPTIN',
    ),
    netPrincipleLimit: FormatMoney(summaryResponse.principalLimit),
    totalLoanBalance: FormatMoney(summaryResponse.upbAmount),
    setAsideFunds: FormatMoney(summaryResponse.serviceFeesetAside),
    interestRate: (summaryResponse.currentInterestRate * 100)
      .toFixed(5)
      .toString(),
    availableLineOfCredit: FormatMoney(summaryResponse.locNetAmount),
    accountType: loanResponse.product.productName,
    availableFirstYearLineOfCredit:
      summaryResponse.firstYearLOCAvailable
        ? FormatMoney(summaryResponse.firstYearLOCAvailable)
        : null,
    anniversaryDate: loanResponse.anniversaryDate
      ? formatDate(loanResponse.anniversaryDate)
      : null,
    clientName: loanResponse.legalEntity.legalEntity[0].servicer[0].servicedAs
  }

  const borrower = {
    userName: `${borrowerResponse.firstName} ${borrowerResponse.lastName}`,
    houseNumberStreeName: loanResponse.property.address1,
    cityStateZip: `${loanResponse.property.city} 
    ${loanResponse.property.state} 
    ${loanResponse.property.postalCode}`,
  }

  const transactions =
    transactionsData.data.loan.loanData.monetaryEvents.monetaryEvents
      .map((transaction) => {
        return {
          date: formatDate(transaction.transactionEffectiveDate),
          transaction: transaction.codeDescription,
          amount: FormatMoney(transaction.transactionAmount),
          interest: FormatMoney(transaction.interestAmount),
          mip: FormatMoney(transaction.mipAmount),
          serviceFees: FormatMoney(transaction.servicingFees),
          transactionTotal: FormatMoney(transaction.transactionTotal),
          endingBalance: FormatMoney(transaction.endingBalance),
        }
      })
      .slice(0, 5)

  if (!eligibilityData.data.getLOCEligibility.info)
    eligibilityData.data.getLOCEligibility.info = {}

  if (eligibilityData.data.getLOCEligibility.info?.pendingTransactionAmount) {
    loan.availableLineOfCredit = FormatMoney(summaryResponse.locNetAmount - eligibilityData.data.getLOCEligibility.info.pendingTransactionAmount)
    if (loan.availableFirstYearLineOfCredit !== null) {
      loan.availableFirstYearLineOfCredit = FormatMoney(summaryResponse.firstYearLOCAvailable - eligibilityData.data.getLOCEligibility.info.pendingTransactionAmount)
    }
  }

  loan.maxOnlineRequestAmount = FormatMoney(0.0);
  if(eligibilityData.data.getLOCEligibility.info?.maxOnlineRequestAmount && eligibilityData.data.getLOCEligibility.info.maxOnlineRequestAmount >= 0){
    loan.maxOnlineRequestAmount = FormatMoney(eligibilityData.data.getLOCEligibility.info.maxOnlineRequestAmount)
  }

  let clientRefiInfo = servicer && servicer.refiContactDetails

  sessionStorage.setItem('clientName', loan.clientName);
  sessionStorage.setItem('clientAddress', loan.clientName);

  return (
    <>
      <Title>My Loan Details</Title>
      <MyLoan
        loan={loan}
        borrower={borrower}
        transactions={transactions}
        clientRefiInfo={clientRefiInfo}
        summaryResponse={summaryResponse}
        // boCanSeeNbs={boCanSeeNbs}
        // occCertDue={occCertDue}
        certification={borrowerResponse.borrower[0]}
      />
    </>
  )
}

export default MyLoanContainer
