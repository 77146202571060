import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import CommonQuestionCategories from './CommonQuestionCategories'


const RepairsRequiredAtLoanOrigination = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.RepairsRequiredAtLoanOrigination, title)
  })

  return (
    <>
      <h3
        ref={title}
        id="repairs-required-at-loan-origination"
        tabIndex="-1"
      >
        Repairs Required at Loan Origination
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="7">
              What is Repair Set Aside?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              When the loan was originated, if there were repairs identified and detailed on a Repair Rider to the Loan Agreement, those repairs need to be completed by the deadline date on the Repair Rider. The funds in the Repair Set Aside represent a portion of the line of credit "set aside" to pay for those repairs.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              What do I need to know about getting required repairs
              completed?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              You will receive a welcome letter and guide from the
              Repair Department. This guide details the requirements
              to satisfying the repair rider requirement associated
              with your reverse mortgage. Documentation that will be
              required includes compliance inspections, contracts, and
              Lien Waivers from your contractor(s). The Repair
              Department will follow up with you via phone call
              several times throughout the process to answer any
              additional questions that arise. <br />
              <br />
              <strong>
                IMPORTANT: Failure to complete repairs by the deadline
                is a DEFAULT and could result in available line of
                credit funds being frozen until repairs are completed
                and inspected. Additionally, if the property is
                determined to be in disrepair, the loan could be
                called due and payable.{' '}
              </strong>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              I am having trouble completing the required repairs to
              my home. Who do I contact about repair issues?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              Please call 866-654-0020 and select the option for
              Repairs from the main menu. If the repair deadline is
              less than one year from closing, you can also use the{' '}
              <a href="./forms/2024/Repairs_Extension_Request.pdf" target="_blank">
                Repairs Extension Request Form
              </a>
              .
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              What is the Repair Set Aside?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              A portion of the line of credit set aside for payment of
              repairs required to qualify for loan. These funds are
              not available to a borrower for purposes other than
              completion of the required repairs detailed in the
              Repair Rider to the Loan Agreement.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              How long do I have to complete the required repairs?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              The Repair Rider to the Loan Agreement you signed at
              closing specifies the timeframe in which the repairs
              must be completed. If that timeframe is less than 12
              months from the closing date, you may be eligible for an
              extension of time in which to complete your required
              repairs; however,{' '}
              <strong>
                on HECM loans in no case may that timeframe exceed 12
                months from the date of the loan closing in compliance
                with HUD guidelines.{' '}
              </strong>{' '}
              see the{' '}
              <a href="./forms/2024/Repairs_Extension_Request.pdf" target="_blank">
                Repairs Extension Request Form
              </a>
              .
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              If I used a contractor to complete the required repairs,
              why must they complete a Lien Waiver form?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              On HECM loans, HUD requires certification that there
              will be no liens placed on the property for work
              performed where payment has not been made. See the{' '}
              <a href="./forms/2024/Repairs_Affidavit_and_Lien_Waiver.pdf" target="_blank">
                Repairs Affidavit and Lien Waiver Form
              </a>
              . To discuss repairs with us, please call 866-654-0020
              and select the option for Repairs from the main menu.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="5">
              Am I able to complete the repairs without a contractor?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              When you have finished the repairs, you will need to
              complete a <b>Repairs Homeowners Certification Form</b>.
              Please contact us at 866-654-0020 to obtain this form
              and then complete, sign and return it to us using the
              instructions on the form. A final inspection will be
              completed ensuring the repairs have been completed to
              minimum HUD standards.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
              What if I do not use the entire Repair Set Aside?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              Depending upon the type of loan, the funds may be
              transferred to your available line of credit. Funds will
              then be accessed by logging into this website to submit
              an online advance request, or by completing and
              submitting the Line of Credit Draw Request form you
              receive with your monthly statement to the fax number or
              mailing address found on the form. Some Reverse
              Mortgages that are set up without a line of credit
              specify the remaining funds are not accessible. The
              Repair Department will be able to advise further for
              your loan.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default RepairsRequiredAtLoanOrigination
