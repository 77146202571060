import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const AlertIcon = ({ dataTestid, color = 'alert' }) => {
  return (
    <StyledSVG
      color={color}
      data-testid={dataTestid}
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99935.66675c-4.6 0-8.33333 3.73333-8.33333 8.33333 0 4.60002 3.73333 8.33332 8.33333 8.33332 4.59995 0 8.33335-3.7333 8.33335-8.33332 0-4.6-3.7334-8.33333-8.33335-8.33333zm.83333 12.49995H8.16602v-1.6666h1.66666v1.6666zm0-3.33328H8.16602v-5h1.66666v5z"
        fill="#DC7700"
      />
    </StyledSVG>
  )
}

export default AlertIcon
