import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withRouter, Link, BrowserRouter } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Logo from './Logo'
import Button from '../Common/button'
import StyleGuide from '../Global/StyleGuide'
import { logOut } from '../services/auth'
import LeftChevronIcon from '../assets/left-chevron-icon'
import useWindowSize from '../Hooks/useWindowSize'
import { useRetrieveLocEligibility } from '../Hooks/useRetrieveLocEligibility'
import { useApiContext } from '../Hooks/useApiContext'
import { useRetrievePayOffEligibility } from '../Hooks/useRetrievePayOffEligibility'
import { buildTestID } from '../Utils/utils'
import EmptyStateLogoIcon from '../assets/empty-state-logo-icon'
import {
  trackUserSignedOut,
  trackLOCRequest,
  trackPayoffQuote,
  trackNavigateToUploadPage,
  trackRepaymentButton,
} from '../Global/Analytics'
import { useLoggedInUser } from '../Hooks/useLoggedInUser'
import { EQUITY_ELITE, HOMESAFE } from './AdvanceRequest'

const StyledHeader = styled.div`
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  background: white;
  top: 0;
  z-index: 200;

  .top_nav,
  .bottom_nav {
    min-height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 56px;

    .logo_placeholder {
      margin: 0;
      line-height: inherit;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black};

      .screen_reader_only {
        display: none;
      }
    }

    .links_top_nav,
    .links_bottom_nav {
      white-space: nowrap;
      display: flex;
      align-items: center;

      .body-2 {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black};

        :hover {
          color: ${({ theme }) => theme.colors.primary};
        }
      }

      a {
        margin-left: 24px;
      }
    }
  }

  .break_line {
    margin: 0;
  }

  .bottom_nav {
    min-height: unset;
    .links_bottom_nav {
      a {
        margin: 0;
        padding: 0 16px;
        height: 56px;
        display: flex;
        align-items: center;
        border-bottom: 4px solid ${({ theme }) => theme.colors.white};
      }
      .selected {
        border-bottom: 4px solid
          ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .top_nav {
    min-height: unset;
    height: 80px;

    .links_top_nav {
      margin-left: 8px;
      a.body-2 {
        margin: 0;
        padding: 0 12px;
        height: 56px;
        display: flex;
        align-items: center;
        border-bottom: 4px solid ${({ theme }) => theme.colors.white};
      }

      a.body-2.selected {
        border-bottom: 4px solid
          ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .dropdown {
    position: relative;

    button {
      margin: 0 0 0 16px;
      font-size: 18px;
      line-height: 24px;
      padding: 0 30px 4px 0;
      border-radius: 4px;
      border: none;
      color: ${({ theme }) => theme.colors.black};
      background: ${({ theme }) => theme.colors.white};

      ::after {
        display: none;
      }

      :active,
      :focus,
      :focus-visible,
      :focus-within,
      :target,
      :visited {
        border: none !important;
        color: black !important;
        background-color: white !important;
        box-shadow: none !important;
        background-color: white !important;
        border-color: white !important;
      }

      :hover {
        color: ${({ theme }) => theme.colors.primary} !important;
      }
    }

    .chev_icon {
      pointer-events: none;
      position: absolute;
      right: 14px;
      top: 22px;
      transform: ${({ onlineToolsOpen }) =>
    onlineToolsOpen ? 'rotate(90deg)' : 'rotate(270deg)'};
    }

    .dropdown-menu {
      border-radius: 4px;
      padding: 0;
      transform: translate3d(0px, 42px, 0px) !important;

      min-width: unset;
      width: unset;

      .dropdown-item {
        border: 0;
        color: ${({ theme }) => theme.colors.black};
        text-decoration: none;
        font-weight: normal;
        padding: 12px 16px;

        :active {
          background: none;
        }

        :hover {
          background: ${({ theme }) => theme.colors.lightGrey};
        }
      }
    }
  }

  .mobile_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 24px;

    .burger {
      background: ${({ theme }) => theme.colors.white};
      align-self: end;
      display: flex;
      width: 40px;
      height: 40px;
      border: 1px solid ${({ theme }) => theme.colors.black12};
      border-radius: 4px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .line {
        border: 0.5px solid ${({ theme }) => theme.colors.black60};
        width: 18px;
        border-radius: 4px;
      }
      :hover {
        outline: ${({ theme }) => theme.colors.primary} auto 1px;
      }
      :focus {
        outline: ${({ theme }) => theme.colors.primary} auto 1px;
      }
    }
  }

  .mobile_nav {
    position: absolute;
    min-width: 100vw;
    transition: all 0.2s ease-in;
    overflow-y: auto;
    height: calc(100vh - 60px);

    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.white};
    padding: 0;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);

    .mobile_link {
      text-align: start;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black};
      padding: 12px 24px;
      margin: 0 8px;
    }

    .mobile_link.selected {
      color: ${({ theme }) => theme.colors.primaryDark};
      background: ${({ theme }) => theme.colors.primary4};
      border-radius: 4px;
    }

    .buttons {
      padding: 0 24px 34px 24px;
      .button {
        margin: 20px 0 0 0;
        text-align: center;
        width: 360px;
      }
    }
  }

  #online-tools-menu {
    padding: 16px 0;
    margin-right: 2px;

    &:focus-within {
      outline: 2px solid #1460AA;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {

  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .mobile_nav {
      .buttons {
        .button {
          width: 100%;
        }
      }
    }
  }


  @media screen and ( min-width: 864px ) and ( max-width: 1008px){
    .links_top_nav {
      a {
        font-size: .75rem;
      }
    }
  }
`

const UnAuthedLinks = [
  { text: 'Helpful Resources', link: 'helpful-resources' },
  { text: 'Common Questions', link: 'common-questions' },
  { text: 'Common Forms', link: 'common-forms' },
  { text: 'Contact Us', link: 'contact' },
]

const defaultAuthedLinks = [
  { text: 'My Loan', link: 'my-loan' },
  {
    text: 'Statements',
    link: 'statements-and-documents',
  },
  {
    text: 'Online Tools',
    link: 'NESTED',
    nestedLinks: [],
  },
  { text: 'My Account', link: 'account-settings' }
]

const buildTestIDHeader = (text) => {
  return buildTestID(text, '/home', 'header')
}

const Header = ({
  history,
  isAuthenticated,
  navVisible,
  setNavVisible,
}) => {
  const [selectedNavItem, setSelectedNavItem] = useState('')
  const [onlineToolsOpen, setOnlineToolsOpen] = useState(false)
  const [authedLinks, setAuthedLinks] = useState(defaultAuthedLinks)

  const { data, loading } = useLoggedInUser(isAuthenticated)
  const userIsLoggedIn = !loading && data

  const { isSmall, isXSmall } = useWindowSize()

  const { pathname } = useLocation()

  useEffect(() => {
    setSelectedNavItem(pathname.slice(1))
  }, [pathname])

  const { data: eligibilityData } = useRetrieveLocEligibility()
  const { data: payoffData } = useRetrievePayOffEligibility()
  const apiContext = useApiContext()
  const { data: loanData } = apiContext.loan
  const getPrepaymentUrl = () => 'https://secure.directbiller.com/pbills/payer/welcomeDirect.do?h=1gzzt5f3tn' //process.env.REACT_APP_PREPAYMENT_URL

  // updates online tools dropdown nav to reflect available online tools
  useEffect(() => {
    if (!userIsLoggedIn) return

    let availableTools = [
      {
        text: 'Upload a Form or Document',
        link: 'upload',
        trackEvent: () =>
          trackNavigateToUploadPage({ fromNav: true }),
      },
      {
        text: 'Voluntary Prepayments/Repayment Plan Payments',
        link: getPrepaymentUrl(),
        trackEvent: () =>
          trackRepaymentButton({ fromNav: true }),
      },
    ]

    if (payoffData) {
      availableTools = [
        ...availableTools,
        {
          text: 'Request a Payoff Quote',
          link: 'payoff-quote',
          trackEvent: () =>
            trackPayoffQuote.Started({ fromNav: true }),
        },
      ]
    }
  
    const loanType = loanData && loanData.data && loanData.data.loan.loanData.product.productName
   
    if (eligibilityData && eligibilityData.data && eligibilityData.data.getLOCEligibility && eligibilityData.data.getLOCEligibility.info) {
      const { eligible, pending, history, maxRequestAmount } = eligibilityData.data.getLOCEligibility.info
      const hide = (loanType === HOMESAFE || loanType === EQUITY_ELITE) && maxRequestAmount < 500

      if ((history && !pending) || (maxRequestAmount && pending)) {
        availableTools = [
          {
            text: 'View my Line of Credit Requests',
            link: 'my-advance-requests',
            trackEvent: () => { },
          },
          ...availableTools,
        ]
      }

      if (!hide)
        if (maxRequestAmount && eligible && !pending) {
          availableTools = [
            {
              text: 'Request a Line of Credit Advance',
              link: 'advance-request',
              trackEvent: () =>
                trackLOCRequest.Started({ fromNav: true }),
            },
            ...availableTools,
          ]
        }
    }

    setAuthedLinks([
      defaultAuthedLinks[0],
      defaultAuthedLinks[1],
      {
        text: 'Online Tools',
        link: 'NESTED',
        nestedLinks: [...availableTools],
      },
      defaultAuthedLinks[3]
    ])
  }, [userIsLoggedIn, eligibilityData, loanData, payoffData])

  const mobileLinks = userIsLoggedIn
    ? [
      ...authedLinks.filter((_, i) => i !== 2),
      ...authedLinks[2].nestedLinks,
      ...UnAuthedLinks,
    ]
    : UnAuthedLinks

  const onLandingPage = selectedNavItem === ''

  return (
    <StyleGuide>
      <StyledHeader
        role="navigation"
        mobileNavHeight={
          isXSmall ? '100vh' : mobileLinks.length * 48 + 150 + 'px'
        }
        navVisible={navVisible}
        onlineToolsOpen={onlineToolsOpen}
      >
        {!isSmall ? (
          <>
            <div className="top_nav">
              {userIsLoggedIn ? (
                <Logo />
              ) : (
                <a
                  href="/"
                  className="body-2 logo_placeholder"
                  data-testid={buildTestIDHeader('Reverse Mortgage')}
                  aria-label=" Reverse Mortgage Servicing Department home link"
                >
                  <span className="screen_reader_only">
                    Reverse Mortgage Servicing Department
                  </span>
                  <EmptyStateLogoIcon />
                </a>
              )}
              <div className="links_top_nav">
                {UnAuthedLinks.map((link) => {
                  return link.link !== 'common-questions' && link.link !== 'common-forms' ? (
                    <Link
                      data-testid={buildTestIDHeader(link.text)}
                      key={link.link}
                      to={link.link}
                      className={`body-2 ${selectedNavItem === link.link
                        ? 'selected'
                        : ''
                        }`}
                    >
                      {link.text}
                    </Link>
                  ) : (
                    <BrowserRouter forceRefresh={true}>
                      <Link
                        data-testid={buildTestIDHeader(link.text)}
                        key={link.link}
                        to={link.link}
                        className={`body-2 ${selectedNavItem === link.link
                          ? 'selected'
                          : ''
                          }`}
                      >
                        {link.text}
                      </Link>
                    </BrowserRouter>
                  )
                })}
                {!userIsLoggedIn && selectedNavItem !== 'register' ? (
                  <Button
                    className="create_account_button_full_screen"
                    linkTo="/register"
                    text="Create Account"
                    outlined
                    small
                    refreshPage
                    UID="create_account_nav"
                  />
                ) : null}
                {userIsLoggedIn ? (
                  <Button
                    text="Log Out"
                    UID="log_out_nav"
                    onClick={(e) => {
                      trackUserSignedOut()
                      e.preventDefault()
                      sessionStorage.clear()
                      logOut()
                      history.push('/')
                      setNavVisible(false)
                    }}
                    small
                    linkTo="/"
                  />
                ) : !onLandingPage ? (
                  <Button
                    UID="sign_in_nav"
                    text="Sign In"
                    small
                    linkTo="/"
                    refreshPage
                  />
                ) : null}
              </div>
            </div>
            {userIsLoggedIn ? (
              <>
                <div className="break_line" />
                <div className="bottom_nav">
                  <div className="links_bottom_nav">
                    {authedLinks.map((link) => {
                      return link.link !== 'NESTED' ? (
                        <Link
                          data-testid={buildTestIDHeader(link.text)}
                          key={link.link}
                          className={`body-2 ${selectedNavItem === link.link
                            ? 'selected'
                            : ''
                            }`}
                          to={link.link}
                          aria-current={selectedNavItem === link.link
                            ? 'true'
                            : 'false'}
                        >
                          {link.text}
                        </Link>
                      ) : (
                        <Dropdown
                          id="online-tools-menu"
                          data-testid={buildTestIDHeader(link.text)}
                          key={link.link}
                          onToggle={(isOpen) =>
                            setOnlineToolsOpen(isOpen)
                          }
                          aria-expanded={onlineToolsOpen ? 'true' : 'false'}
                        >
                          <LeftChevronIcon
                            className="chev_icon"
                            color={'black'}
                          />
                          <Dropdown.Toggle
                            id="dropdown-title"
                            bsPrefix="unset"
                            aria-label='online tools'
                          >
                            Online Tools
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {link.nestedLinks.map((nestedLink) => {
                              return nestedLink.text !== 'Voluntary Prepayments/Repayment Plan Payments' ? (
                                <Dropdown.Item
                                  data-testid={buildTestIDHeader(
                                    nestedLink.text,
                                  )}
                                  key={nestedLink.link}
                                  eventKey={nestedLink.link}
                                  value={nestedLink.link}
                                  as={Link}
                                  to={nestedLink.link}
                                  onClick={nestedLink.trackEvent}
                                >
                                  {nestedLink.text}
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  data-testid={buildTestIDHeader(
                                    "repayment",
                                  )}
                                  key="repayment"
                                  eventKey="repayment"
                                  value="repayment"
                                  as={Link}
                                  to={{ pathname: nestedLink.link }}
                                  onClick={(e) => { e.preventDefault(); trackRepaymentButton({ fromNav: true }) }}>
                                  {nestedLink.text}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      )
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : (
          <>
            <div className="mobile_header">
              {userIsLoggedIn ? (
                <Logo />
              ) : (
                <a
                  href="/"
                  className="body-2 logo_placeholder"
                  data-testid={buildTestIDHeader('Reverse Mortgage')}
                >
                  <EmptyStateLogoIcon />
                </a>
              )}
              <button
                data-testid={buildTestIDHeader('Hamburger')}
                className="burger"
                onClick={() => setNavVisible(!navVisible)}
                aria-expanded={navVisible}
                aria-label={navVisible ? 'menu button expanded' : 'menu button collapsed'}
                aria-controls="mobile-menu"
              >
                <div className="line" />
                <div className="line" />
                <div className="line" />
              </button>
            </div>
            <div id="mobile-menu" className="mobile_nav" aria-hidden={!navVisible} style={{ "display": navVisible ? 'flex' : 'none' }}>
              {mobileLinks.map((link) => {
                return link.text !== 'Voluntary Prepayments/Repayment Plan Payments' ? (<Link
                  data-testid={buildTestIDHeader(
                    link.text + '_mobile',
                  )}
                  key={link.link}
                  to={link.link}
                  className={`body-1 mobile_link ${selectedNavItem === link.link ? 'selected' : ''
                    }`}
                >
                  {link.text}
                </Link>) : (<Link
                  data-testid={buildTestIDHeader(
                    'repayment_mobile',
                  )}
                  key={'repayment_mobile'}
                  to={{ pathname: link.link }}
                  className={`body-1 mobile_link ${selectedNavItem === 'repayment_mobile' ? 'selected' : ''
                    }`}
                >
                  {link.text}
                </Link>)
              })}
              <div className="buttons">
                {userIsLoggedIn ? (
                  <Button
                    className="button"
                    text="Log Out"
                    UID="log_out_nav_mobile"
                    onClick={(e) => {
                      trackUserSignedOut()
                      e.preventDefault()
                      sessionStorage.clear()
                      logOut()
                      history.push('/')
                      setNavVisible(false)
                    }}
                    small
                    linkTo="/"
                    fullWidth
                  />
                ) : !onLandingPage ? (
                  <Button
                    className="button"
                    UID="sign_in_nav_mobile"
                    text="Sign In"
                    small
                    linkTo="/"
                    refreshPage
                    fullWidth
                  />
                ) : null}
                {!userIsLoggedIn ? (
                  <Button
                    className="button"
                    linkTo="/register"
                    text="Create Account"
                    outlined
                    small
                    refreshPage
                    UID="create_account_nav_mobile"
                    fullWidth
                  />
                ) : null}
              </div>
            </div>
          </>
        )}
      </StyledHeader>
    </StyleGuide>
  )
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  navVisible: PropTypes.bool.isRequired,
  setNavVisible: PropTypes.func.isRequired,
}

export default withRouter(Header)
