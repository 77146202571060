import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }
`

const PersonIcon = () => {
    return (
        <StyledSVG
            aria-hidden="true"
            role="img"
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="10" cy="14" rx="8" ry="4" fill="#4FA6D7" />
            <circle cx="10" cy="5" r="4" fill="#4FA6D7" />
            <circle cx="15.5" cy="15.5" r="3.5" fill="#001F30" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9748 14.6468L17.2677 13.9397L15.1464 16.061L14.0963 15.011L13.3892 15.7181L14.4393 16.7681L14.4393 16.7681L15.1464 17.4752L17.9748 14.6468Z" fill="white" />
        </StyledSVG>
    )
}

export default PersonIcon
