import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Button from '../../Common/button'

const StyledCancelMfaModal = styled(Modal)`
  .header {
    margin: 32px 32px 20px 32px;
    text-align: left;
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: ${({ theme }) => theme.colors.black87};
  }

  .body {
    margin: 0 32px 20px 32px;
  }

  .body-text {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.black87};
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
    margin: 0 32px 36px 32px;

    .right-button {
      margin-left: 40px;
    }
  }
`

const CancelMfaModal = ({ onHide, show }) => {
    return (
        <StyledCancelMfaModal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="header">
                <h6 className="title">Are you sure you want to cancel?</h6>
            </div>
            <div className="body">
                <p className="body-text">
                    Multifactor Authentication is a great way to protect your online account. Are you sure you don’t want to further protect your account?
                </p>
            </div>
            <div className="buttons">
                <Button
                    linkTo="#"
                    onClick={onHide}
                    textButton
                    text="No, Continue enabling MFA"
                    UID="modal_button_continue"
                />
                <Button
                    className="right-button"
                    linkTo="/my-loan"
                    onClick={onHide}
                    textButton
                    text="I want to Cancel"
                    UID="modal_button_cancel"
                />
            </div>
        </StyledCancelMfaModal>
    )
}

CancelMfaModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}

export default CancelMfaModal
