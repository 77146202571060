import React from 'react'
import Styled from 'styled-components'
import { Container } from 'react-bootstrap'

import Title from '../Common/Title'
import ScrollUpButton from '../Common/ScrollUpButton'
import QLLogo from '../assets/QL_Logo.png'

const StyledAccessiblityStatement = Styled.div`
  margin-top: 80px;
  margin-bottom: 100px;
  font-size: 20px;

.title {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 800px;
}

.question {
  margin-bottom: 20px;
}

p {
  margin-bottom: 40px;
}

a {
  color: ${({ theme }) => theme.colors.primary};
}

.list-item {
  display: list-item;
  margin: 0;                                  
}

img {
  max-width: 100%;
}
`

const AccessiblityStatement = () => {
  return (
    <>
      <Title>Accessibility Statement</Title>
      <StyledAccessiblityStatement>
        <main id="main" tabIndex="0">
          <Container>
            <div className="title">
              <h1>Accessibility Statement</h1>
            </div>
            <div>
              <strong>
                Website Accessibility Statement: WCAG 2.1 AA
                Conformant
              </strong>
              <p>
                Celink is committed to ensuring digital accessibility
                for people of all abilities. We are continually
                improving the user experience for everyone and are
                regularly reviewing and applying the relevant
                accessibility standards.{' '}
              </p>
              <strong>Conformance Status</strong>
              <p>
                The Web Content Accessibility Guidelines (WCAG)
                defines requirements for designers and developers to
                improve accessibility for people with disabilities. It
                defines three levels of conformance: Level A, Level
                AA, and Level AAA. The{' '}
                <a href="https://www.reversedepartment.com">
                  https://www.reversedepartment.com
                </a>{' '}
                website is fully conformant with WCAG 2.1 level AA.
                Fully conformant means that the content fully conforms
                to the accessibility standard without any exceptions.
              </p>
              <strong>Technical Specifications</strong>
              <p>
                Accessibility of Celink’s website relies on the
                following technologies to work with the particular
                combination of web browser and any assistive
                technologies or plugins installed on your computer:
              </p>
              <ul>
                <li>HTML</li>
                <li>WAI-ARIA</li>
                <li>CSS</li>
                <li>JavaScript</li>
              </ul>
              <p>
                These technologies are relied upon for conformance
                with the accessibility standards used.
              </p>
              <strong>Assessment Approach</strong>
              <p>
                Celink engaged QualityLogic as a third-party quality
                assurance testing company to audit for compliance with
                WCAG 2.1 AA. The accessibility of the{' '}
                <a href="https://www.reversedepartment.com">
                  https://www.reversedepartment.com
                </a>{' '}
                website was audited using a testing methodology based
                on the WCAG-EM evaluation methodology referenced here:{' '}
                <a href="https://www.w3.org/TR/WCAG-EM/">
                  https://www.w3.org/TR/WCAG-EM/
                </a>
              </p>
              <p>
                During the audit the following methods were employed
                to test for compliance:
              </p>
              <ul>
                <p className="list-item">
                  Complete review of the website using both automated
                  tools and manual assistive technology including, but
                  not limited to:
                </p>
                <ul>
                  <li>JAWS</li>
                  <li>NVDA</li>
                  <li>Voiceover</li>
                  <li>Pa11y</li>
                  <li>Wave</li>
                </ul>
                <p className="list-item">
                  Usability testing by individuals who use assistive
                  technology in their everyday lives
                </p>
              </ul>
              <strong>Limitations and Alternatives</strong>
              <p>
                Despite our best efforts to ensure accessibility of
                the{' '}
                <a href="https://www.reversedepartment.com">
                  https://www.reversedepartment.com
                </a>{' '}
                website, there may be some limitations caused by some
                assistive technologies. Please contact us if you have
                any issues using the{' '}
                <a href="https://www.reversedepartment.com">
                  https://www.reversedepartment.com
                </a>{' '}
                website. You can contact us at: Borrower Care: (866)
                654-0020
              </p>
              <strong>Release Notes for the Celink Website:</strong>
              <ul>
                <li>
                  The{' '}
                  <a href="https://www.reversedepartment.com">
                    https://www.reversedepartment.com
                  </a>{' '}
                  website is designed for modern browsers. Please use
                  the most current version of the following supported
                  browsers:
                  <ul>
                    <li>Google Chrome</li>
                    <li>Mozilla Firefox</li>
                  </ul>
                </li>
              </ul>
              <strong>Feedback</strong>
              <p>
                We welcome your feedback on the accessibility of{' '}
                <a href="https://www.reversedepartment.com">
                  https://www.reversedepartment.com
                </a>
                . Please let us know if you encounter accessibility
                barriers on our pages: Borrower Care: (866) 654-0020
              </p>
              <p>
                We try to respond to feedback within 5 business days.
              </p>
              <p>*Last Updated 2-25-2021</p>
              Certification Provided by QualityLogic, Inc.
              <br />
              <img src={QLLogo} alt="QualityLogic" />
            </div>
          </Container>
          <ScrollUpButton />
        </main>
      </StyledAccessiblityStatement>
    </>
  )
}

export default AccessiblityStatement
