import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

import CommonQuestionCategories from './CommonQuestionCategories'
import { buildTestID } from '../../Utils/utils'

const StyledUsefulLinks = styled.div`
  .sub-heading {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black60};
  }

  img {
    margin-bottom: 12px;
  }

  .card-body {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.black12};
  }
`
const UsefulLinks = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.UsefulLinks, title)
  })

  return (
    <StyledUsefulLinks>
      <h3 ref={title} id="useful-links" tabIndex="-1">
      Helpful Resources & Useful Links
      </h3>
      <p> <a href="/helpful-resources" target="_blank" data-testid={buildTestID('helpful_resources','/common_questions','helpful_resources_link',)}>Helpful Resources & Useful Links</a></p>
    </StyledUsefulLinks>
  )
}

export default UsefulLinks
