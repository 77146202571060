import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { buildTestID } from '../../Utils/utils'

import CommonQuestionCategories from './CommonQuestionCategories'

const MonthlyPayments = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.MonthlyPayments, title)
  })

  return (
    <>
      <h3 ref={title} id="monthly-payments" tabIndex="-1">Monthly Payments</h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              When will my first monthly payment be mailed?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              If you chose to receive monthly payments from your reverse mortgage AND your payment method is check by mail, your first payment will be mailed to you on the first business day of the month following the day your loan funded (for example, if your loan closed in June and funded (disbursed) in June, you will receive your first payment in July. If your loan closed in June but funded (disbursed) in July, you will receive your first payment in August). <strong>We encourage you to setup EFT/Direct Deposit for the fastest and safest delivery method of your funds. It’s simple – just complete the Electronic Funds Transfer (EFT) / Direct Deposit Agreement (login to your account to get this form)</strong>.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              When will I receive my monthly payment?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              All monthly payments are mailed or sent via Electronic
              Funds Transfer (“EFT”) for direct deposit (if previously
              set up on your loan) on the first business day of the
              month. If you provided a voided check at the time of
              your loan closing for this purpose, you do not need to
              do anything. If you did not provide a voided check at
              closing and would like to do so now, please see
              “EFT/Direct Deposit” below and complete the{' '}
              Electronic Funds Transfer / Direct Deposit Form
              and send it to the email address, fax number or mailing
              address on the form.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default MonthlyPayments
