import React, { useEffect, useRef, useState } from 'react'
import MoneyHandIcon from '../../assets/money-hand-icon'
import useWindowSize from '../../Hooks/useWindowSize'
import { trackRepaymentButton } from '../../Global/Analytics'
import { buildTestID } from '../../Utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'

export const RepaymentTool =({ className }) => {
        const [mockLoading, setMockLoading] = useState(true)
        const linkElement = useRef()
        const getPrepaymentUrl = () => 'https://secure.directbiller.com/pbills/payer/welcomeDirect.do?h=1gzzt5f3tn' //process.env.REACT_APP_PREPAYMENT_URL

        useEffect(() => {
            setTimeout(() => setMockLoading(false), 1500)
        })

        const triggerClick = (e) => {
            e.preventDefault()
            trackRepaymentButton({ fromNav: false })
            linkElement.current.click()
        }

        const handleClick = (e) => {
            trackRepaymentButton({ fromNav: false })
        }

        const { isXSmall } = useWindowSize()

        if (!isXSmall) {
            return !mockLoading ? (
                <div className="tool">
                    <div className="content">
                        <MoneyHandIcon />
                        <div className="button-and-text">
                            <h3 className="body-1 bold">
                                Voluntary Prepayments/Repayment Plan Payments
                            </h3>
                            <a href={getPrepaymentUrl()} target="_blank" rel="noreferrer" className="button-link" style={{marginLeft: "-30px"}} onClick={handleClick}>
                                Voluntary Prepayments/Repayment Plan Payments
                                <FontAwesomeIcon icon={faExternalLinkAlt} className="external_icon" style={{marginLeft: "10px"}} aria-label='link opens in new window' data-testid={buildTestID('online_tools_repayment_link', '/', 'carousel_repayment_link')} />
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="tool">
                    <div className="loading">
                        <div className="content">
                            <div className="rectangle icon" />
                            <div className="left-rec-group">
                                <div className="rectangle long" />
                                <div className="rectangle long" />
                                <div className="rectangle large" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <a
                    href={getPrepaymentUrl()}
                    ref={linkElement}
                    target="_blank"
                    rel="noreferrer"
                    className="mobile_card"
                    data-testid={buildTestID(
                        'prepayment',
                        '_my_loan',
                        'mobile',
                    )}>
                    <div className="icon_section" onClick={(e) => { triggerClick(e) }}>
                        <MoneyHandIcon color="white" />
                    </div>
                    <p className="caption">Voluntary Prepayments/Repayment Plan Payments</p>
                </a>
            )
        }
    }