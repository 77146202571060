import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CONSENT } from '.'

import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { trackESignConsent } from '../../Global/Analytics'

dayjs.extend(utc)


const StyledOptIn = styled.div`
  h1, h3 {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }
  
  p {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background: #fafafa;
  }
  
  .cb-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
  }
  
  .cb {
    height: 18px;
    width: 18px;
    margin-right: 12px;
  
    position: static;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
  
    /* Inside Auto Layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 12px;
  }
  
  .l-cb {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    flex: none;
    order: 1;
    flex-grow: 1;
    margin: 0px 12px;
  
    position: static;
    width: 235px;
    height: 96px;
    left: calc(50% - 235px / 2 + 18px);
    top: 0px;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .optin {
      background-color: #fff;
      padding: 15px;
      margin-top: 32px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      text-align: center;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 24px;
      background: #FAFAFA;
      font-size: 16px;
      font-weight: 700;
      margin: 25px 35px;
    }

    .cb-container {
      margin: 25px 35px;
    }

    .l-cb {
        height: auto
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const OptIn = ({ setView, setModalShow, setOptInTimestamp, mainRef }) => {
  const [nextButtonDisabled, setnextButtonDisabled] = useState(true)
  const [buttonStatus, setButtonStatus] = useState('')
  const history = useHistory();

  const routeChange = () => {
    let path = `/my-loan`;
    history.push(path);
  }

  const handleClick = (e) => {
    const checked = e.target.checked;
    setnextButtonDisabled(!checked)
    checked ? setOptInTimestamp(dayjs.utc().local().format()) : setOptInTimestamp(null)
    checked ? setButtonStatus("Please click next to continue eSign") : setButtonStatus("Please check box to review and sign the Annual Occupancy Certification form electronically")
  }

  return (
    <StyledOptIn>
      <div className="optin"
        data-gridcolumnlayout={{
          xl: '3/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Annual Occupancy Certification</h1>
        <h5>Your review and signature is required for the Annual Occupancy Certification document.</h5>
        <p><b>The following message is applicable to you if you have an FHA-insured loan:
          <br/>I/We, the undersigned, certify under penalty of perjury that the information provided above is true and correct. WARNING: Anyone who knowingly submits a false claim or makes a false statement is subject to criminal and/or civil penalties, including confinement for up to five years, fines, and civil and administrative penalties. (18 U.S.C. §§ 287, 1001, 1010, 1012, 1014; 31 U.S.C. §§ 3729, 3802).</b></p>
        <div className="cb-container">
          <input
            className="cb"
            type="checkbox"
            id={`chk-review-and-sign`}
            name="chk-review-and-sign"
            onClick={(e) => handleClick(e)}
            aria-label="I would like to review and sign the Annual Occupancy Certification form electronically."
            data-testid={buildTestID('chk_review_and_sign', '/esign', 'annual_occupancy',)}
          />
          <label htmlFor="chk-review-and-sign" className="l-cb">I would like to review and sign the Annual Occupancy Certification form electronically.</label>
        </div>
        <hr />
        <div className="buttons">
          <Button
            className="cancel button"
            text="Cancel"
            outlined
            fullWidth
            UID="optin_button_cancel"
            onClick={() => nextButtonDisabled ? routeChange() : setModalShow(true)}
          />
          <Button
            className="button"
            text="Next"
            fullWidth
            UID="optin_button_next"
            disabled={nextButtonDisabled}
            aria-disabled={nextButtonDisabled}
            onClick={() => {
              trackESignConsent.StepCompleted('completed opt-in for esign', 'optin_button_next')
              setView(CONSENT)
            }}
          />
          <p className="visually-hidden" aria-live="assertive">{buttonStatus}</p>
        </div>
      </div>
    </StyledOptIn>
  )
}

export default OptIn