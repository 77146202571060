import React from 'react'
import styled from 'styled-components'

import Button from '../../Common/button'
import InformationBubbleIcon from '../../assets/info-bubble-icon'
import { useHistory } from 'react-router-dom'
import { trackESignConsent } from '../../Global/Analytics'
import { useRetrieveLocEligibility } from '../../Hooks/useRetrieveLocEligibility'
import { FormatMoney, getCookie } from '../../Utils/utils'

const StyledCertificationOccupancyBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryDark};
  background: ${({ theme }) => theme.colors.primary12};
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  border-radius: 4px;
  padding: 16px 18px;
  margin-top: 54px;

  svg {
      width: 16px;
      height: 16px;
      margin-top: -4px;
      margin-right: 15px;
  }

  .cert-message {
      margin-bottom: 15px;
  }

  @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;

      .cert-message {
        margin-bottom: 0;
    }
   }
`

const CertificationOccupancyBanner = ({ loan, summaryResponse }) => {
    const history = useHistory()

    const cookieDate = (getCookie('occ_cert_completed_date'))
    let completed = false;
    if (cookieDate) {
        completed = true
    }

    let boCanSeeNbs, occCertDue

    const {
        loading: eligibilityLoading,
        data: eligibilityData,
    } = useRetrieveLocEligibility()

    if (!eligibilityLoading) {
        if (!eligibilityData.data.getLOCEligibility.info)
            eligibilityData.data.getLOCEligibility.info = {}

        if (eligibilityData.data.getLOCEligibility.info?.pendingTransactionAmount) {
            loan.availableLineOfCredit = FormatMoney(summaryResponse.locNetAmount - eligibilityData.data.getLOCEligibility.info.pendingTransactionAmount)
            if (loan.availableFirstYearLineOfCredit !== null) {
                loan.availableFirstYearLineOfCredit = FormatMoney(summaryResponse.firstYearLOCAvailable - eligibilityData.data.getLOCEligibility.info.pendingTransactionAmount)
            }
        }

        ({ boCanSeeNbs, occCertDue } = eligibilityData.data.getLOCEligibility.info)
    }


    const routeChange = () => {
        let path = `/esign`;
        if (boCanSeeNbs)
            path += `?type=NBS`
        history.push(path);
    }

    return (
        occCertDue && !completed ? (
            <StyledCertificationOccupancyBanner>
                <div className="cert-message">
                    <InformationBubbleIcon />
                    Your <b>Annual Certification Form</b> is ready for you to review and sign.
                </div>
                <Button
                    className="review_and_sign_button"
                    text="Review and Sign Document"
                    onClick={() => {
                        trackESignConsent.Started()
                        routeChange()
                    }}
                    UID="review_and_sign_button"
                />
            </StyledCertificationOccupancyBanner>
        ) : null
    )
}

export default CertificationOccupancyBanner