import React, { useEffect, useState } from "react";
import { Col, Form, Row } from 'react-bootstrap'
import styled from "styled-components";
import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'
import useWindowSize from '../../Hooks/useWindowSize'
import InputMask from 'react-input-mask'
import EyeIcon from '../../assets/eye-icon'
import NoEyeIcon from '../../assets/no-eye-icon'
import dayjs from 'dayjs'
import { CONFIRM_IDENTITY_ERROR, SELECT_PHONE_NUMBER } from ".";
import { checkValidateIdentity } from "../../services/api";

const StyledConfirmIdentity = styled.div`
h1, h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }
  
  p {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 20px;
    // background: #fafafa;
  }

  input.form-control {
    height: 56px;
  }

  .eye-icon {
    position: absolute;
    top: 46px;
    right: 50px;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .confirmed-identity {
      background-color: #fff;
      padding: 15px;
      margin: 32px auto 0;
      width: 420px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      // text-align: center;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // padding: 24px;
      // background: #FAFAFA;
      font-size: 16px;
      // font-weight: 700;
      // margin: 25px 35px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .button-confirm-identity  {
        width: 230px !important;
      }
 }
  `

const ConfirmIdentity = ({ borrower, setView, setModalShow, mainRef }) => {
  const [ssn, setSsn] = useState()
  const [validSsn, setValidSsn] = useState(false)
  const [showSsnValidation, setShowSsnValidation] = useState(false)
  const [showSsn, setShowSsn] = useState(false)

  const [dob, setDob] = useState()
  const [validDob, setValidDob] = useState(false)
  const [showDobValidation, setShowDobValidation] = useState(false)

  const [zipCode, setZipCode] = useState()
  const [validZipCode, setValidZipCode] = useState(false)
  const [showZipCodeValidation, setShowZipCodeValidation] = useState(false)

  const [loading, setLoading] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const { isSmall } = useWindowSize()
  const [showDobMask, setShowDobMask] = useState(true)

  useEffect(() => {
    if (invalid)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
  }, [invalid])

  useEffect(() => {
    const domains = ['dev-borrower.celink.cloud', 'stg-borrower.celink.cloud']
    if (domains.includes(window.location.host))
      setShowDobMask(false)
  }, [showDobMask])

  const handleDobChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "").substring(0, 8)
    setDob(value.replace(/^([\d]{2})([\d]{2})([\d]{4})$/, "$1/$2/$3"))
  }

  const handleSsnChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "")
    setSsn(value)
  }

  const handleZipCodeChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "")
    setZipCode(value)
  }

  const validateSsn = (e) => {
    const ssn = e.target.value.trim()
    if (ssn.length !== 4) {
      setValidSsn(false)
      setShowSsnValidation(true)
      return
    }

    setValidSsn(true)
    setShowSsnValidation(false)
  }

  const validateDob = (e) => {
    const dob = e.target.value.trim()
    if (!dob.length || !dayjs(dob).isValid()) {
      setValidDob(false)
      setShowDobValidation(true)
      return
    }

    setValidDob(true)
    setShowDobValidation(false)
  }

  const validateZipCode = (e) => {
    const zipCode = e.target.value.trim()
    if (!zipCode.length || zipCode.length !== 5) {
      setValidZipCode(false)
      setShowZipCodeValidation(true)
      return
    }

    setValidZipCode(true)
    setShowZipCodeValidation(false)
  }

  const handleClick = async (event) => {
    try {
      const attempt = attempts + 1

      setAttempts(attempt)
      setInvalid(false)
      setLoading(true)

      const dobFormatted = dayjs(dob).format('YYYY-MM-DD')
  
      const response = await checkValidateIdentity(borrower.loanNumber, ssn, dobFormatted, zipCode)
      
      if (response.data.validateIdentity.isValid) {
        setView(SELECT_PHONE_NUMBER)
      }
      else if (attempts >= 2) {
        setView(CONFIRM_IDENTITY_ERROR)
      }
      else {
        setLoading(false)
        setInvalid(true)
      }

    } catch (err) {
      setLoading(false)
      setInvalid(true)
      event.target.blur();

      if (attempts >= 2)
        setView(CONFIRM_IDENTITY_ERROR)
    }
  }

  return (
    <StyledConfirmIdentity>
      <div className="confirmed-identity"
        data-gridcolumnlayout={{
          xl: '1/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Enable Multifactor Authentication</h1>
        <p>Enabling multifactor authentication is a great way to protect your account. First, we need to make sure
          that its you making this request.<br /><br />
          Please enter the information below, and then click “Confirm My Identity”</p>
        {invalid && (<p className="error_text">One or more of your entries failed to match the data on file. Please check your entries and try again. You have {(3 - attempts)} attempts remaining.</p>)}
        <Form>
          <Row>
            <Col>
              <Form.Group className="mb-40" controlId="ssn">
                <Form.Label>Last 4 of Social Security Number</Form.Label>
                <Form.Control type={showSsn ? "text" : "password"}
                  className={`${showSsnValidation ? "is-invalid" : ""}`}
                  name="ssn"
                  placeholder="Enter SSN"
                  minLength={4}
                  maxLength={4}
                  value={ssn}
                  onChange={handleSsnChange}
                  onBlur={validateSsn} />
                <i className="eye-icon"
                  tabindex="0"
                  aria-label={showSsn ? 'Last 4 of SSN shown' : 'Last 4 of SSN hidden'}
                  aria-pressed={showSsn}
                  onKeyUp={(e) => e.key === 'Enter' && setShowSsn(!showSsn)}
                  onClick={() => setShowSsn(!showSsn)}
                >
                  {
                    showSsn ? <EyeIcon></EyeIcon> : <NoEyeIcon></NoEyeIcon>
                  }
                </i>
                <Form.Control.Feedback type="invalid">
                  Please provide the last 4 digits of your social security number.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-40" controlId="dateOfBirth">
                <Form.Label>Date of Birth</Form.Label>
                <InputMask type="text"
                  className={`form-control ${showDobValidation ? "is-invalid" : ""}`}
                  name="dateOfBirth"
                  mask={showDobMask ? "99/99/9999" : ""}
                  placeholder={showDobMask ? "MM/DD/YYYY" : ""}
                  value={dob}
                  onChange={handleDobChange}
                  onBlur={validateDob}
                  data-testid={buildTestID('dateofbirth', '/enable-multifactor')}
                  aria-label="date of birth">
                </InputMask>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid date of birth.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-40" controlId="zipCode">
                <Form.Label>Property Zip Code</Form.Label>
                <Form.Control type="text"
                  className={`${showZipCodeValidation ? "is-invalid" : ""}`}
                  name="zipCode"
                  placeholder="Enter Property Zip Code"
                  minLength={5}
                  maxLength={5}
                  value={zipCode}
                  onChange={handleZipCodeChange}
                  onBlur={validateZipCode}
                  onKeyUp={validateZipCode}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid zip code.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <hr />
        <div className="buttons">
          <Button
            className="button button-confirm-identity"
            text={isSmall ? "Confirm" : "Confirm My Identity"}
            type="submit"
            fullWidth
            UID="confirm_identity_submit"
            disabled={!(validDob && validSsn && validZipCode && !loading)}
            loading={loading}
            onClick={handleClick}
          />
          <Button
            className="button cancel"
            text="Cancel"
            textButton
            fullWidth
            UID="confirm_identity_cancel"
            onClick={() => setModalShow(true)}
          />
        </div>
      </div>
    </StyledConfirmIdentity>
  )
}

export default ConfirmIdentity;