import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

import StyleGuide from '../../../Global/StyleGuide'
import Button from '../../../Common/button'
import useWindowSize from '../../../Hooks/useWindowSize'
import { trackChangePassword } from '../../../Global/Analytics'

const StyledConfirmEmail = styled(Modal)`
  display: flex;
  flex-direction: column;

  .modal-content {
    padding: 32px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
  }

  .modal_width {
    max-width: 450px !important;
  }

  .title {
    margin: 0;
    color: ${({ theme }) => theme.colors.black87};
  }

  .sub_heading {
    color: ${({ theme }) => theme.colors.black87};
    margin: 16px 0 24px 0;
  }

  .input_group {
    margin: 0 0 32px 0;
    display: block;

    h5,
    h6 {
      margin: 0;
    }

    .input {
      margin-top: 16px;
      color: ${({ theme }) => theme.colors.black60};
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .error_message {
    color: ${({ theme }) => theme.colors.error};
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .button {
      width: 162px;
    }

    .cancel {
      margin-right: 24px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 24px 0 0 0;

    .button {
      width: 100%;
    }

    .buttons {
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const ConfirmEmail = ({
  closeScreen,
  show,
  openChangePasswordScreen,
  userEmail,
}) => {
  const [error, setError] = useState('')

  const { isSmall } = useWindowSize()

  const handleCloseScreen = () => {
    setError('')
    closeScreen()
  }

  const handleGetVerificationCode = async (e) => {
    e.preventDefault()
    try {
      await Auth.forgotPassword(userEmail)
      openChangePasswordScreen()
      trackChangePassword.StepTwo()
    } catch (err) {
      setError(err.message)
    }
  }

  let ModalOrDivProps = {
    onHide: handleCloseScreen,
    show: show,
    centered: true,
    size: 'md',
    as: isSmall ? 'div' : Modal,
    dialogClassName: 'modal_width',
  }

  if (isSmall) delete ModalOrDivProps.onHide

  return show ? (
    <StyledConfirmEmail {...ModalOrDivProps}>
      <StyleGuide>
        <h3 className="title heading-6">Change My Password</h3>
        <p className="subtitle-2 sub_heading">
          We will send you a verification code to your email address
          or phone before you can change your password.
        </p>
        <div className="input_group">
          <h5 className="subtitle-2 bold">Email Address</h5>
          <p className="input body-1">{userEmail}</p>
          <span className="error_message" role="alert">
            {error}
          </span>
        </div>
        <div className="buttons">
          <Button
            className="cancel button"
            text="Cancel"
            outlined
            onClick={handleCloseScreen}
            fullWidth
            UID="confirm_email"
          />
          <Button
            className="button"
            fullWidth
            text="Send"
            UID="confirm_email"
            onClick={handleGetVerificationCode}
          />
        </div>
      </StyleGuide>
    </StyledConfirmEmail>
  ) : null
}

ConfirmEmail.propTypes = {
  show: PropTypes.bool.isRequired,
  closeScreen: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  openChangePasswordScreen: PropTypes.func.isRequired,
}

export default ConfirmEmail
