import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import CheckIcon from '../../assets/check-icon.js'
import { CONFIRM_IDENTITY, CONSENT, DIDNT_RECEIVE_CODE_ERROR, DONE, NUMBER_REPLACEMENT, SELECT_PHONE_NUMBER, VERIFY_PHONE_NUMBER, VERIFY_PHONE_NUMBER_CODE } from './index.js'

const StyledStepIndicator = styled.div`
  padding: 48px 0 32px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .status_item {
    display: flex;
    flex-direction: column;

    div {
      text-align: center;
    }

    .circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 16px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.4px;
      margin: 0 auto;
      margin-bottom: 30px;
      background: ${({ theme }) => theme.colors.black12};
      border: 2px solid rgba(0, 0, 0, 0.01);
    }

    .status_text {
      margin: 0 16px;
    }

    .in_progress {
      background: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.primaryDark};
    }

    .completed {
      background: ${({ theme }) => theme.colors.primaryDark};
      border: 1px solid ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .active {
    color: ${({ theme }) => theme.colors.primaryDark};
    font-weight: bold;
  }

  .line {
    width: 100px;
    height: 0px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    margin: 20px -24px 0 -24px;
  }

  .check_icon {
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 100%;
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .line {
      width: 30px;
      margin: 20px -12px 0 -12px;
    }

    .status_text {
      display: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .status_text {
      min-width: 125px;
    }
  }
`

const StepIndicator = ({ currentScreen }) => {
  return (
    <StyledStepIndicator data-testid="stepper" className='stepper'>
      {currentScreen === CONFIRM_IDENTITY ? (
        <>
          <span className="visually-hidden">Enable Multifactor Authentication, step 1 of 4</span>
          <div className="status_item complete-form active">
            <div className="circle in_progress">1</div>
            <div className="status_text">Confirm Identity</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">2</div>
            <div className="status_text">Select Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">3</div>
            <div className="status_text">Verify Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">Done</div>
          </div>{' '}
        </>
      ) : (
        ''
      )}

      {currentScreen === SELECT_PHONE_NUMBER || currentScreen === NUMBER_REPLACEMENT ? (
        <>
          <span className="visually-hidden">Enable Multifactor Authentication, step 2 of 4</span>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Confirm Identity</div>
          </div>
          <div className="line" />
          <div className="status_item active">
            {' '}
            <div className="circle in_progress">2</div>
            <div className="status_text">Select Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">3</div>
            <div className="status_text">Verify Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">Done</div>
          </div>{' '}
        </>
      ) : (
        ''
      )}

      {currentScreen === CONSENT ? (
        <>
          <span className="visually-hidden">Enable Multifactor Authentication, step 3 of 4</span>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Confirm Identity</div>
          </div>
          <div className="line" />
          <div className="status_item active">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Select Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle in_progress">3</div>
            <div className="status_text">Verify Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">Done</div>
          </div>{' '}
        </>
      ) : (
        ''
      )}

      {currentScreen === VERIFY_PHONE_NUMBER || currentScreen === VERIFY_PHONE_NUMBER_CODE || currentScreen === DIDNT_RECEIVE_CODE_ERROR ? (
        <>
          <span className="visually-hidden">Enable Multifactor Authentication, step 3 of 4</span>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Confirm Identity</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Select Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item active">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Verify Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">Done</div>
          </div>{' '}
        </>
      ) : (
        ''
      )}

      {currentScreen === DONE ? (
        <>
          <span className="visually-hidden">Enable Multifactor Authentication, step 4 of 4</span>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Confirm Identity</div>
          </div>
          <div className="line" />
          <div className="status_item">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Select Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Verify Phone Number</div>
          </div>
          <div className="line" />
          <div className="status_item active">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Done</div>
          </div>
        </>
      ) : (
        ''
      )}
    </StyledStepIndicator>
  )
}

StepIndicator.propTypes = {
  currentScreen: PropTypes.oneOf([
    'CONFIRM_IDENTITY',
    'SELECT_PHONE_NUMBER',
    'CONSENT',
    'NUMBER_REPLACEMENT',
    'VERIFY_PHONE_NUMBER',
    'VERIFY_PHONE_NUMBER_CODE',
    'DONE'
  ]).isRequired,
}

export default StepIndicator
