import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const PlayIcon = ({ color = '#074EE8' }) => {
    return (
        <StyledSVG
            xmlns="http://www.w3.org/2000/svg" 
            width="31" 
            height="31" 
            viewBox="0 0 31 31" 
            fill="none">
            <path d="M12.75 19.0384L19.0384 15L12.75 10.9616V19.0384ZM15.0016 24.5C13.6877 24.5 12.4527 24.2506 11.2966 23.752C10.1404 23.2533 9.13472 22.5765 8.2795 21.7217C7.42427 20.8669 6.74721 19.8616 6.24833 18.706C5.74944 17.5504 5.5 16.3156 5.5 15.0017C5.5 13.6877 5.74933 12.4527 6.248 11.2966C6.74667 10.1404 7.42342 9.13472 8.27825 8.2795C9.1331 7.42427 10.1383 6.74721 11.294 6.24833C12.4496 5.74944 13.6844 5.5 14.9983 5.5C16.3122 5.5 17.5473 5.74933 18.7034 6.248C19.8596 6.74667 20.8652 7.42342 21.7205 8.27825C22.5757 9.1331 23.2527 10.1383 23.7516 11.294C24.2505 12.4496 24.5 13.6844 24.5 14.9983C24.5 16.3122 24.2506 17.5473 23.752 18.7034C23.2533 19.8596 22.5765 20.8652 21.7217 21.7205C20.8669 22.5757 19.8616 23.2527 18.706 23.7516C17.5504 24.2505 16.3156 24.5 15.0016 24.5ZM15 23C17.2333 23 19.125 22.225 20.675 20.675C22.225 19.125 23 17.2333 23 15C23 12.7666 22.225 10.875 20.675 9.32498C19.125 7.77498 17.2333 6.99998 15 6.99998C12.7666 6.99998 10.875 7.77498 9.32498 9.32498C7.77498 10.875 6.99998 12.7666 6.99998 15C6.99998 17.2333 7.77498 19.125 9.32498 20.675C10.875 22.225 12.7666 23 15 23Z" fill={color} />
        </StyledSVG>
    )
}

export default PlayIcon