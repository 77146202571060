import React, { useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Dropdown } from 'react-bootstrap'

import Title from '../../Common/Title'
import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import { useApiContext } from '../../Hooks/useApiContext'
import LoadingPage from '../../Components/LoadingPage'
import RecentTransactionsSection from '../MyLoan/RecentTransactionsSection'
import { formatDate } from '../../Utils/utils'
import CalendarIcon from '../../assets/cal-icon'
import LeftChevronIcon from '../../assets/left-chevron-icon'
import { buildTestID, FormatMoney } from '../../Utils/utils'

const StyledTransactions = styled.div`
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    .heading-6,
    h2 {
      margin: 0 16px 0 0;
    }
  }

  .year_selection {
    display: flex;
    align-items: center;

    .dropdown {
      position: relative;

      button {
        min-width: 120px;
        min-height: 42px;
        font-size: 18px;
        line-height: 24px;
        padding: 8px 30px 8px 44px;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.black12};
        color: ${({ theme }) => theme.colors.black};
        background: ${({ theme }) => theme.colors.white};

        :focus {
          box-shadow: none;
        }

        :active,
        :focus,
        :focus-visible,
        :focus-within,
        :target,
        :visited {
          color: black !important;
          background-color: white !important;
          box-shadow: none !important;
          background-color: white !important;
        }

        :active {
          background-color: inherit;
          border-color: inherit;
        }

        ::after {
          display: none;
        }
      }
      .cal_icon {
        pointer-events: none;
        position: absolute;
        right: 82px;
        top: 8px;
      }

      .chev_icon {
        pointer-events: none;
        position: absolute;
        right: 12px;
        top: 14px;
        transform: ${({ selectOpen }) =>
          selectOpen ? 'rotate(90deg)' : 'rotate(270deg)'};
      }

      .dropdown-menu {
        border-radius: 0 0 4px 4px;
        transform: translate3d(0px, 40px, 0px) !important;
        padding: 0;

        min-width: unset;
        width: 120px;

        .dropdown-item {
          color: ${({ theme }) => theme.colors.black};
          text-decoration: none;
          font-weight: normal;
          padding: 12px 16px;

          :active {
            background: none;
          }

          :hover {
            background: ${({ theme }) => theme.colors.lightGrey};
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .content {
      flex-direction: column;
      align-items: flex-start;

      .year_selection {
        margin-top: 24px;
      }
    }
  }
`

const Transactions = () => {
  const [selectedTransactions, setSelectedTransactions] = useState([])
  const [selectedOption, setSelectedOption] = useState('')
  const [selectOpen, setSelectOpen] = useState(false)

  const { transactions: apiTransactions } = useApiContext()

  const {
    error: transactionsError,
    loading: transactionsLoading,
    data: transactionsData,
  } = apiTransactions

  if (transactionsLoading || transactionsError || !transactionsData) {
    return <LoadingPage />
  }

  const transactions =
    transactionsData.data.loan.loanData.monetaryEvents.monetaryEvents.map(
      (transaction) => {
        return {
          date: formatDate(transaction.transactionEffectiveDate),
          transaction: transaction.codeDescription,
          amount: FormatMoney(transaction.transactionAmount),
          interest: FormatMoney(transaction.interestAmount),
          mip: FormatMoney(transaction.mipAmount),
          serviceFees: FormatMoney(transaction.servicingFees),
          transactionTotal: FormatMoney(transaction.transactionTotal),
          endingBalance: FormatMoney(transaction.endingBalance),
        }
      },
    )

  const transactionYears = transactions
    .map((transaction) => dayjs(transaction.date).format('YYYY'))
    .filter((item, i, ar) => ar.indexOf(item) === i)

  const defaultTransactions = transactions.filter(
    (transaction) =>
      dayjs(transaction.date).format('YYYY') === transactionYears[0],
  )

  const selectOptions = ['All', ...transactionYears]

  const handleYearSelection = (selection) => {
    setSelectedOption(selection)

    if (selection === 'All') {
      setSelectedTransactions(transactions)
    } else {
      setSelectedTransactions(
        transactions.filter(
          (transaction) =>
            dayjs(transaction.date).format('YYYY') === selection,
        ),
      )
    }
  }

  return (
    <>
      <Title>Transactions</Title>
      <StyleGuide>
        <StyledTransactions selectOpen={selectOpen}>
          <Grid>
            <div
              className="content"
              data-gridcolumnlayout={{
                xl: '2/14',
                lg: '1/13',
                md: '1/13',
                sm: '1/9',
              }}
            >
              <h1 className="heading-6">Transactions</h1>
              <div className="year_selection">
                <h2 className="body-2 bold">Select a year</h2>
                <Dropdown
                  aria-label="select a year to view transaction history"
                  onSelect={(selection) =>
                    handleYearSelection(selection)
                  }
                  onToggle={(isOpen) => setSelectOpen(isOpen)}
                  data-testid={buildTestID(
                    'transaction_year_selection',
                    'transactions',
                  )}
                >
                  <CalendarIcon className="cal_icon" />
                  <LeftChevronIcon className="chev_icon" />
                  <Dropdown.Toggle
                    id="dropdown-title"
                    bsPrefix="unset"
                  >
                    {selectedOption || transactionYears[0]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {selectOptions.map((year, index) => {
                      return (
                        <Dropdown.Item
                          key={year}
                          eventKey={year}
                          value={year}
                          data-testid={buildTestID(
                            String(index),
                            '_transactions',
                          )}
                        >
                          {year}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <RecentTransactionsSection
              data-gridcolumnlayout={{
                xl: '2/14',
                lg: '1/13',
                md: '1/13',
                sm: '1/9',
              }}
              onAllTransactionsPage={true}
              transactions={
                selectedTransactions.length
                  ? selectedTransactions
                  : defaultTransactions
              }
            />
          </Grid>
        </StyledTransactions>
      </StyleGuide>
    </>
  )
}

export default Transactions
