import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
    stroke: ${({ theme, color }) => theme.colors[color]};
  }
`

const ContractIcon = ({ color = 'primaryDark' }) => {
  return (
    <StyledSVG
      color={color}
      fill="none"
      viewBox="0 0 140 140"
      role="presentation"
    >
      <path
        fill="#000"
        stroke="#000"
        strokeWidth="0.5"
        d="M64.854 53.217c0 12.785 10.402 23.187 23.188 23.187 12.785 0 23.187-10.402 23.187-23.187s-10.402-23.188-23.187-23.188c-12.786 0-23.188 10.402-23.188 23.188zm42.842 0c0 10.837-8.817 19.654-19.654 19.654-10.838 0-19.655-8.817-19.655-19.654 0-10.838 8.817-19.654 19.655-19.654 10.837 0 19.654 8.816 19.654 19.654zM88.042 41.292c.975 0 1.766.79 1.766 1.766v.746a5.309 5.309 0 013.534 4.996 1.767 1.767 0 01-3.534 0c0-.974-.792-1.767-1.766-1.767s-1.767.793-1.767 1.767v.715a1.76 1.76 0 001.24 1.686l2.107.659a5.278 5.278 0 013.72 5.059v.714a5.309 5.309 0 01-4.196 5.184v.558a1.767 1.767 0 01-3.533 0v-1.034a5.304 5.304 0 01-2.871-4.708 1.767 1.767 0 013.533 0c0 .974.793 1.767 1.767 1.767s1.766-.793 1.766-1.767v-.715a1.76 1.76 0 00-1.24-1.686l-2.107-.658a5.278 5.278 0 01-3.72-5.06V48.8a5.309 5.309 0 013.534-4.996v-.746c0-.975.791-1.766 1.767-1.766zm28.708 46.816v22.525c0 6.819-5.548 12.367-12.367 12.367H36.367C29.547 123 24 117.452 24 110.633V24.067A7.075 7.075 0 0131.067 17h57.416a7.075 7.075 0 017.067 7.067 1.767 1.767 0 01-3.533 0 3.538 3.538 0 00-3.534-3.534H31.067a3.538 3.538 0 00-3.534 3.534v86.566c0 4.871 3.963 8.834 8.834 8.834H95.74a12.33 12.33 0 01-3.723-8.834V83.029a1.767 1.767 0 013.533 0v27.604c0 4.871 3.963 8.834 8.833 8.834 4.871 0 8.834-3.963 8.834-8.834V88.108a3.537 3.537 0 00-3.534-3.533h-7.508a1.767 1.767 0 110-3.533h7.508a7.075 7.075 0 017.067 7.066zM39.458 64.48c0-.975.791-1.766 1.767-1.766h15.017a1.767 1.767 0 010 3.533H41.225a1.767 1.767 0 01-1.767-1.767zm0-19.875c0-.975.791-1.766 1.767-1.766h15.017a1.767 1.767 0 010 3.533H41.225a1.767 1.767 0 01-1.767-1.767zm40.634 39.75c0 .976-.791 1.767-1.767 1.767h-37.1a1.767 1.767 0 010-3.533h37.1c.976 0 1.767.79 1.767 1.766zm0 18.55c0 .976-.791 1.767-1.767 1.767h-37.1a1.767 1.767 0 010-3.534h37.1c.976 0 1.767.792 1.767 1.767z"
      ></path>
    </StyledSVG>
  )
}

export default ContractIcon
