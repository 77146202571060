import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const Refinancing = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.Refinancing, title)
  })

  return (
    <>
      <h3 ref={title} id="refinancing" tabIndex="-1">
        Refinancing
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              I’d like to see if I can get more money by refinancing
              my loan. Who should I call?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Refinancing contact information is available on your My Loan page when you log in to your account on this website. You may also email us at  <a href="mailto:BC@reversedepartment.com">
                BC@reversedepartment.com
              </a>{' '} and we will be happy to provide you with the contact information to discuss a refinance.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default Refinancing
