import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const ErrorIcon = ({ color = 'error' }) => {
  return (
    <StyledSVG
      color={color}
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Error Icon</title>
      <path
        d="M8.99935.66675c-4.60833 0-8.33333 3.725-8.33333 8.33333 0 4.60832 3.725 8.33332 8.33333 8.33332 4.60835 0 8.33335-3.725 8.33335-8.33332 0-4.60833-3.725-8.33333-8.33335-8.33333zM13.166 11.9917l-1.175 1.175-2.99165-2.9916-2.99167 2.9916-1.175-1.175 2.99167-2.99162-2.99167-2.99167 1.175-1.17499 2.99167 2.99166L11.991 4.83342l1.175 1.17499-2.9917 2.99167 2.9917 2.99162z"
        fill="#B00020"
      />
    </StyledSVG>
  )
}

export default ErrorIcon
