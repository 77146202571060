import React from 'react'
import HelpfulResources from '../Components/HelpfulResources'

const HelpfulResourcesContainer = () => {
    return (
        <>
            <HelpfulResources />
        </>
    )
}

export default HelpfulResourcesContainer
