import React, { useEffect, useState } from 'react'
import Styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import useWindowSize from '../../Hooks/useWindowSize'
import { trackESignConsent } from '../../Global/Analytics'
import {
  ESIGN_CONSENT, ESIGN_CONSENT_PREFERENCE
} from './index'
import ESignConsentPreference from './modal/ESignConsentPreference'

const StyledESignConsent = Styled.div`
width: 100%;
padding: 32px;
margin-top: 32px;
box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
border-radius: 4px;
display: flex;
flex-direction: column;

.heading {
  margin: 0;
}

.sub_heading {
  margin: 16px 0 32px 0;
}

.group_title {
  margin: 0 0 12px 0;
}

.consent {
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
}

.edit_group {
  display: flex;
  justify-content: space-between;
  margin: 0 0 32px 0;

  button {
    text-align: end;
    margin-left: 24px;
  }
}

p {
  margin: 0;
}

.note_container {
  h6 {
    margin: 0;
  }
  background: ${({ theme }) => theme.colors.primary4};
  padding: 16px;
  border-radius: 4px;
}

@media (max-width: ${({ theme }) => theme.breakPoints.small}) {
  box-shadow: none;
  margin-top: 24px;
  padding: 0;

  .edit_group {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    
    .removed_esign_consent {
      width: 100%;
      margin-top: 20px;
      
      button {
        text-align: center;
        margin-left: 0;
        line-height: 20px;
      }
    }
  }

  .note_container {
    margin-top: 20px;
  }
}
`

const ESignConsent = ({
  signatureId,
  email,
  eSignExpiredDate,
  scrollRef,
  currentScreen,
  setCurrentScreen
}) => {
  const { isSmall } = useWindowSize()
  const [eSignConsent, setESignConsent] = useState("Not Applicable")

  useEffect(() => {
    if (eSignExpiredDate !== null)
      if (new Date(eSignExpiredDate) >= new Date())
        setESignConsent("Approved")
      else
        setESignConsent("Removed")
  }, [eSignExpiredDate])

  return (
    <>
      <ESignConsentPreference
        signatureId={signatureId}
        email={email}
        show={currentScreen === ESIGN_CONSENT_PREFERENCE}
        closeScreen={() => setCurrentScreen(ESIGN_CONSENT)}
        setESignConsent={setESignConsent}
      />
      {currentScreen === ESIGN_CONSENT || !isSmall ? (
        <StyledESignConsent id="esign_consent">
          <div ref={scrollRef} className="scroll" />
          <h2
            className="heading-6 heading"
            role={currentScreen === ESIGN_CONSENT ? 'alert' : null}
          >
            eSign Consent
          </h2>
          <p className="subtitle-2 sub_heading">
            In order to complete electronic signatures you have to review and accept our Electronic Consent Agreement. You can change your consent status here.
          </p>
          <h3 className="subtitle-2 bold group_title">
            Consent Status:
          </h3>
          <div className="edit_group">
            <div className="consent">
              <p className="subtitle">{eSignConsent}</p>
            </div>
            {eSignConsent === "Approved" &&
              < Button
                className="removed_esign_consent"
                text="Remove Electronic Consent"
                onClick={() => {
                  trackESignConsent.Removed()
                  setCurrentScreen(ESIGN_CONSENT_PREFERENCE)
                }}
                UID="removed_esign_consent"
                textButton={isSmall ? false : true}
                fullWidth={isSmall ? true : false}
              />
            }
          </div>
        </StyledESignConsent>
      ) : null}
    </>
  )
}

ESignConsent.propTypes = {
  scrollRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  currentScreen: PropTypes.string.isRequired,
  setCurrentScreen: PropTypes.func.isRequired
}

export default ESignConsent