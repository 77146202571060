import React from "react"
import styled from "styled-components"
import Button from '../../Common/button'
import { useHistory, useLocation } from 'react-router-dom'
import { MULTIFACTOR_SUCCESS } from "../FinishYourAccount"

const StyledDone = styled.div`
h1, h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }
  
  p {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 20px;
    // background: #fafafa;
  }

  div.col:nth-child(even) {
    text-align: center;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-around;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .enable-mfa-done {
      background-color: #fff;
      padding: 15px;
      margin: 32px auto 0;
      width: 600px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      // text-align: center;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // padding: 24px;
      // background: #FAFAFA;
      font-size: 16px;
      // font-weight: 700;
      // margin: 25px 35px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {

 }
  `

const Done = ({ mainRef, setCurrentScreen, completed, setCompleted }) => {
  const history = useHistory()
  const location = useLocation()

  return (
    <StyledDone>
      <div className="enable-mfa-done"
        data-gridcolumnlayout={{
          xl: '1/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef} className="mb-3">You did it!</h1>
        <p>By enabling Multifactor Authentication, you have improved the security of your personal information.</p>
        <p>Going forward, each time you log in, we’ll send you a text message with a new code to confirm it’s you.</p>
        <hr />
        <div className="buttons">
          {location.pathname === '/finish-your-account'
            ? (<Button
              text='Back to Finish Your Account'
              onClick={() => { setCompleted([...completed, MULTIFACTOR_SUCCESS]); setCurrentScreen(MULTIFACTOR_SUCCESS) }}
              UID="done"
            />) : (
              <Button
                text='Return to Login Settings'
                onClick={() => history.push('/account-settings#login-settings')}
                UID="done"
              />)}
        </div>
      </div>
    </StyledDone>
  )
}

export default Done;