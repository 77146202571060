import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Dropdown } from 'react-bootstrap'

import { list } from '../../services/file'
import Button from '../../Common/button'
import { download } from '../../services/file'
import { trackStatementDownload } from '../../Global/Analytics'
import PDFIcon from '../../assets/pdf-icon.js'
import CalendarIcon from '../../assets/cal-icon'
import LeftChevronIcon from '../../assets/left-chevron-icon'
import LoadingPage from '../../Components/LoadingPage'
import useWindowSize from '../../Hooks/useWindowSize'
import { buildTestID } from '../../Utils/utils'
import TempLogoChip from '../../assets/temp-loan-chip-icon'
import ChipLogoPlaceholder from '../../assets/placeholder-chip-logo-icon.js'
import HaveQuestions from '../../Common/HaveQuestions.js'

const StyledStatements = styled.div`
  padding: 32px;
  margin: 32px 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  .heading-6 {
    margin: 0 0 28px 0;
  }

  .break_line {
    margin: 0 -32px 20px -32px;
  }

  .body-1 {
    text-align: start;
    margin: 0;
  }

  .statements {
    display: flex;
    align-items: start;

    button {
      margin: 24px 0 0 0;
    }
  }

  .dropdowns {
    display: flex;
    justify-content: start;
  }

  .year_selection,
  .loan_selection {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 0 24px 0;

    .dropdown {
      position: relative;

      button {
        min-width: 120px;
        min-height: 42px;
        font-size: 18px;
        line-height: 24px;
        padding: 8px 30px 8px 44px;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.black12};
        color: ${({ theme }) => theme.colors.black};
        background: ${({ theme }) => theme.colors.white};

        :focus {
          box-shadow: none;
        }

        :active,
        :focus,
        :focus-visible,
        :focus-within,
        :target,
        :visited {
          color: black !important;
          background-color: white !important;
          box-shadow: none !important;
          background-color: white !important;
        }

        :active {
          background-color: inherit;
          border-color: inherit;
        }

        ::after {
          display: none;
        }
      }
      .cal_icon {
        pointer-events: none;
        position: absolute;
        right: 82px;
        top: 8px;
      }

      .chev_icon {
        pointer-events: none;
        position: absolute;
        right: 12px;
        top: 14px;
        transform: ${({ selectYearOpen }) =>
    selectYearOpen ? 'rotate(90deg)' : 'rotate(270deg)'};
      }

      .dropdown-menu {
        border-radius: 0 0 4px 4px;
        transform: translate3d(0px, 40px, 0px) !important;
        padding: 0;

        min-width: unset;
        width: 120px;

        .dropdown-item {
          color: ${({ theme }) => theme.colors.black};
          text-decoration: none;
          font-weight: normal;
          padding: 12px 16px;

          :active {
            background: none;
          }

          :hover {
            background: ${({ theme }) => theme.colors.lightGrey};
          }
        }
      }
    }
  }

  .loan_selection {
    margin-right: 32px;

    .dropdown {
      .dropdown-toggle {
        width: 310px;
        text-align: start;
      }

      .dropdown-menu {
        width: 310px;
        .dropdown-item {
          text-align: start;
          text-transform: none;
          padding: 12px 52px;
        }
      }

      button {
        padding: 8px 30px 8px 50px;
      }

      .chev_icon {
        transform: ${({ selectLoanOpen }) =>
    selectLoanOpen ? 'rotate(90deg)' : 'rotate(270deg)'};
      }

      .logo_chip,
      .logo_chip_temp,
      .logo_chip_placeholder {
        pointer-events: none;
        position: absolute;
        right: 266px;
        top: 8px;
      }

      .logo_chip_placeholder {
        top: 48px;
      }
    }
  }

  .empty_state {
    p {
      margin-top: 20px;
      text-align: center;
      background: ${({ theme }) => theme.colors.lightGrey};
      padding: 32px 24px;
      border-radius: 4px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    margin: 32px 12px 32px 0;

    .dropdowns {
      display: block;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    margin: 32px 0 32px 0;
    .dropdowns {
      display: flex;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    padding: 0;
    margin: 24px 0;
    box-shadow: none;

    .dropdowns {
      display: block;
    }

    .break_line {
      display: none;
    }
  }
`

const Statements = ({ loan }) => {
  const [statements, setStatements] = useState([])
  const [selectedYear, setSelectedYear] = useState('')
  const [availableYears, setAvailableYears] = useState([])
  const [selectYearOpen, setSelectYearOpen] = useState(false)

  const [loans, setLoans] = useState([])
  const [selectedLoan, setSelectedLoan] = useState('')
  const [selectLoanOpen, setSelectLoanOpen] = useState(false)

  const [loading, setLoading] = useState(true)

  const { isXSmall } = useWindowSize()

  const mainRef = useRef(null);

  useEffect(() => {
    if (!loading)
      mainRef.current.focus();
  }, [loading]);

  // TODO: update to utilize loan switcher when back end support is available
  useEffect(() => {
    setLoans([
      // 'Active loan ending in 5678',
      // 'Inactive loan ending in 1234',
    ])
  }, [])

  useEffect(() => {
    const getStatements = async () => {
      try {
        const dataEnglish = await list(`${loan.loanId}/statements/en`)
        const dataSpanish = await list(`${loan.loanId}/statements/es`)
        const allData = [...dataEnglish.results, ...dataSpanish.results].filter(s => s.size > 0)
        
        if (allData.length) {
          const statements = allData
            .map((statement) => {
              return {
                key: statement.key,
                language: statement.key.split('_')[3].slice(0, 2),
                date: statement.key.split('_')[2],
              }
            })
            .sort()
            .reverse()

          const statementYears = allData
            .map((statement) =>
              dayjs(statement.key.split('_')[2]).format('YYYY'),
            )
            .filter((item, i, ar) => ar.indexOf(item) === i)
            .reverse()

          setStatements(statements)
          setAvailableYears(statementYears)
          setSelectedYear(statementYears[0])
        }
      } catch (e) {
        new Error('There was an error getting the statements', e)
      } finally {
        setLoading(false)
      }
    }
    getStatements()
  }, [loan])

  if (loading) return <LoadingPage />

  return (
    <StyledStatements
      selectYearOpen={selectYearOpen}
      selectLoanOpen={selectLoanOpen}
    >
      <h1 className="heading-6" tabIndex={-1} ref={mainRef}>Statements</h1>
      {statements.length ? (
        <>
          <div className="dropdowns">
            {loans.length > 1 ? (
              <div className="loan_selection">
                <h2 className="body-2 bold">Select a Loan</h2>
                <Dropdown
                  aria-label="select a loan to view relevant statements"
                  onSelect={(selection) => setSelectedLoan(selection)}
                  onToggle={(isOpen) => setSelectLoanOpen(isOpen)}
                  data-testid={buildTestID(
                    'loan_selection',
                    'statements_and_documents',
                  )}
                >
                  {selectedLoan.includes('Inactive') ? (
                    <ChipLogoPlaceholder className="logo_chip" />
                  ) : (
                    <TempLogoChip className="logo_chip" />
                  )}
                  <LeftChevronIcon className="chev_icon" />
                  <Dropdown.Toggle id="dropdown-title">
                    {selectedLoan || loans[0]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {loans.map((loan, index) => {
                      return (
                        <Dropdown.Item
                          key={loan}
                          eventKey={loan}
                          value={loan}
                          data-testid={buildTestID(
                            String(index),
                            '_loan_options',
                          )}
                        >
                          {loan.includes('Active') ? (
                            <ChipLogoPlaceholder className="logo_chip_placeholder" />
                          ) : (
                            <TempLogoChip className="logo_chip_temp" />
                          )}
                          {loan}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : null}
            <div className="year_selection">
              <h2 className="body-2 bold">Select a year</h2>
              <Dropdown
                aria-label="select a year to view transaction history"
                onSelect={(selection) => setSelectedYear(selection)}
                onToggle={(isOpen) => setSelectYearOpen(isOpen)}
                data-testid={buildTestID(
                  'statements_year_selection',
                  'statements_and_documents',
                )}
              >
                <CalendarIcon className="cal_icon" />
                <LeftChevronIcon className="chev_icon" />
                <Dropdown.Toggle id="dropdown-title">
                  {selectedYear || availableYears[0]}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {['All', ...availableYears].map((year, index) => {
                    return (
                      <Dropdown.Item
                        key={year}
                        eventKey={year}
                        value={year}
                        data-testid={buildTestID(
                          String(index),
                          'year_options',
                        )}
                      >
                        {year}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="break_line" />
          <h3 className={`${isXSmall ? 'body-2' : 'body-1'}`}>
            Your statements are available online for up to 2 years.
          </h3>
          {statements
            .filter(
              (transaction) =>
                selectedYear === 'All' ||
                dayjs(transaction.date).format('YYYY') ===
                selectedYear,
            )
            .map((statement) => {
              return (
                <div key={statement.date} className="statements">
                  <Button
                    text={`Statement ${dayjs(statement.date).format(
                      'MM/YYYY',
                    )} (PDF)`}
                    textButton
                    onClick={async () => {
                      trackStatementDownload(statement)
                      await download(statement.key)
                    }}
                    SVG={PDFIcon}
                  />
                </div>
              )
            })}
        </>
      ) : (
        <div className="empty_state">
          <div className="break_line" />
          <h3 className={`${isXSmall ? 'body-2' : 'body-1'}`}>
            Your statements are available online for up to 2 years.
          </h3>
          <p className={`${isXSmall ? 'caption' : 'body-2'}`}>
            You do not have any statements as of right now. They will
            appear here once they are ready to view.
          </p>
        </div>
      )}
      <HaveQuestions />
    </StyledStatements>
  )
}

export default Statements
