import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }
`

const EnvelopeIcon = () => {
    return (
        <StyledSVG
            aria-hidden="true"
            role="img"
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="3" y="4" width="14" height="12" rx="2" fill="#EECE7A" />
            <path d="M4.2564 16C3.89843 16 3.59954 15.8679 3.35972 15.6036C3.11991 15.3393 3 15.0099 3 14.6154V5.38461C3 4.99011 3.11991 4.66071 3.35972 4.39643C3.59954 4.13214 3.89843 4 4.2564 4H15.7436C16.1016 4 16.4005 4.13214 16.6403 4.39643C16.8801 4.66071 17 4.99011 17 5.38461V14.6154C17 15.0099 16.8801 15.3393 16.6403 15.6036C16.4005 15.8679 16.1016 16 15.7436 16H4.2564ZM10 10.0989L3.77778 5.61539V14.6154C3.77778 14.7692 3.82265 14.8956 3.91239 14.9945C4.00213 15.0934 4.1168 15.1429 4.2564 15.1429H15.7436C15.8832 15.1429 15.9979 15.0934 16.0876 14.9945C16.1774 14.8956 16.2222 14.7692 16.2222 14.6154V5.61539L10 10.0989ZM10 9.14286L15.9829 4.85714H4.0171L10 9.14286ZM3.77778 5.61539V4.85714V14.6154C3.77778 14.7692 3.82265 14.8956 3.91239 14.9945C4.00213 15.0934 4.1168 15.1429 4.2564 15.1429H3.77778V5.61539Z" fill="#897C5B" />
        </StyledSVG>
    )
}

export default EnvelopeIcon
