import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const HowReverseMortgagesWork = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.HowReverseMortgagesWork, title)
  })

  return (
    <>
      <h3
        id="how-do-reverse-mortgages-work"
        ref={title}
        tabIndex="-1"
      >
        How Do Reverse Mortgages Work?
      </h3>
      <h5>Home ownership</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Who owns my home?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              You retain full ownership of the property. Your reverse mortgage is simply a loan taken out against the equity in your home.
              <br />
              <br />
              As long as you occupy the home as your principal residence, and certify annually that you do, keep your property taxes, insurance, and any other applicable property charges, current and comply with all of the terms of your reverse mortgage, you will be in no danger of losing your home because of the reverse mortgage.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <h5>Non-recourse</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Reverse mortgage loans are “non-recourse” loans. What
              does that mean?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Non-recourse means the only collateral securing the loan
              is the property itself, and the investor (the owner of
              your loan) may not pursue any other remedies against a
              borrower or their heirs for repayment of the loan. There
              is no credit reporting to any credit bureau on your
              reverse mortgage loan.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <h5>FHA insurance</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Why are HECM loans FHA-insured and what does that mean
              exactly?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              The FHA insurance on HECM loans ensures that the
              borrower will always have access to the available funds
              in their loan in case their lender should go out of
              business (in which case HUD would take over the
              servicing of the loan).
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default HowReverseMortgagesWork
