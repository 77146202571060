import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import UploadFileIcon from '../../assets/upload-file-icon'
import Button from '../../Common/button'
import useWindowSize from '../../Hooks/useWindowSize'
import { trackNavigateToUploadPage } from '../../Global/Analytics'
import { buildTestID } from '../../Utils/utils'

const UploadFileTool = () => {
  const [mockLoading, setMockLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setMockLoading(false), 1500)
  })

  const { isXSmall } = useWindowSize()

  if (!isXSmall) {
    return !mockLoading ? (
      <div className="tool">
        <div className="content">
          <UploadFileIcon />
          <div className="button-and-text">
            <h3 className="body-1 bold">
              Submit a file using our upload tool.
            </h3>
            <Button
              text="go to upload"
              onClick={trackNavigateToUploadPage}
              linkTo="/upload"
              UID="upload"
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="tool">
        <div className="loading">
          <div className="content">
            <div className="rectangle icon" />
            <div className="left-rec-group">
              <div className="rectangle long" />
              <div className="rectangle long" />
              <div className="rectangle large" />
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Link
        to="upload"
        onClick={trackNavigateToUploadPage}
        className="mobile_card"
        data-testid={buildTestID(
          'upload_a_document_or_form',
          '_my_loan',
          'mobile',
        )}
      >
        <div className="icon_section">
          <UploadFileIcon color="white" />
        </div>
        <p className="caption">Upload a document or form</p>
      </Link>
    )
  }
}

export default UploadFileTool
