import React from 'react'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'

const StyledDisclosuresBlock = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  .disclosure-text {
    font-weight: 700;
    margin: 0;
  }

  p:nth-child(2) {
    margin: 24px 0;
  }

  p:nth-child(3) {
    margin-bottom: 54px;
  }

  a {
    text-transform: lowercase;
    font-weight: 700;
  }

  .caption {
    width: 100%;
  }
`

const DisclosuresBlock = () => (
  <StyledDisclosuresBlock className="cell">
    <h2 className="caption disclosure-text">Important Disclosures</h2>
    <p className="caption">
      Please be advised that if payment is received in full, the loan and any line of credit (if applicable) will be permanently closed. If it is not your intention to close the loan and any line of credit, a positive unpaid loan balance must be maintained AND you must indicate with your prepayment that the loan is to remain open and not be paid in full and closed - include your intent with your check or in wire comments. If you are attempting to pay down the loan balance without closing the loan, the amount of your prepayment should be less than the Current Unpaid Balance shown above.
    </p>
    <p className="caption">
      If you don’t want to pay your loan in full and only wish to make
      a pre-payment,{' '}
      <a
        className="caption"
        href="./forms/2024/Partial_Prepayment_Form.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        please complete this form
      </a>{' '}
      and follow the instructions on the form.
    </p>
  </StyledDisclosuresBlock>
)

export default DisclosuresBlock
