import * as React from "react";
import styled from "styled-components";

const Section = styled.section`
  border-radius: 20px;
  border: 1px solid rgba(255, 101, 101, 1);
  background-color: var(--White, #fff);
  display: flex;
  /* max-width: 370px; */
  padding-bottom: 22px;
  flex-direction: column;
  margin: 0 0 20px 0;
`;

const Image = styled.img`
  aspect-ratio: 2.13;
  object-fit: auto;
  object-position: center;
  width: 100%;
`;

const Title = styled.h2`
  color: var(--Red, #ff5050) !important;
  align-self: start;
  margin: 27px 0 0 10px;
  font: 700 18px/144% Lato, sans-serif;
`;

const WarningText = styled.div`
  color: var(--Black, #000);
  margin-top: 30px;
  width: 100%;
  font: 400 14px/20px Lato, sans-serif;
  padding: 10px;

  p {
    margin: 0 0 20px 0;
  }
`;

const CybercrimeWarning = () => {
  return (
    <Section>
      <Image
        loading="lazy"
        src="./assets/cybercrime-warning.png"
        alt="Cybercrime Warning"
      />
      <Title>CYBERCRIME & WIRE FRAUD WARNING</Title>
      <WarningText>
        <p><strong>Email hacking and wire fraud, with the intent to misdirect wired funds, is a risk.</strong></p>
        <p>
          Emailing wire instructions, or relying upon wiring instructions that 
          have been emailed, is dangerous as email is not secure and wire 
          instructions could be intercepted and altered.
        </p>
        <p>
          Always verify the accuracy of wiring instructions with the intended 
          recipient, prior to sending funds, using a trusted and verified phone 
          number for the recipient.
        </p>
        <p>
          Be alert and suspicious of unexpected or last minute changes to wiring 
          instructions, the appearance of misspelled words, and altered email 
          addresses or domain names especially those that closely resemble a 
          trusted email address or domain but are slightly different.
        </p>
      </WarningText>
    </Section>
  );
}

export default CybercrimeWarning;