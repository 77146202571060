import { useEffect, useState } from 'react'
import { getLoan } from '../services/api'
import { extractLoan } from '../Utils/apiExtractors'
import {
  eachWeekendOfYear,
  addMonths,
  startOfMonth,
  addDays,
  startOfToday,
} from 'date-fns'

const today = startOfToday(new Date())
const thirtyDaysAhead = addDays(today, 30)

const firstDayTwoMonthsAheaad = startOfMonth(addMonths(today, 2))
const secondDayTwoMonthsAheaad = addDays(firstDayTwoMonthsAheaad, 1)
const thirdDayTwoMonthsAheaad = addDays(firstDayTwoMonthsAheaad, 2)
const fourthDayTwoMonthsAheaad = addDays(firstDayTwoMonthsAheaad, 3)

// Good Through Dates are allowed 30 days in advance, unless it lands two months ahead
// These dates account for that possibility during short months
const firstFourDays2MonthsAhead = [
  firstDayTwoMonthsAheaad,
  secondDayTwoMonthsAheaad,
  thirdDayTwoMonthsAheaad,
  fourthDayTwoMonthsAheaad,
]

const weekends = eachWeekendOfYear(today)

//TODO: Future holidays to be added or API utilized
const holidays_2021 = [
  new Date('Fri, Jan 1, 2021'),
  new Date('Mon, Jan 18, 2021'),
  new Date('Mon, May 31, 2021'),
  new Date('Fri, Jun 18, 2021'),
  new Date('Mon, Jul 5, 2021'),
  new Date('Mon, Sep 6, 2021'),
  new Date('Thu, Nov 11, 2021'),
  new Date('Thu, Nov 25, 2021'),
  new Date('Fri, Dec 24, 2021'),
  new Date('Fri, Dec 31, 2021'),
]

const holidays_2022 = [
  new Date('Mon, Jan 17, 2022'),
  new Date('Mon, May 30, 2022'),
  new Date('Mon, Jun 20, 2022'),
  new Date('Mon, Jul 4, 2022'),
  new Date('Mon, Sep 5, 2022'),
  new Date('Fri, Nov 11, 2022'),
  new Date('Thu, Nov 24, 2022'),
  new Date('Mon, Dec 26, 2022'),
]

const holidays_2023 = [
  new Date('Mon, Jan 2, 2023'),
  new Date('Mon, Jan 16, 2023'),
  new Date('Mon, May 29, 2023'),
  new Date('Mon, Jun 19, 2023'),
  new Date('Tue, Jul 4, 2023'),
  new Date('Mon, Sep 4, 2023'),
  new Date('Fri, Nov 10, 2023'),
  new Date('Thu, Nov 23, 2023'),
  new Date('Mon, Dec 25, 2023'),
]

const holidays_2024 = [
  new Date('Mon, Jan 1, 2024'),
  new Date('Mon, Jan 15, 2024'),
  new Date('Mon, May 27, 2024'),
  new Date('Wed, Jun 19, 2024'),
  new Date('Thu, Jul 4, 2024'),
  new Date('Mon, Sep 2, 2024'),
  new Date('Mon, Nov 11, 2024'),
  new Date('Thu, Nov 28, 2024'),
  new Date('Wed, Dec 25, 2024'),
]

const weekendsAndHolidays = [
  ...weekends,
  ...holidays_2021,
  ...holidays_2022,
  ...holidays_2023,
  ...holidays_2024,
]

const disallowedDays = [
  ...firstFourDays2MonthsAhead,
  ...weekendsAndHolidays,
]

export const usePayoffDateEligibility = () => {
  const [maxDate, setMaxDate] = useState(thirtyDaysAhead)
  const [isMass, setIsMass] = useState(false)
  const [isInvalidDate, setIsInvalidDate] = useState(false)

  // All states require a fixed date that must be at least 30 days out
  // the fixed date must not land on a holiday or weekend and is limited to the
  // current or following month otherwise subsequent days may be added
  const getMaxDate = () => {
    var date = thirtyDaysAhead

    while (
      weekendsAndHolidays
        .map((day) => day.toString())
        .includes(date.toString())
    ) {
      date = addDays(date, 1)
    }

    if (
      firstFourDays2MonthsAhead
        .map((day) => day.toString())
        .includes(date.toString())
    ) {
      setIsInvalidDate(true)
    }

    setMaxDate(date)
  }

  useEffect(() => {
    const getState = async () => {
      const loan = extractLoan(await getLoan())
      const state = loan.property.state

      if (state === 'MA') setIsMass(true)

      getMaxDate()
    }
    getState()
  }, [])

  return {
    disallowedDays,
    maxDate,
    isMass,
    isMassAndInvalidDate: isMass && isInvalidDate,
  }
}
