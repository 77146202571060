import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const PDFIcon = ({ color = 'primary', className }) => {
  return (
    <StyledSVG
      color={color}
      fill="none"
      width="16"
      height="21"
      className={className}
    >
      <path
        fill="#5A5A5A"
        d="M3.963 12.867c0-.332-.227-.53-.626-.53-.164 0-.274.016-.332.032v1.066c.069.015.153.02.269.02.426 0 .689-.218.689-.588zm2.473-.519c-.179 0-.294.016-.363.032v2.36c.069.017.18.017.28.017.725.005 1.198-.401 1.198-1.263.006-.75-.426-1.145-1.115-1.145z"
      ></path>
      <path
        fill="#5A5A5A"
        d="M11.032 0H3.14C1.986 0 1.047.956 1.047 2.129V10H.843a.85.85 0 00-.843.858v5.202a.85.85 0 00.843.858h.204v.953c0 1.175.939 2.129 2.092 2.129h10.77C15.06 20 16 19.046 16 17.871V5.038L11.032 0zm-8.82 11.797a6.492 6.492 0 011.083-.075c.494 0 .846.096 1.083.289.226.182.379.482.379.835s-.116.653-.326.856c-.274.262-.679.38-1.152.38-.105 0-.2-.005-.274-.016v1.29h-.794v-3.56zm11.697 6.9H3.139a.819.819 0 01-.81-.826v-.953h10.04a.85.85 0 00.843-.858v-5.202a.85.85 0 00-.843-.858H2.329V2.129c0-.454.363-.824.81-.824l7.414-.008v2.789c0 .815.65 1.476 1.45 1.476l2.685-.008.03 12.317a.818.818 0 01-.81.825zm-8.64-3.357v-3.543a7.097 7.097 0 011.083-.075c.673 0 1.11.123 1.452.385.368.279.6.722.6 1.36 0 .69-.248 1.167-.59 1.461-.373.316-.941.466-1.635.466-.416 0-.71-.027-.91-.054zm5.74-2.098v.664H9.736v1.45h-.805V11.75h2.167v.669H9.736v.824h1.273z"
      ></path>
    </StyledSVG>
  )
}

export default PDFIcon
