import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'

import StepIndicator from './StepIndicator'
import ConfirmIdentity from './ConfirmIdentity'
import SelectPhoneNumber from './SelectPhoneNumber'
import Consent from './Consent'
import VerifyPhoneNumberCode from './VerifyPhoneNumberCode'
import Done from './Done'
import ConfirmIdentityError from './ConfirmIdentityError'
import NumberReplacement from './NumberReplacement'
import VerifyPhoneNumber from './VerifyPhoneNumber'
import VerifyPhoneNumberCodeError from './VerifyPhoneNumberCodeError'
import CancelMfaModal from './CancelMfaModal'
import DidntReceiveCodeError from './DidntReceiveCodeError'

export const CONFIRM_IDENTITY = 'CONFIRM_IDENTITY'
export const CONFIRM_IDENTITY_ERROR = 'CONFIRM_IDENTITY_ERROR'
export const SELECT_PHONE_NUMBER = 'SELECT_PHONE_NUMBER'
export const NUMBER_REPLACEMENT = 'NUMBER_REPLACEMENT'
export const CONSENT = 'CONSENT'
export const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER'
export const VERIFY_PHONE_NUMBER_CODE = 'VERIFY_PHONE_NUMBER_CODE'
export const VERIFY_PHONE_NUMBER_CODE_ERROR = 'VERIFY_PHONE_NUMBER_CODE_ERROR'
export const DONE = 'DONE'
export const DIDNT_RECEIVE_CODE_ERROR = 'DIDNT_RECEIVE_CODE_ERROR'

const StyledEnableMultifactor = styled.div`
margin-top: 15px;

h1:focus, h1:focus-visible {
  outline: none;
}

padding-bottom: 72px;
//min-height: 900px;

.back_button {
    position: relative;
    display: inline-block;
    margin-top: 28px;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    /* added line */
    border: 0;
}

.visually-hidden:not(:focus):not(:active) {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.stepper-header { display: none; }

.error_text {
  color: ${({ theme }) => theme.colors.error};
  margin: 0 0 24px 0;
}

@media (min-width: ${({ theme }) => theme.breakPoints.small}) {
  .rectangle-10 {
    position: absolute;
    width: 100%;
    height: 286px;
    left: 0px;
    top: 112px;
    
    /* p - dark */
    
    background: #00377A;
    opacity: 0.06;
  }

  .stepper-header {
    font-size: 16px;
   
    left: 400px;
    top: 260px;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    margin-left: 100px;
    letter-spacing: 1px;
  }
}
`
const EnableMultifactor = ({ borrower }) => {
  const [view, setView] = useState(CONFIRM_IDENTITY)
  const [modalShow, setModalShow] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otherNumberType, setOtherNumberType] = useState('')
  const mainRef = useRef(null);

  const primaryPhone = borrower.phone.find(phone => phone.primaryIndicator)

  useEffect(() => {
    if (mainRef)
      mainRef.current.focus();
  }, [mainRef]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [view])

  return (
    <StyleGuide>
      <StyledEnableMultifactor>
        <CancelMfaModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <div className="rectangle-10"></div>
        <StepIndicator currentScreen={view} />
        <h1 className='stepper-header'>Enable Multifactor Authentication</h1>
        <hr />
        <Grid className="grid">
          {view === CONFIRM_IDENTITY && (
            <ConfirmIdentity borrower={borrower} setView={setView} setModalShow={setModalShow} mainRef={mainRef} />
          )}
          {view === CONFIRM_IDENTITY_ERROR && (
            <ConfirmIdentityError mainRef={mainRef} />
          )}
          {view === SELECT_PHONE_NUMBER && (
            <SelectPhoneNumber borrower={borrower} primaryPhone={primaryPhone} setView={setView} setModalShow={setModalShow} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} otherNumberType={otherNumberType} setOtherNumberType={setOtherNumberType} mainRef={mainRef} />
          )}
          {view === NUMBER_REPLACEMENT && phoneNumber.length && (
            <NumberReplacement borrower={borrower} primaryPhone={primaryPhone} setView={setView} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} mainRef={mainRef} />
          )}
          {view === CONSENT && (
            <Consent setView={setView} setModalShow={setModalShow} phoneNumber={phoneNumber} otherNumberType={otherNumberType}  mainRef={mainRef} />
          )}
          {view === VERIFY_PHONE_NUMBER && (
            <VerifyPhoneNumber setView={setView} phoneNumber={phoneNumber} mainRef={mainRef} />
          )}
          {view === VERIFY_PHONE_NUMBER_CODE && (
            <VerifyPhoneNumberCode setView={setView} phoneNumber={phoneNumber} mainRef={mainRef} />
          )}
          {view === VERIFY_PHONE_NUMBER_CODE_ERROR && (
            <VerifyPhoneNumberCodeError mainRef={mainRef} />
          )}
          {view === DIDNT_RECEIVE_CODE_ERROR && (
            <DidntReceiveCodeError setView={setView} mainRef={mainRef} />
          )}
          {view === DONE && (
            <Done mainRef={mainRef} />
          )}
        </Grid>
      </StyledEnableMultifactor>
    </StyleGuide>
  )
}

export default EnableMultifactor
