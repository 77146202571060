import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Hub } from 'aws-amplify'
import UAParser from 'ua-parser-js'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { useApiContext } from '../Hooks/useApiContext'
import {
  extractServicer,
  extractBorrowerAndCoborrowers,
  extractLoan,
} from '../Utils/apiExtractors'

export const trackRegistration = {
  Started: () =>
    window.analytics.track('Registration Started', {
      activity: 'account registration',
      description: 'clicked create account button',
      button_name: 'Create Account',
    }),
  StepTwo: () => {
    window.analytics.track('Registration Step Completed', {
      activity: 'account registration',
      description: 'entered loan information',
      button_name: 'Continue',
    })
  },
  StepThree: () => {
    window.analytics.track('Registration Step Completed', {
      activity: 'account registration',
      description: 'entered Upb amount',
      button_name: 'Continue',
    })
  },
  StepFour: () =>
    window.analytics.track('Registration Step Completed', {
      activity: 'account registration',
      description: 'setup email and password',
      button_name: 'Create My Account',
    }),
  Completed: () =>
    window.analytics.track('Registration Completed', {
      activity: 'account registration',
      description: 'verified email',
      button_name: 'Confirm',
    }),
}

export const track2FA = {
  Started: () =>
    window.analytics.track('2FA Started', {
      activity: '2fa',
      description: 'valid phone number submitted',
      button_name: 'Confirm',
    }),
  OptedOut: () => {
    trackFirstTimeUserSignedIn()
    window.analytics.identify({
      two_fa_opt_out: true,
    })
    window.analytics.track('2FA Opted Out', {
      activity: '2fa',
      description: '2fa opt out',
      button_name: "No I don't want to verify my phone",
    })
  },
  Completed: () => {
    trackFirstTimeUserSignedIn()
    window.analytics.identify({
      two_fa_opt_out: false,
    })
    window.analytics.track('2FA Completed', {
      activity: '2fa',
      description: 'phone number verified',
      button_name: 'Confirm',
    })
  },
  FinishYourAccount: () => {
    window.analytics.identify({
      two_fa_opt_out: false,
    })
    window.analytics.track('Finsh Your Account 2FA Completed', {
      activity: '2fa',
      description: 'finish your account phone number verified',
      button_name: 'Verify Code',
    })
  }
}

export const trackPayoffQuote = {
  Started: ({ fromNav = false }) =>
    window.analytics.track('Payoff Quote Started', {
      activity: 'payoff quote request',
      description: fromNav
        ? 'clicked request quote menu item'
        : 'clicked request quote button from my loan page',
      button_name: fromNav
        ? 'Request a Payoff Quote'
        : 'Request Quote',
    }),
  Requested: (good_through_date) => {
    window.analytics.track('Payoff Quote Requested', {
      activity: 'payoff quote request',
      description: 'clicked get quote button',
      button_name: 'Get Quote',
      good_through_date,
    })
  },
  Downloaded: (good_through_date) => {
    window.analytics.track('Payoff Quote Downloaded', {
      activity: 'payoff quote request',
      description: 'downloaded payoff quote',
      button_name: 'Click To View Quote',
      good_through_date,
    })
  },
}

export const trackLOCRequest = {
  Started: ({ fromNav = false }) =>
    window.analytics.track('LOC Request Started', {
      activity: 'line of credit request',
      description: fromNav
        ? 'clicked request advance menu item'
        : 'clicked request advance button from my loan page',
      button_name: fromNav
        ? 'Request a Line of Credit Advance'
        : 'Request Advance',
    }),
  StepTwo: () => {
    window.analytics.track('LOC Request Step Completed', {
      activity: 'line of credit request',
      description: 'completed form',
      button_name: 'Continue',
    })
  },
  StepThree: () => {
    window.analytics.track('LOC Request Step Completed', {
      activity: 'line of credit request',
      description: 'reviewed request',
      button_name: 'Continue',
    })
  },
  StepFour: (selection_type) => {
    window.analytics.track('LOC Request Step Completed', {
      activity: 'line of credit request',
      description: 'selected verification method',
      selection_type,
      button_name: 'Send Code',
    })
  },
  StepFive: () => {
    window.analytics.track('LOC Request Step Completed', {
      activity: 'line of credit request',
      description: 'identity verified successfully',
      button_name: 'Verify Code',
    })
  },
  Completed: () =>
    window.analytics.track('LOC Request Completed', {
      activity: 'line of credit request',
      description: 'loc request submitted',
      button_name: 'Submit Request',
    }),
}

export const trackGoPaperless = {
  Banner: ({ fromMyLoanPage = false }) =>
    window.analytics.track('Go Paperless Banner Clicked', {
      activity: 'go paperless',
      page: fromMyLoanPage ? 'my-loan' : 'statements',
      description: fromMyLoanPage
        ? 'clicked see delivery options button from my loan page'
        : 'clicked see delivery options button from statements page',
      button_name: 'see delivery options',
    }),
  Started: () => {
    window.analytics.track('Delivery Preference Change Started', {
      activity: 'go paperless',
      description: 'clicked change my delivery preference',
      button_name: 'change my delivery preference',
    })
  },
  Completed: ({ selected_go_paperless }) => {
    window.analytics.track('Delivery Preference Change Completed', {
      activity: 'go paperless',
      description: 'changed delivery preference',
      selected_go_paperless,
      button_name: 'save',
    })
    window.analytics.identify({
      paperless_opt_in: selected_go_paperless,
    })
  },
  FinishYourAccount: ({ selected_go_paperless }) => {
    window.analytics.track('Finish Your Account Paperless Completed', {
      activity: 'go paperless',
      description: 'finish your account paperless opt in',
      selected_go_paperless,
      button_name: 'opt in now',
    })
    window.analytics.identify({
      paperless_opt_in: selected_go_paperless,
    })
  }
}

export const trackESignConsent = {
  Started: () =>
    window.analytics.track('Occupancy Certification eSign Started', {
      activity: 'occupancy certification esign',
      description: 'clicked review & sign document button from my loan page',
      button_name: 'review_and_sign_button',
    }),
  StepCompleted: (description, button) =>
    window.analytics.track('Occupancy Certification eSign Step Completed', {
      activity: 'occupancy certification esign',
      description: description,
      button_name: button,
    }),
  Completed: (description, button) =>
    window.analytics.track('Occupancy Certification eSign Completed', {
      activity: 'occupancy certification esign',
      description: description,
      button_name: button,
    }),
  Skip: (description, button) =>
    window.analytics.track('Occupancy Certification eSign Step Skipped', {
      activity: 'occupancy certification esign',
      description: 'clicked review & sign document button from my loan page',
      button_name: 'review_and_sign_button',
    }),
  Removed: () => window.analytics.track('Occupancy Certification eSign Removed', {
    activity: 'occupancy certification esign',
    description: 'clicked removed button from account settings page',
    button_name: 'review_and_sign_button',
  })
}

export const trackChangePassword = {
  Started: () =>
    window.analytics.track('Change Password Started', {
      activity: 'change my password',
      description:
        'clicked change my password button from account settings',
      button_name: 'change my password',
    }),
  StepTwo: () => {
    window.analytics.track('Change Password Step Completed', {
      activity: 'change my password',
      description: 'successfully sent verification code',
      button_name: 'send',
    })
  },
  Completed: () => {
    window.analytics.track('Change Password Completed', {
      activity: 'change my password',
      description: 'successfully verified code and changed password',
      button_name: 'update',
    })
  },
}

export const trackDeliveryPreferenceRadioSelected = {
  Mail: () => {
    window.analytics.track('Radio Selected', {
      radio_button_name: 'mail',
      page: 'account-settings',
      group: 'delivery preference',
    })
  },
  Online: () => {
    window.analytics.track('Radio Selected', {
      radio_button_name: 'online',
      page: 'account-settings',
      group: 'delivery preference',
    })
  },
}

export const trackLOCRadioSelected = {
  Email: () => {
    window.analytics.track('Radio Selected', {
      radio_button_name: 'Email code to',
      page: 'advance-request',
      group: 'where should we send your verification code',
    })
  },
  Phone: () => {
    window.analytics.track('Radio Selected', {
      radio_button_name: 'Text code to',
      page: 'advance-request',
      group: 'where should we send your verification code',
    })
  },
}

export const trackFirstTimeUserSignedIn = () => {
  window.analytics.track('First Time Signed In', {
    activity: `user signed in for the first time after account creation`,
    description: 'landed on 2fa screen',
    button_name: `No I don't want to verify my phone,Confirm`,
  })
}

export const trackUserSignedOut = () => {
  window.analytics.track('User Logged Out', {
    description: 'user clicked on sign out',
    button_name: 'sign out',
  })
}

export const trackFAQViewed = (question) => {
  window.analytics.track('FAQ Viewed', {
    question: question,
  })
}

export const trackStatementDownload = ({ key, language }) => {
  window.analytics.track('Statement Downloaded', {
    key,
    language,
  })
}

export const trackFormUpload = (name, type) => {
  window.analytics.track('Form Uploaded', {
    name,
    type,
  })
}

export const trackUserOptedInForOnlineTool = (online_tool) => {
  window.analytics.track('User Opted in for Online Tool', {
    description:
      'user clicked on complete online button from common forms speed bump',
    button_name: 'complete online',
    online_tool,
  })
}

export const trackFormDownloaded = (form_name, category_name) => {
  window.analytics.track('Form Downloaded', {
    form_name,
    category_name,
  })
}

export const trackTooltipInfoViewed = () => {
  window.analytics.track('Tooltip Info Viewed', {
    activity: 'line of credit request',
    description: 'viewed why this amount',
    button_name: 'why this amount',
  })
}

export const trackUsedHelpBanner = (button_name) => {
  window.analytics.track('Used Help Banner', {
    activity: 'used help banner',
    description: 'clicked on help link in banner',
    button_name,
  })
}

export const trackNavigateToUploadPage = ({ fromNav = false }) => {
  window.analytics.track('Navigate to Upload Page', {
    activity: 'navigate to upload page',
    description: fromNav
      ? 'clicked upload a form or document menu item'
      : 'clicked go to upload button from my loan page',
    button_name: fromNav
      ? 'Upload a Form or Document'
      : 'Go To Upload',
  })
}

export const trackRepaymentButton = ({ fromNav = false }) => {
  window.analytics.track('Repayment Button clicked', {
    activity: 'navigate to repayment site',
    description: fromNav
      ? 'repayment button clicked from nav menu'
      : 'repayment button clicked from online tools carousel on my loan page',
    button_name: fromNav
      ? 'Nav Online Tools Menu'
      : 'My Loan Online Tools Carousel',
  })
}

export const trackButtonClick = ({
  button_name,
  page,
  link_to,
  uid,
}) => {
  window.analytics.track('Button Clicked', {
    button_name,
    page,
    link_to,
    uid,
  })
}

export const trackVideoPlayed = {
  WatchedVideo: (name, page) => {
    window.analytics.track('Video Played', {
      activity: 'watched video',
      description: `clicked play button on ${name} on ${page}}`,
      button_name: 'play',
    })
  }
}

export const analyticsReset = () => window.analytics.reset()

export const trackUserSignedIn = () => {
  window.analytics.track('User Signed In', {
    button_name: 'Sign In',
  })
}

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signOut') {
    analyticsReset()
  }
  if (data.payload.event === 'signIn') {
    setTimeout(trackUserSignedIn, 2000)
  }
})

const getAge = ({ birthDate }) => {
  const currentYear = new Date().getFullYear()
  const yearOfBirth = new Date(birthDate).getFullYear()
  return currentYear - yearOfBirth
}

const parsedUserAgent = new UAParser()

const { name: osName } = parsedUserAgent.getOS()

const {
  type: deviceType,
  model: deviceModel = osName,
  vendor: deviceVendor = osName,
} = parsedUserAgent.getDevice()

const getDevice = (deviceType) => {
  const isIpadOS = () => {
    return Boolean(
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform),
    )
  }

  if (!!deviceType) return deviceType
  return isIpadOS() ? 'tablet' : 'desktop'
}

const AuthedAnalytics = withLDConsumer()(({ userInfo, ldClient }) => {
  const context = useApiContext()
  const { data: loanData } = context.loan

  useEffect(() => {
    if(!userInfo.attributes)
      return;

    if (loanData) {
      const servicer = extractServicer(loanData)
      const [borrower] = extractBorrowerAndCoborrowers(
        loanData,
        userInfo.attributes['custom:person_id'],
      )

      const { paperlessIndicator } = extractLoan(loanData).disclosures

      const TrackableUserFields = {
        client_id: servicer.subservicerId,
        client_name: servicer.clientName,
        email: userInfo.attributes.email,
        first_name: userInfo.attributes.given_name,
        last_name: userInfo.attributes.family_name,
        two_fa_opt_out:
          userInfo.attributes['custom:phone_verif_opt_out'] || false,
        email_verified: userInfo.attributes.email_verified,
        age: getAge(borrower),
        deviceType: getDevice(deviceType),
        deviceVendor,
        deviceName: deviceModel,
        osName,
        paperless_opt_in: Boolean(paperlessIndicator === 'OPTIN'),
      }

      window.analytics.identify(
        userInfo.attributes.sub,
        TrackableUserFields,
      )

      ldClient.identify({
        key: userInfo.attributes.sub,
        custom: TrackableUserFields,
      })
    } else {
      window.analytics.identify(userInfo.attributes.sub, {
        email: userInfo.attributes.email,
        first_name: userInfo.attributes.given_name,
        last_name: userInfo.attributes.family_name,
        two_fa_opt_out:
          userInfo.attributes['custom:phone_verif_opt_out'] || false,
        email_verified: userInfo.attributes.email_verified,
      })
    }
  }, [userInfo, loanData, ldClient])

  return null
})

const Analytics = ({ userInfo, isAuthenticated }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.analytics.page(pathname)
  }, [pathname])

  return isAuthenticated && userInfo ? (
    <AuthedAnalytics userInfo={userInfo} />
  ) : null
}

export default Analytics