import React, { useEffect, useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useRetrieveLocEligibility } from '../Hooks/useRetrieveLocEligibility'
import { useRetrievePayOffEligibility } from '../Hooks/useRetrievePayOffEligibility'
import { checkIsUserSignedIn } from '../services/auth'
import CommonForms from '../Components/CommonForms/index'
import { useApiContext } from '../Hooks/useApiContext'
import { EQUITY_ELITE, HOMESAFE } from '../Components/AdvanceRequest';

const formGroupsInitialState = [
  {
    group: 'Account Maintenance',
    forms: [
      {
        name: 'Borrower Information for Servicing',
        description:
          '(Cell/Email Consent & Third Party Authorization)',
        url: './forms/2024/Borrower_Information_for_Servicing.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Change of Mailing Address',
        url: './forms/2024/Change_of_Mailing_Address.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Electronic Funds Transfer / Direct Deposit Agreement',
        url: './forms/2024/Electronic_Fund_Transfer_Direct_Deposit_Agreement.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Third Party Authorization',
        url: './forms/2024/Third_Party_Authorization.pdf',
        onlineToolEligible: false,
      },
    ],
  },
  {
    group: 'Line of Credit (LOC) Draws',
    forms: [
      {
        name: 'Line of Credit Draw Request',
        url: './forms/2024/Line_Of_Credit_Draw_Request.pdf',
        onlineToolEligible: false,
      },
    ],
  },
  {
    group: 'Loan Resolution',
    forms: [
      {
        name: 'Loan Resolution Checklist For Borrowers And Heirs/Letter of Intent',
        url: './forms/2024/Loan_Resolution_Checklist_For_Borrowers_And_Heirs.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Non Borrowing Spouse Information',
        url: './forms/2024/Non-Borrowing_Spouse_Information.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Deed-in-lieu of Foreclosure Request',
        url: './forms/2024/Deed-in-lieu_of_Foreclosure_Request.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'At-Risk Extension Request for Extension of Time Due to Critical Circumstances',
        url: './forms/2024/At_Risk_Extension_Request_for_Extension_of_Time_Due_to_Critical_Circumstances.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Short Sale Instruction Package',
        url: './forms/2024/Short_Sale_Instruction_Package.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Loss Mitigation Application (NY Only)',
        url: './forms/2024/Loss_Mitigation_Application_NY_Only.pdf',
        onlineToolEligible: false,
      }
    ],
  },
  {
    group: 'Occupancy Certification',
    forms: [
      {
        name: 'Annual Occupancy Certificate',
        description: '(or create an account or log in to your account to complete online)',
        url: './forms/2024/Annual_Occupancy_Certificate.pdf',
        onlineToolEligible: false,
      },
    ],
  },
  {
    group: 'Payment Plan Changes',
    forms: [
      {
        name: 'Payment Plan Change Request',
        url: './forms/2024/Payment_Plan_Change_Request.pdf',
        onlineToolEligible: false,
      },
    ],
  },
  {
    group: 'Partial Prepayments/Payments in Full',
    forms: [
      {
        name: 'Partial Prepayment Form',
        url: './forms/2024/Partial_Prepayment_Form.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Request for Reverse Mortgage Payoff Quote',
        url: './forms/2024/Request_for_Reverse_Mortgage_Payoff_Quote.pdf',
        onlineToolEligible: false,
      },
    ],
  },
  {
    group: 'Repairs Required at Loan Origination',
    forms: [
      {
        name: 'Homeowner’s Certification of Completed Repairs',
        url: './forms/2024/Homeowners_Certification_of_Completed_Repairs.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Repairs Affidavit and Lien Waiver',
        url: './forms/2024/Repairs_Affidavit_and_Lien_Waiver.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Repairs Extension Request',
        url: './forms/2024/Repairs_Extension_Request.pdf',
        onlineToolEligible: false,
      },
    ],
  },
  {
    group: 'Taxes & Insurance',
    forms: [
      {
        name: 'Repayment Plan Review Request',
        url: './forms/2024/Repayment_Plan_Review_Request.pdf',
        onlineToolEligible: false,
      },
      {
        name: 'Tax Payment Request',
        url: './forms/2024/Tax_Payment_Request.pdf',
        onlineToolEligible: false,
      },
    ],
  },
]

const CommonFormsContainer = () => {
  const [formGroups, setFormGroups] = useState(formGroupsInitialState)
  const [authed, setAuthed] = useState(false)

  const [redirectLOCAdvanceForm, setRedirectLOCAdvanceForm] = useState(false)
  const [redirectPayoffQuoteForm, setRedirectPayoffQuoteForm] = useState(false)
  const [redirectOccCertForm, setRedirectOccCertForm] = useState(false)

  const { data: eligibilityData } = useRetrieveLocEligibility()
  const { data: payoffData } = useRetrievePayOffEligibility()
  const apiContext = useApiContext()
  const { data: loanData } = apiContext.loan
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  // confirms user has logged in
  useEffect(() => {
    const runEffect = async () => {
      setAuthed(!!(await checkIsUserSignedIn()))
    }
    
    if (authStatus === 'authenticated')
      runEffect()
  }, [authStatus])

  // checks users LOC Advance eligibility
  useEffect(() => {
    if (authed && eligibilityData && loanData) {
      const { eligible, pending, maxRequestAmount, occCertDue } = eligibilityData.data.getLOCEligibility.info
      const loanType = loanData.data.loan.loanData.product.productName
      const hide = (loanType === HOMESAFE || loanType === EQUITY_ELITE) && maxRequestAmount < 500 
      
      setRedirectLOCAdvanceForm(maxRequestAmount && eligible && !pending && !hide)
      setRedirectOccCertForm(occCertDue)
    }
  }, [authed, eligibilityData, loanData])

  // checks users Payoff Quote eligibility
  useEffect(() => {
    if (authed && payoffData) {
      const { eligible } = payoffData
      setRedirectPayoffQuoteForm(eligible)
    }
  }, [authed, payoffData])

  // updates forms with information indicating user is LOC Advance eligible
  useEffect(() => {
    let formGroupsUpdated = formGroups

    if (redirectLOCAdvanceForm) {
      formGroupsUpdated = formGroupsUpdated.map(
        (formGroup) => {
          if (formGroup.group.includes('LOC')) {
            return {
              group: formGroup.group,
              forms: formGroup.forms.map((form) => {
                if (form.name.includes('Line of Credit Draw Request')) {
                  return {
                    ...form,
                    onlineToolEligible: {
                      url: '/advance-request',
                      toolName: 'Line of Credit',
                    },
                  }
                } else {
                  return form
                }
              }),
            }
          } else {
            return formGroup
          }
        },
      )

      setFormGroups(formGroupsUpdated)
      setRedirectLOCAdvanceForm(false)
    }

    if (redirectOccCertForm) {
      formGroupsUpdated = formGroupsUpdated.map(
        (formGroup) => {
          if (formGroup.group.includes('Occupancy Certification')) {
            return {
              group: formGroup.group,
              forms: formGroup.forms.map((form) => {
                if (form.name.includes('Annual Occupancy Certificate')) {
                  return {
                    ...form,
                    onlineToolEligible: {
                      url: '/eSign',
                      toolName: 'eSign',
                    },
                  }
                } else {
                  return form
                }
              }),
            }
          } else {
            return formGroup
          }
        },
      )

      setFormGroups(formGroupsUpdated)
      setRedirectOccCertForm(false)
    }

  }, [redirectLOCAdvanceForm, redirectOccCertForm, formGroups])

  // updates forms with information indicating user is Payoff Quote eligible
  useEffect(() => {
    if (redirectPayoffQuoteForm) {
      let formGroupsWithUpdatedPayOffQuote = formGroups.map(
        (formGroup) => {
          if (formGroup.group.includes('Partial Prepayments/Payments in Full')) {
            return {
              group: formGroup.group,
              forms: formGroup.forms.map((form) => {
                if (form.name.includes('Payoff Quote')) {
                  return {
                    ...form,
                    onlineToolEligible: {
                      url: '/payoff-quote',
                      toolName: 'Payoff Quote',
                    },
                  }
                } else {
                  return form
                }
              }),
            }
          } else {
            return formGroup
          }
        },
      )
      setFormGroups(formGroupsWithUpdatedPayOffQuote)
      setRedirectPayoffQuoteForm(false)
    }
  }, [redirectPayoffQuoteForm, formGroups])

  return <CommonForms formGroups={formGroups} />
}

export default CommonFormsContainer
