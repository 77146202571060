import React from 'react'
import styled from 'styled-components'

import Title from '../../Common/Title'
import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import InfoCard from '../../Common/InfoCard.js'
import NewUploadIcon from '../../assets/new-upload-icon'
import QuestionBubbleIcon from '../../assets/question-bubble-icon'
import PrivacyPolicyIcon from '../../assets/privacy-policy-icon'
import Statements from './Statements'
import GoPaperlessImg from '../../assets/go-paperless.png'
import { trackGoPaperless } from '../../Global/Analytics'
import { buildTestID } from '../../Utils/utils'
import { extractLoan } from '../../Utils/apiExtractors'
import { useApiContext } from '../../Hooks/useApiContext'

const StyledStatementsAndDocuments = styled.div`
  min-height: 80vh;

  .info_cards {
    margin: 0 0 0 12px;
    padding: 32px 0 0 0;

    .break_line {
      display: none;
    }

    .mt-32 {
      margin-top: 32px;
    }
  }

  .go_paperless {
    display: flex;
    justify-content: space-between;
    margin: 0 0 32px 0;
    padding-top: 12px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.adBg};

    .text_block {
      padding: 18px 18px 28px 18px;
      display: flex;
      flex-direction: column;

      h6 {
        margin: 0 0 8px 0;

        a {
          white-space: normal;
          display: block;
          line-height: inherit;
        }
      }

      .button {
        align-self: flex-start;
      }
    }

    img {
      align-self: flex-end;
      height: 140px;
    }
  }

  .cls-1{
    fill-rule:evenodd;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    .info_cards {
      margin: 32px 0 72px 0;
      padding: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .info_cards {
      margin: 0 0 72px 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .info_cards {
      .break_line {
        display: inherit;
        margin: 0 -32px 20px -32px;
      }
    }
  }
`

const StatementsAndDocuments = () => {
  const { loan: apiLoan } = useApiContext()
  const { data: loanData } = apiLoan
  const loanResponse = extractLoan(loanData)

  const enrolledInPaperless = Boolean(
    loanResponse &&
    loanResponse.disclosures.paperlessIndicator === 'OPTIN',
  )

  const clientName = loanResponse?.legalEntity?.legalEntity[0].servicer?.[0]?.clientName

  return (
    <>
      <Title>Statements And Documents</Title>
      <StyleGuide>
        <StyledStatementsAndDocuments>
          <Grid>
            <Statements
              loan={loanResponse}
              data-gridcolumnlayout={{
                xl: '3/9',
                lg: '2/8',
                md: '2/8',
                sm: '1/9',
              }}
            />
            <div
              className="info_cards"
              data-gridcolumnlayout={{
                xl: '9/13',
                lg: '8/12',
                md: '8/12',
                sm: '1/9',
              }}
            >
              {!enrolledInPaperless ? (
                <>
                  <div className="break_line" />
                  <div className="go_paperless">
                    <div className="text_block">
                      <h5 className="subtitle bold">Go Paperless!</h5>
                      <h6 className="body-2">
                        Receive documents online only.{' '}
                        <a
                          onClick={trackGoPaperless.Banner}
                          href="/account-settings#go-paperless"
                          data-testid={buildTestID(
                            'See Paperless Settings',
                            '/my-loan',
                            'go_paperless',
                          )}
                        >
                          See Paperless Settings
                        </a>
                      </h6>
                    </div>
                    <img
                      src={GoPaperlessImg}
                      className="paperless_img"
                      alt=""
                      role="presentation"
                    />
                  </div>
                </>
              ) : null}
              <InfoCard
                smallScreenStyles
                SVG={PrivacyPolicyIcon}
                paragraph="Annual Privacy Notice"
                buttonProps={{
                  text: 'Visit Annual Privacy Notice',
                  linkTo: `/annual-privacy-notice`,
                }}
              />
              <InfoCard
                className="mt-32"
                smallScreenStyles
                SVG={NewUploadIcon}
                paragraph="Upload your documents or files safely
                and securely using our online Upload tool."
                buttonProps={{
                  text: 'Visit Upload Page',
                  linkTo: '/upload',
                }}
              />
              <InfoCard
                className="mt-32"
                smallScreenStyles
                SVG={QuestionBubbleIcon}
                paragraph="Have questions? Visit our Common
                Questions page for help."
                buttonProps={{
                  text: 'Visit Common Questions',
                  linkTo: '/common-questions',
                }}
              />
            </div>
          </Grid>
        </StyledStatementsAndDocuments>
      </StyleGuide>
    </>
  )
}

export default StatementsAndDocuments
