import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const NewUploadIcon = ({ className }) => {
  return (
    <StyledSVG className={className} fill="none" viewBox="0 0 52 56">
      <path
        d="M19.0737 54.9063H32.4948L30.7921 46.4519H20.9238L19.0737 54.9063Z"
        fill="#5C90AE"
      />
      <path
        d="M1.62305 41.1926V43.1393C1.62305 44.969 3.10628 46.4522 4.93602 46.4522H47.065C48.8947 46.4522 50.3779 44.969 50.3779 43.1393V41.1926H1.62305Z"
        fill="#EAF0F0"
      />
      <path
        d="M50.3776 41.1928V11.2175C50.3776 10.7603 50.2846 10.325 50.1172 9.92798C49.6076 8.72376 48.3224 7.8083 47.0646 7.68689H38.1494V34.2913H13.8507V7.68689H4.93553C3.12975 7.8258 1.62256 9.35923 1.62256 11.2175V41.1928"
        fill="#5C90AE"
      />
      <path
        d="M45.5445 41.1928V12.5223H38.1496V34.2912H13.8508V12.5223H6.45605V41.1928"
        fill="#8CC8FF"
      />
      <path
        d="M13.8506 7.68687L20.6068 1.09375V7.68687H13.8506Z"
        fill="#EAF0F0"
      />
      <path
        d="M20.6068 1.09375V7.68687H13.8506V34.2917H38.1501V1.09375H20.6068Z"
        fill="#EAF0F0"
      />
      <path
        d="M51.4714 11.2175C51.4714 8.96087 49.4228 6.59312 47.0647 6.59312H39.2436V1.09375C39.2436 0.489672 38.7538 0 38.1499 0H20.6066C20.3247 0 20.0443 0.113969 19.8427 0.310953L13.4051 6.59312H4.93553C4.90753 6.59312 4.87953 6.59422 4.85164 6.5963C2.42756 6.78278 0.528809 8.81267 0.528809 11.2175L0.529246 43.1391C0.529246 45.569 2.50598 47.5459 4.93585 47.5459H19.5645L18.1932 53.8125H14.894C14.29 53.8125 13.8003 54.3022 13.8003 54.9062C13.8003 55.5103 14.29 56 14.894 56H36.674C37.2779 56 37.7677 55.5103 37.7677 54.9062C37.7677 54.3022 37.2779 53.8125 36.674 53.8125H33.3899L32.1278 47.5459H47.0647C49.4946 47.5459 51.4714 45.569 51.4714 43.1391V11.2175ZM47.0063 8.78062C48.1974 8.92347 49.2839 9.99414 49.2839 11.2175V40.0988H46.6381V12.5223C46.6381 11.9183 46.1484 11.4286 45.5444 11.4286H39.2437V8.78062H47.0063ZM37.0558 33.1975H14.9445V8.78062H20.6066C21.2105 8.78062 21.7003 8.29095 21.7003 7.68687V2.1875H37.0561L37.0558 33.1975ZM19.5128 3.68933V6.59312H16.5373L19.5128 3.68933ZM4.98157 8.78062H12.7566V11.4286H6.45595C5.85198 11.4286 5.3622 11.9183 5.3622 12.5223V40.0988H2.71642V11.2175C2.71631 9.98211 3.72901 8.89689 4.98157 8.78062ZM31.1585 53.8125H20.4324L21.8039 47.5459H29.8965L31.1585 53.8125ZM47.0648 45.3584H4.93585C3.71217 45.3584 2.71675 44.3628 2.71675 43.1391V42.2866H21.2639C21.8679 42.2866 22.3577 41.7969 22.3577 41.1928C22.3577 41.0418 22.327 40.8978 22.2717 40.767C22.1056 40.3744 21.7169 40.0988 21.2638 40.0988H7.5497V13.6161H12.7566V34.2917C12.7566 34.8958 13.2464 35.3854 13.8504 35.3854H38.1499C38.7538 35.3854 39.2436 34.8958 39.2436 34.2917V13.6161H44.4505V40.0988H30.8861C30.2821 40.0988 29.7923 40.5885 29.7923 41.1926V41.1927V41.1928C29.7923 41.7969 30.2821 42.2866 30.8861 42.2866H49.284V43.1391C49.284 44.3628 48.2884 45.3584 47.0648 45.3584Z"
        fill="black"
      />
      <path
        d="M30.2964 25.1938H21.7041C21.1001 25.1938 20.6104 25.6835 20.6104 26.2875C20.6104 26.8916 21.1001 27.3813 21.7041 27.3813H30.2964C30.9003 27.3813 31.3901 26.8916 31.3901 26.2875C31.3901 25.6835 30.9005 25.1938 30.2964 25.1938Z"
        fill="black"
      />
      <path
        d="M25.8308 10.872C25.6253 10.9123 25.4301 11.0149 25.2799 11.1611L21.2375 15.0963C20.8047 15.5177 20.7954 16.2101 21.2168 16.6429C21.6381 17.0757 22.3307 17.085 22.7635 16.6637L24.9491 14.5359V22.0235C24.9491 22.6276 25.4389 23.1173 26.0429 23.1173C26.6469 23.1173 27.1367 22.6276 27.1367 22.0235V14.5359L29.3223 16.6637C29.5349 16.8707 29.8102 16.9737 30.0852 16.9737C30.37 16.9737 30.6546 16.8631 30.869 16.6429C31.2904 16.21 31.2811 15.5176 30.8483 15.0963L26.8059 11.1611C26.551 10.9131 26.1794 10.8033 25.8308 10.872Z"
        fill="black"
      />
      <path
        d="M27.1071 40.775C26.9302 40.3436 26.4804 40.0683 26.0164 40.103C25.5399 40.1385 25.1362 40.488 25.0305 40.9535C24.8135 41.9081 25.9257 42.6475 26.7263 42.0864C27.1398 41.7967 27.3013 41.2412 27.1071 40.775Z"
        fill="black"
      />
    </StyledSVG>
  )
}

export default NewUploadIcon
