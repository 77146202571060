import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .phone {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }

  .num-pad {
    fill: ${({ theme }) => theme.colors.primary};
  }
`

const FaxIcon = () => {
  return (
    <StyledSVG
      aria-hidden="true"
      role="img"
      width="103"
      height="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          className="phone"
          d="M7.58322 6.73466h-2.1834c-2.9808 0-5.4 2.33258-5.4 5.21053v53.33147c0 2.87621 2.4192 5.21053 5.4 5.21053h2.1834V6.73466zM90.51915 21.35192H60.0898c-1.43018 0-2.5909-1.16716-2.5909-2.60526s1.16072-2.60526 2.5909-2.60526h30.42936c1.43019 0 2.59091 1.16715 2.59091 2.60526 0 1.4381-1.16072 2.60526-2.5909 2.60526M89.27724 35.6114c-2.11073 0-3.82419-1.72121-3.82419-3.84364 0-2.12415 1.71346-3.84536 3.82419-3.84536 2.11072 0 3.82245 1.7212 3.82245 3.84536 0 2.12243-1.71173 3.84364-3.82245 3.84364m0 12.9742c-2.11073 0-3.82419-1.72294-3.82419-3.84536s1.71346-3.84363 3.82419-3.84363c2.11072 0 3.82245 1.7212 3.82245 3.84363 0 2.12242-1.71173 3.84537-3.82245 3.84537m0 12.97247c-2.11073 0-3.82419-1.72121-3.82419-3.84363s1.71346-3.84363 3.82419-3.84363c2.11072 0 3.82245 1.7212 3.82245 3.84363 0 2.12242-1.71173 3.84363-3.82245 3.84363M75.3036 35.6114c-2.11073 0-3.82246-1.72121-3.82246-3.84364 0-2.12415 1.71173-3.84536 3.82246-3.84536s3.82245 1.7212 3.82245 3.84536c0 2.12243-1.71172 3.84364-3.82245 3.84364m0 12.9742c-2.11073 0-3.82246-1.72294-3.82246-3.84536s1.71173-3.84363 3.82246-3.84363 3.82245 1.7212 3.82245 3.84363c0 2.12242-1.71172 3.84537-3.82245 3.84537m0 12.97247c-2.11073 0-3.82246-1.72121-3.82246-3.84363s1.71173-3.84363 3.82246-3.84363 3.82245 1.7212 3.82245 3.84363c0 2.12242-1.71172 3.84363-3.82245 3.84363M61.3317 35.6114c-2.11245 0-3.82418-1.72121-3.82418-3.84364 0-2.12415 1.71173-3.84536 3.82418-3.84536 2.109 0 3.82073 1.7212 3.82073 3.84536 0 2.12243-1.71173 3.84364-3.82073 3.84364m0 12.9742c-2.11245 0-3.82418-1.72294-3.82418-3.84536s1.71173-3.84363 3.82418-3.84363c2.109 0 3.82073 1.7212 3.82073 3.84363 0 2.12242-1.71173 3.84537-3.82073 3.84537m0 12.97247c-2.11245 0-3.82418-1.72121-3.82418-3.84363s1.71173-3.84363 3.82418-3.84363c2.109 0 3.82073 1.7212 3.82073 3.84363 0 2.12242-1.71173 3.84363-3.82073 3.84363M97.09315 6.73466H48.68806v63.75253h48.4051c2.86209 0 5.18181-2.33432 5.18181-5.21053V11.94519c0-2.87795-2.31972-5.21053-5.18182-5.21053"
          fill="#0E4377"
        />
        <path
          className="num-pad"
          d="M33.32592 72.39925h-11.319c-1.449 0-2.625-1.1687-2.625-2.6087 0-1.44 1.176-2.6087 2.625-2.6087h11.319c1.449 0 2.625 1.1687 2.625 2.6087 0 1.44-1.176 2.6087-2.625 2.6087m6.384-72.1113h-24.087c-2.89975 0-5.25 2.33564-5.25 5.21738v68.5113c0 2.88 2.35025 5.2174 5.25 5.2174h24.087c2.9015 0 5.25-2.3374 5.25-5.2174V5.50534c0-2.88174-2.3485-5.21739-5.25-5.21739"
          fill="#1460AA"
        />
      </g>
    </StyledSVG>
  )
}

export default FaxIcon
