import React, { useEffect, useState } from 'react'

import FinishYourAccount from '../Components/FinishYourAccount'
import Title from '../Common/Title'
import {
  extractLoan,
  extractBorrowerAndCoborrowers,
} from '../Utils/apiExtractors'
import { useApiContext } from '../Hooks/useApiContext'
import LoadingPage from '../Components/LoadingPage'
import { useLoggedInUser } from '../Hooks/useLoggedInUser'
import { Auth } from 'aws-amplify'
import { storeCognitoId } from '../services/api'

const FinishYourAccountContainer = ({ setAuthState, setSkip }) => {
  const { loan } = useApiContext()
  const {
    error: loanError,
    loading: loanLoading,
    data: loanData,
    refresh: refreshLoanData,
  } = loan

  const {
    error: userError,
    loading: userLoading,
    data: userData,
  } = useLoggedInUser()

  const [mfaEnabled, setMfaEnabled] = useState()

  useEffect(() => {
    storeCognitoId()
  }, [])

  useEffect(() => {
    const getMfa = async () => {
      try {
        // Will retrieve the current mfa type from cache
        // bypassCache is optional, by default is false.
        // If set to true, it will get the MFA type from server side instead of from local cache.
        const user = await Auth.currentAuthenticatedUser();
        const data = await Auth.getPreferredMFA(user, { bypassCache: false });
        setMfaEnabled(data === 'NOMFA' ? false : true);
      } catch (err) {
        console.log(err)
      }
    }

    getMfa()
  }, [])

  if (loanLoading || !loanData || userLoading || !userData)
    return <LoadingPage />

  if (loanError || userError) return <div>{loanError.message}</div>

  const { paperlessIndicator, paperlessChangeDate } =
    extractLoan(loanData).disclosures
  const [borrowerResponse, coborrowersResponse] =
    extractBorrowerAndCoborrowers(
      loanData,
      userData.attributes['custom:person_id']
    )

  const borrower = {
    fullName: `${borrowerResponse.firstName} ${borrowerResponse.lastName}`,
    birthDate: borrowerResponse.birthDate,
    houseNumberStreeName: borrowerResponse.address[0].address1,
    cityStateZip: `${borrowerResponse.address[0].city}, ${borrowerResponse.address[0].state} ${borrowerResponse.address[0].postalCode}`,
    phoneNumber: borrowerResponse.phone.find(
      ({ primaryIndicator }) => primaryIndicator === true,
    )?.phoneNumber,
    coborrowers: coborrowersResponse.map(
      (person) => person.firstName + ' ' + person.lastName,
    ),
    email: userData.attributes["email"],
  }

  return (
    <>
      <Title>Finish Your Account</Title>
      <FinishYourAccount
        paperlessIndicator={Boolean(paperlessIndicator === 'OPTIN')}
        paperlessChangeDate={paperlessChangeDate}
        refreshLoanData={refreshLoanData}
        borrower={borrower}
        mfaEnabled={mfaEnabled}
        setAuthState={setAuthState}
        setSkip={setSkip}
      />
    </>
  )
}

export default FinishYourAccountContainer
