import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { buildTestID } from '../../Utils/utils'
import { trackGoPaperless } from '../../Global/Analytics'
import GoPaperlessImg from '../../assets/go-paperless.png'

const StyledWelcomeSection = styled.div`
  padding: 32px;
  margin: 32px 0 0 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};

  h1.heading-6:focus, h1.heading-6:focus-visible {
    outline: none;
  }

  .heading-6 {
    display:inline-block;
    margin: 0 0 24px 0;
  }

  .info_section {
    display: flex;
    justify-content: space-between;

    .info_group {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-right: 24px;

      .body-1 {
        margin: 0;
        text-align: start;
      }

      .body-2,
      .caption {
        margin: 4px 0 0 0;
      }

      .success_text {
        color: ${({ theme }) => theme.colors.success};
      }
    }
  }

  .go_paperless {
    max-width: 352px;
    border-radius: 4px;
    margin-top: -54px;
    background-color: ${({ theme }) => theme.colors.adBg};
    display: flex;

    .text_block {
      padding: 18px 0 28px 18px;
      display: flex;
      flex-direction: column;

      h6 {
        margin: 0 0 8px 0;
      }

      a {
        white-space: nowrap;
        overflow: hidden;
      }

      .button {
        align-self: flex-start;
      }
    }

    img {
      align-self: flex-end;
      height: 140px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .go_paperless {
      .text_block {
        a {
          white-space: unset;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    padding: 0;
    margin: 32px 0 0 0;
    box-shadow: none;

    .heading-6 {
      margin: 0;
    }

    .info_section {
      flex-direction: column;

      .info_group,
      .go_paperless {
        margin: 20px 0 0 0;

        .text_block {
          a {
            white-space: normal;
            display: block;
            line-height: inherit;
          }
        }
      }
    }
  }
`

const WelcomeSection = ({ loan, borrower }) => {
  const { accountNumber, loanType, enrolledInPaperless } = loan
  const { houseNumberStreeName, cityStateZip, userName } = borrower
  const mainRef = useRef(null);

  useEffect(() => {
    if (mainRef)
      mainRef.current.focus();
  }, [mainRef]);

  return (
    <StyledWelcomeSection>
      <h1 className="heading-6" tabIndex={-1} ref={mainRef}>{`Welcome, ${userName}.`}</h1>
      <div className="info_section">
        <div className="info_group">
          <h2 className="body-1 bold">Account Number:</h2>
          <p className="body-2">{accountNumber}</p>
          <p className="caption">{`Loan Type: ${loanType}`}</p>
        </div>
        <div className="info_group">
          <h2 className="body-1 bold">Property Address:</h2>
          <p className="body-2">{houseNumberStreeName}</p>
          <p className="body-2">{cityStateZip}</p>
        </div>
        {enrolledInPaperless ? (
          <div className="info_group">
            <h2 className="body-1 bold">Paperless Delivery:</h2>
            <p className="body-2 success_text">Enrolled</p>
          </div>
        ) : (
          <div className="go_paperless">
            <div className="text_block">
              <h5 className="subtitle bold">Go Paperless!</h5>
              <h6 className="body-2">
                Receive documents online only. <br />
                <a
                  onClick={() =>
                    trackGoPaperless.Banner({ fromMyLoanPage: true })
                  }
                  href="/account-settings#go-paperless"
                  data-testid={buildTestID(
                    'See Paperless Settings',
                    '/my-loan',
                    'go_paperless',
                  )}
                >
                  See Paperless Settings
                </a>
              </h6>
            </div>
            <img
              src={GoPaperlessImg}
              className="paperless_img"
              alt=""
              role="presentation"
            />
          </div>
        )}
      </div>
    </StyledWelcomeSection>
  )
}

WelcomeSection.propTypes = {
  loan: PropTypes.object.isRequired,
  borrower: PropTypes.object.isRequired,
}

export default WelcomeSection
