import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'

import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'
import useWindowSize from '../../Hooks/useWindowSize'
import dayjs from 'dayjs'
import { SIGNATURE_COMPLETE } from '.'
import { submitESignAgreement } from '../../services/api'
import { trackESignConsent } from '../../Global/Analytics'
import Countdown from 'react-countdown'

const StyledVerifyMfa = styled.div`
h1, h3 {
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  margin-top: 16px;
  line-height: 30px;
  letter-spacing: 0.15px;
}

h5 {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 20px 0 24px;
}

.invalid {
  color: #dc3545
}

input.form-control {
  height: 56px;
}

label {
  font-size: 18px;
line-height: 22px;
letter-spacing: 0.1px;
}

.form-control.is-invalid {
  background-image: none;
}

.row {
  flex-direction: column;
}

.mb-40 {
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin: 24px 0 32px 0;
  justify-content: space-between;

  .cancel {
    margin-right: 24px;
  }

  .button {
    min-width: 153px;
  }
}

.warning {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.resend-buttons {
  display: flex;
 justify-content: center;
}

.what_to_do { 
  display: flex;
}

.what_to_do_text {
  margin-left: 5px;
}

@media (min-width: ${({ theme }) => theme.breakPoints.small}) {
  .verify-mfa {
    background-color: #fff;
    padding: 15px;
    margin-top: 32px;
    border-radius: 4px;
    z-index: 150;
    position: relative;
    box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
  }

  h1, h3, h5 {
    text-align: center;
  }

  .row {
    flex-direction: row;
    margin: 0;
  }

  .col {
    padding: 0 10px;
  }


  .button-verify-mfa {
    width: 235px;
  }

  .warning {
    padding: 24px;
  }
}

@media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .row {
      margin: 0 88px;
    }
 }
`

const VerifyMfa = ({ borrower, optInTimestamp, consentTimestamp, thirdPartyTimestamp, thirdParty, nbsInformation, setView, setModalShow, setCertificate, authData, challengeDestination, ssn, dob, mainRef }) => {
  const [loading, setLoading] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const { isSmall } = useWindowSize()
  const [enteredCode, setEnteredCode] = useState('')
  const [showError, setShowError] = useState(false)
  const [, setIsVerifying] = useState(false)
  const [codeSentVisible, setCodeSentVisible] = useState(false)
  const [canResendCode, setCanResendCode] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const [resendAttempts, setResendAttempts] = useState(0)
  const [useVerify, setUseVerify] = useState(false)

  const setCodeSent = () => {
    setCanResendCode(false)
    setCodeSentVisible(true)
    setTimeout(() => setCodeSentVisible(false), 5000)
  }

  const verifyCode = async () => {
    try {
      const attempt = attempts + 1
      setAttempts(attempt)
      setIsVerifying(true)
      setLoading(true)

      try {
        let response = null;
        console.log(authData)
        if (!useVerify) {
          response = await Auth.confirmSignIn(
            authData,
            enteredCode,
            'SMS_MFA',
          )
        } else {
          response = await Auth.verifyCurrentUserAttributeSubmit(
            'phone_number',
            enteredCode,
          )
        }
        console.log('verified phone response', response)

        const eSignResult = await submitESignAgreement(borrower, optInTimestamp, consentTimestamp, thirdPartyTimestamp, Date.now(), dayjs(dob).format('YYYY-MM-DD'), ssn, thirdParty, nbsInformation)
        console.log('eSignResult', eSignResult)
        
        let paths = ['data', 'submitESignature', 'responseData', 'responseCode']
        const responseCode = paths.reduce((object, path) => {
          return (object || {})[path]; // Oliver Steele's pattern
        }, eSignResult)

        if (responseCode === 200) {
          paths = ['data', 'submitESignature', 'link']
          const certificate = paths.reduce((object, path) => {
            return (object || {})[path]; // Oliver Steele's pattern
          }, eSignResult)

          trackESignConsent.Completed('occupancy certification signed and submitted', 'signature_button_submit')
          setCertificate(certificate)
          setView(SIGNATURE_COMPLETE)
        }
        else if (attempts > 4) {
          Auth.signOut()
        }
        else {
          setLoading(false)
          setInvalid(true)
        }
      } catch (err) {
        console.log('an error', err)
        if (attempts > 4)
          Auth.signOut()

        setShowError(true)
        setIsVerifying(false)
        setLoading(false)
      }
    } catch (e) {
      console.log('an error', e)
      setIsVerifying(false)
      setLoading(false)
      setShowError(true)
    }
  }

  const handleClick = async (e) => {
    e.preventDefault()
    const attempt = resendAttempts + 1
    setResendAttempts(attempt)
    setUseVerify(true)

    try {
      // To initiate the process of verifying the attribute like 'phone_number' or 'email'
      Auth.verifyCurrentUserAttribute('phone_number')
        .then(() => {
          console.log('a verification code is sent');
          setCodeSent()
        })
        .catch((e) => {
          console.log('failed with error', e);
          setCodeSent()
        });
    } catch (err) {
      setShowError(true)
    }
  }

  useEffect(() => {
    if (invalid)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
  }, [invalid])

  return (
    <StyledVerifyMfa>
      <div className="verify-mfa"
        data-gridcolumnlayout={{
          xl: '3/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Annual Occupancy Certification</h1>
        <h5><b>Enter your verification code</b>:</h5>
        {
          invalid && (
            <h5 className='invalid'>Please verify information is correct and resubmit.</h5>
          )
        }
        <Form>
          <Row>
            <Col>
              {showError ? (
                <p className="error_text" role="alert">
                  Incorrect code. Please check to see that the code is correctly entered and try again.  If multiple codes were requested, please make sure you are entering the most recent one sent to you. You have {(5 - attempts)} attempts remaining.
                </p>
              ) : (
                ''
              )}
              <Form.Group className="mb-40" controlId="verify-code">
                <div className="verify_content">
                  <div className="input_group">
                    <Form.Label>We sent your code to {challengeDestination}. Please enter the code below.</Form.Label>
                    <Form.Control type="text"
                      data-testid={buildTestID(
                        'verify_code',
                        'enable_mfa',
                        'enter_verify_code',
                      )}
                      // ref={inputRef}
                      pattern="\d*"
                      className={showError ? 'input_error' : ''}
                      name="verify-code"
                      min="111111"
                      max="999999"
                      value={enteredCode}
                      // onWheel={() => inputRef.current.blur()}
                      // onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        setShowError(false)
                        if (e.target.value.length <= 6) {
                          setEnteredCode(e.target.value.replace(/\D/g, ''))
                        }
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="resend-buttons">
          {codeSentVisible ? (
            <>
              <p className="error_text resend_code_text" role="alert">
                Code Resent
              </p>
            </>
          ) : (
            <div className="what_to_do">
              {canResendCode ? (
                <>
                  <Button
                    onClick={handleClick}
                    text="Re-Send Code"
                    textButton
                    UID="enter_verify_code"
                  />
                </>
              ) : resendAttempts < 5 ? (
                <>
                  <span className="what_to_do_text">Wait </span>
                  <span className="what_to_do_text">
                    <Countdown
                      date={Date.now() + 20000}
                      onComplete={() => setCanResendCode(true)}
                      intervalDelay={1000}
                      precision={2}
                      renderer={(props) => <span>{props.seconds}</span>}
                    />{' '}
                  </span>
                  <span className="what_to_do_text">Seconds</span>
                </>
              ) : null}
              {resendAttempts < 5 ? (
                <span className="what_to_do_text">I Didn't Receive Code?</span>)
                :
                (
                  <Button
                    // onClick={() => setView(DIDNT_RECEIVE_CODE_ERROR)}
                    text="I Didn't Receive Code?"
                    textButton
                    UID="i_didn't_receive_code"
                  />
                )
              }
            </div>
          )}
        </div>
        <hr />
        <div className="buttons">
          <Button
            className="button cancel"
            text="Cancel"
            outlined
            fullWidth
            UID="verify-mfa_button_cancel"
            onClick={() => setModalShow(true)}
          />
          <Button
            className="button button-verify-mfa"
            text={isSmall ? "Verify" : "Verify Code"}
            type="submit"
            fullWidth
            UID="verify-mfa_button_submit"
            disabled={!(enteredCode)}
            loading={loading}
            onClick={verifyCode}
          />
        </div>
      </div>
    </StyledVerifyMfa>
  )
}

export default VerifyMfa