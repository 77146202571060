import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'
import { buildTestID } from '../../Utils/utils'

const StyledUsefulLinks = styled.div`
  .sub-heading {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.black60};
  }

  img {
    margin-bottom: 12px;
  }

  .card-body {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.black12};
  }
`

const WhatDoIDoWhenMyLoanIsDue = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.WhatDoIDoWhenMyLoanIsDue, title)
  })

  return (
    <StyledUsefulLinks>
      <h3 ref={title} id="what-do-i-do-when-my-loan-is-due" tabIndex="-1">
        What Do I Do When My Loan is Due?
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              What is a Maturity Event?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <strong>A maturity event happens when one of the following occurs:</strong>
              <ul>
                <li>
                  <b>Death</b>: The last remaining borrower (or eligible non-borrowing spouse (“NBS”), if applicable, passes away
                </li>
                <li>
                  <b>Occupancy</b>: All borrowers (or NBS, if applicable) move out of the property, and it is no longer the primary residence of at least one borrower or NBS
                </li>
                <li>
                  <b>Property Charge Delinquency</b>: Borrower or NBS fails to pay applicable property charges (taxes, insurance, HOA, ground rents, etc…)
                </li>
                <li>
                  <b>Property Condition</b>: Borrower or NBS fails to maintain property and it falls into serious disrepair or condemnation
                </li>
                <li>
                  <b>Title Transfer</b>: No living borrower remains on title to the property – at least one living borrower must remain on title.  An NBS may be on title but is not required to be.  Title may be transferred to trust or life estate but those documents should be approved by your servicer first.
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              What Happens when a Maturity Event occurs and my Loan is Called Due and Payable?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <p>When a loan is called due and payable, we will send a demand letter to the property and mailing address on file, and to the person legally authorized to act on behalf of your Estate, if one has been identified.</p>
              <p>This letter outlines the amount of the debt owed on the Reverse Mortgage and the options available and requirements to resolve the debt. You or your heirs will have 30 days to respond to this letter indicating which option is preferred. That response will need to include certain documentation (Letter of Intent, Death Certificate, Will, Trust, Probate, Letters of Authority, etc.). Providing these documents in a timely manner may qualify you or your heirs for time extensions to resolve the debt. Documents can be uploaded to us on this website.</p>
              <p>Depending on the option chosen, additional extensions of time may be available if consistent and timely updates and documentation are provided supporting efforts to sell the home or pay off the loan. You or your heirs will receive periodic correspondence and phone calls to help determine the status and progress of those efforts.</p>
              <p>We understand that this can be a challenging time however it is in your and your heirs’ best interest to provide updates to us regularly as we want to help resolve the debt as easily and quickly as possible. We also encourage you to contact a housing counselor free of charge at (800) 569-4287 to discuss your options. Additionally, see the <a href="/helpful-resources" target="_blank" data-testid={buildTestID('maturity_event', '/common_questions', 'helpful_resources')}>Helpful Resources</a> page on this website.</p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              What Options are Available to Me or My Heirs When my Loan is Due and Payable?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <p>Depending on the maturity event, or reason for your loan being due and payable, you may be able to resolve the issue through steps that would return your loan to an active status as follows:</p>
              <br />
              <strong>Occupancy</strong>
              <ul>
                <li>At least one borrower moves back into property and certifies occupancy (either online or via the form available under “<a href="/common-forms" target="_blank" data-testid={buildTestID('what_do_i_do_1', '/common_questions', 'common_forms')}>Common Forms</a>{' '}” on this website)</li>
              </ul>
              <br />
              <strong>Property Charge Delinquency</strong>
              <ul>
                <li><b>Repayment Plan</b> (see form titled “Repayment Plan Review Request” under <a href="/common-forms" target="_blank" data-testid={buildTestID('what_do_i_do_2', '/common_questions', 'common_forms')}>Common Forms</a>{' '} on this website)</li>
                <li><b>At Risk Extension</b> (see form titled “Request for Extension of Time Due to Critical Circumstances”) under <a href="/common-forms" target="_blank" data-testid={buildTestID('what_do_i_do_3', '/common_questions', 'common_forms')}>Common Forms</a>{' '} on this website)</li>
                <li><b>Obtain Financial Assistance</b> Contact a housing counselor free of charge at (800) 569-4287 to learn what programs might be available to you. Additionally, see the “Helpful Resources” page on this website.</li>
              </ul>
              <br />
              <strong>Property Condition</strong>
              <ul>
                <li>Repair property</li>
              </ul>
              <br />
              <strong>Title Transfer</strong>
              <ul>
                <li>Return at least one borrower to title (if transferring title to a trust, contact your servicer ahead of time for approval)</li>
              </ul>
              <br />
              <strong>Death</strong>&nbsp;(of last remaining borrower or NBS, if applicable)
              <ul>
                <li><b>Payoff</b>: Pay loan balance in full (with personal funds, a different loan, life insurance proceeds, etc.)</li>
                <li><b>Short Sale</b>: Sell the property and paying the loan off through the sale at less than the outstanding balance on the loan. A short sale is subject to the terms of the loan and prior approval from the investor or owner of the loan.</li>
                <li><b>Deed-in-Lieu of Foreclosure</b>: Sign the property over to the investor (subject to investor approval).  Title must be clear of any liens or encumbrances, the property must be clear of all personal belongings and, if all borrowers are deceased, an authorized representative for the Estate must be appointed to convey title.</li>
                <li><b>Foreclosure Sale</b>: Allow the property to be foreclosed upon.</li>
              </ul>
              <p><strong>We are here to assist you and your heirs in understanding these options, however time is of the essence as your servicer has an obligation to refer the property for foreclosure action in a timely manner.</strong></p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              What Documentation is Required to Obtain an Extension of Time to Resolve the Loan Debt?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <p>Depending on you or your heirs’ intent, additional documentation may be required for us to secure an extension of time for you to resolve the debt. We will apply for an extension on your behalf, but approval of the extension is required. Documents can be uploaded to us on this website.</p>
              <br />
              <p>These documents may include:</p>
              <ul>
                <li>Proof of ongoing efforts to pay off the loan or sell the property (such as a listing agreement, sales contract, evidence the home is listed for sale by owner, proof of funds sufficient to pay off the loan in full.  <em>Important: a loan <u>pre-approval</u> is not acceptable – we must have a <u>final approval or loan commitment</u> if new loan funds are being obtained to pay off the debt</em>.</li>
                <li>A completed Short Sale Packet. This packet can be found on our <a href="/common-forms" target="_blank" data-testid={buildTestID('what_do_i_do_4', '/common_questions', 'common_forms')}>Common Forms</a>{' '} page on this website.</li>
                <li>A completed Deed-In-Lieu of Foreclosure Request Form, found on our <a href="/common-forms" target="_blank" data-testid={buildTestID('what_do_i_do_5', '/common_questions', 'common_forms')}>Common Forms</a>{' '} page on this website.</li>
                <li>A completed Request for Extension of Time Due to Critical Circumstances form, found on our <a href="/common-forms" target="_blank" data-testid={buildTestID('what_do_i_do_6', '/common_questions', 'common_forms')}>Common Forms</a>{' '} page on this website.</li>
                <li>Proof of critical circumstance</li>
              </ul>
             </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="5">
            My Property is in New York, what Options are Available?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <p>For properties in New York a full loss mitigation application is required, and available options are described in that application.  Please complete our New York Loss Mitigation Application, located on our <a href="/common-forms" target="_blank" data-testid={buildTestID('what_do_i_do_7', '/common_questions', 'common_forms')}>Common Forms</a>{' '} page on this website and can be uploaded there.</p>
             </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
            Can my spouse continue living in the home after I pass away if they were not on the Reverse Mortgage?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              <p>For HECM loans with FHA case numbers assigned prior to August 4, 2014, where the borrower has an Eligible Surviving Non-Borrowing Spouse, the mortgage lender may elect to assign the loan to FHA/HUD, thereby deferring the immediate obligation to pay off the loan after the last HECM borrower dies if, among other requirements, the following specific conditions are met and continue to be met throughout the duration of the deferral period:</p>
              <ol>
                <li>The HECM is not in default (or eligible to be called due and payable) for any reason other than the last borrower’s death (e.g., failure to pay property taxes or make hazard insurance payments);<br/>AND</li>
                <li>The borrower and his or her spouse were either:
                  <ol type="a">
                    <li>Legally married at the time the HECM closed and remained married until the HECM borrower’s death; OR</li>
                    <li>Engaged in a committed relationship akin to marriage but were legally prohibited from marrying before the closing of the HECM, because of the gender of the borrower and Non-Borrowing Spouse, if the spouses legally married before the death of the borrower and remained married until the death of the borrowing spouse;<br/>AND</li>
                  </ol>
                </li>
                <li>The Non-Borrowing Spouse lived in the property at loan closing and continues to live in the property as his or her Principal Residence.</li>
              </ol>
             </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </StyledUsefulLinks>
  )
}

export default WhatDoIDoWhenMyLoanIsDue
