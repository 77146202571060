import React, { useState } from "react"
import styled from "styled-components"
import Button from '../../Common/button'
import { VERIFY_PHONE_NUMBER_CODE } from "."
import { Auth } from "aws-amplify"
import { getRandomLetters } from "../../services/auth"

const StyledVerifyPhoneNumber = styled.div`
h1, h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }
  
  p {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 20px;
    // background: #fafafa;
  }

  div.col:nth-child(even) {
    text-align: center;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-around;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .verify-phone-number {
      background-color: #fff;
      padding: 15px;
      margin: 32px auto 0;
      width: 600px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      // text-align: center;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // padding: 24px;
      // background: #FAFAFA;
      font-size: 16px;
      // font-weight: 700;
      // margin: 25px 35px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {

 }
  `

const VerifyPhoneNumber = ({ setView, phoneNumber, mainRef }) => {
  const [, setError] = useState('')
  const [, setRandomLetters] = useState('')
  const [dialCode] = useState('+1')

  const setErrorForAda = (error) => {
    setRandomLetters(getRandomLetters(4))
    setError(error)
  }

  const handleClick = async (e) => {
    e.preventDefault()
    
    try {
      const user = await Auth.currentAuthenticatedUser()
      const result = await Auth.updateUserAttributes(user, {
        phone_number: dialCode + phoneNumber,
      })
      console.log(result)
      setView(VERIFY_PHONE_NUMBER_CODE)
    } catch (err) {
      setErrorForAda(err.message)
    }
  }

  return (
    <StyledVerifyPhoneNumber>
      <div className="verify-phone-number"
        data-gridcolumnlayout={{
          xl: '1/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef} className="mb-3">Let’s verify the number you chose</h1>
        <p>When you click the Verify My Number button below, we’ll send a text to the phone number you selected with a code to enter.</p>
        <hr />
        <div className="buttons">
          <Button
            text='Verify My Number'
            onClick={handleClick}
            UID="verify-my-number"
          />
        </div>
      </div>
    </StyledVerifyPhoneNumber>
  )
}

export default VerifyPhoneNumber;