import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }
`

const EmailIcon = () => {
  return (
    <StyledSVG
      aria-hidden="true"
      role="img"
      width="80"
      height="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          className="primary"
          d="M77.42555 21.13961c1.18547-.49802 2.50186.39915 2.52004 1.69546.15273 12.0806-.01091 21.43676-.55637 33.16949-.13455 2.8636-2.41459 5.18524-5.25827 5.30609-21.804.91913-46.45894.91913-68.26293 0-2.84368-.12085-5.12735-2.44249-5.2619-5.30976C.07884 44.66732-.08844 35.66271.04247 23.9776c.01455-1.29632 1.33093-2.19714 2.52004-1.69546l33.9751 13.66254c1.34184.56393 2.85096.56393 4.1928 0zM5.50692.69596C27.24546-.232 51.8193-.232 73.55785.69596c2.76297.11783 5.00156 2.35299 5.19324 5.16259.09402 1.35509.17359 2.55184.24591 4.26043.06148 1.5392-.82817 2.96425-2.22412 3.5571L40.25387 28.5747c-1.33448.56708-2.83893.56708-4.1734 0L2.2268 14.80655C.83445 14.2137-.0552 12.78497.00266 11.24576.08584 9.09898.1871 7.63342.30283 5.88801.49089 3.06736 2.73309.81379 5.50692.69596z"
          fill="#1460AA"
        />
        <path
          className="primaryDark"
          d="M43.0582 69.21778c-1.12347 1.35018-2.72078 2.132-4.66534 2.132-4.62584 0-7.13961-3.34725-7.13961-8.4839 0-6.0665 3.33905-9.98069 8.8171-9.98069 2.09167 0 4.39201.57737 6.28656 1.62086l.51755.28506v8.80653c0 2.21638.18342 2.62554.57684 2.62554 1.05195 0 1.78463-1.61284 1.78463-6.0515 0-5.45718-3.00455-8.34657-9.19913-8.34657-6.4325 0-10.23918 4.13066-10.23918 10.74141 0 6.71969 3.62282 10.54185 10.27273 10.54185 2.07098 0 4.50996-.434 6.26871-1.07111l.95697-.34667 1.88501 5.50546-.90672.34246C45.90618 78.4329 42.82692 79 40.0368 79 29.7454 79 23 72.52337 23 62.56652 23 52.95302 30.17166 46 40.07035 46 49.54569 46 56 52.15605 56 60.10515c0 6.60537-3.30326 11.37768-8.31385 11.37768-2.03605 0-3.66095-.89485-4.62794-2.26505zM40 65.12794v-7.10092C39.88567 58.00795 39.7691 58 39.63566 58 38.60828 58 38 59.1963 38 62.77703 38 65.32205 38.31506 66 38.89147 66c.44153 0 .8312-.31378 1.10853-.87206z"
          stroke="#FFF"
          strokeWidth="2"
          fill="#0E4377"
          fillRule="nonzero"
        />
      </g>
    </StyledSVG>
  )
}

export default EmailIcon
