import { useState, useEffect } from 'react'

import { retrieveLocStatus, getLoan } from '../services/api'
import { extractLoan } from '../Utils/apiExtractors'

export const useRetrieveLocStatus = () => {
  const [locStatus, setLocStatus] = useState({ loading: true })

  useEffect(() => {
    const getLoc = async () => {
      let locStatus = null
      let error = null
      try {
        const loan = extractLoan(await getLoan())
        const loanNumber = loan.loanId
        locStatus = await retrieveLocStatus(loanNumber)
      } catch (err) {
        error = err
      } finally {
        setLocStatus({ data: locStatus, error })
      }
    }

    getLoc()
  }, [])

  return locStatus
}
