import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import LOCAdvanceTool from './LOCAdvanceTool'
import PayoffQuoteTool from './PayoffQuoteTool'
import UploadFileTool from './UploadFileTool'
import ChevronIcon from '../../assets/left-chevron-icon'
import { buildTestID } from '../../Utils/utils'
import { RepaymentTool } from './RepaymentTool'

const StyledOnlineToolsSection = styled.div`
  padding: 32px;
  margin: 32px 0 0 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};

  .heading {
    display: flex;
    justify-content: space-between;

    .heading-6 {
      margin: 0 0 8px 0;
    }

    .caption {
      color: ${({ theme }) => theme.colors.black87};
    }

    .nav_arrows {
      display: flex;

      .arrow_left,
      .arrow_right {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.colors.primary};
        transition: background-color 0.2s ease-out;
        width: 32px;
        height: 32px;
        radius: 32px;
        border-radius: 16px;
        text-decoration: none;
        border: none;

        svg {
          min-width: 20px;
        }

        :hover {
          opacity: 0.8;
        }

        :focus {
         outline: 5px solid ${({ theme }) => theme.colors.primary};
        }
      }

      .arrow_right {
        transform: rotate(180deg);
        margin-left: 16px;

        :focus {
          outline: 5px solid black;
         }
      }

      .arrow_left.disabled,
      .arrow_right.disabled {
        background-color: ${({ theme }) => theme.colors.black12};
        cursor: default;

        svg {
          min-width: 20px;
        }
      }
    }
  }

  .carousel {
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;

    div {
      display: flex;
      align-items: flex-start;
    }
  }

  .tool {
    display: inline-block;
    white-space: initial;

    .content {
      width: 390px;
      display: flex;
      min-height: ${({ toolHeight }) => toolHeight}px;
      align-items: flex-start;
      margin-right: 16px;
      border: 1px solid ${({ theme }) => theme.colors.black12};
      border-radius: 4px;
      padding: 24px;
      color: ${({ theme }) => theme.colors.black87};
      height: 204px;

      svg {
        max-width: 140px;
        max-height: 140px;
        min-width: 90px;
        min-height: 90px;
      }

      .button-and-text {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-left: 10px;

        h3 {
          text-align: start;
          margin-bottom: 14px;
        }
      }
    }

    .view-my-req-button {
      margin-top: 12px;
    }

    .loading {
      .left-rec-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120px;
      }

      .rectangle {
        background-color: ${({ theme }) => theme.colors.loading};
        border-radius: 4px;
      }

      .icon {
        height: 70px;
        width: 64px;
        margin-right: 20px;
      }

      .long {
        height: 20px;
        width: 236px;
      }

      .large {
        height: 56px;
        width: 236px;
      }
    }
  }

  .mobile_card {
    display: inline-block;
    white-space: initial;
    display: flex;
    flex-direction: column;
    width: 128px;
    min-width: 128px;
    margin-right: 8px;
    text-decoration: none;

    .icon_section {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.colors.primaryDark};
      height: 88px;
      border-radius: 4px;
      svg {
        max-height: 55px;
        min-width: 55px;
        min-height: 55px;
      }
    }

    p {
      margin-top: 8px;
      overflow-wrap: break-word;
      white-space: initial;
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .button-link {
    appearance: none;
    color: rgb(255, 255, 255);
    background: rgb(20, 96, 170);
    border: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 18px 24px;
    text-decoration: none;
    text-align: center;
    line-height: 18px;
  }

  .button-link:hover {
    color: rgb(255, 255, 255);
    background: rgb(20, 96, 170);
    opacity: 0.92;
  }

  .link_icon {
    font-size: 40px;
    display: flex;
  }

  .external_icon {
    min-height: initial !important;
    min-width: initial !important;
  }

  .extra-text {
    margin-left: -100px;
    margin-top: 5px;
    font-size: small;

    > a {
      font-size: small;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    left: 0;
    width: 100vw;
    position: absolute;
    padding: 24px 32px 14px 32px;

    margin: 32px 0 0 0;
    box-shadow: none;
    background: ${({ theme }) => theme.colors.primary4};

    .carousel {
      div {
        display: flex;
      }
    }
  }
`

const defaultAvailableTools = {
  payoffQuote: false,
  locAdvance: false,
  downloadForm: true,
}

const OnlineToolsSection = ({loanType}) => {
  const [carouselXPosition, setCarouselXPosition] = useState(0)
  const [carouselXEndPosition, setCarouselXEndPosition] = useState(21)
  const [toolHeight, setToolHeight] = useState(225)
  const [availableTools, setAvailableTools] = useState(
    defaultAvailableTools,
  )
  const [leftPressed, setLeftPressed] = useState(false)
  const [rightPressed, setRightPressed] = useState(false)

  const carouselParentRef = useRef()
  const carouselRef = useRef()

  const onCarouselNavArrowClick = (directionClicked) => {
    const { innerWidth } = window

    if (directionClicked === 'left') {
      carouselParentRef.current.scrollLeft -= innerWidth * 0.5
      setLeftPressed(true)
      setRightPressed(false)
    } else if (directionClicked === 'right') {
      carouselParentRef.current.scrollLeft += innerWidth * 0.5
      setLeftPressed(false)
      setRightPressed(true)
    }
  }

  const onCarouselScroll = () => {
    const parent = carouselRef.current.getBoundingClientRect()
    const child = carouselParentRef.current.getBoundingClientRect()

    setCarouselXPosition(child.x - parent.x)
    setCarouselXEndPosition(
      carouselParentRef.current.scrollWidth - parent.width,
    )
  }

  const numberOfTools =
    Object.values(availableTools).filter(Boolean).length

  return (
    <StyledOnlineToolsSection toolHeight={toolHeight}>
      <div className="heading">
        <div>
          <h2 className="heading-6">Online Tools</h2>
          {numberOfTools > 2 ? (
            <p className="caption">
              <i>
                Use the arrows or scroll in this section to view more
                Online Tools.
              </i>
            </p>
          ) : null}
        </div>
        {numberOfTools > 2 ? (
          <div className="nav_arrows">
            <button
              className={`arrow_left ${carouselXPosition < 20 ? 'disabled' : ''
                }`}
              onClick={() => onCarouselNavArrowClick('left')}
              data-testid={buildTestID('left_arrow', '_my_loan')}
              aria-label={`left carousel arrow ${carouselXPosition < 20 ? 'disabled' : ''}`}
              aria-pressed={leftPressed ? "true" : "false"}
              aria-disabled={Boolean(`${carouselXPosition < 20 ? true : false}`)}
            >
              <ChevronIcon color="white" />
            </button>
            <button
              className={`arrow_right ${carouselXEndPosition < carouselXPosition + 20
                ? 'disabled'
                : ''
                }`}
              onClick={() => onCarouselNavArrowClick('right')}
              data-testid={buildTestID('right_arrow', '_my_loan')}
              aria-label={`right carousel arrow ${carouselXEndPosition < carouselXPosition + 20 ? 'disabled' : '' }`}
              aria-pressed={rightPressed ? "true" : "false"}
              aria-disabled={Boolean(`${carouselXEndPosition < carouselXPosition + 20 ? true : false }`)}
            >
              <ChevronIcon color="white" />
            </button>
          </div>
        ) : null}
      </div>
      <div
        className="carousel"
        onScroll={onCarouselScroll}
        ref={carouselParentRef}
      >
        <div ref={carouselRef}>
          <LOCAdvanceTool
            loanType={loanType}
            setToolHeight={setToolHeight}
            setAvailableTools={() =>
              !availableTools.locAdvance
                ? setAvailableTools({
                  ...availableTools,
                  locAdvance: true,
                })
                : null
            }
          />
          <UploadFileTool />
          <RepaymentTool />
          <PayoffQuoteTool
            setAvailableTools={() =>
              !availableTools.payoffQuote
                ? setAvailableTools({
                  ...availableTools,
                  payoffQuote: true,
                })
                : null
            }
          />
        </div>
      </div>
    </StyledOnlineToolsSection>
  )
}

export default OnlineToolsSection
