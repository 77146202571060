import React, { useState, useEffect } from 'react'
import Styled from 'styled-components'
import { useAuthenticator } from '@aws-amplify/ui-react';

import { checkIsUserSignedIn } from '../../services/auth'
import PairIcon from '../../assets/pair-icon.js'
import FaxIcon from '../../assets/fax-icon.js'
import MailBoxIcon from '../../assets/mailbox-icon.js'
import EmailIcon from '../../assets/email-icon.js'
import Button from '../../Common/button'

const StyledSendSection = Styled.div`
  max-width: 400px;
  margin-left: 20px;

  .send-item {
    margin-bottom: 20px;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  img {
    margin-bottom: 25px;
  }

  h4 {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 0;
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label:hover {
    background-color: #2587e5;
}

  .send-email {
    color: ${({ theme }) => theme.colors.primary};
    
  }

  .completed-sign-in-text {
    margin-bottom: 10px;
  }

`

const SendSection = () => {
  const [authed, setAuthed] = useState(null)
  const { authStatus } = useAuthenticator(context => [context.authStatus, context.user]);

  useEffect(() => {
    const runEffect = async () => {
      setAuthed(await checkIsUserSignedIn())
    }

    if (authStatus === 'authenticated')
      runEffect()
  }, [authStatus])

  return (
    <>
      <StyledSendSection>
        <div className="send-item">
          <PairIcon />
          <h4>
            Send from a computer <br />
            or smart phone
          </h4>
          <p className="completed-sign-in-text">
            If you have the completed, signed
            <br />
            form as a file on your computer,
            <br />
            you can send it to us now.
          </p>
          {authed === null ? (
            <div></div>
          ) : authed ? (
            <Button
              linkTo="/upload"
              text="Upload Now"
              UID="send_section"
            />
          ) : (
            <Button linkTo="/" text="Sign In" UID="send_section" />
          )}
        </div>
        <div className="send-item">
          <FaxIcon />
          <h4>Send via Fax</h4>
          <p>
            Fax your form to the
            <br /> fax number on the form
          </p>
        </div>
        <div className="send-item">
          <MailBoxIcon />
          <h4>Send via Mail</h4>
          <p>
            Send by mail using the
            <br />
            address on the form
          </p>
        </div>
        <div className="send-item">
          <EmailIcon />
          <h4>Send via Email</h4>
          <p>
            Send by email using the <br />
            email on the form
          </p>
        </div>
      </StyledSendSection>
    </>
  )
}

export default SendSection
