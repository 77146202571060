import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledResourceCardModern = styled.div`
    border-radius: 20px;
    background: var(--White, #FFF);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
    padding: 20px;
    flex: 1 1 0;

    h6 {
        border-radius: 6px;
        background-color: var(--Dark-Grey, #475468);
        color: var(--White, #fff);
        white-space: nowrap;
        justify-content: center;
        padding: 5px;
        font: 700 14px/114% Lato, sans-serif;
        width: 95px;
        height: 24px;
        flex-shrink: 0;
    }

    .text-heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.25px;
    }

    .text-paragraph {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
    }
    
    .button-learn-more {
    color: #1460aa;
    justify-content: start;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    text-transform: capitalize;
    }

    @media (min-width: ${({ theme }) => theme.breakPoints.small}) { 
    margin-right: 20px;
    }   
`

const ResourceCard = ({ heading, paragraph, linkTo, helpText }) => {
    return (
        <StyledResourceCardModern>
            <h6>RESOURCE</h6>
            <p className='text-heading'>{heading}</p>
            <p className='text-paragraph'>{paragraph}</p>
            <a
                href={linkTo}
                rel="noopener noreferrer"
                target="_blank"
                className='button-learn-more'
            >
                Learn More
                <span className="sr-only">
                    {helpText}
                </span>
            </a>
        </StyledResourceCardModern>
    )
}

ResourceCard.propTypes = {
    heading: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired
}

export default ResourceCard