import React from 'react'
import Styled from 'styled-components'
import Title from '../Common/Title'
import Button from '../Common/button'

const StyledLandingPage = Styled.div`
  margin: 20px 40px;
  background: rgba(255,255,255,.5);
  border-radius: 8px;
  padding: 40px 20px;

  h3 {
    margin-bottom: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    margin: 0;
  {
`

const LandingPage = () => {
  return (
    <>
      <Title>Home</Title>
      <StyledLandingPage>
        <h3>For Information Regarding Your Loan</h3>
        <Button
          text="View Loan Details"
          linkTo="/my-loan"
          UID="landing"
        />
      </StyledLandingPage>
    </>
  )
}

export default LandingPage
