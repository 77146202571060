import React from 'react'
import Styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useAuthenticator } from '@aws-amplify/ui-react';

import housingLogo from '../assets/equal-housing-png-logo-5004.png'

import { useApiContext } from '../Hooks/useApiContext'
import { extractServicer } from '../Utils/apiExtractors'

import { buildTestID } from '../Utils/utils'

const StyledFooter = Styled.div`
  text-align: center;

  .top-footer {
    background: ${({ theme }) => theme.colors.black};
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .top-footer ul {
    margin-top: 52px;
  }

  .top-footer li {
    margin-right: 80px;
    font-size: 26px;
  }
  .top-footer li a {
    color: ${({ theme }) => theme.colors.primary24};
  }

  .custom-middle-footer {
    padding: 48px 165px 8px;
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .custom-middle-footer a: hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.white};
  }

  .bottom-footer {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .bottom-footer li {
    color: ${({ theme }) => theme.colors.white};
    margin-right: 20px;
    font-size: 20px;
  }

  .bottom-footer li a, a {
    color: ${({ theme }) => theme.colors.white};
    white-space: nowrap;
  }

  .bottom-footer a: hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.white};
  }

  h3 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 30px;
  }

  ul {
    list-style-type: none;
  }

  ul li {
    display: inline;
  }

  .bottom-footer {
    ul li+li::before {
      content: " | " / "";
      margin-right: 20px;
      z-index: -1;
    }
  }

  span {
    font-size: 16px;
  }

  img {
    margin-top: 3px;
    margin-right: 8px;
    height: 45px;
  }

  .bottom-disclaimer {
    background: ${({ theme }) => theme.colors.primary};
    margin: 0 auto 32px;
    width: 80%;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {

    ul {
      padding-left: 0;
      text-align: center;
    }

    .top-footer li {
      margin-right: 0px;
      font-size: 20px;
    }

    .custom-middle-footer {
      padding: 48px 20px 1px;
    }

    .bottom-footer {
      ul li+li::before {
        content: "";
        margin-right: 0;
      }
    }

    ul > li {
      display: block;
      margin: 0 auto;
    }

    .bottom-text {
      padding: 15px 30px;
    }

    .mobile-text {
      display: none;
    }
  }

`

const rmfServicedAsId = '316800009'

const DynamicDisclaimer = () => {
  const { loan } = useApiContext()
  const {
    error: loanError,
    loading: loanLoading,
    data: loanData,
  } = loan

  if (loanError || loanLoading || !loanData) return null

  const isRMF =
    extractServicer(loanData).subservicerId === rmfServicedAsId

  return isRMF ? (
    <div className="custom-middle-footer">
      <p className="subtitle-2">
        Compu-Link Corporation, dba Celink, is retained by Reverse
        Mortgage Funding LLC (“RMF”) (NMLS ID#1019941) as a
        third-party service provider to subservice your loan on behalf
        of RMF. You can find more information about RMF, including
        licensing information and its privacy policy at{' '}
        <a
          href="https://www.reversefunding.com"
          aria-label="reverse mortgage funding"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.reversefunding.com
        </a>
        . RMF remains responsible for all actions taken by Celink
        related to the servicing of your loan.{' '}
      </p>
    </div>
  ) : null
}

const Footer = () => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const buildTestIDFooter = (text) => {
    return buildTestID(text, '/home', 'footer')
  }

  return (
    <StyledFooter>
      <div className="top-footer">
        <h3>Reverse Mortgage Servicing Department</h3>
        <ul>
          <li>
            <Link data-testid={buildTestIDFooter('Home')} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link
              data-testid={buildTestIDFooter('Contact Us')}
              to="/contact"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      {authStatus === 'signedIn' ? <DynamicDisclaimer /> : null}
      <div className="bottom-footer">
        <ul>
          <li>
            <Link
              data-testid={buildTestIDFooter('Privacy Policy')}
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link
              to="/terms-conditions"
              data-testid={buildTestIDFooter('Terms & Conditions')}
            >
              Terms &amp; Conditions
            </Link>
          </li>
          <li>
            <Link
              data-testid={buildTestIDFooter(
                'Schedule of Borrower Fees',
              )}
              to="/schedule-of-borrower-fees"
            >
              Schedule of Borrower Fees
            </Link>
          </li>
          <li>
            <Link
              data-testid={buildTestIDFooter('Licenses')}
              to="/licenses"
            >
              Licenses
            </Link>
          </li>
          <li>
            <Link
              to="/important-disclosures"
              data-testid={buildTestIDFooter('Important Disclosures')}
            >
              Important Disclosures
            </Link>
          </li>
          <li>
            <Link
              to="/accessibility-statement"
              data-testid={buildTestIDFooter(
                'Accessibility Statement',
              )}
            >
              Accessibility Statement
            </Link>
          </li>
        </ul>
        <p className="bottom-text">
          <span>
            <FontAwesomeIcon icon={faLock} /> Your data and
            information is being protected by encryption
          </span>
        </p>
        <p className="bottom-text">
          <span>
            <a
              data-testid={buildTestIDFooter('NMLS #3020')}
              href="https://nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/3020"
              aria-label="NMLS #3020"
            >
              NMLS #3020
            </a>
          </span>
        </p>
        <p className="bottom-text">
          <span>
            <img src={housingLogo} alt="equal housing opportunity logo" />©
            2020-2024 Compu-Link Corporation D/B/A Celink (WA license# CL3020 and 603 018 607)
          </span>
        </p>
      </div>
    </StyledFooter>
  )
}

export default Footer