import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import QuestionIcon from '../assets/question-icon'
import { buildTestID } from '../Utils/utils'
import { trackUsedHelpBanner } from '../Global/Analytics'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'

const StyledHaveQuestionsBottomBar = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryDark};
  background: ${({ theme }) => theme.colors.primary12};
  border-radius: 4px;
  padding: 16px 18px;
  margin-top: 20px;

  svg {
    min-width: 20px;
    align-self: flex-start;
    margin-top: 2px;
  }

  a {
    color: ${({ theme }) => theme.colors.primaryDark};
    font-weight: normal;
  }

  p {
    margin: 0 0 0 8px;
  }
`

const HaveQuestions = () => {
    useLocation()

    return (
        <StyledHaveQuestionsBottomBar>
            <QuestionIcon />
            <p className="body-2">
                <b>Have questions?</b> Check out our{' '}
                <b>New Loan Reference Guide</b> on the{' '}
                <Link
                    data-testid={buildTestID('have_questions', '/statements', 'bottom_bar')}
                    target="_blank"
                    to="/helpful-resources"
                    onClick={() => trackUsedHelpBanner("Statement Helpful Resources")}
                    aria-label={`${"helpful resources link opens in new tab"}`}
                >
                    Helpful Resources
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="external_icon" style={{marginLeft: "5px"}} aria-label='link opens in new window' />
                </Link>{' '}
                page.
            </p>
        </StyledHaveQuestionsBottomBar>
    )
}

export default HaveQuestions
