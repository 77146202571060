import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

import { upload } from '../../services/file'
import { trackESignConsent } from '../../Global/Analytics'
import { SIGNATURE } from '.'

import Button from '../../Common/button'
import useWindowSize from '../../Hooks/useWindowSize'
import UploadSection from '../../Components/Upload/UploadSection'

const StyledProofOfMarriage = styled.div`
  h1, h3 {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }

  input[type="radio"] {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-top: -4px;
  }

  input[type="radio"] + label { 
    margin-left: 24px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .mail, .upload {
      margin-top: 24px;
  }

  .mail label, .upload label {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1px; 
  }

  .mail p, .upload p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  .address {
      background: #FAFAFA;
      padding: 24px;
  }

  .uploaded_file {
      position: relative;
  }
  
  .mb-20 {
      margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .proof-of-marriage {
      background-color: #fff;
      padding: 15px;
      margin-top: 32px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      text-align: center;
      margin: 32px;
    }

    .questions {
        margin: 20px 100px 0;
    }  

    .mail, .upload {
        margin-left: 100px;
        margin-right: 100px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const invalidFileTypes = [
    'exe',
    'scr',
    'vbs',
    'js',
    'xml',
    'docm',
    'xps',
    'iso',
    'img',
    'arj',
    'lzh',
    'r01',
    'r14',
    'r18',
    'r25',
    'tar',
    'ace',
    'zip',
    'jar',
    'doc',
    'rtf',
    'xls',
    'pub',
    'asp',
    'php',
]

const ProofOfMarriage = ({ setView, setModalShow, handleProofOfMarriage, handleClientName, mainRef }) => {
    const [method, setMethod] = useState("")
    const { isSmall } = useWindowSize()

    const [selected] = useState("proof-of-marriage")
    const [clientName] = useState(sessionStorage.getItem('clientName'))
    const [fileToUpload, setFileToUpload] = useState([])
    const [invalidFileError, setInvalidFileError] = useState("")
    const [loading, setLoading] = useState(false)

    const fileUploaded = Boolean(fileToUpload.length)

    const handleUpload = () => {
        if (!fileToUpload.length)
            setView(SIGNATURE)
        else {
            setLoading(true)

            upload(fileToUpload[0], selected, true).then((data) => {
                if (data.key) {
                    setFileToUpload([])
                    trackESignConsent.StepCompleted('uploaded proof of marriage', 'proof_of_marriage_button_next')
                    handleProofOfMarriage(true)
                    setLoading(false)
                    setView(SIGNATURE)
                } else {
                    setLoading(false)
                }
            })
        }
    }

    const handleMail = () => {
        handleClientName(true)
        setView(SIGNATURE)
    }

    const onDrop = useCallback(
        (acceptedFiles) => {
            setInvalidFileError("")
            const invalidFiles = acceptedFiles.filter((x) =>
                invalidFileTypes.includes(
                    x.name.split('.').pop().toLowerCase(),
                ),
            )
            if (invalidFiles.length) {
                setInvalidFileError(
                    'Invalid file type. Please upload a different file type.',
                )
                return
            }
            setTimeout(() => setFileToUpload(acceptedFiles), 1000)
        },
        [setInvalidFileError, setFileToUpload],
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    // Prevents user from uploading when file has been uploaded
    const dynamicUpload = fileUploaded ? () => { } : getRootProps

    return (
        <StyledProofOfMarriage>
            <div className="proof-of-marriage"
                data-gridcolumnlayout={{
                    xl: '3/13',
                    lg: '1/13',
                    md: '1/14',
                    sm: '1/9',
                }}>
                <h1 tabIndex={-1} ref={mainRef}>Annual Occupancy Certification</h1>
                <h5>Please provide proof of your marriage.</h5>
                <div className='questions'>
                    <p>How would you like to submit this proof?</p>
                    <div>
                        <input type="radio" id="proof-mail" name="method" value="mail" onClick={() => setMethod("mail")} />
                        <label htmlFor="proof-mail">Mail a copy</label><br />
                    </div>
                    <div>
                        <input type="radio" id="proof-upload" name="method" value="upload" onClick={() => setMethod("upload")} />
                        <label htmlFor="proof-upload">Upload a copy</label><br /><br />
                    </div>
                </div>
                {method === 'mail' && (
                    <div className="mail">
                        <label className={'mb-20'}>Please mail a copy to the following address:</label>
                        <div className="address">
                            <label>Mailing Address</label>
                            {isSmall ?
                                <p>{clientName} <br />
                                    P.O. Box 40087<br />
                                    Lansing, MI 48901-7287
                                </p> :
                                <p>{clientName}<br />P.O. Box 40087, Lansing, MI 48901-7287</p>}
                        </div>
                    </div>
                )}
                {method === 'upload' && (
                    <div className='upload'>
                        <label className={'mb-20'}>Please upload a copy using the upload box below.</label>
                        <p>Note: If you do not have your document on hand, you can use the upload tool after completing this form and upload it at any time. </p>
                        <UploadSection
                            data-gridcolumnlayout={{
                                sm: '1/9',
                                md: '8/12',
                                lg: '8/12',
                                xl: '9/13',
                            }}
                            cellclassname="fixed_cell"
                            setFileToUpload={setFileToUpload}
                            fileUploaded={fileUploaded}
                            fileToUpload={fileToUpload}
                            getInputProps={getInputProps}
                            isDragActive={isDragActive}
                            invalidFileError={invalidFileError}
                            dynamicUpload={dynamicUpload}
                        />
                    </div>
                )}
                <hr />
                <div className="buttons">
                    <Button
                        className="cancel button"
                        text="Cancel"
                        outlined
                        fullWidth
                        UID="proof_of_marriage_button_cancel"
                        onClick={() => setModalShow(true)}
                    />
                    <Button
                        className="button"
                        text="Next"
                        fullWidth
                        UID="proof_of_marriage_button_next"
                        disabled={method === ""}
                        loading={loading}
                        onClick={() => {
                            trackESignConsent.StepCompleted('selected preferred submission of proof of marriage', 'proof_of_marriage_button_next')
                            method === 'mail' ? handleMail() : handleUpload()
                        }}
                    />
                </div>
            </div>
        </StyledProofOfMarriage>
    )
}

export default ProofOfMarriage