import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import StyleGuide from '../../../Global/StyleGuide'
import Button from '../../../Common/button'
import useWindowSize from '../../../Hooks/useWindowSize'
import { trackESignConsent } from '../../../Global/Analytics'
import { postESignAgreementConsent } from '../../../services/api'

const StyledESignConsentPreference = styled(Modal)`
  .modal-content {
    padding: 32px 32px 0 32px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
    max-height: 70vh;
    overflow-y: auto;
  }

  .modal-width {
    max-width: 450px !important;
  }

  .title {
    margin: 0 0 24px 0;
  }

  .error_text {
    color: ${({ theme }) => theme.colors.error};
    margin: 0 0 24px 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 32px;
    margin-bottom: 32px;

    .cancel {
      margin-right: 24px;
    }
  }

  ul {
    padding-left: 20px;
  }

  h5 {
    margin: 14px 0;
  }

  .consent_check {
    display: flex;
    align-items: center;

    .checkbox {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .body2 {
      margin: 0;
    }
  }

  .button {
    width: 162px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 24px 0 0 0;

    .button {
      width: 100%;
    }

    .buttons {
      padding-bottom: 48px;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const ESignConsentPreference = ({
  signatureId,
  email,
  closeScreen,
  show,
  setESignConsent
}) => {
  const [loading, setLoading] = useState(false)
  const [, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const { isSmall } = useWindowSize()

  const handleCloseScreen = () => {
    closeScreen()
    setError('')
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await postESignAgreementConsent(signatureId, email)
      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        handleCloseScreen()
        trackESignConsent.StepCompleted()
        setESignConsent("Removed")
      }, 2500)
    } catch (e) {
      setLoading(false)
      console.log('error updating: ', e)
      setError('Network error. Please try again.')
    }
  }

  let ModalOrDivProps = {
    onHide: handleCloseScreen,
    show,
    centered: true,
    size: 'md',
    as: isSmall ? 'div' : Modal,
    dialogClassName: 'modal-width',
  }

  if (isSmall) delete ModalOrDivProps.onHide

  return show ? (
    <StyledESignConsentPreference {...ModalOrDivProps}>
      <StyleGuide>
        <h3 className="title heading-6">Remove Electronic Consent</h3>
        <h5 className='caption'>
          Are you sure you want to remove your Electronic Consent? By clicking “remove” you will no longer be able to sign documents electronically.
        </h5>
        <div className="buttons">
          <Button
            className="cancel button"
            text="Cancel"
            outlined
            onClick={handleCloseScreen}
            UID="esign_cancel_modal_cancel"
            fullWidth
          />
          <Button
            className="button"
            text={loading ? 'Saving' : 'Remove'}
            UID="remove_esign_consent"
            onClick={handleSave}
            loading={loading}
            fullWidth
          />
        </div>
        {success && <p role="status" className="sr-only">remove esign consent successful</p>}
      </StyleGuide>
    </StyledESignConsentPreference>
  ) : null
}

ESignConsentPreference.propTypes = {
  show: PropTypes.bool.isRequired,
  closeScreen: PropTypes.func.isRequired
}

export default ESignConsentPreference
