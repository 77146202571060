import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const SendViaMailIcon = ({ className, color = 'black60' }) => {
    return (
        <StyledSVG
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 136 136" fill="none">
            <circle cx="68" cy="68" r="68" fill="#E1E1E1" />
            <path d="M74.2916 100C73.6729 100 73.1363 99.7727 72.6818 99.3182C72.2273 98.8637 72 98.3271 72 97.7084V80.2916C72 79.6729 72.2273 79.1363 72.6818 78.6818C73.1363 78.2273 73.6729 78 74.2916 78H97.2084C97.8271 78 98.3637 78.2273 98.8182 78.6818C99.2727 79.1363 99.5 79.6729 99.5 80.2916V97.7084C99.5 98.3271 99.2727 98.8637 98.8182 99.3182C98.3637 99.7727 97.8271 100 97.2084 100H74.2916ZM85.75 89.8708L74.2916 82.4687V97.7084H97.2084V82.4687L85.75 89.8708ZM85.75 87.5792L97.1167 80.2916H74.4062L85.75 87.5792ZM74.2916 82.4687V80.2916V97.7084V82.4687Z" fill="#475468" />
            <path d="M68 56C68 52.134 71.134 49 75 49C78.866 49 82 52.134 82 56V75H68V56Z" fill="#475468" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M70.124 49C67.6646 50.3647 66 52.988 66 56V75H40V57C40 52.5817 43.5817 49 48 49H70.124Z" fill="#475468" />
            <rect x="59" y="41" width="2" height="8" fill="#475468" />
            <rect x="57" y="70" width="4" height="21" fill="#475468" />
            <path d="M59 36H69L67.3333 39.5L69 43H59V36Z" fill="#475468" />
        </StyledSVG>
    )
}

export default SendViaMailIcon
