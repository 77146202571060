import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }

  margin-top: -5px;
`
const TimerIcon = ({ dataTestid, color = 'alert' }) => {
  return (
    <StyledSVG
      color={color}
      data-testid={dataTestid}
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11.9 20.0465C10.8038 20.0465 9.77676 19.8393 8.81878 19.4248C7.86078 19.0103 7.02539 18.4467 6.31262 17.7339C5.59984 17.0211 5.03621 16.1857 4.62173 15.2277C4.20724 14.2698 4 13.2427 4 12.1465C4 11.0504 4.20724 10.0233 4.62173 9.06534C5.03621 8.10736 5.59984 7.27197 6.31262 6.55919C7.02539 5.84642 7.86078 5.2828 8.81878 4.86832C9.77676 4.45382 10.8038 4.24657 11.9 4.24657C13.141 4.24657 14.2859 4.51099 15.3346 5.03984C16.3833 5.56869 17.2717 6.27862 17.9999 7.16964V3.90039H19.2999V8.97727H14.223V7.67729H16.7634C16.1586 7.01396 15.4407 6.493 14.6098 6.11442C13.7789 5.73583 12.8756 5.54654 11.9 5.54654C10.0833 5.54654 8.52914 6.19237 7.23748 7.48404C5.94581 8.77571 5.29997 10.3299 5.29997 12.1465C5.29997 13.9632 5.94581 15.5174 7.23748 16.809C8.52914 18.1007 10.0833 18.7465 11.9 18.7465C13.7166 18.7465 15.2676 18.0991 16.5529 16.8042C17.8381 15.5094 18.4903 13.9568 18.5096 12.1465H19.7903C19.7967 14.3452 19.0329 16.2116 17.499 17.7456C15.965 19.2795 14.0987 20.0465 11.9 20.0465ZM14.5731 15.3734L11.25 12.0504V7.34654H12.55V11.4927L15.5019 14.4446L14.5731 15.3734Z" fill="white"/>
    </StyledSVG>
  )
}

export default TimerIcon
