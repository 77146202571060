import * as React from "react";
import styled from "styled-components";

const Section = styled.section`
  border-radius: 20px;
  border: 1px solid rgba(255, 101, 101, 1);
  background-color: var(--White, #fff);
  display: flex;
  max-width: 814px;
  flex-direction: column;
  justify-content: center;
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 416px;
  width: 100%;
  align-items: flex-end;
  padding: 33px 20px;
  padding-left: 80px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 220px;
  object-fit: cover;
  object-position: left center;
`;

const Title = styled.h5`
  color: var(--Red, #FF5050);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 108.333% */
  margin-bottom: 30px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const WarningText = styled.p`
  position: relative;
  color: var(--Black, #000);
  margin-top: 38px;
  width: 494px;
  font: 400 16px/20px Lato, sans-serif;
  
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const BoldText = styled.span`
  font-weight: 800;
`;

const CybercrimeWarningDesktop = () => {
    return (
        <Section>
            <WarningContainer>
                <BackgroundImage loading="lazy" src="./assets/cybercrime-warning-desktop.png" alt="" />
                <div>
                    <Title>CYBERCRIME & WIRE FRAUD WARNING</Title>
                    <WarningText>
                        <BoldText>Email hacking and wire fraud, with the intent to misdirect wired funds, is a risk.</BoldText>
                        <br />
                        <br />
                        Emailing wire instructions, or relying upon wiring instructions that have been emailed, is dangerous as email is not secure and wire instructions could be intercepted and altered.
                        <br />
                        <br />
                        Always verify the accuracy of wiring instructions with the intended recipient, prior to sending funds, using a trusted and verified phone number for the recipient.
                        <br />
                        <br />
                        Be alert and suspicious of unexpected or last minute changes to wiring instructions, the appearance of misspelled words, and altered email addresses or domain names especially those that closely resemble a trusted email address or domain but are slightly different.
                    </WarningText>
                </div>
            </WarningContainer>
        </Section>
    );
}

export default CybercrimeWarningDesktop;