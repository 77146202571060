import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }
`

const GreenLeafIcon = () => {
    return (
        <StyledSVG
            aria-hidden="true"
            role="img"
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
        >

            <path d="M14.5 15C16.9 12.6 17.1667 6 17 3H14C12.5 3 9 3.2 7 4C4.5 5 3 8 3 9.5C3 11 3.5 14 5.5 15.5C7.5 17 11.5 18 14.5 15Z" fill="#57BB1A" />
            <path d="M4.5923 15.3686C3.93205 14.7019 3.41827 13.9396 3.05095 13.0816C2.68365 12.2237 2.5 11.3366 2.5 10.4205C2.5 9.49869 2.67756 8.57979 3.03268 7.66377C3.38781 6.74774 3.95768 5.89741 4.7423 5.11279C5.37692 4.47818 6.18108 3.97497 7.1548 3.60317C8.12852 3.23139 9.18685 2.95992 10.3298 2.78877C11.4727 2.6176 12.6574 2.52304 13.8837 2.50509C15.1099 2.48714 16.291 2.51664 17.4269 2.59357C17.509 3.69357 17.541 4.85383 17.5231 6.07434C17.5051 7.29486 17.4061 8.47852 17.226 9.62532C17.0458 10.7721 16.7708 11.8362 16.401 12.8176C16.0311 13.799 15.5333 14.6025 14.9077 15.2282C14.1397 16.009 13.3112 16.5779 12.4221 16.9349C11.533 17.292 10.6406 17.4705 9.745 17.4705C8.80602 17.4705 7.87756 17.2868 6.95963 16.9195C6.04168 16.5522 5.25257 16.0352 4.5923 15.3686ZM6.27692 15.4397C6.79873 15.7872 7.36508 16.0458 7.97598 16.2157C8.58686 16.3856 9.1782 16.4705 9.75 16.4705C10.5253 16.4705 11.2922 16.3195 12.0507 16.0176C12.8092 15.7157 13.5237 15.2166 14.1942 14.5205C14.4918 14.2205 14.7936 13.7997 15.0994 13.258C15.4053 12.7163 15.6698 12.008 15.893 11.133C16.1162 10.258 16.2857 9.19966 16.4014 7.95799C16.5171 6.71633 16.5462 5.23716 16.4885 3.52049C15.6718 3.48716 14.7445 3.48107 13.7067 3.50222C12.6689 3.52337 11.6321 3.61215 10.5962 3.76857C9.56025 3.92499 8.58717 4.16344 7.67693 4.48394C6.76667 4.80446 6.03911 5.23716 5.49423 5.78204C4.75704 6.51921 4.24037 7.28331 3.94422 8.07434C3.64807 8.86536 3.5 9.60254 3.5 10.2859C3.5 11.1923 3.68109 12.0388 4.04328 12.8253C4.40546 13.6118 4.8436 14.2256 5.3577 14.6666C5.77565 13.4872 6.4218 12.3554 7.29615 11.2714C8.17052 10.1875 9.3936 9.1769 10.9654 8.23972C9.81668 9.23844 8.85097 10.3009 8.06827 11.4272C7.28557 12.5535 6.68846 13.891 6.27692 15.4397Z" fill="#2F640E" />
        </StyledSVG>
    )
}

export default GreenLeafIcon
