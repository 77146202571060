import React, { useEffect } from 'react'
import { saveAs } from 'file-saver';
import styled from 'styled-components'

import Button from '../../Common/button'
import QuestionIcon from '../../assets/question-icon'


const StyledSignatureComplete = styled.div`
h1, h3 {
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  margin-top: 16px;
  line-height: 30px;
  letter-spacing: 0.15px;
}

h5 {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-top: 16px;
}

.buttons {
  display: flex;
  align-self: flex-start;
  align-items: start;
  margin-top: 24px;
  justify-content: space-between;
  flex-direction: column;

  .button {
    margin-bottom: 16px;
  }

  .all-buttons {
    height: 40px;
  }
}

svg {
  width: 20px;
  height: 20px;
  margin-top: -4px;
  margin-right: 15px;
}

.tax-message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  background: #e3ecf5;
  border-radius: 4px;
  margin-top: 8px;

  .message {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #00377a;
    order: 1;
    flex-grow: 1;
    margin: 0px 8px;
  }
}


  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .complete {
      background-color: #fff;
      padding: 32px;
      margin-top: 32px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      text-align: center;
    }

    .buttons {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 32px;

      .button {
        margin: 0 12px;
      }
    }
  }
`

const SignatureComplete = ({ certificate, mainRef }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleDownload = () => {
    const filename = certificate.substring(certificate.lastIndexOf('/') + 1, certificate.lastIndexOf('?'));
    fetch(certificate)
      .then(res => res.blob())
      .then(blob => saveAs(blob, filename))
  }

  return (
    <StyledSignatureComplete>
      <div className="complete"
        data-gridcolumnlayout={{
          xl: '3/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Occupancy Certification Successfully Signed</h1>
        <h5>Thank you! You have successfully signed and submitted the Annual Occupancy Certification form.</h5>
        <div className="buttons">
          <Button
            className="button"
            text="Download a Copy"
            type="button"
            UID="download_occupancy"
            onClick={handleDownload}
          />
          <Button
            className="button"
            text="Print a copy"
            outlined
            UID="print_occupancy"
            onClick={() => window.open(certificate)}
          />
        </div>
        <div className="tax-message">
          <div><QuestionIcon /></div>
          <div className="message">If you’ve entered into a tax deferral program, please call us at (866) 654-0020.</div>
        </div>
      </div>
    </StyledSignatureComplete>
  )
}

export default SignatureComplete