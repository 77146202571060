import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'
import useWindowSize from '../../Hooks/useWindowSize'
import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'
import { useHistory } from 'react-router-dom'

const StyledEnableMfa = styled.div`
 
`

export const EnableMfa = ({ className }) => {
        const { isSmall } = useWindowSize()
        const history = useHistory()
        const [mfaEnabled, setMfaEnabled] = useState()

        useEffect(() => {
            const getMfa = async () => {
                try {
                    // Will retrieve the current mfa type from cache
                    // bypassCache is optional, by default is false.
                    // If set to true, it will get the MFA type from server side instead of from local cache.
                    const user = await Auth.currentAuthenticatedUser();
                    const data = await Auth.getPreferredMFA(user, { bypassCache: false });
                    setMfaEnabled(data === 'NOMFA' ? false : true);
                } catch (err) {
                    console.log(err)
                }
            }

            getMfa()
        }, [])

        return (
            <StyledEnableMfa
                className={className}
                gridcolumnlayout={{ sm: '1/9', lg: '1/13', xl: '2/14' }}>
                <h3 className="subtitle-2 bold group_title">Multifactor Authentication:</h3>
                <div className="edit_group">
                    <p className="subtitle-2">
                        Enable MFA to further protect your online account.
                    </p>
                    {mfaEnabled ? 
                    (<p><b>Enabled</b></p>) 
                    : (<Button
                        className="enable_mfa_button"
                        text="Enable MFA"
                        onClick={() => {
                            history.push('enable-multifactor');
                        }}
                        data-testid={buildTestID(
                            'account-settings',
                            '/multifactor',
                            'enable-mfa',
                        )}
                        UID="enable_mfa"
                        textButton={isSmall ? false : true}
                        fullWidth={isSmall ? true : false}
                    />)}
                </div>
            </StyledEnableMfa>
        )
    }