import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Styled from 'styled-components'

import Button from '../Common/button'
import ScrollToTop from '../Global/ScrollToTop'
import InfoCard from '../Common/InfoCard.js'
import VideoIcon from '../assets/video-icon'
import StyleGuide from '../Global/StyleGuide'
import useWindowSize from '../Hooks/useWindowSize'
import InforCircleIcon from '../assets/info-circle-icon'
import RegistrationModal from '../Common/RegistrationModal'

const StyledSignUpLayout = Styled.div`
    margin-top: 60px;
    margin-bottom: 60px;

  h2 {
    font-size: 1.5rem;
  }

  .sign-in-right {
    text-align: center;
  }

  .refinance {
    margin: 24px 15px;
    background: #f6f9fc;
    border: 1px solid #1460aa;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 32px;

    .col {
      padding: 0;
    }

    svg {
      position: absolute;
      left: -25px;
      top: 3px;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
    
  }

  .need_help_card {
    margin: 32px 0 0 32px;

    a {
      text-decoration: none;  
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }

  h1 {
    margin-bottom:24px;
  }

  
  .heading-4 {
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 42px;
    letter-spacing: 0.25px;
  }

  
  .heading-5 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
  }

  .text {
    display: inline-flex;

    button {
      text-transform: none;
    }
  }

  ul.helpful-tips li {
    margin-left: -22px;
    padding: 15px 0 0 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .need_help_card {
      margin: inherit 28px;

      a {
        display: initial;
      }
    }
  }

  @media (min-width: 992px) { 
    .refinance {
      margin-left: -5px;
      margin-right: -5px;
      padding: 16px 64px;
    }
  }

  .Form__formContainer___1GA3x {
    text-align: center;
    margin-top: 20px;
    margin: 5% auto 50px;
  }
  
  .Form__formSection___1PPvW {
    position: relative;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 35px 40px;
    text-align: left;
    display: inline-block;
    min-width: 460px;
    border-radius: 6px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
    box-sizing: border-box;
  }
  
  .Form__formField___38Ikl {
    margin-bottom: 22px;
  }
  
  .Form__formRow___2mwRs {
    margin-bottom: 12px;
  }
  
  @media only screen and (max-width: 599px) {
    .Form__formContainer___1GA3x {
      margin: 0;
    }
  
    .Form__formSection___1PPvW {
      width: 100%;
      box-sizing: border-box;
      padding: 35px 25px;
      box-shadow: none;
      border-radius: 0;
      min-width: auto;
    }
  }

  .Form__formContainer___1GA3x {
  text-align: center;
  margin-top: 20px;
  margin: 5% auto 50px;
}

.Form__formSection___1PPvW {
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  box-sizing: border-box;
}

.Form__formField___38Ikl {
  margin-bottom: 22px;
}

.Form__formRow___2mwRs {
  margin-bottom: 12px;
}

@media only screen and (max-width: 599px) {
  .Form__formContainer___1GA3x {
    margin: 0;
  }

  .Form__formSection___1PPvW {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    box-shadow: none;
    border-radius: 0;
    min-width: auto;
  }
}

.form-section {
  background: rgba(255, 255, 255, 0.5);
  font-size: 20px;
}

.Section__container___3YYTG {
	font-weight: 400;
}

.Section__actionRow___2LWSU {
	margin-bottom: 15px;
}

.Section__sectionHeader___2djyg {
	color: #152939;
	margin-bottom: 24px;
	font-size: 18px;
	font-weight: 500;
}

.Section__sectionHeaderHint___3Wxdc {
	color: #6c757d;
	font-size: 16px;
	font-weight: 400;
	margin-top: 4px;
}

.Section__sectionBody___ihqqd {
	margin-bottom: 30px;
}

.Section__sectionHeaderContent___1UCqa {
}

.Section__sectionFooter___1T54C {
	font-size: 14px;
	color: #6c757d;
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-start;
}

.Section__sectionFooterPrimaryContent___2r9ZX {
	margin-left: auto;
}

.Section__sectionFooterSecondaryContent___Nj41Q {
	margin-right: auto;
	align-self: center;
}

@media only screen and (max-width: 599px) {
	.Section__sectionFooter___1T54C {
		flex-wrap: wrap;
	}

	.Section__sectionFooterPrimaryContent___2r9ZX {
		width: 100%;
		margin-bottom: 32px;
	}

	.Section__sectionFooterPrimaryContent___2r9ZX > button {
		width: 100%;
	}

	.Section__sectionFooterSecondaryContent___Nj41Q {
		text-align: center;
		flex: 0 0 100%;
	}
}

.section-header {
  font-size: 30px;
  margin-bottom: 16px;
}

.section-header {
  font-size: 30px;
  margin-bottom: 16px;
}

.Input__input___3e_bf {
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  color: #152939;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.Input__input___3e_bf:disabled {
  color: #545454;
  background-color: #e1e4ea;
}

.Input__inputLabel___3VF0S {
  color: #152939;
  font-size: 14px;
  margin-bottom: 8px;
}

.Input__label___23sO8 {
  color: #152939;
}

.Input__radio___2hllK {
  margin-right: 18px;
  vertical-align: bottom;
}

@media only screen and (max-width: 599px) {
  .Input__input___3e_bf {
    font-size: 16px;
  }
}
`

const SignUpLayout = ({ children }) => {
  const [modalShow, setModalShow] = useState(false)
  const { isSmall } = useWindowSize()

  const toggleModal = () => {
    // watchedRegistrationVideo("registration")
    setModalShow(true)
  }

  return (
    <Container>
      <ScrollToTop />
      <RegistrationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        page="registration"
      />
      <StyledSignUpLayout>
        <Row>
          <Col sm="8">
            <h1
              className={`bold ${isSmall ? 'heading-5' : 'heading-4'
                }`}
            >
              Register Your Account
            </h1>
            <Row className="refinance">
              <Col>
                <InforCircleIcon />
                <p className='mb-0'><b>Recently Refinanced?</b> If you have already registered and have an online account for your prior loan, have refinanced, and now want to access your new loan online, log in with your existing account. You will be directed to your active loan.</p>
              </Col>
            </Row>
            <div>{children}</div>
          </Col>
          <Col>
            <div className="sign-in-right">
              <h2>Do you already have an account?</h2>
              <div className="mt-4">
                <Button
                  linkTo="/"
                  text="Sign In"
                  outlined
                  UID="account_creation"
                  refreshPage
                />
              </div>
            </div>
            <StyleGuide>
              <InfoCard
                className="need_help_card"
                smallScreenStyles
                title="Need Help Registering?"
                SVG={VideoIcon}
                onClick={toggleModal}
                paragraph={
                  <>
                    Watch our registration video  <span className='text'><Button
                      text="here"
                      UID="registration_video"
                      textButton
                      ariaLabel="Click here to watch our registration video"
                      onClick={toggleModal}
                    /></span>
                  </>
                }
              />
              <InfoCard
                className="need_help_card"
                smallScreenStyles
                title="Need Help?"
                paragraph={
                  <>
                    <b>Helpful Tips:</b><br />
                    <ul className='helpful-tips'>
                      <li>Make sure you are using the most current version of your preferred browser (Chrome, Edge, Safari, etc.- this site is not compatible with Internet Explorer)</li>
                      <li>You may need to clear your browser’s cache and cookies.</li>
                      <li>We recommend that you do not use “auto-fill” when creating an account.</li>
                    </ul>
                  </>
                }
              />
            </StyleGuide>
          </Col>
        </Row>
      </StyledSignUpLayout>
    </Container>
  )
}

export default SignUpLayout
