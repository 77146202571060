import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import CheckIcon from '../assets/check-circle-icon'
import AlertIcon from '../assets/alert-icon'
import OutlinedAlertIcon from '../assets/outlined-alert-icon.js'
import VerifiedIcon from '../assets/verified-icon.js'
import { buildTestID } from '../Utils/utils'

const StyledBannerBar = styled.div`
  background: ${({ theme, color }) => theme.colors[color + 'Bg']};
  border: 1px solid ${({ theme, color }) => theme.colors[color]};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  margin-top: 30px;

  svg {
    min-width: 20px;
    margin-right: 8px;
    margin-top: 4px;
    align-self: flex-start;
  }

  h4 {
    margin: 0;
  }
`

const BannerBar = ({ className, type, text, show, UID = '' }) => {
  const [color, setColor] = useState('')

  useEffect(() => {
    if (type === 'info') {
      setColor('alert')
    } else if (type === 'verified') {
      setColor('success')
    } else {
      setColor(type)
    }
  }, [type])

  let path = useLocation().pathname

  return show ? (
    <StyledBannerBar
      className={className}
      color={color}
      data-testid={buildTestID(text, path, UID)}
    >
      {type === 'error' ? <AlertIcon color={color} /> : null}
      {type === 'success' ? <CheckIcon color={color} /> : null}
      {type === 'verified' ? <VerifiedIcon color={color} /> : null}
      {type === 'alert' ? <AlertIcon color={color} /> : null}
      {type === 'info' ? <OutlinedAlertIcon color={color} /> : null}
      <h4 className="subtitle" role="alert" aria-live="polite">{text}</h4>
    </StyledBannerBar>
  ) : null
}

BannerBar.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  show: PropTypes.bool,
}

export default BannerBar
