import { useEffect } from 'react'

export const useFocus = (refComponent, isInitialStateFocused) => {
  useEffect(() => {
    if (
      isInitialStateFocused &&
      refComponent &&
      refComponent.current
    ) {
      refComponent.current.focus()
    }
  }, [refComponent, isInitialStateFocused])

  return {
    focus: () => {
      if (!refComponent || !refComponent.current) return
      document.activeElement.blur()
      refComponent.current.focus()
    },
  }
}
