import React from 'react'
import styled from 'styled-components'

import StyleGuide from '../Global/StyleGuide'
import Grid from '../Global/Grid'
import SiteBrokenIcon from '../assets/site-broken-icon'

const StyledErrorBountry = styled.div`
  text-align: center;

  .heading {
    margin: 40px 0 20px 0;
    font-weight: 500;
  }

  .sub_heading {
    margin: 0;
  }

  svg {
    margin: 24px 0 100px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .heading {
      margin-top: 60px;
      font-size: 24px;
    }

    .sub_heading {
      font-size: 18px;
    }

    svg {
      min-height: 160px;
      min-width: 190px;
      height: 160px;
      width: 190px;
    }
  }
`
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return (
        <StyleGuide>
          <StyledErrorBountry>
            <Grid
              gridcolumnlayout={{
                xl: '3/13',
                lg: '1/13',
                md: '1/14',
                sm: '1/9',
              }}
            >
              <h5 className="heading-5 bold heading">
                Something went wrong with your request. We have been
                notified and are working to fix the issue.
              </h5>
              <h4 className="body-1 sub_heading">
                We apologize for any inconvenience. We’ll be back
                online soon!
              </h4>
              <SiteBrokenIcon />
            </Grid>
          </StyledErrorBountry>
        </StyleGuide>
      )
    }
    return this.props.children
  }
}
