import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from '../../Common/button'
import useWindowSize from '../../Hooks/useWindowSize'
import { useHistory } from 'react-router-dom'
import { buildTestID, formatPhoneNumberNormalize } from "../../Utils/utils";
import { CONSENT } from ".";
import { Col, Row } from "react-bootstrap";

const StyledNumberReplacement = styled.div`
h1, h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }
  
  p {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 20px;
    // background: #fafafa;
  }

  input.form-control {
    height: 38px;
  }

  div.col:nth-child(even) {
    text-align: center;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .number-replacement {
      background-color: #fff;
      padding: 15px;
      margin: 32px auto 0;
      width: 600px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      // text-align: center;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // padding: 24px;
      // background: #FAFAFA;
      font-size: 16px;
      // font-weight: 700;
      // margin: 25px 35px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .button  {
        width: 230px !important;
      }
 }
  `

const NumberReplacement = ({ setView, primaryPhone, phoneNumber, setPhoneNumber, mainRef }) => {
  const [loading, setLoading] = useState(false)
  const [nextButtonDisabled, setnextButtonDisabled] = useState(true)
  const { isSmall } = useWindowSize()
  const history = useHistory()

  const handleClick = (e) => {
    const checked = e.target.checked;
    checked ? setnextButtonDisabled(false) : setnextButtonDisabled(true)
  }

  return (
    <StyledNumberReplacement>
      <div className="number-replacement"
        data-gridcolumnlayout={{
          xl: '1/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef} className="mb-3">Did you Mean to Change your Phone Number on file?</h1>
        <p>You entered a Mobile Number that is different than the one we have on file. Should we use the number on file, or the new number you entered as your Mobile Number going forward?</p>
        <hr />
        <Row className="text-center">
            <Col><label>Number on File</label></Col>
            <Col><label>Number you entered</label></Col>
        </Row>
        <Row className="text-center">
            <Col><label>{formatPhoneNumberNormalize(primaryPhone.phoneNumber)}</label></Col>
            <Col><label>{formatPhoneNumberNormalize(phoneNumber)}</label></Col>
        </Row>
        <div className="buttons">
        <Button
            className="button"
            text={'Use the number on file'}
            type="submit"
            fullWidth
            UID="consent_submit"
            aria-disabled={nextButtonDisabled}
            loading={loading}
            onClick={() => { setPhoneNumber(primaryPhone.phoneNumber); setView(CONSENT)}}
          />
          <Button
            className="button"
            text={'Replace the number on file'}
            type="submit"
            fullWidth
            UID="consent_submit"
            aria-disabled={nextButtonDisabled}
            loading={loading}
            onClick={() => {setView(CONSENT)}}
          />
        </div>
      </div>
    </StyledNumberReplacement>
  )
}

export default NumberReplacement;