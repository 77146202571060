import React from 'react'
import Styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Title from '../Common/Title'
import ScrollUpButton from '../Common/ScrollUpButton'

const StyledPrivacyPolicy = Styled.div`
  margin-top: 80px;
  margin-bottom: 100px;
  font-size: 20px;

.title {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 800px;
}

.question {
  margin-bottom: 20px;
}

p {
  margin-bottom: 40px;
}

a {
  color: ${({ theme }) => theme.colors.primary};
}
`

const PrivacyPolicy = () => {
  return (
    <>
      <Title>Privacy Policy</Title>
      <StyledPrivacyPolicy>
        <main id="main" tabIndex="0">
          <Container>
            <div className="title">
              <h2>Privacy Policy</h2>
            </div>
            <div>
              <p>Effective: December 16th, 2019 </p>
              <p className="question">
                <strong>
                  Celink/Reverse Mortgage Servicing Department Online
                  Privacy Policy
                </strong>
              </p>
              <p>
                At Celink/Reverse Mortgage Servicing Department
                (“Celink”, “we”, “our”, “us”), we respect your right
                to privacy and understand the importance of
                maintaining the security of your personal information.
                This Online Privacy Policy (“Policy”) only relates to
                the information we collect online through{' '}
                <a href="https://www.reversedepartment.com">
                  https://www.reversedepartment.com
                </a>{' '}
                along with our online servicing platform
                (collectively, the “Sites”). We ask that you read this
                Policy carefully. By using any of the Sites, you
                consent to this Policy.
              </p>
              <p>
                This Policy may be revised or modified at any time
                without notice to you, and any updates to this Policy
                will be posted on this page with the updated effective
                date appearing at the top of the page. Your use of the
                Site(s) following these changes means that you accept
                the revised Policy.
              </p>
              <p>
                This statement is in addition to Celink’s Information
                Sharing and Protection Practices, available at{' '}
                <a href="https://www.celink.com/privacy-policy/index.html">
                  https://www.celink.com/privacy-policy
                </a>
                . We will use and share any information that we
                collect from or about you in accordance with the
                Information Sharing and Protection Practices.{' '}
              </p>
              <p className="question">
                <strong>
                  What types of information do we collect about you?{' '}
                </strong>
              </p>
              <p>
                When you visit our Sites, you may be asked to provide
                information about you that is personally identifiable
                (“Personal Information”). This may include:{' '}
              </p>
              <ul>
                <li>Your first and last name;</li>
                <li>
                  A home, mailing and/or physical address (including
                  street name or name of a city or town);
                </li>
                <li>An e-mail address;</li>
                <li>A telephone number;</li>
                <li>Date of birth;</li>
                <li>A tax identification number; </li>
                <li>
                  Your third party bank account information used for
                  ACH transactions; and
                </li>
                <li>
                  Any other identifier that permits physical or online
                  contact with you.{' '}
                </li>
              </ul>
              <p>
                In addition, we may collect and store the following
                information about your visit: the internet protocol
                (“IP”) address from which you access our Site(s); the
                type of browser and operating system used to access
                our Site(s); the date and time you access our Site(s);
                the Internet address of the site from which you linked
                directly to our Site(s); and the pages you visit and
                the information you request.
              </p>
              <p className="question">
                <strong>
                  Will we share this information with third parties?
                </strong>
              </p>
              <p>
                Please see the{' '}
                <a href="https://www.celink.com/privacy-policy/index.html">
                  https://www.celink.com/privacy-policy
                </a>{' '}
                for information on how we may share your Personal
                Information.
              </p>

              <p className="question">
                <strong>How do we use Analytics?</strong>
              </p>
              <p>
                We use third-party analytics tools to better
                understand how users are visiting and using our Sites.
                We may use such third-party analytics tools for our
                internal business purposes, such as to understand your
                use of the Sites and your interactions with us,
                improve our Sites, identify usage trends and determine
                the effectiveness of our communications and website
                tools.
              </p>
              <p className="question">
                <strong>Do we use third-party advertisers? </strong>
              </p>
              <p>
                We do not currently use third parties to advertise our
                Sites, but we reserve our rights to do so in the
                future. If we choose to partner or associate with any
                third-party advertisers, we may amend our Policy. Any
                updates to our Policy will be posted on this page and
                are effective at the time of posting. How do we use
                cookies?{' '}
              </p>
              <p className="question">
                <strong>How do we use cookies? </strong>
              </p>
              <p>
                We employ the use of “Cookies.” Cookies are small
                files containing limited data that are created when
                you access our Sites and that can be accessed by our
                Sites to enhance your use of our Sites and its
                functionality. We use cookies to provide you with an
                improved and more personalized online service. Also,
                without revealing your identity, cookies help us
                confirm your identity and help us keep your
                transactions with us secure when you transact with us
                online. We also use cookies for various administrative
                purposes such as maintaining continuity during an
                online servicing system session or gathering data
                about the use of our Sites. This information helps us
                inform you about additional features or services that
                may help us serve you better.{' '}
              </p>
              <p>
                We do not use or associate personally identifiable
                information (such as your name, address or email
                address) with cookies. The information gathered is not
                shared with third parties. You may elect to set your
                web browser to inform you when cookies are set or turn
                off cookies by adjusting the appropriate settings on
                your browser. Please consult the HELP menu of your
                browser to learn how to turn your cookies off. Cookies
                do not reveal personal information and if you turn off
                your cookies you may find your use of the internet or
                our Sites is impaired or otherwise limited and some
                online servicing system functions may not work
                properly.
              </p>
              <p>
                Some of our business partners or associates may use
                cookies on our site (for example, advertisers).
                However, we have no access to or control over these
                cookies. Google may also use cookies to serve
                advertisements to you based on your past visit(s) to
                our Sites. You may opt out of Google’s use of cookies
                by visiting Google’s{' '}
                <a href="https://www.google.com/settings/ads">
                  https://www.google.com/settings/ads
                </a>{' '}
                or opt-out of a third party vendor’s use of cookies by
                visiting{' '}
                <a href="https://optout.networkadvertising.org/?c=1">
                  https://optout.networkadvertising.org/?c=1
                </a>{' '}
                . More information about how Google uses cookies is
                available at{' '}
                <a href="https://policies.google.com/privacy">
                  https://policies.google.com/privacy
                </a>{' '}
                and{' '}
                <a href="https://policies.google.com/technologies/ads">
                  https://policies.google.com/technologies/ads
                </a>
                .{' '}
              </p>
              <p className="question">
                <strong>
                  What do we do when your web browser sends a “Do Not
                  Track” signal?{' '}
                </strong>
              </p>
              <p>
                We do not acknowledge “Do Not Track” signals received
                from a user’s browser.{' '}
              </p>
              <p className="question">
                <strong>
                  How do we protect your information, confidentiality
                  and security?{' '}
                </strong>
              </p>
              <p>
                Celink is committed to protecting your personal
                information. We maintain physical, electronic, and
                procedural safeguards that comply with federal
                standards to guard your Personal Information.{' '}
              </p>
              <p className="question">
                <strong>
                  How can you make sure your information is accurate?
                </strong>
              </p>
              <p>
                Keeping your account information accurate and updated
                is very important. If you are a registered online
                servicing system user, you may review your information
                by logging into the online servicing system platform
                and selecting the <strong>“My Loan”</strong> tab.{' '}
              </p>
              <p>
                This website is not enabled to permit you to change
                your information at this time. If you wish to update
                your information, please call us or send in a written,
                signed request by fax, email or mail. Our contact
                information is available here:{' '}
                <Link to="/contact">
                  https://www.reversedepartment.com/contact
                </Link>
                .{' '}
              </p>
              <p className="question">
                <strong>
                  Children’s Online Privacy Protection Act Disclosure{' '}
                </strong>
              </p>
              <p>
                The Children’s Online Privacy Protection Act of 1998
                (“COPPA”) restricts the collection, use, or disclosure
                of Personal Information from and about children under
                the age of 13 on the internet. Celink is committed to
                protecting the online privacy of the children who
                visit our Sites and complying with COPPA.{' '}
              </p>
              <p>
                In general, our Sites are not directed to children
                under the age of 13 and we request that children under
                the age of 13 not provide Personal Information through
                the Sites. We do not knowingly collect information
                from children under 13 without parental consent. We
                may collect information on domain names, dates and
                times of visits, and number of page views. This
                information contains no Personal Information and will
                only be used to continue to improve the overall value
                of our Sites. Please visit the Federal Trade
                Commission website for more information about COPPA at
                the link below:{' '}
                <a href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule">
                  https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule
                </a>
                .{' '}
              </p>
              <p className="question">
                <strong>Links to other sites </strong>
              </p>
              <p>
                Our Site(s) may contain links to other sites. Please
                be aware that we are not responsible for the content
                or privacy practices of such other sites. We encourage
                our users to be aware when they leave our Site(s) and
                to read the privacy statements of any other site that
                collects personally identifiable information.{' '}
              </p>
              <p className="question">
                <strong>Questions? </strong>
              </p>
              <p>
                Please contact us with questions about this Policy at
                1-866-654-0020 or write to us at: <br />
                Celink/Reverse Mortgage Servicing Department <br />
                P.O. Box 40724 <br />
                Lansing, MI 48901-7924 <br />
              </p>
            </div>
          </Container>
          <ScrollUpButton />
        </main>
      </StyledPrivacyPolicy>
    </>
  )
}

export default PrivacyPolicy
