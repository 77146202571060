import React from 'react'

import AuthLayout from '../Components/AuthLayout'
import LandingPage from '../Components/LandingPage'

const LoadingPageContainer = () => {
  return (
    <AuthLayout>
      <LandingPage />
    </AuthLayout>
  )
}

export default LoadingPageContainer
