import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import Nav from './Nav'
import LoginSettings from './LoginSettings'
import GoPaperless from './GoPaperless'
import BorrowerDetails from './BorrowerDetails'
import BannerBar from '../../Common/BannerBar'
import useWindowSize from '../../Hooks/useWindowSize'
import Button from '../../Common/button'
import { useFocus } from '../../Hooks/useFocus'
import ESignConsent from './ESignConsent'

const StyledAccountSettings = styled.div`
  padding-bottom: 72px;
  min-height: 900px;

  .scroll {
    position: absolute;
    margin-top: -182px;
  }

  .fixed_cell {
    position: sticky;
    height: 280px;
    top: 112px;
  }

  .static_nav_on_banner_open {
    margin-top: -92px;
  }

  .banner {
    margin-top: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;

    .fixed_cell {
      position: unset;
      height: unset;
    }

    .back_button {
      display: flex;
      margin: 40px 0 0 0;
      justify-content: start;

      :focus {
        color: ${({ theme }) => theme.colors.primary};
        background: none;
        outline: 2px solid #1460AA;
      }

      :focus-visible {
        color: ${({ theme }) => theme.colors.primary};
        background: none;
        outline: 2px solid #1460AA;
      }
    }

    .banner {
      margin: 24px 0 0 0;
    }
  }
`

// All Mobile screens are represented below (modals on desktop)
// As they are ubiquitous they are stored here to ensure consistency
export const NAV = 'NAV'
// sections
export const LOGIN_SETTINGS = 'LOGIN_SETTINGS'
export const GO_PAPERLESS = 'GO_PAPERLESS'
export const ESIGN_CONSENT = 'ESIGN_CONSENT'
export const BORROWER_DETAILS = 'BORROWER_DETAILS'
// sub-sections (modals on desktop)
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const STATEMENT_PREFERENCE = 'STATEMENT_PREFERENCE'
export const STATEMENT_PREFERENCE_LEGAL = 'STATEMENT_PREFERENCE_LEGAL'

export const ESIGN_CONSENT_PREFERENCE = 'ESIGN_CONSENT_PREFERENCE'

const initialBannerState = { text: '', type: '' }

const AccountSettings = ({
  paperlessIndicator,
  paperlessChangeDate,
  refreshLoanData,
  borrower,
}) => {
  const [currentScreen, setCurrentScreen] = useState(NAV)
  const [openBanner, setOpenBanner] = useState(initialBannerState)

  let bannerisOpen = Boolean(openBanner.text)

  const loginSettingsRef = useRef(null)
  const goPaperlessRef = useRef(null)
  const eSignConsentRef = useRef(null)
  const borrowerDetailsRef = useRef(null)

  const { isSmall } = useWindowSize()

  useEffect(() => {
    if (bannerisOpen)
      setTimeout(() => setOpenBanner(initialBannerState), 5000)

    if (currentScreen === NAV) setOpenBanner(initialBannerState)
  }, [bannerisOpen, currentScreen])

  const backButtonRef = useRef(null)
  const { focus: focusOnBackButton } = useFocus(backButtonRef, false)

  useEffect(() => {
    if (
      isSmall &&
      (currentScreen === LOGIN_SETTINGS ||
        currentScreen === GO_PAPERLESS)
    ) {
      focusOnBackButton()
    }
  })


  return (
    <StyleGuide>
      <StyledAccountSettings>
        <Grid>
          {isSmall &&
            (currentScreen === LOGIN_SETTINGS ||
              currentScreen === GO_PAPERLESS ||
              currentScreen === ESIGN_CONSENT ||
              currentScreen === BORROWER_DETAILS) ? (
            <Button
              forwardRef={backButtonRef}
              className="back_button"
              UID="account_settings"
              text="Back to My Account"
              textButton
              backArrow
              onClick={() => setCurrentScreen(NAV)}
            />
          ) : null}
          <BannerBar
            show={Boolean(openBanner.text && openBanner.type)}
            aria-hidden={!Boolean(openBanner.text && openBanner.type)}
            className="banner"
            text={openBanner.text}
            type={openBanner.type}
            data-gridcolumnlayout={{
              xl: '5/14',
              lg: '4/14',
              md: '5/14',
            }}
          />
          <Nav
            cellclassname={`fixed_cell ${openBanner.text ? 'static_nav_on_banner_open' : ''
              }`}
            loginSettingsRef={loginSettingsRef}
            goPaperlessRef={goPaperlessRef}
            eSignConsentRef={eSignConsentRef}
            borrowerDetailsRef={borrowerDetailsRef}
            bannerVisible={bannerisOpen}
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
            data-gridcolumnlayout={{
              xl: '2/5',
              lg: '1/4',
              md: '1/5',
              sm: '1/9',
              xs: '1/5',
            }}
          />
          <LoginSettings
            scrollRef={loginSettingsRef}
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
            setOpenBanner={setOpenBanner}
            data-gridcolumnlayout={{
              xl: '5/14',
              lg: '4/14',
              md: '5/14',
            }}
          />
          <GoPaperless
            refreshLoanData={refreshLoanData}
            paperlessIndicator={paperlessIndicator}
            paperlessChangeDate={paperlessChangeDate}
            scrollRef={goPaperlessRef}
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
            setOpenBanner={setOpenBanner}
            data-gridcolumnlayout={{
              xl: '5/14',
              lg: '4/14',
              md: '5/14',
            }}
          />
          <ESignConsent
            signatureId={borrower.signatureId}
            email={borrower.email}
            eSignExpiredDate={borrower.eSignExpiredDate}
            scrollRef={eSignConsentRef}
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
            setOpenBanner={setOpenBanner}
            data-gridcolumnlayout={{
              xl: '5/14',
              lg: '4/14',
              md: '5/14',
            }}
          />
          <BorrowerDetails
            borrower={borrower}
            scrollRef={borrowerDetailsRef}
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
            setOpenBanner={setOpenBanner}
            data-gridcolumnlayout={{
              xl: '5/14',
              lg: '4/14',
              md: '5/14',
            }}
          />
        </Grid>
      </StyledAccountSettings>
    </StyleGuide>
  )
}

AccountSettings.propTypes = {
  paperlessIndicator: PropTypes.bool.isRequired,
  refreshLoanData: PropTypes.func.isRequired,
  borrower: PropTypes.object.isRequired,
  paperlessChangeDate: PropTypes.string.isRequired,
}

export default AccountSettings
