import React, { useState, useEffect } from 'react'
import Styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import ChangePasswordModal from './modal/ChangePassword'
import ConfirmEmailModal from './modal/ConfirmEmail'
import useWindowSize from '../../Hooks/useWindowSize'
import { trackChangePassword } from '../../Global/Analytics'
import { getUser } from '../../services/auth'
import {
  CHANGE_PASSWORD,
  CONFIRM_EMAIL,
  LOGIN_SETTINGS,
} from './index'
import { EnableMfa } from './EnableMfa'

const StyledLoginSettings = Styled.div`
width: 100%;
padding: 32px;
margin: 32px 0 0 0;
box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
border-radius: 4px;
display: flex;
flex-direction: column;

.heading {
  margin: 0;
}

.sub_heading {
  margin: 16px 0 24px 0;
}

.group_title {
  margin: 0 0 12px 0;
}

.edit_group {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;

  button {
    text-align: end;
    margin-left: 24px;
  }
}

.email {
  margin-bottom: 24px;
  overflow-wrap: break-word;
}

p {
  margin: 0;
}

.change_password_button {
  >button {
    &:focus-visible {
      outline: 2px solid #1460AA;
    }
  }
}


@media (max-width: ${({ theme }) => theme.breakPoints.small}) {
  box-shadow: none;
  margin-top: 24px;
  padding: 0;

  .edit_group {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    
    .change_password_button, .enable_mfa_button {
      width: 100%;
      margin-top: 24px;
      
      button {
        text-align: center;
        margin-left: 0;
        line-height: 20px;
      }
    }
  }
}
`

const LoginSettings = ({
  scrollRef,
  currentScreen,
  setCurrentScreen,
  setOpenBanner,
}) => {
  const [userEmail, setUserEmail] = useState('')

  const { isSmall } = useWindowSize()

  useEffect(() => {
    const getUserEmail = async () => {
      setUserEmail((await getUser()).attributes['email'])
    }

    getUserEmail()
  }, [])

  return (
    <>
      <ConfirmEmailModal
        show={currentScreen === CONFIRM_EMAIL}
        closeScreen={() => setCurrentScreen(LOGIN_SETTINGS)}
        openChangePasswordScreen={() =>
          setCurrentScreen(CHANGE_PASSWORD)
        }
        userEmail={userEmail}
      />
      <ChangePasswordModal
        show={currentScreen === CHANGE_PASSWORD}
        closeScreen={() => setCurrentScreen(LOGIN_SETTINGS)}
        setOpenBanner={setOpenBanner}
        userEmail={userEmail}
      />
      {currentScreen === LOGIN_SETTINGS || !isSmall ? (
        <StyledLoginSettings id="login_settings">
          <div ref={scrollRef} className="scroll" />
          <h2
            className="heading-6 heading"
            role={currentScreen === LOGIN_SETTINGS ? 'alert' : null}
          >
            Login Settings
          </h2>
          <p className="subtitle-2 sub_heading">
            Change the security settings associated with logging into
            your online account.
          </p>
          <h3 className="subtitle-2 bold group_title">Email:</h3>
          <p className="subtitle email">{userEmail}</p>
          <h3 className="subtitle-2 bold group_title">Password:</h3>
          <div className="edit_group">
            <p className="subtitle-2">
              Set a unique password to protect your online account.
            </p>
            <Button
              className="change_password_button"
              text="Change My Password"
              onClick={() => {
                trackChangePassword.Started()
                setCurrentScreen(CONFIRM_EMAIL)
              }}
              UID="login_settings"
              textButton={isSmall ? false : true}
              fullWidth={isSmall ? true : false}
            />
          </div>
          <EnableMfa />
        </StyledLoginSettings>
      ) : null}
    </>
  )
}

LoginSettings.propTypes = {
  scrollRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  currentScreen: PropTypes.string.isRequired,
  setCurrentScreen: PropTypes.func.isRequired,
  setOpenBanner: PropTypes.func.isRequired,
}

export default LoginSettings
