import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Button from '../Common/button'

const StyledLeavingSiteModal = styled(Modal)`
  .header {
    margin: 32px 32px 20px 32px;
    text-align: left;
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: ${({ theme }) => theme.colors.black87};
  }

  .body {
    margin: 0 32px 20px 32px;
  }

  .body-text {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.black87};
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0 32px 36px 32px;

    .right-button {
      margin-left: 40px;
    }
  }
`

const LeavingSiteModal = ({ onHide, show, linkTo }) => {
  return (
    <StyledLeavingSiteModal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="header">
        <h6 className="title">Leaving site</h6>
      </div>
      <div className="body">
        <p className="body-text">
          You are leaving reversedepartment.com. Click OK to continue.
        </p>
      </div>
      <div className="buttons">
        <Button
          linkTo="#"
          onClick={onHide}
          textButton
          text="STAY"
          UID="leave_site_modal"
        />
        <Button
          className="right-button"
          onClick={() => { onHide(); window.open(linkTo, '_blank') }}
          textButton
          text="OK"
          UID="leave_site_modal"
        />
      </div>
    </StyledLeavingSiteModal>
  )
}

LeavingSiteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default LeavingSiteModal
