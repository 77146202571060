import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ScrollUpButton from 'react-scroll-up-button'
import styled from 'styled-components'
import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import WelcomeSection from './WelcomeSection.js'
import OnlineToolsSections from './OnlineToolsSection.js'
import LoanDetailsSection from './LoanDetailsSection.js'
import RecentTransactionsSection from './RecentTransactionsSection.js'
import NewLoanModal from './NewLoanModal'
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from '../../Utils/utils'
import InfoCard from '../../Common/InfoCard'
import PiggyBankIcon from '../../assets/piggy-bank-icon.js'
import CertificationOccupancyBanner from './CertificationOccupancyBanner'
import OccupancyIcon from '../../assets/occupancy-icon'
import { buildTestID } from '../../Utils/utils'
import { get, list } from '../../services/file'
import Button from '../../Common/button'
import dayjs from 'dayjs'
import useWindowSize from '../../Hooks/useWindowSize'

const StyledCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 18px;

  .view-document {
    justify-content: start;
  }

  .green {
    color: #2E7D32;
  }

  .item {
    flex-basis: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    flex-direction: row;
  }
`

const MyLoan = ({ loan, borrower, transactions, clientRefiInfo, summaryResponse, certification }) => {
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false)
  const [occupancyFile, setOccupancyFile] = useState()
  const { isSmall } = useWindowSize()

  useEffect(() => {
    const getOccupancy = async () => {
      const data = await list(`occupancy/${loan.accountNumber}`)

      if (data.length)
        setOccupancyFile(data[data.length - 1])
    }

    getOccupancy()
  }, [loan.accountNumber])

  const handleClick = async () => {
    const data = await get(occupancyFile.key)
    window.open(data)
  }

  return (
    <StyleGuide>
      <Grid>
        <div
          data-gridcolumnlayout={{
            xl: '3/13',
            lg: '1/13',
            md: '1/14',
            sm: '1/9',
          }}

          style={{ "marginTop": "60px" }}
        >
          <NewLoanModal
            show={welcomeModalOpen}
            closeScreen={() => setWelcomeModalOpen(false)}
          />
          <CertificationOccupancyBanner loan={loan} summaryResponse={summaryResponse} />
          <WelcomeSection loan={loan} borrower={borrower} />
          <StyledCards>
            <InfoCard
              className='item'
              title="Thinking of refinancing?"
              SVG={PiggyBankIcon}
              paragraph={
                isValidPhoneNumber(clientRefiInfo) ? (
                  <>
                    If you are interested in refinancing your reverse
                    mortgage loan, please call us at{' '}
                    <a
                      className="caption phone-web-link"
                      href={`tel:+1-${formatPhoneNumber(
                        clientRefiInfo,
                      )}`}
                    >
                      {formatPhoneNumber(clientRefiInfo)}
                    </a>{' '}
                    to learn more. Refer to code PORTAL when you call.
                  </>
                ) : (
                  <>
                    If you are interested in refinancing your reverse
                    mortgage loan, please visit{' '}
                    <a
                      className="caption phone-web-link"
                      href="https://www.reversemortgage.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.reversemortgage.org
                    </a>{' '}
                    to learn more.
                  </>
                )
              }
            />
            <InfoCard
              className='item'
              title="Annual Occupancy Certification"
              SVG={OccupancyIcon}
              paragraph={
                <>
                  {certification.occupancyReceivedFlag.toLowerCase() === 'yes' && certification.occupancyCertNextDueDate !== '' && dayjs(certification.occupancyCertNextDueDate).isAfter(dayjs(new Date())) &&
                    <>
                      <span className='green'>Completed</span> on {dayjs(certification.occupancyCertReceivedDate).format('MM/DD/YYYY')}<br />
                    </>
                  }
                  {certification.occupancyReceivedFlag.toLowerCase() === 'yes' && certification.occupancyCertNextDueDate === '' && dayjs(certification.occupancyCertReceivedDate).add(1, 'y').format('MM/YYYY') !== dayjs(new Date()).format('MM/YYYY') &&
                    <>
                      <span className='green'>Completed</span> on {dayjs(certification.occupancyCertReceivedDate).format('MM/DD/YYYY')}<br />
                    </>
                  }
                  Next Due Date: {certification.occupancyCertNextDueDate !== '' ? dayjs(certification.occupancyCertNextDueDate).format('MM/DD/YYYY') : dayjs(certification.occupancyCertReceivedDate).add(1, 'y').format('MM/YYYY')}<br />
                  {occupancyFile != null &&
                    <Button
                      className='view-document'
                      text="View Document"
                      textButton
                      UID='view_occupancy_document'
                      onClick={handleClick}
                      data-testid={buildTestID('view_document', '/my-loan', '')}
                    />
                  }
                </>
              }
            />
          </StyledCards>
          <OnlineToolsSections loanType={loan.loanType} />
          <LoanDetailsSection loan={loan} />
          <RecentTransactionsSection transactions={transactions} />
        </div>
      </Grid>
      <ScrollUpButton />
    </StyleGuide>
  )
}

MyLoan.propTypes = {
  loan: PropTypes.object.isRequired,
  borrower: PropTypes.object.isRequired,
  transactions: PropTypes.array.isRequired,
  clientRefiInfo: PropTypes.string.isRequired,
}

export default MyLoan
