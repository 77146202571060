import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme }) => theme.colors.black60};
  }
`

const AccountBalanceIcon = () => {
  return (
    <StyledSVG
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 10.5001V14.5001C2 15.3301 2.67 16.0001 3.5 16.0001C4.33 16.0001 5 15.3301 5 14.5001V10.5001C5 9.67007 4.33 9.00007 3.5 9.00007C2.67 9.00007 2 9.67007 2 10.5001ZM8 10.5001V14.5001C8 15.3301 8.67 16.0001 9.5 16.0001C10.33 16.0001 11 15.3301 11 14.5001V10.5001C11 9.67007 10.33 9.00007 9.5 9.00007C8.67 9.00007 8 9.67007 8 10.5001ZM1.5 21.0001H17.5C18.33 21.0001 19 20.3301 19 19.5001C19 18.6701 18.33 18.0001 17.5 18.0001H1.5C0.67 18.0001 0 18.6701 0 19.5001C0 20.3301 0.67 21.0001 1.5 21.0001ZM14 10.5001V14.5001C14 15.3301 14.67 16.0001 15.5 16.0001C16.33 16.0001 17 15.3301 17 14.5001V10.5001C17 9.67007 16.33 9.00007 15.5 9.00007C14.67 9.00007 14 9.67007 14 10.5001ZM8.57 0.490068L0.67 4.65007C0.26 4.86007 0 5.29007 0 5.75007C0 6.44007 0.56 7.00007 1.25 7.00007H17.76C18.44 7.00007 19 6.44007 19 5.75007C19 5.29007 18.74 4.86007 18.33 4.65007L10.43 0.490068C9.85 0.180068 9.15 0.180068 8.57 0.490068Z" />
    </StyledSVG>
  )
}

export default AccountBalanceIcon
