import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const MagnifyingGlassIcon = ({ color = 'primary' }) => {
    return (
        <StyledSVG
            color={color}
            xmlns="http://www.w3.org/2000/svg"
            width="34" height="34" viewBox="0 0 34 34" fill="none">
            <g clip-path="url(#clip0_464_799)">
                <path d="M21.9583 19.8333H20.8392L20.4425 19.4508C21.8308 17.8358 22.6667 15.7392 22.6667 13.4583C22.6667 8.3725 18.5442 4.25 13.4583 4.25C8.3725 4.25 4.25 8.3725 4.25 13.4583C4.25 18.5442 8.3725 22.6667 13.4583 22.6667C15.7392 22.6667 17.8358 21.8308 19.4508 20.4425L19.8333 20.8392V21.9583L26.9167 29.0275L29.0275 26.9167L21.9583 19.8333ZM13.4583 19.8333C9.93083 19.8333 7.08333 16.9858 7.08333 13.4583C7.08333 9.93083 9.93083 7.08333 13.4583 7.08333C16.9858 7.08333 19.8333 9.93083 19.8333 13.4583C19.8333 16.9858 16.9858 19.8333 13.4583 19.8333Z" fill="#767676" />
            </g>
            <defs>
                <clipPath id="clip0_464_799">
                    <rect width="34" height="34" fill="white" />
                </clipPath>
            </defs>

        </StyledSVG>
    )
}

export default MagnifyingGlassIcon
