import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
    stroke: ${({ theme, color }) => theme.colors[color]};
  }
`

const VideoIcon = ({ color = 'primaryDark' }) => {
    return (
        <StyledSVG
            color={color}
            fill="none"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
            role="presentation"
            aria-labelledby="videoIconTitle"
            stroke="#00377A"
            stroke-width="1"
            stroke-linecap="square"
            stroke-linejoin="miter">
            <title id="videoIconTitle">Video</title>
            <polygon points="18 12 9 16.9 9 7" /> <circle cx="12" cy="12" r="10" />
        </StyledSVG>
    )
}

export default VideoIcon
