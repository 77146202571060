import React, { useEffect } from 'react'
import Styled from 'styled-components'

import { getPasswordValidationList } from '../../services/auth'
import ErrorIcon from '../../assets/error-icon'
import CheckIcon from '../../assets/check-circle-icon'

const StyledPasswordValidation = Styled.div`
li {
  margin-bottom: 6px;
}
`

const PasswordValidation = ({
  password,
  confirmPassword,
  setIsPasswordValid,
  showConfirmPassword,
  className
}) => {
  const passwordValidationList = getPasswordValidationList(
    password,
    confirmPassword,
    showConfirmPassword,
  )

  useEffect(() => {
    if (setIsPasswordValid) {
      if (!passwordValidationList.some((x) => !x.success))
        setIsPasswordValid(true)
      else setIsPasswordValid(false)
    }
  }, [
    password,
    confirmPassword,
    passwordValidationList,
    setIsPasswordValid,
  ])

  return (
    <StyledPasswordValidation className={className}>
      <p className="body2">Passwords must have:</p>
      <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
        {passwordValidationList.map(({ success, message }) => (
          <li key={message}>
            {success ? (
              <CheckIcon color="success" />
            ) : (
              <ErrorIcon color="black34" />
            )}{' '}
            {message}
          </li>
        ))}
      </ul>
    </StyledPasswordValidation>
  )
}

export default PasswordValidation
