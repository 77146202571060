import { useState, useEffect } from 'react'

import { retrievePayOffEligibility, getLoan } from '../services/api'

import { extractLoan } from '../Utils/apiExtractors'

export const useRetrievePayOffEligibility = () => {
  const [payOffEligibility, setPayOffEligibility] = useState({
    loading: true,
  })

  useEffect(() => {
    const getPayOff = async () => {
      const user = localStorage.getItem('ajs_user_traits');
      if(!user) return;
      
      let payoffData = null
      let error = null
      try {
        const loan = extractLoan(await getLoan())

        const loanNumber = loan.loanId
        const state = loan.property.state

        payoffData = await retrievePayOffEligibility(loanNumber)

        if (state === 'OR' || state === 'NV') {
          payoffData.data.getPayoffEligibility.info.eligible = false
        }
      } catch (err) {
        console.log('err', err)
        error = err
      } finally {
        setPayOffEligibility({
          data: payoffData && payoffData.data.getPayoffEligibility.info || null,
          error,
          loading: false,
        })
      }
    }

    getPayOff()
  }, [])

  return payOffEligibility
}