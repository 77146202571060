import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import StyleGuide from '../../Global/StyleGuide'
import WelcomeGif from '../../assets/welcom.gif'
import CloseIcon from '../../assets/close-icon'
import { buildTestID } from '../../Utils/utils'
import useWindowSize from '../../Hooks/useWindowSize'
import Button from '../../Common/button'

const StyledNewLoanModal = styled(Modal)`
  display: flex;
  flex-direction: column;

  .modal-content {
    padding: 24px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
    min-width: min-content;

    .header {
      display: flex;
      justify-content: space-between;
      .title {
        margin: 0 0 16px 0;
      }

      .close_icon {
        :hover {
          cursor: pointer;
        }
      }
    }

    .sub_heading {
      margin: 0 0 18px 0;
    }

    img {
      border: 1px solid ${({ theme }) => theme.colors.black12};
    }

    .button {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    padding: 24px 24px 0 0;
    background: white;
    position: fixed;
    z-index: 10;
    width: calc(100vw - 24px);
    height: 100vh;

    .header {
      .close_icon {
        display: none;
      }

      .title {
        margin: 0 0 16px 0;
      }
    }
    .sub_heading {
      margin: 0 0 18px 0;
    }

    img {
      border: 1px solid ${({ theme }) => theme.colors.black12};
      width: -webkit-fill-available;
    }

    .button {
      display: block;
      margin: 16px 0;
    }
  }
`

const NewLoanModal = ({ closeScreen, show }) => {
  const { isXSmall } = useWindowSize()

  let ModalOrDivProps = {
    onHide: closeScreen,
    show: show,
    centered: true,
    size: 'md',
    as: isXSmall ? 'div' : Modal,
    dialogClassName: 'modal_width',
  }

  if (isXSmall) delete ModalOrDivProps.onHide

  return show ? (
    <StyledNewLoanModal {...ModalOrDivProps}>
      <StyleGuide>
        <div className="header">
          <h3 className="title heading-6">
            Welcome to your new loan
          </h3>
          <div
            className="close_icon"
            onClick={closeScreen}
            data-testid={buildTestID(
              'close_icon',
              'my_loan',
              'welcom_modal',
            )}
          >
            <CloseIcon />
          </div>
        </div>
        <h6 className="subtitle-2 sub_heading">
          Congratulations on your new reverse mortgage loan! You now
          have access to your loan information on this portal. You can
          still access Statements on your prior loan(s) as shown
          below:
        </h6>
        <img
          src={WelcomeGif}
          alt="gif of how to access old statements this can be done by 
          navigating to the statments page and changing your selected loan"
        />
        <Button
          className="button"
          fullWidth
          text="Continue to My Loan"
          UID="confirm_email"
          onClick={closeScreen}
        />
      </StyleGuide>
    </StyledNewLoanModal>
  ) : null
}

NewLoanModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeScreen: PropTypes.func.isRequired,
}

export default NewLoanModal
