import React, { useEffect, useState } from 'react'
import Styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import useWindowSize from '../../Hooks/useWindowSize'
import {
    ENABLE_MFA,
    MULTIFACTOR_SUCCESS,
} from './index'
import { useHistory } from 'react-router-dom'
import { setCookie } from '../../Utils/utils'
import { Auth } from 'aws-amplify'

const StyledMultiFactorAuth = Styled.div`
    display: flex;
    width: 740px;
    padding: 30px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 20px;
    background: var(--Surface, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    margin-top: 32px;
    align-self: stretch;
    
    .heading {
      margin: 0;
    }
    
    p {
        color: var(--Text-Normal, #111);
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 122.222% */
    }
    
    button:not(.textButton){
      border-radius: 4px;
      background: var(--Primary, #074EE8);
      padding: 8px 12px;
    }
    
    .mt-20 {
      margin-top: 20px;
    }
    
    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      margin-left: 35px;
    }
`

const MultiFactorAuth = ({
    currentScreen,
    setCurrentScreen,
    completed,
    setAuthState,
    setSkip
}) => {
    const [showButton, setShowButton] = useState(false);
    const { isSmall } = useWindowSize()
    const history = useHistory()
    const isCompleted = completed.includes(MULTIFACTOR_SUCCESS)

    useEffect(() => {
        // Set a timer to display the button after 5 seconds
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 15000);

        // Clear the timer to avoid memory leaks
        return () => clearTimeout(timer);
    }, []);

    const handleGoToLoan = async(e) => {
        e.preventDefault()
        const user = await Auth.currentAuthenticatedUser()
        if (setSkip)
        setSkip(true)

        if (setAuthState)
        setAuthState('signedIn', { user, redirect: true })

       history.push('/my-loan')
    }

    return (
        <>
            {currentScreen === MULTIFACTOR_SUCCESS || !isSmall ? (
                <StyledMultiFactorAuth id="multi_factor_auth">
                    {isCompleted ? (
                        <>
                            <h2 className="heading-6 heading mb-3">You did it!</h2>
                            <p>By enabling Multifactor Authentication, you have improved the security of your personal information. </p>
                            <p>Going forward, each time you log in, we’ll send you a text message with a new code to confirm it’s you.</p>
                        </>
                    ) : (
                        <>
                            <h2 className="heading-6 heading mb-3">Enable Multifactor Authentication</h2>
                            <p>Enabling multifactor authentication is a great way to protect your account.</p>
                        </>
                    )
                    }
                    <div className="d-flex justify-content-between w-100 mt-20">
                        {!isCompleted && showButton ?
                            <Button
                                className="skip-for-now"
                                text="Skip for now"
                                textButton
                                UID="enable_mfa_skip_button"
                                linkTo={"/my-loan"}
                            /> : <span></span>
                        }
                        {!isCompleted ?
                            (<Button
                                className="enable_mfa_button"
                                text="Enable MFA"
                                onClick={() => {
                                    setCurrentScreen(ENABLE_MFA)
                                    setCookie("finish_your_account_mfa_enabled", "true", 1);
                                }} />) :
                            (<Button
                                className="enable_mfa_button"
                                text="Go to My Loan"
                                onClick={(e) => 
                                    handleGoToLoan(e)
                                }
                                />)}
                    </div>
                </StyledMultiFactorAuth>
            ) : null}
        </>
    )
}

MultiFactorAuth.propTypes = {
    scrollRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
    currentScreen: PropTypes.string.isRequired,
    setCurrentScreen: PropTypes.func.isRequired,
    setOpenBanner: PropTypes.func.isRequired,
    paperlessIndicator: PropTypes.bool.isRequired,
    refreshLoanData: PropTypes.func.isRequired,
}

export default MultiFactorAuth
