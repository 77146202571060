import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const DocumentIcon = ({ className, color = 'black60' }) => {
  return (
    <StyledSVG
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
    >
      <path
        fill="#5A5A5A"
        d="M10.59.59C10.21.21 9.7 0 9.17 0H2C.9 0 0 .9 0 2v16c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6.83c0-.53-.21-1.04-.59-1.41L10.59.59zM11 16H5c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H5c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zM9 6V1.5L14.5 7H10c-.55 0-1-.45-1-1z"
      ></path>
    </StyledSVG>
  )
}

export default DocumentIcon
