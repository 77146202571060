import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import Styled from 'styled-components'

import { refreshUser, getRandomLetters } from '../../services/auth'
import AuthContainer from './AuthContainer'
import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'

const StyledAddPhone = Styled.div`
.error-message {
  color: ${({ theme }) => theme.colors.error};
}

.resend-button {
  display: flex;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
`

const AddPhone = ({ setAuthState, authData }) => {
  const [phone, setPhone] = useState(
    localStorage.getItem('phone') || '',
  )
  const [error, setError] = useState('')
  const [randomLetters, setRandomLetters] = useState('')

  const setErrorForAda = (error) => {
    setRandomLetters(getRandomLetters(4))
    setError(error)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (phone.length !== 10)
        throw new Error('Phone number must be 10 digits')
      await Auth.updateUserAttributes(user, { phone_number: phone })
      await refreshUser()
      setAuthState('confirmPhone', user)
    } catch (err) {
      setErrorForAda(err.message)
    }
  }
  return (
    <StyledAddPhone>
      <AuthContainer header="Add Your Phone Number">
        <form
          data-testid="confirm-sign-in-body-section"
          onSubmit={handleSubmit}
        >
          <div className="Section__sectionBody___ihqqd">
            <div className="Form__formField___38Ikl">
              <div className="Input__inputLabel___3VF0S">
                <label htmlFor="phone">
                  <strong>Phone *</strong>
                </label>
              </div>
              <input
                placeholder="Phone"
                name="phone"
                autoComplete="off"
                data-testid={buildTestID(
                  'phone',
                  '/home',
                  'add_phone',
                )}
                className="Input__input___3e_bf"
                value={phone}
                onChange={(e) =>
                  setPhone(e.target.value.replace(/-/g, ''))
                }
                id="phone"
                aria-required="true"
                pattern="\d*"
                type="text"
              />
            </div>
          </div>
          <p role="alert">
            <span
              className="error-message"
              data-testid="add-phone-error"
            >
              {error}
              <span
                style={{ visibility: 'hidden' }}
                dangerouslySetInnerHTML={{ __html: randomLetters }}
              ></span>
            </span>
          </p>
          <div className="buttons">
            <Button
              onClick={() => setAuthState('signIn')}
              textButton
              text="Back to Sign In"
              small
              UID="add_phone"
            />
            <Button type="submit" text="Confirm" UID="add_phone" />
          </div>
        </form>
      </AuthContainer>
    </StyledAddPhone>
  )
}

export default AddPhone
