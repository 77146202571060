import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const PaymentCardComputerIcon = ({ className, color = 'black60' }) => {
    return (
        <StyledSVG
        width="110" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="68" cy="68" r="68" fill="#F8FBFF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35 43C32.7909 43 31 44.7909 31 47V83C31 85.2091 32.7909 87 35 87H57V92H50C49.4477 92 49 92.4477 49 93C49 93.5523 49.4477 94 50 94H78C78.5523 94 79 93.5523 79 93C79 92.4477 78.5523 92 78 92H71V87H80V79H33V47C33 45.8954 33.8954 45 35 45H93C94.1046 45 95 45.8954 95 47V60H97V47C97 44.7909 95.2091 43 93 43H35ZM66 83C66 84.1046 65.1046 85 64 85C62.8954 85 62 84.1046 62 83C62 81.8954 62.8954 81 64 81C65.1046 81 66 81.8954 66 83Z" fill="#475468"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M82 66C82 63.7909 83.7909 62 86 62H102C104.209 62 106 63.7909 106 66V96C106 98.2091 104.209 100 102 100H86C83.7909 100 82 98.2091 82 96V66ZM84 66C84 64.8954 84.8954 64 86 64H102C103.105 64 104 64.8954 104 66V96C104 97.1046 103.105 98 102 98H86C84.8954 98 84 97.1046 84 96V66ZM90 65C89.4477 65 89 65.4477 89 66C89 66.5523 89.4477 67 90 67H98C98.5523 67 99 66.5523 99 66C99 65.4477 98.5523 65 98 65H90Z" fill="#475468"/>
        
        </StyledSVG>
    )
}

export default PaymentCardComputerIcon
