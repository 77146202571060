import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import StyleGuide from '../../../Global/StyleGuide'
import Button from '../../../Common/button'
import useWindowSize from '../../../Hooks/useWindowSize'
import { trackGoPaperless } from '../../../Global/Analytics'
import { buildTestID } from '../../../Utils/utils'
import { updateStatementPreference } from '../../../services/api'

const StyledStatementPreference = styled(Modal)`
  .modal-content {
    padding: 32px 32px 0 32px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
    max-height: 70vh;
    overflow-y: auto;
  }

  .modal-width {
    max-width: 450px !important;
  }

  .title {
    margin: 0 0 24px 0;
  }

  .error_text {
    color: ${({ theme }) => theme.colors.error};
    margin: 0 0 24px 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 32px;
    margin-bottom: 32px;

    .cancel {
      margin-right: 24px;
    }
  }

  ul {
    padding-left: 20px;
  }

  h5 {
    margin: 14px 0;
  }

  .consent_check {
    display: flex;
    align-items: center;

    .checkbox {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    .body2 {
      margin: 0;
    }
  }

  .button {
    width: 162px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 24px 0 0 0;

    .button {
      width: 100%;
    }

    .buttons {
      padding-bottom: 48px;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const StatementPreferenceLegal = ({
  closeScreen,
  show,
  setOpenBanner,
  refreshLoanData,
  setDeliveryOnline,
}) => {
  const [loading, setLoading] = useState(false)
  const [consentGiven, setConsentGiven] = useState(false)
  const [error, setError] = useState('')

  const { isSmall } = useWindowSize()

  const handleCloseScreen = () => {
    closeScreen()
    setConsentGiven(false)
    setError('')
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      const response = await updateStatementPreference('1')
      if (response.data.updatePaperless.responseCode !== 200)
        throw new Error('Error updating statement preference. Please try again.')

      setLoading(false)
      handleCloseScreen()
      setOpenBanner({
        type: 'success',
        text: 'You are now enrolled in Paperless Delivery.',
      })
      refreshLoanData()
      setDeliveryOnline(true)
      trackGoPaperless.Completed({ selected_go_paperless: true })
    } catch (e) {
      setLoading(false)
      console.log('error updating: ', e)
      setError('Network error. Please try again.')
    }
  }

  let ModalOrDivProps = {
    onHide: handleCloseScreen,
    show,
    centered: true,
    size: 'md',
    as: isSmall ? 'div' : Modal,
    dialogClassName: 'modal-width',
  }

  if (isSmall) delete ModalOrDivProps.onHide

  return show ? (
    <StyledStatementPreference {...ModalOrDivProps}>
      <StyleGuide>
        <h3 className="title heading-6">Delivery Preference</h3>
        <h4 className="subtitle-2 bold">
          Please review and accept the Electronic Consent Agreement.
        </h4>
        <p className="caption">
          (CONSENT AGREEMENT FOR USE OF ELECTRONIC DISCLOSURES,
          SIGNATURES, RECORDS, AND COMMUNICATIONS)
        </p>
        <p className="caption">
          Electronic Documents and Signatures - Consent Celink(also
          referred to here as “we” or “us”) is required or allowed to
          provide you with certain statements, written disclosures,
          documents and information pertaining to your transactions
          with us (“Required Information”).
        </p>
        <p className="caption">
          Under the federal E-SIGN Act and related state laws, we must
          obtain your consent to deliver Required Information to you
          electronically and to use electronic records and electronic
          signatures in connection with your transactions with us.
          Therefore, please review the terms of this Electronic
          Consent Agreement prior to giving your consent and retain a
          copy of this Electronic Consent Agreement for your records.
        </p>
        <p className="caption">
          If you do not want to receive the Required Information
          electronically, or do not want to use electronic signatures
          in connection with your transactions with us, you should not
          agree to the terms of this Electronic Consent Agreement.
        </p>
        <p className="caption">
          Your consent will apply to all your transactions with us,
          all accounts registered under your Social Security number
          and any Required Information we provide or make available to
          you. Your consent also permits the general use of electronic
          records and electronic signatures in connection with all
          your transactions with us. Celink reserves the right to deny
          or cancel providing any documents and other information
          electronically at any time.
        </p>
        <p className="caption">
          <b>Required Information You Will Receive Electronically.</b>
          By providing your consent under this Agreement, you agree
          that we may provide you with the following Required
          Information solely in electronic form:
        </p>
        <ul>
          <li className="caption">
            Any statements, privacy policies, contracts, documents, or
            disclosures required by law or provided by Celink or the
            holder of your loan, including, without limitation, any
            loan or insurance disclosures, documentation, and other
            transaction-related communications and disclosures. You
            will be notified at the e-mail address you have provided
            to us when one of these documents is available.
          </li>
        </ul>
        <p className="caption">
          Access to your electronic statement, privacy policy and
          other documents and disclosures that we provide you is
          through accessing your reversedepartment.com account. We may
          send you a hyperlink by e-mail to access your electronic
          statement or other documents and information, but you will
          need to log into your reversedepartment.com account to
          access any such documents or information.{' '}
          <b>
            IT IS YOUR RESPONSIBILITY TO PROTECT AND SAFEGUARD YOUR
            REVERSEDEPARTMENT.COM USERNAME AND PASSWORD AT ALL TIMES.
          </b>
        </p>
        <p className="caption">
          There may be certain notices or documents that by law, we
          are not permitted to provide you electronically, even with
          your consent. If required by law, we will deliver these
          notices or documents to you in paper format, unless we are
          permitted to deliver them electronically. Sometimes we may
          require, by law or for other reasons, that you give us a
          written notice or provide a “wet ink” signature. You must
          still provide these notices on paper or with a “wet ink”
          signature, unless we specifically tell you that you may
          deliver that notice to us electronically or with an
          e-signature.
        </p>
        <p className="caption">
          <b>
            Paper Copy of Required Information Available Upon Request
            - No Charge.
          </b>
          If you consent to receive the Required Information
          electronically, you can also request a paper copy of the
          Required Information by contacting us by phone at
          866-654-0020 or by sending an email request to
          borrower.care@reversedepartment.com. We will not charge you
          any fees for providing a paper copy of the Required
          Information. Your consent does not mean that Celink must
          provide the Required Information electronically - only that
          they may do so. We may, at our option, deliver Required
          Information on paper, if we choose to do so. Celink may also
          require that certain communications from you be delivered to
          us on paper at a specified address.
        </p>
        <p className="caption">
          <b>Withdrawing Your Consent.</b> You may withdraw your
          consent to receive Required Information electronically or to
          the use of electronic signatures in connection with any
          future transactions with us at any time. Any withdrawal of
          your consent will be effective only after we have a
          reasonable period of time to process your withdrawal, which
          may take up to 10 business days. We will not charge you any
          fees for withdrawing your consent. If you decide you do not
          want to receive Required Information electronically, or do
          not want to use electronic signatures in connection with any
          future transactions with us, you may withdraw your consent*
          by logging on to reversedepartment.com, going to [My
          Account], choosing [Paperless Settings] and changing
          settings under [Paperless Settings]. If you are on mobile,
          go to [navigation icon] in the top right of the screen,
          choose [Account Settings], go to [Paperless Settings] under
          [My Account] and change Paperless Settings. You may also
          revoke your consent by contacting us by phone at
          866-654-0020 or by sending a signed written request to the
          physical mailing address on your account statement. Celink
          may revoke your consent if your web access is blocked or if
          e-mail notifications are repeatedly returned as
          undeliverable. In the event Celink materially amends this
          consent agreement, you will be provided notice and will have
          the opportunity to revoke your consent.
        </p>
        <p className="caption">
          <b>
            System Requirements to Access the Required Information.
          </b>
          To access the Required Information being provided to you
          electronically, you must have an active
          reversedepartment.com account, active e-mail address,
          internet connection and compatible computing system/browser,
          which are as follows: Chrome, Edge, Mozilla Firefox or
          Safari.
        </p>
        <ul>
          <li>
            A personal computer or mobile device with internet access
            and one of the following operating system web browser
            combinations installed:
            <ul>
              <li>
                Microsoft Windows 7, 8, 8.1, 10 or higher with:
                <ul>
                  <li>Edge(version 86 or more recent)</li>
                  <li>Mozilla Firefox (version81 or more recent)</li>
                  <li>Google Chrome (version 86 or more recent)</li>
                </ul>
              </li>
              <li>
                Apple Mac OS X 10.9 (Mavericks) or higher with:
                <ul>
                  <li>Safari (version 14 or more recent)</li>
                  <li>Mozilla Firefox (version 81 or more recent)</li>
                  <li>Google Chrome (version 86 or more recent)</li>
                </ul>
              </li>
              <li>
                Android 7.0 (Nougat) or higher with:
                <ul>
                  <li>Google Chrome (version 86 or more recent)</li>
                </ul>
              </li>
              <li>
                Apple iOS 12.4.7 or higher with:
                <ul>
                  <li>Safari (version 14 or more recent)</li>
                  <li>Google Chrome (version 86 or more recent)</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            The reversedepartment.com site may use session cookies or
            other forms of temporary data storage while you are logged
            into your account. If these options are disabled, the site
            may not function properly. Please ensure your cookies,
            session Storage, and local Storage options in your browser
            are set to 'enabled'.
          </li>
          <li>
            The ability to receive e-mail that contains hyperlinks to
            Websites in order for us to deliver information to you.
            Please be sure to add noreply@reversedepartment.com to
            your “safe sender” list to prevent emails from going into
            your “junk” or “spam” folder.
          </li>
          <li>
            Software which permits you to receive, print and access
            Portable Document Format or "PDF" files, such as Adobe
            Acrobat Reader. If you do not have such software,
            visit https://get.adobe.com/reader/ to download a free
            version of Adobe Acrobat Reader.
          </li>
          <li>
            Sufficient electronic storage capacity on your computer's
            hard drive or other data storage unit or the ability to
            print PDF files from your computer or mobile device.
          </li>
        </ul>
        <p className="caption">
          <b>Electronic Communications:</b> We and our agents may
          contact you by e-mail for any reason including for account
          servicing or collections, to tell you about policies,
          requirements or services applicable to your loan or account
          from Celink or the holder of your loan, and for other
          business purposes.
        </p>
        <p className="caption">
          <b>
            Consent: I agree to be bound by the terms of this
            Electronic Consent Agreement and confirm that I have
            computer hardware and software that meets the requirements
            listed above. I also consent to receiving Required
            Information electronically and to the use of electronic
            records and signatures in connection with my
            transaction(s) with Celink in place of written (paper)
            documents and handwritten signatures.
          </b>
        </p>
        <h6 className="caption error_text" role="alert">
          {error}
        </h6>
        <div className="consent_check">
          <input
            className="checkbox"
            checked={consentGiven}
            type="checkbox"
            onChange={() => setConsentGiven(!consentGiven)}
            aria-label="Check here to consent"
            data-testid={buildTestID(
              'legal-modal',
              '/account-settings',
              'statement_preference_legal',
            )}
          />
          <label className="body2">Check here to consent</label>
        </div>
        <div className="buttons">
          <Button
            className="cancel button"
            text="Cancel"
            outlined
            onClick={handleCloseScreen}
            UID="statement_preference_legal"
            fullWidth
          />
          <Button
            className="button"
            text={loading ? 'Saving' : 'Save'}
            UID="statement_preference_legal"
            onClick={handleSave}
            disabled={!consentGiven}
            ariaLabel={!consentGiven ? "disable save" : "save"}
            loading={loading}
            fullWidth
          />
        </div>
      </StyleGuide>
    </StyledStatementPreference>
  ) : null
}

StatementPreferenceLegal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeScreen: PropTypes.func.isRequired,
  setOpenBanner: PropTypes.func.isRequired,
  refreshLoanData: PropTypes.func.isRequired,
  setDeliveryOnline: PropTypes.func.isRequired,
}

export default StatementPreferenceLegal
