import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import Styled from 'styled-components'

import AuthContainer from './AuthContainer'
import Title from '../../Common/Title'
import { getRandomLetters } from '../../services/auth'
import Button from '../../Common/button'
import { buildTestID, setCookie } from '../../Utils/utils'
import { trackRegistration } from '../../Global/Analytics'
import Countdown from 'react-countdown'
import MfaErrorModal from '../../Common/MfaErrorModal'

const StyledConfirmSignUpModern = Styled.div`
justify-content: center;
border-radius: 20px;
box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
background-color: rgba(255, 255, 255, 0.95);
display: flex;
flex-direction: column;
font-size: 16px;
padding: 20px;
margin-left: 15px;
margin-bottom: 40px;

.label {
  color: var(--Black, #000);
  font-family: Lato, sans-serif;
  font-weight: 500;
  line-height: 137.5%;
  // margin-top: 82px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
}

.input {
  font-family: Lato, sans-serif;
  border-radius: 10px;
  border: 1px solid #a9a9a9;
  background-color: var(--White, #fff);
  // margin-top: 6px;
  justify-content: center;
  color: var(--Grey-2, #777);
  font-weight: 400;
  line-height: 137.5%;
  width: 100%;
  
  display: flex;
  padding: 10px 8px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
}

  .section-header {
    margin-bottom: 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .what_to_do {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    width: 100%;
  }

  .what_to_do_text {
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.black87};
    margin: 0;
    margin-right: 8px;
  }

  .resend_code_text {
    width: 100%;   
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: darkgreen;
    margin: 0;
    margin-top: 8px;
  }
`

const ConfirmSignUp = ({ setAuthState, authData }) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [randomLetters, setRandomLetters] = useState('')
  const [codeSentVisible, setCodeSentVisible] = useState(false)
  const [canResendCode, setCanResendCode] = useState(false)
  const [showMfaErrorModal, setShowMfaErrorModal] = useState(false)

  const setErrorForAda = (error) => {
    setRandomLetters(getRandomLetters(4))
    setError(error)
  }

  const setCodeSent = () => {
    setCanResendCode(false)
    setCodeSentVisible(true)
    setTimeout(() => setCodeSentVisible(false), 5000)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await Auth.confirmSignUp(authData.username, code).then(() => {
        setCookie('confirm_sign_up', 'true', 1)
        trackRegistration.Completed()
      })
      setAuthState('signIn')
    } catch (err) {
      console.warn('err', err)
      setShowMfaErrorModal(true)
      setMessage('')
      setErrorForAda(err.message)
    }
  }

  return (
    <>
      <Title>Email Verification</Title>
      <StyledConfirmSignUpModern>
        <AuthContainer>
          <form onSubmit={handleSubmit}>
            <div className="Section__sectionBody___ihqqd">
              <div className="Form__formField___38Ikl">
                <p>
                  Your account has been created. <br /> Please check
                  your email address and confirm your email by
                  entering the verification code you received.
                </p>
                <div className="label">
                  <label htmlFor="email">
                    <strong>Email Address *</strong>
                  </label>
                </div>
                <input
                  placeholder="Username"
                  name="username"
                  autoComplete="off"
                  data-testid={buildTestID(
                    'user_name',
                    '/home',
                    'confirm_sign_up',
                  )}
                  className="input"
                  value={authData.username}
                  disabled
                  id="email"
                  aria-required="true"
                />
              </div>
            </div>
            <div className="Section__sectionBody___ihqqd">
              <div className="Form__formField___38Ikl">
                <div className="label">
                  <label htmlFor="code">
                    <strong>Code *</strong>
                  </label>
                </div>
                <input
                  placeholder="Code"
                  name="code"
                  autoComplete="off"
                  data-testid={buildTestID(
                    'code',
                    '/home',
                    'confirm_sign_up',
                  )}
                  className="input"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  id="code"
                  aria-required="true"
                  type="text"
                  pattern="\d*"
                />
                <div className="resend-button">
                  {codeSentVisible ? (
                    <>
                      <p className="error_text resend_code_text" role="alert">
                        Code Resent
                      </p>
                    </>
                  ) : (
                    <div className="what_to_do">
                      <p className="what_to_do_text">Didn't receive code?</p>
                      {canResendCode ? (
                        <>
                          <Button
                            onClick={() => {
                              Auth.resendSignUp(authData.username).then(
                                () => {
                                  setCodeSent()
                                },
                              )
                            }}
                            text="Re-Send Code"
                            textButton
                            UID="enter_verify_code"
                          />
                        </>
                      ) : (
                        <>
                          <span className="what_to_do_text">Wait </span>
                          <span className="what_to_do_text">
                            <Countdown
                              date={Date.now() + 20000}
                              onComplete={() => setCanResendCode(true)}
                              intervalDelay={1000}
                              precision={2}
                              renderer={(props) => <span>{props.seconds}</span>}
                            />{' '}
                          </span>
                          <span className="what_to_do_text">Seconds</span>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <p className='help-text mt-2'>
                  Having trouble finding your verification code via email? Check your spam folder. To avoid receiving the verification code in your spam folder, add noreply@reversedepartment.com in your contacts list.
                </p>
              </div>
            </div>
            <p role="alert">
              <span className="error-message">
                {error}
                <span
                  style={{ visibility: 'hidden' }}
                  dangerouslySetInnerHTML={{ __html: randomLetters }}
                ></span>
              </span>
            </p>
            <span className="text-success">{message}</span>
            <div className="buttons">
              <Button
                onClick={() => setAuthState('signIn')}
                textButton
                text="Back to Sign In"
                small
                UID="confirm_sign_up"
              />
              <Button
                type="submit"
                text="Confirm"
                UID="confirm_sign_up"
              />
            </div>
          </form>
          <MfaErrorModal onHide={() => { setShowMfaErrorModal(false) }} show={showMfaErrorModal} />
        </AuthContainer>
      </StyledConfirmSignUpModern>
    </>
    )
}

export default ConfirmSignUp