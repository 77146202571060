import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const Trusts = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.Trusts, title)
  })

  return (
    <>
      <h3 ref={title} id="trusts" tabIndex="-1">
        Trusts
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              I want to place my property in a Trust. What do I need to do?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <p>If you would like to place the property securing your reverse mortgage in a trust, please consult with an attorney and send the following documentation to us for review <u>prior to finalization of the trust</u>:</p>
              <ul style={{"listStyleType" : "circle"}}>
                <li>A complete copy of the proposed Trust documents</li>
                <li>A copy of the proposed Transfer Deed</li>
                <li>An attorney opinion letter <strong>may be required</strong> stating the Trust meets all reverse mortgage guidelines set forth in <u>FHA Handbook 4000.1, Single Family Housing Policy Handbook, Title II Insured Housing Programs Home Equity Conversion Mortgage (HECM), Section B 2b ii (5)(a) Eligibility Requirements for Property Held in Living Trusts</u> and that the Trust is also compliant with the terms and conditions outlined in your loan documents.</li>
              </ul>
              If other documents are required, we will notify you. Documents should be sent  {' '}
              <a href="mailto:DocReview@reversedepartment.com">
                DocReview@reversedepartment.com
              </a>. Please allow 10 business days from receipt for review.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default Trusts
