import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`

const UploadFileIcon = ({ color = 'primaryDark' }) => {
  return (
    <StyledSVG
      color={color}
      fill="none"
      viewBox="0 0 140 140"
      role="presentation"
    >
      <path
        fill="#000"
        d="M115.21 25.12H97.96V12.85A1.85 1.85 0 0096.11 11H58.52a1.89 1.89 0 00-1.29.53L43.31 25.12H24.82a9.52 9.52 0 00-8.8 9.4v68.4a9 9 0 008.94 9h32l-3.16 14.42h-7.49a1.85 1.85 0 00-1.85 1.85 1.85 1.85 0 001.85 1.84h46.67a1.854 1.854 0 001.307-.574 1.844 1.844 0 00.503-1.336 1.84 1.84 0 00-1.84-1.85H85.5l-2.9-14.42h32.61a9 9 0 009-9v-68.4c-.05-4.75-4.4-9.33-9-9.33zm-58.53-7.91v7.91h-8.11l8.11-7.91zm-10.79 11.6h12.63a1.84 1.84 0 001.84-1.85V14.69h33.95v67.43H45.93l-.04-53.31zm-26.21 5.72a5.91 5.91 0 015.31-5.72H42.2v6.67h-14a1.85 1.85 0 00-1.85 1.85v59.59h-6.67V34.53zm37.85 91.77l3.15-14.42h18.16l2.9 14.42H57.53zm62.94-23.37a5.262 5.262 0 01-5.25 5.26H24.94a5.272 5.272 0 01-5.26-5.26v-2.31h40.25a1.851 1.851 0 001.84-1.85 2.001 2.001 0 00-.14-.72 1.85 1.85 0 00-1.7-1.12H30.04V39.18H42.2v44.8a1.85 1.85 0 001.84 1.85h52.07a1.85 1.85 0 001.85-1.85v-44.8h12.15v57.75H80.55a1.84 1.84 0 00-1.85 1.84 1.85 1.85 0 001.85 1.85h39.92v2.31zm0-6h-6.67V37.34a1.853 1.853 0 00-1.84-1.85h-14v-6.67h17.19a5.996 5.996 0 015.32 5.72v62.39z"
      ></path>
      <path
        fill="#000"
        d="M61.49 46.12c.42 0 .825-.16 1.13-.45l6-5.8V57.7a1.59 1.59 0 103.18 0V39.87l6 5.8a1.62 1.62 0 002.25 0 1.53 1.53 0 00.45-1.13 1.54 1.54 0 00-.48-1.12l-8.71-8.46a1.62 1.62 0 00-1.42-.43 1.57 1.57 0 00-.8.43l-8.67 8.43a1.6 1.6 0 000 2.25c.28.293.664.465 1.07.48zm10.26 52a1.59 1.59 0 00-1.47-1h-.11a1.63 1.63 0 00-1.44 1.25 1.54 1.54 0 00.68 1.63 1.558 1.558 0 001.79 0 1.6 1.6 0 00.56-1.9l-.01.02zM79.01 69H60.59a1.59 1.59 0 100 3.18h18.42a1.59 1.59 0 100-3.18z"
      ></path>
    </StyledSVG>
  )
}

export default UploadFileIcon
