import React, { useState } from 'react'
import Styled from 'styled-components'
import { Container } from 'react-bootstrap'

import ScrollUpButton from '../../Common/ScrollUpButton'
import Title from '../../Common/Title'
import { useAuthenticator } from '@aws-amplify/ui-react'
import PaymentCards from './PaymentCards'
import PaymentTiles from './PaymentTiles'

const StyledPaymentModern = Styled.div`
h1 {
  color: var(--Black, #000);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 130% */
  margin: 40px 0;
}

.card-wrapper {
  display: flex;
  margin-top: 250px;
  flex-direction: column;
  width: 100%;
}

@media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    margin-top:-15rem;
}

@media (min-width: 768px) {
  .card-wrapper {
    margin-top: 100px;
  }
}

@media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .card-wrapper {
      flex-direction: row;
      justify-content: space-between;
      margin-top: -68px;
    }
}

@media (max-width: ${({ theme }) => theme.breakPoints.medium}) and (min-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    margin-top:-180px;
    .card-wrapper {
      flex-direction: row;
      justify-content: space-between;
    }
      .container{
        max-width:100% !important;
        width:98%
      }
}
`
const Payment = () => {
  const [redirectModalOpen, setRedirectModalOpen] = useState('')
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  return (<>
    <Title>Make a Payment</Title>
    <StyledPaymentModern>
      <main id="main" tabIndex="0">
        <Container>
          <div className='card-wrapper'>
            <PaymentCards />
          </div>
          <h1>Important Information for Online and Phone Payments:</h1>
          <PaymentTiles />
        </Container>
        <ScrollUpButton />
      </main>
    </StyledPaymentModern>
  </>)
}

export default Payment
