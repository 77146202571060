import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`

const MoneyHandIcon = ({ color }) => {
  return (
    <StyledSVG
      color={color}
      fill="none"
      viewBox="0 0 140 140"
      role="presentation"
    >
      <path
        fill="#00377a"
        d="M36.124 96.778c1.3-.522 2.462-1.054 3.664-1.462 7.306-2.477 14.378-1.713 21.168 1.761 2.968 1.52 6.033 2.319 9.376 2.332 4.214.018 8.424.215 12.634.338 4.9.145 8.723 2.192 11.29 6.43.075.123.177.228.313.395 3.316-1.568 6.614-3.144 9.926-4.695 2.321-1.084 4.611-2.261 6.997-3.175 4.721-1.81 9.728-.026 12.423 4.264.766 1.217.454 2.284-.964 3.202-7.958 5.156-15.92 10.312-23.877 15.463-3.29 2.13-6.57 4.269-9.864 6.386-6.32 4.053-13.185 4.897-20.332 3.043-7.86-2.038-15.655-4.343-23.475-6.548-2.22-.623-4.334-.518-6.368.637-1.017.58-2.096 1.054-3.083 1.682-.352.224-.687.738-.722 1.146-.216 2.402-.766 2.921-3.148 2.855-4.43-.123-8.865-.224-13.295-.334-.51-.013-1.026-.009-1.537-.061-1.612-.159-2.272-.879-2.237-2.504.093-4.427.198-8.853.313-13.28.185-6.952.387-13.9.572-20.852.058-2.2.722-2.864 2.933-2.806 4.91.127 9.816.259 14.726.404 1.946.057 2.602.764 2.58 2.71-.022.843-.013 1.677-.013 2.67zm83.075 6.926c-1.775-1.721-4.351-2.174-6.659-1.102-5.011 2.332-9.996 4.708-14.994 7.062-1.599.755-1.599.755-1.466 2.529.14 1.95-.749 2.89-2.678 2.842-4.43-.114-8.864-.22-13.294-.338-3.224-.084-6.447-.132-9.666-.299-.736-.039-1.647-.334-2.114-.843-.388-.422-.467-1.397-.286-2.007.273-.914 1.14-1.247 2.118-1.217 3.99.119 7.984.259 11.978.325 3.175.053 6.354.009 9.547.009a.995.995 0 00-.035-.25c-1.053-3.953-4.426-6.518-8.781-6.64-4.175-.115-8.35-.255-12.529-.282-4.038-.022-7.768-.996-11.374-2.85-7.469-3.838-14.92-3.592-22.243.527-.564.316-.819.663-.837 1.353-.127 6.293-.308 12.582-.466 18.875-.01.308.026.615.044 1.046.563-.295 1.026-.51 1.457-.764 3.012-1.779 6.17-2.065 9.517-1.094a810.147 810.147 0 0014.686 4.115c3.985 1.072 7.953 2.323 12.013 2.973 4.337.698 8.745.088 12.515-2.292 10.952-6.917 21.785-14.027 32.662-21.063.295-.18.581-.4.885-.615zm-99.285-8.401l-.814 31.225h12.03l.872-31.225H19.914z"
      ></path>
      <path
        fill="#00377a"
        d="M70.882 35.062c16.214-.044 29.469 13.17 29.553 29.464.079 16.13-13.264 29.49-29.496 29.525-16.218.031-29.517-13.223-29.561-29.464-.044-16.17 13.26-29.481 29.504-29.525zm-25.387 29.56c.123 14.128 11.472 25.398 25.51 25.323 14.052-.07 25.467-11.55 25.365-25.507-.1-14.032-11.616-25.402-25.594-25.27-14.078.132-25.404 11.533-25.28 25.454zM72.935 19.7v9.534c0 .988-.437 1.717-1.375 2.02-.916.29-1.779.084-2.307-.773-.234-.378-.352-.887-.357-1.34-.022-6.319-.022-12.643-.009-18.963.005-1.308.789-2.147 1.96-2.178 1.215-.035 2.074.808 2.083 2.16.022 3.18.004 6.36.004 9.54zm-15.26 4.997c0 1.498.019 2.991-.003 4.489-.018 1.3-.797 2.134-1.969 2.17-1.22.034-2.092-.796-2.105-2.148-.035-2.956-.03-5.912 0-8.867.013-1.331.912-2.227 2.083-2.196 1.158.026 1.982.957 1.995 2.284.013 1.423 0 2.845 0 4.268zm30.553.066c0 1.458.018 2.92-.004 4.379-.018 1.366-.82 2.213-2.04 2.218-1.21.004-2.038-.84-2.051-2.21-.027-2.92-.027-5.836 0-8.757.013-1.335.885-2.244 2.043-2.244 1.19 0 2.035.953 2.048 2.35.017 1.418.004 2.841.004 4.264z"
      ></path>
      <path
        fill="#00377a"
        d="M72.63 49.208c2.273.619 4.105 1.73 5.417 3.623a8.648 8.648 0 011.541 4.391c.097 1.37-.665 2.416-1.863 2.534-1.043.101-2.008-.733-2.175-2.02-.194-1.51-.66-2.828-1.977-3.742-1.541-1.071-3.616-1.137-5.166-.114-1.62 1.076-2.413 3.004-1.99 4.862.431 1.906 1.929 3.303 3.875 3.518 2.167.237 4.302.553 6.037 2.029 2.616 2.226 3.7 5.05 3.048 8.427-.634 3.294-2.673 5.46-5.817 6.553-.665.228-.991.487-.903 1.207.048.396.022.809-.031 1.204-.136 1.023-1.061 1.827-2.026 1.8-.933-.03-1.792-.808-1.928-1.783-.049-.36-.036-.729-.044-1.093-.005-.365 0-.73 0-1.129-2.079-.738-3.968-1.81-4.972-3.772-.714-1.392-1.101-2.991-1.396-4.541-.211-1.116.687-2.003 1.757-2.108 1.136-.114 2.03.601 2.241 1.778.124.681.177 1.397.436 2.025a4.486 4.486 0 005.095 2.705c2.207-.457 3.69-2.275 3.713-4.54.022-2.144-1.484-4.006-3.664-4.445-1.14-.233-2.343-.237-3.453-.558-3.492-1.014-5.87-4.198-6.134-7.62-.326-4.238 2.603-7.725 5.544-8.708.687-.229.912-.602.846-1.287a5.247 5.247 0 01.044-1.313c.154-.993 1.026-1.73 1.977-1.726.951.004 1.836.746 1.95 1.743.08.681.018 1.38.018 2.1z"
      ></path>
    </StyledSVG>
  )
}

export default MoneyHandIcon
