import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }
`

const ContactCardIcon = () => {
    return (
        <StyledSVG
            aria-hidden="true"
            role="img"
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="4" width="18" height="12" rx="2" fill="#2D47A5" />
            <circle cx="5.5" cy="7.5" r="1.5" fill="#F7F8FF" />
            <ellipse cx="5.5" cy="12" rx="3.5" ry="2" fill="#F7F8FF" />
            <rect x="11" y="8" width="6" height="1" fill="#E0E7FF" />
            <rect x="11" y="6" width="6" height="1" fill="#E0E7FF" />
            <rect x="11" y="10" width="6" height="1" fill="#E0E7FF" />
            <rect x="11" y="12" width="5" height="1" fill="#E0E7FF" />
        </StyledSVG>
    )
}

export default ContactCardIcon
