import React from 'react'
import Styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import useWindowSize from '../../Hooks/useWindowSize'
import {
    GO_PAPERLESS_SUCCESS,
    MULTIFACTOR_SUCCESS,
} from './index'

const StyledGoPaperlessSuccess = Styled.div`
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 20px;
    background: var(--Surface, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    margin-top: 32px;
    
    .heading {
      margin: 0;
    }
    
    p {
      color: var(--Text-Subtle, #767676);
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 122.222% */
      margin: 0;
    }
    
    button:not(.textButton){
      border-radius: 4px;
      background: var(--Primary, #074EE8);
      padding: 8px 12px;
    }
    
    .mt-20 {
      margin-top: 20px;
    }
    
    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      margin-left: 35px;
      min-width: 710px;
    }
`

const GoPaperlessSuccess = ({
    currentScreen,
    setCurrentScreen,
}) => {
    return (
        <>
            {currentScreen === GO_PAPERLESS_SUCCESS ? (
                <StyledGoPaperlessSuccess id="change_email">
                    <p className="subtitle-2 sub_heading">
                        Success!<br />Thank you for enrolling in paperless statements.
                    </p>
                    <div className="d-flex justify-content-center w-100 mt-20">
                        <Button
                            className="next-setup-item"
                            text="Next Setup Item"
                            UID="next_setup_item_button"
                            onClick={() => setCurrentScreen(MULTIFACTOR_SUCCESS)}
                        />
                    </div>
                </StyledGoPaperlessSuccess>
            ) : null}
        </>
    )
}

GoPaperlessSuccess.propTypes = {
    currentScreen: PropTypes.string.isRequired,
    setCurrentScreen: PropTypes.func.isRequired
}

export default GoPaperlessSuccess
