import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { PROOF_OF_MARRIAGE, SIGNATURE } from '.'
import { buildTestID } from '../../Utils/utils'

import InputMask from 'react-input-mask'
import Button from '../../Common/button'
import EyeIcon from '../../assets/eye-icon'
import NoEyeIcon from '../../assets/no-eye-icon'
import dayjs from 'dayjs'
import { trackESignConsent } from '../../Global/Analytics'

const StyledNbsInformation = styled.div`
  h1, h3 {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }

  input.form-control {
    height: 56px;
  }
  
  label {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1px;
  }
  
  .form-control.is-invalid {
    background-image: none;
  }
  
  .row {
    flex-direction: column;
  }  

  .eye-icon {
    position: absolute;
    top: 88px;
    right: 30px;
  }

  .form-check {
      margin-bottom: 20px;
  }

  input[type="radio"] {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-top: 4px;
  }

  input[type="radio"] + label { 
    margin-left: 24px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
  
  .span-link {
      text-decoration: underline;
      color: #4C86BE;
      cursor: pointer;
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .nbs-information {
      background-color: #fff;
      padding: 15px;
      margin-top: 32px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      text-align: center;
      margin: 32px;
    }

    .information {
        margin: 0 108px;
    }

    .eye-icon {
        top: 66px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const NbsInformation = ({ setView, setModalShow, setNbsInformation, nameIsListed, mainRef }) => {
    const [validFirstName, setValidFirstName] = useState(false)
    const [showFirstNameValidation, setShowFirstNameValidation] = useState(false)
    const [firstName, setFirstName] = useState()
    const [validLastName, setValidLastName] = useState(false)
    const [showLastNameValidation, setShowLastNameValidation] = useState(false)
    const [lastName, setLastName] = useState()
    const [showTaxPayerId, setShowTaxPayerId] = useState(false)
    const [validTaxPayerId, setValidTaxPayerId] = useState(false)
    const [showTaxPayerIdValidation, setShowTaxPayerIdValidation] = useState(false)
    const [taxpayerid, setTaxPayerId] = useState();
    const [validDob, setValidDob] = useState(false)
    const [showDobValidation, setShowDobValidation] = useState(false)
    const [dob, setDob] = useState();
    const [validDom, setValidDom] = useState(false)
    const [showDomValidation, setShowDomValidation] = useState(false)
    const [dom, setDom] = useState();
    const [meetRequirements, setMeetRequirements] = useState("")
    const [showMask, setShowMask] = useState(true)

    useEffect(() => {
        if (window.location.host === 'dev-borrower.celink.cloud' || window.location.host === 'stg-borrower.celink.cloud')
            setShowMask(false)
    }, [showMask])

    const validateFirstName = (e) => {
        const firstName = e.target.value.trim()
        if (!firstName.length) {
            setValidFirstName(false)
            setShowFirstNameValidation(true)
            return
        }

        setValidFirstName(true)
        setShowFirstNameValidation(false)
    }

    const validateLastName = (e) => {
        const lastName = e.target.value.trim()
        if (!lastName.length) {
            setValidLastName(false)
            setShowLastNameValidation(true)
            return
        }

        setValidLastName(true)
        setShowLastNameValidation(false)
    }

    const handleTaxPayerIdChange = (e) => {
        const value = e.target.value.trim().replace(/\D/g, "").slice(0, 9)
        setTaxPayerId(value)
    };

    const validateTaxPayerId = (e) => {
        const taxpayerid = e.target.value.trim().replace(/-/g, "")
        if (taxpayerid.length !== 9) {
            setValidTaxPayerId(false)
            setShowTaxPayerIdValidation(true)
            return;
        }

        setValidTaxPayerId(true)
        setShowTaxPayerIdValidation(false)
    }

    const validateDob = (e) => {
        const dob = e.target.value.trim()
        if (!dob.length || !dayjs(dob).isValid()) {
            setValidDob(false)
            setShowDobValidation(true)
            return
        }

        setValidDob(true)
        setShowDobValidation(false)
    }

    const validateDom = (e) => {
        const dom = e.target.value.trim()
        if (!dom.length || !dayjs(dom).isValid()) {
            setValidDom(false)
            setShowDomValidation(true)
            return
        }

        setValidDom(true)
        setShowDomValidation(false)
    }

    const handleClick = (e) => {
        const nbsInformation = {
            "firstName": firstName,
            "lastName": lastName,
            "taxPayerId": taxpayerid,
            "birthDate": dob,
            "marriageDate": dom,
            "factSheetRequirements": meetRequirements === 'yes' ? true : false,
            "nameIsListed": nameIsListed,
            "isMarried": true,
            "proofUploaded": false,
            "clientName": ''
        }
        trackESignConsent.StepCompleted('completed nbs form', 'nbs_information_button_next')
        setNbsInformation(nbsInformation)
        meetRequirements === 'yes' ? setView(PROOF_OF_MARRIAGE) : setView(SIGNATURE)
    }

    const handleFactSheetClick = (e) => {
        e.preventDefault()
        window.open('./forms/Non_Borrowing_Spouse_Fact_Sheet.pdf')
    }

    return (
        <StyledNbsInformation>
            <div className="nbs-information"
                data-gridcolumnlayout={{
                    xl: '3/13',
                    lg: '1/13',
                    md: '1/14',
                    sm: '1/9',
                }}>
                <h1 tabIndex={-1} ref={mainRef}>Annual Occupancy Certification</h1>
                <h5>Please provide the information requested below to assist us in identifying whether or not your non-borrowing spouse may potentially be eligible to remain in the mortgaged property after the death of the last remaining HECM borrower.</h5>
                <Form className="information">
                    <Row>
                        <Col>
                            <Form.Group className="mb-36" controlId="firstName">
                                <Form.Label>Non-borrowing Spouse’s First Name</Form.Label>
                                <Form.Control type="text"
                                    name="firstName"
                                    placeholder="Enter first name"
                                    className={`${showFirstNameValidation ? "is-invalid" : ""}`}
                                    onBlur={validateFirstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your first name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-36" controlId="lastName">
                                <Form.Label>Non-borrowing Spouse’s Last Name</Form.Label>
                                <Form.Control type="text"
                                    name="lastName"
                                    placeholder="Enter last name"
                                    className={`${showLastNameValidation ? "is-invalid" : ""}`}
                                    onBlur={validateLastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your last name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-40" controlId="taxpayerid">
                                <Form.Label>Non-borrowing Spouse’s Social Security Number or Taxpayer Identification Number</Form.Label>
                                <InputMask
                                    type={showTaxPayerId ? "text" : "password"}
                                    className={`form-control ${showTaxPayerIdValidation ? "is-invalid" : ""}`}
                                    name="taxpayerid"
                                    placeholder="Enter social security number"
                                    minLength={9}
                                    value={taxpayerid}
                                    mask={showTaxPayerId ? "999-99-9999" : ""}
                                    onChange={handleTaxPayerIdChange}
                                    onBlur={validateTaxPayerId} />
                                <i className="eye-icon" onClick={() => setShowTaxPayerId(!showTaxPayerId)}>
                                    {
                                        showTaxPayerId ? <EyeIcon></EyeIcon> : <NoEyeIcon></NoEyeIcon>
                                    }
                                </i>
                                <Form.Control.Feedback type="invalid">
                                    Please enter your social security number.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="dateOfBirth">
                                <Form.Label>Non-borrowing Spouse’s Date of Birth</Form.Label>
                                <InputMask
                                    type="text"
                                    className={`form-control ${showDobValidation ? "is-invalid" : ""}`}
                                    name="dateOfBirth"
                                    mask={showMask ? "99/99/9999" : ""}
                                    placeholder="MM/DD/YYYY"
                                    onBlur={validateDob}
                                    onChange={(e) => setDob(e.target.value)}>
                                </InputMask>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid date of birth.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="dateOfMarriage">
                                <Form.Label>Date of Marriage</Form.Label>
                                <InputMask
                                    type="text"
                                    className={`form-control ${showDomValidation ? "is-invalid" : ""}`}
                                    name="dateOfMarriage"
                                    mask={showMask ? "99/99/9999" : ""}
                                    placeholder="MM/DD/YYYY"
                                    onBlur={validateDom}
                                    onChange={(e) => setDom(e.target.value)}>
                                </InputMask>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid date of marraige.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Does the HECM borrower and his/her spouse meet the requirements in Part 2 in the provided fact sheet? <span className={'span-link'} onClick={handleFactSheetClick} data-testid={buildTestID('view_fact_sheet', '/esign', 'nbs_information')}>Click here to view fact sheet</span>.</p>
                            <Form.Check
                                type={"radio"}
                                label={"Yes"}
                                name={"meet-requirements"}
                                onClick={() => setMeetRequirements("yes")}
                            />
                            <Form.Check
                                type={"radio"}
                                name={"meet-requirements"}
                                label={"No"}
                                onClick={() => setMeetRequirements("no")}
                            />
                        </Col>
                    </Row>
                </Form>
                <hr />
                <div className="buttons">
                    <Button
                        className="cancel button"
                        text="Cancel"
                        outlined
                        fullWidth
                        UID="nbs_information_button_cancel"
                        onClick={() => setModalShow(true)}
                    />
                    <Button
                        className="button"
                        text="Next"
                        fullWidth
                        UID="nbs_information_button_next"
                        disabled={!validFirstName || !validLastName || !validTaxPayerId || !validDob || !validDom || !meetRequirements.length}
                        onClick={handleClick}
                    />
                </div>
            </div>
        </StyledNbsInformation>
    )
}

export default NbsInformation