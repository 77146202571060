import React from 'react'

const SiteBrokenIcon = () => {
  return (
    <svg
      width="228"
      height="228"
      viewBox="0 0 228 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M181.789 70.4137C171.236 66.3008 162.321 58.7959 156.869 48.869C147.7 32.1756 129.959 20.8597 109.567 20.8597C88.4017 20.8597 70.0922 33.0511 61.2612 50.7932C55.8525 61.6593 46.6487 70.191 35.393 74.7336C18.7615 81.4467 4.79915 96.7873 0.368293 120.771C-0.117988 123.403 -0.125113 126.109 0.361168 128.741C7.24036 165.956 37.0545 182.372 64.1896 178.019C79.3863 175.581 94.7701 180.65 106.034 191.139C128.938 212.466 165.654 219.152 201.297 196.291C205.473 193.613 211.075 187.993 213.75 183.816C244.462 135.869 221.712 85.9738 181.789 70.4137V70.4137Z"
        fill="url(#paint0_linear_1879:1313)"
      />
      <rect
        opacity="0.1"
        x="25"
        y="42"
        width="177"
        height="126"
        rx="6"
        fill="#00377A"
      />
      <rect
        x="26"
        y="51"
        width="177"
        height="127"
        rx="6"
        fill="white"
      />
      <path
        d="M163.075 208.028C161.845 208.028 160.849 207.031 160.849 205.801C160.849 204.324 159.647 203.122 158.17 203.122C156.94 203.122 155.943 202.126 155.943 200.896C155.943 199.666 156.94 198.669 158.17 198.669C159.647 198.669 160.849 197.468 160.849 195.99C160.849 194.76 161.845 193.764 163.075 193.764C164.305 193.764 165.302 194.76 165.302 195.99C165.302 197.467 166.503 198.669 167.981 198.669C169.211 198.669 170.207 199.666 170.207 200.896C170.207 202.126 169.211 203.122 167.981 203.122C166.504 203.122 165.302 204.324 165.302 205.801C165.302 207.03 164.305 208.028 163.075 208.028Z"
        fill="#588DDC"
      />
      <path
        d="M152.72 41.6274C151.49 41.6274 150.493 40.6308 150.493 39.4008C150.493 37.9237 149.292 36.7218 147.814 36.7218C146.584 36.7218 145.587 35.7252 145.587 34.4953C145.587 33.2653 146.584 32.2687 147.814 32.2687C149.291 32.2687 150.493 31.0673 150.493 29.5897C150.493 28.3598 151.49 27.3632 152.72 27.3632C153.949 27.3632 154.946 28.3598 154.946 29.5897C154.946 31.0668 156.148 32.2687 157.625 32.2687C158.855 32.2687 159.852 33.2653 159.852 34.4953C159.852 35.7252 158.855 36.7218 157.625 36.7218C156.148 36.7218 154.946 37.9233 154.946 39.4008C154.946 40.6304 153.949 41.6274 152.72 41.6274Z"
        fill="#588DDC"
      />
      <path
        d="M121.786 32.2643C120.556 32.2643 119.559 31.2676 119.559 30.0377C119.559 28.5606 118.358 27.3587 116.88 27.3587C115.65 27.3587 114.654 26.3621 114.654 25.1321C114.654 23.9022 115.65 22.9056 116.88 22.9056C118.357 22.9056 119.559 21.7041 119.559 20.2266C119.559 18.9966 120.556 18 121.786 18C123.016 18 124.013 18.9966 124.013 20.2266C124.013 21.7037 125.214 22.9056 126.692 22.9056C127.921 22.9056 128.918 23.9022 128.918 25.1321C128.918 26.3621 127.921 27.3587 126.692 27.3587C125.214 27.3587 124.013 28.5601 124.013 30.0377C124.013 31.2676 123.016 32.2643 121.786 32.2643Z"
        fill="#588DDC"
      />
      <path
        d="M73.4485 200.559C72.2186 200.559 71.222 199.563 71.222 198.333C71.222 196.856 70.0205 195.653 68.543 195.653C67.313 195.653 66.3164 194.657 66.3164 193.427C66.3164 192.197 67.313 191.2 68.543 191.2C70.0201 191.2 71.222 189.999 71.222 188.521C71.222 187.291 72.2186 186.295 73.4485 186.295C74.6785 186.295 75.6751 187.291 75.6751 188.521C75.6751 189.998 76.8765 191.2 78.3541 191.2C79.584 191.2 80.5807 192.197 80.5807 193.427C80.5807 194.657 79.584 195.653 78.3541 195.653C76.877 195.653 75.6751 196.855 75.6751 198.333C75.6751 199.563 74.678 200.559 73.4485 200.559Z"
        fill="#588DDC"
      />
      <path d="M203 71.98H26V90.98H203V71.98Z" fill="#588DDC" />
      <path
        d="M44.2301 63.5001C44.522 63.5014 44.8113 63.4449 45.0812 63.3338C45.3512 63.2227 45.5964 63.0592 45.8028 62.8528C46.0093 62.6464 46.1727 62.4011 46.2838 62.1312C46.3949 61.8612 46.4514 61.572 46.4501 61.2801V61.2101C46.4706 60.9053 46.4282 60.5996 46.3256 60.312C46.2231 60.0243 46.0625 59.7607 45.8539 59.5376C45.6453 59.3146 45.3931 59.1367 45.1129 59.0151C44.8327 58.8935 44.5305 58.8308 44.2251 58.8308C43.9197 58.8308 43.6175 58.8935 43.3374 59.0151C43.0572 59.1367 42.805 59.3146 42.5964 59.5376C42.3877 59.7607 42.2272 60.0243 42.1246 60.312C42.0221 60.5996 41.9797 60.9053 42.0001 61.2101V61.2801C42.0001 61.5724 42.0579 61.8619 42.1701 62.1319C42.2822 62.4019 42.4467 62.6471 42.6539 62.8534C42.8611 63.0597 43.107 63.223 43.3775 63.3339C43.648 63.4449 43.9377 63.5014 44.2301 63.5001Z"
        fill="#00377A"
      />
      <path
        d="M52.83 63.5001C53.1224 63.5014 53.4121 63.4449 53.6826 63.3339C53.9531 63.223 54.199 63.0597 54.4062 62.8534C54.6134 62.6471 54.7779 62.4019 54.89 62.1319C55.0022 61.8619 55.06 61.5724 55.06 61.2801V61.2101C55.0804 60.9053 55.038 60.5996 54.9355 60.312C54.8329 60.0243 54.6724 59.7607 54.4637 59.5376C54.2551 59.3146 54.0029 59.1367 53.7227 59.0151C53.4426 58.8935 53.1404 58.8308 52.835 58.8308C52.5296 58.8308 52.2274 58.8935 51.9472 59.0151C51.667 59.1367 51.4148 59.3146 51.2062 59.5376C50.9976 59.7607 50.837 60.0243 50.7345 60.312C50.6319 60.5996 50.5895 60.9053 50.61 61.2101V61.2801C50.6087 61.572 50.6652 61.8612 50.7763 62.1312C50.8874 62.4011 51.0508 62.6464 51.2573 62.8528C51.4637 63.0592 51.7089 63.2227 51.9789 63.3338C52.2488 63.4449 52.5381 63.5014 52.83 63.5001Z"
        fill="#00377A"
      />
      <path
        d="M61.4401 63.5001C61.732 63.5014 62.0213 63.4449 62.2912 63.3338C62.5611 63.2227 62.8064 63.0592 63.0128 62.8528C63.2192 62.6464 63.3827 62.4011 63.4938 62.1312C63.6049 61.8612 63.6614 61.572 63.6601 61.2801V61.2101C63.6805 60.9053 63.6381 60.5996 63.5356 60.312C63.433 60.0243 63.2725 59.7607 63.0638 59.5376C62.8552 59.3146 62.603 59.1367 62.3228 59.0151C62.0427 58.8935 61.7405 58.8308 61.4351 58.8308C61.1297 58.8308 60.8275 58.8935 60.5473 59.0151C60.2671 59.1367 60.0149 59.3146 59.8063 59.5376C59.5977 59.7607 59.4371 60.0243 59.3346 60.312C59.232 60.5996 59.1896 60.9053 59.2101 61.2101V61.2801C59.2101 61.5724 59.2678 61.8619 59.38 62.1319C59.4922 62.4019 59.6566 62.6471 59.8638 62.8534C60.071 63.0597 60.317 63.223 60.5875 63.3339C60.8579 63.4449 61.1477 63.5014 61.4401 63.5001V63.5001Z"
        fill="#00377A"
      />
      <path
        d="M98.5 177.98H132L138 206.98H91L98.5 177.98Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M197 48.98H32C29.8783 48.98 27.8434 49.8228 26.3431 51.3231C24.8429 52.8234 24 54.8582 24 56.98V170.98C24 173.102 24.8429 175.137 26.3431 176.637C27.8434 178.137 29.8783 178.98 32 178.98H95.2L88.2 206.98H81C80.4696 206.98 79.9609 207.191 79.5858 207.566C79.2107 207.941 79 208.45 79 208.98C79 209.51 79.2107 210.019 79.5858 210.394C79.9609 210.769 80.4696 210.98 81 210.98H149C149.53 210.98 150.039 210.769 150.414 210.394C150.789 210.019 151 209.51 151 208.98C151 208.45 150.789 207.941 150.414 207.566C150.039 207.191 149.53 206.98 149 206.98H141.23L134.23 178.98H197C199.122 178.98 201.157 178.137 202.657 176.637C204.157 175.137 205 173.102 205 170.98V56.98C205 54.8582 204.157 52.8234 202.657 51.3231C201.157 49.8228 199.122 48.98 197 48.98ZM137.11 206.98H92.34L99.34 178.98H130.14L137.11 206.98ZM201 170.98C201 172.041 200.579 173.058 199.828 173.808C199.078 174.559 198.061 174.98 197 174.98H32C30.9391 174.98 29.9217 174.559 29.1716 173.808C28.4214 173.058 28 172.041 28 170.98V155.98H201V170.98ZM201 151.98H28V73.98H201V151.98ZM201 69.98H28V56.98C28 55.9191 28.4214 54.9017 29.1716 54.1516C29.9217 53.4014 30.9391 52.98 32 52.98H197C198.061 52.98 199.078 53.4014 199.828 54.1516C200.579 54.9017 201 55.9191 201 56.98V69.98Z"
        fill="#00377A"
      />
      <path
        d="M112 39.98C112.346 39.3548 112.853 38.8337 113.468 38.4708C114.084 38.1079 114.785 37.9165 115.5 37.9165C116.215 37.9165 116.916 38.1079 117.531 38.4708C118.147 38.8337 118.654 39.3548 119 39.98L167 123.23C167.351 123.839 167.536 124.529 167.536 125.231C167.536 125.934 167.35 126.624 166.999 127.233C166.647 127.841 166.141 128.346 165.532 128.696C164.923 129.047 164.233 129.231 163.53 129.23H67.44C66.7373 129.231 66.0468 129.047 65.4379 128.696C64.829 128.346 64.3232 127.841 63.9715 127.233C63.6197 126.624 63.4343 125.934 63.4341 125.231C63.4338 124.529 63.6187 123.839 63.97 123.23L112 39.98Z"
        fill="white"
      />
      <path
        d="M144 84.98C143.441 84.9779 142.906 84.7551 142.51 84.36C142.312 84.1638 142.154 83.9302 142.047 83.6727C141.939 83.4153 141.884 83.139 141.884 82.86C141.884 82.581 141.939 82.3048 142.047 82.0473C142.154 81.7899 142.312 81.5562 142.51 81.36C142.805 81.0657 143.181 80.8654 143.59 80.7843C143.998 80.7033 144.422 80.745 144.807 80.9044C145.192 81.0638 145.521 81.3336 145.753 81.6799C145.985 82.0261 146.109 82.4333 146.11 82.85C146.111 83.1284 146.058 83.4044 145.953 83.6621C145.847 83.9198 145.692 84.1542 145.496 84.352C145.3 84.5498 145.067 84.7071 144.811 84.8148C144.554 84.9226 144.278 84.9787 144 84.98V84.98Z"
        fill="#00377A"
      />
      <path
        d="M159.69 133.13H69.8799C68.2826 133.13 66.7135 132.709 65.3302 131.911C63.947 131.112 62.7983 129.963 61.9997 128.58C61.2011 127.196 60.7807 125.627 60.7808 124.03C60.7808 122.432 61.2013 120.863 61.9999 119.48L106.91 41.55C107.703 40.1662 108.848 39.0164 110.228 38.2167C111.608 37.4171 113.175 36.996 114.77 36.996C116.365 36.996 117.932 37.4171 119.312 38.2167C120.692 39.0164 121.837 40.1662 122.63 41.55L141.25 73.87C141.423 74.1088 141.544 74.3813 141.605 74.6698C141.667 74.9583 141.667 75.2565 141.606 75.545C141.545 75.8336 141.424 76.1061 141.251 76.3451C141.078 76.584 140.857 76.7841 140.602 76.9323C140.347 77.0805 140.063 77.1736 139.77 77.2056C139.477 77.2376 139.18 77.2078 138.899 77.1181C138.618 77.0283 138.359 76.8807 138.139 76.6846C137.919 76.4886 137.742 76.2486 137.62 75.98L119 43.67C118.576 42.9281 117.963 42.3115 117.223 41.8827C116.484 41.4538 115.645 41.228 114.79 41.228C113.935 41.228 113.096 41.4538 112.356 41.8827C111.617 42.3115 111.004 42.9281 110.58 43.67L65.6699 121.59C65.2416 122.33 65.0156 123.17 65.0146 124.024C65.0136 124.879 65.2377 125.72 65.6644 126.46C66.0911 127.201 66.7053 127.817 67.4453 128.245C68.1853 128.673 69.025 128.899 69.8799 128.9H159.69C160.545 128.899 161.385 128.673 162.125 128.245C162.865 127.817 163.479 127.201 163.905 126.46C164.332 125.72 164.556 124.879 164.555 124.024C164.554 123.17 164.328 122.33 163.9 121.59L145.9 90.36C145.619 89.8746 145.542 89.2975 145.686 88.7555C145.831 88.2135 146.185 87.7511 146.67 87.47C147.155 87.1888 147.732 87.112 148.274 87.2564C148.816 87.4008 149.279 87.7546 149.56 88.24L167.56 119.48C168.358 120.863 168.779 122.431 168.779 124.027C168.78 125.624 168.36 127.192 167.563 128.575C166.765 129.958 165.618 131.107 164.236 131.907C162.854 132.706 161.286 133.128 159.69 133.13V133.13Z"
        fill="#00377A"
      />
      <path
        d="M114.79 100.98C113.896 100.984 113.011 100.811 112.185 100.47C111.358 100.13 110.608 99.6289 109.976 98.9966C109.345 98.3642 108.845 97.6129 108.506 96.7862C108.167 95.9594 107.995 95.0736 108 94.18V75.11C108 73.3198 108.711 71.6029 109.977 70.337C111.243 69.0712 112.96 68.36 114.75 68.36C116.54 68.36 118.257 69.0712 119.523 70.337C120.789 71.6029 121.5 73.3198 121.5 75.11V94.18C121.5 95.9711 120.789 97.689 119.524 98.9565C118.258 100.224 116.541 100.937 114.75 100.94L114.79 100.98ZM114.79 72.61C114.118 72.6126 113.475 72.8814 113.001 73.3575C112.526 73.8335 112.26 74.4781 112.26 75.15V94.18C112.26 94.851 112.527 95.4945 113.001 95.969C113.476 96.4434 114.119 96.71 114.79 96.71C115.461 96.71 116.105 96.4434 116.579 95.969C117.054 95.4945 117.32 94.851 117.32 94.18V75.11C117.32 74.4381 117.054 73.7935 116.58 73.3175C116.106 72.8414 115.462 72.5726 114.79 72.57V72.61Z"
        fill="#00377A"
      />
      <path
        d="M114.79 119.54C113.226 119.547 111.708 119.011 110.495 118.024C109.281 117.038 108.448 115.661 108.136 114.128C107.824 112.595 108.053 111.002 108.784 109.619C109.516 108.237 110.704 107.151 112.146 106.546C113.588 105.941 115.196 105.855 116.695 106.303C118.193 106.75 119.49 107.704 120.365 109C121.239 110.297 121.637 111.857 121.49 113.414C121.343 114.971 120.661 116.429 119.56 117.54C118.938 118.174 118.196 118.677 117.377 119.02C116.557 119.364 115.678 119.54 114.79 119.54V119.54ZM114.79 110.24C114.287 110.238 113.795 110.385 113.376 110.663C112.957 110.941 112.63 111.337 112.436 111.801C112.243 112.265 112.191 112.776 112.288 113.269C112.385 113.763 112.626 114.216 112.98 114.572C113.335 114.929 113.788 115.172 114.281 115.27C114.774 115.369 115.285 115.32 115.75 115.128C116.214 114.936 116.612 114.61 116.891 114.192C117.171 113.774 117.32 113.283 117.32 112.78C117.32 112.108 117.054 111.463 116.58 110.987C116.105 110.511 115.462 110.243 114.79 110.24V110.24Z"
        fill="#00377A"
      />
      <path
        d="M141 143.98H90C89.4696 143.98 88.9609 143.769 88.5858 143.394C88.2107 143.019 88 142.51 88 141.98C88 141.45 88.2107 140.941 88.5858 140.566C88.9609 140.191 89.4696 139.98 90 139.98H141C141.53 139.98 142.039 140.191 142.414 140.566C142.789 140.941 143 141.45 143 141.98C143 142.51 142.789 143.019 142.414 143.394C142.039 143.769 141.53 143.98 141 143.98Z"
        fill="#00377A"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1879:1313"
          x1="13.2975"
          y1="71.7652"
          x2="235.119"
          y2="161.458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E3ECF5" />
          <stop offset="1" stop-color="#B4D9FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SiteBrokenIcon
