import * as React from "react";
import styled from "styled-components";

const Section = styled.section`
  border-radius: 20px;
  background-color: rgba(71, 84, 104, 0.9);
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  padding: 24px 15px;
  position: relative;
  // top: 140px;
  margin: 0 15px;


  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) and (min-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    top: -110px;
 }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    width: 700px;
    position: relative;
    margin-top: -347px;
    margin-left: 250px;
 }
  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    margin-top:-60px;
 }

 @media (min-width: 1920px) {
    // margin-top: -150px;
    margin-left: 380px;
 }
`;

const Title = styled.h2`
  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    font-size: 44px;
    font-weight: 800;
    line-height: 52px; /* 118.182% */
    text-align: left;
  }
`;

const Description = styled.p`
  margin-top: 24px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    font-size: 24px;
    font-style: normal;
    line-height: 32px; /* 133.333% */
    text-align: left;
  }
`;

const PaymentOptions = () => {
  return (
    <Section>
      <Title>Three Easy Options for Making a Payment on Your Reverse Mortgage Loan</Title>
      <Description>All voluntary prepayments or payments on an approved Repayment Plan may be made online, on the phone or by mail as follows:</Description>
    </Section>
  );
}

export default PaymentOptions;