import React, { useState, useRef } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react';
import styled from 'styled-components'
import Countdown from 'react-countdown'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

import Button from '../../Common/button'
import { requestAuthCode, confirmAuthCode } from '../../services/api'
import { buildTestID } from '../../Utils/utils'
import { checkIsUserSignedIn } from '../../services/auth'
import { trackLOCRequest } from '../../Global/Analytics'
import { VERIFY_ACCOUNT, SUBMIT_REQUEST } from './index'
import MfaErrorModal from '../../Common/MfaErrorModal'


const StyledVerifyAccount = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0 40px;

  .title_content {
    margin: 48px auto;

    h6 {
      margin-top: 0;
      margin-bottom: 26px;
    }
  }

  .verify_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 56px;
    max-width: 400px;

    .input_group {
      margin-bottom: 16px;
      text-align: center;

      input {
        width: 100%;
        text-align: center;
        border: 1px solid rgba(0, 55, 122, 0.56);
        border-radius: 4px;
        padding: 20px;
        font-size: 28px;
        letter-spacing: 48px;
        padding-left: 28px;
        white-space: nowrap;
        overflow-x: hidden;
      }

      .what_to_do {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 48px;
        width: 100%;
      }

      .what_to_do_text {
        text-align: center;
        font-weight: normal;
        font-size: 18px;
        line-height: 16px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: ${({ theme }) => theme.colors.black87};
        margin: 0;
        margin-right: 8px;
      }

      .input_error {
        border-color: ${({ theme }) => theme.colors.error};
      }

      .error_text {
        margin-top: 10px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: ${({ theme }) => theme.colors.error};
      }

      .resend_code_text {
        width: 100%;   
        text-align: center;
        font-weight: normal;
        font-size: 18px;
        line-height: 16px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: darkgreen;
        margin: 0;
        margin-top: 48px;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }

      label {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.15px;
        color: ${({ theme }) => theme.colors.black87};
        margin-bottom: 0;
      }
    }
  }

  .break_line {
    width: 100%;
    border: 0.5px solid ${({ theme }) => theme.colors.black12};
    margin: 0;
  }

  .card_buttons {
    display: flex;
    justify-content: space-between;
    margin: 32px 0;

    .back_btn {
      margin-right: 20px;
    }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 32px 16px;

    .title_content {
      width: 100%;
      margin: 0 0 40px 0;

      p {
        margin: 0;
      }
    }

    .verify_content {
      width: 100%;
      margin-bottom: 20px;

      .input_group {
        width: 100%;

        input {
          letter-spacing: 20px;
          padding-left: 24px;
          font-size: 24px;
        }
      }
    }

    .card_buttons {
      margin: 32px 0 0 0;
    }
  }
`

const EnterVerifyCode = ({
  setCurrentScreen,
  verificationValue,
  verificationMethod,
}) => {
  const [enteredCode, setEnteredCode] = useState('')
  const [showError, setShowError] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [codeSentVisible, setCodeSentVisible] = useState(false)
  const [canResendCode, setCanResendCode] = useState(false)
  const [showMfaErrorModal, setShowMfaErrorModal] = useState(false)
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  const verifyCode = async () => {
    try {
      setIsVerifying(true)
      const confirm = await confirmAuthCode(enteredCode)
      const result = confirm.data && confirm.data.confirmCode
      if (result && result.result === 'failure') throw result.message
      setShowError(false)
      setIsVerifying(false)
      setCurrentScreen(SUBMIT_REQUEST)
      trackLOCRequest.StepFive()
    } catch (e) {
      setShowMfaErrorModal(true)
      const isUserSignedIn = authStatus === 'authenticated' && await checkIsUserSignedIn()
      if (!isUserSignedIn) Auth.signOut()
      setIsVerifying(false)
      setShowError(true)

      console.log('an error', e)
    }
  }

  const setCodeSent = () => {
    setCanResendCode(false)
    setCodeSentVisible(true)
    setTimeout(() => setCodeSentVisible(false), 5000)
  }

  // used to prevent scroll on verify code input
  const inputRef = useRef()

  // disalows all keys except listed below (verify code input)
  const handleKeyDown = (e) => {
    let deleteKeyCode = 8
    let tabKeyCode = 9
    let pasteKeyCode = 86
    let numberKeyCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
    let numPadKeyCode = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105]

    let allowedKeyCodes = [
      deleteKeyCode,
      tabKeyCode,
      pasteKeyCode,
      ...numberKeyCode,
      ...numPadKeyCode,
    ]

    let disallowedKeys = '!@#$%^&*()'
    if (
      !allowedKeyCodes.includes(e.keyCode) ||
      disallowedKeys.includes(e.key)
    )
      e.preventDefault()
  }

  return (
    <StyledVerifyAccount>
      <div className="title_content">
        <h6>Enter your verification code</h6>
        <p>
          We sent your code to {verificationValue}. Please check your
          {verificationMethod === 'phone' ? ' phone' : ' email'}, then
          enter it here to confirm your identity.
        </p>
      </div>
      <div className="verify_content">
        <div className="input_group">
          <input
            data-testid={buildTestID(
              'verify_code',
              'advance_request',
              'enter_verify_code',
            )}
            ref={inputRef}
            type="text"
            pattern="\d*"
            className={showError ? 'input_error' : ''}
            name="verify-code"
            id="verify-code"
            min="111111"
            max="999999"
            value={enteredCode}
            onWheel={() => inputRef.current.blur()}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setShowError(false)
              if (e.target.value.length <= 6) {
                setEnteredCode(e.target.value.replace(/\D/g, ''))
              }
            }}
          />
          {showError ? (
            <p className="error_text" role="alert">
              Incorrect code. Please try again.
            </p>
          ) : (
            ''
          )}
          {codeSentVisible ? (
            <>
              <p className="error_text resend_code_text" role="alert">
                Code Resent
              </p>
            </>
          ):(
            <div className="what_to_do">
            <p className="what_to_do_text">Didn't receive code?</p>
            {canResendCode ? (
              <>
                <Button
                  onClick={() => {
                    requestAuthCode(verificationMethod)
                    setCodeSent()
                  }}
                  text="Re-Send Code"
                  textButton
                  UID="enter_verify_code"
                />
              </>
            ) : (
              <>
                <span className="what_to_do_text">Wait </span>
                <span className="what_to_do_text">
                  <Countdown
                    date={Date.now() + 20000}
                    onComplete={() => setCanResendCode(true)}
                    intervalDelay={1000}
                    precision={2}
                    renderer={(props) => <span>{props.seconds}</span>}
                  />{' '}
                </span>
                <span className="what_to_do_text">Seconds</span>
              </>
            )}
          </div>
          )}
        </div>
      </div>
      <p className='help-text'>
        Having trouble finding your verification code via email? Check your spam folder. To avoid receiving the verification code in your spam folder, add noreply@reversedepartment.com in your contacts list.
      </p>
      <div className="break_line"></div>
      <div className="card_buttons">
        <Button
          onClick={() => setCurrentScreen(VERIFY_ACCOUNT)}
          backArrow
          text="Back"
          outlined
          UID="enter_verify_code"
          className="back_btn"
        />
        <Button
          loading={isVerifying}
          text={isVerifying ? 'Verifying' : 'Verify Code'}
          disabled={enteredCode.length !== 6}
          onClick={
            enteredCode.length === 6 && !isVerifying
              ? verifyCode
              : () => { }
          }
          UID="enter_verify_code"
        />
      </div>
      <MfaErrorModal onHide={() => { setShowMfaErrorModal(false) }} show={showMfaErrorModal} />
    </StyledVerifyAccount>
  )
}

EnterVerifyCode.propTypes = {
  setCurrentScreen: PropTypes.func.isRequired,
  verificationValue: PropTypes.string.isRequired,
  verificationMethod: PropTypes.string.isRequired,
}

export default EnterVerifyCode
