import React from 'react';
import styled from 'styled-components';
import MagnifyingGlassIcon from '../../assets/magnifying-glass-icon';

const SearchContainer = styled.section`
  border-radius: 20px;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  max-width: 880px;
  justify-content: space-between; 
  gap: 20px;
  padding: 18px 24px;
  width: 100%;

  svg {
    margin-top: 8px;
  }
`;

const SearchInput = styled.input`
  border: none;
  font-size: 24px;
  color: #000;
  font-weight: 400;
  flex-grow: 1;
  margin-top: 10px;
  font-family: Lato, sans-serif;
  &:focus {
    outline: none;
  }
`;

const SearchIcon = styled.div`
  aspect-ratio: 1;
  object-fit: cover;
  width: 34px;
  cursor: pointer;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -42px;
`;

const Search = () => {
  const handleKeyUp = (e) => {
    const keyword = e.target.value;
    const cards = document.querySelectorAll('.card')
    for (let i = 0; i < cards.length; i++) {
      // search text content for keyword
      if (cards[i].textContent.toLowerCase().includes(keyword.toLowerCase())) {
        cards[i].classList.remove('d-none');
      } else {
        cards[i].classList.add('d-none');
      }
    }
  }

  return (
    <SearchWrapper>
      <SearchContainer>
        <SearchInput placeholder="Keyword Search..." aria-label="Keyword Search" onKeyUp={(e) => handleKeyUp(e)} />
        <MagnifyingGlassIcon />
      </SearchContainer>
    </SearchWrapper>
  );
};

export default Search;