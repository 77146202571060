import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }
`

const EllipseBlueIcon = () => {
  return (
    <StyledSVG
      aria-hidden="true"
      role="img"
      width="8"
      height="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="3.5" fill="#074EE8" />
    </StyledSVG>
  )
}

export default EllipseBlueIcon
