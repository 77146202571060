import * as React from "react"
import styled from "styled-components"
import SendFromComputerIcon from "../../assets/send-from-computer-icon"
import SendViaFaxIcon from "../../assets/send-via-fax-icon"
import SendViaMailIcon from "../../assets/send-via-mail-icon"
import SendViaEmailIcon from "../../assets/send-via-email-icon"
import Button from "../../Common/button"
import { BrowserRouter } from "react-router-dom"

const CardWrapper = styled.div`
  border-radius: 20px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
  background-color: #fff;
  display: flex;
  gap: 10px;
  padding: 20px 11px;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 0;
    background-color: unset;
    box-shadow: none;
    padding: 0;
    gap: unset;
 }
`;

const CardImage = styled.div`
  width: 66px;
  aspect-ratio: 1;
  object-fit: cover;
  margin: auto 0;

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    width: 136px;
    height: 136px;
    object-fit: cover;
    margin-bottom: -68px;
    z-index: 1;
  }
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;

    .button {
      justify-content: left;
      margin: 20px 0;
    }

    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 60px 20px 20px 20px;
      border-radius: 20px;
      background-color: #fff;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.13);
      max-height: 375px;
      min-height: 375px;
    }
`;

const CardTitle = styled.h3`
  margin: 25px 0 0;
  color: #000;
  font: 700 22px/1.09 Lato, sans-serif;
`;

const CardDescription = styled.p`
  margin: 10px 0 0;
  
  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    margin: 39px 0 0;
    color: #475468;
    font: 400 16px/1.25 Lato, sans-serif;
  }
`;

export const SEND_FROM_COMPUTER = 'SEND_FROM_COMPUTER';
export const SEND_VIA_FAX = 'SEND_VIA_FAX';
export const SEND_VIA_MAIL = 'SEND_FROM_MAIL';
export const SEND_VIA_EMAIL = 'SEND_VIA_EMAIL';

const CommonFormsCard = ({ title, description, svg, buttonProps, authStatus }) => {
  return (
    <CardWrapper>
      <CardImage>
        {svg === SEND_FROM_COMPUTER && <SendFromComputerIcon />}
        {svg === SEND_VIA_FAX && <SendViaFaxIcon />}
        {svg === SEND_VIA_MAIL && <SendViaMailIcon />}
        {svg === SEND_VIA_EMAIL && <SendViaEmailIcon />}
      </CardImage>
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardDescription>
          {description}
        </CardDescription>
        {buttonProps?.text && authStatus === 'authenticated' ? (
          <BrowserRouter forceRefresh={true}>
            <Button
              className="button"
              {...buttonProps}
              UID={buttonProps.linkTo}
            />
          </BrowserRouter>
        ) : null}
      </CardContent>
    </CardWrapper>
  )
}

export default CommonFormsCard;