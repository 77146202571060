import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import MaskedInput from 'react-text-mask'
import 'react-datepicker/dist/react-datepicker.css'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import Button from '../../Common/button'
import CalIcon from '../../assets/cal-icon'
import useWindowSize from '../../Hooks/useWindowSize'
import { useFocus } from '../../Hooks/useFocus'

const StyledGenerateQuote = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.white};
    padding: 40px 40px 60px 40px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    text-align: center;
    
    .heading-6 {
      margin: 0;
    }

    p.subtitle-2.good-through-text {
      text-align: left;
    }

    p {
      margin: 24px 0;
    }
  

    .radio_card {
      display: flex;
      align-items: left;
      
    }
    .error {
      color: ${({ theme }) => theme.colors.error};
      text-align: left;
    }
    .cal-btn-group {
      /* width: 450px; */
      display: flex;
      justify-content: start;
      align-self: center;
      
      .get-quote-button {
        align-self: flex-end;
        margin-bottom: 2px;
        margin-left: 20px;
      }
      
    }
    .cal-btn-group-del {
      /* width: 450px; */
      display: block;
      /* justify-content: start; */
      align-self: center;
      margin-bottom: 40px;
      
      .get-quote-button {
        align-self: flex-end;
        margin-top: 20px;
      }
      
    }
    .addressInput {
    
      input{
        height: 36px;
      }
      textarea{
        width: 252px;
        height: 56px;
        display: block;
        margin-left: 0px;
        border: 1px solid rgba(0, 0, 0, 0.87);
        border-radius: 4px;
        padding-left: 18px;
        :focus {
          outline: none;
          border: 2px solid ${({ theme, invalidDate }) =>
    invalidDate ? theme.colors.error : theme.colors.primary};
        }
      }
    }
    .cal-text-group-del {
      /* display: block; */
      flex-direction: row;
      justify-content: center;
      text-align: left;

      input[type="radio"] {
        width: 25px;
        height: 25px;
        display: inline-block;
        margin: 10px;
        vertical-align: middle;
      }
     
    }
    
    .cal-text-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      height: 96px;
      
      .good-through-text {
        text-align: left;
        align-self: flex-start;
        margin: 0 0 16px;
        color: ${({ theme }) => theme.colors.black87};
      }
     
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container {
      border: none;
    }

    .react-datepicker__header {
       background: none;
       border: none;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__day-name {
      color: ${({ theme }) => theme.colors.black87};
    }

    .react-datepicker {
      border: none;
      box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    .react-datepicker__day {
      font-weight: normal;
      font-size: 16px;
      width: 36px;
      height: 36px;
      padding-top: 4px;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    .react-datepicker__day-names {
      display: none;
    }

    input {
      position: inherit;
      background: center;
      z-index: 1;
      width: 252px;
      height: 56px;
      display: block;
      border: 1px solid rgba(0, 0, 0, 0.87);
      box-sizing: border-box;
      border-radius: 4px;
      padding-left: 16px;

      :focus {
        outline: none;
        border: 2px solid ${({ theme, invalidDate }) =>
    invalidDate ? theme.colors.error : theme.colors.primary};
      }
    }
  }

  .error-text {
    color: ${({ theme }) => theme.colors.error};
    margin: 0;
    margin-left: 8px;
    position: absolute;
    height: 0;
  }

  .react-datepicker__current-month {
    font-weight: normal;
    font-size: 18px;
  }

  .cal-icon {
    position: absolute;
    right: 10px;
    top: 56px;
  }

  
  .react-datepicker__portal {
    .react-datepicker__navigation--next {
      border-left-color: ${({ theme }) => theme.colors.primary};
    }
    
    .react-datepicker__navigation--previous {  
      border-right-color: ${({ theme }) => theme.colors.primary};
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: ${({ theme }) => theme.colors.primary};
  }
  
  .react-datepicker__navigation--previous {  
    border-right-color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    box-shadow: none;
    padding: 0;
    
    .heading-6,
    p.subtitle-2 {
      text-align: left;
      display: flex;
      justify-content: flex-start;
    }
    
    .cal-btn-group, .cal-btn-group-del {
      justify-content: start;
      align-items: start;
    }
    
  }

  input::-ms-clear {
    display: none;
  }
  
  input:disabled {
    color: ${({ theme }) => theme.colors.black60};
  }
  
  
  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .cal-btn-group, .cal-btn-group-del {
      flex-direction: column;
      width: 100%;
  
      .get-quote-button {
        align-self: start;
        padding: 24px 0 0;
        margin: 0;
      }
    }

    .cal-text-group > div {
      width: 100%;
    }
    
    .react-datepicker-wrapper {
      width: 100%;
    }
    
    .react-datepicker {
      left: 0px;
    }

    .react-datepicker__week {
      height: unset;
    }

    .react-datepicker__day {
      height: unset;
    }
  }
`;

const today = new Date()

const mask = [
  /[0-9]/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const GenerateQuoteBlock = ({
  selectedDate,
  handleDateSelection,
  generatingPDF,
  handleGetQuote,
  handleDelivery,
  handleDeliveryAdress,
  handleDeliveryOption,
  disallowedDays,
  maxDate,
  isMass,
  eligible,
  loading
}) => {
  const [invalidDate, setInvalidDate] = useState(false)
  const { isXSmall } = useWindowSize()

  const mainRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("email");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [fax, setFax] = useState("");
  const [mail, setMail] = useState("");
  const [delivery, setDelivery] = useState(true);
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (mainRef)
      mainRef.current.focus();
  }, [mainRef]);

  useEffect(() => {
    if (isMass) handleDateSelection(maxDate)
  })
  useEffect(() => {
    if (!eligible && !delivery) {
      handleDeliveryAdress(address);
      handleDeliveryOption(selectedOption)
    }
  }, [address, delivery, selectedOption, eligible])

  useEffect(() => {

    if (selectedOption === "email") {
      let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(email)) {
        email.length === 0 ? setMessage("") : setMessage("Error! you have entered invalid email.");
        setDelivery(true);
      } else {
        setMessage("");
        setDelivery(false);
        setAddress(email);
      }
    } else if (selectedOption === "fax") {
      if (fax.length === 0) {
        setDelivery(true);
      } else {
        setDelivery(false);
        setAddress(fax);
      }
    } else if (selectedOption === "mail") {
      if (mail.length === 0) {
        setDelivery(true);
      } else {
        setDelivery(false);
        setAddress(mail);
      }
    }
  }, [email, fax, mail, selectedOption, address])

  // ADA issue resolved by forcing focus after cal closed
  const generateQuoteButtonRef = useRef(null)
  const { focus: focusOnGenerateQuoteButton } = useFocus(
    generateQuoteButtonRef,
    false,
  )

  const handleChange = (e) => {
    const enteredDate = new Date(e.target.value)

    const todayOrGreater = enteredDate >= today
    const earlierThanThirtyDays = enteredDate <= maxDate

    const allowedDate = !disallowedDays
      .map((day) => day.toString())
      .includes(enteredDate.toString())

    if (todayOrGreater && earlierThanThirtyDays && allowedDate) {
      setInvalidDate(false)
    } else {
      setInvalidDate(true)
    }
  }

  const handleDeliveryEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleDeliveryFax = (e) => {
    const value = e.target.value.trim().replace(/[^0-9-]/g, "")
    setFax(value);
  }

  const handleDeliveryMail = (e) => {
    setMail(e.target.value);
  }

  const MobileInput = React.forwardRef((props, ref) => (
    <input
      data-testid="date_picker_payoff_quote"
      type="text"
      {...props}
      ref={ref}
      readOnly
    />
  ))
  return (
    <StyledGenerateQuote
      className="generate-quote-block"
      invalidDate={invalidDate}
    >
      <h1 className="heading-6" tabIndex={-1} ref={mainRef}>Request a Payoff Quote</h1>

      {isMass ? (
        <p className="subtitle-2">
          The Good Through Date on this quote may be limited.
        </p>
      ) : (
        <div>
          {eligible ?
            (<p className="subtitle-2">
              Payoff quotes generated may be made valid for up to 30 days,
              however, the Good Through Date on the quote may be limited
              due to your loan status.
            </p>
            ) : (
              <p className="subtitle-2 good-through-text">
                We will do our best to provide you a payoff quote that is valid
                through your requested Good Through Date below, but the actual Good
                Through Date of the quote you receive will be determined by your current
                loan conditions.
              </p>
            )}
        </div>
      )}
      <div className="cal-btn-group">
        <div className="cal-text-group">
          {eligible ? (
            <p className="subtitle-2 good-through-text">
              Good Through Date
            </p>
          ) : (
            <p className="subtitle-2 good-through-text">
              Requested Good Through Date
            </p>
          )}
          <DatePicker
            excludeDates={disallowedDays}
            minDate={today}
            maxDate={maxDate}
            type="date"
            withPortal={isXSmall}
            popperModifiers={{
              offset: {
                offset: '120px',
              },
            }}
            selected={selectedDate}
            onChange={(date) => {
              setInvalidDate(false)
              handleDateSelection(date)
            }}
            onBlur={() => setInvalidDate(false)}
            onChangeRaw={handleChange}
            disabled={generatingPDF || isMass}
            onCalendarClose={
              window.document.documentMode
                ? () => { }
                : focusOnGenerateQuoteButton
            }
            customInput={
              isXSmall ? (
                <MobileInput />
              ) : (
                <MaskedInput
                  data-testid="date_picker_payoff_quote"
                  keepCharPositions
                  showMask
                  mask={mask}
                />
              )
            }
          >
            <div role="alert" aria-label="date picker open" />
          </DatePicker>
          <CalIcon className="cal-icon" color="black60" />
          {invalidDate ? (
            <p className="label error-text" role="alert">
              Invalid Date
            </p>
          ) : null}
        </div>
        {
          eligible && (
            <Button
              className="get-quote-button"
              onClick={handleGetQuote}
              text={generatingPDF ? 'In Progress' : 'Get Quote'}
              disabled={generatingPDF || invalidDate}
              loading={generatingPDF}
              forwardRef={generateQuoteButtonRef}
              UID="payoff_quote"
            />
          )
        }
      </div>
      {!eligible && (<div className="cal-btn-group-del">
        <div className="cal-text-group">
          <p className="subtitle-2 good-through-text">
            Delivery Method:
          </p>
        </div>
        <div>
          <div className="cal-text-group-del">
            <label htmlFor="email">
              <input
                type="radio"
                name="email"
                id="email"
                value="email"
                checked={selectedOption === "email"}
                onChange={e => setSelectedOption(e.target.value)}
              />
              Email</label>
            <label htmlFor="fax">
              <input
                type="radio"
                name="fax"
                id="fax"
                value="fax"
                checked={selectedOption === "fax"}
                onChange={e => setSelectedOption(e.target.value)}
              />
              Fax</label>
            <label htmlFor="mail">
              <input
                type="radio"
                name="mail"
                id="mail"
                value="mail"
                checked={selectedOption === "mail"}
                onChange={e => setSelectedOption(e.target.value)}
              />
              Mail</label>
          </div>
        </div>
        <div className="addressInput">
          <div>{selectedOption === "email" ? <div className="error"> {message} </div> : null}</div>
          <div>
            {(selectedOption === "email") && <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => handleDeliveryEmail(e)}
            >
            </input>}
            {(selectedOption === "fax") && <input
              type="phone"
              id="fax"
              value={fax}
              onChange={(e) => handleDeliveryFax(e)}
            >
            </input>
            }
            {(selectedOption === "mail") && <textarea
              type="text"
              id="mail"
              value={mail}
              onChange={(e) => handleDeliveryMail(e)}
            >
            </textarea>}
          </div>
        </div>
        <Button
          className="get-quote-button"
          onClick={handleDelivery}
          text={'Request Payoff Quote'}
          disabled={delivery || invalidDate}
          loading={loading}
        />
      </div>)}
    </StyledGenerateQuote>
  )
}

GenerateQuoteBlock.propTypes = {
  selectedDate: PropTypes.object.isRequired,
  handleDateSelection: PropTypes.func.isRequired,
  generatingPDF: PropTypes.bool.isRequired,
  handleGetQuote: PropTypes.func.isRequired,
  handleDelivery: PropTypes.func.isRequired,
  handleDeliveryAdress: PropTypes.func.isRequired,
  handleDeliveryOption: PropTypes.func.isRequired,
  disallowedDays: PropTypes.array.isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  isMass: PropTypes.bool.isRequired,
  eligible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
}

export default GenerateQuoteBlock