import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import QuestionIcon from '../assets/question-icon'
import { buildTestID } from '../Utils/utils'
import { trackUsedHelpBanner } from '../Global/Analytics'

const StyledHaveQuestionsBottomBar = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryDark};
  background: ${({ theme }) => theme.colors.primary12};
  border-radius: 4px;
  padding: 16px 18px;
  margin-top: 20px;

  svg {
    min-width: 20px;
    align-self: flex-start;
    margin-top: 2px;
  }

  a {
    color: ${({ theme }) => theme.colors.primaryDark};
    font-weight: normal;
  }

  p {
    margin: 0 0 0 8px;
  }
`

const HaveQuestionsBottomBar = ({
  link,
  text,
  target = '_self',
  UID = '',
}) => {
  const { pathname } = useLocation()

  return (
    <StyledHaveQuestionsBottomBar>
      <QuestionIcon />
      <p className="body-2">
        <b>Have questions?</b> Check out our{' '}
        <Link
          data-testid={buildTestID(text, pathname, UID)}
          target={target}
          to={link}
          onClick={() => trackUsedHelpBanner(text)}
          aria-label={`${text} this link may open a pdf`}
        >
          {text}
        </Link>{' '}
        for help.
      </p>
    </StyledHaveQuestionsBottomBar>
  )
}

HaveQuestionsBottomBar.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  target: PropTypes.string,
  UID: PropTypes.string,
}

export default HaveQuestionsBottomBar
