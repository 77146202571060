import { useState, useEffect } from 'react'
import { getESignAgreementConsent } from '../services/api'
import { getUser } from '../services/auth'
export const useESignConsentAgreement = () => {
    const [data, setData] = useState({
        data: null,
        error: null,
        loading: true,
      })

    useEffect(() => {
        const getESignAgreement = async () => {
            let eSignAgreementData = null
            try {
                const user = await getUser()

                if (user) {
                    const personId = user.attributes['custom:person_id']
                    eSignAgreementData = await getESignAgreementConsent(personId)

                    setData({
                        data: eSignAgreementData,
                        loading: false,
                        error: false,
                      })
                }
                else throw new Error('user does not exist')
            } catch (err) {
                setData({ error: err })
            }
        }

        getESignAgreement()
    }, [])

    return data
}
