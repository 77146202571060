import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const MailingAddress = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.MailingAddress, title)
  })

  return (
    <>
      <h3 ref={title} id="mailing-address-name-changes" tabIndex="-1">
        Mailing Address and Name Changes
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              My legal name has changed, or my property address has
              changed due to something like a 911 change. What do I
              do?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Please send a signed letter along with any supporting
              documentation you can provide which confirms the change
              to{' '}
              <a href="mailto:BC@reversedepartment.com">
                BC@reversedepartment.com
              </a>{' '}
              or fax to 866-616-2160 or via mail to Reverse Mortgage
              Servicing Dept, PO Box 40724, Lansing, MI 48901-7924.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              What is required to change my mailing address?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              Please complete and sign a{' '}
              <a href="./forms/2024/Change_of_Mailing_Address.pdf" target="_blank">
                Change of Mailing Address Form
              </a>{' '}
              and send it to the email address, fax number or mailing
              address on the form.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}
export default MailingAddress
