import React, { useEffect, useState } from 'react'
import Styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import useWindowSize from '../../Hooks/useWindowSize'
import {
  GO_PAPERLESS,
  STATEMENT_PREFERENCE_LEGAL,
  MULTIFACTOR_SUCCESS,
} from './index'

const StyledGoPaperless = Styled.div`
display: inline-flex;
padding: 30px 40px;
flex-direction: column;
align-items: flex-start;
gap: 20px;
border-radius: 20px;
background: var(--Surface, #FFF);
box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
margin-top: 32px;

.heading {
  margin: 0;
}

p {
  color: var(--Text-Subtle, #767676);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
}

.group_title {
  margin: 0 0 12px 0;
}

.online_only_text {
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
}

.edit_group {
  display: flex;
  justify-content: space-between;
  margin: 0 0 32px 0;

  button {
    text-align: end;
    margin-left: 24px;
  }
}

p {
  margin: 0;
}

.note_container {
  p {
    margin: 0;
  }
  background: ${({ theme }) => theme.colors.primary4};
  padding: 16px;
  border-radius: 4px;
}

.change_delivery_button {
  >button {
    &:focus-visible {
      outline: 2px solid #1460AA;
    }
  }
}

button:not(.textButton){
  border-radius: 4px;
  background: var(--Primary, #074EE8);
  padding: 8px 12px;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
  margin-left: 35px;
}

@media (max-width: ${({ theme }) => theme.breakPoints.small}) {
  box-shadow: none;
  margin-top: 24px;

  .edit_group {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    
    .change_delivery_button {
      width: 100%;
      margin-top: 20px;
      
      button {
        text-align: center;
        margin-left: 0;
        line-height: 20px;
      }
    }
  }

  .note_container {
    margin-top: 20px;
  }
}
`

const GoPaperless = ({
  currentScreen,
  setCurrentScreen,
  completed
}) => {
  const { isSmall } = useWindowSize()
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Set a timer to display the button after 5 seconds
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 15000);

    // Clear the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {currentScreen === GO_PAPERLESS || !isSmall ? (
        <StyledGoPaperless id="go_paperless">
          <h2
            className="heading-6 heading"
          >
            Sign-up for Paperless Statements
          </h2>
          <p className="subtitle-2 sub_heading">
            Paperless statements are the most secure and timely way to receive your monthly statement, <b style={{ "color": "#111111" }}>don't worry, you will be able to see the last 24 months of your statements online</b>, and its better for the environment!
          </p>
          <p>You will receive an email each month when your statement is ready to view online.</p>
          <div className="d-flex justify-content-between w-100 mt-20">
            {showButton ?
              <Button
                className="skip-for-now"
                text="Skip for now"
                textButton
                UID="paperless_skip_button"
                onClick={() => setCurrentScreen(MULTIFACTOR_SUCCESS)}
              /> : <span></span>
            }
            <Button
              className="paperless-opt-in"
              text="Opt In Now"
              UID="paperless_opt_in_button"
              onClick={() => setCurrentScreen(STATEMENT_PREFERENCE_LEGAL)}
            />
          </div>
        </StyledGoPaperless>
      ) : null}
    </>
  )
}

GoPaperless.propTypes = {
  scrollRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  currentScreen: PropTypes.string.isRequired,
  setCurrentScreen: PropTypes.func.isRequired,
  setOpenBanner: PropTypes.func.isRequired,
  paperlessIndicator: PropTypes.bool.isRequired,
  refreshLoanData: PropTypes.func.isRequired,
}

export default GoPaperless
