import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }
`

const MailBoxIcon = () => {
  return (
    <StyledSVG
      aria-hidden="true"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="90"
      height="68"
    >
      <defs>
        <path
          className="primaryDark"
          id="mailbox_icon"
          d="M.918 16.557V49.67h23.397c3.785 0 16.707-1.707 16.707-5.476V9.864C41.022.721 24.4 0 16.045 0 7.691 0 .918 7.414.918 16.557z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          className="primary"
          fill="#1460AA"
          d="M14.834 18.328C6.642 18.328.001 25.742.001 34.885V61.18C0 64.95 3.01 68 6.725 68h56.487v-49.67H14.834z"
        ></path>
        <path
          className="primaryDark"
          fill="#0E4377"
          d="M23 18.33h4v27h-4z"
        ></path>
        <path
          className="primary"
          fill="#1460AA"
          d="M20 0h3v45h-3zM9 0h11v9H9z"
        ></path>
        <path
          className="primaryDark"
          fill="#0E4377"
          d="M48.336 34.885v33.114h23.396c3.785 0 6.857-3.05 6.857-6.819V34.885c0-9.143-6.772-16.557-15.127-16.557s-15.126 7.414-15.126 16.557z"
        ></path>
        <g transform="translate(51.082 18.328)">
          <mask id="mailbox" fill="#fff">
            <use xlinkHref="#a"></use>
          </mask>
          <path
            className="primary"
            fill="#1460AA"
            d="M38.705 21.481c.094 7.515-.007 13.335-.346 20.635-.084 1.779-1.502 3.225-3.27 3.298-13.556.572-28.885.572-42.44 0-1.769-.073-3.189-1.52-3.272-3.303-.328-7.05-.432-12.65-.35-19.919.008-.806.827-1.367 1.566-1.052l21.121 8.496c.837.35 1.775.35 2.61 0l22.814-9.21c.737-.309 1.555.25 1.566 1.055zM-9.45 16.28a2.293 2.293 0 01-1.388-2.203c.05-1.328.115-2.232.188-3.314.117-1.745 1.519-3.139 3.25-3.211 13.59-.574 28.956-.574 42.546 0 1.725.072 3.127 1.455 3.244 3.193.061.838.109 1.579.156 2.635a2.294 2.294 0 01-1.392 2.2l-22.828 9.217c-.835.35-1.775.35-2.612 0L-9.45 16.279z"
            mask="url(#b)"
          ></path>
        </g>
      </g>
    </StyledSVG>
  )
}

export default MailBoxIcon
