import React from 'react'
import Styled from 'styled-components'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Title from '../Common/Title'
import SiteBrokenIcon from '../assets/site-broken-icon'

const Styled404 = Styled.div`
  margin-top: 150px;
  margin-bottom: 250px;
  font-size: 20px;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }

  .oops {
    font-size: 40px;
    font-weight: bold;
  }

  .bold {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }  

  p {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25px;
  }
  
  .mt-20 {
    margin-top: 20px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }
`

const BadRequestPage = ({ error }) => {
  return (
    <>
      <Container>
        <Styled404>
          {error === 'Unauthorized' ? (
            <>
              <Title>Unauthorized</Title>
              <p className="oops">Oops!</p>
              <p>
                <strong>401</strong> - Unauthorized
              </p>
              <p>You are not authorized to access this page.</p>
              <p>
                <a href="/">Go To Homepage</a>.
              </p>
            </>
          ) : (
            <>
              <Title>Page Not Found</Title>
              <h2 className="bold">Oops!</h2>
              <h2 className="bold">
                404 - Page Not Found
              </h2>
              <p className="mt-20 mb-40">
                The page you are looking for might have been removed, had it’s name changed, or is temporarily unavailable.
              </p>
              <SiteBrokenIcon />
            </>
          )}
        </Styled404>
      </Container>
    </>
  )
}

BadRequestPage.propTypes = {
  error: PropTypes.string,
}

export default BadRequestPage
