import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme }) => theme.colors.success};
  }
`
const CheckIcon = () => {
  return (
    <StyledSVG
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Successful Circle Check Icon</title>
      <path
        d="M8.99935.66675c-4.6 0-8.33333 3.73333-8.33333 8.33333 0 4.60002 3.73333 8.33332 8.33333 8.33332 4.59995 0 8.33335-3.7333 8.33335-8.33332 0-4.6-3.7334-8.33333-8.33335-8.33333zM7.33268 13.1667L3.16602 9.00008l1.175-1.175 2.99166 2.98332 6.32502-6.32499 1.175 1.18334-7.50002 7.49995z"
        fill="#2E7D32"
      />
    </StyledSVG>
  )
}

export default CheckIcon
