import React from 'react'

const leafIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        fill="#D9FF7D"
        d="M3.325 14.678a7.045 7.045 0 01-1.1-.899 6.955 6.955 0 01-2.05-4.95c0-4.776 4.714-7.591 8.497-8.434 1.988-.442 4.221-.514 6.64-.211a.586.586 0 01-.073 1.167c-2.663 0-5.367 1.619-7.211 3.545a13.645 13.645 0 00-3.794 9.3.586.586 0 01-.909.482z"
      ></path>
      <path
        fill="#66A182"
        d="M3.647 20a.586.586 0 01-.586-.586v-5.226a.586.586 0 011.172 0v5.226a.586.586 0 01-.586.586z"
      ></path>
      <path
        fill="#97DB4F"
        d="M15.802.602a.586.586 0 00-.563-.423c-2.704 0-5.716 1.46-8.058 3.906a14.813 14.813 0 00-4.12 10.096v.313a7.011 7.011 0 009.064-.715c2.064-2.063 1.636-4.907 1.495-6.502-.216-2.436-.402-4.54 1.932-6.017a.586.586 0 00.25-.658z"
      ></path>
    </svg>
  )
}

export default leafIcon
