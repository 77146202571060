import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

import PasswordInput from '../../auth/ShowHidePassword'
import StyleGuide from '../../../Global/StyleGuide'
import PasswordValidation from '../../auth/PasswordValidation'
import Button from '../../../Common/button'
import { buildTestID } from '../../../Utils/utils'
import useWindowSize from '../../../Hooks/useWindowSize'
import { trackChangePassword } from '../../../Global/Analytics'

const StyledChangePassword = styled(Modal)`
  .modal-content {
    padding: 32px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }

  .modal-width {
    max-width: 450px !important;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .title {
    margin: 0;
  }

  .sub_heading {
    margin: 16px 0 0 0;
  }

  .new_password_input {
    margin-bottom: 24px;
  }

  .input_group {
    margin: 24px 0 0 0;
    width: 100%;

    h5 {
      margin: 0;
    }

    input {
      margin: 16px 0 0 0;
    }

    .input_error {
      border: 2px solid ${({ theme }) => theme.colors.error};
    }
  }

  .resend_code_button {
    margin: 24px 0 32px 0;
  }

  .code_sent {
    font-size: 18px;
    margin-top: 1rem;
    color: ${({ theme }) => theme.colors.success};
  }

  .error_message {
    color: ${({ theme }) => theme.colors.error};
  }

  .password_validation {
    margin: 24px 0 0 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 16px;

    .cancel {
      margin-right: 24px;
    }

    .button {
      width: 162px;
    }
  }

  .new_pass_text {
    margin-bottom: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 24px 0 48px 0;

    .button {
      width: 100%;
    }

    .buttons {
      width: 100%;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const ChangePassword = ({
  closeScreen,
  show,
  userEmail,
  setOpenBanner,
}) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [resendStatus, setResendStatus] = useState("");

  const { isSmall } = useWindowSize()

  const handleGetVerificationCode = async (e) => {
    e.preventDefault()
    try {
      await Auth.forgotPassword(userEmail)
        .then(() => {
          setResendStatus("Code sent")
        })
    } catch (err) {
      setError(err.message)
    }

    setTimeout(() => setResendStatus(""), [2000])
  }

  const handleCloseScreen = () => {
    closeScreen()
    setCode('')
    setError('')
    setPassword('')
  }

  const handleGetPasswordSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      await Auth.forgotPasswordSubmit(userEmail, code, password)
      setLoading(false)
      handleCloseScreen()
      setPassword('')
      setCode('')
      trackChangePassword.Completed()
      setOpenBanner({
        type: 'success',
        text: 'Your password is successfully updated.',
      })
    } catch (err) {
      setLoading(false)
      if (err.code === 'NetworkError') {
        setError('Network error, please try again.')
      } else {
        setError(err.message)
      }
    }
  }

  const handleCodeChange = (e) => {
    setError('')
    if (e.target.value.length <= 6) {
      setCode(e.target.value.replace(/\D/g, ''))
    }
  }

  let ModalOrDivProps = {
    onHide: handleCloseScreen,
    show,
    centered: true,
    size: 'md',
    as: isSmall ? 'div' : Modal,
    dialogClassName: 'modal-width',
  }

  if (isSmall) delete ModalOrDivProps.onHide

  return show ? (
    <StyledChangePassword {...ModalOrDivProps}>
      <StyleGuide>
        <form onSubmit={handleGetPasswordSubmit}>
          <h3 className="title heading-6">Change My Password</h3>
          <p className="subtitle-2 sub_heading">
            Please check your email or phone for a code.
          </p>
          <p role="alert" aria-live="polite" className="code_sent" style={{ "display": resendStatus === "" ? "none" : "block" }}>Code Sent</p>
          <div className="input_group">
            <h5 className="subtitle-2 bold">Code</h5>
            <input
              placeholder="Enter Code"
              className={`input ${error.includes('code') ? 'input_error' : ''
                }`}
              name="code"
              autoComplete="off"
              value={code}
              id="code"
              aria-required="true"
              onChange={handleCodeChange}
              data-testid={buildTestID(
                'code',
                '/home',
                'get_password_submit',
              )}
            />
          </div>
          <Button
            className="resend_code_button"
            text="Resend Code"
            textButton
            onClick={(e) => handleGetVerificationCode(e)}
            small
            UID="change_password"
          />
          <h5 className="subtitle-2 bold new_pass_text">
            New Password
          </h5>
          <PasswordInput
            className="new_password_input input"
            placeholder="Enter New Password"
            name="password"
            type="text"
            autoComplete="off"
            value={password}
            aria-required="true"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            datatestid="change_password"
          />
          <PasswordValidation
            password={password}
            showConfirmPassword={false}
            setIsPasswordValid={setPasswordValid}
            className="password_validation"
          />
          <span className="error_message" role="alert">
            {error}
          </span>
          <div className="buttons">
            <Button
              className="cancel button"
              text="Cancel"
              outlined
              onClick={handleCloseScreen}
              fullWidth
              UID="change_password"
            />
            <Button
              className="button"
              loading={loading}
              text={loading ? 'Updating' : 'Update'}
              type="submit"
              disabled={!passwordValid}
              fullWidth
              UID="change_password"
            />
          </div>
        </form>
      </StyleGuide>
    </StyledChangePassword>
  ) : null
}

ChangePassword.propTypes = {
  show: PropTypes.bool.isRequired,
  closeScreen: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  setOpenBanner: PropTypes.func.isRequired,
}

export default ChangePassword
