import React, { useEffect, useRef, useState } from 'react'
import Styled from 'styled-components'
import PropTypes from 'prop-types'

import { logOut } from '../../services/auth'
import {
  handleScrollIntoView,
  buildTestID,
  debounce,
} from '../../Utils/utils'
import LockIcon from '../../assets/lock-icon'
import PDFIcon from '../../assets/pdf-icon'
import BorrowerDetailIcon from '../../assets/borrow-details-icon'
import useWindowSize from '../../Hooks/useWindowSize'
import LeftChevronIcon from '../../assets/left-chevron-icon'
import SignOutIcon from '../../assets/sign-out-icon'
import { trackUserSignedOut } from '../../Global/Analytics'
import {
  LOGIN_SETTINGS,
  GO_PAPERLESS,
  BORROWER_DETAILS,
  NAV,
  ESIGN_CONSENT,
} from './index'
import ESignConsentIcon from '../../assets/esign-consent-icon'

const StyledNav = Styled.div`
display: flex;
flex-direction: column;
margin-top: 32px;

h1:focus, h1:focus-visible {
  outline: none;
  display: inline-block;
}

.title {
    margin-bottom: 40px;
}

.nav_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    margin-bottom: 12px;
    width: fit-content;
    border-radius: 30px;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};

    -webkit-appearance: none;
    -webkit-border-radius: none;
    border: none;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    text-transform: none;

    svg {
      margin-right: 16px;
    }
    
    svg:hover,
    :hover {
        color: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
        path {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }

    :focus-visible {
      outline: 2px solid #1460AA;
    }
}

.selected {
    background: ${({ theme }) => theme.colors.primary12};
    color: ${({ theme }) => theme.colors.primary};

    svg:hover,
    :hover {
        color: ${({ theme }) => theme.colors.primary};
        path {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    position: unset;

    .break_line {
      margin: 0;
    }

    .title {
      margin-bottom: 12px;
    }

    .nav_item {
      width: 100%;
      height: 72px;
      display: flex;
      justify-content: flex-start;
      margin: 0 6px;
      padding: 0;

      .chevron {
        position: absolute;
        right: 30px;
        transform: rotate(180deg);
      }
    }

    .log_out {
      width: 120px;
    }
  }
  `

const Nav = ({
  loginSettingsRef,
  goPaperlessRef,
  eSignConsentRef,
  borrowerDetailsRef,
  bannerVisible,
  currentScreen,
  setCurrentScreen,
}) => {
  const [selectedNav, setSelectedNav] = useState('Login Settings')
  const [scrollPosition, setScrollPosition] = useState(0)
  const [autoScroll, setAutoScroll] = useState(false)
  const [refPositions, setRefPositions] = useState({
    loginSettings: 0,
    goPaperless: 0,
    eSignConsent: 0,
    borrowerDetails: 0,
  })

  const { isSmall } = useWindowSize()

  const mainRef = useRef(null);

  useEffect(() => {
    mainRef.current.focus();
  }, [mainRef]);

  // sets initial section positions required due to dynamic
  // section heights based on screen size
  useEffect(() => {
    if (isSmall) return

    if (refPositions.goPaperless === 0) {
      setRefPositions({
        loginSettings: loginSettingsRef.current.getBoundingClientRect().y,
        goPaperless: goPaperlessRef.current.getBoundingClientRect().y,
        eSignConsent: eSignConsentRef.current.getBoundingClientRect().y,
        borrowerDetails: borrowerDetailsRef.current.getBoundingClientRect().y,
      })
    }
  }, [
    refPositions,
    loginSettingsRef,
    eSignConsentRef,
    goPaperlessRef,
    borrowerDetailsRef,
    isSmall,
  ])

  // scrolls to top on every mobile screen change
  useEffect(() => {
    if (isSmall) {
      window.scrollTo(0, 0)
    }
  }, [currentScreen, isSmall])

  // scroll to top when banner opens
  useEffect(() => {
    if (isSmall) return

    if (bannerVisible) {
      window.scrollTo(0, 0)
    }
  }, [isSmall, bannerVisible])

  // scrolls to go paperless section on page load if hash includes 'go-paperless'
  useEffect(() => {
    if (isSmall) return

    const { hash } = window.location
    if (hash.includes('go-paperless')) {
      setCurrentScreen(GO_PAPERLESS)
      setAutoScroll(true)
      setTimeout(() => setSelectedNav('Paperless Settings'), 600)
      setTimeout(() => setAutoScroll(false), 1000)
      if (isSmall) return

      handleScrollIntoView(goPaperlessRef.current, 'smooth', 'start')
    }
  }, [isSmall, setCurrentScreen, goPaperlessRef])

  // sets native scroll position in local state
  useEffect(() => {
    if (isSmall) return

    const handleScroll = () => {
      const position = window.pageYOffset
      setScrollPosition(position)
      setAutoScroll(false)

      if (
        refPositions.borrowerDetails === 0 &&
        refPositions.eSignConsent === 0 &&
        refPositions.goPaperless === 0 &&
        refPositions.loginSettings === 0
      )
        return false

      if (position >= refPositions.borrowerDetails - 32 ||
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 32
      ) {
        setSelectedNav('Borrower Details')
      }
      else if (
        position < refPositions.borrowerDetails - 32 &&
        position >= refPositions.eSignConsent - 100) {
        setSelectedNav('eSign Consent')
      }
      else if (
        position < refPositions.eSignConsent - 32 &&
        position >= refPositions.goPaperless - 100
      ) {
        setSelectedNav('Paperless Settings')
      } else {
        setSelectedNav('Login Settings')
      }
    }

    window.addEventListener('scroll', debounce(handleScroll, 300), {
      passive: true,
    })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isSmall, refPositions])

  // updates nav to reflect native scroll position
  useEffect(() => {
    if (isSmall || autoScroll) return
  }, [isSmall, autoScroll, scrollPosition, refPositions])

  // changes scroll position on nav click
  useEffect(() => {
    if (isSmall) return

    if (!autoScroll) return
    const options = { behavior: 'smooth', block: 'start' }
    if (selectedNav === 'Borrower Details')
      borrowerDetailsRef.current.scrollIntoView(options)

    if (selectedNav === 'eSign Consent')
      eSignConsentRef.current.scrollIntoView(options)

    if (selectedNav === 'Paperless Settings')
      goPaperlessRef.current.scrollIntoView(options)

    if (selectedNav === 'Login Settings')
      loginSettingsRef.current.scrollIntoView(options)
  }, [
    isSmall,
    selectedNav,
    autoScroll,
    goPaperlessRef,
    loginSettingsRef,
    eSignConsentRef,
    borrowerDetailsRef,
  ])

  const navItems = [
    {
      name: 'Login Settings',
      ID: LOGIN_SETTINGS,
      Svg: ({ color }) => <LockIcon color={color} />,
    },
    {
      name: 'Paperless Settings',
      ID: GO_PAPERLESS,
      Svg: ({ color }) => <PDFIcon color={color} />,
    },
    {
      name: 'eSign Consent',
      ID: ESIGN_CONSENT,
      Svg: ({ color }) => <ESignConsentIcon color={color} />,
    },
    {
      name: 'Borrower Details',
      ID: BORROWER_DETAILS,
      Svg: ({ color }) => <BorrowerDetailIcon color={color} />,
    },
  ]

  return currentScreen === NAV || !isSmall ? (
    <StyledNav>
      <h1 className="bold title heading-5" tabIndex={-1} ref={mainRef}>My Account</h1>
      {navItems.map(({ name, Svg, ID }) => {
        const selected = name === selectedNav && !isSmall
        return (
          <React.Fragment key={ID}>
            <a
              href={'#' + ID.toLowerCase()}
              data-testid={buildTestID(ID, '/account-settings')}
              className={`nav_item body-2 ${selected && 'selected'}`}
              aria-label={`${name} same page link`}
              aria-pressed={selected}
              name={name}
              role="button"
              onClick={(e) => {
                e.preventDefault()
                setAutoScroll(true)
                setCurrentScreen(ID)
                setSelectedNav(name)
              }}
            >
              <Svg color={selected ? 'primary' : 'black'} />
              {name}
              {isSmall ? (
                <LeftChevronIcon className="chevron" />
              ) : null}
            </a>
            {isSmall ? <div className="break_line" /> : null}
          </React.Fragment>
        )
      })}
      {isSmall ? (
        <a
          data-testid={buildTestID('log_out', '/account-settings')}
          className="nav_item log_out body-2"
          onClick={(e) => {
            e.preventDefault()
            trackUserSignedOut()
            logOut()
          }}
          href="/"
        >
          <SignOutIcon color="black" />
          Sign Out
        </a>
      ) : null}
    </StyledNav>
  ) : null
}

Nav.propTypes = {
  loginSettingsRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  goPaperlessRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  borrowerDetailsRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  bannerVisible: PropTypes.bool.isRequired,
  currentScreen: PropTypes.string.isRequired,
  setCurrentScreen: PropTypes.func.isRequired,
}

export default Nav
