import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const CalIcon = ({ className, color = 'black60' }) => {
  return (
    <StyledSVG
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      role="presentation"
    >
      <path
        fill="#5A5A5A"
        d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"
      ></path>
    </StyledSVG>
  )
}

export default CalIcon
