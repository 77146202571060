import { useState, useEffect } from 'react'
import { jsPDF } from 'jspdf'
import { useApiContext } from '../Hooks/useApiContext'
import { extractServicer } from '../Utils/apiExtractors'

export const useLOCAdvancePDF = ({
  RequestID = '',
  drawAmount = '',
  DateTimeOfReq = '',
  payType = '',
}) => {
  // default celink image and ratio if error loading loan data
  const [imgSizeRatio, setimgSizeRatio] = useState(3.25)
  const [img, setImage] = useState('./logos/3300.jpg')
  const { loan } = useApiContext()

  const doc = new jsPDF()
  const leftGlobalStart = 15

  // LOGO ------------------------------------------------------------------------------
  const {
    error: loanError,
    loading: loanLoading,
    data: loanData,
  } = loan



  useEffect(() => {
    const servicer = extractServicer(loanData)
    const logo = new Image()
    if (!loanError && !loanLoading) {
      logo.src = `./logos/${servicer.subservicerId}.jpg`
      logo.onload = function () {
        setimgSizeRatio(this.width / this.height)
      }
      setImage(logo.src)
    }
  }, [loanLoading, loanError, loanData])

  const imgHeight = 15
  const imgWidth = imgHeight * imgSizeRatio

  doc.addImage(img, 'JPEG', 15, 20, imgWidth, imgHeight)

  // ADDRESS BOX ------------------------------------------------------------------------
  const addressTopStart = 48
  const addressLineGap = 5

  doc.setFontSize(10)
  doc.setFont('Helvetica', 'bold')
  doc.text(
    'Reverse Mortgage Servicing Department',
    leftGlobalStart,
    addressTopStart,
  )
  doc.setFont('Helvetica', 'normal')
  doc.text(
    'P.O. Box 40738',
    leftGlobalStart,
    addressTopStart + addressLineGap,
  )
  doc.text(
    'Lansing, MI 48901',
    leftGlobalStart,
    addressTopStart + addressLineGap * 2,
  )
  doc.text(
    '(866) 654-0020 Office',
    leftGlobalStart,
    addressTopStart + addressLineGap * 3,
  )
  doc.text(
    '(844) 569-1565 FAX',
    leftGlobalStart,
    addressTopStart + addressLineGap * 4,
  )

  // SUBMITTED TEXT --------------------------------------------------------------------
  doc.setFontSize(13)
  doc.text(
    'Your Line of Credit Advance request has been submitted.',
    leftGlobalStart,
    105,
  )

  // LOC ADVANCE INFO BOX --------------------------------------------------------------
  const infoTextTopStart = 135
  const infoGap = 14
  const infoLeftGlobalStart = 22
  const infoLeftRightTextStart = 105

  doc.setDrawColor(1)
  doc.setFillColor('#f3f7fb')
  doc.rect(leftGlobalStart, 125, 180, 60, 'F')

  doc.setFont('Helvetica', 'bold')
  doc.text('Request ID', infoLeftGlobalStart, infoTextTopStart)
  doc.text(
    'Line of Credit Advance Amount',
    infoLeftGlobalStart,
    infoTextTopStart + infoGap,
  )
  doc.text(
    'Date/Time of Request*',
    infoLeftGlobalStart,
    infoTextTopStart + infoGap * 2,
  )
  doc.text(
    'Disbursement Method',
    infoLeftGlobalStart,
    infoTextTopStart + infoGap * 3,
  )

  doc.setFont('Helvetica', 'normal')
  doc.text(RequestID, infoLeftRightTextStart, infoTextTopStart)
  doc.text(
    drawAmount,
    infoLeftRightTextStart,
    infoTextTopStart + infoGap,
  )
  doc.text(
    DateTimeOfReq,
    infoLeftRightTextStart,
    infoTextTopStart + infoGap * 2,
  )
  doc.text(
    payType,
    infoLeftRightTextStart,
    infoTextTopStart + infoGap * 3,
  )

  doc.setLineWidth(0.3)
  doc.setDrawColor(211, 211, 211)
  doc.line(leftGlobalStart, 210, 195, 210)

  // CONTACT BORROWER CARE TEXT ---------------------------------------------------------
  doc.text(
    'If you need to make any changes please contact Borrower Care',
    leftGlobalStart,
    244,
  )
  doc.text('at (866) 654-0020 for support.', leftGlobalStart, 250)

  // DISCLAIMER TEXT --------------------------------------------------------------------
  const disclaimerTopStart = 262
  const disclaimerGap = 5
  doc.setFontSize(10)
  doc.text(
    '*A Line of Credit advance request submitted by 5pm ET on a business day will begin processing',
    leftGlobalStart,
    disclaimerTopStart,
  )
  doc.text(
    'on the same day and will be disbursed within 5 business days in accordance with your established',
    leftGlobalStart,
    disclaimerTopStart + disclaimerGap,
  )
  doc.text(
    'preferred method of payment. Requests that are submitted after 5pm ET, or on a weekend or',
    leftGlobalStart,
    disclaimerTopStart + disclaimerGap * 2,
  )
  doc.text(
    'federal holiday, will begin processing on the next business day.',
    leftGlobalStart,
    disclaimerTopStart + disclaimerGap * 3,
  )

  const downloadLOCAdvancePDF = () =>
    doc.save(`LOC_Submitted_Request_${RequestID.slice(0, 5)}.pdf`)

  return { downloadLOCAdvancePDF }
}
