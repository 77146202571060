import React from 'react'
import H1 from '../Common/H1'
import styled from 'styled-components'
import { Table } from 'react-bootstrap'

import Title from '../Common/Title'
import ScrollUpButton from '../Common/ScrollUpButton'

const StyledLicenses = styled.div`
  margin-top: 40px;
  h3 {
    text-align: center;
  }

  table {
    margin-top: 40px;
  }

  div.view-header {
    font-size: 15px;
  }

  thead {
    text-align: center;
  }

  th {
    width: 33.3%;
  }

  table.views-table th {
    font-size: 18px;
    text-transform: uppercase;
    color: rgb(20, 96, 170);
  }

  table.views-table td {
    text-align: center;
    padding: 0.2rem;
  }

  table.views-table tbody tr:nth-of-type(odd) {
    background-color: rgba(20, 96, 170, 0.1);
  }

  table.views-table tr.first-state-row td {
    border-top: 1px solid rgb(20, 96, 170);
  }
`

const Licenses = () => {
  return (
    <>
      <Title>Licenses</Title>
      <StyledLicenses>
        <main id="main" tabIndex="0">
          <H1>Licenses</H1>
          <h3>Compu-Link Corporation (D/B/A Celink)</h3>
          <h3>3900 Capital City Blvd</h3>
          <h3>Lansing, MI 48906</h3>

          <div className="view-content">
            <div className="table-responsive">
              <Table
                responsive
                className="views-table cols-3 table table-hover table-striped"
                summary='Compu-Link Corporation (D/B/A Celink)	 "3900 Capital City Blvd Lansing, MI  48906"	'
              >
                <thead>
                  <tr>
                    <th className="views-field views-field-field-state active views-align-center">
                      State{' '}
                    </th>
                    <th className="views-field views-field-field-license views-align-center">
                      License{' '}
                    </th>
                    <th className="views-field views-field-field-license-number views-align-center">
                      License Number{' '}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Alaska{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Business License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      980905{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Arizona{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      CA-0932170{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Arkansas{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      10176{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Arkansas{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      4664{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      California{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      “Licensed by the Department of Financial
                      Protection and Innovation under the California
                      Residential Mortgage Lending Act"
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      4131131{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Colorado{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      CAR-11000204{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Connecticut{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MS 3020{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Connecticut{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      CCA-3020{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      District of Columbia{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MLB3020{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Florida{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MLD540{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Florida{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Consumer Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      CCA9903586{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Georgia{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      6803{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Hawaii{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MS059{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Hawaii{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency Exempt{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      COLAX 782{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Idaho{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Broker/Lender License
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MBL-2080003020
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Illinois{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Residential Mortgage License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MB.0006846{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Indiana{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      N/A{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Iowa{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Banker License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      2006-0002{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Kansas{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Company License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MC.0001865{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Kentucky{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Company License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MC73885{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Louisiana{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Residential Mortgage Lending License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      N/A{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Louisiana{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      40996311I{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Maine{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Supervised Lender License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      3020{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Maryland{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      3020{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Massachusetts{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Debt Collector License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      DC3020{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Michigan{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      2401002677{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Michigan 1st{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      1st Mortgage Broker/Lender/ Servicer Registrant{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      FR-0021841{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Minnesota{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Residential Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MN-MS-20192020{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Mississippi{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      3020{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Missouri{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Residential Mortgage Loan Broker License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      17-1878-S{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Montana{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      3020{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Nebraska{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Banker License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      320{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Nevada{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      4385{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Nevada{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Business License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      NV20161515008{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Nevada{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      CAD11734{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Nevada{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Gloria Cantwell Collection Agency Compliance Manager{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      CM12779{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      New Hampshire{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      11568-MS{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      New Jersey{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency Bond{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      16134{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      New Jersey{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      N/A
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      New Mexico{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      02488{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      New York{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      NYC Debt Collection Agency License/Business
                      License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      2001047{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      New York{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Loan Servicer Registration
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      3020{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      New York: City of Buffalo{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      City of Buffalo Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      CAG13-10027280{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      North Carolina{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      S-146059{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      North Carolina{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      119506368{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      North Dakota{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MS104335{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Ohio{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Residential Mortgage Lending Act Certificate of
                      Registration{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      RM.804445.000{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Oklahoma{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender License - OTN #1{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      ML011054{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Oregon{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Business Registration{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      42712281
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Oregon{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Collection Agency Registration{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      50115{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Oregon{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MS-24{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Pennsylvania{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      67321{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Puerto Rico{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender/Servicer{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      IH-170{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Rhode Island{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Third-Party Loan Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      20163232LS{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      South Carolina{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender/Servicer License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MLS-3020{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      South Carolina{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender/Servicer License OTN #1{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      MLS - 3020 OTN #1{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      South Dakota{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      ML.04924{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Tennessee{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      109083{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Texas{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Third-Party Debt Collector Bond{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      20150062{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Texas{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Regulated Lender License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      150948{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Texas{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Residential Mortgage Loan Servicer Registration{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      N/A{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Utah{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Residential First Mortgage Notification{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      UDFI-RFMN2015{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Vermont{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Loan Servicer License (OTN #1){' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      3020-1{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Virgin Islands{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Business License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      581122{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Virgin Islands{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      VI-MLC-3020{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Washington{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Consumer Loan Company License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      CL-3020{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      Washington{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Business License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      603 018 607{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      West Virginia{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Lender License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      ML-31003{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="views-field views-field-field-state active views-align-center">
                      West Virginia{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Business Registration Certificate{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      1028-8593{' '}
                    </td>
                  </tr>
                  <tr className="first-state-row">
                    <td className="views-field views-field-field-state active views-align-center">
                      Wisconsin{' '}
                    </td>
                    <td className="views-field views-field-field-license views-align-center">
                      Mortgage Banker License{' '}
                    </td>
                    <td className="views-field views-field-field-license-number views-align-center">
                      21902BA{' '}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <ScrollUpButton />
        </main>
      </StyledLicenses>
    </>
  )
}

export default Licenses
