import React, { useEffect, useState } from 'react'
import Styled from 'styled-components'
import PropTypes from 'prop-types'

import { logOut } from '../../services/auth'
import {
  buildTestID,
} from '../../Utils/utils'
import useWindowSize from '../../Hooks/useWindowSize'
import SignOutIcon from '../../assets/sign-out-icon'
import { trackUserSignedOut } from '../../Global/Analytics'
import {
  MULTIFACTOR_SUCCESS,
  GO_PAPERLESS,
  CONTACT_INFO,
  NAV,
  CHANGE_EMAIL,
  STATEMENT_PREFERENCE_LEGAL,
  GO_PAPERLESS_SUCCESS,
} from './index'
import GreenLeafIcon from '../../assets/green-left-icon'
import EnvelopeIcon from '../../assets/envelope-icon'
import PersonIcon from '../../assets/person-icon'
import ContactCardIcon from '../../assets/contact-card-icon'
import EllipseBlueIcon from '../../assets/ellipse-blue-icon'
import CheckGreenIcon from '../../assets/check-green-icon'

const StyledNav = Styled.div`
display: flex;
flex-direction: column;
margin-top: 32px;

h1:focus, h1:focus-visible {
  outline: none;
  display: inline-block;
}

.title {
    margin-bottom: 40px;
}

.nav_item {
    display: flex;
    width: 320px;
    min-height: 50px;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: #E2F5FF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);
    margin: 0 0 15px 0;

    // svg {
    //   margin-right: 16px;
    // }
    
    // svg:hover,
    // :hover {
    //     color: ${({ theme }) => theme.colors.primary};
    //     cursor: pointer;
    //     path {
    //         fill: ${({ theme }) => theme.colors.primary};
    //     }
    // }

    :focus-visible {
      outline: 2px solid #1460AA;
    }
}

.selected {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);

    // svg:hover,
    // :hover {
    //     color: ${({ theme }) => theme.colors.primary};
    //     path {
    //         fill: ${({ theme }) => theme.colors.primary};
    //     }
    // }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    position: unset;

    .break_line {
      margin: 0;
    }

    .title {
      margin-bottom: 12px;
    }

    .nav_item {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      .chevron {
        position: absolute;
        right: 30px;
        transform: rotate(180deg);
      }
    }

    .log_out {
      width: 120px;
    }
  }
  `

const Nav = ({
  bannerVisible,
  currentScreen,
  setCurrentScreen,
  completed
}) => {
  const [, setSelectedNav] = useState('Paperless Settings')
  const { isSmall } = useWindowSize()

  // scrolls to top on every mobile screen change
  useEffect(() => {
    if (isSmall) {
      window.scrollTo(0, 0)
    }
  }, [currentScreen, isSmall])

  // scroll to top when banner opens
  useEffect(() => {
    if (isSmall) return

    if (bannerVisible) {
      window.scrollTo(0, 0)
    }
  }, [isSmall, bannerVisible])

  const navItems = [
    {
      name: 'Paperless Settings',
      ID: GO_PAPERLESS,
      Svg: ({ color }) => <GreenLeafIcon color={color} />,
    },
    {
      name: 'Multi-Factor Authentication',
      ID: MULTIFACTOR_SUCCESS,
      Svg: ({ color }) => <PersonIcon color={color} />,
    },
    // {
    //   name: 'Email',
    //   ID: CHANGE_EMAIL,
    //   Svg: ({ color }) => <EnvelopeIcon color={color} />,
    // },
    // {
    //   name: 'Contact Info',
    //   ID: CONTACT_INFO,
    //   Svg: ({ color }) => <ContactCardIcon color={color} />,
    // },
  ]

  const handleClick = (e, ID, name) => {
    e.preventDefault()

    if (ID === GO_PAPERLESS) {
      completed.includes(GO_PAPERLESS)
        ? setCurrentScreen(GO_PAPERLESS_SUCCESS)
        : setCurrentScreen(GO_PAPERLESS)
    }
    else {
      setCurrentScreen(ID)
    }
    setSelectedNav(name)
  }

  return currentScreen === NAV || !isSmall ? (
    <StyledNav>
      {navItems.map(({ name, Svg, ID }) => {
        const selected = (currentScreen === ID || ((currentScreen === STATEMENT_PREFERENCE_LEGAL || currentScreen === GO_PAPERLESS_SUCCESS) && ID === GO_PAPERLESS)) && !isSmall
        const isCompleted = completed.includes(ID)
        return (
          <React.Fragment key={ID}>
            <a
              href={'#' + ID.toLowerCase()}
              data-testid={buildTestID(ID, '/account-settings')}
              className={`nav_item body-2 ${selected && 'selected'}`}
              aria-label={`${name} same page link`}
              aria-pressed={selected}
              name={name}
              role="button"
              onClick={(e) => handleClick(e, ID, name)}
            >
              <Svg color={selected ? 'primary' : 'black'} />
              {name}

              {!isCompleted && currentScreen === ID ? (
                <span className="ml-auto"><EllipseBlueIcon /></span>
              ) : null}
              {!isCompleted && (currentScreen === STATEMENT_PREFERENCE_LEGAL || currentScreen === GO_PAPERLESS_SUCCESS) && ID === GO_PAPERLESS ? (
                <span className="ml-auto"><EllipseBlueIcon /></span>
              ) : null}
              {isCompleted ? (<span className="ml-auto"><CheckGreenIcon /></span>) : null}
            </a>
          </React.Fragment>
        )
      })}
    </StyledNav>
  ) : null
}

Nav.propTypes = {
  bannerVisible: PropTypes.bool.isRequired,
  currentScreen: PropTypes.string.isRequired,
  setCurrentScreen: PropTypes.func.isRequired,
  completed: PropTypes.array.isRequired,
}

export default Nav
