import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { NBS_FACTSHEET, SIGNATURE } from '.'

import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'
import useWindowSize from '../../Hooks/useWindowSize'
import { trackESignConsent } from '../../Global/Analytics'

const StyledThirdParty = styled.div`
  h1, h3 {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
  }

  .invalid {
    color: #dc3545
  }
  
  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin-bottom: 20px;
  }  

  input.form-control, select.form-control {
    height: 56px;
  }

  .form-control.is-invalid {
    background-image: none;
  }

  .row {
    flex-direction: column;
  }

  .mb-36 {
    margin-bottom: 36px;
  }
  
  .cb-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 24px;
  }
  
  .cb {
    height: 18px;
    width: 18px;
    margin-right: 12px;
  
    position: static;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
  
    /* Inside Auto Layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  .l-cb {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin: 0px 12px;
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    margin-bottom: 32px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }

    .flex {
      display: flex;
    }

    .skip {
      width: 200px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .thirdparty {
      background-color: #fff;
      padding: 15px 100px;
      margin-top: 32px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      text-align: center;
    }

    p {
      margin-left: -80px;
      margin-right: -80px
    }

    .row {
      flex-direction: row;
      margin: 0;
    }
  
    .col {
      padding: 0 10px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const ThirdParty = ({ borrower, nbs, setView, setModalShow, setThirdParty, setThirdPartyTimestamp, mainRef }) => {
  const [validFirstName, setValidFirstName] = useState(false)
  const [showFirstNameValidation, setShowFirstNameValidation] = useState(false)
  const [firstName, setFirstName] = useState()
  const [validLastName, setValidLastName] = useState(false)
  const [showLastNameValidation, setShowLastNameValidation] = useState(false)
  const [lastName, setLastName] = useState()
  const [validRelationship, setValidRelationship] = useState(false)
  const [showRelationshipValidation, setShowRelationshipValidation] = useState(false)
  const [relationship, setRelationship] = useState()
  const [validStreetAddress, setValidStreetAddress] = useState(false)
  const [showStreetAddressValidation, setShowStreetAddressValidation] = useState(false)
  const [streetAddress, setStreetAddress] = useState()
  const [validCity, setValidCity] = useState(false)
  const [showCityValidation, setShowCityValidation] = useState(false)
  const [city, setCity] = useState()
  const [validState, setValidState] = useState(false)
  const [showStateValidation, setShowStateValidation] = useState(false)
  const [state, setState] = useState()
  const [validPostalCode, setValidPostalCode] = useState(false)
  const [showPostalCodeValidation, setShowPostalCodeValidation] = useState(false)
  const [postalCode, setPostalCode] = useState();
  const [agreeChecked, setAgreeChecked] = useState(false)
  const [showAgreeValidtion, setShowAgreeValidation] = useState(false)
  const [homePhone, setHomePhone] = useState();
  const [workPhone, setWorkPhone] = useState();
  const [workExtension, setWorkExtension] = useState();
  const [mobilePhone, setMobilePhone] = useState();
  const [loading] = useState(false)
  const [invalid] = useState(false)

  const { isSmall } = useWindowSize()

  useEffect(() => {
    if (invalid)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
  }, [invalid])

  const relationships = [
    "Sister in Law",
    "Son",
    "Son in Law",
    "Spouse",
    "Step Daughter",
    "Step Son",
    "Realtor",
    "Financial Advisor",
    "Caregiver",
    "Domestic Partner",
    "Cousin",
    "Remainderman",
    "Brother",
    "Brother in Law",
    "Non-Borrowing Spouse",
    "Daughter",
    "Daughter in Law",
    "Friend",
    "Grandchild",
    "Nearest Living Relative",
    "Neighbor",
    "Nephew",
    "Niece",
    "Other",
    "Parent",
    "Religious Leader",
    "Sister",
    "POA"]

  const states = [
    {
      "name": "Alabama",
      "abbreviation": "AL"
    },
    {
      "name": "Alaska",
      "abbreviation": "AK"
    },
    {
      "name": "American Samoa",
      "abbreviation": "AS"
    },
    {
      "name": "Arizona",
      "abbreviation": "AZ"
    },
    {
      "name": "Arkansas",
      "abbreviation": "AR"
    },
    {
      "name": "California",
      "abbreviation": "CA"
    },
    {
      "name": "Colorado",
      "abbreviation": "CO"
    },
    {
      "name": "Connecticut",
      "abbreviation": "CT"
    },
    {
      "name": "Delaware",
      "abbreviation": "DE"
    },
    {
      "name": "District Of Columbia",
      "abbreviation": "DC"
    },
    {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
    },
    {
      "name": "Florida",
      "abbreviation": "FL"
    },
    {
      "name": "Georgia",
      "abbreviation": "GA"
    },
    {
      "name": "Guam",
      "abbreviation": "GU"
    },
    {
      "name": "Hawaii",
      "abbreviation": "HI"
    },
    {
      "name": "Idaho",
      "abbreviation": "ID"
    },
    {
      "name": "Illinois",
      "abbreviation": "IL"
    },
    {
      "name": "Indiana",
      "abbreviation": "IN"
    },
    {
      "name": "Iowa",
      "abbreviation": "IA"
    },
    {
      "name": "Kansas",
      "abbreviation": "KS"
    },
    {
      "name": "Kentucky",
      "abbreviation": "KY"
    },
    {
      "name": "Louisiana",
      "abbreviation": "LA"
    },
    {
      "name": "Maine",
      "abbreviation": "ME"
    },
    {
      "name": "Marshall Islands",
      "abbreviation": "MH"
    },
    {
      "name": "Maryland",
      "abbreviation": "MD"
    },
    {
      "name": "Massachusetts",
      "abbreviation": "MA"
    },
    {
      "name": "Michigan",
      "abbreviation": "MI"
    },
    {
      "name": "Minnesota",
      "abbreviation": "MN"
    },
    {
      "name": "Mississippi",
      "abbreviation": "MS"
    },
    {
      "name": "Missouri",
      "abbreviation": "MO"
    },
    {
      "name": "Montana",
      "abbreviation": "MT"
    },
    {
      "name": "Nebraska",
      "abbreviation": "NE"
    },
    {
      "name": "Nevada",
      "abbreviation": "NV"
    },
    {
      "name": "New Hampshire",
      "abbreviation": "NH"
    },
    {
      "name": "New Jersey",
      "abbreviation": "NJ"
    },
    {
      "name": "New Mexico",
      "abbreviation": "NM"
    },
    {
      "name": "New York",
      "abbreviation": "NY"
    },
    {
      "name": "North Carolina",
      "abbreviation": "NC"
    },
    {
      "name": "North Dakota",
      "abbreviation": "ND"
    },
    {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
    },
    {
      "name": "Ohio",
      "abbreviation": "OH"
    },
    {
      "name": "Oklahoma",
      "abbreviation": "OK"
    },
    {
      "name": "Oregon",
      "abbreviation": "OR"
    },
    {
      "name": "Palau",
      "abbreviation": "PW"
    },
    {
      "name": "Pennsylvania",
      "abbreviation": "PA"
    },
    {
      "name": "Puerto Rico",
      "abbreviation": "PR"
    },
    {
      "name": "Rhode Island",
      "abbreviation": "RI"
    },
    {
      "name": "South Carolina",
      "abbreviation": "SC"
    },
    {
      "name": "South Dakota",
      "abbreviation": "SD"
    },
    {
      "name": "Tennessee",
      "abbreviation": "TN"
    },
    {
      "name": "Texas",
      "abbreviation": "TX"
    },
    {
      "name": "Utah",
      "abbreviation": "UT"
    },
    {
      "name": "Vermont",
      "abbreviation": "VT"
    },
    {
      "name": "Virgin Islands",
      "abbreviation": "VI"
    },
    {
      "name": "Virginia",
      "abbreviation": "VA"
    },
    {
      "name": "Washington",
      "abbreviation": "WA"
    },
    {
      "name": "West Virginia",
      "abbreviation": "WV"
    },
    {
      "name": "Wisconsin",
      "abbreviation": "WI"
    },
    {
      "name": "Wyoming",
      "abbreviation": "WY"
    }
  ];

  const validateFirstName = (e) => {
    const firstName = e.target.value.trim()
    if (!firstName.length) {
      setValidFirstName(false)
      setShowFirstNameValidation(true)
      return
    }

    setValidFirstName(true)
    setShowFirstNameValidation(false)
  }

  const validateLastName = (e) => {
    const lastName = e.target.value.trim()
    if (!lastName.length) {
      setValidLastName(false)
      setShowLastNameValidation(true)
      return
    }

    setValidLastName(true)
    setShowLastNameValidation(false)
  }

  const validateRelationship = (e) => {
    const relationship = e.target
    if (relationship.selectedIndex === 0) {
      setValidRelationship(false)
      setShowRelationshipValidation(true)
      return
    }

    setValidRelationship(true)
    setShowRelationshipValidation(false)
  }

  const validateStreetAddress = (e) => {
    const streetAddress = e.target.value.trim()
    if (!streetAddress.length) {
      setValidStreetAddress(false)
      setShowStreetAddressValidation(true)
      return
    }

    setValidStreetAddress(true)
    setShowStreetAddressValidation(false)
  }

  const validateCity = (e) => {
    const city = e.target.value.trim()
    if (!city.length) {
      setValidCity(false)
      setShowCityValidation(true)
      return
    }

    setValidCity(true)
    setShowCityValidation(false)
  }

  const validateState = (e) => {
    const state = e.target
    if (state.selectedIndex === 0) {
      setValidState(false)
      setShowStateValidation(true)
      return
    }

    setValidState(true)
    setShowStateValidation(false)
  }

  const validatePostalCode = (e) => {
    const zipcode = e.target.value.trim()
    if (!zipcode.length) {
      setValidPostalCode(false)
      setShowPostalCodeValidation(true)
      return
    }

    setValidPostalCode(true)
    setShowPostalCodeValidation(false)
  }

  const validateAgree = (e) => {
    if (!agreeChecked) {
      setShowAgreeValidation(true)
      return
    }

    setShowAgreeValidation(false)
  }

  const handlePostalCodeChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "")
    setPostalCode(value)
  }

  const handleHomePhoneChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "")
    setHomePhone(value)
  }

  const handleWorkPhoneChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "")
    setWorkPhone(value)
  }

  const handleWorkExtensionChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "")
    setWorkExtension(value)
  }

  const handleMobilePhoneChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "")
    setMobilePhone(value)
  }

  const handleAgreeClick = (e) => {
    var checked = e.target.checked;
    setAgreeChecked(checked)
    checked ? setThirdPartyTimestamp(Date.now()) : setThirdPartyTimestamp(null)
    checked ? setShowAgreeValidation(false) : setShowAgreeValidation(true)
  }

  const handleClick = async () => {
    const thirdParty = {
      "firstName": firstName,
      "lastName": lastName,
      "relationship": relationship,
      "streetAddress": streetAddress,
      "city": city,
      "state": state,
      "zipCode": postalCode,
      "homePhone": homePhone,
      "workPhone": workPhone,
      "mobilePhone": mobilePhone
    }

    trackESignConsent.StepCompleted('completed a3p information', 'thirdparty_button_next')
    setThirdParty(thirdParty)
    nbs ? setView(NBS_FACTSHEET) : setView(SIGNATURE)
  }

  return (
    <StyledThirdParty>
      <div className="thirdparty"
        data-gridcolumnlayout={{
          xl: '3/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Add Authorized Third Party</h1>
        <p className='sr-only'>optional if you do not want to add a third party you may omit the form by selecting the skip this section button after the form fields, at the end of the page</p>
        <p>If you would like to give permission to us to discuss your account and/or share copies of your loan documents with anyone other than yourself (son, daughter, etc.), please complete the Authorized Third Party form below.</p>
        <p><b><em>Please note that completing this section is optional</em></b>.</p>
        {
          invalid && (
            <h5 className='invalid'>Something went wrong. Please try again later.</h5>
          )
        }
        <Form>
          <Row><Col><h4>Authorized Third Party Form (optional)</h4></Col></Row>
          <Row>
            <Col>
              <Form.Group className="mb-36" controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text"
                  name="firstName"
                  placeholder="Enter first name"
                  className={`${showFirstNameValidation ? "is-invalid" : ""}`}
                  onBlur={validateFirstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  aria-required="true"
                />
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please enter your first name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-36" controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text"
                  name="lastName"
                  placeholder="Enter last name"
                  className={`${showLastNameValidation ? "is-invalid" : ""}`}
                  onBlur={validateLastName}
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  aria-required="true"
                />
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please enter your last name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-36" controlId="relationship">
                <Form.Label>Relationship to Borrower</Form.Label>
                <Form.Control as="select"
                  className={`${showRelationshipValidation ? "is-invalid" : ""}`}
                  onBlur={validateRelationship}
                  onChange={(e) => setRelationship(e.target.value)}
                  value={relationship}
                  aria-required="true">
                  <option>Select relationship</option>
                  {relationships.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please select relationship to borrower.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-36" controlId="streetAddress">
                <Form.Label>Street Address</Form.Label>
                <Form.Control type="text"
                  name="streetAddress"
                  placeholder="Enter street address"
                  className={`${showStreetAddressValidation ? "is-invalid" : ""}`}
                  onBlur={validateStreetAddress}
                  onChange={(e) => setStreetAddress(e.target.value)}
                  value={streetAddress}
                  aria-required="true"
                />
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please enter your street address.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-36" controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control type="text"
                  className={`form-control ${showCityValidation ? "is-invalid" : ""}`}
                  name="city"
                  placeholder="Enter city"
                  onBlur={validateCity}
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  aria-required="true"
                />
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please enter your city.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-36" controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control as="select"
                  className={`${showStateValidation ? "is-invalid" : ""}`}
                  onBlur={validateState}
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                  aria-required="true">
                  <option>Select state</option>
                  {states.map(item => (
                    <option key={item.abbreviation} value={item.abbreviation}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please select your state.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-36" controlId="postalCode">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control type="text"
                  name="postalCode"
                  placeholder="Enter zip code"
                  className={`${showPostalCodeValidation ? "is-invalid" : ""}`}
                  onBlur={validatePostalCode}
                  onChange={handlePostalCodeChange}
                  minLength={5}
                  maxLength={5}
                  value={postalCode}
                  aria-required="true"
                />
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please enter your zip code.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-36" controlId="homePhone">
                <Form.Label>Home Phone (optional)</Form.Label>
                <Form.Control type="tel"
                  name="homePhone"
                  placeholder="Enter home phone"
                  onChange={handleHomePhoneChange}
                  maxLength={15}
                  value={homePhone}
                  aria-describedby="home-desc"
                />
                <span id="home-desc" className="sr-only">Home phone can be up to 15 digits</span>
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please enter your home phone.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-36" controlId="workPhone">
                <Form.Label>Work Phone (optional)</Form.Label>
                <Form.Control type="tel"
                  name="workPhone"
                  placeholder="Enter work phone"
                  onChange={handleWorkPhoneChange}
                  maxLength={15}
                  value={workPhone}
                  aria-describedby="work-desc"
                />
                <span id="work-desc" className="sr-only">Work phone can be up to 15 digits</span>
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please enter your work phone.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-36" controlId="workExtension">
                <Form.Label>Work Extension (optional)</Form.Label>
                <Form.Control type="tel"
                  name="workExtension"
                  placeholder="Enter work extension"
                  onChange={handleWorkExtensionChange}
                  maxLength={15}
                  value={workExtension}
                  aria-describedby="work-ext-desc"
                />
                <span id="work-ext-desc" className="sr-only">Work extension can be up to 15 digits</span>
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please enter your work extension.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-36" controlId="mobilePhone">
                <Form.Label>Mobile Phone (optional)</Form.Label>
                <Form.Control type="tel"
                  name="mobilePhone"
                  placeholder="Enter mobile phone"
                  onChange={handleMobilePhoneChange}
                  maxLength={15}
                  value={mobilePhone}
                  aria-describedby="mobile-desc"
                />
                <span id="mobile-desc" className="sr-only">Mobile phone number can be up to 15 digits</span>
                <Form.Control.Feedback type="invalid" aria-live="assertive" role="status">
                  Please enter your mobile phone.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="cb-container">
                <input
                  className={"cb"}
                  type="checkbox"
                  id={`chk-agree`}
                  name="chk-agree"
                  onClick={handleAgreeClick}
                  onBlur={validateAgree}
                  aria-label="Agree to add authorized party checkbox"
                  data-testid={buildTestID('chk_agree', '/esign', 'third_party',)}
                  aria-required="true"
                />
                <label htmlFor="chk-agree" className="l-cb">I agree to add this person as an authorized party on my account.</label>
              </div>
              <Form.Control.Feedback type="invalid" aria-live="assertive" role="status" style={{ display: showAgreeValidtion ? "block" : "none" }}>
                Please click the checkbox to agree to add this person as an authorized party on my account.
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form>
        <div className="buttons">
          <Button
            className="cancel button"
            text="Cancel"
            outlined
            fullWidth
            UID="thirdparty_button_cancel"
            onClick={() => setModalShow(true)}
          />
          <div className='flex'>
            {
              !isSmall && (
                <Button
                  className="cancel skip button"
                  text="Skip This Section"
                  outlined
                  fullWidth
                  UID="thirdparty_button_skip"
                  onClick={() => {
                    trackESignConsent.Skip('skipped a3p information', 'thirdparty_button_skip')
                    nbs ? setView(NBS_FACTSHEET) : setView(SIGNATURE)
                  }}
                />
              )
            }
            <Button
              className="button"
              text={"Next"}
              fullWidth
              UID="thirdparty_button_next"
              disabled={!validFirstName || !validLastName || !validRelationship || !validStreetAddress || !validCity || !validState || !validPostalCode || !agreeChecked}
              loading={loading}
              onClick={handleClick}
            />
          </div>
        </div>
        {
          isSmall && (
            <Button
              className="cancel skip button"
              text="Skip This Section"
              outlined
              fullWidth
              UID="thirdparty_button_skip"
              onClick={() => {
                trackESignConsent.Skip('skipped a3p information', 'thirdparty_button_skip')
                nbs ? setView(NBS_FACTSHEET) : setView(SIGNATURE)
              }}
            />
          )
        }
      </div>
    </StyledThirdParty>
  )
}

export default ThirdParty