import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const LockIconThree = () => {
    return (
        <StyledSVG
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path d="M6.9004 21.0991C6.45642 21.0991 6.07866 20.9421 5.76713 20.628C5.45558 20.314 5.2998 19.9351 5.2998 19.4914V10.5069C5.2998 10.0632 5.45683 9.68428 5.77088 9.37024C6.08491 9.05619 6.46379 8.89917 6.9075 8.89917H7.6998V6.49917C7.6998 5.3058 8.11868 4.2908 8.95643 3.45417C9.79416 2.61754 10.8105 2.19922 12.0054 2.19922C13.2004 2.19922 14.2148 2.61754 15.0488 3.45417C15.8828 4.2908 16.2998 5.3058 16.2998 6.49917V8.89917H17.0921C17.5358 8.89917 17.9146 9.05619 18.2287 9.37024C18.5427 9.68428 18.6998 10.0632 18.6998 10.5069V19.4914C18.6998 19.9351 18.5426 20.314 18.2284 20.628C17.9142 20.9421 17.535 21.0991 17.0911 21.0991H6.9004ZM6.9075 19.7991H17.0921C17.1818 19.7991 17.2555 19.7703 17.3132 19.7126C17.3709 19.6549 17.3998 19.5812 17.3998 19.4914V10.5069C17.3998 10.4171 17.3709 10.3434 17.3132 10.2857C17.2555 10.228 17.1818 10.1991 17.0921 10.1991H6.9075C6.81775 10.1991 6.74403 10.228 6.68633 10.2857C6.62863 10.3434 6.59978 10.4171 6.59978 10.5069V19.4914C6.59978 19.5812 6.62863 19.6549 6.68633 19.7126C6.74403 19.7703 6.81775 19.7991 6.9075 19.7991ZM12.0051 16.5491C12.4374 16.5491 12.803 16.398 13.1017 16.0957C13.4004 15.7935 13.5498 15.4262 13.5498 14.9938C13.5498 14.5615 13.3986 14.1959 13.0964 13.8972C12.7941 13.5985 12.4268 13.4492 11.9945 13.4492C11.5621 13.4492 11.1966 13.6003 10.8979 13.9025C10.5992 14.2048 10.4498 14.5721 10.4498 15.0044C10.4498 15.4368 10.6009 15.8023 10.9032 16.101C11.2054 16.3998 11.5727 16.5491 12.0051 16.5491ZM8.99978 8.89917H14.9998V6.49917C14.9998 5.66584 14.7081 4.9575 14.1248 4.37417C13.5414 3.79084 12.8331 3.49917 11.9998 3.49917C11.1664 3.49917 10.4581 3.79084 9.87478 4.37417C9.29145 4.9575 8.99978 5.66584 8.99978 6.49917V8.89917Z" fill="#475468" />
        </StyledSVG>
    )
}

export default LockIconThree