import React from "react";
import styled from "styled-components";
import PlayIcon from "../assets/play-icon";
import Button from "../Common/button";
import { SIGN_IN } from "./AuthLayout";

const StyledLandingCreateAccount = styled.div`
justify-content: center;
border-radius: 20px;
box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
background-color: rgba(255, 255, 255, 0.95);
display: flex;
flex-direction: column;
font-size: 16px;
padding: 20px;
margin-left: 15px;
margin-bottom: 40px;
text-align: center;

.heading {
    color: var(--Black, #000);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

ul {
    column-count: 2;
    text-align: left;
    
    li {
      margin-right: 10px;
    }
}

.text {
  display: inline-flex;

  button {
    text-transform: none;
  }
}

@media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    width: 610px;
    padding: 80px 40px;
  }
`;

const BottomSection = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 30px;
  gap: 10px;
  font-size: 18px;
  

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const CreateAccountButton = styled.a`
  font-family: Lato, sans-serif;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--Dark-Grey, #475468);
  color: var(--Surface, #fff);
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  padding: 10px 20px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const LandingPageCreateAccount = ({ setModalShow, setActiveFormTab, setCurrentTab }) => {
  const toggleModal = () => {
    setModalShow(true)
  }

  const handleSignIn = (e) => {
    e.preventDefault();
    setCurrentTab(SIGN_IN);
    setActiveFormTab(SIGN_IN);
  }

  return (
    <StyledLandingCreateAccount>
      <p className="heading">Online account for managing reverse mortgage.</p>
      <ul>
        <li>Request a line of credit draw </li>
        <li>Get statements</li>
        <li>Complete your annual occupancy certification</li>
        <li>Access and upload forms</li>
        <li>See loan information</li>
        <li>Get a payoff quote</li>
        <li>View transaction history</li>
      </ul>
      <p className="text-left"><b>Recently Refinanced?</b> If you have already registered and have an online account for your prior loan, have refinanced, and now want access to your new loan online, <a href="" onClick={(e) => handleSignIn(e)}>sign in</a> with your existing account. You will be directed to your active loan.</p>
      <BottomSection>
        <CreateAccountButton href="/register">Create Account</CreateAccountButton>
      </BottomSection>
      <BottomSection>
        <PlayIcon /><p>Need Help Registering? Watch our registration video <span className='text'><Button text="here" UID="registration_video" textButton ariaLabel="Click here to watch our registration video" onClick={toggleModal} /></span></p>
      </BottomSection>
    </StyledLandingCreateAccount>
  );
}

export default LandingPageCreateAccount;