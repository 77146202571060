import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import StyleGuide from '../../../Global/StyleGuide'
import Button from '../../../Common/button'
import useWindowSize from '../../../Hooks/useWindowSize'
import { buildTestID } from '../../../Utils/utils'
import { trackDeliveryPreferenceRadioSelected } from '../../../Global/Analytics'
import { updateStatementPreference } from '../../../services/api'
import { trackGoPaperless } from '../../../Global/Analytics'

const StyledStatementPreference = styled(Modal)`
  .modal-content {
    padding: 32px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
  }

  .modal-width {
    max-width: 450px !important;
  }

  .title {
    margin: 0;
  }

  .sub_heading {
    margin: 16px 0 0 0;
  }

  .radio_group {
    display: flex;
    margin: 20px 0 0 0;

    input {
      margin-top: 4px;
      width: 20px;
      height: 20px;
    }

    label {
      margin: 0 0 0 18px;
      width: -webkit-fill-available;
      width: -moz-fit-content;
    }
  }

  .email_notification_text {
    margin: 12px 0 24px 34px;
  }

  .error_text {
    color: ${({ theme }) => theme.colors.error};
    margin: 0 0 24px 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 32px;

    .cancel {
      margin-right: 24px;
    }
  }

  .button {
    width: 162px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 24px 0 0 0;

    .button {
      width: 100%;
    }

    .buttons {
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const StatementPreference = ({
  closeScreen,
  show,
  deliveryOnline,
  openLegalScreen,
  setOpenBanner,
  refreshLoanData,
  setDeliveryOnline,
}) => {
  const [radioDeliveryOnline, setRadioDeliveryOnline] =
    useState(deliveryOnline)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const { isSmall } = useWindowSize()

  const handleCloseScreen = () => {
    closeScreen()
    setError('')
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      const response = await updateStatementPreference('0')
      if(response.data.updatePaperless.responseCode !== 200) 
        throw new Error('Error updating statement preference. Please try again.')

      setLoading(false)
      handleCloseScreen()
      setOpenBanner({
        type: 'success',
        text: 'You have opted out of Paperless Delivery.',
      })
      refreshLoanData()
      setDeliveryOnline(false)
      trackGoPaperless.Completed({ selected_go_paperless: false })
    } catch (e) {
      setLoading(false)
      console.log('error updating: ', e)
      setError('Network error. Please try again.')
    }
  }

  let ModalOrDivProps = {
    onHide: handleCloseScreen,
    show,
    centered: true,
    size: 'md',
    as: isSmall ? 'div' : Modal,
    dialogClassName: 'modal-width',
  }

  if (isSmall) delete ModalOrDivProps.onHide

  return show ? (
    <StyledStatementPreference {...ModalOrDivProps}>
      <StyleGuide>
        <h3 className="title heading-6">Delivery Preference</h3>
        <div>
          <div className="radio_group">
            <input
              type="radio"
              id="mail"
              checked={!radioDeliveryOnline}
              onChange={() => {
                trackDeliveryPreferenceRadioSelected.Mail()
                setRadioDeliveryOnline(false)
              }}
              data-testid={buildTestID(
                'mail',
                '/account-settings',
                'statement_preference',
              )}
            />
            <label htmlFor="mail" className="subtitle-2 sub_heading">
              I’d like to receive correspondence by mail.
            </label>
          </div>
          <div className="radio_group">
            <input
              type="radio"
              id="online"
              checked={radioDeliveryOnline}
              onChange={() => {
                trackDeliveryPreferenceRadioSelected.Online()
                setRadioDeliveryOnline(true)
              }}
              data-testid={buildTestID(
                'online',
                '/account-settings',
                'statement_preference',
              )}
            />
            <label
              htmlFor="online"
              className="subtitle-2 sub_heading"
            >
              I’d like to receive correspondence by logging onto my
              account and viewing it online.
            </label>
          </div>
        </div>
        <p className="caption email_notification_text">
          You will get an email notification when any correspondence is
          ready to view.
        </p>
        <p className="caption error_text" role="alert">
          {error}
        </p>
        <div className="buttons">
          <Button
            className="cancel button"
            text="Cancel"
            outlined
            onClick={handleCloseScreen}
            disabled={null}
            aria-disabled={null}
            UID="statement_preference"
            fullWidth
          />
          <Button
            className="button"
            text={
              deliveryOnline
                ? loading
                  ? 'Saving'
                  : 'Save'
                : 'Continue'
            }
            UID="statement_preference"
            onClick={deliveryOnline ? handleSave : openLegalScreen}
            disabled={radioDeliveryOnline === deliveryOnline}
            aria-disabled={radioDeliveryOnline === deliveryOnline}
            ariaLabel={radioDeliveryOnline === deliveryOnline ? "disable continue" : "continue"}
            fullWidth
            loading={loading}
          />
        </div>
      </StyleGuide>
    </StyledStatementPreference>
  ) : null
}

StatementPreference.propTypes = {
  show: PropTypes.bool.isRequired,
  closeScreen: PropTypes.func.isRequired,
  deliveryOnline: PropTypes.bool.isRequired,
  openLegalScreen: PropTypes.func.isRequired,
  setOpenBanner: PropTypes.func.isRequired,
  refreshLoanData: PropTypes.func.isRequired,
  setDeliveryOnline: PropTypes.func.isRequired,
}

export default StatementPreference
