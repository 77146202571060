import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import CommonQuestionCategories from './CommonQuestionCategories'


const LossDraft = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.LossDraft, title)
  })

  return (
    <>
      <h3
        ref={title}
        id="loss-draft-insurance-claims-property-damage"
        tabIndex="-1"
      >
        Insurance Claims (Loss Draft)/Property Damage
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              What happens if I have an insurance loss to my property?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              If you experience damage to your property due to a fire, flood, or natural disaster, your insurance company may issue a check for the loss that is payable to both you and your reverse mortgage company. Please contact us at 800-266-6337 to start the Loss Draft process. Our Loss Draft support team is ready to assist you with your claim. Their support center hours are 8:00 am – 6:00 pm EST. Monday – Friday. Please know that guidelines may require us to monitor the progress of the repairs through to completion. Completed repairs may require an inspection prior to funds being issued. We will work with you to monitor the progress and make disbursements to you or your contractors as required. Please contact us for further clarification or to discuss your specific loss.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <h5>Insurance Claims (Loss Draft)/Natural Disaster</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              What happens if I have damage to my property due to a fire, flood, or natural disaster and I have check from my insurance company?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              If you experience damage to your property due to a fire, flood, or natural disaster, your insurance company may issue a check for the loss that is payable to both you and your reverse mortgage company. Please contact us at 800-266-6337 to start the Loss Draft process. Our Loss Draft support team is ready to assist you with your claim.  Their support center hours are 8:00 am – 6:00 pm EST. Monday – Friday.  Please know that guidelines may require us to monitor the progress of the repairs through to completion. Completed repairs may require an inspection prior to funds being issued. We will work with you to monitor the progress and make disbursements to you or your contractors as required. Please contact us for further clarification or to discuss your specific loss.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              What is the contact information for the Loss Draft Insurance Claim/Property Damage team?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              Phone number - 800-266-6337<br />
              Hours - 8:00 am – 6:00 pm EST. Monday – Friday<br />
              Fax to 248-710-1690
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              Where do I send my check so that it can be endorsed?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              Regular Mail:<br />
              Reverse Mortgage Servicing Department c/o PLP<br />
              Attention: Loss Draft Department<br />
              PO Box 39608<br />
              Solon, OH  44139<br /><br />

              Overnight Mail:<br />
              Reverse Mortgage Servicing Department c/o PLP<br />
              Attention:  Loss Draft Department<br />
              6680 Parkland Blvd.<br />
              Solon, OH 44139
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              Why is the check from my insurance company made out to me and my reverse mortgage company?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              The mortgage company has a financial interest in your home.  We want to make sure the home is restored back to the prior condition before the loss.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              How does my contractor get paid?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              If your contractor is requesting payment, please call the loss draft insurance claim team at 800-266-6337 to request a disbursement. An initial draw can be released if we have all required documentation on file, including the signed contractor's agreement. Inspection results verifying work completed is required to release additional funds.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="5">
              How long after I send you my check will I receive my money?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              Please allow 7-10 business days once the completed documents are received before your initial disbursement is released.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
              I received an initial draw, but still have funds needed for repairs.  When will I receive those funds?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              After the initial draw, additional draws will be based on the percentage of work complete.  The following items will be required each time a draw is requested:<br />
              •	Inspection verifying percentage of work completed<br />
              •	Waiver of lien/sworn statement from the contractor<br />
              Please call the loss draft insurance claim team at 800-266-6337 to schedule the inspection
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default LossDraft
