import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { requestAuthCode } from '../../services/api'
import ScrollToTop from '../../Global/ScrollToTop'
import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'
import {
  trackLOCRequest,
  trackLOCRadioSelected,
} from '../../Global/Analytics'
import { REVIEW_REQUEST, ENTER_VERIFY_CODE } from './index'

const StyledVerifyAccount = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0 40px;

  .title_content {
    margin: 48px auto;

    h6 {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  .verify_content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 56px;

    .input_group {
      display: flex;
      margin-bottom: 16px;
      border: 1px solid rgba(0, 55, 122, 0.56);
      border-radius: 4px;
      padding: 20px;
      text-align: start;

      input {
        margin-right: 20px;
        margin-top: 4px;
        height: 20px;
        width: 20px;
        color: #902d63;
      }

      label {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.15px;
        color: ${({ theme }) => theme.colors.black87};
        margin-bottom: 0;
        overflow-wrap: anywhere;
      }
    }
  }

  .break_line {
    width: 100%;
    border: 0.5px solid ${({ theme }) => theme.colors.black12};
    margin: 0;
  }

  .card_buttons {
    display: flex;
    justify-content: space-between;
    margin: 32px 0;

    .back_btn {
      margin-right: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 32px 16px;

    .title_content {
      width: 100%;
      margin: 0 0 40px 0;

      p {
        margin: 0;
      }
    }

    .verify_content {
      width: 100%;
      margin-bottom: 20px;

      .input_group {
        padding: 20px 20px 20px 0;

        input {
          margin-left: 20px;
        }

        .email_label {
          display: flex;
          flex-direction: column;
          width: 100%;

          div:first-child {
            align-self: start;
            margin-left: 54px;
          }
        }
      }
    }

    .break_line {
      width: 100%;
    }

    .card_buttons {
      margin: 32px 0 0 0;
    }
  }
`

const VerifyAccount = ({
  setCurrentScreen,
  verificationMethod,
  setVerificationMethod,
  setVerificationValue,
  user,
}) => {
  let splitEmail = user.email.split('@')
  splitEmail[0] = splitEmail[0].slice(0, 2) + '*****'

  const hiddenEmail = splitEmail.join('@')

  let hiddenPhone = ''

  if (user.phone) {
    let splitPhone = user.phone.slice(-4)
    splitPhone = 'xxx-xxx-' + splitPhone
    hiddenPhone = splitPhone
  }

  return (
    <>
      <ScrollToTop />
      <StyledVerifyAccount>
        <div className="title_content">
          <h6>Help us confirm it’s really you</h6>
          <p>
            Your account security is important to us. Where should we
            send your verification code?{' '}
          </p>
        </div>
        <div className="verify_content">
          <div className="input_group">
            <input
              data-testid={buildTestID(
                'email_verify',
                '/advance_request',
                'verify_account',
              )}
              type="radio"
              id="email-verify"
              name="verify"
              value="email-verify"
              checked={verificationMethod === 'email'}
              onChange={() => {
                setVerificationMethod('email')
                setVerificationValue(hiddenEmail)
                trackLOCRadioSelected.Email()
              }}
            />
            <label className="email_label" htmlFor="email-verify">
              {`Email code to ${hiddenEmail}`}
            </label>
          </div>
          {user.phone ? (
            <div className="input_group">
              <input
                data-testid={buildTestID(
                  'phone_verify',
                  '/advance_request',
                  'verify_account',
                )}
                type="radio"
                id="phone-verify"
                name="verify"
                value="phone-verify"
                checked={verificationMethod === 'phone'}
                onChange={() => {
                  setVerificationMethod('phone')
                  setVerificationValue(hiddenPhone)
                  trackLOCRadioSelected.Phone()
                }}
              />
              <label className="email_label" htmlFor="phone-verify">
                {`Text code to ${hiddenPhone}`}
              </label>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="break_line"></div>
        <div className="card_buttons">
          <Button
            onClick={() => setCurrentScreen(REVIEW_REQUEST)}
            className="back_btn"
            backArrow
            outlined
            text="Back"
            UID="verify_account"
          />
          <Button
            text="Send Code"
            onClick={() => {
              if (verificationMethod === 'email') {
                setVerificationValue(hiddenEmail)
              }
              trackLOCRequest.StepFour(verificationMethod)
              requestAuthCode(verificationMethod)
              setCurrentScreen(ENTER_VERIFY_CODE)
            }}
            UID="verify_account"
          />
        </div>
      </StyledVerifyAccount>
    </>
  )
}

VerifyAccount.propTypes = {
  setCurrentScreen: PropTypes.func.isRequired,
  verificationMethod: PropTypes.string.isRequired,
  setVerificationMethod: PropTypes.func.isRequired,
  setVerificationValue: PropTypes.func.isRequired,
  user: PropTypes.exact({
    phone: PropTypes.string,
    email: PropTypes.string.isRequired,
    completeName: PropTypes.string,
  }).isRequired,
}

export default VerifyAccount
