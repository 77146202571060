import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }
`

const LockIconTwo = () => {
    return (
        <StyledSVG
            aria-hidden="true"
            role="img"
            width="32"
            height="32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_1339_1196)">
                <circle cx="16" cy="15" r="15" fill="white" />
            </g>
            <path d="M10.9006 24.1001C10.4566 24.1001 10.0789 23.9431 9.76734 23.629C9.45579 23.315 9.30002 22.9361 9.30002 22.4924V13.5078C9.30002 13.0641 9.45704 12.6853 9.77109 12.3712C10.0851 12.0572 10.464 11.9001 10.9077 11.9001H11.7V9.50015C11.7 8.30678 12.1189 7.29178 12.9566 6.45515C13.7944 5.61851 14.8107 5.2002 16.0056 5.2002C17.2006 5.2002 18.215 5.61851 19.049 6.45515C19.883 7.29178 20.3 8.30678 20.3 9.50015V11.9001H21.0923C21.536 11.9001 21.9149 12.0572 22.2289 12.3712C22.5429 12.6853 22.7 13.0641 22.7 13.5078V22.4924C22.7 22.9361 22.5429 23.315 22.2286 23.629C21.9144 23.9431 21.5353 24.1001 21.0913 24.1001H10.9006ZM10.9077 22.8001H21.0923C21.182 22.8001 21.2557 22.7713 21.3134 22.7136C21.3711 22.6559 21.4 22.5821 21.4 22.4924V13.5078C21.4 13.4181 21.3711 13.3444 21.3134 13.2867C21.2557 13.229 21.182 13.2001 21.0923 13.2001H10.9077C10.818 13.2001 10.7442 13.229 10.6865 13.2867C10.6288 13.3444 10.6 13.4181 10.6 13.5078V22.4924C10.6 22.5821 10.6288 22.6559 10.6865 22.7136C10.7442 22.7713 10.818 22.8001 10.9077 22.8001ZM16.0053 19.5501C16.4377 19.5501 16.8032 19.399 17.1019 19.0967C17.4006 18.7945 17.55 18.4272 17.55 17.9948C17.55 17.5625 17.3988 17.1969 17.0966 16.8982C16.7943 16.5995 16.427 16.4501 15.9947 16.4501C15.5623 16.4501 15.1968 16.6013 14.8981 16.9035C14.5994 17.2058 14.45 17.5731 14.45 18.0054C14.45 18.4378 14.6011 18.8033 14.9034 19.102C15.2056 19.4007 15.5729 19.5501 16.0053 19.5501ZM13 11.9001H19V9.50015C19 8.66681 18.7083 7.95848 18.125 7.37515C17.5417 6.79181 16.8333 6.50015 16 6.50015C15.1667 6.50015 14.4583 6.79181 13.875 7.37515C13.2917 7.95848 13 8.66681 13 9.50015V11.9001Z" fill="black" />
            <defs>
                <filter id="filter0_d_1339_1196" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1339_1196" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1339_1196" result="shape" />
                </filter>
            </defs>
        </StyledSVG>
    )
}

export default LockIconTwo
