import React from 'react'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import { useLocation } from 'react-router-dom'
import { buildTestID } from '../Utils/utils'

const smallScreenStyles = `
  flex-direction: column;
  align-items: start;
  padding: 24px 20px;

  .title {
    margin-bottom: 20px;
  }

  .all-text {
    margin: 0;
  }

  svg {
    min-height: 57px;
    min-width: 57px;
    height: 57px;
    width: 57px;
    margin-bottom: 20px;
  }
`
const StyledNoticeOfErrorCardModern = styled.div`
  margin-top: 32px;
  
  h3 {
    &.subtitle {
      font-size: 18px;
    }
  }

  .info_card {
    display: flex;
    padding: 32px 40px;
    flex-direction: row;
    /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2); */
    border-radius: 20px;
    background: var(--Light-Blue, #F8FBFF);
    height: 100%;

    .all-text {
      margin-left: 32px;
      width: 100%;
    }

    .paragraph {
      margin: 0;

      &.address {
        border-radius: 10px;
        background: var(--Light-Blue-4, #E2F5FF);
        padding: 10px;
      }

      .phone-web-link {
        text-decoration: none;
        font-weight: 700;
        text-transform: lowercase;
        white-space: nowrap;
      }
    }

    svg {
      min-height: 88px;
      min-width: 88px;
    }
  }

  .button {
    margin-top: 12px;

    a {
      text-align: start;
    }
  }

  .smallScreenStyles {
    ${smallScreenStyles}
    margin-top: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .info_card {
      ${smallScreenStyles}
    }
  }
`

const NoticeOfErrorCard = () => {
  let { pathname } = useLocation()

  return (
    <StyledNoticeOfErrorCardModern>
      <div className={`info_card ${smallScreenStyles ? 'smallScreenStyles' : ''
        }`}>
        <div className="all-text">
          <h3 className="subtitle bold">Notice of Error or Request for Information</h3>
          <p
            className="body-2 paragraph"
            data-testid={buildTestID("notice_of_error", pathname)}>
            Federal law gives customers the right to notify us of an error regarding the servicing of their loan or to request information regarding their loan. If you wish to provide a notice of error or a request for information, you must write to us at the following address:
          </p>
          <br />
          <p className="body-2 paragraph address">
            <b>PO Box 40719</b><br />
            Lansing, MI 48901-7919
          </p>
          <br />
          <p className="body-2 paragraph">
            The letter must provide the customer’s name, loan number, and description of the error or detailed list of the information being requested.
          </p>
        </div>
      </div>
    </StyledNoticeOfErrorCardModern>
  )
}

export default NoticeOfErrorCard
