import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link'
import { buildTestID } from '../../Utils/utils'

import CommonQuestionCategories from './CommonQuestionCategories'

const Statements = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.Statements, title)
  })

  return (
    <>
      <h3
        ref={title}
        id="statements-loan-documents-fees"
        tabIndex="-1"
      >
        Statements, Loan Documents, and Fees
      </h3>
      <h5>Receiving Loan Documents</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              I need to request a copy of my loan document(s).
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Document requests may be emailed to{' '}
              <a href="mailto:BC@reversedepartment.com">
                BC@reversedepartment.com
              </a>
              . The documents will be printed and mailed to the
              mailing address on record. Please include <em>which</em>{' '}
              documents you are requesting. An entire loan file may
              not be printed and mailed, only select documents.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              When will I receive an account statement?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              You will receive a monthly statement of the activity on your reverse mortgage. You may OPT IN to paperless statements by <a href="/" target="_blank" data-testid={buildTestID('when_will_i_receive', '/statements', 'log_on')}>logging into this website</a> and selecting the paperless option. If you go paperless, you will receive an email each month when your statement is ready. If you choose not to opt in to paperless statements, a statement will be mailed to you, early in the  following month. Please allow for mailing time - it can take an additional 7 days for you to receive your statement in the mail.
              <br /> <br />
              You also will receive an annual statement in January of
              each year detailing all of the activity on your reverse
              mortgage over the previous year.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              How do I read and understand my monthly statement?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              For your convenience, we have included a glossary of terms and sample statement in the New Loan Reference Guide located on this website’s Helpful Resources page  <a href="/helpful-resources" target="_blank">here</a>.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <h5>Loan Terminology and Numbers</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              What is the Original Principal Limit?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              This is the principal amount available to you at the
              time of loan origination, determined by using a formula
              that considers your property value, your age and the
              current interest rate when your loan closed. On a HECM
              loan, the Principal Limit is calculated by multiplying
              the Maximum FHA Claim Amount by a factor supplied by
              HUD.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              What is the Current Outstanding Principal Balance?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              The total amount owed as of current statement.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              What is Previous Outstanding Principal Balance?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              The total amount owed as of the previous statement.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              What is the Current Net Principal Limit?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              The total amount available for you to withdraw either as
              monthly scheduled payment or line of credit
              disbursements.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              What is the Original Line of Credit?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              The amount available in your line of credit after
              initial charges were paid.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="5">
              What is the Line of Credit Principal Balance?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              The amount of your line of credit that you have used.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
              What is the Current Net Line of Credit?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              The total amount available for you to advance.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="7">
              What is the Current Available Line of Credit?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              The total amount currently available for you to advance.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="8">
              What is the Daily Periodic Rate, Monthly Periodic Rate,
              Corresponding Annual Rate and Historical Annual
              Percentage Rate?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="8">
            <Card.Body>
              <span>These are the rates used to determine your Interest and{' '}
                <abbr title="Mortgage Insurance Premium">MIP</abbr>{' '}</span>
              charges.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="9">
              <span>What is Periodic{' '}
                <abbr title="Mortgage Insurance Premium">MIP</abbr>?</span>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="9">
            <Card.Body>
              <span> The amount charged for Mortgage Insurance Premium
                prorated for the number of days in a month.{' '}
                <abbr title="Mortgage Insurance Premium">MIP</abbr> is
                paid to HUD, not to the lender or servicer.</span>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="10">
              What is the Periodic Interest?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="10">
            <Card.Body>
              The amount charged for Interest prorated for the number
              of days in the month.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="11">
              Where in the loan documents can the interest type be
              located?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="11">
            <Card.Body>See the Note you signed at closing.</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <h5>Interest and Loan Fees</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              What determines my adjustable interest rate?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Your interest rate is tied directly to an index
              published by the Federal Reserve, as stated in your loan
              documents.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              What is the Interest Rate Change Notice and where can I
              find it?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              If your loan has an adjustable interest rate (ARM), the
              change notice (included on your monthly statement)
              informs you of what your interest rate was in the
              previous month and what it will be in the coming month.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              How is the current interest rate determined on an
              Adjustable Rate loan?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              Add the current interest rate Index and the Margin
              (found on your Note signed at closing) to get the
              current interest rate in effect. Be sure to check your
              note for the rate caps that apply to your loan.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              Does the Margin portion of an adjustable interest rate
              (ARM) ever change?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              No – the Margin will remain the same for the life of the
              loan and is identified in your Note signed at closing.
              If the interest rate type is adjustable (and not a fixed
              interest rate), then the Index portion of the interest
              rate may adjust based on the type of interest rate that
              was selected at the time of closing (e.g. the rate could
              adjust monthly or annually depending on how the loan was
              closed). Margin + Current Index Rate = Current Interest
              Rate. Your loan note may also include periodic and
              lifetime minimum and maximum rates that may further
              limit the current interest rate.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              What is the Monthly Servicing Fee?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              An administrative fee charged for servicing the loan which was disclosed to you in your loan documents. May not apply to all loans.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="5">
              How is the monthly servicing fee determined?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              If the loan documents specify a monthly service fee, the
              amount is generally up to $35 per month, depending upon
              the option selected by the borrower when the loan was
              originated.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
              What is the reason for the fixed monthly servicing fee?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              The servicing fee covers the loan administration
              /servicing functions for the loan, such as the handling
              of customer service issues, mailing of monthly
              statements, disbursing payments to the borrower,
              collecting and processing payments from the borrower (if
              applicable), remitting of monthly mortgage insurance
              premiums to HUD on HECM loans, monitoring of property
              taxes, monitoring of property insurance, and monitoring
              of other maturity events (such as death, non-occupancy,
              etc.), to name a few.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="7">
              How is the monthly servicing fee shown in the loan
              balance?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              The monthly servicing fee is capitalized to (added to)
              the loan balance each month, along with the accrued
              interest and{' '}
              <abbr title="Mortgage Insurance Premium">MIP</abbr> paid
              to HUD on a HECM loan.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="8">
              What fees could be associated with servicing loans?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="8">
            <Card.Body>
              The fee schedule may be found here:{' '}
              <Link to="/schedule-of-borrower-fees">
                https://www.reversedepartment.com/schedule-of-borrower-fees
              </Link>
              . Note: For non-HECM loans, consult loan documents as
              fees vary by product type.
              <br />
              <strong>Common Reverse Mortgage Fees:</strong>
              <ul>
                <li>
                  Servicing Fee generally up to $35 (Total Annual Loan
                  Cost sheet or TALC provided at origination
                </li>
                <li>
                  Monthly{' '}
                  <abbr title="Mortgage Insurance Premium">MIP</abbr>{' '}
                  (rate set by HUD at origination)
                </li>
                <li>
                  Payment Plan Change Fee ($20 on all HECM &
                  HomeKeeper loans){' '}
                </li>
                <li>
                  Property Charges (such as taxes, insurance, ground
                  rents, flood & hazard insurance premiums, HOA dues
                  and special assessments - if not paid timely by the
                  borrower).
                </li>
                <li>
                  Repair Administration Fee (if repairs are required
                  at origination - see Repair Rider to Loan Agreement
                  on HECM loans)
                </li>
                <li>
                  Repair Compliance Inspection Fee (if repairs are
                  required at origination, to confirm completion of
                  repairs).{' '}
                </li>
                <li>
                  Property Repair Inspection Fee (as applicable for
                  repairs made as a result of an insurance loss claim
                  during servicing of the loan).{' '}
                </li>
                <li>
                  Appraisal, Property Preservation, and Foreclosure
                  Fees (as applicable on loans called due and
                  payable).
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="9">
              Where in the loan documents can the monthly service fee
              be located?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="9">
            <Card.Body>
              See the TALC (Total Annual Loan Cost) document or the
              loan agreement you signed at closing.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="10">
              Will these charges appear in my loan balance each month?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="10">
            <Card.Body>
              Yes, these charges accrue to the loan balance on a
              monthly basis.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <h5>MIP – Mortgage Insurance Premium</h5>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              <span> What is Periodic{' '}
                <abbr title="Mortgage Insurance Premium">MIP</abbr>?</span>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <span>The amount charged for Mortgage Insurance Premium
                prorated for the number of days in a month.{' '}
                <abbr title="Mortgage Insurance Premium">MIP</abbr> is
                paid to HUD on a HECM loan, not to the lender or
                servicer.</span>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              <span>Why do I have to pay{' '}
                <abbr title="Mortgage Insurance Premium">MIP</abbr> on
                my HECM loan?</span>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              MIP (Mortgage Insurance Premium) is a fee that is
              charged on a HECM loan on a monthly basis, at a rate
              specified in the loan documents you signed at closing.
              MIP is charged to the loan in accordance with the Loan
              Agreement document. This fee is required by HUD on a
              HECM loan and paid directly to the Department of Housing
              and Urban Development (HUD), not to the lender or
              servicer. HUD administers the HECM loan program on
              behalf of the FHA (Federal Housing Administration), who
              insures the loan.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default Statements
