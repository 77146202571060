import React, { useState } from 'react'
import { BrowserRouter, Link } from 'react-router-dom'

import { useApiContext } from '../Hooks/useApiContext'
import { extractServicer } from '../Utils/apiExtractors'
import { buildTestID } from '../Utils/utils'
import { theme } from '../Global/Theme'

const logoStyle = {
  height: '60px',
  width: 'unset',
}

const loadStyle = {
  backgroundColor: theme.colors.loading,
  borderRadius: '4px',
  width: '112px',
  height: '40px',
}

const Logo = () => {
  const [imgLoading, setImgLoading] = useState(true)
  const { loan } = useApiContext()
  const {
    error: loanError,
    loading: loanLoading,
    data: loanData,
  } = loan

  if (loanLoading || loanError || !loanData) {
    return <div style={loadStyle} />
  }

  const servicer = extractServicer(loanData)
  return (
    <BrowserRouter forceRefresh={true}>
      <a href="/my-loan">
        <img
          src={`./logos/${servicer.subservicerId}.jpg`}
          alt="brand logo"
          style={imgLoading ? loadStyle : logoStyle}
          onLoad={() => setImgLoading(false)}
          aria-label={servicer.clientName === 'FAR' ? `Finance of America Reverse` : `${servicer.clientName} Home`}
          data-testid={buildTestID('logo', '/home', 'logo')}
        />
      </a>
    </BrowserRouter>
  )
}

export default Logo
