import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const SendViaFaxIcon = ({ className, color = 'black60' }) => {
    return (
        <StyledSVG
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 136 136" fill="none">
        <circle cx="68" cy="68" r="68" fill="#E1E1E1"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M57 39H89V61H93C95.2091 61 97 62.7909 97 65V89C97 91.2091 95.2091 93 93 93H49V61H57V39ZM59 61H87V41H59V61ZM85 43H61V45H85V43ZM61 47H81V49H61V47ZM78 55H61V57H78V55ZM61 51H83V53H61V51ZM38 65C38 62.7909 39.7909 61 42 61H43C45.2091 61 47 62.7909 47 65V89C47 91.2091 45.2091 93 43 93H42C39.7909 93 38 91.2091 38 89V65ZM59 66C59 64.8954 59.8954 64 61 64H85C86.1046 64 87 64.8954 87 66C87 67.1046 86.1046 68 85 68H61C59.8954 68 59 67.1046 59 66ZM63 72C61.8954 72 61 72.8954 61 74C61 75.1046 61.8954 76 63 76C64.1046 76 65 75.1046 65 74C65 72.8954 64.1046 72 63 72ZM61 81C61 79.8954 61.8954 79 63 79C64.1046 79 65 79.8954 65 81C65 82.1046 64.1046 83 63 83C61.8954 83 61 82.1046 61 81ZM63 86C61.8954 86 61 86.8954 61 88C61 89.1046 61.8954 90 63 90C64.1046 90 65 89.1046 65 88C65 86.8954 64.1046 86 63 86ZM71 74C71 72.8954 71.8954 72 73 72C74.1046 72 75 72.8954 75 74C75 75.1046 74.1046 76 73 76C71.8954 76 71 75.1046 71 74ZM73 79C71.8954 79 71 79.8954 71 81C71 82.1046 71.8954 83 73 83C74.1046 83 75 82.1046 75 81C75 79.8954 74.1046 79 73 79ZM71 88C71 86.8954 71.8954 86 73 86C74.1046 86 75 86.8954 75 88C75 89.1046 74.1046 90 73 90C71.8954 90 71 89.1046 71 88ZM83 72C81.8954 72 81 72.8954 81 74C81 75.1046 81.8954 76 83 76C84.1046 76 85 75.1046 85 74C85 72.8954 84.1046 72 83 72ZM81 81C81 79.8954 81.8954 79 83 79C84.1046 79 85 79.8954 85 81C85 82.1046 84.1046 83 83 83C81.8954 83 81 82.1046 81 81ZM83 86C81.8954 86 81 86.8954 81 88C81 89.1046 81.8954 90 83 90C84.1046 90 85 89.1046 85 88C85 86.8954 84.1046 86 83 86Z" fill="#475468"/>
        </StyledSVG>
    )
}

export default SendViaFaxIcon
