import React, { useState } from 'react'
import styled from 'styled-components'
import { NBS_INFORMATION, SIGNATURE } from '.'

import Button from '../../Common/button'
import { trackESignConsent } from '../../Global/Analytics'

const StyledHecm = styled.div`
  h1, h3 {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }

  .borrower {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;  
    margin: 20px 0 32px 0;
    background: #fafafa;
  }
  
  .borrower > h5 {
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
  }
  
  input[type="radio"] {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-top: -4px;
  }

  input[type="radio"] + label { 
    margin-left: 24px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .hecm {
      background-color: #fff;
      padding: 15px;
      margin-top: 32px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      text-align: center;
      margin: 32px;
    }

    .borrower {
      margin: 20px 100px 32px;
    }

    .borrower > h5 {
      margin: 0 0 8px 0;
    }

    .questions {
      margin: 20px 100px 32px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const Hecm = ({ borrower, setView, setModalShow, setNbsInformation, mainRef }) => {
  const [married, setMarried] = useState("")
  const [currentSpouse, setcurrentSpouse] = useState("")

  const handleClick = (e) => {
    trackESignConsent.StepCompleted('answered nbs questions', 'hecm_button_next')
    const nbsInformation = {
      "nameIsListed": currentSpouse,
      "isMarried": married === "YES" ? true : false
    }
    setNbsInformation(nbsInformation)
    
    if (married === "YES" && currentSpouse !== "NO") {
      setView(NBS_INFORMATION)
    } else {
      setView(SIGNATURE)
    }
  }

  return (
    <StyledHecm>
      <div className="hecm"
        data-gridcolumnlayout={{
          xl: '3/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Annual Occupancy Certification</h1>
        <h5>Please provide the information requested below to assist us in identifying whether or not your non-borrowing spouse may potentially be eligible to remain in the mortgaged property after the death of the last remaining HECM borrower.</h5>
        <div className='borrower'>
          <h5>Name of Borrower</h5>
          <label>{borrower.userName}</label>
        </div>
        <div className='questions'>
          <p>Is the borrower identified above currently married?</p>
          <div>
            <input type="radio" id="married-yes" name="married" value="yes" onClick={() => setMarried("YES")} />
            <label htmlFor="married-yes">Yes</label><br />
          </div>
          <div>
            <input type="radio" id="married-no" name="married" value="no" onClick={() => setMarried("NO")} />
            <label htmlFor="married-no">No</label><br /><br />
          </div>
          <p>If the loan documents signed at closing DO NOT list the name of the borrower’s current spouse, check “Yes” here, otherwise check “No” or “Not Sure” as applicable.</p>
          <div>
            <input type="radio" id="current-yes" name="current-spouse" value="yes" onClick={() => setcurrentSpouse("YES")} />
            <label htmlFor="current-yes">Yes</label><br />
          </div>
          <div>
            <input type="radio" id="current-no" name="current-spouse" value="no" onClick={() => setcurrentSpouse("NO")} />
            <label htmlFor="current-no">No</label><br />
          </div>
          <div>
            <input type="radio" id="current-notsure" name="current-spouse" value="notsure" onClick={() => setcurrentSpouse("NOT_SURE")} />
            <label htmlFor="current-notsure">Not Sure</label>
          </div>
        </div>
        <div className="buttons">
          <Button
            className="cancel button"
            text="Cancel"
            outlined
            fullWidth
            UID="hecm_button_cancel"
            onClick={() => setModalShow(true)}
          />
          <Button
            className="button"
            text="Next"
            fullWidth
            UID="hecm_button_next"
            disabled={!married.length || !currentSpouse.length}
            onClick={handleClick}
          />
        </div>
      </div>
    </StyledHecm>
  )
}

export default Hecm