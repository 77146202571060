import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import ScrollToTop from '../../Global/ScrollToTop'
import { useLOCAdvancePDF } from '../../Hooks/useLOCAdvancePDF'

const StyledRequestSubmitted = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  text-align: center;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  .review_content {
    margin: 0 auto;

    h6 {
      text-align: center;
      margin-top: 0;
    }

    .submitted_body_text {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.15px;
      color: ${({ theme }) => theme.colors.black87};
      margin-bottom: 0;
    }

    .submitted_link {
      display: flex;
      justify-content: center;
      padding: 38px 0;
    }
  }

  .card_buttons {
    justify-content: center;
    display: flex;
    margin: 0 40px 0 40px;

    .back_button {
      margin-left: 16px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 32px 24px;

    .review_content {
      width: 100%;
      h6 {
        margin-top: 0;
      }
    }

    .card_buttons {
      flex-direction: column;

      .back_button {
        margin-left: 0;
        margin-top: 24px;
      }
    }
  }
`

const RequestSubmitted = ({
  submittedRequestData,
  setSubmittedRequestData,
  setUserVerified,
}) => {
  const { downloadLOCAdvancePDF } = useLOCAdvancePDF(
    submittedRequestData,
  )

  useEffect(() => {
    setUserVerified(false)
  })

  useEffect(() => {
    return () => setSubmittedRequestData()
  }, [setSubmittedRequestData])

  return (
    <>
      <ScrollToTop />
      <StyledRequestSubmitted>
        <div className="review_content">
          <h6>
            Your Line of Credit Advance request has been submitted.
          </h6>
          <p className="submitted_body_text">
            You will receive an email confirmation shortly. If you
            need to make any changes please contact Borrower Care at
            (866) 654–0020 for support.
          </p>
          <Button
            className="submitted_link"
            onClick={downloadLOCAdvancePDF}
            text="Print or download for your records"
            textButton
            UID="request_submitted"
          />
        </div>

        <div className="card_buttons">
          <Button
            text="Check My Status"
            linkTo="/my-advance-requests"
            UID="request_submitted"
          />
          <Button
            className="back_button"
            outlined
            linkTo="/my-loan"
            text="Back to My Loan"
            UID="request_submitted"
          />
        </div>
      </StyledRequestSubmitted>
    </>
  )
}

RequestSubmitted.propTypes = {
  submittedRequestData: PropTypes.object.isRequired,
  setSubmittedRequestData: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
}

export default RequestSubmitted
