import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BrowserRouter, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Logo from './Logo'
import Button from '../Common/button'
import { logOut } from '../services/auth'
import useWindowSize from '../Hooks/useWindowSize'
import { useRetrieveLocEligibility } from '../Hooks/useRetrieveLocEligibility'
import { useApiContext } from '../Hooks/useApiContext'
import { useRetrievePayOffEligibility } from '../Hooks/useRetrievePayOffEligibility'
import ReverseMortgageIcon from '../assets/reverse-mortgage-icon'
import {
  trackUserSignedOut,
  trackLOCRequest,
  trackPayoffQuote,
  trackNavigateToUploadPage,
  trackRepaymentButton,
} from '../Global/Analytics'
import { useLoggedInUser } from '../Hooks/useLoggedInUser'
import { buildTestID } from '../Utils/utils'


const StyledHeaderModern = styled.div`
  background-color: var(--Surface, #fff);
  border-radius: 20px;
  padding: 25px 20px;

  .mobile_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 8px 24px;

    .burger {
      background: ${({ theme }) => theme.colors.white};
      align-self: end;
      display: flex;
      width: 40px;
      height: 40px;
      border: 1px solid ${({ theme }) => theme.colors.black12};
      border-radius: 4px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .line {
        border: 0.5px solid ${({ theme }) => theme.colors.black60};
        width: 18px;
        border-radius: 4px;
      }
      :hover {
        outline: ${({ theme }) => theme.colors.primary} auto 1px;
      }
      :focus {
        outline: ${({ theme }) => theme.colors.primary} auto 1px;
      }
    }
  }

  .mobile_nav {
    position: absolute;
    width: calc(100vw - 16px);
    transition: all 0.2s ease-in;
    overflow-y: auto;
    height: calc(100vh - 60px);
    left: 8px;
    margin-right: 8px;
    top: 80px;
    z-index: 1;

    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.white};
    padding: 0;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);

    .mobile_link {
      text-align: start;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black};
      padding: 12px 24px;
      margin: 0 8px;
    }

    .mobile_link.selected {
      color: ${({ theme }) => theme.colors.primaryDark};
      background: ${({ theme }) => theme.colors.primary4};
      border-radius: 4px;
    }

    .buttons {
      padding: 0 24px 34px 24px;
      .button {
        margin: 20px 0 0 0;
        text-align: center;
        width: 360px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 21px 24px;
 }
 
`;

const Menu = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const MenuItem = styled.a`
  font-family: Lato, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  color: #000;

  &.selected {
    border-bottom: 3px solid #074EE8;
  }
`;

const StyleButton = styled.a`
  color: var(--Surface, #fff);
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  border-radius: 10px;
  background-color: #475468;
  align-self: stretch;
  padding: 8px 20px;
  font: 700 18px Lato, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const StyleOutlinedButton = styled.a`
  border-radius: 10px;
  border: 1px solid #475468;
  background-color: #fff;
  color: #475468;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  margin-left: 10px;

  align-self: stretch;
  padding: 8px 20px;
  font: 700 18px Lato, sans-serif;

  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f4f4f4;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(71, 84, 104, 0.5);
  }

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const UnAuthedLinks = [
  { text: 'Make a Payment', link: 'payment' },
  { text: 'Helpful Resources', link: 'helpful-resources' },
  { text: 'Common Questions', link: 'common-questions' },
  { text: 'Common Forms', link: 'common-forms' },
  { text: 'Contact Us', link: 'contact' },
]

const defaultAuthedLinks = [
  { text: 'My Loan', link: 'my-loan' },
  {
    text: 'Statements',
    link: 'statements-and-documents',
  },
  {
    text: 'Online Tools',
    link: 'NESTED',
    nestedLinks: [],
  },
  { text: 'My Account', link: 'account-settings' }
]

const buildTestIDHeader = (text) => {
  return buildTestID(text, '/home', 'header')
}

export const HeaderModern = ({
  history,
  isAuthenticated,
  navVisible,
  setNavVisible,
}) => {
  const [selectedNavItem, setSelectedNavItem] = useState('')
  const [onlineToolsOpen, setOnlineToolsOpen] = useState(false)
  const [authedLinks, setAuthedLinks] = useState(defaultAuthedLinks)

  const { data, loading } = useLoggedInUser(isAuthenticated)
  const userIsLoggedIn = !loading && data

  const { isMedium } = useWindowSize()

  const { pathname } = useLocation()

  useEffect(() => {
    setSelectedNavItem(pathname.slice(1))
  }, [pathname])

  const { data: eligibilityData } = useRetrieveLocEligibility()
  const { data: payoffData } = useRetrievePayOffEligibility()
  const apiContext = useApiContext()
  const { data: loanData } = apiContext.loan
  const getPrepaymentUrl = () => 'https://secure.directbiller.com/pbills/payer/welcomeDirect.do?h=1gzzt5f3tn' //process.env.REACT_APP_PREPAYMENT_URL

  // updates online tools dropdown nav to reflect available online tools
  useEffect(() => {
    if (!userIsLoggedIn) return

    let availableTools = [
      {
        text: 'Upload a Form or Document',
        link: 'upload',
        trackEvent: () =>
          trackNavigateToUploadPage({ fromNav: true }),
      },
      {
        text: 'Voluntary Prepayments/Repayment Plan Payments',
        link: getPrepaymentUrl(),
        trackEvent: () =>
          trackRepaymentButton({ fromNav: true }),
      },
    ]

    if (payoffData) {
      const { eligible } = payoffData
      if (eligible) {
        availableTools = [
          ...availableTools,
          {
            text: 'Request a Payoff Quote',
            link: 'payoff-quote',
            trackEvent: () =>
              trackPayoffQuote.Started({ fromNav: true }),
          },
        ]
      }
    }

    if (eligibilityData && eligibilityData.data && eligibilityData.data.getLOCEligibility && eligibilityData.data.getLOCEligibility.info) {
      const { eligible, pending, history, maxRequestAmount } = eligibilityData.data.getLOCEligibility.info

      if ((history && !pending) || (maxRequestAmount && pending)) {
        availableTools = [
          {
            text: 'View my Line of Credit Requests',
            link: 'my-advance-requests',
            trackEvent: () => { },
          },
          ...availableTools,
        ]
      }

      if (maxRequestAmount && eligible && !pending) {
        availableTools = [
          {
            text: 'Request a Line of Credit Advance',
            link: 'advance-request',
            trackEvent: () =>
              trackLOCRequest.Started({ fromNav: true }),
          },
          ...availableTools,
        ]
      }
    }

    setAuthedLinks([
      defaultAuthedLinks[0],
      defaultAuthedLinks[1],
      {
        text: 'Online Tools',
        link: 'NESTED',
        nestedLinks: [...availableTools],
      },
      defaultAuthedLinks[3]
    ])
  }, [userIsLoggedIn, eligibilityData, loanData, payoffData])

  const mobileLinks = userIsLoggedIn
    ? [
      ...authedLinks.filter((_, i) => i !== 2),
      ...authedLinks[2].nestedLinks,
      ...UnAuthedLinks,
    ]
    : UnAuthedLinks

  const onLandingPage = selectedNavItem === ''

  return (
    <StyledHeaderModern>
      {!isMedium ? (
        <>
          {!userIsLoggedIn ?
            (<BrowserRouter forceRefresh={true}>
              <a href="/" aria-label=" Reverse Mortgage Servicing Department home link">
                <ReverseMortgageIcon />
              </a>
            </BrowserRouter>)
            : (<Logo />)
          }
          <Menu>
            <MenuItem href="/payment" data-testid={buildTestIDHeader('payment')} className={selectedNavItem === 'payment' ? 'selected' : ''}>Make a Payment</MenuItem>
            <MenuItem href="/helpful-resources" data-testid={buildTestIDHeader('helpful-resources')} className={selectedNavItem === 'helpful-resources' ? 'selected' : ''}>Helpful Resources</MenuItem>
            <MenuItem href="/common-questions" data-testid={buildTestIDHeader('common-questions')} className={selectedNavItem === 'common-questions' ? 'selected' : ''}>Common Questions</MenuItem>
            <MenuItem href="/common-forms" data-testid={buildTestIDHeader('common-forms')} className={selectedNavItem === 'common-forms' ? 'selected' : ''}>Common Forms</MenuItem>
            <MenuItem href="/contact" data-testid={buildTestIDHeader('contact')} className={selectedNavItem === 'contact' ? 'selected' : ''}>Contact Us</MenuItem>
          </Menu>
          <div>
            {!userIsLoggedIn && selectedNavItem !== 'register' ? (
              <StyleButton href="/register">Create Account</StyleButton>)
              : null}
            {userIsLoggedIn ?
              (<StyleOutlinedButton href="/"
                onClick={(e) => {
                  e.preventDefault()
                  trackUserSignedOut()
                  sessionStorage.clear()
                  logOut()
                  window.location = '/'
                  setNavVisible(false)
                }}>Log Out</StyleOutlinedButton>)
              : !onLandingPage
                ? (<StyleOutlinedButton href="/">Sign In</StyleOutlinedButton>)
                : null}
          </div>
        </>
      ) : (
        <>
          <div className="mobile_header">
            {userIsLoggedIn ? (
              <Logo />
            ) : (
              <a
                href="/"
                className="body-2 logo_placeholder"
                data-testid={buildTestIDHeader('Reverse Mortgage')}
                aria-label=" Reverse Mortgage Servicing Department home link"
              >
                <ReverseMortgageIcon />
              </a>
            )}
            <button
              data-testid={buildTestIDHeader('Hamburger')}
              className="burger"
              onClick={() => setNavVisible(!navVisible)}
              aria-expanded={navVisible}
              aria-label={navVisible ? 'menu button expanded' : 'menu button collapsed'}
              aria-controls="mobile-menu"
            >
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </button>
          </div>
          <div id="mobile-menu" className="mobile_nav" aria-hidden={!navVisible} style={{ "display": navVisible ? 'flex' : 'none' }}>
            {mobileLinks.map((link) => {
              return link.text !== 'Voluntary Prepayments/Repayment Plan Payments' ? (<Link
                data-testid={buildTestIDHeader(
                  link.text + '_mobile',
                )}
                key={link.link}
                to={link.link}
                className={`body-1 mobile_link ${selectedNavItem === link.link ? 'selected' : ''
                  }`}
              >
                {link.text}
              </Link>) : (<Link
                data-testid={buildTestIDHeader(
                  'repayment_mobile',
                )}
                key={'repayment_mobile'}
                to={{ pathname: link.link }}
                className={`body-1 mobile_link ${selectedNavItem === 'repayment_mobile' ? 'selected' : ''
                  }`}
              >
                {link.text}
              </Link>)
            })}
            <div className="buttons">
              {!userIsLoggedIn ? (
                <>
                  <Button
                    className="button"
                    linkTo="/register"
                    text="Create Account"
                    small
                    refreshPage
                    UID="create_account_nav_mobile"
                    fullWidth
                  />
                </>
              ) : null}
              {userIsLoggedIn ? (
                <Button
                  className="button"
                  text="Log Out"
                  UID="log_out_nav_mobile"
                  onClick={(e) => {
                    trackUserSignedOut()
                    e.preventDefault()
                    sessionStorage.clear()
                    logOut()
                    history.push('/')
                    setNavVisible(false)
                  }}
                  small
                  linkTo="/"
                  fullWidth
                />
              ) : !onLandingPage ? (
                <Button
                  className="button"
                  UID="sign_in_nav_mobile"
                  text="Sign In"
                  outlined
                  small
                  linkTo="/"
                  refreshPage
                  fullWidth
                />
              ) : null}
            </div>
          </div>
        </>
      )
      }
    </StyledHeaderModern >
  );
}