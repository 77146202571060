import React from 'react'
import Styled from 'styled-components'
import { Container } from 'react-bootstrap'

import H1 from '../Common/H1'
import Title from '../Common/Title'
import ScrollUpButton from '../Common/ScrollUpButton'

const StyledTermsConditions = Styled.div`
  margin-top: 80px;
  margin-bottom: 100px;
  font-size: 20px;

.title {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 800px;
}

p {
  margin-bottom: 40px;
}

a {
      color: ${({ theme }) => theme.colors.primary};
    }
`

const TermsConditions = () => {
  return (
    <>
      <Title>Terms &amp; Conditions</Title>
      <StyledTermsConditions>
        <main id="main" tabIndex="0">
          <Container>
            <div className="title">
              <H1>
                Celink/Reverse Mortgage Servicing Department Terms of
                Service
              </H1>
            </div>
            <div>
              <p>
                <strong>
                  Please read these Terms of Service carefully.
                </strong>{' '}
                It sets forth the terms and conditions of use of this
                website,{' '}
                <a href="https://www.reversedepartment.com">
                  https://www.reversedepartment.com
                </a>
                , along with our online servicing platform
                (collectively, the “Website”). BY ACCESSING OR USING
                THIS WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS OF
                SERVICE AND ACCEPT THEM IN FULL, AS THEY MAY BE
                MODIFIED BY Compu-Link Corporation D/B/A
                Celink/Reverse Mortgage Servicing Department
                (“Celink”, “we”, “our”, “us”) FROM TIME-TO-TIME,
                WITHOUT NOTICE TO YOU, AND POSTED ON THIS WEBSITE. You
                are responsible for regularly reviewing these Terms of
                Service. YOUR USE OF THE WEBSITE FOLLOWING THESE
                CHANGES MEANS THAT YOU ACCEPT THE REVISED TERMS OF
                SERVICE.
              </p>
              <p>
                <strong>No Warranties.</strong> Although Celink
                attempts to provide accurate information, names,
                images, pictures, logos, icons, documents, and
                materials (collectively, the “Contents”) on the
                Website, it makes no representation, endorsement, or
                warranty that such Contents are accurate or suitable
                for any particular purpose. THE WEBSITE AND ITS
                CONTENTS ARE PROVIDED ON AN “AS IS” BASIS. USE OF THE
                WEBSITE AND ITS CONTENTS IS AT THE USER’S SOLE RISK.
                THE WEBSITE AND ITS CONTENTS ARE PROVIDED WITHOUT ANY
                REPRESENTATIONS, ENDORSEMENTS, OR WARRANTIES OF ANY
                KIND WHATSOEVER, EITHER EXPRESS OR IMPLIED, INCLUDING,
                BUT NOT LIMITED TO, ANY WARRANTIES OF TITLE OR
                ACCURACY AND ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                NON-INFRINGEMENT, WITH THE SOLE EXCEPTION OF
                WARRANTIES (IF ANY) WHICH CANNOT BE EXPRESSLY EXCLUDED
                UNDER APPLICABLE LAW. CELINK ALSO MAKES NO
                REPRESENTATIONS, ENDORSEMENTS, OR WARRANTIES, EITHER
                EXPRESS OR IMPLIED, WITH RESPECT TO ANY WEBSITE
                OPERATED BY A THIRD PARTY.
              </p>
              <p>
                <strong>Limitation of Liability.</strong> IN NO EVENT
                WILL CELINK OR ITS SUBSIDIARIES, AFFILIATES,
                CONTRACTORS, OR THEIR RESPECTIVE EMPLOYEES, OFFICERS
                OR AGENTS BE LIABLE FOR ANY DAMAGES, INCLUDING,
                WITHOUT LIMITATION, INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL OR PUNITIVE DAMAGES, WHETHER UNDER A
                CONTRACT, TORT OR ANY OTHER THEORY OF LIABILITY,
                ARISING IN CONNECTION WITH ANY PARTY’S USE OF THE
                WEBSITE OR IN CONNECTION WITH ANY FAILURE OF
                PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT,
                DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS,
                LINE SYSTEM FAILURE, LOSS OF DATA, COMPUTER DAMAGE, OR
                LOSS OF USE RELATED TO THIS WEBSITE OR ANY WEBSITE
                OPERATED BY ANY THIRD PARTY OR ANY CONTENTS OF THIS
                WEBSITE OR ANY OTHER WEBSITE, EVEN IF CELINK IS AWARE
                OF OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES.
              </p>
              <p>
                <strong>Use of Website.</strong> Contents are included
                in this Website solely for the personal use of
                authorized (meaning, the borrower or borrower's agent)
                Website users. You may not copy (other than a copy for
                personal use), modify, distribute, transmit, display,
                perform, reproduce, transfer, resell, or republish any
                of the Contents of this Website without the prior
                written consent of Celink, which may be withheld in
                its sole discretion. The Website may be temporarily
                unavailable from time-to-time for maintenance or other
                reasons. Celink assumes no responsibility for any
                error, omission, interruption, deletion, defect, delay
                in operation or transmission, communications line
                failure, theft or destruction or unauthorized access
                to, or alteration of, communications with you.{' '}
              </p>
              <p>
                <strong>
                  Copyrights and Other Intellectual Property
                </strong>{' '}
                Except where otherwise expressly noted or as noted
                below, all Contents of this Website, including the
                graphics, icons and overall appearance of the Website,
                are the sole and exclusive property of Celink and/or
                its subsidiaries or affiliates. The posting of the
                Contents of this Website neither constitutes a waiver
                of any of Celink’s proprietary rights or any other
                party’s proprietary rights, including but not limited
                to, copyrights, trademarks, servicemarks, patents, and
                other intellectual property, nor a transfer by
                implication, estoppel, or otherwise of any such rights
                or of any license to the Website user or to any third
                party. Contents of this Website are protected by
                United States and international copyright laws, both
                as individual works and as a collection and by United
                States and international trademark laws. You agree not
                to delete any copyright, trademark or similar notice
                from any Contents you obtain from the Website.
                Celink’s trade and service marks may not be used or
                reproduced without the Company’s prior written
                consent.
              </p>
              <p>
                The display of any of Celink’s trademarks or service
                marks within this Website does not grant a license of
                any kind to the reader. Any downloading of Contents of
                this Website or any website linked to this Website may
                be a violation of federal and other trademark laws and
                federal copyright laws.
              </p>
              <p>
                <strong>Privacy Policy.</strong> Please review the
                Online Privacy Policy{' '}
                <a href="https://www.reversedepartment.com/privacy-policy">
                  https://www.reversedepartment.com/privacy-policy
                </a>
                . This notice explains our policies for collecting and
                using the information that we collect from you or
                about you. The Online Privacy Policy is incorporated
                in these Terms of Service by reference. By using the
                Website, you consent to our collection and use of your
                personal information as set forth in the Online
                Privacy Policy. You understand and agree that we may
                modify or update our Online Privacy Policy at any time
                and that your continued use of the Website after any
                such change or modification signifies your agreement
                to the updated or modified Online Privacy Policy.
              </p>
              <p>
                <strong>Accuracy of Information.</strong> You are
                responsible for confirming the accuracy of any
                information you submit to our Website or any third
                party (such as your lender or the holder of your
                reverse mortgage note). We are not responsible for any
                incorrect or inaccurate information relating to your
                account with us. We do not guarantee the accuracy of
                information supplied by any person (including you) to
                any third parties or supplied by any third parties to
                anyone.
              </p>
              <p>
                <strong>Communications.</strong> To service your
                account, we may contact you at any telephone number or
                email address that you provide. In providing Celink
                with a telephone number on the Website or updating
                your telephone number on the Website, you consent to
                receive calls using an automatic telephone dialing
                system and/or an artificial or pre-recorded voice, and
                text messages from or on behalf of Celink at any
                telephone number provided to Celink for informational
                and account service calls, but not for telemarketing
                or sales calls. It may include contact from companies
                working on our behalf to service your accounts.
                Message and data rates may apply. You may contact us
                at any time to change these preferences.
              </p>
              <p>
                <strong>Prohibited Activities.</strong> You are
                prohibited from using the Website or your account in
                any unlawful manner or any other manner that could
                damage, disable, overburden or impair the Website. You
                are prohibited from violating or attempting to violate
                any security features of the Website, including but
                not limited to: (i) accessing Content or data not
                intended for you or logging into a server or account
                that you are not authorized to access; (ii) creating,
                accessing or attempting to create or access an account
                for which you are not an authorized user; (iii)
                attempting to probe, scan, or test the vulnerability
                of the Website, or any associated system or network,
                or to breach security or authentication measures
                without proper authorization; (iv) interfering with or
                attempting to interfere with our services to any user,
                host or network, including without limitation, by
                means of submitting a virus to the Website,
                overloading, spamming, mail bombing or crashing the
                Website; (v) using the Website to send unsolicited
                email, including without limitation promotions or
                decompile, disassemble or otherwise reduce or attempt
                to reduce to any form any of the source code used by
                us to provide and maintain the Website. Any violation
                of system or network security may subject you to civil
                or criminal liability, or both.
              </p>
              <p>
                <strong>Indemnification for Unauthorized Use.</strong>{' '}
                If you use the website to access data related to any
                account(s) of which you are not the owner or
                authorized user, you shall indemnify, defend, and hold
                harmless Celink/ Reverse Mortgage Servicing Department
                and/or any affected third parties from any and all
                losses, liabilities, damages, and all related costs
                and expenses (including any court costs and legal
                fees) to the extent permitted by law, arising from,
                relating to, or resulting (directly or indirectly)
                from such access. Further, without limiting
                Celink/Reverse Mortgage Servicing Department's rights
                or your obligations under any other provision of these
                Terms and Conditions, and notwithstanding the same, in
                the event of any actual or reasonably suspected
                unauthorized access to the personal information of a
                customer (including but not limited to customer names,
                addresses, phone numbers, bank and credit card account
                numbers, income and credit histories, and social
                security numbers) under your control or subsequent to
                and arising from your past exercise of control, direct
                damages in connection with any such breach will
                include the cost and expenses of investigation and
                analysis (including by law firms and forensic firms),
                correction or restoration of any destroyed, lost or
                altered data, notification to affected customers,
                offering and providing of credit monitoring, customer
                service, or other remediation services, and any
                related cost. Celink/Reverse Mortgage Servicing
                Department's rights to indemnity under this section
                are in addition to all other rights and remedies
                available at Law or in equity. Any exercise by
                Celink/Reverse Mortgage Servicing Department of its
                rights to indemnification shall be without prejudice
                to such other rights and remedies. You manifest your
                assent to this indemnity by accessing account data
                through the website or systems, notwithstanding the
                terms of any agreement you have with a customer or an
                account owner stating otherwise. This indemnity
                includes but is not limited to losses associated with
                (1) a data breach of your system(s) and (2) a data
                breach of the system(s) of any person or entity with
                whom you provided or shared Celink/Reverse Mortgage
                Servicing Department's customer account data.
              </p>
              <p>
                <strong>Links to Other Websites.</strong> Celink may
                establish links between this Website and one or more
                websites operated by third parties. Celink has no
                control over any such other websites or the contents
                therein. The existence of any such links shall not
                constitute an endorsement by Celink of such websites,
                the contents of the websites, or the operators of the
                websites.
              </p>
              <p>
                <strong>
                  Transmissions to and from this Website.
                </strong>{' '}
                Any transmission to Celink through this Website,
                including emails, shall be deemed and remain the
                property of Celink. Celink shall be free to use, for
                any purpose, any ideas, concepts, know-how, or
                techniques provided by a Website user to Celink
                through this Website.
              </p>
              <p>
                <strong>
                  Available Only Where Permitted by Law.
                </strong>{' '}
                The products and services of Celink are available only
                in the United States, where permitted by law. The
                owner of the Website is based in the United States. We
                provide this Website for use only by persons located
                in the United States and its territories. We make no
                claims that the Website or any of its content is
                accessible or appropriate outside of the United States
                or its territories. Access to the Website may not be
                legal by certain persons or in certain countries. If
                you access the Website from outside the United States
                or its territories, you do so on your own initiative
                and are responsible for compliance with local laws.
              </p>
              <p>
                <strong>Governing Law; Venue.</strong> These Terms of
                Service shall be governed by and construed in
                accordance with the Iaws of the State of Michigan,
                without regard to the conflict of laws thereof, and to
                the laws of the United States. Disputes arising from
                the use of this Website shall be exclusively subject
                to the jurisdiction of the federal or state court in
                Lansing, Michigan.{' '}
              </p>
              <p>
                <strong>Questions?</strong>
              </p>
              <p>
                Please contact us with questions about these Terms of
                Service at 1-866-654-0020 or write to us at:
              </p>
              <p>
                Celink/Reverse Mortgage Servicing Department
                <br />
                P.O. Box 40724
                <br />
                Lansing, MI 48901-7924
              </p>
            </div>
          </Container>
          <ScrollUpButton />
        </main>
      </StyledTermsConditions>
    </>
  )
}

export default TermsConditions
