import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { buildTestID } from '../../Utils/utils'

import CommonQuestionCategories from './CommonQuestionCategories'

const Popular = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.Popular, title)
  })

  return (
    <>
      <h3 ref={title} id="popular" tabIndex="-1">
        Popular
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
              {' '}
              How do I communicate with you in a language other than
              English? Do you have a Language Assistance service?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              {' '}
              Borrower Care team members who speak English or Spanish
              can be reached at 866-654-0020. Additionally, we have
              established a relationship with a third-party
              interpreter service, Language Line Services (LLS). LLS
              is an over-the-phone interpretation service that assists
              customers who request a translation of any communication
              in a language other than English. Call us at
              866-654-0020 and we will connect an LLS agent to
              translate the call. For New York City borrowers, a
              translation and description of commonly-used debt
              collection terms is available in multiple languages on
              the NYC Department of Consumer Affairs website:{' '}
              <a
                href=" https://www.nyc.gov/dca"
                aria-label="NYC Department of Consumer Affairs"
              >
                https://www.nyc.gov/dca
              </a>
              .
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              {' '}
              How do I request an advance from my Line of Credit
              (LOC)?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {' '}
              If your loan has an established line of credit, you may
              request funds from your Line of Credit by <a href="/" target="_blank" data-testid={buildTestID('how_do_i_loc', '/popular', 'log_on')}>logging into
                this website</a> where you can request an advance securely
              online. Otherwise, you may fax or mail a request using
              the fax number or mailing address on the LOC form
              provided to you with your monthly account statement. All
              requests for funds will be validated and, provided funds
              are available and the request is approved, we will
              disburse the funds to you within 5 business days of
              receipt.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              {' '}
              How long before I get my money?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {' '}
              Upon receipt and validation of your request for funds, a
              payment will be processed via ACH (if EFT/Direct Deposit
              has been established) or mailed within five (5) business
              days of receipt.{' '}
              <strong>
                We encourage you to setup EFT/Direct Deposit for the
                fastest and safest delivery method of your funds. It’s
                simple – just complete the{' '}
                Electronic Funds Transfer (EFT) / Direct Deposit Agreement
                (login to your account to get this form).
              </strong>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              How do I order a payoff quote?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              If your loan is in an Active status and is otherwise eligible, you may obtain a Payoff Quote instantaneously by <a href="/" target="_blank" data-testid={buildTestID('how_do_i_payoff_quote', '/popular', 'log_on')}>logging into
                this website</a>. Otherwise, a borrower or their authorized representative (i.e., Attorney-in-Fact, Guardian or Conservator) may request a payoff using the{' '}
              <a href="./forms/2024/Request_for_Reverse_Mortgage_Payoff_Quote.pdf" target="_blank">
                Request for Reverse Mortgage Payoff Quote Form
              </a>{' '}
              and send the form, signed by the borrower or their authorized representative, to the email address, fax number or mailing address on the form.
              <br />
              <br />
              <strong>
                NOTE: The outstanding balance on your monthly
                statement is not sufficient to pay your loan off in
                full. Please submit the{' '}
                <a href="./forms/2024/Request_for_Reverse_Mortgage_Payoff_Quote.pdf" target="_blank">
                  Request for Reverse Mortgage Payoff Quote Form
                </a>{' '}
                if you intend to pay the loan in full and close the
                account.
              </strong>
              <br />
              <br />
              <strong>
                <em>
                  Please be advised that if payment is received in full, the loan and any line of credit (if applicable) will be permanently closed. If it is not your intention to close the loan and any line of credit, a positive unpaid loan balance must be maintained AND you must indicate with your prepayment that the loan is to remain open and not be paid in full and closed - include your intent with your check or in wire comments. If you are attempting to pay down the loan balance without closing the loan, the amount of your prepayment should be less than the Current Unpaid Balance shown on your most recent statement.
                </em>
              </strong>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              {' '}
              When will I receive an account statement?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              {' '}
              You will receive a monthly statement of the activity on your reverse mortgage. You may OPT IN to paperless statements by <a href="/" target="_blank" data-testid={buildTestID('when_will_i_receive_my', '/popular', 'log_on')}>logging into
                this website</a> and selecting the paperless option. If you go paperless, you will receive an email each month when your statement is ready. If you choose not to opt in to paperless statements, a statement will be mailed to you, early in the  following month. Please allow for mailing time - it can take an additional 7 days for you to receive your statement in the mail.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              What are the requirements for EFT/Direct Deposit?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              To establish or change direct deposit information, the following are required:
              <ol>
                <li>A completed and signed (by all borrowers) Electronic Funds Transfer/Direct Deposit Agreement (login to your account to get this form).</li>
                <li>Include a voided check, or a bank statement that provides the full routing number and full account number (image acceptable), or a letter signed by the financial institution on their letterhead confirming the deposit account routing number and account number.</li>
                <li>A personal or Trust checking or savings account may be used but a business account may NOT be used, even if a borrower's name appears on the account. <strong>Note: The Trust must be approved and on file with us</strong>.</li>
                <li>If there is/are additional person(s) named or authorized on the deposit account, the signature(s) on the Electronic Funds Transfer/Direct Deposit Agreement gives consent to deposit funds from the reverse mortgage loan to the account, and by signing the Agreement borrowers understand that anyone else on their bank account will have access to any funds deposited.</li>
              </ol>
              <ul>
                <li>IMPORTANT NOTES:<br/>We cannot accept Deposit slips or Counter checks (checks that are not imprinted with the account holder’s name). <br/>If there are two or more borrowers on the loan, we can accept an account that names only one borrower if all borrowers have signed the Agreement.<br/><strong><u>When updating or adding a bank account, the account cannot be used to receive funds for at least 14 calendar days following processing and validation</u></strong>.</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="5">
              {' '}
              How can I authorize third parties to receive information
              on my account?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              {' '}
              A borrower may submit a signed <a href="./forms/2024/Third_Party_Authorization.pdf" target="_blank" data-testid={buildTestID('how_can_i_authorize_third_party', '/popular', 'third_party_for')}>Third Party Authorization Form</a> authorizing us to provide account-specific information on their reverse mortgage to their third-party designee(s) –they must list the specific names of the authorized persons. Signed authorizations may be uploaded <a href="/common-forms" target="_blank" data-testid={buildTestID('how_can_i_authorize_third_party', '/popular', 'common_forms')}>here</a>, faxed to 866-616-2160 or mailed to P.O. Box 40724, Lansing, MI 48901.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}
export default Popular
