import React, { useState, useRef } from 'react'
import { Auth } from 'aws-amplify'
import Styled from 'styled-components'
import { Alert } from 'react-bootstrap'

import Button from '../../Common/button'
import { useFocus } from '../../Hooks/useFocus'
import AuthContainer from './AuthContainer'
import { getRandomLetters } from '../../services/auth'
import { buildTestID } from '../../Utils/utils'
import PasswordValidation from './PasswordValidation'
import PasswordInput from './ShowHidePassword'
import { SIGN_IN } from '../AuthLayout'

const StyledResetPasswordModern = Styled.div`
font-size: 20px;

  .error-message {
    color: ${({ theme }) => theme.colors.error};
  }

  .stacked-buttons {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 4px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }

  .label {
    color: var(--Black, #000);
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 137.5%;
    // margin-top: 82px;
  
    @media (max-width: 991px) {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .input {
    font-family: Lato, sans-serif;
    border-radius: 10px;
    border: 1px solid #a9a9a9;
    background-color: var(--White, #fff);
    // margin-top: 6px;
    justify-content: center;
    color: var(--Grey-2, #777);
    font-weight: 400;
    line-height: 137.5%;
    
    display: flex;
    padding: 10px 8px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 20px;
    width: 100%;
  
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
`

const ResetPassword = ({ setAuthState, setActiveFormTab }) => {
  const [username, setUsername] = useState('')
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const [isShowingSubmit, setIsShowingSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)

  const [randomLetters, setRandomLetters] = useState('')

  const setErrorForAda = (error) => {
    setRandomLetters(getRandomLetters(4))
    setError(error)
  }

  const email = useRef(null)
  useFocus(email, true)
  const handleGetPassword = async (e) => {
    e.preventDefault()
    try {
      if (!username) {
        setErrorForAda('Email cannot be empty')
        return
      }
      await Auth.forgotPassword(username.toLowerCase())
    } catch ({ code, message }) {
      if (code === 'LimitExceededException') {
        setErrorForAda(
          'Exceeded limit for forgot password requests. Please try again later.',
        )
        return
      }
    }
    setIsShowingSubmit(true)
  }

  const handleCodeChange = (e) => {
    setError('')
    if (e.target.value.length <= 6) {
      setCode(e.target.value.replace(/\D/g, ''))
    }
  }

  const handleGetPasswordSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
      // if (flags && flags.landingPageV2) { setActiveFormTab(SIGN_IN) } else { setAuthState('signIn') }
      setActiveFormTab(SIGN_IN)
      setLoading(false)
    } catch (err) {
      setErrorForAda(err.message)
      setLoading(false)
    }
  }

  const getForgetPassword = () => {
    return (
    <StyledResetPasswordModern>
        <form onSubmit={handleGetPassword}>
          <div className="">
            <div className="">
              <div className="">
                <label className="label" htmlFor="email">
                  <strong>Email</strong>
                </label>
              </div>
              <input
                placeholder="Email"
                name="username"
                autoComplete="off"
                className="input"
                value={username}
                onChange={(e) => setUsername(e.target.value.trim())}
                id="email"
                ref={email}
                type="email"
                data-testid={buildTestID(
                  'email',
                  '/home',
                  'reset_password',
                )}
              />
            </div>
          </div>
          <p role="alert">
            <span className="error-message">
              {error}
              <span
                style={{ visibility: 'hidden' }}
                dangerouslySetInnerHTML={{ __html: randomLetters }}
              ></span>
            </span>
          </p>
          <div className="buttons">
            <Button
              type="submit"
              text="Confirm"
              UID="reset_password"
            />
            <Button
              onClick={() => setActiveFormTab(SIGN_IN)}
              textButton
              text="Back to Sign In"
              small
              UID="reset_password"
            />
          </div>
        </form>
      </StyledResetPasswordModern>)
  }

  const getForgetPasswordSubmit = () => {
    return (
    <StyledResetPasswordModern>
        <form onSubmit={handleGetPasswordSubmit}>
          <div className="Section__sectionBody___ihqqd">
            <Alert variant="success">
              Your code has been sent to your email or phone. If you
              do not receive the email or text message, please verify
              you entered it correctly.
            </Alert>
            <div className="">
              <div className="label">
                <label htmlFor="code">Code *</label>
              </div>
              <input
                placeholder="Code"
                name="code"
                autoComplete="off"
                className="input"
                value={code}
                onChange={handleCodeChange}
                id="code"
                aria-required="true"
                type="text"
                pattern="[a-zA-Z0-9]+"
                data-testid={buildTestID(
                  'code',
                  '/home',
                  'forgot_password_submit',
                )}
              />
            </div>
            <div className="">
              <div className="label">
                <label htmlFor="password">New Password *</label>
              </div>
              <PasswordInput
                placeholder="Password"
                name="password"
                type="password"
                autoComplete="off"
                className="input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                aria-required="true"
                datatestid="reset_password"
              />
              <p role="alert">
                <span className="error-message">
                  {error}
                  <span
                    style={{ visibility: 'hidden' }}
                    dangerouslySetInnerHTML={{
                      __html: randomLetters,
                    }}
                  ></span>
                </span>
              </p>
              <PasswordValidation
                password={password}
                showConfirmPassword={false}
                setIsPasswordValid={setPasswordValid}
              />
            </div>
          </div>

          <div className="buttons">
            {loading ? (
              <Button
                loading
                text="Loading..."
                UID="forgot_password_submit"
              />
            ) : (
              <Button
                text="Confirm"
                type="submit"
                UID="forgot_password_submit"
                disabled={!passwordValid}
              />
            )}
            <div className="stacked-buttons">
              <Button
                onClick={(e) => handleGetPassword(e)}
                textButton
                text="Resend Code"
                small
                UID="forgot_password_submit"
              />
              <Button
                onClick={() => setActiveFormTab(SIGN_IN)}
                textButton
                text="Back to Sign In"
                small
                UID="forgot_password_submit"
              />
            </div>
          </div>
        </form>
      </StyledResetPasswordModern>
    )
  }

  return (
    <>
      <AuthContainer header="Reset your password">
        {isShowingSubmit
          ? getForgetPasswordSubmit()
          : getForgetPassword()}
      </AuthContainer>
    </>
  )
}

export default ResetPassword
