import React, { useState } from 'react'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import Button from '../../Common/button'
import PiggyBankIcon from '../../assets/piggy-bank-icon.js'
import { getPayOffQuote,updatePayoffRequest } from '../../services/api'
import { trackPayoffQuote } from '../../Global/Analytics'
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from '../../Utils/utils'

import BannerBar from '../../Common/BannerBar'
import GenerateQuoteBlock from './GenerateQuote'
import QuoteReadyBlock from './QuoteReady'
import InfoCard from '../../Common/InfoCard'
import DisclosuresBlock from './Disclosures'

const StyledPayoffQuote = styled.div`
  .background {
    height: 330px;
    width: 100%;
    position: absolute;
    z-index: -1;
    background: ${({ theme }) => theme.colors.primary4};
  }
  .banner {
    margin-bottom: 30px;
  }
  .back-to-my-loan-button {
    display: flex;
    margin: 26px 0 30px 0;
    justify-content: start;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .background {
      display: none;
    }
  }
`

const PayoffQuote = ({
  loanNumber,
  clientRefiInfo,
  disallowedDays,
  maxDate,
  isMass,
  eligible
}) => {
  const [selectedDate, setSelectedDate] = useState(maxDate)
  const [generatedDate, setGeneratedDate] = useState('')
  const [generatingPDF, setGeneratingPDF] = useState(false)
  const [errorWithRequest, setErrorWithRequest] = useState(false)
  const [payoffQuoteUrl, setPayoffQuoteUrl] = useState('')
  const [destination, setDestination] = useState('');
  const [type, setType] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleGetQuote = async () => {
    try {
      setErrorWithRequest(false)
      setGeneratingPDF(true)
      setGeneratedDate(selectedDate)
      const result = await getPayOffQuote(
        loanNumber,
        dayjs(selectedDate).format(),
      )
      setPayoffQuoteUrl(
        result.data.getPayoffQuote.details.payoff_presignedurl,
      )
      trackPayoffQuote.Requested(selectedDate.toString())
    } catch {
      setErrorWithRequest(true)
    } finally {
      setGeneratingPDF(false)
    }
  } 
  const handleDelivery = async () => {
    setErrorWithRequest(false)
    setError(false)
    setSuccess(false)
    setLoading(true)
    try {
    const requestedGoodThroughDate = dayjs(selectedDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD');
    const result = await updatePayoffRequest(
      destination,
      requestedGoodThroughDate,
      type
    )
    let response = await result.data.putPayoffRequest.responseData;
    
    response.responseCode === 200 ? (setSuccess(true)) : (setError(true));
    } catch {
      setErrorWithRequest(true)
      setSuccess("");
    }
    setLoading(false)
  }

  return (
    <StyleGuide>
      <StyledPayoffQuote>
        <div className="background" />
        <Grid>
          <BannerBar
            show={errorWithRequest}
            type="error"
            text="This part of our website is not currently available. Please try again later. Thank you."
          />
          <Button
            className="back-to-my-loan-button"
            text="Back To my loan"
            textButton
            backArrow
            linkTo="my-loan"
            UID="payoff_quote"
          />
          <GenerateQuoteBlock
            selectedDate={selectedDate}
            handleDateSelection={(date) => setSelectedDate(date)}
            handleDeliveryAdress={(data) => setDestination(data)}
            handleDeliveryOption={(select) => setType(select)}
            generatingPDF={generatingPDF}
            handleGetQuote={handleGetQuote}
            handleDelivery={handleDelivery}
            disallowedDays={disallowedDays}
            maxDate={maxDate}
            isMass={isMass}
            eligible={eligible}
            loading={loading}
          />
          { success ?
            (<BannerBar
              show={success}
              className="banner"
              type="verified"
              text="Your request has been received and we will deliver 
              to you within 5 business days."
             />) : (
              <BannerBar
              show={error}
              className="banner"
              type="error"
              text="Your previous request is still being processed. Please allow 5 business days for processing."
             />)
          }
          {Boolean(payoffQuoteUrl) ? (
            <QuoteReadyBlock
              generatingPDF={generatingPDF}
              generatedDate={generatedDate}
              payoffQuoteUrl={payoffQuoteUrl}
            />
          ) : null}
          <InfoCard
            title="Wiring Instructions Update"
            paragraph={
              <p className="body-2 paragraph"><strong>IMPORTANT</strong>: Our wiring instructions have recently changed. Please confirm the wiring instructions on your payoff quote prior to sending funds. Log in to your account to obtain a current payoff quote or order a quote using this <a href="./forms/Request_for_Reverse_Mortgage_Payoff_Quote.pdf" target={"_blank"} style={{"textTransform": "none"}}>form</a>.</p>
            }
          />
          <InfoCard
            title="Thinking of refinancing?"
            SVG={PiggyBankIcon}
            paragraph={
              isValidPhoneNumber(clientRefiInfo) ? (
                <>
                  If you are interested in refinancing your reverse
                  mortgage loan, please call us at{' '}
                  <a
                    className="caption phone-web-link"
                    href={`tel:+1-${formatPhoneNumber(
                      clientRefiInfo,
                    )}`}
                  >
                    {formatPhoneNumber(clientRefiInfo)}
                  </a>{' '}
                  to learn more. Refer to code PORTAL when you call.
                </>
              ) : (
                <>
                  If you are interested in refinancing your reverse
                  mortgage loan, please visit{' '}
                  <a
                    className="caption phone-web-link"
                    href="https://www.reversemortgage.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.reversemortgage.org
                  </a>{' '}
                  to learn more.
                </>
              )
            }
          />
          <div className="break_line" />
          <DisclosuresBlock />
        </Grid>
      </StyledPayoffQuote>
    </StyleGuide>
  )
}

PayoffQuote.propTypes = {
  loanNumber: PropTypes.number.isRequired,
  clientRefiInfo: PropTypes.string.isRequired,
  disallowedDays: PropTypes.array.isRequired,
  maxDate: PropTypes.PropTypes.instanceOf(Date).isRequired,
  isMass: PropTypes.bool.isRequired,
  eligible: PropTypes.bool.isRequired
}

export default PayoffQuote