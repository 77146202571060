import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { buildTestID } from '../../Utils/utils'

import CommonQuestionCategories from './CommonQuestionCategories'

const PrepaymentsPayoffs = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.PrepaymentsPayoffs, title)
  })

  return (
    <>
      <h3 ref={title} id="prepayments-payoffs" tabIndex="-1">
        Prepayments and Payoffs
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Can I make a payment on the loan to pay down the balance
              and still keep my line of credit open for future use?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Yes, you may make prepayments at any time by sending a check to the address on your monthly loan statement, along with a <a href="./forms/2024/Partial_Prepayment_Form.pdf" target="_blank">
                Partial Prepayment Form
              </a>{' '}(or write your loan number on the check and the word "prepayment"). Wire transfer and pay-by-phone with a checking account options are also available.
              <br />
              <br />
              IMPORTANT: Please be advised that if payment is received in full, the loan and any line of credit (LOC) (if applicable) will be permanently closed and the lien(s) will be released. If it is not your intention to close the loan and any line of credit, you must maintain an unpaid loan balance.  Please complete and submit a <a href="./forms/2024/Partial_Prepayment_Form.pdf" target="_blank">
                Partial Prepayment Form
              </a>{' '}, or include a letter of intent with your payment to clearly state your intentions to either close the loan or keep the LOC open.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              How can my loan be paid off?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              A loan can be paid off by the proceeds from the sale of
              the property, refinancing, or personal funds. A Payoff
              Quote must be requested (see below) and certified funds
              will be required.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="how-do-i-order-payoff-quote">
          <Card.Header>
            <CustomToggle eventKey="2">
              How do I order a payoff quote?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              If your loan is in an Active status and is otherwise eligible, you may obtain a Payoff Quote instantaneously by <a href="/" target="_blank" data-testid={buildTestID('how_do_i_order_payoff_quote', '/prepaymentpayoffs', 'log_on')}>logging into this website</a>. Otherwise, a borrower or their authorized representative (i.e., Attorney-in-Fact, Guardian or Conservator) may request a payoff using the  <a href="./forms/2024/Request_for_Reverse_Mortgage_Payoff_Quote.pdf">
                Request for Reverse Mortgage Payoff Quote Form
              </a>{' '} and send the form, signed by the borrower or their authorized representative, to the email address, fax number or mailing address on the form. NOTE: The outstanding balance on your monthly statement is not sufficient to pay your loan off in full. Please submit the  <a href="./forms/2024/Request_for_Reverse_Mortgage_Payoff_Quote.pdf" target="_blank">
                Request for Reverse Mortgage Payoff Quote Form
              </a>{' '} if you intend to pay the loan in full and close the account.
              <br />
              <br />
              Please be advised that if payment is received in full, the loan and any line of credit (if applicable) will be permanently closed. If it is not your intention to close the loan and any line of credit, a positive unpaid loan balance must be maintained AND you must indicate with your prepayment that the loan is to remain open and not be paid in full and closed - include your intent with your check or in wire comments. If you are attempting to pay down the loan balance without closing the loan, the amount of your prepayment should be less than the Current Unpaid Balance shown on your most recent statement.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              If I make a prepayment on my loan, are those funds available for me to draw in the future?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              If your loan is a HECM loan and includes a line of credit, prepayments will be applied to your LOC and available for future draws. If you have a non-HECM loan, please refer to any LOC restrictions in your loan documents. Please be aware that funds may not be immediately available for you to draw after you make a prepayment as we must wait for your payment to clear your bank.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              Can I designate how my prepayments are applied to my balance (e.g. interest only)?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              The application of your prepayments to the various fees and balances (MIP, Servicing Fees, Interest, Principal) are outlined in your loan documents, specifically the Note. All prepayments are applied in a "waterfall" according to the provisions of your Note. That "waterfall" is typically as follows (but can vary by loan product): First to the aggregate amount of the principal balance representing MIP (if applicable). Next, to the aggregate amount representing servicing fees (if any). Third to accrued interest. Fourth, to the outstanding principal balance.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default PrepaymentsPayoffs
