import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const UploadIcon = ({ className, color = 'primaryDark' }) => {
  return (
    <StyledSVG
      className={className}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="44"
      fill="none"
      role="presentation"
    >
      <path
        fill="#00377A"
        d="M46.658 14.584C44.07 4.274 33.614-1.986 23.303.6a19.248 19.248 0 00-14.51 17.272C3.07 18.817-.803 24.22.141 29.941a10.499 10.499 0 0010.4 8.79h8.75v-3.5h-8.75a7 7 0 010-13.998 1.75 1.75 0 001.75-1.75c-.009-8.697 7.035-15.755 15.732-15.764a15.748 15.748 0 0115.467 12.702 1.75 1.75 0 001.487 1.4 8.75 8.75 0 01-1.19 17.41H36.79v3.5h7c6.764-.02 12.231-5.52 12.21-12.286a12.249 12.249 0 00-9.34-11.861z"
      ></path>
      <path
        fill="#00377A"
        d="M26.798 21.741l-7 7 2.468 2.467 4.025-4.007v16.78h3.5v-16.78l4.006 4.007 2.468-2.467-7-7a1.75 1.75 0 00-2.467 0z"
      ></path>
    </StyledSVG>
  )
}

export default UploadIcon
