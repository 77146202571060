import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { buildTestID } from '../../Utils/utils'

import CommonQuestionCategories from './CommonQuestionCategories'

const PaymentPlanChanges = ({ CustomToggle, addRef }) => {
    const title = useRef(null)

    useEffect(() => {
        addRef(CommonQuestionCategories.PaymentPlanChanges, title)
    })

    return (
        <>
            <h3 ref={title} id="payment-plan-changes" tabIndex="-1">Payment Plan Changes (PPCs)</h3>
            <Accordion>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="0">
                            What types of Payment Plans are available?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            For most reverse mortgages, including HECM loans,
                            borrowers may choose any of the following (or a
                            combination of them) when their loan closes. Changes to
                            your payment plan may be available after closing – see
                            your loan documents and “How do I request a payment plan
                            change (PPC)?” below.
                            <ol>
                                <li>
                                    A <strong>lump sum</strong> disbursement at closing
                                    (all available funds are disbursed when the loan
                                    closes)
                                </li>
                                <li>
                                    Monthly payments paid to the borrower on the first
                                    of each month, for a specific term{' '}
                                    <strong>(term payment plan)</strong> or their
                                    lifetime <strong>(tenure payment plan)</strong>
                                </li>
                                <li>
                                    A <strong>line of credit</strong>, with funds
                                    available when the borrower needs them.
                                </li>
                                <li>
                                    A <strong>Modified Term</strong> payment plan allows
                                    a borrower to combine a term payment plan (fixed
                                    monthly payments for a term of months) with a line
                                    of credit. The borrower sets aside a portion of the
                                    principal limit as a line of credit from which to
                                    draw at times and in amounts of his or her choosing
                                    and receives the rest in equal monthly payments for
                                    a term of months selected by the borrower, as long
                                    as he or she maintains the property as a principal
                                    residence.
                                </li>
                                <li>
                                    A <strong>Modified Tenure</strong> payment plan
                                    allows a borrower to combine a tenure payment plan
                                    (fixed monthly payments for as long as property is
                                    principal residence) with a line of credit. The
                                    borrower sets aside a portion of the principal limit
                                    as a line of credit from which to draw at times and
                                    in amounts of his or her choosing and receives the
                                    rest in equal monthly payments for as long as he or
                                    she continues to occupy the home as a principal
                                    residence.
                                </li>
                            </ol>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="1">
                            How do I request a payment plan change (PPC)?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <strong>
                                <em>
                                    Important: Please be aware that if your payment plan is changed at any time when you are receiving monthly payments, the amount of your payment and/or the term of your payments will be recalculated and either or both could change.
                                </em>
                            </strong>
                            <br />A PPC may be requested by the borrower, their agent [power of attorney (POA), guardian/conservator], or their authorized third party* by completing the <a href="./forms/2024/Payment_Plan_Change_Request.pdf" target="_blank">Payment Plan Change Request</a> form found on this website or by mail to Reverse Mortgage Servicing Dept, PO Box 40724, Lansing, MI 48901-7924. PPC documents will then be prepared and sent to the borrowers for signature before the PPC can be implemented. All PPCs are effective as of the first of the following month following receipt of the documents signed by the borrower(s) or their agent. If the terms of your loan, as outlined in your loan agreement, provide for a fee to be charged when you request a payment plan change, that fee will be added to your loan balance.
                            <br />
                            <strong>
                                *Note – while an authorized third party can request PPC documents on behalf of the borrower, only the borrower or their agent (POA, guardian/conservator) can sign the documents authorizing the change.
                            </strong>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="2">
                            If I selected a lump sum payment type at closing, can I
                            have a payment plan change later in the life of the
                            loan?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            No – payment plan changes are not allowed for lump sum
                            payment plan types as all available funds were withdrawn
                            at the time the loan was closed.
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="3">
                            I have monthly payments but no Line of Credit. I need a
                            lump sum disbursement/advance. What do I do?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            A payment plan change will need to be requested by completing the <a href="./forms/2024/Payment_Plan_Change_Request.pdf" target="_blank">Payment Plan Change Request</a> form found on this website or by mail to Reverse Mortgage Servicing Dept, PO Box 40724, Lansing, MI 48901-7924, requesting the amount of funds needed in a lump sum. We will determine the effect of the lump sum disbursement/advance on your scheduled monthly payment (either a reduced term or reduced monthly payment) and send you a new payment plan document to be signed and returned.
                            <br />
                            <br />
                            <strong>
                                <em>
                                    Important: Please be aware that if your payment plan is changed at any time when you are receiving monthly payments, the amount of your payment and/or the term of your payments will be recalculated and either or both could change.
                                </em>
                            </strong>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="4">
                            When can I get the lump sum advance money?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            While Payment Plan Changes (PPCs) can only go into effect on the first business day of a month, if you request to create a line of credit and a lump sum advance, the funds in the line of credit will become available once we receive the signed and dated <em>Modification of Home Equity Conversion Loan Agreement</em>. Please send in a signed <a href="./forms/2024/Line_Of_Credit_Draw_Request.pdf" target="_blank">Line of Credit Draw Request</a> form with your signed agreement and we will process your advance within 5 (five) days of receipt of both documents. Please note that this signed agreement must be received by the deadline stated in the <em>Modification of Home Equity Conversion Loan Agreement</em> which is generally the third week of the month prior to the effective date of the change (example, if the deadline date in the agreement is August 25th, and we received your signed agreement by that date, the payment plan change will be effective September 1st).
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="5">
                            I have a Line of Credit, but I want to receive monthly
                            payments. What do I do?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>
                            A payment plan change (PPC) will need to be requested  by completing the <a href="./forms/2024/Payment_Plan_Change_Request.pdf" target="_blank">Payment Plan Change Request</a> form found on this website or by mail to Reverse Mortgage Servicing Dept, PO Box 40724, Lansing, MI 48901-7924, requesting that monthly payments in the amount that you need established. PPC documents will be prepared and mailed to you for signature.
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="6">
                            How is the tenure payment amount calculated?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="6">
                        <Card.Body>
                            Under the tenure payment plan option, a borrower
                            receives equal monthly payments for as long as they
                            maintain primary residence in the home or until another
                            maturity event occurs. Even if the loan balance exceeds
                            the principal limit of the loan, a borrower will
                            continue to receive payments. The length of term for
                            tenure payments is calculated by subtracting the age of
                            the youngest borrower from 100 years, although a
                            borrower will continue to receive payments if he/she
                            lives past 100 years of age.
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="7">
                            What is a forced payment plan change?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="7">
                        <Card.Body>
                            If you are not current on property charge obligations
                            such as taxes, insurance, ground rents or homeowner’s
                            associations fees and we are required to pay property
                            charges on your behalf, it may result in a forced
                            payment plan change potentially reducing your monthly
                            payments. If you receive notice that any of your
                            property charges are being reported as delinquent or
                            expired, please contact us immediately to resolve the
                            issue.
                            <br />
                            <strong>
                                <em>
                                    Important: Please be aware that if your payment plan
                                    is changed at any time when you are receiving
                                    monthly payments, the amount of your payment and/or
                                    the term of your payments will be recalculated and
                                    either or both could change.
                                </em>
                            </strong>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default PaymentPlanChanges
