import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const GettingHelp = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.GettingHelp, title)
  })

  return (
    <>
      <h3 ref={title} id="getting-help" tabIndex="-1">
        Getting Help
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Who should I contact for any questions about my reverse
              mortgage?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Our Borrower Care Associates are available from 8:00 am
              to 8:00 pm Eastern Time Zone, Monday through Thursday,
              and 8:00 am to 5:00 pm Eastern Time Zone on Fridays.
              <br />
              <br />
              You can reach us by calling our toll-free number: (866)
              654-0020. <br />
              We can also be reached by fax at (866) 616-2160, or via
              e-mail at:{' '}
              <a href="mailto:BC@reversedepartment.com">
                BC@reversedepartment.com
              </a>
              . <br />
              <br />
              If you would prefer to write to us, please mail your
              inquiry to the applicable address below:
              <br />
              <br />
              Reverse Mortgage Servicing Department
              <br />
              P.O. Box 40724
              <br />
              Lansing, MI 48901-7924
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              Where do I send a written complaint, request for
              information, or a qualified written request?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              Please send any such requests to P.O. Box 40719,
              Lansing, MI 48901.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              How do I request correction to my information?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              Please email a request to {' '}
              <a href="mailto:BC@reversedepartment.com">
                BC@reversedepartment.com
              </a>. Supporting documentation may be required to be submitted to support the requested change. We will review and advise you if any documentation is required.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              What do I need to do to get a trusted family member or
              friend authorization to discuss my reverse mortgage
              account?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              Fill out the{' '}
              <a href="./forms/2024/Third_Party_Authorization.pdf" target="_blank">
                Third Party Authorization Form
              </a>{' '}
              indicating the name(s) of the third parties you wish to
              authorize to discuss your account with us and be sure to
              include your loan number (found on your monthly
              statement) and sign the form. You can send the form to
              us via email at{' '}
              <a href="mailto:BC@reversedepartment.com">
                BC@reversedepartment.com
              </a>
              , via fax to (866) 616-2160, or mail it to us at the
              address on your monthly statement.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              {' '}
              How do I communicate with you in a language other than
              English? Do you have a Language Assistance service?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              {' '}
              Borrower Care team members who speak English or Spanish
              can be reached at 866-654-0020. Additionally, we have
              established a relationship with a third-party
              interpreter service, Language Line Services (LLS). LLS
              is an over-the-phone interpretation service that assists
              customers who request a translation of any communication
              in a language other than English. Call us at
              866-654-0020 and we will connect an LLS agent to
              translate the call. For New York City borrowers, a
              translation and description of commonly-used debt
              collection terms is available in multiple languages on
              the NYC Department of Consumer Affairs website:{' '}
              <a
                href=" https://www.nyc.gov/dca"
                aria-label="NYC Department of Consumer Affairs"
              >
                https://www.nyc.gov/dca
              </a>
              .
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}
export default GettingHelp
