import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link'
import { buildTestID } from '../../Utils/utils'

import CommonQuestionCategories from './CommonQuestionCategories'

const Occupancy = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.Occupancy, title)
  })

  return (
    <>
      <h3 ref={title} id="occupancy" tabIndex="-1">
        Occupancy
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              What is an Occupancy Certification?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              As a condition of your reverse mortgage, you agreed that you would live in your home as your primary place of residence and The Reverse Mortgage Servicing Department is required to verify, on an annual basis, that you are still living in your home and that the home is your primary residence. When your annual certification is due, you will see a banner when you log in to your account asking you to securely certify your occupancy electronically. You may also verify your occupancy by phone by calling 866-654-0020 and following the prompts to certify your occupancy. Alternatively, an Annual Occupancy Certificate will be mailed to you each year, starting one year after you closed on your reverse mortgage, and you will simply need to sign the form and return it to us within 30 days. You may leave the home for medical reasons for up to twelve (12) months without defaulting on your reverse mortgage. If you will be out of the home for more than two (2) consecutive months, please notify us so we can keep our records current for the mailing of your monthly statement and any other documents. You may do so by completing a <a href="./forms/2024/Change_of_Mailing_Address.pdf" target="_blank">
                Change of Mailing Address Change Form
              </a>. When you return to your home, be sure to complete another <a href="./forms/2024/Change_of_Mailing_Address.pdf" target="_blank">
                Change of Mailing Address Change Form
              </a> so that your mailing address will be updated.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              What happens if I don't sign and send in the Occupancy
              Certification?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              If you don't respond to the first notice to return an{' '}
              <a href="./forms/2024/Annual_Occupancy_Certificate.pdf" target="_blank">
                Occupancy Certificate Form
              </a>
              , a second notice will be sent out giving you 30 days to
              respond. If you don't respond to the Second Notice, we
              will try to contact you by phone but please be aware
              that we may also order a property inspection. If a
              borrower’s occupancy can’t be verified within 30 days of
              the property inspection, your loan may be matured and
              called due and payable.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              Can I be temporarily away from my home?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              Yes. However, if you are away for more than 2 months,
              you must notify us using the{' '}
              <a href="./forms/2024/Change_of_Mailing_Address.pdf" target="_blank">
                Change of Mailing Address Change Form
              </a>{' '}
              so that we can notate our system. If you don't notify us
              and we receive returned mail from you, we will assume
              that you are no longer living in the property and the
              loan could be matured and called due and payable.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              What happens to my reverse mortgage if I pass away or
              move from the home permanently?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              Your reverse mortgage will have to be repaid when the last surviving borrower on the loan passes away, moves from the home permanently, or does not occupy the home as their primary residence and certify that he or she does in accordance with the loan terms.
              <br />
              <br />
              Depending on the type of reverse mortgage you obtained,
              you (or your heirs) may be eligible for potential time
              extensions ranging up to one year. These potential time
              extensions are only to be used to provide time to
              satisfy the balance of the reverse mortgage.
              <br />
              <br />
              It is important to note that there may also be certain
              protections available for eligible “non-borrowing
              spouses” in the event the borrower passes away. If the
              borrower passes away, the non-borrowing spouse would
              receive a communication from us with instructions on
              what steps would need to be completed to determine their
              eligibility for these potential protections. There will
              be several pieces of documentation and information that
              will need to be provided quickly by the non-borrowing
              spouse after the borrower has passed away. So, please
              make sure that the non-borrowing spouse has all of our{' '}
              <Link to="/contact">contact information</Link> to reach
              out to us if that event were to occur in the future.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              Can I sign my Annual Occupancy Certification Electronically?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              You may complete your Annual Occupancy Certificate securely by <a href="/" target="_blank" data-testid={buildTestID('can_i_sign_my', '/occupancy', 'log_on')}>logging into your account on the website</a>. When your annual certification is due, you will see a banner on your account when you log in asking you to securely certify your occupancy.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default Occupancy
