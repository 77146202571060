import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import Nav from './Nav'
import GoPaperless from '../FinishYourAccount/GoPaperless'
import useWindowSize from '../../Hooks/useWindowSize'
import Button from '../../Common/button'
import { useFocus } from '../../Hooks/useFocus'
import TimerIcon from '../../assets/timer'
import LockIconTwo from '../../assets/lock-icon-two'
import ChangeEmail from './ChangeEmail'
import ContactIinfo from './ContactInfo'
import MultiFactorAuth from './MultiFactorAuth'
import StatementPreferenceLegal from './StatementPreferenceLegal'
import GoPaperlessSuccess from './GoPaperlessSuccess'
import { useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import EnableMultifactorContainer from '../../Containers/EnableMultifactorContainer'

const StyledFinishYourAccount = styled.div`
  padding-bottom: 72px;
  min-height: 900px;

  h1 {
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px; /* 110% */
    margin: 10px 0;
  }

  .introduction {
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  .d-time-needed {
    flex-direction: column;
  }

  .time-needed {
    color: #FFF;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 87.5% */
    text-align: center;
  }

  .to-complete {
    color: #DCDCDC;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }

  .remind-me {
    height: 19px;

    .all-buttons {
      background: var(--Primary, #074EE8);
      border-radius: 4px;
      height: 19px;
    }
  }

  .information {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 10px;
    border-radius: 17px;
    background: #E2F5FF;
    color: var(--Text-Normal, #111);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;

    >svg {
      width: 70px;
    }
  }

  .scroll {
    position: absolute;
    margin-top: -182px;
  }

  .fixed_cell {
    position: sticky;
    height: 280px;
    top: 112px;
  }

  .static_nav_on_banner_open {
    margin-top: -92px;
  }

  .banner {
    margin-top: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    margin-top: 32px;
    padding: 0;

    .fixed_cell {
      position: unset;
      height: unset;
    }

    .back_button {
      display: flex;
      margin: 20px 0 0 0;
      justify-content: start;

      :focus {
        color: ${({ theme }) => theme.colors.primary};
        background: none;
        outline: 2px solid #1460AA;
      }

      :focus-visible {
        color: ${({ theme }) => theme.colors.primary};
        background: none;
        outline: 2px solid #1460AA;
      }
    }

    .banner {
      margin: 24px 0 0 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .d-time-needed {
      flex-direction: row;
    }

    .time-needed { 
      text-align: left;
    }

    .information {
      padding: 4px 14px 4px 4px;
      white-space: nowrap;
      display: inline-flex;

      >svg {
        width: 30px;
      }
    }
  }
`

// All Mobile screens are represented below (modals on desktop)
// As they are ubiquitous they are stored here to ensure consistency
export const NAV = 'NAV'
// sections
export const MULTIFACTOR_SUCCESS = 'MULTIFACTOR_SUCCESS'
export const GO_PAPERLESS = 'GO_PAPERLESS'
export const CHANGE_EMAIL = 'CHANGE_EMAIL'
export const CONTACT_INFO = 'CONTACT_INFO'
// sub-sections (modals on desktop)
export const STATEMENT_PREFERENCE = 'STATEMENT_PREFERENCE'
export const STATEMENT_PREFERENCE_LEGAL = 'STATEMENT_PREFERENCE_LEGAL'
export const ESIGN_CONSENT_PREFERENCE = 'ESIGN_CONSENT_PREFERENCE'
export const GO_PAPERLESS_SUCCESS = 'GO_PAPERLESS_SUCCESS'
export const ENABLE_MFA = 'ENABLE_MFA'

const initialBannerState = { text: '', type: '' }

const FinishYourAccount = ({
  paperlessIndicator,
  paperlessChangeDate,
  refreshLoanData,
  borrower,
  mfaEnabled,
  setAuthState,
  setSkip
}) => {
  const [currentScreen, setCurrentScreen] = useState(paperlessIndicator ? MULTIFACTOR_SUCCESS : GO_PAPERLESS)
  const [openBanner, setOpenBanner] = useState(initialBannerState)
  const [completed, setCompleted] = useState([])
  const [showButton, setShowButton] = useState(false);

  const history = useHistory()

  useEffect(() => {
    // Set a timer to display the button after 5 seconds
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 15000);

    // Clear the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const statusArray = [...completed]
    if (paperlessIndicator)
      statusArray.push(GO_PAPERLESS)

    if (mfaEnabled)
      statusArray.push(MULTIFACTOR_SUCCESS)

    setCompleted(statusArray)

    if (paperlessIndicator && mfaEnabled)
      history.push('/my-loan')

  }, []);

  let bannerisOpen = Boolean(openBanner.text)

  const { isSmall } = useWindowSize()

  useEffect(() => {
    if (isSmall)
      setCurrentScreen(NAV)
  }, [isSmall])

  useEffect(() => {
    if (bannerisOpen)
      setTimeout(() => setOpenBanner(initialBannerState), 5000)

    if (currentScreen === NAV) setOpenBanner(initialBannerState)
  }, [bannerisOpen, currentScreen])

  const backButtonRef = useRef(null)
  const { focus: focusOnBackButton } = useFocus(backButtonRef, false)

  useEffect(() => {
    if (
      isSmall &&
      (currentScreen === MULTIFACTOR_SUCCESS ||
        currentScreen === GO_PAPERLESS)
    ) {
      focusOnBackButton()
    }
  })

  const handleRemindMe = async (e) => {
    e.preventDefault()
    const user = await Auth.currentAuthenticatedUser()
    if (setSkip)
      setSkip(true)

    if (setAuthState)
      setAuthState('signedIn', { user, redirect: true })

    history.push('/my-loan')
  }

  return (
    <StyleGuide>
      <StyledFinishYourAccount>
        <Grid>
          <p className="introduction">Your online account is active, but we are missing some important information.</p>
          <h1>Let's finish your account setup now!</h1>
          <div className="d-flex justify-content-center d-time-needed" style={{ "gap": "10px", "margin": "10px 0" }}>
            <div className="time-needed"><TimerIcon color="white" /> 3 min <span className="to-complete">to complete</span></div>
            {showButton && <div><Button className="remind-me" UID="finish_your_account" text="Remind Me Next Time" onClick={(e) => handleRemindMe(e)} /></div>}
          </div>
          <p className="information"><LockIconTwo /><span>This will secure your online account, and help us to provide you with the best loan servicing experience.</span></p>
          {isSmall &&
            (currentScreen === MULTIFACTOR_SUCCESS ||
              currentScreen === GO_PAPERLESS ||
              currentScreen === CHANGE_EMAIL ||
              currentScreen === CONTACT_INFO) ? (
            <Button
              forwardRef={backButtonRef}
              className="back_button"
              UID="finsh_your_account_settings"
              text="Back to finish your account settings"
              textButton
              backArrow
              onClick={() => setCurrentScreen(NAV)}
            />
          ) : null}
          {isSmall && currentScreen === NAV && (
            <Nav
              cellclassname={`${openBanner.text ? 'static_nav_on_banner_open' : ''
                }`}
              bannerVisible={bannerisOpen}
              currentScreen={currentScreen}
              setCurrentScreen={setCurrentScreen}
              completed={completed}
              data-gridcolumnlayout={{
                xl: '2/5',
                lg: '1/4',
                md: '1/5',
                sm: '1/9',
                xs: '1/5',
              }}
            />
          )}
          {
            !isSmall && (
              <Nav
                cellclassname={`${openBanner.text ? 'static_nav_on_banner_open' : ''
                  }`}
                bannerVisible={bannerisOpen}
                currentScreen={currentScreen}
                setCurrentScreen={setCurrentScreen}
                completed={completed}
                data-gridcolumnlayout={{
                  xl: '2/5',
                  lg: '1/4',
                  md: '1/5',
                  sm: '1/9',
                  xs: '1/5',
                }}
              />)
          }
          <div className="d-flex"
            data-gridcolumnlayout={{
              xl: '5/14',
              lg: '5/14',
              md: '5/14',
            }}>
            {currentScreen === GO_PAPERLESS &&
              <GoPaperless
                refreshLoanData={refreshLoanData}
                paperlessIndicator={paperlessIndicator}
                paperlessChangeDate={paperlessChangeDate}
                currentScreen={currentScreen}
                setCurrentScreen={setCurrentScreen}
                setOpenBanner={setOpenBanner}
                completed={completed}
                data-gridcolumnlayout={{
                  xl: '5/14',
                  lg: '5/14',
                  md: '5/14',
                }}
              />
            }
            {
              currentScreen === STATEMENT_PREFERENCE_LEGAL &&
              <StatementPreferenceLegal
                refreshLoanData={refreshLoanData}
                setCurrentScreen={setCurrentScreen}
                completed={completed}
                setCompleted={setCompleted}
              />
            }
            {
              currentScreen === GO_PAPERLESS_SUCCESS &&
              <GoPaperlessSuccess
                currentScreen={currentScreen}
                setCurrentScreen={setCurrentScreen}
                data-gridcolumnlayout={{
                  xl: '5/14',
                  lg: '5/14',
                  md: '5/14',
                }}
              />
            }
            {
              currentScreen === ENABLE_MFA &&
              <EnableMultifactorContainer
                setCurrentScreen={setCurrentScreen}
                completed={completed}
                setCompleted={setCompleted}
                data-gridcolumnlayout={{
                  xl: '5/14',
                  lg: '5/14',
                  md: '5/14',
                }} />
            }
            {currentScreen === MULTIFACTOR_SUCCESS &&
              <MultiFactorAuth
                currentScreen={currentScreen}
                setCurrentScreen={setCurrentScreen}
                completed={completed}
                setAuthState={setAuthState}
                setSkip={setSkip}
                data-gridcolumnlayout={{
                  xl: '5/14',
                  lg: '5/14',
                  md: '5/14',
                }}
              />}
            {currentScreen === CHANGE_EMAIL &&
              <ChangeEmail
                signatureId={borrower.signatureId}
                email={borrower.email}
                eSignExpiredDate={borrower.eSignExpiredDate}
                currentScreen={currentScreen}
                setCurrentScreen={setCurrentScreen}
                setOpenBanner={setOpenBanner}
                data-gridcolumnlayout={{
                  xl: '5/14',
                  lg: '5/14',
                  md: '5/14',
                }}
              />}
            {currentScreen === CONTACT_INFO &&
              <ContactIinfo
                borrower={borrower}
                currentScreen={currentScreen}
                setCurrentScreen={setCurrentScreen}
                setOpenBanner={setOpenBanner}
                data-gridcolumnlayout={{
                  xl: '5/14',
                  lg: '4/14',
                  md: '5/14',
                }}
              />}
          </div>
        </Grid>
      </StyledFinishYourAccount>
    </StyleGuide>
  )
}

FinishYourAccount.propTypes = {
  paperlessIndicator: PropTypes.bool.isRequired,
  refreshLoanData: PropTypes.func.isRequired,
  borrower: PropTypes.object.isRequired,
  paperlessChangeDate: PropTypes.string.isRequired,
}

export default FinishYourAccount
