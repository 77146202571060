import React from 'react'
import styled from 'styled-components'

const StyledInfoSection = styled.div`
  margin: 40px 0;

  h1 {
    margin: 0 0 24px 0;
    letter-spacing: 0;
  }

  h1:nth-of-type(2) {
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    margin-bottom: 0;
  }
`

const InfoSection = () => {
  return (
    <StyledInfoSection>
      <h1 className="bold heading-5">Form and Document Upload</h1>
      <p className="subtitle">
        <b>Important:</b> Uploading your form or document to an
        incorrect location may delay the processing of your request.
        Please be sure to upload your forms and documents under the
        right names below.
      </p>
      <p className="subtitle">
        If you have more than one form or document to upload,{' '}
        <b>please upload and submit them individually matching</b>{' '}
        each to the correct name below. If you do not see a form name
        below, please refer to the form itself for submission
        instructions. Forms can be found on our{' '}
        <a className="" href="/common-forms">
          Common Forms
        </a>{' '}
        page.
      </p>
    </StyledInfoSection>
  )
}

export default InfoSection
