import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'

import Button from '../../Common/button'
import { buildTestID, setCookie } from '../../Utils/utils'
import useWindowSize from '../../Hooks/useWindowSize'
import InputMask from 'react-input-mask'
import EyeIcon from '../../assets/eye-icon'
import NoEyeIcon from '../../assets/no-eye-icon'
import dayjs from 'dayjs'
import { SIGNATURE_COMPLETE, VERIFY_MFA } from '.'
import { submitESignAgreement } from '../../services/api'
import { trackESignConsent } from '../../Global/Analytics'

const StyledSignature = styled.div`
h1, h3 {
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  margin-top: 16px;
  line-height: 30px;
  letter-spacing: 0.15px;
}

h5 {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 20px 0 24px;
}

.invalid {
  color: #dc3545
}

input.form-control {
  height: 56px;
}

label {
  font-size: 18px;
line-height: 22px;
letter-spacing: 0.1px;
}

.form-control.is-invalid {
  background-image: none;
}

.row {
  flex-direction: column;
}

.mb-40 {
  margin-bottom: 40px;
}

.cb-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 24px;
}

.cb {
  height: 18px;
  width: 18px;
  margin-right: 12px;

  position: static;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.l-cb {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin: 0px 12px;
}

.address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: #fafafa;
}

.address > h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 10px 0px;
}

.address > p {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 10px 0px;
}

.buttons {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin: 24px 0 32px 0;
  justify-content: space-between;

  .cancel {
    margin-right: 24px;
  }

  .button {
    min-width: 153px;
  }
}

.eye-icon {
  position: absolute;
  top: 45px;
  right: 30px;
}

#password.is-invalid, #ssn.is-invalid {
  background-position: right calc(2.375em + 0.5875rem) center;
}

.warning {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
}

@media (min-width: ${({ theme }) => theme.breakPoints.small}) {
  .signature {
    background-color: #fff;
    padding: 15px;
    margin-top: 32px;
    border-radius: 4px;
    z-index: 150;
    position: relative;
    box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
  }

  h1, h3, h5 {
    text-align: center;
  }

  .row {
    flex-direction: row;
    margin: 0;
  }

  .col {
    padding: 0 10px;
  }

  .address {
    margin-bottom: 24px;
  }

  .button-esignature {
    width: 235px;
  }

  .warning {
    padding: 24px;
  }
}

@media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .row {
      margin: 0 88px;
    }
 }
`

const Signature = ({ borrower, optInTimestamp, consentTimestamp, thirdPartyTimestamp, thirdParty, nbsInformation, setView, setModalShow, setCertificate, setAuthData, setChallengeDestination, setSsnMfa, setDobMfa, mainRef }) => {
  const [dob, setDob] = useState()
  const [ssn, setSsn] = useState()
  const [showSsn, setShowSsn] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [validDob, setValidDob] = useState(false)
  const [showDobValidation, setShowDobValidation] = useState(false)
  const [password, setPassword] = useState('')
  const [validSsn, setValidSsn] = useState(false)
  const [showSsnValidation, setShowSsnValidation] = useState(false)
  const [validPassword, setValidPassword] = useState(false)
  const [passwordInvalid, setPasswordInvalid] = useState(false)
  const [showPasswordValidation, setShowPasswordValidation] = useState(false)
  const [certifyChecked, setCertifyChecked] = useState(false)
  const [showCertifyValidtion, setShowCertifyValidation] = useState(false)
  const [addressTimestamp, setAddressTimestamp] = useState()
  const [loading, setLoading] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [attempts, setAttempts] = useState(1)
  const { isSmall } = useWindowSize()
  const [showDobMask, setShowDobMask] = useState(true)

  useEffect(() => {
    if (invalid)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
  }, [invalid])

  useEffect(() => {
    if (window.location.host === 'dev-borrower.celink.cloud' || window.location.host === 'stg-borrower.celink.cloud')
      setShowDobMask(false)
  }, [showDobMask])

  const handleDobChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "").substring(0, 8)
    setDob(value.replace(/^([\d]{2})([\d]{2})([\d]{4})$/, "$1/$2/$3"))
  }

  const handleSsnChange = (e) => {
    const value = e.target.value.trim().replace(/\D/g, "")
    setSsn(value)
  }

  const validateSsn = (e) => {
    const ssn = e.target.value.trim()
    if (ssn.length !== 4) {
      setValidSsn(false)
      setShowSsnValidation(true)
      return
    }

    setValidSsn(true)
    setShowSsnValidation(false)
  }

  const validateDob = (e) => {
    const dob = e.target.value.trim()
    if (!dob.length || !dayjs(dob).isValid()) {
      setValidDob(false)
      setShowDobValidation(true)
      return
    }

    setValidDob(true)
    setShowDobValidation(false)
  }

  const validatePassword = (e) => {
    const password = e.target.value.trim()
    if (password.length === 0) {
      setValidPassword(false)
      setShowPasswordValidation(true)
      return
    }

    setValidPassword(true)
    setShowPasswordValidation(false)
  }

  const validateCertify = (e) => {
    if (!certifyChecked) {
      setShowCertifyValidation(true)
      return
    }

    setShowCertifyValidation(false)
  }

  const handleCertifyClick = (e) => {
    const checked = e.target.checked;
    setCertifyChecked(!certifyChecked)
    checked ? setShowCertifyValidation(false) : setShowCertifyValidation(true)
    checked ? setAddressTimestamp(Date.now()) : setAddressTimestamp(null)
  }

  const handleClick = async (event) => {
    try {
      const attempt = attempts + 1

      setAttempts(attempt)
      setInvalid(false)
      setLoading(true)

      const user = await Auth.signIn(borrower.email.toLowerCase().trim(), password)
      const { challengeName } = user

      if (challengeName) {
        setAuthData(user)
        setSsnMfa(ssn)
        setDobMfa(dob)
        setChallengeDestination(user.challengeParam.CODE_DELIVERY_DESTINATION)
        setView(VERIFY_MFA)
      } else {
        const eSignResult = await submitESignAgreement(borrower, optInTimestamp, consentTimestamp, thirdPartyTimestamp, addressTimestamp, dayjs(dob).format('YYYY-MM-DD'), ssn, thirdParty, nbsInformation)
        let paths = ['data', 'submitESignature', 'responseData', 'responseCode'];
        const responseCode = paths.reduce((object, path) => {
          return (object || {})[path]; // Oliver Steele's pattern
        }, eSignResult)

        if (responseCode === 200) {
          paths = ['data', 'submitESignature', 'link']
          const certificate = paths.reduce((object, path) => {
            return (object || {})[path]; // Oliver Steele's pattern
          }, eSignResult)

          trackESignConsent.Completed('occupancy certification signed and submitted', 'signature_button_submit')
          setCookie('occ_cert_completed_date', new Date(), 1)
          setCertificate(certificate)
          setView(SIGNATURE_COMPLETE)
        }
        else if (attempts > 5) {
          Auth.signOut()
        }
        else {
          setLoading(false)
          setInvalid(true)
        }
      }
    } catch (err) {
      setLoading(false)
      event.target.blur();
      if (err.code === 'NotAuthorizedException') {
        setPasswordInvalid(true)
        setShowPasswordValidation(true)
      }

      if (err.message === 'Password attempts exceeded' || attempts > 5)
        Auth.signOut()
    }
  }

  return (
    <StyledSignature>
      <div className="signature"
        data-gridcolumnlayout={{
          xl: '3/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Annual Occupancy Certification</h1>
        <h5>To electronically sign the document, please provide the following information:</h5>
        {
          invalid && (
            <h5 className='invalid'>Please verify information is correct and resubmit.</h5>
          )
        }
        <Form>
          <Row>
            <Col>
              <Form.Group className="mb-40" controlId="dateOfBirth">
                <Form.Label>Date of Birth</Form.Label>
                <InputMask type="text"
                  className={`form-control ${showDobValidation ? "is-invalid" : ""}`}
                  name="dateOfBirth"
                  mask={showDobMask ? "99/99/9999" : ""}
                  placeholder={showDobMask ? "MM/DD/YYYY" : ""}
                  value={dob}
                  onChange={handleDobChange}
                  onBlur={validateDob}
                  data-testid={buildTestID('dateofbirth', '/esign')}
                  aria-label="date of birth">
                </InputMask>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid date of birth.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-40" controlId="ssn">
                <Form.Label>Last 4 of Social Security Number</Form.Label>
                <Form.Control type={showSsn ? "text" : "password"}
                  className={`${showSsnValidation ? "is-invalid" : ""}`}
                  name="ssn"
                  placeholder="Enter SSN"
                  minLength={4}
                  maxLength={4}
                  value={ssn}
                  onChange={handleSsnChange}
                  onBlur={validateSsn} />
                <i className="eye-icon"
                  tabindex="0"
                  aria-label={showSsn ? 'Last 4 of SSN shown' : 'Last 4 of SSN hidden'}
                  aria-pressed={showSsn}
                  onKeyUp={(e) => e.key === 'Enter' && setShowSsn(!showSsn)}
                  onClick={() => setShowSsn(!showSsn)}
                >
                  {
                    showSsn ? <EyeIcon></EyeIcon> : <NoEyeIcon></NoEyeIcon>
                  }
                </i>
                <Form.Control.Feedback type="invalid">
                  Please provide the last 4 digits of your social security number.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="cb-container">
                <input
                  className={"cb"}
                  type="checkbox"
                  id={`chk-certify`}
                  name="chk-certify"
                  onClick={handleCertifyClick}
                  onBlur={validateCertify}
                  aria-label="Certify address checkbox"
                  data-testid={buildTestID('chk_certify', '/esign', 'annual_occupancy',)}
                />
                <label htmlFor="chk-certify" className="l-cb">I hereby certify that I occupy the property noted below as my principal residence:</label>
              </div>
              <Form.Control.Feedback type="invalid" style={{ display: showCertifyValidtion ? "block" : "none" }}>
                Please click the checkbox to certify your property address.
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="address">
                <h6>Property Address</h6>
                {isSmall ?
                  <p>{borrower.houseNumberStreeName}<br />
                    {borrower.cityStateZip}
                  </p> :
                  <p>{borrower.houseNumberStreeName} {borrower.cityStateZip}
                  </p>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-40" controlId="password">
                <Form.Label>Please re-enter your password</Form.Label>
                <Form.Control type={showPassword ? "text" : "password"}
                  name="password"
                  className={`${showPasswordValidation ? "is-invalid" : ""}`}
                  placeholder="Enter Password"
                  onBlur={validatePassword}
                  onKeyUp={validatePassword}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i className="eye-icon"
                  tabindex="0"
                  aria-label={showPassword ? 'Password shown' : 'Password hidden'}
                  aria-pressed={showPassword}
                  onKeyUp={(e) => e.key === 'Enter' && setShowPassword(!showPassword)}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {
                    showPassword ? <EyeIcon></EyeIcon> : <NoEyeIcon></NoEyeIcon>
                  }
                </i>
                <Form.Control.Feedback type="invalid">
                  {passwordInvalid ? "Incorrect Password" : "Please re-enter your password."}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <hr />
        <div className="buttons">
          <Button
            className="button cancel"
            text="Cancel"
            outlined
            fullWidth
            UID="signature_button_cancel"
            onClick={() => setModalShow(true)}
          />
          <Button
            className="button button-esignature"
            text={isSmall ? "Submit" : "Submit ESignature"}
            type="submit"
            fullWidth
            UID="signature_button_submit"
            disabled={!(validDob && validSsn && certifyChecked && validPassword && !loading)}
            loading={loading}
            onClick={handleClick}
          />
        </div>
        <div className="warning">
          <p>The following message is applicable to you if your loan is insured by HUD:
            Warning: Section 1001 of Title 18 of the United States Code makes it a criminal offense to make a willfully false statement or misrepresentation to any department or agency of the United States government as to any matter within its jurisdiction.</p>
        </div>
      </div>
    </StyledSignature>
  )
}

export default Signature