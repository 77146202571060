import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import Theme from './Theme'
import LeavingSiteModal from '../Common/LeavingSiteModal'
import { HeaderModern } from '../Components/HeaderModern'
import FooterModern from '../Components/FooterModern'
import { useHistory } from 'react-router-dom'
import PaymentOptions from '../Components/Payment/PaymentOptions'

const StyledLayoutModern = styled.div`
  background: ${({ theme, whiteBackground, modernBackground }) => whiteBackground && !modernBackground ? theme.colors.white : modernBackground ? 'url(./assets/background.png), lightgray 50% / cover no-repeat' : 'url(./assets/background-modern.jpeg), lightgray 50% / cover no-repeat'};

  background-size: ${({ theme, whiteBackground, modernBackground }) => modernBackground ? '1920px 1441px' : '2112px 100%'};
    
  .header-wrapper {
    padding: 7px 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding-top: 0;
  }

  @media (max-width: ${({ theme, modernBackground }) => theme.breakPoints.xSmall}) {
    // background: ${({ theme, modernBackground }) => modernBackground ? 'url(./assets/background.png), lightgray 50% / cover no-repeat' : 'url(./assets/background-modern.jpeg), lightgray 50% / cover no-repeat'};
    margin-top: ${({ theme, modernBackground }) => modernBackground ? '105px' : '0'};
    background-repeat: ${({ theme, modernBackground }) => modernBackground ? 'repeat-y' : 'no-repeat'};
    background-size: ${({ theme, modernBackground }) => modernBackground ? '' : '834px 1441px'};
    background-position: ${({ theme, modernBackground }) => modernBackground ? '' : '-211px left'};
  }

  @media (max-width: ${({ theme, modernBackground }) => theme.breakPoints.medium}) {
    // background: ${({ theme, modernBackground }) => modernBackground ? 'url(./assets/background.png), lightgray 50% / cover no-repeat' : 'url(./assets/background-modern.jpeg), lightgray 50% / cover no-repeat'};
    margin-top: ${({ theme, modernBackground }) => modernBackground ? '105px' : '0'};
    background-repeat: ${({ theme, modernBackground }) => modernBackground ? 'repeat-y' : 'no-repeat'};
    background-size: ${({ theme, modernBackground }) => modernBackground ? '' : '1024px 1441px'};
    background-position: ${({ theme, modernBackground }) => modernBackground ? '' : '-211px left'};
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    width: 98.5vw;
    height: 1400px;
    flex-shrink: 0;

    .header-wrapper {
      padding: 20px;
    }
 }

 @media (min-width: ${({ theme }) => theme.breakPoints.xlarge}) {
  width: 98.5vw;
  height: 100vh;
  background-size: cover;
  flex-shrink: 0;
}
`

const StyledCommonHeader = styled.div`
`

const StyledCommonHeaderLeft = styled.div`
    background: linear-gradient(90deg, #000 59.37%, rgba(0, 0, 0, 0.00) 88.23%);
    border-bottom-left-radius:25%;
`

const StyledCommonQuestionsHeaderCenter = styled.div`
    background: url('/assets/background-common-questions-header.png') no-repeat center center;
    background-size:cover;
    height: 372px;
    border-bottom-right-radius:15%;
    border-bottom-left-radius:15%;
    @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
      border-bottom-right-radius:5%;
      border-bottom-left-radius:5%;
      height:300px;
    }
    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    padding: 20px;
    border-bottom-right-radius: 25%; 
    border-bottom-left-radius: 25%;

    .header-wrapper {
      padding: 20px;
    }
 }
`

const StyledCommonFormsCenter = styled.div`
    background: url('/assets/background-common-forms-header.png') no-repeat center center;
    background-size: cover;
    height: 372px;
    border-bottom-right-radius: 25%; 
    border-bottom-left-radius: 25%;
    overflow: hidden;
    @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
      border-bottom-right-radius:15%;
      border-bottom-left-radius:15%;
    }
    @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
      border-bottom-right-radius:5%;
      border-bottom-left-radius:5%;
      height:300px;
    }
    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    padding: 20px;

    .header-wrapper {
      padding: 20px;
    }
 }
`

const StyledHelpfulResourcesCenter = styled.div`
    background: url('/assets/background-helpful-resources.png') no-repeat center center;
    background-size: 100% 674px;
    height: 372px;
    border-bottom-right-radius: 25%; 
    border-bottom-left-radius: 25%;
    @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
      border-bottom-right-radius: 15%; 
      border-bottom-left-radius: 15%;
      height:300px;
    }
    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    padding: 20px;
    border-bottom-right-radius: 30%; 
    border-bottom-left-radius: 30%;
    .header-wrapper {
      padding: 20px;
    }
 }
`

const StyledPaymentHeader = styled.div`
  @media (min-width: 1280px) {
    .header-wrapper {
      // padding: 20px;
    }
  }
  .background-image{
      background: url('/assets/background-payment-mobile.jpg') no-repeat center center;
      height: 278px;
      background-size: cover;
      border-bottom-right-radius:15%;
      border-bottom-left-radius:15%;
      @media (min-width: 1280px) {
        padding: 20px 0px;
        height: 500px;
        background: url('/assets/background-payment.jpg') no-repeat center center;
        background-size: cover;
        border-bottom-right-radius:25%;
        border-bottom-left-radius:25%;
    }
    @media (max-width: ${({ theme }) => theme.breakPoints.medium}) and (min-width: ${({ theme }) => theme.breakPoints.xSmall}) {
        height: 396px;
        background: url('/assets/background-payment-tablet.jpg') no-repeat center center;
        background-size: cover;
        border-bottom-right-radius:20%;
        border-bottom-left-radius:20%;
    }
}
`

const StyledContactHeader = styled.div`

    @media ((min-width: 820px) and (max-width: 1024px)) {
      padding: 20px;
      background-size: 834px 396px;

      .header-wrapper {
        padding: 20px;
      }
    }

    @media (min-width: 1024px) {
      padding: 10px;
    }
`

const StyledCommonHeaderRight = styled.div`
    background: linear-gradient(270deg, #000 46.66%, rgba(0, 0, 0, 0.00) 100%);
    border-bottom-right-radius:25%;
`

const LayoutModern = ({ children, isAuthenticated }) => {
  const [navVisible, setNavVisible] = useState(false)
  const [linkTo, setLinkTo] = useState('')
  const [showLeaveModal, setShowLeaveModal] = useState(false)

  const { pathname } = useLocation()
  const history = useHistory()

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      isAuthenticated: isAuthenticated,
    })
  })

  const body = document.querySelector('body') // or const root = document.body

  useEffect(() => {
    body.addEventListener('click', (e) => {
      if (e.target.href
        && !e.target.href.includes(window.location.hostname)
        && !e.target.href.includes('tel:')
        && !e.target.href.includes('mailto:')
        && !e.target.href.includes('cel-borrower')) {
        e.preventDefault();
        setLinkTo(e.target.href)
        setShowLeaveModal(true)
      }

      const selector = document.querySelector('h1.heading-6');
      if (e.target && (e.target?.classList?.contains('ScrollUpButton__Container') || e.target?.parentElement?.classList?.contains('ScrollUpButton__Container') || e.target?.parentElement?.parentElement?.classList?.contains('ScrollUpButton__Container'))) {
        e.target.blur()
        selector.focus()
      }

      return;
    })

    body.addEventListener('keyup', (e) => {
      // if key is enter key
      if (e.key === 'Enter') {
        const selector = document.querySelector('h1.heading-6');
        if (e.target && e.target?.classList?.contains('ScrollUpButton__Container')) {
          e.target.blur()
          selector.focus()
        }
      }
    })

  }, [body]);

  return (
    <Theme>
      <StyledLayoutModern
        whiteBackground={
          pathname.includes('my-loan') ||
          pathname.includes('transactions') ||
          pathname.includes('common-questions') ||
          pathname.includes('common-forms') ||
          pathname.includes('helpful-resources') ||
          pathname.includes('contact') ||
          pathname.includes('payment')
        }
        modernBackground={pathname.includes('finish-your-account')}
        isAuthenticated={isAuthenticated}
        onTouchMove={() => navVisible && setNavVisible(false)}
        onClick={() => navVisible && setNavVisible(false)}
      >
        {(pathname === '/') && (
          <div className='header-wrapper'>
            <HeaderModern
              history={history}
              isAuthenticated={isAuthenticated}
              setNavVisible={setNavVisible}
              navVisible={navVisible}
            />
          </div>
        )}
        {pathname === '/common-questions' && (
          <StyledCommonHeader>
            {/* <StyledCommonHeaderLeft>
              <StyledCommonHeaderRight> */}
                <StyledCommonQuestionsHeaderCenter>
                  <HeaderModern
                    history={history}
                    isAuthenticated={isAuthenticated}
                    setNavVisible={setNavVisible}
                    navVisible={navVisible}
                  />
                </StyledCommonQuestionsHeaderCenter>
              {/* </StyledCommonHeaderRight>
            </StyledCommonHeaderLeft> */}
          </StyledCommonHeader>
        )}
        {pathname === '/common-forms' && (
          <StyledCommonHeader>
            {/* <StyledCommonHeaderLeft> */}
              {/* <StyledCommonHeaderRight> */}
                <StyledCommonFormsCenter>
                  <HeaderModern
                    isAuthenticated={isAuthenticated}
                    setNavVisible={setNavVisible}
                    navVisible={navVisible}
                  />
                </StyledCommonFormsCenter>
              {/* </StyledCommonHeaderRight> */}
            {/* </StyledCommonHeaderLeft> */}
          </StyledCommonHeader>
        )}
        {pathname === '/helpful-resources' && (
          <StyledCommonHeader>
            <StyledHelpfulResourcesCenter>
              <HeaderModern
                isAuthenticated={isAuthenticated}
                setNavVisible={setNavVisible}
                navVisible={navVisible}
              />
            </StyledHelpfulResourcesCenter>
          </StyledCommonHeader >
        )}
        {
          pathname === '/contact' && (
            <StyledCommonHeader>
              <StyledContactHeader>
                <HeaderModern
                  isAuthenticated={isAuthenticated}
                  setNavVisible={setNavVisible}
                  navVisible={navVisible}
                />
              </StyledContactHeader>
            </StyledCommonHeader>
          )}
        {
          pathname === '/payment' && (
            <StyledCommonHeader>
              <StyledPaymentHeader>
                <HeaderModern
                  isAuthenticated={isAuthenticated}
                  setNavVisible={setNavVisible}
                  navVisible={navVisible}
                />
                <div className='background-image'></div>
                <PaymentOptions />
              </StyledPaymentHeader>
            </StyledCommonHeader>
          )}
        {childrenWithProps}
        {
          pathname !== '/finish-your-account' &&
          <FooterModern />
        }
        {
          linkTo.length ? (<LeavingSiteModal onHide={() => { setShowLeaveModal(false) }} show={showLeaveModal} linkTo={linkTo} />) : ("")
        }
      </StyledLayoutModern >
    </Theme >
  )
}

LayoutModern.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
}

export default LayoutModern
