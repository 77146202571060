import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import StyleGuide from '../../Global/StyleGuide'
import Button from '../../Common/button'
import {
  trackESignConsent,
  trackFormDownloaded,
  trackUserOptedInForOnlineTool,
} from '../../Global/Analytics'

const StyledOnlineToolsRedirect = styled(Modal)`
  display: flex;
  flex-direction: column;

  .modal-content {
    padding: 32px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
  }

  .modal_width {
    max-width: 450px !important;
  }

  .title {
    margin: 0;
    color: ${({ theme }) => theme.colors.black87};
  }

  .sub_heading {
    color: ${({ theme }) => theme.colors.black87};
    margin: 16px 0 24px 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .download {
      margin-right: 24px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 24px 0 0 0;

    .button {
      width: 100%;
    }

    .buttons {
      .download {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .buttons {
      flex-direction: column;

      .download {
        margin: 0 0 20px 0;
      }
    }
  }
`

const OnlineToolsRedirect = ({
  closeScreen,
  show,
  dowloadFormLink,
  url: onlineToolRedirect,
  formName,
  formGroup,
  toolName,
}) => {
  let history = useHistory()

  return (
    <StyledOnlineToolsRedirect
      onHide={closeScreen}
      show={show}
      centered={true}
      size={'md'}
      dialogClassName={'modal_width'}
    >
      <StyleGuide>
        <h3 className="title heading-6">Complete Request Online</h3>
        <h6 className="subtitle-2 sub_heading">
          {`
          You are eligible to securely complete this request online
          using our ${toolName} tool.
          `}
        </h6>
        <div className="buttons">
          <Button
            className="download button"
            text="Download Form"
            outlined
            externalLink={dowloadFormLink}
            onClick={() => {
              closeScreen()
              trackFormDownloaded(formName, formGroup)
            }}
            fullWidth
            UID="online_tools_redirect"
          />
          <Button
            className="button"
            fullWidth
            text="Complete Online"
            UID="online_tools_redirect"
            onClick={() => {
              history.push(onlineToolRedirect)
              trackUserOptedInForOnlineTool(toolName)
              if (toolName === "eSign")
                trackESignConsent.Started()
            }}
          />
        </div>
      </StyleGuide>
    </StyledOnlineToolsRedirect>
  )
}

OnlineToolsRedirect.propTypes = {
  show: PropTypes.bool.isRequired,
  closeScreen: PropTypes.func.isRequired,
  dowloadFormLink: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  formGroup: PropTypes.string.isRequired,
  toolName: PropTypes.string.isRequired,
}

export default OnlineToolsRedirect
