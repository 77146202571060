import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import CheckIcon from '../../assets/check-icon.js'
import {
  OPT_IN,
  CONSENT,
  SIGNATURE,
  NBS_FACTSHEET,
  HECM,
  NBS_INFORMATION,
  PROOF_OF_MARRIAGE,
  THIRD_PARTY
} from './index.js'

const StyledStepIndicatorNbs = styled.div`
  padding: 48px 0 32px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .status_item {
    display: flex;
    flex-direction: column;

    div {
      text-align: center;
    }

    .circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 16px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.4px;
      margin: 0 auto;
      margin-bottom: 30px;
      background: ${({ theme }) => theme.colors.black12};
      border: 2px solid rgba(0, 0, 0, 0.01);
    }

    .status_text {
      margin: 0 16px;
    }

    .in_progress {
      background: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.primaryDark};
    }

    .completed {
      background: ${({ theme }) => theme.colors.primaryDark};
      border: 1px solid ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .active {
    color: ${({ theme }) => theme.colors.primaryDark};
    font-weight: bold;
  }

  .line {
    width: 100px;
    height: 0px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    margin: 20px -24px 0 -24px;
  }

  .check_icon {
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 100%;
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .line {
      width: 30px;
      margin: 20px -12px 0 -12px;
    }

    .status_text {
      display: none;
    }
  }
`

const StepIndicatorNbs = ({ currentScreen }) => {
  return (
    <StyledStepIndicatorNbs data-testid="stepper">
      {currentScreen === OPT_IN ? (
        <>
          <span className="visually-hidden">ESign, step 1 of 5</span>
          <div className="status_item complete-form active">
            <div className="circle in_progress">1</div>
            <div className="status_text">Opt-in for eSign</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle ">2</div>
            <div className="status_text">Electronic Consent</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">3</div>
            <div className="status_text">A3P Information</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">NBS Information</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">5</div>
            <div className="status_text">Submit Form</div>
          </div>{' '}
        </>
      ) : (
        ''
      )}

      {currentScreen === CONSENT ? (
        <>
          <span className="visually-hidden">ESign, step 2 of 5</span>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Opt-in for eSign</div>
          </div>
          <div className="line" />
          <div className="status_item active">
            {' '}
            <div className="circle in_progress">2</div>
            <div className="status_text">Electronic Consent</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">3</div>
            <div className="status_text">A3P Information</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">NBS Information</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">5</div>
            <div className="status_text">Submit Form</div>
          </div>{' '}
        </>
      ) : (
        ''
      )}

      {currentScreen === THIRD_PARTY ? (
        <>
          <span className="visually-hidden">ESign, step 3 of 5</span>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Opt-in for eSign</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Electronic Consent</div>
          </div>
          <div className="line" />
          <div className="status_item active">
            {' '}
            <div className="circle in_progress">3</div>
            <div className="status_text">A3P Information</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">NBS Information</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">5</div>
            <div className="status_text">Submit Form</div>
          </div>{' '}
        </>
      ) : (
        ''
      )}

      {currentScreen === NBS_FACTSHEET || currentScreen === HECM || currentScreen === NBS_INFORMATION || currentScreen === PROOF_OF_MARRIAGE ? (
        <>
          <span className="visually-hidden">ESign, step 4 of 5</span>
          <div className="status_item complete-form ">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Opt-in for eSign</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Electronic Consent</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">A3P Information</div>
          </div>
          <div className="line" />
          <div className="status_item active">
            {' '}
            <div className="circle in_progress">4</div>
            <div className="status_text">NBS Information</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle">5</div>
            <div className="status_text">Submit Form</div>
          </div>
        </>
      ) : (
        ''
      )}

      {currentScreen === SIGNATURE ? (
        <>
          <span className="visually-hidden">ESign, step 5 of 5</span>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Opt-in for eSign</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Electronic Consent</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">A3P Information</div>
          </div>
          <div className="line" />
          <div className="status_item">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">NBS Information</div>
          </div>
          <div className="line" />
          <div className="status_item active">
            {' '}
            <div className="circle in_progress">5</div>
            <div className="status_text">Submit Form</div>
          </div>
        </>
      ) : (
        ''
      )}
    </StyledStepIndicatorNbs>
  )
}

StepIndicatorNbs.propTypes = {
  currentScreen: PropTypes.oneOf([
    'OPT_IN',
    'CONSENT',
    'SIGNATURE',
    'COMPLETE',
    'NBS_FACTSHEET',
    'HECM',
    'NBS_INFORMATION',
    'PROOF_OF_MARRIAGE',
    'THIRD_PARTY'
  ]).isRequired,
}

export default StepIndicatorNbs
