import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const ViewIcon = ({ color = 'white' }) => {
  return (
    <StyledSVG color={color} fill="none" viewBox="0 0 140 140">
      <path
        fill="#000"
        d="M55.31 76.135a20.85 20.85 0 004.184 12.607l.585.79L43.25 106.4a2.056 2.056 0 00-.608 1.443 2.014 2.014 0 00.608 1.444c.38.386.898.604 1.44.609a2.048 2.048 0 001.44-.609l16.872-16.87.788.586A20.73 20.73 0 0076.367 97.2a21.008 21.008 0 0014.851-6.176 21.114 21.114 0 006.161-14.888 21.114 21.114 0 00-6.16-14.889 20.989 20.989 0 00-22.903-4.58 21.032 21.032 0 00-6.83 4.564 21.092 21.092 0 00-4.567 6.836 21.13 21.13 0 00-1.61 8.068zm33.925-10.961l.675.789-13.498 13.532L71.53 74.6a2.045 2.045 0 00-1.44-.61 1.997 1.997 0 00-1.44.61c-.385.381-.603.9-.607 1.443.004.543.222 1.062.607 1.444l6.322 6.337a2.002 2.002 0 002.88 0L92.16 69.504l.517 1.827c.447 1.53.675 3.118.675 4.713a17.026 17.026 0 01-4.968 12.002 16.941 16.941 0 01-11.972 4.98 16.942 16.942 0 01-11.972-4.98 17.027 17.027 0 01-4.969-12.002 17.027 17.027 0 014.969-12.002 16.942 16.942 0 0111.972-4.98c2.452.022 4.87.58 7.085 1.635a16.855 16.855 0 015.738 4.477z"
      ></path>
      <path
        fill="#000"
        d="M90.427 17.609A2.09 2.09 0 0088.965 17h-56.94a2.021 2.021 0 00-1.877 1.269c-.1.249-.15.515-.148.783V120.97a2.03 2.03 0 002.025 2.03h75.973a2.017 2.017 0 001.446-.586 2.037 2.037 0 00.601-1.444V38.087a2.007 2.007 0 00-.607-1.443l-19.01-19.035zm.585 6.382l12.014 12.044H91.012V23.992zm14.916 94.927H34.072V21.082H86.94v17.005c0 .539.214 1.055.594 1.435.38.381.894.595 1.431.595h16.963v78.801z"
      ></path>
      <path
        fill="#000"
        d="M44.69 33.78h18.966c.543 0 1.064-.217 1.447-.601a2.055 2.055 0 00.444-2.234 2.031 2.031 0 00-1.11-1.1c-.248-.1-.513-.15-.781-.148H44.69a2.024 2.024 0 00-2.048 2.03c0 .544.216 1.067.6 1.451.384.385.905.602 1.448.602zm0 12.607h31.632a2.02 2.02 0 001.89-1.248 2.055 2.055 0 00-.443-2.233 2.044 2.044 0 00-1.447-.601H44.69c-.543 0-1.064.216-1.448.6a2.055 2.055 0 00-.443 2.234 2.03 2.03 0 001.89 1.248z"
      ></path>
    </StyledSVG>
  )
}

export default ViewIcon
