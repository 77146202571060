import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const TempLogoChip = ({ className }) => {
  return (
    <StyledSVG
      className={className}
      fill="none"
      role="presentation"
      width="32"
      height="24"
      viewBox="0 0 32 24"
    >
      <rect width="32" height="24" rx="2" fill="#133459" />
      <path
        d="M16 8.85694V6L11 8.887V18H13.4713V15.6208L16 14.1618V11.3059L13.4713 12.765V10.316L16 8.85694Z"
        fill="white"
      />
      <path
        d="M16 8.85694V6L21 8.887V18H18.5292V15.6219L16 14.1618V11.3059L18.5292 12.765V10.3171L16 8.85694Z"
        fill="#03A7DA"
      />
    </StyledSVG>
  )
}

export default TempLogoChip
