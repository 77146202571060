import * as React from "react";
import styled from "styled-components";
import LockIconThree from "../assets/lock-icon-three";
import housingLogo from '../assets/equal-housing-png-logo-5004.png'
import HousingLogoIcon from "../assets/housing-logo-icon";
import { buildTestID } from "../Utils/utils";

const StyledFooterModern = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 212px;
  align-items: center;
  font-size: 14px;
  color: var(--Dark-Grey, #475468);
  font-weight: 400;
  padding: 40px 20px;
  margin: 0;
  background-color: #f8fbff;

  a {
    color: #000;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    padding: 50px 60px 30px;
    margin-top: 40px;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  width: 800px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    width: auto;
  }
`;

const MenuContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 20px;

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    width: 100%;
    flex-direction: row;
  }
`;

const MenuItem = styled.a`
  font-family: Lato, sans-serif;
  white-space: nowrap;
  color: #000;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const InfoContainer = styled.div`
  display: block;
  margin-top: 50px;
  gap: 6px;
  line-height: 171%;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
`;

const InfoImage = styled.span`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  margin-right: 10px;
`;

const InfoText = styled.div`
  font-family: Lato, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const FooterModern = () => {
  const buildTestIDFooter = (text) => {
    return buildTestID(text, '/home', 'footer')
  }

  return (
    <StyledFooterModern>
      <ContentContainer>
        <MenuContainer>
          <MenuItem href="/privacy-policy" data-testid={buildTestIDFooter('Privacy Policy')}>Privacy Policy</MenuItem>
          <MenuItem href="/terms-conditions" data-testid={buildTestIDFooter('Terms & Conditions')}>Terms & Conditions</MenuItem>
          <MenuItem href="/schedule-of-borrower-fees" data-testid={buildTestIDFooter('Schedule of Borrower Fees',)}>Schedule of Borrower Fees</MenuItem>
          <MenuItem href="/licenses" data-testid={buildTestIDFooter('Licenses')}>Licenses</MenuItem>
          <MenuItem href="/important-disclosures" data-testid={buildTestIDFooter('Important Disclosures')}>Important Disclosures</MenuItem>
          <MenuItem href="/accessibility-statement" data-testid={buildTestIDFooter('Accessibility Statement',)}>Accessibility Statement</MenuItem>
        </MenuContainer>
        <InfoContainer>
          <InfoItem>
            <InfoImage>
              <LockIconThree />
            </InfoImage>
            <InfoText>
              Your data and information is being protected by encryption
              <a data-testid={buildTestIDFooter('NMLS #3020')} href="https://nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/3020" aria-label="NMLS #3020" > NMLS #3020 </a>
            </InfoText>
          </InfoItem>
          <InfoItem>
            <InfoImage>
              <HousingLogoIcon />
            </InfoImage>
            <InfoText>
              © 2020-2024 Compu-Link Corporation D/B/A Celink (WA license#
              CL3020 and 603 018 607)
            </InfoText>
          </InfoItem>
        </InfoContainer>
      </ContentContainer>
    </StyledFooterModern>
  );
}

export default FooterModern