import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme }) => theme.colors.black60};
  }
`

const NoRequestsIcon = () => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
    >
      <path
        stroke="#fff"
        strokeWidth="0.5"
        d="M22.5 20.25h.25v-14 0a3.511 3.511 0 011.028-2.472A3.511 3.511 0 0126.25 2.75h47.5a3.511 3.511 0 013.5 3.5v67.5a3.511 3.511 0 01-1.028 2.472 3.511 3.511 0 01-2.473 1.028H26.251a3.511 3.511 0 01-2.473-1.028 3.511 3.511 0 01-1.028-2.472v-1.5h-19a1 1 0 01-.707-.293l-.177.177.177-.177a1 1 0 01-.293-.707v-50a1 1 0 011-1H22.5zm-15 50h.25v-48h-3v48H7.5zM9.75 70v.25h28v-48h-28V70zM25 72.25h-.25v1.5c0 .398.158.78.44 1.06a1.5 1.5 0 001.06.44h47.5a1.5 1.5 0 001.06-.44 1.5 1.5 0 00.44-1.06V6.25a1.5 1.5 0 00-1.5-1.5h-47.5a1.5 1.5 0 00-1.06.44 1.5 1.5 0 00-.44 1.06v14h14a1 1 0 011 1v50a1 1 0 01-.293.707 1 1 0 01-.707.293H25z"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="0.5"
        d="M67.5 15.25h.25v-3h-35.5v3H67.5zm2.25-4v5a1 1 0 01-.293.707 1 1 0 01-.707.293h-37.5a1 1 0 01-.707-.293 1 1 0 01-.293-.707v-5a1 1 0 01.293-.707 1 1 0 01.707-.293h37.5a1 1 0 011 1zm-22 11.5h4.5v2h-4.5v-2zm7.5 0h14.5v2h-14.5v-2zm-7.5 7.5h4.5v2h-4.5v-2zm7.5 0h14.5v2h-14.5v-2zm-7.5 7.5h4.5v2h-4.5v-2zm7.5 0h14.5v2h-14.5v-2zm-7.5 7.5h4.5v2h-4.5v-2zm7.5 0h14.5v2h-14.5v-2zm-34.833-3.989a6 6 0 013.333-1.011 6.007 6.007 0 016 6 6 6 0 01-1.011 3.333 6 6 0 01-2.693 2.21 6 6 0 01-6.539-1.3 6 6 0 01-1.642-3.072 6 6 0 01.342-3.467 6 6 0 012.21-2.693zm1.11 8.315a4 4 0 002.223.674 4 4 0 002.828-1.172 4 4 0 001.172-2.828 4 4 0 00-.674-2.222 4 4 0 00-1.795-1.473 3.999 3.999 0 00-2.311-.228 4 4 0 00-2.048 1.095 4 4 0 00-1.095 2.048 3.999 3.999 0 00.227 2.31 4 4 0 001.474 1.796z"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="0.5"
        d="M33.75 30.25h0a1 1 0 011 1v30a1 1 0 01-.293.707 1 1 0 01-.707.293h0a4.011 4.011 0 00-4 4v0a1 1 0 01-.293.707 1 1 0 01-.707.293h-10a1 1 0 01-.707-.293 1 1 0 01-.293-.707v0a4.011 4.011 0 00-4-4h0a1 1 0 01-.707-.293 1 1 0 01-.293-.707v-30a1 1 0 01.293-.707 1 1 0 01.707-.293h0a4.011 4.011 0 004-4v0a1 1 0 01.293-.707 1 1 0 01.707-.293h10a1 1 0 011 1v0a4.011 4.011 0 004 4zm-1.2 30.12l.2-.041V32.17l-.2-.04a6.012 6.012 0 01-3.042-1.64 6.012 6.012 0 01-1.638-3.041l-.041-.2H19.67l-.04.2a6.012 6.012 0 01-1.64 3.042 6.012 6.012 0 01-3.041 1.638l-.2.041V60.33l.2.04a6.012 6.012 0 013.042 1.64 6.012 6.012 0 011.638 3.041l.041.2h8.158l.04-.2a6.012 6.012 0 011.64-3.042 6.012 6.012 0 013.041-1.638z"
      ></path>
      <path d="M69 51.7c-.9-.9-2.3-.9-3.2 0L54.7 62.8l-2.9-2.9c-.4-.4-1-.6-1.6-.6-.6-.1-1.2.2-1.6.6-.8.9-.8 2.2 0 3.1l4.5 4.5c.4.4 1 .7 1.6.7.6 0 1.2-.2 1.6-.7L69 54.9c.9-.9.9-2.3 0-3.2zm-1.1 2.1L55.2 66.5c-.3.3-.8.3-1.1 0L49.7 62c-.3-.3-.3-.7 0-1 .3-.3.8-.3 1.1 0l3.9 3.9 12.2-12.2c.3-.3.8-.3 1.1 0 .2.3.2.8-.1 1.1z"></path>
    </StyledSVG>
  )
}

export default NoRequestsIcon
