import React, { useEffect, useRef } from 'react'
import Styled from 'styled-components'

import Title from '../Common/Title'

const StyledClaims = Styled.div`
  margin: 150px 100px 100px 100px;
  font-size: 20px;

  h1:focus, h1:focus-visible {
    outline: none;
    display: inline-block;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    margin: 150px 20px 100px 20px;
  }
`

const Claims = () => {
  const mainRef = useRef(null);

  useEffect(() => {
    if (mainRef)
      mainRef.current.focus();
  }, [mainRef]);

  return (
    <>
      <Title>Claims</Title>
      <StyledClaims>
        <h1 tabIndex={-1} ref={mainRef}>Claims</h1>
        <p>
          If you experience an insurance loss to your property, your
          insurance company may issue a check for the loss that is
          payable to both you and us as your loan servicer. Please watch our video on this process <a href="/helpful-resources" aria-label="Click here to watch video if you experience an insurance loss to your property">here</a>.
        </p>
        <ul>
          <li>If you have property damage, contact your insurance carrier as soon as possible and take photos of the damage.</li>
          <li>
          If you have a claim, please contact us at 800-266-6337. The Loss Draft support hours are 8:00 am – 6:00 pm EST Monday – Friday. You can email claim documents to <a href="mailto:ReverseLD@pfic.com">ReverseLD@pfic.com</a> or fax to 248-710-1690. You can also access <a href="https://www.myinsuranceportal.com/C3142530" aria-label='click here to visit My Insurance Portal' target='_blank'><u>My Insurance Portal</u></a>  once you have filed a claim with your insurance company and the insurance company has provided you with a final determination regarding your claim.          </li>
          <li>If you have been affected by a declared Natural Disaster, <b>contact FEMA to register for assistance</b>. Visit <a href="http://www.disasterassistance.gov/"><b>www.DisasterAssistance.gov</b></a> or call the registration phone number at <a href='tel:18006213362'><b>(800) 621-FEMA (3362)</b></a>. Coverage may vary in your insurance policies and there may be resources available to you that are not covered under your insurance.</li>
          <li>Be cautious. Home repair scams increase when a natural disaster hits. Get multiple bids, in writing, from established contractors. Check the Better Business Bureau website for complaints. Don't pay contractors in cash. Help spot scammers after a Hurricane or other Natural Disaster: Click <a href="https://consumer.ftc.gov/consumer-alerts/2023/08/help-spot-scammers-after-hurricane-idalia?utm_source=govdelivery" aria-label="Click here to help spot scammers after a Hurricane or other Natural Disaster">Here</a>.</li>
          <li>Consult these additional resources:
            <ul>
              <li>Review <a href="/helpful-resources" aria-label="Click here to review helpful resources">Helpful Resources</a> on this website.</li>
              <li>Read the CFPB consumer guide at <a href="https://files.consumerfinance.gov/f/documents/cfpb_OA_natural_disaster_bifold.pdf" aria-label="Click here to Read the CFPB consumer guide">Natural Disaster</a></li>
            </ul>
          </li>
          <li>
            The following documentation is required in order for your claim to be processed:
            <ul>
              <li>The adjuster’s worksheet provided by your insurance adjuster.</li>
              <li>Any checks made out to both you and your servicer (please endorse the check when sending).</li>
              <li>Additional documentation, such as lien releases or inspection reports, as needed.</li>
            </ul>
          </li>
       </ul>
        <p>
          Please know that guidelines may require us to monitor the
          repairs or restoration through completion. We will work with
          you to monitor the progress of the repairs and make
          disbursements to you and your contractor(s) as required. All
          repairs may be required to be inspected to determine
          completion prior to funds being disbursed in their entirety.
          Please contact us for further clarification or to discuss
          your specific loss. We are here to assist you at this
          difficult time.
        </p>
      </StyledClaims>
    </>
  )
}

export default Claims
