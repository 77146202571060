import React from 'react'
import styled from 'styled-components'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import Grid from '../Global/Grid'
import Theme from '../Global/Theme'
import StyleGuide from '../Global/StyleGuide'
import SiteDownIcon from '../assets/site-down-icon'
import OutlinedAlertIcon from '../assets/outlined-alert-icon'

const StyleSiteUnderMaintenancePage = styled.div`
  text-align: center;

  .heading {
    margin: 40px 0 30px 0;
    font-weight: 500;
  }

  .sub_heading {
    margin: 0;
  }

  svg {
    margin: 50px 0 100px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .heading {
      margin-top: 60px;
      font-size: 24px;
    }

    .sub_heading {
      font-size: 18px;
    }

    svg {
      min-height: 160px;
      min-width: 190px;
      height: 160px;
      width: 190px;
    }
  }
`

export const SiteUnderMaintenancePage = withLDConsumer()(
  ({ children, flags }) => {
    return flags.siteUnderMaintenance ? (
      <Theme>
        <StyleGuide>
          <StyleSiteUnderMaintenancePage>
            <Grid
              gridcolumnlayout={{ sm: '1/9', lg: '1/13', xl: '2/14' }}
            >
              <h5 className="heading">
                Our website is currently down for routine scheduled
                maintenance.
              </h5>
              <h4 className="body-1 sub_heading">
                We apologize for any inconvenience. We’ll be back
                online soon!
              </h4>
              <SiteDownIcon />
            </Grid>
          </StyleSiteUnderMaintenancePage>
        </StyleGuide>
      </Theme>
    ) : (
      <>{children}</>
    )
  },
)