import React from 'react'
import styled from 'styled-components'

import Button from '../../Common/button'

const StyledThirdPartyComplete = styled.div`
  h1, h3 {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .buttons {
    display: flex;
    align-self: flex-start;
    align-items: start;
    margin-top: 24px;
    justify-content: space-between;
    flex-direction: column;
  
    .button {
      margin-bottom: 16px;
    }
  
    .all-buttons {
      height: 40px;
    }
  }

 
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .thirdpartycomplete {
      background-color: #fff;
      padding: 15px 25px;
      margin-top: 32px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      text-align: center;
    }

    .buttons {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 32px;

      .button {
        margin: 0 12px;
      }
    }
  }
`

const ThirdPartyComplete = ({ setView, mainRef }) => {
  return (
    <StyledThirdPartyComplete>
      <div className="thirdpartycomplete"
        data-gridcolumnlayout={{
          xl: '3/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Authorized Third Party Successfully Added</h1>
        <h5>Thank you! Your account will be updated to include the authorized third party submission.</h5>
        <div className="buttons">
          <Button
            className="button"
            text="Download a Copy"
            type="button"
            UID="download_esignature"
          />
          <Button
            className="button"
            text="Print a copy"
            outlined
            UID="print_esignature"
          />
        </div>
      </div>
    </StyledThirdPartyComplete>
  )
}

export default ThirdPartyComplete