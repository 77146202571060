import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../../Common/button';
import { StyledSignUp } from './SignUp';
const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
   }
  .modal-content {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin:auto;
  }
    .body-text {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.black87};
  }
  .Input__input___3e_bf {
  display: block;
  width: 98%;
  padding: 16px;
  font-size: 14px;
  color: #152939;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .sign-up-label{
      font-size:18px;
    }
  }
}
  .Input__input___3e_bf:disabled {
  color: #545454;
  background-color: #e1e4ea;
}
  @media only screen and (max-width: 599px) {
  .Input__input___3e_bf {
    font-size: 16px;
  }
}
`;

const LabelWithBoldText = () => (
  <span style={{fontWeight:'500'}}>
    To protect the security of your account, please enter the <b>Current Total Loan Balance amount</b> found in the <b>Balance Summary</b> section of your most recent monthly statement*
  </span>
);


const LockoutDialog = ({ show, onHide,getField,isUpbVerified,error,errorRef,handleUpbVerify,randomLetters,upbMismatch,validationLoading }) => {
  return (
    <StyledModal show={show} onHide={onHide} centered backkdrop="static" keyboard={false}>
    <StyledSignUp>
      <Modal.Header>
        <Modal.Title>One more step... </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {getField && getField(
          <LabelWithBoldText />,
              true,
              'upb_amount',
              isUpbVerified,
              'Please enter the full amount in dollars and cents (example: 12345.67)'
            )}

        <div role="alert" aria-live="polite">
                  <div
                    tabIndex="-1"
                    ref={errorRef}
                    className="error-message"
                    data-testid="verification-error-message"
                  >
                    {upbMismatch?<h2>Lockout</h2>:error}
                    <span
                      style={{ visibility: 'hidden' }}
                      dangerouslySetInnerHTML={{
                        __html: randomLetters,
                      }}
                    ></span>
                  </div>
              </div>
      </Modal.Body>
      <Modal.Footer>
          <div className="error_button_group">
                <Button
                  onClick={(e) => {
                    handleUpbVerify(e)
                  }}
                  text="Continue"
                  UID="sign_up"
                  loading={validationLoading}
                  disabled={upbMismatch}
                />
            </div>
        <Button text='Close' onClick={onHide}  />
      </Modal.Footer>
      </StyledSignUp>
    </StyledModal>
  );
};

export default LockoutDialog;
