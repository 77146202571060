import { useRef, useEffect } from 'react'

export const usePrevious = (previous) => {
  const prev = useRef()

  useEffect(() => {
    prev.current = previous
  })

  return { previous: prev.current }
}
