import React from 'react'
import { Container } from 'react-bootstrap'
import H1 from '../Common/H1'
import Title from '../Common/Title'
import ScrollUpButton from '../Common/ScrollUpButton'

const ScheduleOfFees = () => {
  return (
    <>
      <Title>Schedule of Borrower Fees</Title>
      <main id="main" tabIndex="0">
        <Container
          style={{
            marginTop: '80px',
            marginBottom: '80px',
            fontSize: '20px',
          }}
        >
          <H1 style={{ marginBottom: '40px' }}>
            Schedule of Borrower Fees
          </H1>

          <h2 style={{ "font-size": "20px", "margin-bottom": "1rem", "line-height": "1.5" }}>
            <strong>
              Important: All fees noted below are subject to federal and state guidelines, restrictions, and/or limitations.
            </strong>
          </h2>

          <p>
            The Reverse Mortgage Servicing Department’s servicing platform is designed to ensure that the servicing fees, as well as all other fees noted below, that are charged to borrowers’ reverse mortgage loan balance, meet investor and insurer guidelines, federal and state regulations, the terms of the loan documents, and other applicable requirements. The fees that the Reverse Mortgage Servicing Department collects depend on the type of the mortgage program selected by the borrower, investor and insurer guidelines, and other applicable requirements.
          </p>

          <p>
            <strong>Home Equity Conversion Mortgages (HECM)</strong>
          </p>

          <p>
            For FHA-insured Home Equity Conversion Mortgage (HECM) loans, the following fees may be charged to borrower’s unpaid principal balance during loan servicing:
          </p>

          <ul>
            <li>
              <strong>Servicing Fee</strong>: A monthly servicing fee of no more than $30.00 may be charged if the HECM loan has an annually adjusting interest rate or $35.00 if the interest rate adjusts monthly. A monthly servicing fee of no more than $30.00 may be charged in connection with fixed rate HECM loans.
            </li>

            <li>
              <strong>Repair Administration Fee</strong>: A Repair Administration Fee not exceeding the greater of one and one-half percent (1.5%) of the amount advanced for the repairs or $50.00 may be charged if repairs to the property are required after loan closing in order to meet HUD requirements, in accordance with the Repair Rider.
            </li>

            <li>
              <strong>Payment Plan Change</strong>: A fee of up to $20.00 may be charged if the borrower switches from one HECM payment plan to another (the borrower’s principal loan balance must be less than the current principal limit).
            </li>

            <li>
              <strong>
                On-Going Mortgage Insurance Premiums (MIP)
              </strong>
              : For reverse mortgages that are insured by HUD, the borrower will be charged a monthly MIP as a percentage of the loan balance. The percentage charged is based on when the FHA Case number for the loan was issued and is referenced on the following chart:
              <table className='table table-bordered text-center my-3' style={{ borderWidth: "3px", borderColor: "#000" }}>
                <tr>
                  <td colSpan={3} style={{ borderWidth: "3px", borderColor: "#000" }}>If your FHA Case Number was issued:</td>
                </tr>
                <tr>
                  <td style={{ borderWidth: "3px", borderColor: "#000" }}><b>On Or Before October 3, 2010</b></td>
                  <td style={{ borderWidth: "3px", borderColor: "#000" }}><b>October 4, 2010-October 1, 2017</b></td>
                  <td style={{ borderWidth: "3px", borderColor: "#000" }}><b>On or after October 2, 2017</b></td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ borderWidth: "3px", borderColor: "#000" }}>Your Mortgage Insurance Premium (MIP) Rate is:</td>
                </tr>
                <tr>
                  <td style={{ borderWidth: "3px", borderColor: "#000" }}><b>0.50%</b></td>
                  <td style={{ borderWidth: "3px", borderColor: "#000" }}><b>1.25%</b></td>
                  <td style={{ borderWidth: "3px", borderColor: "#000" }}><b>0.50%</b></td>
                </tr>
              </table>
            </li>

            <li>
              <strong>Property Charges</strong>: Under the terms of the loan documents, HECM borrowers are required to pay charges for real estate taxes, ground rents, flood and hazard insurance premiums, and special assessments in a timely manner. Under certain circumstances, if the borrower fails to pay the property charges in a timely manner and has not elected to have the lender make these payments, the Reverse Mortgage Servicing Department may make payments for such property charges and add them to the unpaid principal loan balance.
            </li>
          </ul>

          <p>
            <strong>Other Fees and Charges</strong>. Under certain circumstances, the Reverse Mortgage Servicing Department may collect certain reasonable and customary fees and charges for services rendered after endorsement by adding them to the loan balance, including the following:
          </p>
          <ul>
            <li>
              Charges for substitution of a hazard or flood insurance policy (at a time other than the normal time for renewing the hazard insurance policy).
            </li>
            <li>
              Attorney and trustee fees and expenses actually incurred when a case has been referred for foreclosure or deed-in-lieu of foreclosure (including the cost of appraisals and cost of advertising).
            </li>
            <li>
              A trustee’s fee in deed-of-trust states for execution of a satisfaction, release, or trustee’s deed when the deed of trust is paid-in-full, if the security instrument provides for such fee.
            </li>
            <li>
              Attorney’s fees and expenses actually incurred in the defense of any suit or legal proceeding when the mortgagee is made a party by reason of the mortgage, if the security instrument permits such fee.
            </li>
            <li>
              Certain property inspection and preservation expenses.
            </li>
            <li>
              Required property appraisals at the time that a loan is called due and payable, or in preparation of a foreclosure sale.
            </li>
            <li>
              A fee for recordation of a satisfaction of mortgage, unless recordation is the responsibility of the mortgagee.
            </li>
            <li>
              Certain fees and charges related to the execution of additional documents to extend the first lien status and related title search costs.
            </li>
          </ul>
          <p>
            <strong>Proprietary Reverse Mortgages</strong>
          </p>
          <p>
            The fees the Reverse Mortgage Servicing Department may collect from borrowers during the servicing of proprietary reverse mortgage loans are based on the loan program and the lender’s specifications and may include, without limitation, monthly servicing fees, repair administration charges, property inspection and preservation charges, and costs and expenses incurred in pursuing foreclosure (including attorney’s and trustee’s fees and expenses).
          </p>
        </Container>
        <ScrollUpButton />
      </main>
    </>
  )
}

export default ScheduleOfFees
