import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'

const StyledSelectionSection = styled.div`
  color: ${({ theme }) => theme.colors.black87};

  .heading-6 {
    margin: 0 0 16px 0;
  }

  .document_title {
    margin: 40px 0 20px 0;
  }

  .radio_card {
    padding: 20px;
    margin: 0 0 16px 0;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.black12};
    border-radius: 4px;

    input {
      min-width: 20px;
      min-height: 20px;
    }

    label {
      margin: 0 0 0 16px;
    }
  }

  .selected {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  .button {
    width: 162px;
    margin-top: 40px;
  }

  button:focus {
    outline: 2px solid #1460AA;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    margin-top: 40px;
  }
`

const SelectionSection = ({
  Forms,
  Documents,
  setSectionSelected,
  setSelected,
  selected,
  fileUploaded,
  successfulUpload,
  handleUpload,
  loading,
  radioNameToID,
}) => {
  return (
    <StyledSelectionSection>
      <h2 className="heading-6">Please select the type of form:</h2>
      {Forms.map((group) => {
        return (
          <React.Fragment key={group.groupName}>
            <h3 className="subtitle-2 bold">{group.groupName}</h3>
            {group.forms.map((form) => {
              const id = radioNameToID(form)
              return (
                <div
                  key={id}
                  className={`radio_card ${
                    id === selected ? 'selected' : ''
                  }`}
                  onClick={() => {
                    setSectionSelected('Form')
                    setSelected(id)
                  }}
                >
                  <input
                    data-testid={buildTestID(id, '/upload', 'radio')}
                    type="radio"
                    id={id}
                    name="upload_form_or_document"
                    value={id}
                    checked={id === selected}
                    aria-checked={id === selected ? "true" : "false"}
                    onChange={() => {
                      setSectionSelected('Form')
                      setSelected(id)
                    }}
                  />
                  <label className="subtitle-2" htmlFor={id}>
                    {form}
                  </label>
                </div>
              )
            })}
          </React.Fragment>
        )
      })}
      <h3
        className="document_title heading-6"
        aria-label="please select the type of document from the list below"
      >
        Please select the type of document:
      </h3>
      {Documents.map((form) => {
        const id = radioNameToID(form)
        return (
          <div
            key={id}
            className={`radio_card ${
              id === selected ? 'selected' : ''
            }`}
            onClick={() => {
              setSectionSelected('Document')
              setSelected(id)
            }}
          >
            <input
              data-testid={buildTestID(id, '/upload', 'radio')}
              type="radio"
              id={id}
              name="upload_form_or_document"
              value={id}
              checked={id === selected}
              onChange={() => {
                setSectionSelected('Document')
                setSelected(id)
              }}
            />
            <label className="subtitle-2" htmlFor={id}>
              {form}
            </label>
          </div>
        )
      })}
      <Button
        text="Upload"
        disabled={successfulUpload || !fileUploaded}
        fullWidth
        className="button"
        UID="upload"
        onClick={handleUpload}
        loading={loading}
      />
    </StyledSelectionSection>
  )
}

SelectionSection.propTypes = {
  Forms: PropTypes.array.isRequired,
  Documents: PropTypes.array.isRequired,
  setSectionSelected: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  fileUploaded: PropTypes.bool.isRequired,
  successfulUpload: PropTypes.bool.isRequired,
  handleUpload: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  radioNameToID: PropTypes.func.isRequired,
}

export default SelectionSection
