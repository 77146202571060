import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import Styled from 'styled-components'

import { refreshUser, getRandomLetters } from '../../services/auth'
import AuthContainer from './AuthContainer'
import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'
import { track2FA } from '../../Global/Analytics'
import MfaErrorModal from '../../Common/MfaErrorModal'

const StyledConfirmPhone = Styled.div`
  .error-message {
    color: ${({ theme }) => theme.colors.error};
  }

  .resend-button {
    display: flex;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const ConfirmPhone = ({ setAuthState, authData }) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [showMfaErrorModal, setShowMfaErrorModal] = useState(false)

  const [randomLetters, setRandomLetters] = useState('')

  const setErrorForAda = (error) => {
    setRandomLetters(getRandomLetters(4))
    setError(error)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const user = await Auth.verifyCurrentUserAttributeSubmit(
        'phone_number',
        code,
      )
      console.log('verified phone response', user)
      await refreshUser()
      track2FA.Completed()
      setAuthState('signedIn', { user, redirect: true })
    } catch (err) {
      setShowMfaErrorModal(true)
      setErrorForAda(err.message)
    }
  }
  return (
    <StyledConfirmPhone>
      <AuthContainer header="Confirm Phone Number">
        <form onSubmit={handleSubmit}>
          <div className="Section__sectionBody___ihqqd">
            <div className="Form__formField___38Ikl">
              <div className="Input__inputLabel___3VF0S">
                <label htmlFor="code">
                  <strong>Code *</strong>
                </label>
              </div>
              <input
                placeholder="Code"
                name="code"
                autoComplete="off"
                data-testid={buildTestID(
                  'code',
                  '/home',
                  'confirm_phone',
                )}
                className="Input__input___3e_bf"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                id="code"
                aria-required="true"
                type="text"
                pattern="\d*"
              />
            </div>

          </div>
          <p role="alert">
            <span
              className="error-message"
              data-testid="confirm-phone-error"
            >
              {error}
              <span
                style={{ visibility: 'hidden' }}
                dangerouslySetInnerHTML={{ __html: randomLetters }}
              ></span>
            </span>
          </p>
          <div className="buttons">
            <Button
              onClick={() => setAuthState('signIn')}
              textButton
              text="Back to Sign In"
              small
              UID="confirm_phone"
            />
            <Button
              type="submit"
              text="Confirm"
              UID="confirm_phone"
            />
          </div>
        </form>
        <MfaErrorModal onHide={() => { setShowMfaErrorModal(false) }} show={showMfaErrorModal} />
      </AuthContainer>
    </StyledConfirmPhone>
  )
}

export default ConfirmPhone
