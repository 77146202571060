import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import Button from '../../Common/button'

import useWindowSize from '../../Hooks/useWindowSize'

import { useHistory } from 'react-router-dom'

import OptIn from './OptIn'
import Consent from './Consent'
import Signature from './Signature'
import SignatureComplete from './SignatureComplete'
import QuitModal from './QuitModal'
import StepIndicator from './StepIndicator'
import ThirdParty from './ThirdParty'
import ThirdPartyComplete from './ThirdPartyComplete'
import StepIndicatorNbs from './StepIndicatorNbs'
import NbsFactSheet from './NbsFactSheet'
import Hecm from './Hecm'
import NbsInformation from './NbsInformation'
import ProofOfMarriage from './ProofOfMarriage'
import VerifyMfa from './VerifyMfa'

export const OPT_IN = 'OPT_IN'
export const CONSENT = 'CONSENT'
export const NBS_FACTSHEET = 'NBS_FACTSHEET'
export const HECM = 'HECM'
export const NBS_INFORMATION = 'NBS_INFORMATION'
export const PROOF_OF_MARRIAGE = 'PROOF_OF_MARRIAGE'
export const SIGNATURE = 'SIGNATURE'
export const SIGNATURE_COMPLETE = 'SIGNATURE_COMPLETE'
export const THIRD_PARTY = 'THIRD_PARTY'
export const THIRD_PARTY_COMPLETE = 'THIRD_PARTY_COMPLETE'
export const VERIFY_MFA =  'VERIFY_MFA'

const StyledESign = styled.div`
h1:focus, h1:focus-visible {
  outline: none;
}

padding-bottom: 72px;
//min-height: 900px;

.back_button {
    position: relative;
    display: inline-block;
    margin-top: 28px;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    /* added line */
    border: 0;
}

.visually-hidden:not(:focus):not(:active) {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

@media (min-width: ${({ theme }) => theme.breakPoints.small}) {
  .rectangle-10 {
    position: absolute;
    width: 100%;
    height: 286px;
    left: 0px;
    top: 112px;
    
    /* p - dark */
    
    background: #00377A;
    opacity: 0.06;
  }
}
`
const ESign = ({ borrower }) => {
  const [view, setView] = useState(OPT_IN)
  const [modalShow, setModalShow] = useState(false)
  const [optInTimestamp, setOptInTimestamp] = useState(null)
  const [consentTimestamp, setConsentTimestamp] = useState(null)
  const [thirdParty, setThirdParty] = useState(null)
  const [thirdPartyTimestamp, setThirdPartyTimestamp] = useState(null)
  const [nbsInformation, setNbsInformation] = useState(null)
  const [certificate, setCertificate] = useState()
  const [authData, setAuthData] = useState(null)
  const [challengeDestination, setChallengeDestination] = useState(null)
  const [ssnMfa, setSsnMfa] = useState(null)
  const [dobMfa, setDobMfa] = useState(null)
  const { isSmall } = useWindowSize()
  const history = useHistory();

  const routeChange = () => {
    let path = `/my-loan`;
    history.push(path);
  }

  const { search } = useLocation()
  const nbs = search.includes('type=NBS')

  const mainRef = useRef(null);

  useEffect(() => {
    if (mainRef)
      mainRef.current.focus();
  }, [mainRef]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [view])

  const handleProofOfMarriage = (successfulUpload) => {
    let information = nbsInformation
    information.proofUploaded = successfulUpload
    setNbsInformation(information)
  }

  const handleClientName = (setClientName) => {
    let information = nbsInformation
    if (setClientName) {
      information.clientName = sessionStorage.getItem('clientName')
      setNbsInformation(information)
    }
  }

  return (
    <StyleGuide>
      <QuitModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <StyledESign>
        <div className="rectangle-10"></div>
        {/* {view !== THIRD_PARTY && view !== THIRD_PARTY_COMPLETE ? ( */}
        {nbs
          ? <StepIndicatorNbs currentScreen={view} />
          : <StepIndicator currentScreen={view} />}
        <Grid className="grid">
          {isSmall || view === SIGNATURE_COMPLETE ? (
            <Button
              className="back_button"
              UID="occupancy_back_to_my_loan"
              text="Back to My Loan"
              textButton
              backArrow
              onClick={() => routeChange()}
            />
          ) : null}
          {view === OPT_IN && (
            <OptIn setView={setView} setModalShow={setModalShow} setOptInTimestamp={setOptInTimestamp} mainRef={mainRef} />
          )}
          {view === CONSENT && (
            <Consent borrower={borrower} setView={setView} setModalShow={setModalShow} setConsentTimestamp={setConsentTimestamp} mainRef={mainRef} />
          )}
          {view === THIRD_PARTY && (
            <ThirdParty borrower={borrower} nbs={nbs} setView={setView} setModalShow={setModalShow} setThirdParty={setThirdParty} setThirdPartyTimestamp={setThirdPartyTimestamp} mainRef={mainRef} />
          )}
          {view === NBS_FACTSHEET && (
            <NbsFactSheet borrower={borrower} setView={setView} setModalShow={setModalShow} mainRef={mainRef} />
          )}
          {view === HECM && (
            <Hecm borrower={borrower} setView={setView} setModalShow={setModalShow} setNbsInformation={setNbsInformation} mainRef={mainRef} />
          )}
          {view === NBS_INFORMATION && (
            <NbsInformation borrower={borrower} setView={setView} setModalShow={setModalShow} setNbsInformation={setNbsInformation} nameIsListed={nbsInformation.nameIsListed} mainRef={mainRef} />
          )}
          {view === PROOF_OF_MARRIAGE && (
            <ProofOfMarriage setView={setView} setModalShow={setModalShow} handleProofOfMarriage={handleProofOfMarriage} handleClientName={handleClientName} mainRef={mainRef} />
          )}
          {view === SIGNATURE && (
            <Signature borrower={borrower} optInTimestamp={optInTimestamp} consentTimestamp={consentTimestamp} thirdPartyTimestamp={thirdPartyTimestamp} thirdParty={thirdParty} nbsInformation={nbsInformation} setView={setView} setModalShow={setModalShow} setCertificate={setCertificate} setAuthData={setAuthData} setChallengeDestination={setChallengeDestination} setSsnMfa={setSsnMfa} setDobMfa={setDobMfa} mainRef={mainRef} />
          )}
          {view === VERIFY_MFA && (
            <VerifyMfa borrower={borrower} optInTimestamp={optInTimestamp} consentTimestamp={consentTimestamp} thirdPartyTimestamp={thirdPartyTimestamp} thirdParty={thirdParty} nbsInformation={nbsInformation} setView={setView} setModalShow={setModalShow} setCertificate={setCertificate} authData={authData} challengeDestination={challengeDestination} ssn={ssnMfa} dob={dobMfa} mainRef={mainRef} />
          )}
          {view === SIGNATURE_COMPLETE && (
            <SignatureComplete certificate={certificate} mainRef={mainRef} />
          )}
          {view === THIRD_PARTY_COMPLETE && (
            <ThirdPartyComplete setView={setView} mainRef={mainRef} />
          )}
        </Grid>
      </StyledESign>
    </StyleGuide>
  )
}

export default ESign
