import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { buildTestID } from '../Utils/utils'
import LeftChevronIcon from '../assets/left-chevron-icon'
import { trackButtonClick } from '../Global/Analytics'

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .all-buttons {
    -webkit-appearance: none;
    -webkit-border-radius: none;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primary};
    border: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 18px 24px;
    text-decoration: none;
    text-align: center;
    line-height: 18px;

    :hover {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.primary};
      opacity: 0.92;
    }

    :focus {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.primary24};
    }
  }

  .outlined {
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary};

    :hover {
      color: ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.primary4};
      opacity: 1;
    }

    :focus {
      color: ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.primary12};
      opacity: 1;
    }
  }

  .disabled.outlined {
    color: ${({ theme }) => theme.colors.black34};
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.black34};

    :hover,
    :focus {
      color: ${({ theme }) => theme.colors.black34};
      background: ${({ theme }) => theme.colors.white};
      border: none;
      border: 1px solid ${({ theme }) => theme.colors.black34};
    }
  }

  .disabled {
    border: none;
    color: ${({ theme }) => theme.colors.black60};
    background: ${({ theme }) => theme.colors.black12};
    cursor: default;

    :hover {
      color: ${({ theme }) => theme.colors.black60};
      background: ${({ theme }) => theme.colors.black12};
      opacity: 1;
    }

    :focus {
      color: ${({ theme }) => theme.colors.black60};
      background: ${({ theme }) => theme.colors.black12};
      opacity: 1;
    }
  }

  .small {
    padding: 12px 26px;
  }

  .textButton {
    color: ${({ theme }) => theme.colors.primary};
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    text-align: center;
    text-decoration-line: underline;
    font-weight: 700;
    outline: none;
    line-height: 24px;

    :hover {
      color: ${({ theme }) => theme.colors.primary};
      background: none;
    }

    :focus {
      color: ${({ theme }) => theme.colors.primary};
      background: none;
      outline: 2px solid #1460AA;
    }

    :focus-visible {
      color: ${({ theme }) => theme.colors.primary};
      background: none;
      outline: 2px solid #1460AA;
    }
  }

  .textButton.small {
    font-size: 16px;
  }

  .spinner-icon {
    height: 16px;
    margin-right: 10px;
  }

  .icon {
    margin-right: 10px;
  }

  .fullWidth {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const Button = (props) => {
  const {
    className,
    text,
    outlined,
    disabled,
    loading,
    small,
    onClick,
    backArrow,
    linkTo,
    textButton,
    linkButton,
    type,
    disableFocus,
    UID,
    externalLink,
    forwardRef,
    fullWidth,
    SVG,
    refreshPage = false,
    ariaLabel
  } = props

  let path = useLocation().pathname

  const handleClick = (e) => {
    if (disableFocus) {
      e.currentTarget.blur()
    }
    onClick && onClick(e)
    trackButtonClick({
      button_name: text,
      page: path,
      link_to: linkTo ? linkTo : null,
      uid: UID,
    })
  }

  const buttonProps = {
    onClick: !disabled ? (e) => handleClick(e) : () => {},
    type: !disabled && type ? type : 'button',
    ref: forwardRef ? forwardRef : null,
  }

  const { type: _, ...linkProps } = buttonProps

  const allLinkProps = {
    ...linkProps,
    to: linkTo || externalLink,
    rel: externalLink ? 'noopener noreferrer' : '',
    target: externalLink ? '_blank' : '_self',
  }

  const buildClassName = () => {
    let builtClassName = 'all-buttons '

    if (outlined && !loading) builtClassName += 'outlined '
    if (disabled) builtClassName += 'disabled '
    if (small) builtClassName += 'small '
    if (textButton) builtClassName += 'textButton '
    if (fullWidth) builtClassName += 'fullWidth '

    return builtClassName
  }

  const testID = buildTestID(text, path, UID)

  return (
    <StyledButton className={className}>
      {(linkTo || externalLink) && !disabled ? (
        !refreshPage ? (
          <Link
            {...allLinkProps}
            className={buildClassName()}
            data-testid={testID}
            aria-label={ariaLabel ? ariaLabel : disabled ? `disabled ${text}` : ''}
          >
            {backArrow && <LeftChevronIcon className="icon" />}
            {SVG && <SVG className="icon" />}
            {text}
          </Link>
        ) : (
          <a
            {...allLinkProps}
            href={linkTo || externalLink}
            className={buildClassName()}
            data-testid={testID}
            aria-label={ariaLabel ? ariaLabel : disabled ? `disabled ${text}` : ''}
          >
            {backArrow && <LeftChevronIcon className="icon" />}
            {SVG && <SVG className="icon" />}
            {text}
          </a>
        )
      ) : (
        <button
          {...buttonProps}
          className={buildClassName()}
          data-testid={testID}
          aria-label={ariaLabel ? ariaLabel : disabled ? `disabled ${text}` : ''}
        >
          {backArrow && <LeftChevronIcon className="icon" />}
          {SVG && <SVG className="icon" />}
          {loading && !outlined && (
            <Spinner
              data-testid="spinner"
              animation="border"
              role="status"
              size="sm"
              className="spinner-icon"
            />
          )}
          {text}
          {linkButton &&  <span className='sr-only'>(Link opens in a new tab)</span>}
        </button>
      )}
    </StyledButton>
  )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  backArrow: PropTypes.bool,
  className: PropTypes.string,
  linkTo: PropTypes.string,
  textButton: PropTypes.bool,
  type: PropTypes.string,
  UID: PropTypes.string,
  externalLink: PropTypes.string,
  forwardRef: PropTypes.object,
  fullWidth: PropTypes.bool,
  SVG: PropTypes.func,
  refreshPage: PropTypes.bool,
}

export default Button
