import React, { useState } from 'react'
import styled from 'styled-components'
import { HECM } from '.'

import Button from '../../Common/button'
import DocumentSolidIcon from '../../assets/document-solid-icon'
import { buildTestID } from '../../Utils/utils'
import DownloadArrowIcon from '../../assets/download-arrow-icon'
import { trackESignConsent } from '../../Global/Analytics'
import { saveAs } from 'file-saver';

const StyledNbsFactSheet = styled.div`
  h1, h3 {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }

  .fact-sheet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FAFAFA; 
    margin-bottom: 25px;
  }

  .fact-sheet > h6 {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px; 
    letter-spacing: 0.1px;
    margin: 0 0 16px 0;
  }
  
  .fact-sheet-links {
      display: flex;
      flex-direction: column;
  }

  .fact-sheet-links div {
    margin-bottom: 24px;
}

 svg {
   margin: -7px 10px 0 0
  }
  
  .cb-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
  }
  
  .cb {
    height: 18px;
    width: 18px;
    margin-right: 12px;
  
    position: static;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
  
    /* Inside Auto Layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 12px;
  }
  
  .l-cb {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    flex: none;
    order: 1;
    flex-grow: 1;
    margin: 0px 12px;
  
    position: static;
    width: 235px;
    height: 96px;
    left: calc(50% - 235px / 2 + 18px);
    top: 0px;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .nbs-factsheet {
      background-color: #fff;
      padding: 15px;
      margin-top: 32px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      text-align: center;
      margin: 32px;
    }

    .fact-sheet {
        margin: 25px 35px;
    }

    .fact-sheet-links { 
        flex-direction: row;
    }

    .fact-sheet-links div {
        margin-right: 29px;
        margin-bottom: 0;
    }

    .cb-container {
      margin: 25px 35px;
    }

    .l-cb {
        height: auto
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`

const NbsFactSheet = ({ setView, setModalShow, mainRef }) => {
  const [nextButtonDisabled, setnextButtonDisabled] = useState(true)
  const handleClick = (e) => {
    e.preventDefault()
    trackESignConsent.StepCompleted('reviewed fact sheet', 'view_factsheet')
    window.open('./forms/Non_Borrowing_Spouse_Fact_Sheet.pdf')
  }

  const handleDownload = () => {
    const url = new URL(`${window.location.origin}/forms/Non_Borrowing_Spouse_Fact_Sheet.pdf`)
    trackESignConsent.StepCompleted('reviewed fact sheet', 'download_factsheet')
    saveAs(url.href, 'Non_Borrowing_Spouse_Fact_Sheet.pdf')
  }

  return (
    <StyledNbsFactSheet>
      <div className="nbs-factsheet"
        data-gridcolumnlayout={{
          xl: '3/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Annual Occupancy Certification</h1>
        <h5>The U.S. Department of Housing and Urban Development (HUD) recently announced changes that may affect your Home Equity Conversion Mortgage (HECM) if you have an eligible Non-Borrowing Spouse. The benefit of this change is that it could result in a delay of the immediate obligation to pay off the loan after the last borrower dies.</h5>
        <section className="fact-sheet">
          <h6>Please review the Eligible Non-Borrowing Spouse fact sheet</h6>
          <div className="fact-sheet-links">
            <div>
              <Button
                text="Click Here To View"
                textButton
                onClick={handleClick}
                UID='view_factsheet'
                data-testid={buildTestID('view_factsheet', '/esign', 'nbs')}
                SVG={DocumentSolidIcon}
              />
            </div>
            <div>
              <Button
                text="Download PDF"
                textButton
                UID="download_factsheet"
                data-testid={buildTestID('download_factsheet', '/esign', 'nbs')}
                onClick={handleDownload}
                SVG={DownloadArrowIcon}
                download />
            </div>
          </div>
        </section>
        <div className="cb-container">
          <input
            className="cb"
            type="checkbox"
            id={`chk-fact-sheet`}
            name="chk-fact-sheet"
            onClick={() => setnextButtonDisabled(!nextButtonDisabled)}
            aria-label="Ackowledge and review fact sheet checkbox"
            data-testid={buildTestID('chk_fact_sheet', '/esign', 'nbs',)}
          />
          <label htmlFor="chk-fact-sheet" className="l-cb">I acknowledge that I’ve reviewed the fact sheet.</label>
        </div>
        <hr />
        <div className="buttons">
          <Button
            className="cancel button"
            text="Cancel"
            outlined
            fullWidth
            UID="nbs_factsheet_button_cancel"
            onClick={() => setModalShow(true)}
          />
          <Button
            className="button"
            text="Next"
            fullWidth
            UID="nbs_factsheet_button_next"
            disabled={nextButtonDisabled}
            onClick={() => {
              trackESignConsent.StepCompleted('reviewed fact sheet', 'nbs_factsheet_button_next')
              setView(HECM)
            }}
          />
        </div>
      </div>
    </StyledNbsFactSheet>
  )
}

export default NbsFactSheet