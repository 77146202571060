import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import UAParser from 'ua-parser-js'

import Button from '../../Common/button'
import useWindowSize from '../../Hooks/useWindowSize'
import { convertMoneyToWords } from '../../Utils/utils'

const StyledRecentTransactionsSection = styled.div`
  padding: 32px;
  margin: 32px 0 74px 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 16px;

    .heading-6 {
      margin: 0 0 24px 0;
    }
  }

  table {
    width: 100%;
    tbody {
      tr {
        border-top: 0.5px solid ${({ theme }) => theme.colors.black12};
      }
    }

    tr td:nth-child(2),
    tr th:nth-child(2) {
      padding: 16px 0;
      text-align: start;
      padding-left: 14px;
      padding-right: 14px;
      width: 100%;
    }

    tr td:first-child,
    tr th:first-child {
      text-align: start;
      min-width: 90px;
    }

    tr td:nth-last-child(-n + 6),
    tr th:nth-last-child(-n + 6) {
      padding: 16px 0;
      padding-right: 14px;
      text-align: end;
      min-width: unset;
    }

    tr td:nth-child(3),
    tr th:nth-child(3),
    tr td:nth-child(4),
    tr th:nth-child(4) {
      min-width: 120px;
    }

    tr td:last-child,
    tr th:last-child {
      padding: 16px 0;
    }

    thead {
      tr {
        th {
          padding-top: 0 !important;
          padding-bottom: 16px !important;
        }

        th.is_windows_os {
          min-width: 124px !important;
          padding-right: 18px;
        }
      }
    }

    .break_line {
      margin: 0 -32px;
      display: none;
    }
  }

  .empty_state {
    border-radius: 4px;
    padding: 124px 214px;
    background: ${({ theme }) => theme.colors.lightGrey};
    text-align: center;
  }

  .transactions_page {
    tr td:nth-last-child(-n + 6),
    tr th:nth-last-child(-n + 6) {
      min-width: 106px;
    }

    tbody {
      display: block;
      height: 40vh;
      overflow-y: scroll;

      tr:first-child {
        border: none;
      }
    }
    thead,
    tfoot,
    tbody tr {
      display: table;
      width: 100%;
    }

    tfoot {
      border-top: 0.5px solid ${({ theme }) => theme.colors.black12};

      td.is_windows_os {
        min-width: 122px !important;
      }
    }

    thead {
      border-bottom: 0.5px solid
        ${({ theme }) => theme.colors.black12};
    }

    .break_line {
      margin: 0 -32px;
      display: block;
    }
  }

  .tablet_row {
    display: flex;
    justify-content: space-between;
    border-top: 0.5px solid ${({ theme }) => theme.colors.black12};
    padding-top: 24px;

    .tablet_col:first-child {
      width: 38%;
    }

    p,
    h6 {
      text-align: start;
    }

    h6 {
      margin: 0 0 8px 0;
    }

    p {
      margin: 0 0 20px 0;
    }
  }

  .tablet_row:first-child {
    border-top: none;
  }

  .tablet_total_row {
    padding-top: 20px;
    border-top: 0.5px solid ${({ theme }) => theme.colors.black12};

    display: flex;
    flex-direction: column;
    align-items: start;

    h6 {
      margin: 0 0 20px 0;
    }

    .tablet_total_data {
      display: flex;
      justify-content: space-between;
      width: 100%;

      p,
      h6 {
        text-align: start;
      }

      h6 {
        margin: 0 0 8px 0;
      }

      p {
        margin: 0;
      }
    }
  }

  .mobile_card {
    h6 {
      text-align: start;
      margin: 0 0 24px 0;
    }

    border: 1px solid ${({ theme }) => theme.colors.black12};
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 16px;

    .break_line {
      margin: 0 0 24px 0;
    }

    .mobile_row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 18px;

      p,
      h6 {
        margin: 0;
        text-align: start;
      }

      p {
        text-align: end;
        margin-left: 8px;
      }
    }

    .mobile_row:last-child {
      margin-bottom: 0;
    }

    .second_mobile_row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        text-align: start;
        margin: 12px 0 0 0;
      }
    }
  }

  .mobile_view_all_button {
    margin-bottom: 36px;
    justify-content: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .empty_state {
      padding: 60px;
      margin-bottom: 32px;
      background: white;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .empty_state {
      background: ${({ theme }) => theme.colors.lightGrey};
    }
    padding: 0;
    margin: 32px 0 0 0;

    box-shadow: none;
  }
`

let numberToCurrency = (value) => {
  let parsed = Number.parseFloat(value).toFixed(2).toString()

  return parsed === null
    ? '$'
    : `$${parsed.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

let currencyToNumber = (currency) => {
  return typeof currency === 'number'
    ? currency
    : Number(currency.replace(/[^0-9.-]+/g, ''))
}

const RecentTransactionsSection = ({
  transactions,
  onAllTransactionsPage = false,
}) => {
  const [totals, setTotals] = useState({
    amount: '',
    interest: '',
    mip: '',
    serviceFees: '',
    transactionTotal: '',
  })

  useEffect(() => {
    if (!transactions.length) return
    setTotals(
      transactions.reduce((acc, cur) => {
        return {
          amount:
            currencyToNumber(acc.amount) +
            currencyToNumber(cur.amount),
          interest:
            currencyToNumber(acc.interest) +
            currencyToNumber(cur.interest),
          mip: currencyToNumber(acc.mip) + currencyToNumber(cur.mip),
          serviceFees:
            currencyToNumber(acc.serviceFees) +
            currencyToNumber(cur.serviceFees),
          transactionTotal:
            currencyToNumber(acc.transactionTotal) +
            currencyToNumber(cur.transactionTotal),
        }
      }),
    )
  }, [transactions])

  const { isXSmall, isSmall } = useWindowSize()

  const isWindowsOS = new UAParser().getOS().name === 'Windows'

  return (
    <StyledRecentTransactionsSection>
      {!onAllTransactionsPage ? (
        <div className="header">
          <h2 className="heading-6">Recent Transactions</h2>
          {!isXSmall && transactions.length ? (
            <Button
              text="View all transactions"
              linkTo="/transactions"
              small
              UID="view_transactions"
            />
          ) : null}
        </div>
      ) : null}
      {!isSmall && !isXSmall ? (
        <>
          <table role="table" aria-rowcount={transactions.length}
            className={
              onAllTransactionsPage ? 'transactions_page' : ''
            }
          >
            <thead>
              <tr className="body-2 bold" role="row">
                <th role="columnheader">Date</th>
                <th role="columnheader">Transaction</th>
                <th role="columnheader">Amount</th>
                <th role="columnheader">Interest</th>
                <th role="columnheader">
                  <abbr title="Mortgage Insurance Premium">MIP</abbr>
                </th>
                <th role="columnheader">Servicing Fees</th>
                <th role="columnheader"><abbr className="body-1 bold" title="Transaction Total">Trans. Total</abbr></th>
              </tr>
            </thead>
            {transactions.length ? (
              <tbody>
                {transactions.map((transaction, i) => {
                  return (
                    <tr key={i} className="body-2" role="row">
                      <td role="cell">{transaction.date}</td>
                      <td role="cell">{transaction.transaction}</td>
                      <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(transaction.amount)}></span><span aria-hidden="true">{transaction.amount}</span></td>
                      <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(transaction.interest)}></span><span aria-hidden="true">{transaction.interest}</span></td>
                      <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(transaction.mip)}></span><span aria-hidden="true">{transaction.mip}</span></td>
                      <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(transaction.serviceFees)}></span><span aria-hidden="true">{transaction.serviceFees}</span></td>
                      <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(transaction.transactionTotal)}></span><span aria-hidden="true">{transaction.transactionTotal}</span></td>
                    </tr>
                  )
                })}
              </tbody>
            ) : null}
            {onAllTransactionsPage && transactions.length ? (
              <tfoot>
                <tr className="body-2 bold">
                  <td role="cell">Totals</td>
                  <td role="cell"></td>
                  <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(totals.amount)}></span><span aria-hidden="true">{numberToCurrency(totals.amount)}</span></td>
                  <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(totals.interest)}></span><span aria-hidden="true">{numberToCurrency(totals.interest)}</span></td>
                  <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(totals.mip)}></span><span aria-hidden="true">{numberToCurrency(totals.mip)}</span></td>
                  <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(totals.serviceFees)}></span><span aria-hidden="true">{numberToCurrency(totals.serviceFees)}</span></td>
                  <td role="cell"><span className="sr-only" aria-label={convertMoneyToWords(totals.transactionTotal)}></span><span aria-hidden="true">{numberToCurrency(totals.transactionTotal)}</span></td>
                  <td role="cell"
                    className={`${isWindowsOS ? 'is_windows_os' : ''
                      }`}
                  />
                </tr>
              </tfoot>
            ) : null}
          </table>
          {transactions.length ? null : (
            <div className="empty_state">
              <p className="body-2">
                You do not have any recent transactions as of right
                now. They will appear here once they are ready to
                view.
              </p>
            </div>
          )}
        </>
      ) : null}

      {isSmall && !isXSmall ? (
        <>
          {transactions.map((transaction, i) => {
            return (
              <div className="tablet_row" key={i}>
                <div className="tablet_col">
                  <h6 className="body-1 bold">Date</h6>
                  <p className="body-2">{transaction.date}</p>
                  <h6 className="body-1 bold">Transaction</h6>
                  <p className="body-2">{transaction.transaction}</p>
                </div>
                <div className="tablet_col">
                  <h6 className="body-1 bold">Amount</h6>
                  <p className="body-2">{transaction.amount}</p>
                  <h6 className="body-1 bold">
                    Servicing
                    <br />
                    Fees
                  </h6>
                  <p className="body-2">{transaction.serviceFees}</p>
                </div>
                <div className="tablet_col">
                  <h6 className="body-1 bold">Interest</h6>
                  <p className="body-2">{transaction.interest}</p>
                  <abbr className="body-1 bold" title="Transaction Total"> 
                    Trans.
                    <br />
                    Total
                  </abbr>
                  <p className="body-2">
                    {transaction.transactionTotal}
                  </p>
                </div>
              </div>
            )
          })}
          {onAllTransactionsPage && transactions.length ? (
            <div className="tablet_total_row">
              <h6 className="body-1 bold">Totals</h6>
              <div className="tablet_total_data">
                <div className="tablet_total_cell">
                  <h6 className="body-1 bold">Amount</h6>
                  <p className="body-2">
                    {numberToCurrency(totals.amount)}
                  </p>
                </div>
                <div className="tablet_total_cell">
                  <h6 className="body-1 bold">Interest</h6>
                  <p className="body-2">
                    {numberToCurrency(totals.interest)}
                  </p>
                </div>
                <div className="tablet_total_cell">
                  <abbr className="body-1 bold" title="Transaction Total">Trans. Total</abbr>
                  <p className="body-2">
                    {numberToCurrency(totals.mip)}
                  </p>
                </div>
                <div className="tablet_total_cell">
                  <h6 className="body-1 bold">Servicing Fees</h6>
                  <p className="body-2">
                    {numberToCurrency(totals.serviceFees)}
                  </p>
                </div>
                <div className="tablet_total_cell">
                  <abbr className="body-1 bold" title="Transaction Total">Trans. Total</abbr>
                  <p className="body-2">
                    {numberToCurrency(totals.transactionTotal)}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {transactions.length ? null : (
            <div className="empty_state">
              <p className="body-2">
                You do not have any recent transactions as of right
                now. They will appear here once they are ready to
                view.
              </p>
            </div>
          )}
        </>
      ) : null}

      {isXSmall ? (
        <>
          {transactions.map((transaction, i) => {
            return (
              <div className="mobile_card" key={i}>
                <div className="mobile_row">
                  <h6 className="body-1 bold">Date</h6>
                  <p className="body-2">{transaction.date}</p>
                </div>
                <div className="break_line" />
                <div className="mobile_row second_mobile_row">
                  <h6 className="body-1 bold">Transaction</h6>
                  <p className="body-2">{transaction.transaction}</p>
                </div>
                <div className="break_line" />
                <div className="mobile_row">
                  <h6 className="body-1">Amount</h6>
                  <p className="body-2">{transaction.amount}</p>
                </div>
                <div className="mobile_row">
                  <h6 className="body-1">Servicing Fees</h6>
                  <p className="body-2">{transaction.serviceFees}</p>
                </div>
                <div className="mobile_row">
                  <h6 className="body-1">Interest</h6>
                  <p className="body-2">{transaction.interest}</p>
                </div>
                <div className="mobile_row">
                  <abbr className="body-1 bold" title="Transaction Total">Trans. Total</abbr>
                  <p className="body-2">
                    {transaction.transactionTotal}
                  </p>
                </div>
                <div className="mobile_row">
                  <abbr className="body-1 bold" title="Mortgage Insurance Premium">MIP</abbr>
                  <p className="body-2">{transaction.mip}</p>
                </div>
              </div>
            )
          })}
          {onAllTransactionsPage && transactions.length ? (
            <div className="mobile_card">
              <h6 className="body-1 bold">Total</h6>
              <div className="break_line" />
              <div className="mobile_row">
                <h6 className="body-1">Amount</h6>
                <p className="body-2">
                  {numberToCurrency(totals.amount)}
                </p>
              </div>
              <div className="mobile_row">
                <h6 className="body-1">Servicing Fees</h6>
                <p className="body-2">
                  {numberToCurrency(totals.serviceFees)}
                </p>
              </div>
              <div className="mobile_row">
                <h6 className="body-1">Interest</h6>
                <p className="body-2">
                  {numberToCurrency(totals.interest)}
                </p>
              </div>
              <div className="mobile_row">
                <abbr className="body-1 bold" title="Transaction Total">Trans. Total</abbr>
                <p className="body-2">
                  {numberToCurrency(totals.transactionTotal)}
                </p>
              </div>
              <div className="mobile_row">
                <abbr className="body-1 bold" title="Mortgage Insurance Premium">MIP</abbr>
                <p className="body-2">
                  {numberToCurrency(totals.mip)}
                </p>
              </div>
            </div>
          ) : null}
          {isXSmall &&
            !onAllTransactionsPage &&
            transactions.length ? (
            <Button
              className="mobile_view_all_button"
              text="View all transactions"
              linkTo="/transactions"
              small
              UID="view_transactions"
            />
          ) : null}
          {transactions.length ? null : (
            <div className="empty_state">
              <p className="body-2">
                You do not have any recent transactions as of right
                now. They will appear here once they are ready to
                view.
              </p>
            </div>
          )}
        </>
      ) : null}
    </StyledRecentTransactionsSection>
  )
}

RecentTransactionsSection.propTypes = {
  transactions: PropTypes.array.isRequired,
  onAllTransactionsPage: PropTypes.bool,
}

export default RecentTransactionsSection
