import React, { useState } from "react"
import styled from "styled-components"
import Button from '../../Common/button'
import { buildTestID } from "../../Utils/utils"
import { VERIFY_PHONE_NUMBER } from "."
import { updateIndividualPhoneNumber } from "../../services/api"

const StyledConsent = styled.div`
h1, h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }
  
  p {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 20px;
    // background: #fafafa;
  }

   
  .cb-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
  }
  
  .cb {
    height: 18px;
    width: 18px;
    margin-right: 12px;
  
    position: static;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
  
    /* Inside Auto Layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 12px;
  }
  
  .l-cb {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    flex: none;
    order: 1;
    flex-grow: 1;
    margin: 0px 12px;
  
    position: static;
    width: 235px;
    left: calc(50% - 235px / 2 + 18px);
    top: 0px;
  }

  input.form-control {
    height: 38px;
  }

  div.col:nth-child(even) {
    text-align: center;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    .consent {
      background-color: #fff;
      padding: 15px;
      margin: 32px auto 0;
      width: 600px;
      border-radius: 4px;
      z-index: 150;
      position: relative;
      box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
    }

    h1, h3, h5 {
      // text-align: center;
    }

    p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // padding: 24px;
      // background: #FAFAFA;
      font-size: 16px;
      // font-weight: 700;
      // margin: 25px 35px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .button-confirm-identity  {
        width: 230px !important;
      }
 }
  `

const Consent = ({ setView, setModalShow, phoneNumber, otherNumberType, mainRef }) => {
  const [loading, setLoading] = useState(false)
  const [nextButtonDisabled, setnextButtonDisabled] = useState(true)

  const handleCheckClick = async (e) => {
    const checked = e.target.checked;
    checked ? setnextButtonDisabled(false) : setnextButtonDisabled(true)
  }  

  const handleClick = async (e) => {
    const homePhoneNumber = otherNumberType === 'home' ? phoneNumber : ''
    const mobilePhoneNumber = otherNumberType === 'mobile' ? phoneNumber : ''
    const workPhoneNumber = otherNumberType === 'work' ? phoneNumber : ''
    setLoading(true);

    const input = {cellConsent: "Yes",  homePhoneNumber, mobilePhoneNumber, workPhoneNumber}
    await updateIndividualPhoneNumber(input)
    setView(VERIFY_PHONE_NUMBER)
  }

  return (
    <StyledConsent>
      <div className="consent"
        data-gridcolumnlayout={{
          xl: '1/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef} className="mb-3">May we have your Consent?</h1>
        <p>In order for us to be able to better communicate with you, we need your consent to contact you electronically.</p>
        <div className="cb-container">
          <input
            className="cb"
            type="checkbox"
            id={`chk-phone-consent`}
            name="chk-phone-consent"
            onClick={(e) => handleCheckClick(e)}
            aria-label="May we have your Consent?"
            data-testid={buildTestID('chk_phone_consent', '/enable-multifactor', 'phone_consent',)}
          />
          <label htmlFor="chk-phone-consent" className="l-cb">By providing my telephone number and/or email address, I consent to be contacted via email, text message, voice call, or through an automated dialing system, or pre-recorded voice message by the Servicer or its authorized third party for informational and account service calls related to my account, <strong><u>but not for telemarketing or sales calls</u></strong>, at any telephone number, including my mobile telephone number, or email addresses that I have provided. Message and Data rates may apply. I understand that I may contact the Servicer at any time to change these preferences.</label>
        </div>
        <hr />
        <div className="buttons">
          <Button
            className="button cancel"
            text="Cancel"
            textButton
            fullWidth
            UID="consent_cancel"
            onClick={() => setModalShow(true)}
          />
          <Button
            className="button button-confirm-identity"
            text={'Confirm'}
            type="submit"
            fullWidth
            UID="consent_submit"
            disabled={nextButtonDisabled || loading}
            aria-disabled={nextButtonDisabled || loading}
            loading={loading}
            onClick={(e) => handleClick(e)}
          />
        </div>
      </div>
    </StyledConsent>
  )
}

export default Consent;