import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const ESignConsentIcon = ({ color = 'primary' }) => {
    return (
        <StyledSVG
            color={color}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="20"
            fill="none"
            viewBox="0 0 20 16"
        >
            <path d="M8 0V1.38C7.17 1.05 6.28 0.88 5.39 0.88C3.6 0.88 1.81 1.56 0.440002 2.93L3.77 6.26H4.88V7.37C5.74 8.23 6.86 8.68 7.99 8.73V11H5V14C5 15.1 5.9 16 7 16H17C18.66 16 20 14.66 20 13V0H8ZM6.89 6.41V4.26H4.61L3.57 3.22C4.14 3 4.76 2.88 5.39 2.88C6.73 2.88 7.98 3.4 8.93 4.34L10.34 5.75L10.14 5.95C9.63 6.46 8.95 6.75 8.22 6.75C7.75 6.75 7.29 6.63 6.89 6.41ZM18 13C18 13.55 17.55 14 17 14C16.45 14 16 13.55 16 13V11H10V8.41C10.57 8.18 11.1 7.84 11.56 7.38L11.76 7.18L14.59 10H16V8.59L10 2.62V2H18V13Z" />
        </StyledSVG>
    )
}

export default ESignConsentIcon
