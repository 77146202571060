import React from 'react'
import Styled from 'styled-components'
import { Container } from 'react-bootstrap'

import Title from '../Common/Title'
import ScrollUpButton from '../Common/ScrollUpButton'

const StyledImportantDisclosure = Styled.div`
  margin-top: 80px;
  margin-bottom: 100px;
  font-size: 20px;

.title {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 800px;
}

.question {
  margin-bottom: 12px;
}

p {
  margin-bottom: 40px;
}

a {
  color: ${({ theme }) => theme.colors.primary};
}
`

const ImportantDisclosure = () => {
  return (
    <>
      <Title>Important Disclosures</Title>
      <StyledImportantDisclosure>
        <main id="main" tabIndex="0">
          <Container>
            <div className="title">
              <h2>Important Disclosures</h2>
            </div>
            <div>
              <p>
                We use third-party providers to perform certain
                services. We remain responsible for all actions taken
                by such third-party providers with respect to such
                services.
              </p>
              <p className="question">
                <strong>
                  Notice of Error and Request for Information{' '}
                </strong>
              </p>
              <p>
                Federal law gives customers the right to notify us of
                an error regarding the servicing of their loan or to
                request information regarding their loan. If you wish
                to provide a notice of error or a request for
                information, you must write to us at the following
                address: PO Box 40719, Lansing, MI 48901. The letter
                must provide the customer’s name, loan number, and
                description of the error or detailed list of the
                information being requested.
              </p>

              <p className="question">
                <strong>
                  If your property is located in the State of Texas:{' '}
                </strong>
              </p>
              <p>
                COMPLAINTS REGARDING THE SERVICING OF YOUR MORTGAGE
                SHOULD BE SENT TO THE DEPARTMENT OF SAVINGS AND
                MORTGAGE LENDING 2601 NORTH LAMAR, SUITE 201, AUSTIN,
                TX 78705. A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT
                877-276-5550. A complaint form and instructions may be
                downloaded and printed from the Department website
                located at{' '}
                <a href="https://www.sml.texas.gov/">
                  https://www.sml.texas.gov/
                </a>{' '}
                or obtained from the department upon request by mail
                at the address above by telephone listed above or by
                email at smlinfo@sml.texas.gov.
              </p>

              <p className="question">
                <strong>
                  If your property is located in the State of New
                  York:{' '}
                </strong>
              </p>
              <p>
                Your servicer is either a registered servicer or
                exempt servicer registered with the Superintendent of
                the New York State Department of Financial Services.
                For further information or to make a complaint
                regarding your servicer you may contact the New York
                State Department of Financial Services Consumer
                Assistance Unit at (800) 342-3736 or by visiting{' '}
                <a href="https://www.dfs.ny.gov/">
                  https://www.dfs.ny.gov/
                </a>
                .
              </p>

              <p className="question">
                <strong>
                  If your property is located in the State of
                  Arkansas:{' '}
                </strong>
              </p>
              <p>
                Your servicer is licensed in Arkansas and complaints
                about your servicer may be submitted to the Arkansas
                Securities Department via the Department’s website{' '}
                <a href="http://www.securities.arkansas.gov/">
                  http://www.securities.arkansas.gov/
                </a>{' '}
                or toll-free at (800) 981-4429.
              </p>

              <p className="question">
                <strong>
                  If your property is located in the State of Oregon:{' '}
                </strong>
              </p>
              <p>
                Borrowers: The Oregon Division of Financial Regulation
                (DFR) oversees residential mortgage loan servicers who
                are responsible for servicing residential mortgage
                loans in connection with real property located in
                Oregon and persons required to have a license to
                service residential mortgage loans in this state. If
                you have questions regarding your residential mortgage
                loan, contact your servicer at (866) 654–0020 or by
                email at BC@reversedepartment.com. To file a complaint
                about unlawful conduct by an Oregon licensee or a
                person required to have an Oregon license, call DFR at
                888-877-4894 or visit{' '}
                <a href="https://dfr.oregon.gov/Pages/index.aspx">
                  https://dfr.oregon.gov/Pages/index.aspx
                </a>
                .
              </p>

              <p className="question">
                <strong>
                  If your property is located in the State of Hawaii:{' '}
                </strong>
              </p>
              <p>
                Your servicer is a licensed mortgage servicer in
                Hawaii. Complaints may be submitted to the Hawaii
                Department of Financial Institutions at P.O. Box 2054,
                Honolulu, Hawaii 96805 or dfi@dcca.hawaii.gov. A
                complaint form and instructions regarding how to file
                a complaint are available at{' '}
                <a href="http://cca.hawaii.gov/dfi/file-a-complaint/">
                  http://cca.hawaii.gov/dfi/file-a-complaint/
                </a>
                .
              </p>
            </div>
          </Container>
          <ScrollUpButton />
        </main>
      </StyledImportantDisclosure>
    </>
  )
}

export default ImportantDisclosure
