import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import CommonQuestionCategories from './CommonQuestionCategories'


const Bankruptcy = ({ CustomToggle, addRef }) => {
    const title = useRef(null)

    useEffect(() => {
        addRef(CommonQuestionCategories.Bankruptcy, title)
    })

    return (
        <>
            <h3
                ref={title}
                id="bankruptcy"
                tabIndex="-1"
            >
                Bankruptcy
            </h3>
            <h5>Short Sales</h5>
            <Accordion>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="0">
                            May I apply for a Short Sale while I am in active Bankruptcy?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            Yes you may. You will need to obtain permission from the court  before closing on a short sale. Please discuss with your attorney.
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <h5>LOC Draw</h5>
            <Accordion>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="0">
                            May I still draw on my HELOC while in Bankruptcy?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            Yes. However automatic draws will immediately cease and you will need to obtain permission from the Bankrutpcy court to incur additional debt while the Bankruptcy Stay is in place. Please inquire with your attorney for more information.
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <h5>Documents</h5>
            <Accordion>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="0">
                            Where do I send my Bankruptcy Documents?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            Please send your Bankruptcy Petition and Bankruptcy closing documents to <a href="mailto:Bankruptcy@ReverseDepartment.com">Bankruptcy@ReverseDepartment.com</a>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="1">
                            Do I need to send in my proof of insurance paid during my Bankruptcy Case?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            Yes. Please send these in to our Taxes and Insurance Department at <a href="mailto:TaxAndInsurance@ReverseDepartment.com">TaxAndInsurance@ReverseDepartment.com</a>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="2">
                            Do I need to send in my proof of insurance paid during my Bankruptcy Case?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            Yes. Please send these in to our Taxes and Insurance Department at <a href="mailto:reverse@Insdoc.com">reverse@Insdoc.com</a>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="3">
                            Where do I send my signed Certificate of Occupancy during my Bankruptcy Case?
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            These are required annually and are still very important even while you are in Bankruptcy. Please email these to <a href="mailto:Occupancy.Dept@ReverseDepartment.com">Occupancy.Dept@ReverseDepartment.com</a>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}

export default Bankruptcy
