import { HashLink as Link } from 'react-router-hash-link'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import useWindowSize from '../../Hooks/useWindowSize'
import { BORROWER_DETAILS, } from './index'
import { formatPhoneNumberNormalize } from '../../Utils/utils'
import dayjs from 'dayjs'

const StyledBorrowerDetails = styled.div`
  width: 100%;
  padding: 32px;
  margin: 32px 0 0 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .heading {
    margin: 0;
  }

  .sub_heading {
    margin: 16px 0 24px 0;
  }

  .group_title {
    margin: 0 0 12px 0;
  }

  .info_group {
    display: flex;
    justify-content: space-between;
    // margin: 0 0 20px 0;

  }

  .info_group > p {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex-grow: 1;
  }

  .info_group > ul {
    list-style-type: none;
    padding: 0
  }

  h5.subtitle-2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex-grow: 1;
  }

  h6 {
    margin: 0;
  }

  hr {
    border-top: 1px solid lightgray;
    width: 100%;
  }

  .info_group {
    flex-direction: column
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    box-shadow: none;
    margin-top: 24px;
    padding: 0;

      .change_password_button {
        width: 100%;
        margin-top: 24px;

        button {
          text-align: center;
          margin-left: 0;
          line-height: 20px;
        }
      }
    }
  }

  @media (min-width: 992px) { 
    .info {
      display: flex;
      flex-direction: row;
    }

    .info_group {
      flex-direction: column;
      align-items: flex-start;
      flex-basis: 100%;
      flex: 1;
    }
  }
`

const BorrowerDetails = ({
  borrower,
  scrollRef,
  currentScreen,
  setCurrentScreen,
  setOpenBanner,
}) => {
  const { isSmall } = useWindowSize()

  return (
    <>
      {currentScreen === BORROWER_DETAILS || !isSmall ? (
        <StyledBorrowerDetails>
          <div id="borrower_details" ref={scrollRef} className="scroll" />
          <h2 className="heading-6 heading"
            role={currentScreen === BORROWER_DETAILS ? 'alert' : null}>Borrower Details</h2>
          <p className="subtitle-2 sub_heading">
            Your information associated with the loan on your account.
          </p>
          <div className="info">
            <div className="info_group">
              <h3 className="subtitle-2 bold group_title">Name:</h3>
              <p>{borrower.fullName}</p>
            </div>
            <div className="info_group">
              <h3 className="subtitle-2 bold group_title">Date of Birth:</h3>
              <p>{dayjs(borrower.birthDate).format('MM/DD/YYYY',)}</p>
            </div>
          </div>
          <div className="info">
            <div className="info_group">
              <h3 className="subtitle-2 bold group_title">Phone Number:</h3>
              {
                borrower.phoneNumber !== undefined ?
                  <p>{formatPhoneNumberNormalize(borrower.phoneNumber)}</p> :
                  <p></p>
              }
            </div>
            <div className="info_group">
              <h3 className="subtitle-2 bold group_title">Mailing Address:</h3>
              <p>{borrower.houseNumberStreeName}<br />{borrower.cityStateZip}</p>
            </div>
          </div>
          {
            borrower.coborrowers.length ?
              <div>
                <hr />
                <div className="info_group">
                  <h5 className="subtitle-2 bold group_title">Other Borrowers:</h5>
                </div>
                <div className="info_group">
                  <ul>
                    {borrower.coborrowers.map((coborrower, index) => {
                      return (
                        <li key={index}>{coborrower}</li>
                      )
                    })}
                  </ul>
                </div>
              </div> : ""
          }
          <div className="borrower-detail-change-link mt-3">
            <Link to="/common-questions#mailing-address-name-changes">
              What is required to change my information above?
            </Link>
          </div>
        </StyledBorrowerDetails>
      ) : null}
    </>
  )
}

BorrowerDetails.propTypes = {
  borrower: PropTypes.object.isRequired,
  scrollRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  currentScreen: PropTypes.string.isRequired,
  setCurrentScreen: PropTypes.func.isRequired,
  setOpenBanner: PropTypes.func.isRequired,
}

export default BorrowerDetails
