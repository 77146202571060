import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import ContractIcon from '../../assets/contract-icon'
import Button from '../../Common/button'
import { trackPayoffQuote } from '../../Global/Analytics'
import { useRetrievePayOffEligibility } from '../../Hooks/useRetrievePayOffEligibility'
import { usePayoffDateEligibility } from '../../Hooks/usePayoffDateEligibility'
import useWindowSize from '../../Hooks/useWindowSize'
import { buildTestID } from '../../Utils/utils'

const PayoffQuoteTool = ({ setAvailableTools }) => {
  const [eligibleForPayoffQuote, setEligibleForPayoffQuote] =
    useState(false)

  const {
    error: payoffError,
    loading: payoffLoading,
    data: payoffData,
  } = useRetrievePayOffEligibility()

  const { isMassAndInvalidDate } = usePayoffDateEligibility()

  useEffect(() => {
    if (!payoffLoading && !payoffError && payoffData && payoffData.eligible) {
      setEligibleForPayoffQuote(true)
    }
  }, [payoffError, payoffData, payoffLoading])

  const { isXSmall } = useWindowSize()

  if (!payoffLoading && eligibleForPayoffQuote) {
    setAvailableTools()
  }

  if (!isXSmall) {
    return !payoffLoading ? (
        <div className="tool">
          <div className="content">
            <ContractIcon />
            <div className="button-and-text">
              <h3 className="body-1 bold">
                {isMassAndInvalidDate
                  ? 'Payoff Quote requests are temporarily unavailable. Please try again later.'
                  : 'Request a payoff quote online for your loan.'}
              </h3>
              <Button
                text="Request Quote"
                linkTo="/payoff-quote"
                onClick={trackPayoffQuote.Started}
                disabled={isMassAndInvalidDate}
                UID="payoff_quote"
              />
            </div>
          </div>
        </div>
    ) : (
      <div className="tool">
        <div className="loading">
          <div className="content">
            <div className="rectangle icon" />
            <div className="left-rec-group">
              <div className="rectangle long" />
              <div className="rectangle long" />
              <div className="rectangle large" />
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return !payoffLoading ? (
      <Link
        to="/payoff-quote"
        onClick={trackPayoffQuote.Started}
        className="mobile_card"
        data-testid={buildTestID(
          'request_a_payoff_quote',
          '_my_loan',
          'mobile',
        )}
      >
        <div className="icon_section">
          <ContractIcon color="white" />
        </div>
        <p className="caption">Request a Payoff Quote</p>
      </Link>
    ) : null
  }
}

PayoffQuoteTool.propTypes = {
  setAvailableTools: PropTypes.func.isRequired,
}

export default PayoffQuoteTool
