import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const InformationBubbleIcon = ({ className }) => {
    return (
        <StyledSVG className={className} fill="none" viewBox="0 0 20 20">
            <path d="M10.5 5.5V6.5H9.5V5.5H10.5ZM10.5 9.5V14.5H9.5V9.5H10.5ZM0.5 10C0.5 4.75614 4.75614 0.5 10 0.5C15.2439 0.5 19.5 4.75614 19.5 10C19.5 15.2439 15.2439 19.5 10 19.5C4.75614 19.5 0.5 15.2439 0.5 10ZM1.5 10C1.5 14.6861 5.31386 18.5 10 18.5C14.6861 18.5 18.5 14.6861 18.5 10C18.5 5.31386 14.6861 1.5 10 1.5C5.31386 1.5 1.5 5.31386 1.5 10Z" fill="#00377A" stroke="#00377A" />
        </StyledSVG>
    )
}

export default InformationBubbleIcon