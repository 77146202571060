import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledCell = styled.div`
  grid-column: ${({ gridcolumnlayout }) => gridcolumnlayout.xl};
  -ms-grid-column: 2;
  -ms-grid-row: ${({ row }) => row};
  grid-column-gap: 8px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.large}) {
    grid-column: ${({ gridcolumnlayout }) => gridcolumnlayout.lg};
    -ms-grid-column-span: 10;
    grid-column-gap: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    grid-column: ${({ gridcolumnlayout }) => gridcolumnlayout.md};
    -ms-grid-column-span: 10;
    grid-column-gap: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    grid-column: ${({ gridcolumnlayout }) => gridcolumnlayout.sm};
    -ms-grid-column-span: 6;
    grid-column-gap: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    grid-column: ${({ gridcolumnlayout }) => gridcolumnlayout.xs};
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column-gap: 8px;
  }
`

const Cell = ({ row, children, className, gridcolumnlayout }) => {
  return (
    <StyledCell
      className={className}
      row={row}
      gridcolumnlayout={gridcolumnlayout}
    >
      {children}
    </StyledCell>
  )
}

const StyledGrid = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr repeat(12, 80px) 1fr;
  -ms-grid-columns: 1fr 800px 1fr;
  column-gap: 20px;
  margin-top: -28px;
  grid-template-rows: 1fr;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.large}) {
    grid-template-columns: repeat(12, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: -28px 80px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    margin: -28px 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    grid-template-columns: repeat(8, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: -28px 24px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    grid-template-columns: repeat(4, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
  }
`

// Each child of the grid component recieves cell styling
// The Grid component or the children can utilize the gridcolumnlayout attribute
const Grid = ({ children, className }) => {
  const defaultColumnLayout = {
    xl: '4/12',
    lg: '3/11',
    md: '3/11',
    sm: '2/8',
    xs: '1/5',
  }

  return (
    <StyledGrid className={className}>
      {(Array.isArray(children) ? children : [children]).map(
        (child, index) => {
          const childColumnLayout =
            child &&
            child.props &&
            child.props['data-gridcolumnlayout']
              ? child.props['data-gridcolumnlayout']
              : {}

          return (
            <Cell
              className={
                child && child.props && child.props.cellclassname
                  ? child.props.cellclassname
                  : null
              }
              key={index}
              row={index + 1}
              gridcolumnlayout={{
                ...defaultColumnLayout,
                ...childColumnLayout,
              }}
            >
              {child}
            </Cell>
          )
        },
      )}
    </StyledGrid>
  )
}

Grid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  className: PropTypes.string,
}

export default Grid
