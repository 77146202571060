const CommonQuestionCategories = {
  Popular: 'Popular',
  HowReverseMortgagesWork: 'HowReverseMortgagesWork',
  RequestingFunds: 'RequestingFunds',
  MonthlyPayments: 'MonthlyPayments',
  EftDirectDeposit: 'EftDirectDeposit',
  PaymentPlanChanges: 'PaymentPlanChanges',
  Statements: 'Statements',
  IncomeTaxes1098: 'IncomeTaxes1098',
  RepairsInsuranceTaxes: 'RepairsInsuranceTaxes',
  Occupancy: 'Occupancy',
  DefaultMaturity: 'DefaultMaturity',
  WhatDoIDoWhenMyLoanIsDue:'WhatDoIDoWhenMyLoanIsDue',
  MailingAddress: 'MailingAddress',
  PrepaymentsPayoffs: 'PrepaymentsPayoffs',
  Refinancing: 'Refinancing',
  LoanSales: 'LoanSales',
  ThirdPartyAuthorizations: 'ThirdPartyAuthorizations',
  Trusts: 'Trusts',
  Spouses: 'Spouses',
  GettingHelp: 'GettingHelp',
  UsefulLinks: 'UsefulLinks',
  LossDraft: 'LossDraft',
  Bankruptcy: 'Bankruptcy',
  RepairsRequiredAtLoanOrigination: 'RepairsRequiredAtLoanOrigination'
}

export default CommonQuestionCategories
