import React from 'react'
import ESign from '../Components/ESign'

import LoadingPage from '../Components/LoadingPage'
import Title from '../Common/Title'

import {
    extractLoan,
    extractBorrowerAndCoborrowers,
} from '../Utils/apiExtractors'
import { useApiContext } from '../Hooks/useApiContext'
import { useLoggedInUser } from '../Hooks/useLoggedInUser'

const ESignContainer = () => {
    const { loan } = useApiContext()
    const {
        error: loanError,
        loading: loanLoading,
        data: loanData,
    } = loan

    const {
        error: userError,
        loading: userLoading,
        data: userData,
    } = useLoggedInUser()

    if (loanLoading || !loanData || userLoading || !userData) {
        return <LoadingPage />
    }

    if (loanError || userError) {
        return <div>{(loanError).message}</div>
    }

    const loanResponse = extractLoan(loanData)
    const [borrowerResponse] = extractBorrowerAndCoborrowers(
        loanData,
        userData.attributes['custom:person_id'],
    )
    
    const borrower = {
        loanNumber: loanData.data.loan.loanData.loanId,
        personId: borrowerResponse.personId,
        firstName: borrowerResponse.firstName,
        lastName: borrowerResponse.lastName,
        email: userData.attributes['email'],
        userName: `${borrowerResponse.firstName} ${borrowerResponse.lastName}`,
        houseNumberStreeName: loanResponse.property.address1,
        cityStateZip: `${loanResponse.property.city}, ${loanResponse.property.state} ${loanResponse.property.postalCode}`,
        city: loanResponse.property.city,
        state: loanResponse.property.state,
        postalCode: loanResponse.property.postalCode
    }
    
    return (
        <>
            <Title>Electronic Signature</Title>
            <ESign borrower={borrower} />
        </>
    )
}

export default ESignContainer
