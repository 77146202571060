import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const QuestionBubbleIcon = ({ className }) => {
  return (
    <StyledSVG className={className} fill="none" viewBox="0 0 56 56">
      <path
        d="M28 1.09375C41.8941 1.09375 53.8125 13.0122 53.8125 26.9063C53.8125 40.8003 41.8941 52.7188 28 52.7188C23.6404 52.7188 19.5397 51.6096 15.9653 49.6584L7.21875 54.9063L9.31782 44.4097C5.29358 39.9505 2.1875 33.3867 2.1875 26.9063C2.1875 13.0122 14.106 1.09375 28 1.09375Z"
        fill="#94E1FF"
      />
      <path
        d="M36.75 20.3438C36.75 24.4137 33.9575 27.8436 30.1875 28.8169V33.4688H25.8125V26.3209C25.8125 25.1127 26.7926 24.1334 28 24.1334H28.1837C30.7421 24.1334 32.8266 21.8041 32.2567 19.3111C32.2502 19.2838 32.2438 19.2564 32.237 19.2282C31.8499 17.6619 30.5297 16.4007 28.9506 16.0696C26.1188 15.4731 23.625 17.6171 23.625 20.3438H19.25C19.25 15.5193 23.1755 11.5938 28 11.5938C32.8232 11.5938 36.75 15.5193 36.75 20.3438Z"
        fill="white"
      />
      <path
        d="M28 37.8438C29.2087 37.8438 30.1875 38.8226 30.1875 40.0313C30.1875 41.24 29.2087 42.2188 28 42.2188C26.7913 42.2188 25.8125 41.24 25.8125 40.0313C25.8125 38.8226 26.7913 37.8438 28 37.8438Z"
        fill="white"
      />
      <path
        d="M49.4375 27.5626C50.0412 27.5626 50.5312 27.0725 50.5312 26.4688C50.5312 25.8651 50.0412 25.3751 49.4375 25.3751C48.8338 25.3751 48.3438 25.8651 48.3438 26.4688C48.3438 27.0725 48.8338 27.5626 49.4375 27.5626Z"
        fill="black"
      />
      <path
        d="M8.13862 44.7271L6.14637 54.6918C6.06134 55.1165 6.23481 55.551 6.58857 55.8005C6.94318 56.05 7.41102 56.0663 7.78144 55.8441L15.9987 50.9141C19.6884 52.8119 23.8233 53.8125 28 53.8125C42.429 53.8125 54.9063 41.4737 54.9063 26.9063C54.9063 12.4773 42.5674 0 28 0C13.5711 0 1.09375 12.3389 1.09375 26.9063C1.09375 33.247 3.95374 39.8967 8.13862 44.7271ZM28 2.1875C41.3989 2.1875 52.7188 13.5074 52.7188 26.9063C52.7188 40.3051 41.3989 51.625 28 51.625C23.9771 51.625 19.9968 50.6129 16.4896 48.6984C16.1491 48.5126 15.7355 48.5211 15.4027 48.7206L8.77607 52.6966L10.3902 44.6242C10.4581 44.285 10.3616 43.9338 10.1296 43.677C5.9054 38.9956 3.28125 32.5694 3.28125 26.9063C3.28125 13.5074 14.6011 2.1875 28 2.1875Z"
        fill="black"
      />
      <path
        d="M41.3224 44.7327C46.3776 41.1306 49.385 36.5133 50.2609 31.0078C50.3557 30.4114 49.949 29.8508 49.3525 29.756C48.7565 29.6611 48.1951 30.0679 48.1003 30.6643C47.3201 35.5682 44.6127 39.7023 40.0527 42.9511C39.5609 43.3014 39.4464 43.9846 39.7967 44.4763C40.1475 44.9685 40.8302 45.083 41.3224 44.7327Z"
        fill="black"
      />
      <path
        d="M19.25 21.4375H23.625C24.2291 21.4375 24.7188 20.9479 24.7188 20.3438C24.7188 18.3374 26.5448 16.681 28.7259 17.1398C29.9034 17.3872 30.8873 18.3301 31.1744 19.4876L31.1911 19.5576C31.3744 20.3617 31.1779 21.1773 30.6383 21.8541C29.5697 23.1948 28.0543 23.0281 28 23.0397C26.1906 23.0397 24.7188 24.512 24.7188 26.3209V33.4688C24.7188 34.0729 25.2084 34.5625 25.8125 34.5625H30.1875C30.7916 34.5625 31.2813 34.0729 31.2813 33.4688V29.6261C35.1846 28.2482 37.8438 24.5453 37.8438 20.3438C37.8438 14.8814 33.4055 10.5 28 10.5C22.5718 10.5 18.1562 14.916 18.1562 20.3438C18.1562 20.9479 18.6459 21.4375 19.25 21.4375ZM28 12.6875C32.2037 12.6875 35.6563 16.0982 35.6563 20.3438C35.6563 23.8361 33.2949 26.8849 29.9141 27.7582C29.4313 27.8829 29.0938 28.3183 29.0938 28.8173V32.375H26.9063V26.3209C26.9063 25.7181 27.3967 25.2272 28 25.2272C28.0479 25.2165 30.5857 25.4297 32.3489 23.2178C33.2987 22.0263 33.6538 20.5138 33.3231 19.0676C33.3222 19.0637 33.3004 18.9719 33.2991 18.9655C32.8155 17.0091 31.1582 15.415 29.1758 14.9989C26.1018 14.3521 23.2319 16.3298 22.6406 19.25H20.4215C20.9539 15.5445 24.1497 12.6875 28 12.6875Z"
        fill="black"
      />
      <path
        d="M28 36.7501C26.1906 36.7501 24.7188 38.2219 24.7188 40.0313C24.7188 41.8407 26.1906 43.3126 28 43.3126C29.8094 43.3126 31.2812 41.8407 31.2812 40.0313C31.2812 38.2219 29.8094 36.7501 28 36.7501ZM28 41.1251C27.3967 41.1251 26.9062 40.6346 26.9062 40.0313C26.9062 39.428 27.3967 38.9376 28 38.9376C28.6033 38.9376 29.0937 39.428 29.0937 40.0313C29.0937 40.6346 28.6033 41.1251 28 41.1251Z"
        fill="black"
      />
    </StyledSVG>
  )
}

export default QuestionBubbleIcon
