import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .primaryDark {
    fill: ${({ theme }) => theme.colors.primaryDark};
  }

  .white {
    fill: ${({ theme }) => theme.colors.white};
  }
`

const PairIcon = () => {
  return (
    <StyledSVG
      aria-hidden="true"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="126"
      height="112"
    >
      <defs>
        <path id="pair_icon" d="M0 .417h102.65v92.581H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          className="primaryDark"
          fill="#0E4377"
          d="M8.25 66.969h88v-59h-88z"
        ></path>
        <g transform="translate(.25 -.031)">
          <mask id="pair" fill="#fff">
            <use xlinkHref="#a"></use>
          </mask>
          <path
            className="primary"
            fill="#1460AA"
            d="M91.362 60.623a1.651 1.651 0 01-1.529 1.46l-3.276.238a499.135 499.135 0 01-73.744-.205 1.668 1.668 0 01-1.526-1.494 212.937 212.937 0 01.004-47.38 1.656 1.656 0 011.522-1.461c12.688-.98 25.46-1.471 38.242-1.471 11.859 0 23.724.422 35.509 1.266l3.263.231c.8.06 1.448.673 1.535 1.467a212.84 212.84 0 010 47.348m9.908-48.44c-.618-5.529-5.127-9.835-10.726-10.24l-3.266-.231a507.552 507.552 0 00-75.236.208C6.483 2.344 2 6.647 1.38 12.179a222.951 222.951 0 00.003 49.568c.618 5.5 5.1 9.803 10.659 10.228 11.386.88 22.84 1.352 34.299 1.457v9.674H26.405c-2.75 0-4.984 2.216-4.984 4.946 0 2.73 2.233 4.946 4.984 4.946h49.839c2.751 0 4.984-2.216 4.984-4.946 0-2.73-2.233-4.946-4.984-4.946H56.308v-9.723c10.347-.106 20.693-.465 30.97-1.197l3.27-.234c5.592-.402 10.104-4.705 10.722-10.235a222.855 222.855 0 000-49.534"
            mask="url(#b)"
          ></path>
        </g>
        <path
          className="white"
          fill="#FFF"
          d="M37.88 18.214c2.75 0 4.983 2.215 4.983 4.946 0 2.73-2.233 4.945-4.984 4.945H21.562c-2.748 0-4.984-2.215-4.984-4.945 0-2.73 2.236-4.946 4.984-4.946h16.317zm14.6 13.755c2.748 0 4.984 2.216 4.984 4.946 0 2.73-2.236 4.946-4.984 4.946H21.56c-2.747 0-4.983-2.216-4.983-4.946 0-2.73 2.236-4.946 4.983-4.946h30.92z"
        ></path>
        <path
          className="primary"
          fill="#1460AA"
          stroke="#FFF"
          strokeWidth="2"
          d="M124.25 103.392c0 4.189-3.439 7.577-7.675 7.577h-25.65c-4.235 0-7.675-3.389-7.675-7.577V52.546c0-4.188 3.44-7.577 7.675-7.577h25.65c4.236 0 7.675 3.389 7.675 7.576v50.847z"
        ></path>
        <path
          className="primaryDark"
          fill="#0E4377"
          d="M107.25 102.469c0 1.934-1.791 3.5-4 3.5-2.21 0-4-1.566-4-3.5s1.79-3.5 4-3.5c2.209 0 4 1.566 4 3.5m12-12.021c0 2.497-2.374 4.52-5.306 4.52H93.556c-2.93 0-5.306-2.023-5.306-4.52V55.49c0-2.497 2.377-4.522 5.306-4.522h20.388c2.932 0 5.306 2.025 5.306 4.522v34.957z"
        ></path>
      </g>
    </StyledSVG>
  )
}

export default PairIcon
