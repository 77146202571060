import React, { useState } from 'react'
import Styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../Common/button'
import StatementPreferenceModal from '../AccountSettings/modal/StatementPreference'
import StatementPreferenceLegalModal from '../AccountSettings/modal/StatementPreferenceLegal'
import useWindowSize from '../../Hooks/useWindowSize'
import { trackGoPaperless } from '../../Global/Analytics'
import LeafIcon from '../../assets/leaf-icon'
import {
    STATEMENT_PREFERENCE,
    GO_PAPERLESS,
    STATEMENT_PREFERENCE_LEGAL,
    MULTIFACTOR_SUCCESS,
    CHANGE_EMAIL,
    CONTACT_INFO,
} from './index'
import dayjs from 'dayjs'

const StyledChangeEmail = Styled.div`
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 20px;
    background: var(--Surface, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    margin-top: 32px;
    
    .heading {
      margin: 0;
    }
    
    p {
      color: var(--Text-Subtle, #767676);
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 122.222% */
      margin: 0;
    }
    
    button:not(.textButton){
      border-radius: 4px;
      background: var(--Primary, #074EE8);
      padding: 8px 12px;
    }
    
    .mt-20 {
      margin-top: 20px;
    }
    
    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      margin-left: 35px;
    }
`

const ChangeEmail = ({
    scrollRef,
    currentScreen,
    setCurrentScreen,
    setOpenBanner,
    paperlessIndicator,
    paperlessChangeDate,
    refreshLoanData,
}) => {
    const [deliveryOnline, setDeliveryOnline] = useState(
        paperlessIndicator,
    )
    const { isSmall } = useWindowSize()

    return (
        <>
            {currentScreen === CHANGE_EMAIL || !isSmall ? (
                <StyledChangeEmail id="change_email">
                    <p className="subtitle-2 sub_heading">
                        Card for email...when user’s email in RS does not match email used to register portal account.
                    </p>
                    <div className="d-flex justify-content-between w-100 mt-20">
                        <Button
                            className="skip-for-now"
                            text="Skip for now"
                            textButton
                            UID="change_email_skip_button"
                            onClick={() => setCurrentScreen(CONTACT_INFO)}
                        />
                        <Button
                            className="change-email"
                            text="Change My Email On File"
                            UID="change_email_button"
                        />
                    </div>
                </StyledChangeEmail>
            ) : null}
        </>
    )
}

ChangeEmail.propTypes = {
    scrollRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
    currentScreen: PropTypes.string.isRequired,
    setCurrentScreen: PropTypes.func.isRequired,
    setOpenBanner: PropTypes.func.isRequired,
    paperlessIndicator: PropTypes.bool.isRequired,
    refreshLoanData: PropTypes.func.isRequired,
}

export default ChangeEmail
