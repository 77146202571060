import React from 'react'
import PayoffQuote from '../Components/PayoffQuote'

import BadRequestPage from '../Components/BadRequestPage'
import LoadingPage from '../Components/LoadingPage'
import Title from '../Common/Title'
import { useApiContext } from '../Hooks/useApiContext'
import { extractLoan, extractServicer } from '../Utils/apiExtractors'
import { useRetrievePayOffEligibility } from '../Hooks/useRetrievePayOffEligibility'
import { usePayoffDateEligibility } from '../Hooks/usePayoffDateEligibility'

const PayoffQuoteContainer = () => {
  const { loan } = useApiContext()
  const {
    error: loanError,
    loading: loanLoading,
    data: loanData,
  } = loan

  const {
    error: payoffError,
    loading: payoffLoading,
    data: payoffData,
  } = useRetrievePayOffEligibility()

  const { disallowedDays, maxDate, isMass, isMassAndInvalidDate } =
    usePayoffDateEligibility()

  if (loanLoading || payoffLoading) {
    return <LoadingPage />
  }

  if (loanError || payoffError) {
    return <div>{(loanError || payoffError).message}</div>
  }

  const servicer = extractServicer(loanData)
  let clientRefiInfo = servicer && servicer.refiContactDetails

  const loanResponse = extractLoan(loanData)
  const loanNumber = loanResponse.loanId

  return !isMassAndInvalidDate ? (
    <>
      <Title>Payoff Quote</Title>
      <PayoffQuote
        loanNumber={loanNumber}
        clientRefiInfo={clientRefiInfo}
        disallowedDays={disallowedDays}
        maxDate={maxDate}
        isMass={isMass}
        eligible={payoffData.eligible}
      />
    </>
  ) : (
    <BadRequestPage error="Unauthorized" />
  )
}

export default PayoffQuoteContainer