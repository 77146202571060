export const useFocuses = (refComponentObjectArr) => {
  return {
    focus: (key) => {
      const refComponent = refComponentObjectArr.find(
        (x) => x.key.toLowerCase() === key.toLowerCase(),
      ).ref
      refComponent.current.focus()
      document.activeElement.blur()
      setTimeout(() => {
        refComponent.current.focus()
      }, 50)
    },
  }
}
