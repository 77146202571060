import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'

import CommonQuestionCategories from './CommonQuestionCategories'

const Spouses = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.Spouses, title)
  })

  return (
    <>
      <h3 ref={title} id="spouses" tabIndex="-1">
        Spouses
      </h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Can I add my spouse to my Reverse Mortgage?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              Once your loan is closed, it is not possible to simply
              “add” another borrower to the loan. This would require
              the loan be refinanced as all borrowers must be
              "applicants" for the loan, attend counseling as required
              and thus would be obligated to the debt. If you wish to
              add someone to the title of the property (i.e., another
              "owner" of the property), you should consult an attorney
              or title company to do so. Important: At least one
              borrower must ALWAYS remain on title to the property to
              avoid a maturity event resulting in the loan being
              called due and payable for title transfer.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default Spouses
