import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const SignOutIcon = ({ color = 'primary' }) => {
  return (
    <StyledSVG color={color} fill="none" width="18" height="23">
      <path
        fill="#000"
        d="M2 2h6c.55 0 1-.45 1-1s-.45-1-1-1H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h6c.55 0 1-.45 1-1s-.45-1-1-1H2V2z"
      ></path>
      <path
        fill="#000"
        d="M17.65 8.65l-2.79-2.79a.501.501 0 00-.86.35V8H7c-.55 0-1 .45-1 1s.45 1 1 1h7v1.79c0 .45.54.67.85.35l2.79-2.79c.2-.19.2-.51.01-.7z"
      ></path>
    </StyledSVG>
  )
}

export default SignOutIcon
