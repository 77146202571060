import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import CurrencyInput from 'react-currency-input-field'

import MoneyHandIcon from '../../assets/money-hand-icon.js'
import { numberToMoney } from '../../Utils/utils'
import useWindowSize from '../../Hooks/useWindowSize'
import ScrollToTop from '../../Global/ScrollToTop'
import Button from '../../Common/button'
import CancelModal from '../../Common/CancelModal'
import { buildTestID } from '../../Utils/utils'
import {
  trackLOCRequest,
  trackTooltipInfoViewed,
} from '../../Global/Analytics'

import { EQUITY_ELITE, HOMESAFE, REVIEW_REQUEST, SUBMIT_REQUEST } from './index'

const StyledCompleteForm = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0 40px;

  .max_amount_box {
    width: 500px;
    margin: 0 auto;
    background: rgba(0, 131, 143, 0.06);
    border: 1px solid ${({ theme }) => theme.colors.black12};
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    text-align: left;

    svg {
      width: 56.27px;
      height: 63.69px;
      margin: 20px 20px 55px;
    }

    .max_amount_info {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-right: 15px;
      width: 380px;

      .max_amount_dollars {
        font-weight: bold;
        font-size: 24px;
        line-height: 25px;
        letter-spacing: 0.15px;
      }
      .max_amount_tooltip {
        margin: 40px auto 34px;
        text-align: center;
        background: ${({ theme }) => theme.colors.white};
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        letter-spacing: 0.5px;
        text-decoration-line: underline;
        text-transform: capitalize;
        margin: 0;
        background-color: rgba(0, 0, 0, 0);
        width: 172px;
        padding: 0;
      }
    }
  }

  .loc_amount {
    width: 418px;
    margin: 40px auto 0 auto;

    p {
      text-align: left;
    }

    .draw_amount_input {
      box-shadow: none;
      font-size: 20px;
      line-height: 24px;
      padding: 16px;
      width: 418px;
      height: 56px;
      border: 1px solid ${({ theme }) => theme.colors.black};
      border-radius: 4px;

      ::placeholder {
        color: ${({ theme }) => theme.colors.black};
      }
    }

    .draw_error_input {
      border: 2px solid ${({ theme }) => theme.colors.error};
    }

    .draw_error_text {
      /* position: absolute; */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: ${({ theme }) => theme.colors.error};
      margin: 8px 28px 0 0;
    }

    .draw_warning_text {
      /* position: absolute; */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      /* color: ${({ theme }) => theme.colors.error}; */
      margin: 8px 28px 0 0;
    }
  }

  .break_line {
    width: 100%;
    border: 0.5px solid ${({ theme }) => theme.colors.black12};
  }

  .card_buttons {
    display: flex;
    justify-content: space-between;
    margin: 32px 0;
  }

  .max_amount_button {
    margin: 48px 0;
  }

  .max-amount-text {
    font-size: 16px;
    width: 380px;

    > a {
      text-transform: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 32px 16px;

    .screen_title {
      margin: 0;
    }

    .max_amount_box {
      width: unset;
      margin: 32px 0;
    }

    .max-amount-text {
      width: unset;
    }

    .loc_amount {
      width: 100%;
      margin: 0;

      .draw_amount_input {
        width: 100%;
      }
    }

    .card_buttons {
      margin: 32px 0 0 0;
    }
  }

  .max_amount_button {
    margin: 48px 0 34px 0;
  }

  @media (max-width: 420px) {
    svg {
      display: none;
    }

    .max_amount_info {
      margin-left: 24px;
    }
  }

  .why-this-amount{
    font-size: 16px;
  }
`

const CompleteForm = ({
  setCurrentScreen,
  maxAmount,
  drawAmount,
  setDrawAmount,
  pendingTransactionAmountDraw,
  userVerified,
  loanType,
}) => {
  const [drawError, setDrawError] = useState('')
  const [drawWarning, setDrawWarning] = useState('')
  const [modalShow, setModalShow] = useState(false)

  const { isMobile } = useWindowSize()

  const validInputAmount =
    parseInt(drawAmount.toString().replace(/,/g, '') * 100) > 0 &&
    parseInt(drawAmount.toString().replace(/,/g, '') * 100) <=
    parseInt(maxAmount * 100) &&
    String(drawAmount).length < 12 &&
    drawError === ''

  const handleValueChanage = (value) => {
    if (value === undefined) value = ''

    if (
      Number(value.replace(/[^0-9.]/g, '')) >
      Number(maxAmount.toString().replace(/[^0-9.]/g, ''))
    ) {
      setDrawError(
       `You must enter an amount that is less than or equal to ${numberToMoney(maxAmount)}.`,
      )
    }    
    else if ((loanType === HOMESAFE || loanType === EQUITY_ELITE) && Number(value.replace(/[^0-9.]/g, '')) < 500) {
      setDrawError(
        `You must enter an amount that is greater than or equal to $500.00`,
       )
    } 
    else {
      setDrawError('')
    }
    
    if ((loanType === HOMESAFE || loanType === EQUITY_ELITE) && ((Number(maxAmount.toString().replace(/[^0-9.]/g, '')) - Number(value.replace(/[^0-9.]/g, ''))) < 500)) {
      setDrawWarning(
        `The amount you are requesting will take your remaining Line of Credit below $500. The minimum amount that can be drawn on this loan type is $500, per the loan note`,
       )
    }  else {
      setDrawWarning('')
    }

    setDrawAmount(value)
  }

  const handleSubmit = () => {
    trackLOCRequest.StepTwo()
    return validInputAmount
      ? userVerified
        ? setCurrentScreen(SUBMIT_REQUEST)
        : setCurrentScreen(REVIEW_REQUEST)
      : () => { }
  }

  return (
    <>
      <ScrollToTop />
      <CancelModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <StyledCompleteForm data-testid="complete-form">
        <h6 className="screen_title">
          Request a Line of Credit Advance
        </h6>
        <div className="max_amount_box">
          <MoneyHandIcon color="secondary" />
          <div className="max_amount_info">
            <p className="caption">Maximum Amount Available to Request Online</p>
            <p
              className="max_amount_dollars"
              data-testid={buildTestID(
                'max_amount',
                '/loc_advance',
                'complete_form',
              )}
            >
              {numberToMoney(maxAmount)}
            </p>
            <p className="max-amount-text">If the maximum amount available to request online is less than your total line of credit and you wish to request more, you must submit a signed LOC request form. Click <a href="./forms/2024/Line_Of_Credit_Draw_Request.pdf" data-testid={buildTestID('loc_form', '/advance-request', 'loc_form_link')}>here</a> for the LOC request form and follow the instructions on the form.</p>
            <p className="why-this-amount">{
        pendingTransactionAmountDraw ? (`There are pending transaction(s) totaling $${pendingTransactionAmountDraw} on your account. This amount may not take into consideration any funds that are limited due to restrictions. All requests are subject to review before funds are disbursed to you.`)
          : ("This amount may not take into consideration any funds that are limited due to restrictions or pending transactions on your account. All requests are subject to review before funds are disbursed to you.")
      }</p>
          </div>
        </div>
        <div className="loc_amount">
          <p className="subtitle-2">Line of Credit Advance Amount</p>
          {(loanType === HOMESAFE || loanType === EQUITY_ELITE) && <p className='small'><small><i>Your Loan Type requires a minimum $500.00 draw</i></small></p>}
          <CurrencyInput
            className={`draw_amount_input ${drawError ? 'draw_error_input' : ''
              }`}
            value={drawAmount}
            autoComplete="off"
            onValueChange={handleValueChanage}
            prefix="$"
            placeholder="$0.00"
            decimalsLimit={2}
            data-testid={buildTestID(
              'currency',
              '/advance_request',
              'complete_form',
            )}
          />
          <p
            className="draw_error_text"
            data-testid={buildTestID(
              'error_text',
              '/advance_request',
              'complete_form',
            )}
          >
            {drawError ? drawError : ''}
          </p>
          <p
            className="draw_warning_text"
            data-testid={buildTestID(
              'draw_warning_text',
              '/advance_request',
              'complete_form',
            )}
          >
            {drawWarning ? drawWarning : ''}
          </p>
          <Button
            className="max_amount_button"
            onClick={() => {
              setDrawError('')
              setDrawAmount(String(Number(maxAmount).toFixed(2)))
            }}
            textButton
            text="Request Max Amount Available"
            UID="complete_form"
          />
        </div>
        <div className="break_line"></div>
        <div className="card_buttons">
          <Button
            onClick={() => setModalShow(true)}
            text="Cancel"
            outlined
            disableFocus
            UID="complete_form"
          />
          <Button
            onClick={handleSubmit}
            text="Continue"
            disabled={!validInputAmount}
            UID="complete_form"
          />
        </div>
      </StyledCompleteForm>
    </>
  )
}

CompleteForm.propTypes = {
  setCurrentScreen: PropTypes.func.isRequired,
  maxAmount: PropTypes.string.isRequired,
  drawAmount: PropTypes.string.isRequired,
  setDrawAmount: PropTypes.func.isRequired,
  userVerified: PropTypes.bool.isRequired,
}

export default CompleteForm
