import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { buildTestID } from '../../Utils/utils'

import CommonQuestionCategories from './CommonQuestionCategories'

const RequestingFunds = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.RequestingFunds, title)
  })

  return (
    <>
      <h3 ref={title} id="requesting-funds" tabIndex="-1">Requesting Funds</h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              What is a Line of Credit (LOC) Advance?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {' '}
              Unscheduled payments or in installments, at times and in
              an amount of your choosing, until the line of credit is
              exhausted. If your loan has an established line of
              credit, you may request an advance to borrow any
              available funds as needed. Interest is not charged on
              funds in a line of credit until they are disbursed to
              and added to your loan’s outstanding balance.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="how-do-i-withdraw-from-loc">
          <Card.Header>
            <CustomToggle eventKey="1">
              How do I request an advance from my line of credit
              (LOC)?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {' '}
              If your loan has an established line of credit, you may
              request funds from your Line of Credit by <a href="/" target="_blank" data-testid={buildTestID('how_do_i_request_an_advance', '/withdrawing_funds', 'log_on')}>logging into
                this website</a> where you can request an advance securely
              online. Otherwise, you may fax or mail a request using
              the fax number or mailing address on the LOC form
              provided to you with your monthly account statement. All
              requests for funds will be validated and, provided funds
              are available and the request is approved, we will
              disburse the funds to you within 5 business days of
              receipt.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="2">
              Do both or all borrowers need to sign the line of credit
              advance request?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              {' '}
              No, only one borrower signature is required.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              Can an attorney-in-fact who has power of attorney sign
              the Line of Credit Advance Request form for the
              borrower(s)?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              {' '}
              {`Yes, assuming adequate proof of the requestor’s authority has been submitted, the attorney-in-fact should sign as follows: POA should sign their name to match signature on file.`}
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              Will I get a checkbook?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              {' '}
              No, there is no checkbook available for access to line
              of credit funds.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card id="how-long-before-i-get-money">
          <Card.Header>
            <CustomToggle eventKey="5">
              How long before I get my money?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              {' '}
              Upon receipt and validation of your request for funds, a
              payment will be processed via ACH (if EFT/Direct Deposit
              has been established) or mailed within five (5) business
              days of receipt.{' '}
              <strong>
                We encourage you to setup EFT/Direct Deposit for the
                fastest and safest delivery method of your funds. It’s
                simple – just complete the{' '}
                Electronic Funds Transfer (EFT) / Direct Deposit Agreement
                (login to your account to get this form).
              </strong>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
              Can I have my LOC funds sent directly to a third party?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              {' '}
              Funds can be sent to an account that has other parties not listed on the loan but the bank account must list at least one borrower/or authorized user (POA, Guardian, or Conservator) on the bank account. To set up direct deposit, all borrower/or authorized users (POA, Guardian, or Conservator) on the loan must sign the Electronic Funds Transfer / Direct Deposit Form acknowledging that any additional person(s) listed on the bank account have access to the funds deposited to this bank account. If no direct deposit/ACH is setup for advances, checks will only be made payable to and mailed to the borrower(s) on the loan and not to a third party.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="7">
              What is an Initial Disbursement Limit (IDL) or first
              year LOC restriction?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              Please note that on HECM loans insured by FHA, HUD may have placed restrictions regarding how much you are able to access from your reverse mortgage during the first year – known as the “Initial Disbursement Limit.” If applicable for your loan, you will not be able to access any funds beyond that first-year limitation – which you may see listed on your monthly statement as “First Year Funds Available” and “First Year Line of Credit Available.” These figures may be different. The "First Year Line of Credit Available" is the amount available for you to draw from your LOC in the first year. Once your loan has reached its one-year anniversary date and, assuming your loan is current and not in default, those limitations will no longer apply, and you will have access to all remaining funds from your reverse mortgage.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default RequestingFunds
