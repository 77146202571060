import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import CheckIcon from '../../assets/check-icon.js'
import {
  COMPLETE_FORM,
  REVIEW_REQUEST,
  VERIFY_ACCOUNT,
  ENTER_VERIFY_CODE,
  SUBMIT_REQUEST,
  REQUEST_SUBMITTED,
} from './index.js'

const StyledStepper = styled.div`
  padding: 48px 0 32px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .status_item {
    display: flex;
    flex-direction: column;

    div {
      text-align: center;
    }

    .circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 16px;
      line-height: 35px;
      text-align: center;
      letter-spacing: 0.4px;
      margin: 0 auto;
      margin-bottom: 30px;
      background: ${({ theme }) => theme.colors.black12};
      border: 2px solid rgba(0, 0, 0, 0.01);
    }

    .in_progress {
      background: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.primaryDark};
    }

    .completed {
      background: ${({ theme }) => theme.colors.primaryDark};
      border: 1px solid ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .active {
    color: ${({ theme }) => theme.colors.primaryDark};
    font-weight: bold;
  }

  .line {
    width: 100px;
    height: 0px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    margin: 20px -24px 0 -24px;
  }

  .check_icon {
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    width: 100%;
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .line {
      width: 30px;
      margin: 20px -12px 0 -12px;
    }

    .status_text {
      display: none;
    }
  }
`

const Stepper = ({ currentScreen }) => {
  return (
    <StyledStepper data-testid="stepper">
      {currentScreen === COMPLETE_FORM ? (
        <>
          <div className="status_item complete-form active">
            <div className="circle in_progress">1</div>
            <div className="status_text">Complete Form</div>
          </div>
          <div className="line" />
          <div className="status_item review-request">
            {' '}
            <div className="circle ">2</div>
            <div className="status_text">Review Request</div>
          </div>
          <div className="line" />
          <div className="status_item verify-account">
            {' '}
            <div className="circle">3</div>
            <div className="status_text">Verify Account</div>
          </div>
          <div className="line" />
          <div className="status_item submit-request">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">Submit Request</div>
          </div>{' '}
        </>
      ) : (
        ''
      )}

      {currentScreen === REVIEW_REQUEST ? (
        <>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Complete Form</div>
          </div>
          <div className="line" />
          <div className="status_item review-request active">
            {' '}
            <div className="circle in_progress">2</div>
            <div className="status_text">Review Request</div>
          </div>
          <div className="line" />
          <div className="status_item verify-account">
            {' '}
            <div className="circle">3</div>
            <div className="status_text">Verify Account</div>
          </div>
          <div className="line" />
          <div className="status_item submit-request">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">Submit Request</div>
          </div>{' '}
        </>
      ) : (
        ''
      )}

      {currentScreen === VERIFY_ACCOUNT ||
      currentScreen === ENTER_VERIFY_CODE ? (
        <>
          <div className="status_item complete-form ">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Complete Form</div>
          </div>
          <div className="line" />
          <div className="status_item review-request">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Review Request</div>
          </div>
          <div className="line" />
          <div className="status_item verify-account active">
            {' '}
            <div className="circle in_progress">3</div>
            <div className="status_text">Verify Account</div>
          </div>
          <div className="line" />
          <div className="status_item submit-request">
            {' '}
            <div className="circle">4</div>
            <div className="status_text">Submit Request</div>
          </div>
        </>
      ) : (
        ''
      )}

      {currentScreen === SUBMIT_REQUEST ? (
        <>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Complete Form</div>
          </div>
          <div className="line" />
          <div className="status_item review-request">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Review Request</div>
          </div>
          <div className="line" />
          <div className="status_item verify-account">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Verify Account</div>
          </div>
          <div className="line" />
          <div className="status_item submit-request active">
            {' '}
            <div className="circle in_progress">4</div>
            <div className="status_text">Submit Request</div>
          </div>
        </>
      ) : (
        ''
      )}

      {currentScreen === REQUEST_SUBMITTED ? (
        <>
          <div className="status_item complete-form">
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Complete Form</div>
          </div>
          <div className="line" />
          <div className="status_item review-request">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Review Request</div>
          </div>
          <div className="line" />
          <div className="status_item verify-account">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>
            <div className="status_text">Verify Account</div>
          </div>
          <div className="line" />
          <div className="status_item submit-request active">
            {' '}
            <div className="circle completed">
              <CheckIcon className="check_icon" />
            </div>{' '}
            <div className="status_text">Submit Request</div>
          </div>
        </>
      ) : (
        ''
      )}
    </StyledStepper>
  )
}

Stepper.propTypes = {
  currentScreen: PropTypes.oneOf([
    'COMPLETE_FORM',
    'REVIEW_REQUEST',
    'VERIFY_ACCOUNT',
    'ENTER_VERIFY_CODE',
    'SUBMIT_REQUEST',
    'REQUEST_SUBMITTED',
  ]).isRequired,
}

export default Stepper
