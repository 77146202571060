import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Table } from 'react-bootstrap'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import AlertIcon from '../../assets/alert-icon.js'
import ErrorIcon from '../../assets/error-icon.js'
import CheckIcon from '../../assets/check-circle-icon.js'
import OutlinedAlertIcon from '../../assets/outlined-alert-icon.js'
import NoRequestsIcon from '../../assets/no-requests-icon.js'
import BankIcon from '../../assets/bank-icon.js'
import WalletIcon from '../../assets/wallet-icon.js'

import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import useWindowSize from '../../Hooks/useWindowSize'
import { numberToMoney } from '../../Utils/utils'
import Button from '../../Common/button'
import { useLOCAdvancePDF } from '../../Hooks/useLOCAdvancePDF'
import BannerBar from '../../Common/BannerBar.js'
import InfoCard from '../../Common/InfoCard.js'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const StyledMyAdvanceRequests = styled.div`
  background: none;
  border: none;
  margin: 0 0 80px 0;

  .banner {
    margin: 0 0 32px 0;
  }

  .top_title {
    margin: 40px auto;
    text-align: center;

    h5 {
      margin-bottom: 32px;
    }
  }

  th {
    color: ${({ theme }) => theme.colors.black60};
  }

  .status_item {
    padding: 6px 10px;
    border-radius: 300px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 8px;
    width: fit-content;

    svg {
      min-width: 18px;
    }

    div {
      margin-left: 10px;
    }
  }

  .status_item_pending {
    background: ${({ theme }) => theme.colors.alertBg};
  }

  .status_item_not_processed {
    background: ${({ theme }) => theme.colors.alertBg};
  }

  .status_item_complete {
    background: ${({ theme }) => theme.colors.successBg};
  }

  .no_requests_block {
    text-align: center;

    .no_loc_requests_inner_block {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 56px 0;
      margin: 32px auto 260px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    .no_requests_title {
      margin-top: 18px;
      margin-bottom: 16px;
    }

    .no_requests_text {
      margin-bottom: 24px;
    }
  }
  .loc_requests_block {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-bottom: 32px;
    padding: 24px;

    h6 {
      margin-top: 0;
    }

    td {
      padding-top: 20px;
    }

    .td_stacked_text {
      padding-top: 10px;
    }

    th {
      font-weight: normal;
      border-top: none;
      padding-bottom: 12px;
      vertical-align: bottom;
    }

    tr {
      border-bottom: 1px solid ${({ theme }) => theme.colors.black12};
    }

    .status_column {
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .alert_icon {
      height: 16px;
      width: 16px;
      background: ${({ theme }) => theme.colors.alert};
      color: ${({ theme }) => theme.colors.alertBg};
    }

    .disclaimer {
      display: flex;
      flex-direction: row;

      .grey_alert_icon {
        height: 26px;
        min-width: 26px;
        margin-top: 16px;
        margin-right: 12px;
      }

      p {
        margin-top: 14px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding: 32px 0 0 0;

    .mobile_view {
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      margin-top: 0;
      padding-left: 16px;
    }

    .top_title {
      margin: 0 0 34px 0;
      padding: 0 40px;

      h5 {
        margin: 0;
        font-size: 24px;
      }
    }

    .bottom_line {
      width: 100%;
      border: 0.5px solid ${({ theme }) => theme.colors.black12};
    }

    .mobile_request_block {
      padding: 4px 0;

      .mobile_row {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 12px 16px 12px 0;
      }
    }

    .mobile_disbursement {
      text-align: right;
    }

    .alert_icon {
      // Error on safari with icon size needs mins
      min-height: 20px;
      min-width: 20px;
      height: 20px;
      width: 20px;
    }

    .grey_alert_icon {
      margin-right: 12px;
    }

    .top_block {
      padding: 24px 14px 24px 0;
      text-align: start;

      h2 {
        font-size: 20px;
        margin: 0;
      }
    }

    .bottom_blocks {
      display: flex;
      justify-content: center;
      padding: 20px 22px 20px 0;

      svg {
        min-width: 20px;
      }
    }
  }
`
const incrementalMobileReqCount = 3

const MyAdvanceRequests = ({ locRequests, eligibility }) => {
  const [requestsInView, setRequestsInView] = useState(
    incrementalMobileReqCount,
  )
  const [locAdvanceToDownload, setLocAdvanceToDownload] = useState({})

  const { downloadLOCAdvancePDF } = useLOCAdvancePDF(
    locAdvanceToDownload,
  )

  // downloads PDF on state change
  // work around as hooks can not be used within callbacks
  useEffect(() => {
    if (locAdvanceToDownload.payType) {
      downloadLOCAdvancePDF()
      setLocAdvanceToDownload({})
    }
  }, [locAdvanceToDownload, downloadLOCAdvancePDF])

  const { isSmall } = useWindowSize()

  const rejects = locRequests.filter(
    (request) => request.request_status === 'Rejected',
  )

  const sortedLocRequests = locRequests.length
    ? locRequests.sort((a, b) => {
      let date1 = dayjs(a.request_datetime)
      let date2 = dayjs(b.request_datetime)
      return date2.diff(date1)
    })
    : []

  const normalizedLocRequests = sortedLocRequests.map((req) => {
    let date, time

    // fallback as ie does not have full time zone support
    if (window.document.documentMode) {
      date = dayjs(
        req.request_datetime,
        'YYYY-MM-DDTHH:mm:ss.000ZZ',
      ).format('MM/DD/YYYY')

      time =
        dayjs(
          req.request_datetime,
          'YYYY-MM-DDTHH:mm:ss.000ZZ',
        ).format('h:mma') + ' (local)'
    } else {
      date = dayjs(req.request_datetime, 'YYYY-MM-DDTHH:mm:ss.000ZZ')
        .tz('America/New_York')
        .format('MM/DD/YYYY')

      time = dayjs(req.request_datetime, 'YYYY-MM-DDTHH:mm:ss.000ZZ')
        .tz('America/New_York')
        .format('h:mma ET')
    }

    const payTypeShortText = req.payType.includes('Direct')
      ? 'Direct Deposit'
      : 'Check mailed via USPS'

    const payTypeLongText = req.payType.includes('Direct')
      ? req.payType
      : 'Check mailed via USPS'

    return {
      RequestID: req.request_id,
      drawAmount: numberToMoney(req.draw_request_amount),
      date,
      time,
      payTypeShortText,
      submissionType: req.submission_type,
      payType: payTypeLongText,
      DateTimeOfReq: `${date} ${time}`,
      reqStatus: req.request_status,
    }
  })

  const showMobileViewReqs = isSmall && !!locRequests.length
  const showDestopTableReqs = !isSmall && !!locRequests.length
  const showNoRequestsCard = !locRequests.length

  const getStatusChip = (status) => {
    if (status === 'Processed' || status === 'Completed') {
      return (
        <div className="status_item status_item_complete">
          <CheckIcon />
          <div className="caption">Processed</div>
        </div>
      )
    } else if (status === 'Pending') {
      return (
        <div className="status_item status_item_pending">
          <AlertIcon />
          <div className="caption">Pending Verification</div>
        </div>
      )
    } else if (status === 'Rejected') {
      return (
        <div className="status_item status_item_not_processed">
          <ErrorIcon />
          <div className="caption">Could Not Be Processed</div>
        </div>
      )
    }
  }

  return (
    <StyleGuide>
      <StyledMyAdvanceRequests>
        <Grid>
          <div
            className="top_title"
            data-gridcolumnlayout={{
              xl: '3/13',
              lg: '2/12',
              md: '1/13',
            }}
          >
            <h5
              className={`bold ${isSmall ? 'heading-6' : 'heading-5'
                }`}
            >
              My Line of Credit Advance Requests
            </h5>
            {!isSmall && (
              <p className="caption">
                Only the advance requests submitted in the last 30 days are shown here.
              </p>
            )}
          </div>
          <BannerBar
            show={!!rejects.length}
            type="info"
            data-gridcolumnlayout={{
              xl: '3/13',
              lg: '2/12',
              md: '1/13',
            }}
            className="banner"
            text={
              isSmall
                ? 'Please call Borrower Care to complete this process at 866-654-0020.'
                : 'Explanation letter has been sent. Please call Borrower Care at 866-654-0020 for more information.'
            }
          />
          {showMobileViewReqs && (
            <>
              <div className="mobile_view">
                <div className="top_block">
                  <h2 className="subtitle bold">
                    Pending Line of Credit Advance Requests
                  </h2>
                </div>
                {normalizedLocRequests
                  .slice(0, requestsInView)
                  .map((req) => {
                    return (
                      <div key={req.RequestID}>
                        <div className="bottom_line bottom_line-requests" />
                        <div className="mobile_request_block">
                          <div className="mobile_row">
                            <div>
                              Date &
                              <br />
                              Received
                            </div>
                            <div>
                              {req.date} at {req.time}
                            </div>
                          </div>
                          <div className="mobile_row">
                            <div>Amount</div>
                            <div>{req.drawAmount}</div>
                          </div>
                          <div className="mobile_row">
                            <div>Status</div>
                            {getStatusChip(req.reqStatus)}
                          </div>
                          {req.reqStatus !== 'Rejected' && (
                            <div className="mobile_row">
                              <div>
                                Disbursement
                                <br /> Method
                              </div>
                              <div className="mobile_disbursement">
                                {req.payTypeShortText}
                              </div>
                            </div>
                          )}
                          {req.reqStatus !== 'Rejected' && (
                            <div className="mobile_row">
                              <div>
                                Submission
                                <br /> Type
                              </div>
                              <div className="mobile_disbursement">
                                {req.submissionType}
                              </div>
                            </div>
                          )}
                          {req.reqStatus !== 'Rejected' && (
                            <div className="mobile_row">
                              <div>Request Receipt</div>
                              <div className="mobile_disbursement">
                                <Button
                                  textButton
                                  text="Download"
                                  onClick={() =>
                                    setLocAdvanceToDownload(req)
                                  }
                                  UID={req.RequestID}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                <div className="bottom_line" />
                <div className="bottom_blocks">
                  <OutlinedAlertIcon className="grey_alert_icon" />
                  <p className="caption">
                    Please allow five business days to disburse funds
                    to you based on your request. All requests are
                    subject to review before processing.
                  </p>
                </div>
                <div className="bottom_line" />
                <div />
                <div className="bottom_blocks">
                  {locRequests.length > incrementalMobileReqCount &&
                    (locRequests.length > requestsInView ? (
                      <Button
                        text="View More"
                        onClick={() =>
                          setRequestsInView(
                            requestsInView +
                            incrementalMobileReqCount,
                          )
                        }
                        UID="my_advance_requests"
                      />
                    ) : (
                      <Button
                        onClick={() => {
                          setTimeout(
                            () =>
                              window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                              }),
                            200,
                          )
                          setRequestsInView(incrementalMobileReqCount)
                        }}
                        text="View Less"
                        UID="my_advance_requests"
                      />
                    ))}
                </div>
              </div>
              <InfoCard
                SVG={WalletIcon}
                title="What if I need to make changes to the amount?"
                paragraph="To make changes or request additional funds, 
                please call Borrower Care at 866-654-0020."
              />
              <InfoCard
                SVG={BankIcon}
                title="When will my request be processed?"
                paragraph="A Line of Credit advance request submitted by 5pm
                ET on a business day will begin processing on the
                same day and will be disbursed within 5 business
                days in accordance with your established preferred
                method of payment. Requests that are submitted
                after 5pm ET, or on a weekend or federal holiday,
                will begin processing on the next business day."
              />
            </>
          )}
          {showDestopTableReqs && (
            <div
              data-gridcolumnlayout={{
                xl: '3/13',
                lg: '2/12',
                md: '1/13',
              }}
            >
              <div className="loc_requests_block">
                <Table size="sm" className="caption">
                  <tbody>
                    <tr>
                      <th>
                        Date &
                        <br />
                        Time Received
                      </th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>
                        Disbursement
                        <br />
                        Method
                      </th>
                      <th>
                        Submission
                        <br />
                        Type
                      </th>
                      <th>
                        Request
                        <br />
                        Receipt
                      </th>
                    </tr>
                    {normalizedLocRequests.map((req) => {
                      return (
                        <tr key={req.RequestID}>
                          <td className="td_stacked_text">
                            {req.date}
                            <br />
                            at {req.time}
                          </td>
                          <td>{req.drawAmount}</td>
                          <td className="status_column">
                            {getStatusChip(req.reqStatus)}
                          </td>
                          {req.reqStatus !== 'Rejected' && (
                            <td>{req.payTypeShortText}</td>
                          )}
                          {req.reqStatus !== 'Rejected' && (
                            <td>{req.submissionType}</td>
                          )}
                          {req.reqStatus !== 'Rejected' && (
                            <td>
                              <Button
                                textButton
                                text="Download"
                                onClick={() =>
                                  setLocAdvanceToDownload(req)
                                }
                                UID={req.RequestID}
                              />
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <div className="disclaimer">
                  <OutlinedAlertIcon className="grey_alert_icon" />
                  <p className="caption">
                    Please allow five business days to disburse funds
                    to you based on your request. All requests are
                    subject to review before processing.
                  </p>
                </div>
              </div>
              <InfoCard
                SVG={WalletIcon}
                title="What if I need to make changes to the amount?"
                paragraph="To make changes or request additional funds, 
                please call Borrower Care at 866-654-0020."
              />
              <InfoCard
                SVG={BankIcon}
                title="When will my request be processed?"
                paragraph="A Line of Credit advance request submitted by 5pm
                    ET on a business day will begin processing on the
                    same day and will be disbursed within 5 business
                    days in accordance with your established preferred
                    method of payment. Requests that are submitted
                    after 5pm ET, or on a weekend or federal holiday,
                    will begin processing on the next business day."
              />
            </div>
          )}
          {showNoRequestsCard && (
            <div className="no_requests_block">
              {' '}
              <div className="no_loc_requests_inner_block">
                <NoRequestsIcon />
                <h6 className="no_requests_title">No requests yet</h6>
                <p className="caption no_requests_text">
                  You can come back here once you’ve submitted a{' '}
                  <br />
                  Line of Credit Advance request.
                </p>
                {eligibility.eligible && (
                  <Button
                    text="Request Advance"
                    linkTo="/advance-request"
                    UID="my_advance_requests"
                  />
                )}
              </div>
            </div>
          )}
        </Grid>
      </StyledMyAdvanceRequests>
    </StyleGuide>
  )
}

MyAdvanceRequests.propTypes = {
  locRequests: PropTypes.arrayOf(
    PropTypes.shape({
      request_id: PropTypes.string.isRequired,
      request_datetime: PropTypes.string.isRequired,
      draw_request_amount: PropTypes.number.isRequired,
      request_status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  eligibility: PropTypes.exact({
    eligible: PropTypes.bool.isRequired,
    history: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
  }).isRequired,
}

export default MyAdvanceRequests
