import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const PayByPhoneIcon = () => {
    return (
        <StyledSVG
            width="110" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="68" cy="68" r="68" fill="#F8FBFF"/>
              <path d="M90.25 77.3889C86.8333 77.3889 83.5278 76.8333 80.4444 75.8333C79.4722 75.5 78.3889 75.75 77.6389 76.5L73.2778 81.9722C65.4167 78.2222 58.0556 71.1389 54.1389 63L59.5556 58.3889C60.3056 57.6111 60.5278 56.5278 60.2222 55.5556C59.1944 52.4722 58.6667 49.1667 58.6667 45.75C58.6667 44.25 57.4167 43 55.9167 43H46.3056C44.8056 43 43 43.6667 43 45.75C43 71.5556 64.4722 93 90.25 93C92.2222 93 93 91.25 93 89.7222V80.1389C93 78.6389 91.75 77.3889 90.25 77.3889Z" fill="#475468"/>

        </StyledSVG>
    )
}

export default PayByPhoneIcon
