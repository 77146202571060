import React, { useRef } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Button from '../Common/button'
import useWindowSize from '../Hooks/useWindowSize'
import VideoJS from '../Components/VideoJS'
import { trackVideoPlayed } from '../Global/Analytics'

const StyledRegistrationModal = styled(Modal)`
  .header {
    margin: 32px 32px 20px 32px;
    text-align: left;
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: ${({ theme }) => theme.colors.black87};
  }

  .body {
    padding: 16px;
    margin: auto;
  }

  .body-text {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.black87};
  }

  .buttons {
    .modal-close {
        justify-content: right;
        margin-right: 16px;
        margin-bottom: 16px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    .buttons {
        .modal-close {
            margin-right: 84.5px;
        }
      }
 }
`

const RegistrationModal = ({ onHide, show, page }) => {
    const { isSmall } = useWindowSize()

    let videoRef = useRef(null)

    const width = isSmall ? 375 : 565
    const height = isSmall ? 211 : 318

    const manageAccountOptions = {
        title: "Manage your account online video",
        autoplay: false,
        controls: true,
        width: isSmall ? width : 565,
        height: height,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [{
            src: './assets/celink.mp4',
            type: 'video/mp4'
        }],
        track: {
            src: './assets/text-tracks/celink-manage-your-account-english.vtt',
        }
    }

    const handlePlayerReady = (player) => {
        videoRef.current = player;
    };

    return (
        <StyledRegistrationModal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="header">

            </div>
            <div className="body">
                <figure data-gridcolumnlayout={{
                    sm: '1/12',
                    md: '2/12',
                    lg: '2/14',
                    xl: '2/14',
                }}>
                    <VideoJS options={manageAccountOptions} onReady={handlePlayerReady} onPlay={() => trackVideoPlayed.WatchedVideo("manage your account online video", "helpful resources")} />
                    <figcaption>
                        <p className='text-manage-online'>Manage Your Online Account</p>
                        <p className='text-learn'>Learn how to create and manage your Reverse Mortgage online account.</p>
                    </figcaption>
                </figure>
            </div>
            <div className="buttons">
                <Button
                    className='modal-close'
                    onClick={onHide}
                    textButton
                    text="CLOSE"
                    UID="registration_modal"
                />
            </div>
        </StyledRegistrationModal>
    )
}

RegistrationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}

export default RegistrationModal
