import React, { } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import OutlinedAlertIcon from '../../assets/outlined-alert-icon'
import HaveQuestionsBottomBar from '../../Common/HaveQuestionsBottomBar'
import useWindowSize from '../../Hooks/useWindowSize'

const StyledLoanDetailsSection = styled.div`
  padding: 32px;
  margin: 32px 0 0 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};

  .heading-6 {
    margin: 0 0 24px 0;
  }

  .body-1 {
    text-align: start;
    margin-right: 8px;
  }

  .loan_detail_row {
    display: flex;
    justify-content: space-between;
  }

  .grey-alert-icon {
    margin-top: -8px;
    margin-left: 4px;
  }

  .disclaimer_row {
    display: flex;
    margin-top: 12px;

    svg {
      min-width: 20px;
    }

    .caption {
      font-style: italic;
      margin: 0 0 0 8px;
    }
  }

  h3 {
    margin: 0;
  }

  .break_line {
    margin: 24px 0 20px 0;
  }

  .no-tooltips {
    pointer-events: none;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    margin: 330px 0 0 0;
  }
`

const LoanDetailsSection = ({ loan }) => {
  const {
    netPrincipleLimit,
    totalLoanBalance,
    setAsideFunds,
    interestRate,
    maxOnlineRequestAmount,
    availableLineOfCredit,
    accountType,
    availableFirstYearLineOfCredit,
    anniversaryDate,
  } = loan

  const { isXSmall } = useWindowSize()

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If applicable to your loan type; please refer to your Note and/or Loan Agreement.
    </Tooltip>
  );

  return (
    <StyledLoanDetailsSection>
      <h2 className="heading-6">Loan Details</h2>
      <div className="loan_detail_row">
        <h3 className={isXSmall ? 'body-2' : 'body-1'}>
          Current Net Principal Limit
        </h3>
        <p className={isXSmall ? 'body-2' : 'body-1'}>
          {netPrincipleLimit}
        </p>
      </div>
      <div className="break_line" />
      <div className="loan_detail_row">
        <h3 className={isXSmall ? 'body-2' : 'body-1'}>
          Current Available Line-of-Credit
          {accountType === 'HECM' && <span className="sr-only">Amount Available to Request Online</span>}
          {accountType === 'HECM' && <span className="sr-only">Line of Credit advances cannot exceed the Current Net Principal Limit</span>}
        </h3>
        <p className={isXSmall ? 'body-2' : 'body-1'}>
          {availableLineOfCredit}
        </p>
      </div>
      {accountType === 'HECM' && (
        <div className="loan_detail_row">
          <h3 className={isXSmall ? 'body-2' : 'body-1'}>
            Amount Available to Request Online
          </h3>
          <p className={isXSmall ? 'body-2' : 'body-1'}>
            {maxOnlineRequestAmount}
          </p>
        </div>
      )}
      {accountType === 'HECM' ? (
        <div id="disclaimer-row" className="disclaimer_row" aria-hidden="true">
          <OutlinedAlertIcon className="grey-alert-icon" aria-hidden="true" />
          <p className="caption" aria-hidden="true">
            Line of Credit advances cannot exceed the Current Net
            Principal Limit
          </p>
        </div>
      ) : (
        ''
      )}
      <div className="break_line" />
      <div className="loan_detail_row">
        <h3 className={isXSmall ? 'body-2' : 'body-1'}>
          Current Total Loan Balance
        </h3>
        <p className={isXSmall ? 'body-2' : 'body-1'}>
          {totalLoanBalance}
        </p>
      </div>
      <div className="break_line" />
      {anniversaryDate !== null && new Date(anniversaryDate) > new Date() && availableFirstYearLineOfCredit !== null ? (
        <>
          <div className="loan_detail_row">
            <div style={{ "flexGrow": 1 }}>
              <h3 className={`d-inline ${isXSmall ? 'body-2' : 'body-1'}`}>
                1st Year Line of Credit Available
              </h3>

              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <i><OutlinedAlertIcon className="grey-alert-icon no-tooltips" aria-hidden="true" title={'If applicable to your loan type; please refer to your Note and/or Loan Agreement.'} /></i>
              </OverlayTrigger>
            </div>
            <p className={isXSmall ? 'body-2' : 'body-1'}>
              {availableFirstYearLineOfCredit}
            </p>
          </div>
          <div className="break_line" />
        </>
      ) : (
        ''
      )}
      {anniversaryDate !== null ? (
        <>
          <div className="loan_detail_row">
            <h3 className={isXSmall ? 'body-2' : 'body-1'}>
              Anniversary Date
            </h3>
            <p className={isXSmall ? 'body-2' : 'body-1'}>
              {anniversaryDate}
            </p>
          </div>
          <div className="break_line" />
        </>
      ) : (
        ''
      )}
      <div className="loan_detail_row">
        <h3 className={isXSmall ? 'body-2' : 'body-1'}>
          Interest Rate
        </h3>
        <p className={isXSmall ? 'body-2' : 'body-1'}>
          {interestRate || ''}%
        </p>
      </div>
      <div className="break_line" />
      <div className="loan_detail_row">
        <h3 className={isXSmall ? 'body-2' : 'body-1'}>
          Set Aside Funds
        </h3>
        <p className={isXSmall ? 'body-2' : 'body-1'}>
          {setAsideFunds}
        </p>
      </div>
      <HaveQuestionsBottomBar
        link="/common-questions"
        text="Common Questions Page"
      />
    </StyledLoanDetailsSection>
  )
}

LoanDetailsSection.propTypes = {
  loan: PropTypes.object.isRequired,
}

export default LoanDetailsSection
