import React, { useRef } from 'react'
import { useFocus } from '../Hooks/useFocus'
import Styled from 'styled-components'

const StyledH1 = Styled.div`
h1 {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 40px;
    text-align: center;
    :focus {
      outline: none;
    }
  }
  `

const H1 = ({ children }) => {
  const h1 = useRef(null)
  useFocus(h1, true)

  return (
    <StyledH1>
      <h1 id="title" tabIndex="-1" ref={h1}>
        {children}
      </h1>
    </StyledH1>
  )
}

export default H1
