import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import PropTypes from 'prop-types'

import { submitLoc } from '../../services/api'
import AccountBalanceIcon from '../../assets/account-balance-icon.js'
import useWindowSize from '../../Hooks/useWindowSize'
import { numberToMoney } from '../../Utils/utils'
import ScrollToTop from '../../Global/ScrollToTop'
import Button from '../../Common/button'
import CancelModal from '../../Common/CancelModal'
import { trackLOCRequest } from '../../Global/Analytics'

import { COMPLETE_FORM, REQUEST_SUBMITTED } from './index.js'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const StyledSubmitRequest = styled.div`
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0 40px;

  .review_content {
    margin: 0 auto;
    width: 416px;

    h6 {
      text-align: center;
    }

    .label {
      margin-bottom: 12px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 13px;
      margin-top: 3px;
    }

    .subtitle {
      font-weight: bold;
    }

    .content_block {
      margin-bottom: 35px;
    }

    .bank_details_block {
      display: flex;
      flex-direction: row;
    }
  }

  .break_line {
    width: 100%;
    border: 0.5px solid ${({ theme }) => theme.colors.black12};
    margin: 0;
  }

  .card_buttons {
    display: flex;
    margin: 32px 0;
    justify-content: space-between;

    .change_button {
      margin-left: auto;
    }

    .submit_button {
      margin-left: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    padding: 32px 16px;

    .review_content {
      width: 100%;

      h6 {
        margin-top: 0;
      }
    }

    .label {
      margin: 32px 0 12px 0;
    }

    .subtitle {
      margin: 0;
    }

    .break_line {
      width: 100%;
    }

    .card_buttons {
      margin: 32px 0 0 0;
      justify-content: space-between;
      margin-bottom: 80px;

      .change_button {
        position: absolute;
        margin-top: 80px;
      }
    }
  }
`

const SubmitRequest = ({
  setCurrentScreen,
  drawAmount,
  loan,
  setSubmittedRequestData,
  payType,
  setUserVerified,
  user,
}) => {
  const [modalShow, setModalShow] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)

  useEffect(() => {
    return () => setUserVerified(true)
  }, [setUserVerified])

  const { isSmall } = useWindowSize()

  const submitRequest = async () => {
    try {
      setIsVerifying(true)
      const result = await submitLoc(
        loan.accountNumber,
        dayjs().format(),
        'Website',
        drawAmount,
        user.completeName,
        payType,
      )

      setIsVerifying(false)
      setCurrentScreen(REQUEST_SUBMITTED)

      const { request_id, request_submission_time, request_amount } =
        result.data.putLOCRequest.details

      let DateTimeOfReq

      // ie does not have full time zone support
      if (window.document.documentMode) {
        DateTimeOfReq =
          dayjs(
            request_submission_time,
            'YYYY-MM-DDTHH:mm:ss.000ZZ',
          ).format('MM/DD/YYYY h:mma') + ' (local)'
      } else {
        DateTimeOfReq = dayjs(
          request_submission_time,
          'YYYY-MM-DDTHH:mm:ss.000ZZ',
        )
          .tz('America/New_York')
          .format('MM/DD/YYYY h:mma ET')
      }
      setSubmittedRequestData({
        RequestID: request_id,
        drawAmount: numberToMoney(request_amount),
        DateTimeOfReq,
        payType,
      })
      trackLOCRequest.Completed()
    } catch (e) {
      setIsVerifying(false)
      console.log('error submitting: ', e)
    }
  }
  return (
    <>
      <ScrollToTop />
      <CancelModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <StyledSubmitRequest>
        <div className="review_content">
          <h6>Please review your Line of Credit Advance request</h6>
          <div className="content_block">
            <p className="label">Line of Credit Advance Amount</p>
            <p className="subtitle">{numberToMoney(drawAmount)}</p>
          </div>
          <div className="content_block">
            <p className="label">Date of Request</p>
            <p className="subtitle">{dayjs().format('MM/DD/YYYY')}</p>
          </div>
          <div className="content_block">
            <p className="label">Disbursement Method</p>
            <div className="bank_details_block">
              <AccountBalanceIcon />
              <p className="subtitle">{payType}</p>
            </div>
            <p className="label">
              Changes to your preferred disbursement method or bank
              account information must be completed prior to
              submitting this request. Otherwise, funds will be
              disbursed according to your disbursement method
              currently on file.
            </p>
          </div>
        </div>
        <div className="break_line"></div>
        <div className="card_buttons">
          <Button
            text="Cancel"
            disabled={isVerifying}
            outlined
            onClick={() => {
              return !isVerifying && setModalShow(true)
            }}
            disableFocus
            UID="submit_request"
          />
          <Button
            className="change_button"
            disabled={isVerifying}
            onClick={() => {
              return !isVerifying && setCurrentScreen(COMPLETE_FORM)
            }}
            text="Change Amount"
            outlined
            UID="submit_request"
          />
          <Button
            className="submit_button"
            text={
              isVerifying
                ? 'Submitting'
                : isSmall
                ? 'Submit'
                : 'Submit Request'
            }
            loading={isVerifying}
            onClick={() => {
              submitRequest()
            }}
            UID="submit_request"
          />
        </div>
      </StyledSubmitRequest>
    </>
  )
}

SubmitRequest.propTypes = {
  setCurrentScreen: PropTypes.func.isRequired,
  drawAmount: PropTypes.string.isRequired,
  setSubmittedRequestData: PropTypes.func.isRequired,
  payType: PropTypes.string.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  loan: PropTypes.shape({
    accountNumber: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.exact({
    completeName: PropTypes.string.isRequired,
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
}

export default SubmitRequest
