import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { THIRD_PARTY } from '.'

import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { trackESignConsent } from '../../Global/Analytics'

dayjs.extend(utc)

const StyledConsent = styled.div`
h1, h3 {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin-top: 16px;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
  
  h5 {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-top: 16px;
  }
  
  .rectangle-70 {
    background: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    height: 450px;
    overflow-y: scroll;
  }

  .scroll-mask {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  }
  
  h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin: 0 0 20px 0;
  }
  
  p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
  }
  
  .cb-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin-top: 20px;
  }
  
  .cb {
    height: 18px;
    width: 18px;
    margin-right: 12px;
  
    position: static;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 12px;
  }
  
  .l-cb {
    left: calc(50% - 235px / 2 + 18px);
    top: 0px;
    position: static;
  }
  
  .list-consent {
    margin-top: 0;
    margin-left: 20px;
  }

  .list-consent li {
    margin-bottom: 10px;
}
  
  label > ul {
    margin-top: 20px;
  }
  
  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 24px;
    justify-content: space-between;
  
    .cancel {
      margin-right: 24px;
    }
  
    .button {
      width: 162px;
    }
  }  

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    min-height: 650px;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
    h1, h3, h5 {
      text-align: center;
    }

    .consent {
        background-color: #fff;
        padding: 15px;
        margin-top: 32px;
        border-radius: 4px;
        z-index: 150;
        position: relative;
        box-shadow: 0px 2px 4px -1px rgba(35, 47, 66, 0.06), 0px 4px 6px -1px rgba(35, 47, 66, 0.1);
      }

    .rectangle-70 {
        height: 354px;
        border-radius: 0px;
        padding: 32px 32px 0 32px;
        margin: 18px 108px 25px 108px;
    }

    .cb-container {
      margin: 16px 108px 0 108px;
    }

    .cb-container-list {
      margin: 0 108px;
    }

    .list-consent li {
        margin-bottom: 12px;
    }

    .buttons {
      width: 100%;
      margin-top: 32px;
      .cancel {
        margin-right: 20px;
      }
    }
  }
`
const Consent = ({ borrower, setView, setModalShow, setConsentTimestamp, mainRef }) => {
  const [nextButtonDisabled, setnextButtonDisabled] = useState(true)
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const [showBlur, setShowBlur] = useState(true)
  const [checked, setchecked] = useState(false)
  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight > scrollHeight - 50) {
        setScrolledToBottom(true)
        if (checked)
          setnextButtonDisabled(false)
      }

      if (scrollTop !== 0)
        setShowBlur(false)
      else
        setShowBlur(true)
    }
  };

  const handleClick = (e) => {
    const checked = e.target.checked;
    setchecked(checked)
    if (checked && scrolledToBottom) {
      setConsentTimestamp(dayjs.utc().local().format());
      setnextButtonDisabled(false)
    }
    else {
      setConsentTimestamp(null);
      setnextButtonDisabled(true)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <StyledConsent>
      <div className="consent"
        data-gridcolumnlayout={{
          xl: '3/13',
          lg: '1/13',
          md: '1/14',
          sm: '1/9',
        }}>
        <h1 tabIndex={-1} ref={mainRef}>Annual Occupancy Certification</h1>
        <h5>You have chosen to electronically sign the Annual Occupancy Certification form.</h5>
        <div
          className={`rectangle-70 ${showBlur ? "scroll-mask" : ""}`}
          onScroll={onScroll}
          ref={listInnerRef}
          data-testid={buildTestID(
            'consent',
            '/esign',
            'scrollable',
          )}>
          <h6>Electronic Consent Agreement</h6>
          <p>Please review and accept the Electronic Consent Agreement. <b><em>You must scroll through this section to review the entire agreement</em></b>.</p>
          <p>(CONSENT AGREEMENT FOR USE OF ELECTRONIC DISCLOSURES, SIGNATURES, RECORDS, AND COMMUNICATIONS)</p>
          <p>Electronic Documents and Signatures - Consent Reverse Mortgage Department(also referred to here as “we” or “us”) is required or allowed to provide you with certain statements, written disclosures, documents and information pertaining to your transactions with us (“Required Information”).</p>
          <p>Under the federal E-SIGN Act and related state laws, we must obtain your consent to deliver Required Information to you electronically and to use electronic records and electronic signatures in connection with your transactions with us. Therefore, please review the terms of this Electronic Consent Agreement prior to giving your consent and retain a copy of this Electronic Consent Agreement for your records.</p>
          <p>If you do not want to receive the Required Information electronically, or do not want to use electronic signatures in connection with your transactions with us, you should not agree to the terms of this Electronic Consent Agreement.</p>
          <p>Your consent will apply to all your transactions with us, all accounts registered under your Social Security number and any Required Information we provide or make available to you. Your consent also permits the general use of electronic records and electronic signatures in connection with all your transactions with us. Celink reserves the right to deny or cancel providing any documents and other information electronically at any time.</p>
          <p><b>Required Information You Will Receive Electronically</b>. By providing your consent under this Agreement, you agree that we may provide you with the following Required Information solely in electronic form:</p>
          <ul>
            <li>Any statements, privacy policies, contracts, documents, or disclosures required by law or provided by Celink or the holder of your loan, including, without limitation, any loan or insurance disclosures, documentation, and other transaction-related communications and disclosures. You will be notified at the e-mail address you have provided to us when one of these documents is available.</li>
          </ul>
          <p><b>Access to your electronic statement, privacy policy and other documents and disclosures that we provide you is through accessing your reversedepartment.com account</b>. We may send you a hyperlink by e-mail to access your electronic statement or other documents and information, but you will need to log into your reversedepartment.com account to access any such documents or information. <b>IT IS YOUR RESPONSIBILITY TO PROTECT AND SAFEGUARD YOUR REVERSEDEPARTMENT.COM USERNAME AND PASSWORD AT ALL TIMES</b>.</p>
          <p><b>There may be certain notices or documents that by law, we are not permitted to provide you electronically, even with your consent</b>.  If required by law, we will deliver these notices or documents to you in paper format, unless we are permitted to deliver them electronically.  Sometimes we may require, by law or for other reasons, that you give us a written notice or provide a “wet ink” signature.  You must still provide these notices on paper or with a “wet ink” signature, unless we specifically tell you that you may deliver that notice to us electronically or with an e-signature.</p>
          <p><b>Paper Copy of Required Information Available Upon Request - No Charge</b>. If you consent to receive the Required Information electronically, you can also request a paper copy of the Required Information by contacting us by phone at 866-654-0020 or by sending an email request to borrower.care@reversedepartment.com.  We will not charge you any fees for providing a paper copy of the Required Information. Your consent does not mean that Celink must provide the Required Information electronically - only that they may do so. We may, at our option, deliver Required Information on paper, if we choose to do so. Celink may also require that certain communications from you be delivered to us on paper at a specified address.</p>
          <p><b>Withdrawing Your Consent</b>. You may withdraw your consent to receive Required Information electronically or to the use of electronic signatures in connection with any future transactions with us at any time. Any withdrawal of your consent will be effective only after we have a reasonable period of time to process your withdrawal, which may take up to 10 business days. We will not charge you any fees for withdrawing your consent. If you decide you do not want to receive Required Information electronically, or do not want to use electronic signatures in connection with any future transactions with us, you may withdraw your consent* by logging on to reversedepartment.com, going to [My Account] page, choosing [Notifications] and changing settings under [Paperless Communications].  You may also revoke your consent by contacting us by phone at 866-654-0020 or by sending a signed written request to the physical mailing address on your account statement. Celink may revoke your consent if your web access is blocked or if e-mail notifications are repeatedly returned as undeliverable.  In the event Celink materially amends this consent agreement, you will be provided notice and will have the opportunity to revoke your consent.</p>
          <p><b>Updating Your Contact Information</b>. If you consent to receive Required Information electronically, we will contact you at the e-mail address you have provided to us. If you change your e-mail address, you must log into your reversedepartment.com account and update your email address by going to the [My Account] page, choosing [Email address], and updating your email address.</p>
          <p><b>System Requirements to Access the Required Information</b>. To access the Required Information being provided to you electronically, you must have an active reversedepartment.com account, active e-mail address, internet connection and compatible computing system/browser, which are as follows:</p>
          <ul>
            <li>A personal computer or mobile device with internet access and one of the following operating system web browser combinations installed:
              <ul>
                <li>Microsoft Windows 7, 8, 8.1, 10 or higher with:
                  <ul>
                    <li>Edge (version 86 or more recent)</li>
                    <li>Mozilla Firefox (version 81 or more recent)</li>
                    <li>Google Chrome (version 86 or more recent)</li>
                  </ul>
                </li>
                <li>Apple Mac OS X 10.9 (Mavericks) or higher with:
                  <ul>
                    <li>Safari (version 14 or more recent)</li>
                    <li>Mozilla Firefox (version 81 or more recent)</li>
                    <li>Google Chrome (version 86 or more recent)</li>
                  </ul>
                </li>
                <li>Android 7.0 (Nougat) or higher with:
                  <ul>
                    <li>Google Chrome (version 86 or more recent)</li>
                  </ul>
                </li>
                <li>Apple iOS 12.4.7 or higher with:
                  <ul>
                    <li>Safari (version 14 or more recent)</li>
                    <li>Google Chrome (version 86 or more recent)</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              The reversedepartment.com site may use session cookies or other forms of temporary data storage while you are logged into your account. If these options are disabled, the site may not function properly.  Please ensure your cookies, session Storage, and local Storage options in your browser are set to 'enabled'.
            </li>
            <li>
              The ability to receive e-mail that contains hyperlinks to Websites in order for us to deliver information to you.  Please be sure to add noreply@reversedepartment.com to your “safe sender” list to prevent emails from going into your “junk” or “spam” folder.
            </li>
            <li>
              Software which permits you to receive, print and access Portable Document Format or "PDF" files, such as Adobe Acrobat Reader. If you do not have such software, visit https://get.adobe.com/reader/ to download a free version of Adobe Acrobat Reader.
            </li>
            <li>
              Sufficient electronic storage capacity on your computer's hard drive or other data storage unit or the ability to print PDF files from your computer or mobile device.
            </li>
          </ul>
          <p>
            <b>Electronic Communications</b>: We and our agents may contact you by e-mail for any reason including for account servicing or collections, to tell you about policies, requirements or services applicable to your loan or account from Celink or the holder of your loan, and for other business purposes.
          </p>
          <p>
            <b>Consent: I agree to be bound by the terms of this Electronic Consent Agreement and confirm that I have computer hardware and software that meets the requirements listed above. I also consent to receiving Required Information electronically and to the use of electronic records and signatures in connection with my transaction(s) with Celink in place of written (paper) documents and handwritten signatures.</b>
          </p>
        </div>
        <div className="cb-container">
          <input
            className="cb"
            type="checkbox"
            id={`chk-electronic-consent`}
            name="chk-electronic-consent"
            onClick={(e) => handleClick(e)}
            disabled={!scrolledToBottom}
            aria-label="Electronic Consent Agreement checkbox"
            data-testid={buildTestID('chk_electronic_consent', '/esign', 'annual_occupancy',)}
          />
          {scrolledToBottom && <p role="status" className="sr-only">electronic consent checkbox available</p>}
          <label htmlFor="chk-electronic-consent" className="l-cb">
            I, <b>{borrower.userName}</b>, have read and previously agreed to the Electronic Consent Agreement and I agree to the following:
          </label>
        </div>
        <div className='cb-container-list'>
          <ul className="list-consent">
            <li>I confirm that I agree to sign this Occupancy Certification form electronically.</li>
            <li>I understand that the purpose of the Occupancy Certification form is to certify that I am occupying the subject property as my primary residence.</li>
            <li>I understand and agree that continuing with this process will result in an electronic signature.</li>
            <li>The following message is applicable to you if your loan is insured by HUD: Warning: Section 1001 of Title 18 of the United States Code makes it a criminal offense to make a willfully false statement or misrepresentation to any department or agency of the United States government as to any matter within its jurisdiction. </li>
            <li>I understand it is my responsibility to advise my loan servicer, in writing, of any absences from my property that exceed two (2) months, and to provided a temporary mailing address.</li>
          </ul>
        </div>
        <hr />
        <div className="buttons">
          <Button
            className="cancel button"
            text="Cancel"
            outlined
            fullWidth
            UID="electronic_consent_button_cancel"
            onClick={() => setModalShow(true)}
          />
          <Button
            className="button"
            text="Next"
            fullWidth
            UID="electronic_consent_button_next"
            disabled={nextButtonDisabled}
            onClick={() => {
              trackESignConsent.StepCompleted('completed electronic consent', 'optin_button_next')
              setView(THIRD_PARTY)
            }}
          />
        </div>
      </div>
    </StyledConsent>
  )
}

export default Consent