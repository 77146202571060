import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Grid from '../../Global/Grid'
import ResourceCard from './ResourceCard'
import { Accordion, Card, Carousel, useAccordionToggle } from 'react-bootstrap'

import hud_hecm from '../../assets/hud_hecm.svg'
import consumer_finacial from '../../assets/consumer_finacial.svg'
import fema_disaster from '../../assets/fema_disaster.svg'
import us_department from '../../assets/us_department.svg'
import national_reverse from '../../assets/national_reverse.svg'
import { usePrevious } from '../../Hooks/usePrevious'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronRight,
    faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import { trackVideoPlayed } from '../../Global/Analytics'
import Title from '../../Common/Title'
import VideoJS from '../VideoJS'
import { theme } from '../../Global/Theme'
import LandingPageHeading from '../LandingPageHeading'

const StyledHelpfulResourcesModern = styled.div`
h1, h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin: 41px 0 20px;
    :focus {
        outline: none;
    }
}

h4 {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
}

figure {
    display: flex;
    margin: 32px 0 24px 0;
    flex-direction: column;
    justify-content: space-between;
    background: #FAFAFA;

    video {
        padding-bottom: 18px;
    }

    figcaption {
        padding: 32px;
        min-height: 520px;

        @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
            min-height: unset;
        }
    }
}

.text-new-video {
  border-radius: 6px;
  background-color: var(--Orange, #c45835);
  color: var(--White, #fff);
  justify-content: center;
  padding: 5px;
  font: 700 14px/114% Lato, sans-serif;
  border: none;
  cursor: pointer;
  width: 100px;
  height: 24px;
  flex-shrink: 0;
  text-transform: uppercase;
}

.text-manage-online {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.25px;
}

.text-learn {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
}

.resource-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;
}
  
hr {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.text-disclosure {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
    margin: 60px 0;
}  

.content {
  /* display: flex;
  flex-direction: row; */
  height: 100%;

  .sidebar {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    height: 50vh;
    font-size: 16px;
  }

  .questions {
    width: 100%;
    word-break: break-word;
  }

  .accordion {
    margin-bottom: 40px;
  }

  .card {
    border-radius: 20px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--White, #fff);
    display: flex;
    gap: 20px;
    font-size: 22px;
    color: var(--Black, #000);
    font-weight: 600;
    line-height: 109%;
    padding: 24px 26px;
    margin-bottom: 10px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
}

.card-header {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    padding: 0;
    border-bottom: none;

    svg {
      /* margin-right: 6px; */
      order: 2
    }

    button {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
      border: none;
      text-align: left;
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-weight: 600;
    }
  }

  .body-1 {
      text-align: left;
      padding: 25px 0;
      border-bottom: 1px solid var(--Grey, #DCDCDC)
  }

  img {
    margin-bottom: 12px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }

  ul {
    padding-left: 0;
    margin-top: 10px;

    li {
      margin-bottom: 10px;
      margin-left: 20px;
    }

    p {
        &.body-1 {
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 1.2;
          margin-bottom: 0.5rem;
        }
    }
  }

  ol {
    padding-left: 0;
    margin-bottom: 20px;

    li {
      margin-bottom: 10px;
      font-size: 20px;
      margin-top: 10px;
      margin-left: 30px;
    }
  }
}

.card-body {
    ul {
        list-style-type: none;
    }

    a {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 130% */
        text-decoration-line: underline;
    }
}

.carousel-control-next, .carousel-control-prev {
    color: #1460AA;
}

.carousel-item {
    figure {
        border-radius: 20px;
        background: var(--White, #FFF);
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
    }
}

.carousel-indicators {
    margin-bottom: -30px;
}

.carousel-indicators li {
    background-color: #000;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
}


@media (min-width: ${({ theme }) => theme.breakPoints.small}) { 
    figure {
        flex-direction: row;
        padding: 30px 0 30px 30px;
    }

    figcaption {
        margin: auto 0 auto 10px;
    }

    .resource-cards {
        flex-direction: row;
        margin-top: 40px;
    }
}
`

const usefulLinks = [
    {
        title: `Senior Benefits Programs`,
        img: '',
        heading: 'Visit the links below for information:',
        links: [
            {
                subHeading: '',
                urlText: 'Eldercare Locator',
                url: 'https://eldercare.acl.gov/Public/Index.aspx',
            },
            {
                subHeading: '',
                urlText: 'National Council On Aging Benefits Checkup',
                url: 'https://benefitscheckup.org/',
            },
            {
                subHeading: '',
                urlText: 'NeighborWorks America',
                url: 'https://www.neighborworks.org/home',
            },
            {
                subHeading: '',
                urlText: 'Rebuilding Together',
                url: 'https://rebuildingtogether.org/',
            },
        ],
    },
    {
        title: `HUD: HECM Loans for Seniors`,
        img: hud_hecm,
        heading: 'Visit the links below for resources:',
        links: [
            {
                subHeading: '',
                urlText: 'How the HECM Program Works',
                url: 'https://www.hud.gov/program_offices/housing/sfh/hecm/hecmabou',
            },
            {
                subHeading: '',
                urlText: 'HUD’s Senior Page',
                url: 'https://www.hud.gov/topics/information_for_senior_citizens',
            },
            {
                subHeading: '',
                urlText: 'Important Info for Non-Borrowing Spouses',
                url: 'https://www.hud.gov/program_offices/housing/sfh/hecm/surviving_non_borrowing_spouse',
            },
            {
                subHeading: '',
                urlText: 'HUD Counselor List',
                url: 'https://entp.hud.gov/idapp/html/hecm_agency_look.cfm',
            },
            {
                subHeading: '',
                urlText: 'HUD’s Online FAQ site',
                url: 'https://www.hud.gov/FHAFAQ',
            },
        ],
    },
    {
        title: `Consumer Financial Protection Bureau Resources`,
        img: consumer_finacial,
        heading: '',
        links: [
            {
                subHeading: 'CFPB Reverse Mortgage Consumer Guide',
                urlText:
                    'You have a reverse mortgage: Know your rights and responsibilities',
                url: 'https://pueblo.gpo.gov/CFPBPubs/CFPBPubs.php?PubID=13459',
            },
            {
                subHeading: 'CFPB Disasters & Emergencies',
                urlText:
                    'Preparing, recovering, and rebuilding after disasters and emergencies',
                url: 'https://www.consumerfinance.gov/consumer-tools/disasters-and-emergencies/?_gl=1*nehkyb*_ga*NzU0NDcwNjM2LjE1OTcwOTU5NjA.*_ga_DBYJL30CHS*MTYxNTQ4NjcyNC4yLjEuMTYxNTQ4NzE2NC4w',
                urlTextTwo: 'Consumer Guide: Your Reverse Mortgage After a Natural Disaster',
                urlTwo: 'https://files.consumerfinance.gov/f/documents/cfpb_OA_natural_disaster_bifold.pdf'
            },
            {
                subHeading: 'CFPB Reverse Mortgages',
                urlText: 'A discussion guide',
                url: 'https://files.consumerfinance.gov/f/documents/cfpb_reverse-mortgage-discussion-guide.pdf',
            },
            {
                subHeading: 'CFPB Preventing Elder Financial Abuse',
                urlText: 'Preventing Elder Financial Abuse',
                url: 'https://files.consumerfinance.gov/f/documents/cfpb_preventing-elder-financial-abuse_friends-family-guide.pdf',
                urlTextSpanish: 'Preventing Elder Financial Abuse (Spanish Version)',
                urlSpanish: 'https://pueblo.gpo.gov/CFPBPubs/CFPBPubs.php?PubID=13532'
            },
            {
                subHeading: 'CFPB Reporting Elder Financial Abuse',
                urlText: 'Reporting Elder Financial Abuse',
                url: 'https://www.justice.gov/elderjustice/roadmap',
                urlTextSpanish: 'Reporting Elder Financial Abuse (Spanish Version)',
                urlSpanish: 'https://pueblo.gpo.gov/CFPBPubs/CFPBPubs.php?PubID=13533'
            },
            {
                subHeading: 'CFPB Help For Surviving Spouses',
                urlText: 'Help for Surviving Spouses | Consumer Financial Protection Bureau ',
                url: 'https://www.consumerfinance.gov/consumer-tools/educator-tools/resources-for-older-adults/discover-resources-for-surviving-spouses/'
            }
        ],
    },
    {
        title: `FEMA Disaster Website`,
        img: fema_disaster,
        heading: 'Visit the link below for information:',
        links: [
            {
                subHeading: '',
                urlText: 'Disaster Website',
                url: 'https://www.fema.gov/disasters/',
            },
        ],
    },
    {
        title: `U.S. Department of the Treasury Homeowner’s Assistance Fund`,
        img: us_department,
        heading: 'Visit the link below for information:',
        links: [
            {
                subHeading: '',
                urlText: 'Homeowner’s Assistance Fund',
                url: 'https://home.treasury.gov/policy-issues/coronavirus/assistance-for-state-local-and-tribal-governments/homeowner-assistance-fund',
            },
        ],
    },
    {
        title: `National Reverse Mortgage Lender’s Association`,
        img: national_reverse,
        heading: 'Visit the links below for resources:',
        links: [
            {
                subHeading: '',
                urlText:
                    'What You Need to Know About Your HECM After Closing',
                url: 'https://www.nrmlaonline.org/2019/01/01/need-know-hecm-closing',
            },
            {
                subHeading: '',
                urlText: 'What Do I Do When My Loan is Due?',
                url: 'https://www.nrmlaonline.org/2018/12/31/what-do-i-do-when-my-loan-is-due',
            }
        ],
    },
]

function CustomToggle({ children, eventKey }) {
    const [accordionOpen, setAccordionOpen] = useState(false)
    const { previous } = usePrevious(accordionOpen)

    useEffect(() => {
        previous && setAccordionOpen(false)
    }, [previous])

    const toggleAccordion = useAccordionToggle(eventKey, () =>
        setAccordionOpen(!accordionOpen),
    )

    const handleClick = (e) => {
        toggleAccordion()
    }

    return (
        <button onClick={handleClick} aria-expanded={accordionOpen}>
            {' '}
            {accordionOpen ? (
                <FontAwesomeIcon icon={faChevronDown} />
            ) : (
                <FontAwesomeIcon icon={faChevronRight} />
            )}
            {children}
        </button>
    )
}

const HelpfulResources = () => {
    const [index, setIndex] = useState(0)

    const mainRef = useRef(null)
    let videoOneRef = useRef(null)
    let videoTwoRef = useRef(null)
    let videoThreeRef = useRef(null)

    const isSmall = window.innerWidth <= parseInt(theme.breakPoints.small, 10)
    const width = isSmall ? 342 : 629
    const height = isSmall ? 211 : 318

    const lossMitigationOptions = {
        title: "Preparing Your Heirs: What happens with my Reverse Mortgage when all borrowers pass away?",
        autoplay: false,
        controls: true,
        width: isSmall ? width : 587,
        height: height,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [{
            src: './assets/celink_loss_mitigation.mp4',
            type: 'video/mp4'
        }],
        track: {
            src: './assets/text-tracks/celink-loss-mitigation-english.vtt',
        }
    }

    const lossOptions = {
        title: "Manage your loss draft video",
        autoplay: false,
        controls: true,
        width: width,
        height: height,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [{
            src: './assets/celink_loss_draft.mp4',
            type: 'video/mp4'
        }],
        track: {
            src: './assets/text-tracks/celink-loss-draft-english.vtt',
        }
    }

    const manageAccountOptions = {
        title: "Manage your account online video",
        autoplay: false,
        controls: true,
        width: isSmall ? width : 587,
        height: height,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [{
            src: './assets/celink.mp4',
            type: 'video/mp4'
        }],
        track: {
            src: './assets/text-tracks/celink-manage-your-account-english.vtt',
        }
    }

    // useEffect(() => {
    //     if (mainRef)
    //         mainRef.current.focus();
    // }, [mainRef]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (videoOneRef.current.paused() && videoTwoRef.current.paused() && videoThreeRef.current.paused())
                index === 2 ? setIndex(0) : setIndex(index + 1);
        }, 10000);
        return () => clearTimeout(timeout);
    }, [index]);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const handlePlayerOneReady = (player) => {
        videoOneRef.current = player;
    };

    const handlePlayerTwoReady = (player) => {
        videoTwoRef.current = player;
    };

    const handlePlayerThreeReady = (player) => {
        videoThreeRef.current = player;
    };
    return (
        <>
            <Title>Helpful Resources</Title>
            <StyledHelpfulResourcesModern>
                <Grid className="grid">
                    <div style={{ margin: "60px 0 40px" }}>
                        <LandingPageHeading title="Educational Videos" />
                    </div>
                    <Carousel
                        activeIndex={index}
                        onSelect={handleSelect}
                        variant=''
                        interval={null}
                        controls={false}
                        data-gridcolumnlayout={{
                            sm: '1/12',
                            md: '2/12',
                            lg: '2/14',
                            xl: '2/14',
                        }}>
                        <Carousel.Item>
                            <figure data-gridcolumnlayout={{
                                sm: '1/12',
                                md: '2/12',
                                lg: '2/14',
                                xl: '7/14',
                            }}>
                                <VideoJS options={lossMitigationOptions} onReady={handlePlayerOneReady} onPlay={() => trackVideoPlayed.WatchedVideo("loss mitigation video", "helpful resources")} />
                                <figcaption>
                                    <p className='text-manage-online'>Preparing your Heirs: What happens with my Reverse Mortgage when I pass away?</p>
                                    <p className='text-learn'>Learn how to resolve a reverse mortgage loan debt after borrowers pass away</p>
                                    <p className='text-learn'><a href="./documents/Loan-Resolution-Visual-Document.pdf">Visual Description</a></p>
                                    <p className='text-learn'>Obtain a copy of the Loan Resolution Checklist for Borrowers and Heirs <a href="./forms/2024/Loan_Resolution_Checklist_For_Borrowers_And_Heirs.pdf">here</a>.</p>
                                    <p className='text-learn'>Actual requirements for non-FHA insured reverse mortgages may vary.</p>
                                </figcaption>
                            </figure>
                        </Carousel.Item>
                        <Carousel.Item>
                            <figure data-gridcolumnlayout={{
                                sm: '1/12',
                                md: '2/12',
                                lg: '2/14',
                                xl: '2/14',
                            }}>
                                <VideoJS options={lossOptions} onReady={handlePlayerTwoReady} onPlay={() => trackVideoPlayed.WatchedVideo("manage your loss draft video", "helpful resources")} />
                                <figcaption>
                                    <p className='text-manage-online'>Loss Draft</p>
                                    <p className='text-learn'>Learn how to manage an insurance loss and insurance claim on your property.</p>
                                </figcaption>
                            </figure>
                        </Carousel.Item>
                        <Carousel.Item>
                            <figure data-gridcolumnlayout={{
                                sm: '1/12',
                                md: '2/12',
                                lg: '2/14',
                                xl: '7/14',
                            }}>
                                <VideoJS options={manageAccountOptions} onReady={handlePlayerThreeReady} onPlay={() => trackVideoPlayed.WatchedVideo("manage your account online video", "helpful resources")} />
                                <figcaption>
                                    <p className='text-manage-online'>Manage Your Online Account</p>
                                    <p className='text-learn'>Learn how to create and manage your Reverse Mortgage online account.</p>
                                </figcaption>
                            </figure>
                        </Carousel.Item>
                    </Carousel>
                    <div className='resource-cards'
                        data-gridcolumnlayout={{
                            sm: '1/12',
                            md: '2/12',
                            lg: '2/14',
                            xl: '2/14',
                        }}>
                        <ResourceCard heading={"Insurance Information and Claims"} paragraph={"View property loss, insurance and claims information."} linkTo={"/claims"} helpText={"about property loss, insurance and claims information"} />
                        <ResourceCard heading={"New Loan Reference Guide"} paragraph={"Includes glossary of terms and sample statement."} linkTo={"/forms/New_Loan_Welcome_Guide.pdf"} helpText={"about new loan reference guide"} />
                        <ResourceCard heading={"Schedule of Borrower Fees"} paragraph={"View a list of borrower loan fees."} linkTo={"/schedule-of-borrower-fees"} helpText={"about borrower loan fees."} />
                    </div>
                    <hr data-gridcolumnlayout={{
                        sm: '1/12',
                        md: '2/12',
                        lg: '2/14',
                        xl: '2/14',
                    }} />
                    <p className='text-disclosure' data-gridcolumnlayout={{
                        sm: '1/12',
                        md: '2/12',
                        lg: '2/14',
                        xl: '2/14',
                    }}>
                        Important Disclosure: These resources are being provided for educational purposes only. Neither Celink nor Reverse Mortgage Servicing Department is responsible for the content, nor are they published by or otherwise associated with Celink or Reverse Mortgage Servicing Department. The logos and other information belong to the below listed entities and are subject to all copyright, trademark and other rights of the entity and, as applicable, the US Government.
                    </p>
                    <div className="content" data-gridcolumnlayout={{
                        sm: '1/12',
                        md: '2/12',
                        lg: '2/14',
                        xl: '2/14',
                    }}>
                        <Accordion data-gridcolumnlayout={{
                            sm: '1/12',
                            md: '2/12',
                            lg: '2/14',
                            xl: '2/14',
                        }}>
                            {usefulLinks.map((linkGroup, index) => {
                                return (
                                    <Card key={linkGroup.title}>
                                        <Card.Header>
                                            <CustomToggle eventKey={index + 1}>
                                                {linkGroup.title}
                                            </CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index + 1}>
                                            <Card.Body>
                                                {linkGroup.img ? (
                                                    <img src={linkGroup.img} alt={linkGroup.title} />
                                                ) : null}
                                                <ul>
                                                    {linkGroup.links.map((link) => {
                                                        return (
                                                            <React.Fragment key={link.urlText}>
                                                                {link.subHeading ? (
                                                                    <p className="body-1">
                                                                        {link.subHeading}
                                                                    </p>
                                                                ) : null}
                                                                <li className="body-1 bold">
                                                                    <a
                                                                        href={link.url}
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        {link.urlText}
                                                                    </a>
                                                                </li>
                                                                {
                                                                    link.urlSpanish &&

                                                                    <li className="body-1 bold">
                                                                        <a
                                                                            href={link.urlSpanish}
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                        >
                                                                            {link.urlTextSpanish}
                                                                        </a>
                                                                    </li>
                                                                }
                                                                {
                                                                    link.urlTextTwo &&

                                                                    <li className="body-1 bold">
                                                                        <a
                                                                            href={link.urlTwo}
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                        >
                                                                            {link.urlTextTwo}
                                                                        </a>
                                                                    </li>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                        </Accordion>
                    </div>
                </Grid>
            </StyledHelpfulResourcesModern>
        </>
    )
}

export default HelpfulResources 