import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

import { upload } from '../../services/file'
import { trackFormUpload } from '../../Global/Analytics'
import StyleGuide from '../../Global/StyleGuide'
import Grid from '../../Global/Grid'
import { toUpper } from '../../Utils/utils'
import Title from '../../Common/Title'

import BannerBar from '../../Common/BannerBar.js'
import InfoSection from './InfoSection'
import SelectionSection from './SelectionSection'
import UploadSection from './UploadSection'

const StyledUpload = styled.div`
  margin-bottom: 54px;

  .grid {
    margin-bottom: 200px;
  }

  .fixed_cell {
    position: sticky;
    top: 122px;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .fixed_cell {
      position: inherit;
    }
  }
`

const Forms = [
  {
    groupName: 'Account Maintenance',
    forms: [
      'Borrower Information for Servicing',
      'Change of Mailing Address',
      'Electronic Funds Transfer / Direct Deposit Agreement',
      'Third Party Authorization',
    ],
  },
  {
    groupName: 'Loan Resolution',
    forms: [
      'Loan Resolution Checklist For Borrowers And Heirs/Letter of Intent',
      'Non Borrowing Spouse Information',
      'Deed-in-lieu of Foreclosure Request',
      'At-Risk Extension Request for Extension of Time Due to Critical Circumstances',
      'Short Sale Instruction Package',
      'Loss Mitigation Application (NY Only)'
    ],
  },
  {
    groupName: 'Occupancy Certification',
    forms: [
      'Annual Occupancy Certificate',
    ],
  },
  {
    groupName: 'Payment Plan Changes',
    forms: [
      'Payment Plan Change Request',
    ],
  },
  {
    groupName: 'Partial Prepayments/Payments in Full',
    forms: [
      'Partial Prepayment Form',
      'Request for Reverse Mortgage Payoff Quote'
    ],
  },
  {
    groupName: 'Repairs Required at Loan Origination',
    forms: [
      'Homeowner’s Certification of Completed Repairs',
      'Repairs Affidavit and Lien Waiver',
      'Repairs Extension Request',
    ],
  },
  {
    groupName: 'Taxes & Insurance',
    forms: [
      'Repayment Plan Review Request',
      'Tax Payment Request',
    ],
  },
]

const Documents = [
  'Death Certificate',
  'Documents evidencing Personal Representative of the Estate',
  'Evidence of Insurance (fire/hazard, wind, flood, HO6, etc.)',
  'Last Will & Testament',
  'Payment Plan Change/Modification of Home Equity Conversion Loan Agreement (Signed)',
  'Proof of Marriage',
  'Tax & Insurance Repayment Plan Agreement (Signed)',
  'Tax & Insurance Repayment Plan Borrower Authorization Form',
  'Tax & Insurance Repayment Plan Due Date Change Letter',
]

const invalidFileTypes = [
  'exe',
  'scr',
  'vbs',
  'js',
  'xml',
  'docm',
  'xps',
  'iso',
  'img',
  'arj',
  'lzh',
  'r01',
  'r14',
  'r18',
  'r25',
  'tar',
  'ace',
  'zip',
  'jar',
  'doc',
  'rtf',
  'xls',
  'pub',
  'asp',
  'php',
]

const radioNameToID = (string) =>
  string
    .replace(/[^\w\s]/gi, '')
    .replace(/\s\s+/g, ' ')
    .replaceAll(' ', '-')
    .toLowerCase()

const Upload = () => {
  const [selected, setSelected] = useState(
    radioNameToID(Forms[0].forms[0]),
  )
  const [sectionSelected, setSectionSelected] = useState('Form')
  const [fileToUpload, setFileToUpload] = useState([])
  const [networkError, setNetworkError] = useState(false)
  const [invalidFileError, setInvalidFileError] = useState('')

  const [successfulUpload, setSuccessfulUpload] = useState(false)
  const [loading, setLoading] = useState(false)

  const fileUploaded = Boolean(fileToUpload.length)

  const handleUpload = () => {
    setLoading(true)

    upload(fileToUpload[0], selected, true).then((data) => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
      setLoading(false)
      if (data.key) {
        setSuccessfulUpload(true)
        setNetworkError(false)
        setFileToUpload([])
        trackFormUpload(
          toUpper(selected.replaceAll('-', ' ')),
          sectionSelected,
        )
        setTimeout(() => setSuccessfulUpload(false), 5000)
      } else {
        setNetworkError(true)
      }
    })
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      setInvalidFileError('')
      const invalidFiles = acceptedFiles.filter((x) =>
        invalidFileTypes.includes(
          x.name.split('.').pop().toLowerCase(),
        ),
      )
      if (invalidFiles.length) {
        setInvalidFileError(
          'Invalid file type. Please upload a different file type.',
        )
        return
      }
      setTimeout(() => setFileToUpload(acceptedFiles), 1000)
    },
    [setInvalidFileError, setFileToUpload],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  // Prevents user from uploading when file has been uploaded
  const dynamicUpload = fileUploaded ? () => { } : getRootProps

  return (
    <>
      <Title>Upload</Title>
      <StyleGuide>
        <StyledUpload>
          <Grid className="grid">
            <BannerBar
              show={successfulUpload}
              data-gridcolumnlayout={{
                sm: '1/9',
                md: '2/12',
                lg: '2/12',
                xl: '3/13',
              }}
              type="success"
              text="Your file is successfully uploaded."
            />
            <BannerBar
              show={networkError}
              data-gridcolumnlayout={{
                sm: '1/9',
                md: '2/12',
                lg: '2/12',
                xl: '3/13',
              }}
              type="error"
              text="Network error. Please try again."
            />
            <InfoSection
              data-gridcolumnlayout={{
                sm: '1/9',
                md: '2/8',
                lg: '2/8',
                xl: '3/9',
              }}
            />
            <UploadSection
              data-gridcolumnlayout={{
                sm: '1/9',
                md: '8/12',
                lg: '8/12',
                xl: '9/13',
              }}
              cellclassname="fixed_cell"
              setFileToUpload={setFileToUpload}
              fileUploaded={fileUploaded}
              fileToUpload={fileToUpload}
              getInputProps={getInputProps}
              isDragActive={isDragActive}
              invalidFileError={invalidFileError}
              dynamicUpload={dynamicUpload}
            />
            <SelectionSection
              data-gridcolumnlayout={{
                sm: '1/9',
                md: '2/8',
                lg: '2/8',
                xl: '3/9',
              }}
              Forms={Forms}
              Documents={Documents}
              setSelected={setSelected}
              selected={selected}
              fileUploaded={fileUploaded}
              successfulUpload={successfulUpload}
              handleUpload={handleUpload}
              loading={loading}
              radioNameToID={radioNameToID}
              setSectionSelected={setSectionSelected}
            />
          </Grid>
        </StyledUpload>
      </StyleGuide>
    </>
  )
}

export default Upload
