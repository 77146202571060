import { Storage } from 'aws-amplify'
import { saveAs } from 'file-saver'
import { getUser } from './auth'

Storage.configure({ level: 'private' })

export const upload = async (
  file,
  path = '',
  includeUserFolder = false,
) => {
  const { name, type } = file
  try {
    const result = await Storage.put(
      `${path}${
        includeUserFolder ? `/${(await getUser()).username}` : ''
      }/${name}.form`,
      file,
      {
        contentType: type,
      },
    )
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const remove = async (key) => await Storage.remove(key)

export const list = async (folder) =>
  await Storage.vault.list(folder, {
    bucket: process.env.REACT_APP_USERS_READ_BUCKET_ID,
  })

export const get = async (path) =>
  await Storage.vault.get(path, {
    bucket: process.env.REACT_APP_USERS_READ_BUCKET_ID,
    download: false,
  })

export const download = async (path) => {
  const { Body, ContentType } = await Storage.vault.get(path, {
    bucket: process.env.REACT_APP_USERS_READ_BUCKET_ID,
    download: true,
  })
  saveByteFile(path, Body, ContentType)
}

const saveByteFile = (reportName, byte, type) => {
  var blob = new Blob([byte], { type })
  saveAs(blob, reportName)
  /*
  var blob = new Blob([byte], { type });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
  */
}
