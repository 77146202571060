import React, { useRef, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { buildTestID } from '../../Utils/utils'

import CommonQuestionCategories from './CommonQuestionCategories'

const EftDirectDeposit = ({ CustomToggle, addRef }) => {
  const title = useRef(null)

  useEffect(() => {
    addRef(CommonQuestionCategories.EftDirectDeposit, title)
  })

  return (
    <>
      <h3 ref={title} id="eft-direct-deposit" tabIndex="-1">EFT/Direct Deposit</h3>
      <Accordion>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="0">
              Is there a fee for EFT/Direct Deposit?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              There is no fee charged to you for the electronic funds
              transfer / direct deposit service.
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Card.Header>
            <CustomToggle eventKey="1">
              How long does it take to set up or change my EFT/Direct
              Deposit information?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {' '}
              When updating or adding a bank account, the account cannot be used to receive funds for at least 14 calendar days following processing and verification.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="how-do-i-setup-direct-deposit">
          <Card.Header>
            <CustomToggle eventKey="2">
              What are the requirements for EFT/Direct Deposit?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              To establish or change direct deposit information, the following are required:
              <ol>
                <li>A completed and signed (by all borrowers) Electronic Funds Transfer/Direct Deposit Agreement (login to your account to get this form).</li>
                <li>Include a voided check, or a bank statement that provides the full routing number and full account number (image acceptable), or a letter signed by the financial institution on their letterhead confirming the deposit account routing number and account number.</li>
                <li>A personal or Trust checking or savings account may be used but a business account may NOT be used, even if a borrower's name appears on the account. <strong>Note: The Trust must be approved and on file with us</strong>.</li>
                <li>If there is/are additional person(s) named or authorized on the deposit account, the signature(s) on the Electronic Funds Transfer/Direct Deposit Agreement gives consent to deposit funds from the reverse mortgage loan to the account, and by signing the Agreement borrowers understand that anyone else on their bank account will have access to any funds deposited.</li>
              </ol>
              <ul>
                <li>IMPORTANT NOTES:<br />We cannot accept Deposit slips or Counter checks (checks that are not imprinted with the account holder’s name). <br />If there are two or more borrowers on the loan, we can accept an account that names only one borrower if all borrowers have signed the Agreement.<br /><strong><u>When updating or adding a bank account, the account cannot be used to receive funds for at least 14 calendar days following processing and validation</u></strong>.</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="3">
              Where should I send my EFT/Direct Deposit form?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              The Electronic Funds Transfer Direct Deposit Form, including <b>all</b> required supporting documentation as explained on the form, may be uploaded on this website by logging into your account or creating an account.  Otherwise, the documentation may be sent using the contact information on the form.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="4">
              Can I use a savings account?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              In order to have your payments deposited into a savings
              account, we must have written and signed verification of
              the account from the bank where the account is held. A
              letter on the bank’s letterhead signed by an authorized
              bank employee or officer will suffice. The letter must
              list the savings account number, the bank or credit
              union’s routing number, and any names that are listed on
              the account.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="5">
              Can I have someone else on my bank account and still
              have EFT/Direct Deposit?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              Funds can be sent to an account that lists another person(s) not listed on the loan (<em>a business name is not permitted</em>) but it must also list at least one of the borrowers on the account. To set up direct deposit, all borrowers on the loan must sign the Electronic Funds Transfer / Direct Deposit Form acknowledging that any additional person(s) listed on the bank account have access to the funds deposited to this bank account.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <CustomToggle eventKey="6">
              Can I have my funds sent to someone else's account or to my business account?
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              Funds can be sent to an account that lists another person(s) not listed on the loan (<em>a business name is not permitted</em>) but it must also list at least one of the borrowers on the account. To set up direct deposit, all borrowers on the loan must sign the Electronic Funds Transfer / Direct Deposit Form acknowledging that any additional person(s) listed on the bank account have access to the funds deposited to this bank account.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  )
}

export default EftDirectDeposit
